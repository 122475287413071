import React from 'react'
import "./BullProof.css"
const BullProof = () => {
  return (
    <div>
     
	 <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 884.7 425.6" style={{ enableBackground: 'new 0 0 884.7 425.6' }}
	 space="preserve">

<g>
	<g>
		<path d="M199.4,347.2c-0.8-0.4-1.4-0.6-2.1-1c0.4-1.4,0.7-2.8,1.1-4.2c1.7-6,3.3-12,5-17.9c0.4-1.3,0.2-2.4-0.5-3.5
			c-10.7-17.8-21.3-35.6-31.9-53.4c-3-5.1-6.1-10.2-9.1-15.4c-1.5-2.6-2.7-5.4-3.3-8.4c-1.1-5.4-2.4-10.7-3.6-16.1
			c-0.4-1.9-0.6-3.9-1-6.2c2.1,1.1,4,2.1,5.8,3.1c1.8,0.9,3.5,1.9,5.3,2.8c9.4,5,18.7,10,28.1,15.1c1.4,0.7,2.8,1.3,4.5,2.1
			c-0.2-1-0.3-1.6-0.4-2.1c-1.5-6.1-3.1-12.2-4.5-18.3c-0.3-1.2-0.9-1.8-2.1-2c-3.9-0.6-7.8-1.3-11.7-2c-7.7-1.3-15.4-2.6-23.1-3.8
			c-1.6-0.3-2.7-0.9-2.9-2.7c0-0.3-0.1-0.6-0.3-0.8c-1.4-1.1-1.4-2.8-1.7-4.3c-2-9.1-4.2-18.1-5.9-27.3c-0.5-2.9-1.9-3.9-4.4-4.7
			c-9.2-2.8-18.3-5.8-27.4-8.8c-5.1-1.7-10.2-3.5-15.3-5.3c-4.5-1.6-8.9-3.5-13.3-5.3c-5.2-2.1-10.5-4.3-15.6-6.5
			c-2.3-1-4.5-2.1-6.7-3.2c-4.3-2.1-8.7-4.2-13-6.3c-1.1-0.5-2.1-1.3-3.3-1.8c-7.6-3.5-14.4-8.5-21.1-13.5
			c-5.8-4.4-10.9-9.5-14.8-15.7c-2.7-4.3-5-8.7-7-13.3c-1.6-3.8-2.5-7.8-3.1-11.8c-0.6-4.6,0.5-8.9,2.5-13c3.4-7.3,8.5-13.4,14-19.1
			c5.5-5.8,11.7-10.8,18.2-15.4c6.1-4.3,12.5-8.1,19-11.8c8.3-4.9,17.1-8.9,26-12.7c6.8-2.9,13.6-5.7,20.6-8
			c3.3-1.1,6.6-2.3,9.9-3.5c0.6-0.2,1.2-0.7,1.8-1c0.2-0.1,0.6,0.1,0.9,0.3c-9.3,4.2-18.7,8.2-27.3,13.7c6.9-2.3,13.6-5.3,20.6-7.2
			c0.1,0.2,0.1,0.3,0.2,0.5c-1,0.6-2,1.2-3,1.7c-7.7,3.7-15.2,7.6-22.4,12.2c-6.6,4.3-12.9,9-18.4,14.7C58,40.9,54,46.3,51.8,52.8
			c-2,5.7-2.2,11.5-0.3,17.3c0.2,0.7,0.5,1.3,0.8,1.9c1.9,4.1,4.7,7.5,7.6,10.7c2.2,2.5,4.9,4.6,7.4,6.8c6.1,5.3,13,9.5,19.9,13.6
			c3,1.8,6,3.6,9.1,5.3c2.6,1.4,5.4,2.6,8.1,3.9c2.8,1.3,5.6,2.6,8.5,3.8c5.2,2.2,10.4,4.2,15.7,6.3c1.2,0.5,2.2,0.4,3.4,0
			c3.5-1.4,7.1-2.6,10.7-4c1.1-0.4,1.9-0.4,2.8,0.4c1.8,1.7,3.8,3.2,5.6,4.9c1.2,1.1,2.3,1.2,3.8,0.8c5.3-1.7,10.5-3.4,15.8-5
			c9.2-2.9,18.4-5.9,27.6-8.8c5.4-1.7,10.8-3.5,16.1-5.3c5.5-1.8,11.1-3.5,16.6-5.3c1.1-0.3,1.9-0.2,2.8,0.6
			c2.1,1.7,4.3,3.2,6.6,4.9c2.3-1.7,4.6-3.2,6.7-4.9c1-0.8,1.7-0.8,2.8-0.4c8.9,3.1,17.8,6.1,26.8,9.1c8.9,3,17.8,5.9,26.7,8.9
			c6.2,2.1,12.3,4.2,18.4,6.4c1.4,0.5,2.4,0.5,3.5-0.6c1.9-1.9,4.1-3.5,6.1-5.3c0.8-0.7,1.5-0.6,2.3-0.3c3.7,1.4,7.4,2.7,11.1,4.1
			c1.1,0.4,2.1,0.5,3.2,0c5-2.1,10.1-4.1,15-6.3c5.1-2.3,10.2-4.9,15.2-7.5c2.1-1.1,4.1-2.5,6.2-3.7c2.4-1.4,4.9-2.6,7.2-4
			c5.7-3.7,11.3-7.4,16.3-12c2-1.8,3.9-3.7,5.8-5.5c0.3-0.3,0.4-0.8,0.6-1.2c0.1-0.2,0.1-0.4,0.2-0.5c2.4-1.8,4.8-4.7,6.3-7.5
			c0.8-1.5,1.9-2.9,2.5-4.5c0.8-2.1,1.3-4.2,1.7-6.4c0.9-4.3,0.4-8.6-0.7-12.8c-0.9-3.2-2.4-6.2-4.6-8.8c-2.6-3.1-5.3-6-8-8.9
			c-5-5.3-11.2-9.2-17.3-13.1c-6.8-4.3-13.9-8-21.1-11.5c-0.8-0.4-1.5-0.8-2.2-1.5c2.9,0.2,5.6,1.4,8.5,2.1
			c-1.6-1.4-3.6-2.2-5.5-3.1c-1.9-0.9-3.8-1.9-5.8-2.8c-2-0.9-4-1.7-6-2.9c0.9,0.2,1.8,0.4,2.7,0.7c6.9,2.5,13.9,4.9,20.8,7.6
			c4,1.6,7.9,3.5,11.9,5.2c0.9,0.4,1.9,0.5,2.7,0.9c6,3,12,6.1,18,9.2c1.5,0.8,3,1.7,4.4,2.6c2.4,1.4,5,2.6,7.3,4.2
			c3.3,2.2,6.4,4.7,9.6,7.1c2.3,1.8,4.7,3.6,6.9,5.5c2.7,2.4,5.3,5,7.9,7.5c0.5,0.5,1.1,0.9,1.9,1.5c-0.1,1.6,1.8,2.3,2.7,3.7
			c1,1.6,2.4,3,3.4,4.6c3.1,4.7,5.7,9.6,7.7,14.9c2.3,6,2.4,12.1,1.4,18.4c-1.6,10.1-5.7,18.9-12.4,26.5c-4.1,4.6-8.8,8.6-13.9,12.1
			c-5.8,3.9-11.9,7.2-18.2,10.4c-9.3,4.7-18.7,9-28.3,12.9c-8.7,3.5-17.5,6.9-26.3,10.1c-9,3.2-18.1,6.1-27.1,9.1
			c-2.8,0.9-5.5,1.7-8.3,2.5c-1,0.3-2,0.4-3.1,0.4c-12.8,0.1-25.6,0.2-38.4,0.3c-6.4,0-12.7,0-19.1,0c-2.7,0-3,0.3-3.7,2.9
			c-3.1,13.3-6.2,26.7-9.3,40c-2.4,10.2-4.8,20.4-7.2,30.7c-4.1,17.7-8.2,35.4-12.4,53.1c-1.6,6.7-3.1,13.3-4.7,20
			c-2.1,8.8-4.2,17.6-6.2,26.4c-0.4,1.7-1,3.3-2.1,4.7c-0.7,0.8-1.2,1.8-1.9,2.7c-0.9,1.3-1.3,1.5-2.8,1c-1.5-0.5-2.9-1.2-4.4-1.7
			c-1.8-0.6-3.7-1.2-5.5-1.8c-5.1-1.7-10.2-3.4-15.4-5.1c-1.6-0.5-1.8-0.8-1.6-2.6C199.3,347.9,199.3,347.6,199.4,347.2z
			 M23.4,107.4c-0.4,0.4-1,0.7-0.7,1.4C23.8,108.3,23.8,108.3,23.4,107.4c0.1-0.5,0.3-1,0.4-1.5c0-0.3-0.2-0.6-0.4-0.8
			c-0.3,0.2-0.7,0.2-0.8,0.5c-0.2,0.5-0.2,1.1-0.3,1.8C22.7,107.3,23.1,107.4,23.4,107.4z M66.1,96.2c-0.6,0.2-0.9,0.1-1.7-0.5
			c0.4-0.5,1.2-1,1.1-1.4c-0.2-1.3,0.9-1.4,1.5-1.9c1.1-0.8,1.2-1,0.3-1.9c-1.1-1-2.3-2-3.4-3c-0.5-0.4-0.9-0.5-1.5-0.2
			c-0.1,0.1-0.3-0.1-0.5-0.2c0.2-0.3,0.4-0.6,0.5-0.9c-1.4-1.1-1.4-1.1-2.3-0.1l0.1-0.1c-0.5,0.1-1,0.1-1.7,0.2c0.6-0.8,1-1.4,1.4-2
			c-0.7-1-1.3-1.9-1.9-2.8c-1.2,0.6-1.5,2.2-3,1.6c-0.5-1.9,1.8-1.3,2.2-2.5c-0.5-0.6-1-1.3-1.6-2c-1,1.8-3.4,2.2-3.2,4.5
			c-0.6,0.1-1.1,0.2-1.6,0.3c-0.2,2.8-2.4,4-4.1,5.7c-0.7,0.8-1.6,1-2.7,0.7c-0.4-0.8-1.2-1.5-0.6-2.7c0.6,0,1.2-0.1,1.6-0.1
			c0.3,0.5,0.5,0.9,0.8,1.3c0.7-0.8,1.7-1.4,1.8-2.2c0.1-0.8-0.5-1.7-0.9-2.7c0.6-0.1,1.1,0,1.7-0.2c0.5-0.1,1.1-0.2,1.5-0.5
			c1.8-1.2,2.4-3.5,4.1-4.9c0.2-0.2,0.2-1-0.1-1.4C53,75.1,52,73.7,51,72.1c-1.2,2.1-2.5,3.9-5,4.3c0.1,0.6,0.2,1,0.3,1.2
			c0.9,0.5,2-0.1,2.7,0.8c-1.3,0.8-2.5,0.2-3.9-0.2c0.3,0.5,0.4,0.7,0.6,1c-2.3,0.8-4,2.3-4.9,4.5c-0.9,2.1-2.6,3.4-4.5,4.8
			c-0.1-0.9-0.3-1.7-0.4-2.7c0.3,0.5,0.5,0.7,0.8,1.2c1.2-1.7,2.1-3.2,3.3-4.4c1.3-1.3,1.6-3.5,3.6-4.4c-0.2-0.2-0.3-0.4-0.4-0.6
			c0.5-0.4,1.2-0.6,1.5-1.1c0.6-1.4,1.7-1.9,2.4-1.9c1.2-1.2,2.1-2,2.8-2.9c0.3-0.4,0.6-1,0.5-1.4c-0.1-0.5-0.6-1-1.1-1.3
			c-0.3-0.2-0.9-0.2-1.3-0.2c-0.5-1.3,0.4-1.5,1-1.7c-0.4-2.2-0.8-4.2-1-6.2c-0.6-7,1.4-13.4,5-19.3c4.9-8,11.7-14.1,19.1-19.6
			c6.7-5,13.9-9.2,21.3-13.1c0.6-0.3,1.3-0.7,1.9-1c-0.5-0.2-0.9-0.1-1.2,0c-4.4,1.7-8.7,3.4-13,5.2c-9.7,4.1-19.1,8.6-28.2,13.8
			c-9.7,5.6-19,11.7-27.6,19c-6.2,5.3-12,11-16.7,17.7C6.1,66.8,3.6,70,2.9,74.1c-0.1,0.3-0.3,0.6-0.6,0.8c-0.9,0.7-0.4,1.4,0,1.9
			c-1.5,3-1.9,5.9-1.3,9.1c0.6-0.4,1.2-0.8,1.8-1.2c0.2,0.4,0.3,0.7,0.5,1C3,86,2.6,86.2,2.2,86.3C2.3,86.9,3,87.4,2,87.9
			c-0.2,0.1-0.2,0.7-0.3,1.3c2.4-1.4,4.5-2.6,6.5-3.9c1.4-0.9,3-1.1,4.6-1.5c2.1-0.5,4.4,0,6.2-1.5c1.5,1.5,2.6,1.5,3.7-0.1
			c0.4-0.6,0.5-1.4,0.8-2l0,0c0.2,0.5,0.4,1,0.7,1.3c0.2,0.3,0.7,0.4,1.2,0.6c-0.5,1.3,1,1.7,1.4,2.6c0.8-0.2,1.6-0.4,2.6-0.7
			c0.1-0.3,0.3-0.8,0.5-1.6c0.4,0.5,0.8,0.9,0.8,1.1c-0.4,1.2-0.3,2.8-2.2,2.7c-0.5-0.3-0.8-1.3-1.7-0.7c0,0.3,0,0.6,0.1,1
			c-1-0.4-1.9-0.9-2.8-0.4c-0.3,2.9-0.6,5.7-1,8.7c-0.5-0.4-0.8-0.6-1-0.7c0.9-0.9,0.4-1.9,0.1-3.1c-0.2,0.5-0.3,1-0.5,1.5
			c-0.5-0.6-0.7-0.7-0.7-0.9c0-0.2,0-0.5,0.1-0.6c1.2-1.4,1.7-2.9,1.4-4.8c-1.1-0.6-2.2-1.5-3.3-1.7c-1.6-0.2-3.2,0.1-4.8,0.5
			c-1.9,0.5-3.7,1.2-5.5,1.9c-0.7,0.3-1.6,0.6-2,1.2C6.4,88.7,6.1,89,5.5,89c-1.8,0.2-2.6,1.4-2.8,3c-0.1,1.3,0.2,2.6,1.2,3.3
			C4.7,96,5.8,96,6.8,95.3c0.3-0.2,0.6-0.2,1-0.3c1.1-0.1,1.9-0.5,2.5-1.5c0.6-1,2.5-1.1,3.6-0.5c-0.3,0.2-0.8,0.3-0.8,0.5
			c-0.2,0.9-0.5,1.5-1.5,1.6c-0.2,0-0.3,0.6-0.6,0.8c-0.4,0.3-0.9,0.6-1.4,0.7c-1.8,0.3-3,1.4-4.1,2.9c0.5,0.5,1,0.8,1.6,1.4
			c0-0.9,0-1.4,0-1.9c0.6,2.1,2.7,2.4,3.9,3.9c1-1,1.7-2.2,2.8-2.7c1.6-0.7,3.4-1.3,5-1.1c1.6,0.2,2.9-0.3,4.6-0.3
			c-0.3-0.9-0.5-1.6-0.8-2.5c2.1-0.6,0.8-2.5,1.4-3.9c0.4,0,0.9,0.1,1.2,0.1c0.5-1.3,0.9-2.4,1.3-3.6c1.6,1.2,0.4,2.2,0.2,3.2
			c1.5,0.6,1.9,1.7,1.5,3.3c-1.4,0.3-2.2,1.3-2.6,2.6c3.3-1.6,5.7-4.3,8.2-6.9c-0.1-0.5-0.1-1.1-0.3-1.5c-0.2-0.4-0.6-0.7-1-1.1
			c0.6-0.2,1.1-0.5,2-0.9c-0.6,1.9,1.1,1.1,1.7,1.8c-0.6,0.8-1.3,1.5-1.7,2.4c-0.9,1.9-2.6,3-4.1,4.2c-0.9,0.7-1.6,1.7-1.5,2.4
			c0.1,0.7-0.5,1.4,0,2.1c1.2,0.5,1.3-1,2.1-1.3c0.2,0.2,0.3,0.4,0.5,0.6l-0.1-0.1c-0.1,0.5-0.2,1.1-0.4,1.6c-0.9,0-1.6,0-2.4,0
			c0.1,0.4,0.2,0.6,0.2,0.8c0.4,1.3,1.1,2.5,0.8,3.9l0.1-0.1c-0.2-0.1-0.5-0.3-0.8-0.5c-0.6,0.7-1.2,1.6-2.3,0.7
			c-0.6,1.7,0.8,1.3,1.4,1.6c0.4,0.8,0.1,2.1,1.6,2.2c0.4-0.8,0.4-2.1,1.8-1.8c-0.5,1.3,0.8,1.1,1,1.4c0.6-1.1,1.1-2.3,1.7-3.5
			c0.3,0.4,0.5,0.7,0.7,0.9c0.3-0.4,0.5-0.6,0.7-0.9c0.2,0.5,0.4,1,0.6,1.4c-0.9,0.3-1.7,0.7-2.6,1c0.6,2-1.1,2.2-2.2,2.9
			c-1.1,0.6-1.7-0.2-2.6-0.9c0.1,0.9,0.1,1.8,0.2,2.6c-0.6-0.2-1.1-0.3-1.6-0.5c0.2,1-1,0.6-1.3,1.2c0.9,0.6,1.9,1.2,2.7,1.8
			c0.8,0.6,1.8,1.2,2.2,2c0.8,1.7,2.4,2.6,3.5,4c1.1-0.6,2.1-1.2,3.1-1.8c0.4,0.7,0.6,1.2,1.1,2.2c0.3-1,0.5-1.6,0.8-2.3
			c0.7,0.2,1.5,0.2,1.9,0.6c0.8,0.8,1.1,0.4,1.6-0.3c0.1-0.1,0.3-0.1,0.7-0.1c-0.1,0.6-0.2,1-0.3,1.7c0.5-0.2,0.8-0.2,0.9-0.4
			c0.5-1,0.9-2.1,2.1-2.6c0.2-0.1,0.2-0.9,0.3-1.4c-1.6-0.5-1.4,0.9-2.2,1.6c-0.5-0.5-1-1-1.5-1.4c-0.3-0.3-0.8-0.9-1-0.8
			c-1.3,0.6-1.3-0.7-1.9-1.4c-0.6,0.2-1.2,0.5-1.8,0.7c-0.7,1,1.2,2.7-0.8,3.1c-1.2-1.8-1.2-1.8-0.6-3.2c-0.5-0.2-1-0.3-1.6-0.5
			c0.9-0.4,1.5-0.7,2.2-1c0.2,0.6,0.7,1.4,1.1,0.5c0.4-1,1.2-0.7,1.8-1c0.3-0.8,0.1-1.9,1.4-2c1.1,1.9,1.5,1.9,2.9,0.2
			c0.3-0.4,0.6-0.9,1.1-1.1c0.4-0.3,1-0.4,1.5-0.4c0.2,0,0.4,0.5,0.6,0.8c-1.5,1.1-2.9,2.1-4.4,3.1c0.3,0.3,0.6,0.6,1,0.9
			c-1,1-1.4-0.6-2.5-0.3c0.9,0.6,1.2,1.5,2.2,1.4c0.4,0,1-0.1,1.1-0.3c0.2-0.9,0.9-1.3,1.6-1.3c0.7,0,1.4,0.5,2.1,0.7
			c-0.2,0.5-0.3,0.8-0.4,1.2c0.5-0.1,0.7-0.4,1-0.6c1.7-1.4,2.5-1.2,3.1,0.9c0,0.1,0.2,0.1,0.4,0.1c0.4-0.6,0.7-1.4,1.1-2
			c0.5-0.7,0.8-1.8,2.3-1.7c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0.6-0.2,1.2-0.2,1.6c0.9-0.2,1.5-0.3,2.4-0.5c0.1-0.7,0.3-1.7,0.4-2.7
			c-1.5-0.9,0.7-1.8,0-2.6c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8,1-1.4,2-2.1,2.9c-0.2-0.1-0.3-0.1-0.5-0.2c0.2-0.9,0.2-1.8,0.7-2.5
			c1.2-1.9,1.4-3.9,1.4-6c0-1.2,0.6-2.3,1-3.4c0.1-0.2,0.4-0.3,0.7-0.3c1.1-0.1,1.1-0.1,1-1c-0.1-0.8-0.2-1.6-0.3-2.1
			c1.1-0.6,1.8-1,2.7-1.4c0.3,0.5,0.4,1.4,1.4,1.3c0.2-0.7,0.3-1.3,0.5-2c0,1,0,2,0,2.9C64.9,97.8,66.5,97.6,66.1,96.2
			c1.2-0.5,1.6-1.5,1.6-2.9C66.5,94.1,66.1,95.1,66.1,96.2z M217.4,347.5c-0.2-0.1-0.4-0.2-1-0.5c0.3,0.9,0.5,1.4,0.6,1.8
			c-0.5,0.4-0.9,0.8-1.3,1.1c2.2,2.1,2.2,2.1,2.9,2c-0.4-0.8-0.7-1.5-1.2-2.5c0.8,0,1.4,0,2.2,0C218.7,348.6,218,348,217.4,347.5
			c1.5,0.3,0.5-1.2,1.3-1.4c-0.6-0.5-1-0.8-1.3-1.1C217.4,345.8,217.4,346.7,217.4,347.5z M16.2,104.9c0.4-0.6,0.8-1.3,1.1-1.9
			c-0.5-0.6-0.9-1.2-1.4-1.8c-1.8-0.1-2.4,1.4-3.5,2.4C13.6,104.6,14.7,105.3,16.2,104.9c0.1,0.6,0.2,1.3,0.3,1.9
			c1.3,0.1,2.5,0.2,3.6,0.3c0.1-0.2,0.1-0.4,0.3-0.5c1.4-1,1-3.2,2.7-3.9c0.1,0,0.2-0.4,0.1-0.6c-0.6-0.9,0.3-1.6,0.2-2.8
			c-2.1,1.5-4,2.9-4.3,5.5C18,104.8,17.1,104.9,16.2,104.9z M418.1,34.5c-0.8-0.4-0.8,0.3-1.2,0.8c0.9,0.4,1.6,0.5,1.8,1.8
			c0.2,1.2,1.4,2.2,2.2,3.3c1.9-1.1,2-1.4,0.5-2.4c0.9-0.6,0.9-0.6,0.7-1.7c1.4,1.8,1.9,4,2,6.2c-0.5,0.3-1.1,0.6-1.6,0.9
			c0.1-0.6,0.2-1.3,0.4-2.2c-0.6,0.3-0.9,0.4-1.2,0.5c0.3,0.6,0.6,1.1,0.9,1.7c0.7,1.2,1.4,2.4,2.1,3.6c-0.1,0.1-0.3,0.3-0.4,0.5
			c1.1,1.4,2.1,2.9,3.3,4.5c-0.8,0.1-1.5,0.1-2.1,0.2c0.2,1.4,0.5,2.7,0.7,3.9c0.6,0,1.1,0,1.6,0.1c-0.1,0.3-0.1,0.7-0.3,0.8
			c-1.6,1.3-1.6,1.7-0.7,3.7c0.3,0.7,0.6,1.6-0.2,2c-0.7,0.3-0.6,0.7-0.7,1.2c-0.2,0.8-0.4,1.6-0.6,2.4c-0.1,0.5-0.3,1.1-0.2,1.5
			c0.1,0.4,0.7,0.7,0.9,0.9c-0.4,0.3-0.8,0.6-1.3,0.8c-1.5,0.9-1.8,2.7-2.6,4.1c-1.6,2.6-3.1,5.1-5.2,7.4c-2.8,3-5.4,6.1-8.6,8.8
			c-7.7,6.5-16.1,12.1-24.9,17c-11.1,6.3-22.7,11.6-34.7,16.2c-1.6,0.6-3,0.6-4.5,0c-3.8-1.5-7.7-2.9-11.5-4.2
			c-2.3,2-4.5,3.7-6.6,5.6c-1.3,1.3-2.5,1.5-4.3,0.9c-7.5-2.6-15-5.1-22.5-7.7c-16.5-5.5-32.9-11.1-49.4-16.7
			c-1-0.3-1.7-0.2-2.4,0.4c-1.7,1.3-3.5,2.5-5.2,3.8c-1.9,1.3-1.9,1.3-3.8,0c-2.1-1.5-4.1-3-6.2-4.6c-0.4,0.1-0.8,0.2-1.2,0.3
			c-7.5,2.4-15.1,4.9-22.6,7.3c-14.2,4.5-28.4,9-42.6,13.6c-3.5,1.1-7.1,2.2-10.6,3.4c-1.8,0.6-3.3,0.5-4.7-0.9
			c-1-1-2.2-1.9-3.3-2.8c-1.1-0.9-2.2-1.8-3.3-2.7c-4.1,1.5-8,3-11.8,4.4c-1.1,0.4-2.2,0.6-3.4,0.1c-6.5-2.7-13-5.3-19.5-8
			c-0.4-0.2-0.8-0.4-1.2-0.4c-1.5-0.1-2.8-0.6-4-1.4c-0.1-0.1-0.4,0.2-0.6,0.3l0,0c-0.7-1.7-0.7-1.7-2.6-1.7
			c0.3,1.3-0.8,1.5-1.7,1.6c0,0.6,0,1,0,1.5c-0.7-0.4-1.2-0.7-1.7-1c0.4-0.4,0.9-0.7,1.2-1.2c0.7-0.9,0.4-2.5-0.5-3
			c-1.2-0.6-2.4-1.1-3.9-0.6c0.1-0.4,0.3-0.7,0.4-1.2c-2.7-1.4-5.4-2.6-8-4.1c-2.6-1.5-5-3.3-7.6-4.9c-2.5-1.7-5-3.4-7.5-5.1
			c-0.8-0.1-1.3,1.2-2.2,0.4c-0.1-0.1-0.7,0.5-1,0.8c-0.3,0.4-0.4,0.9-0.6,1.4c-0.5,1.1-1.3,2.2-1.4,3.3c0,2.5-2.4,4.2-1.9,6.7
			c0,0.2-0.3,0.4-0.4,0.7c-1.2,1.6-1.4,3.3-1,5.2c0.1,0.3,0.1,0.7,0,0.9c-0.8,1.2-0.4,2.6-0.7,3.8c0.7,0.6,1.2,1.2,1.9,1.7
			c0.3-1.6,1.5-2.1,3.8-1.8c1.2-0.7,2-1.3,2.9-1.8c0-0.1-0.1-0.2-0.1-0.4c-1,0.1-2.1,0.2-3.2,0.3c0-0.5,0-1.1,0-1.7
			c0.1-0.1,0.2-0.1,0.4-0.2c0.2,0.3,0.3,0.6,0.4,0.7c1-0.3,2-0.6,3-0.9c0.2-1.4-1.2-1.3-2.1-2c1.1,0,1.8,0,2.6,0
			c0.1,0.5,0.3,1,0.4,1.4c1.2,0.5,1.9-0.3,2.6-0.9c0.7-0.6,1.1-0.8,1.8-0.1c-1,0.9-1.8,1.8-2.8,2.7c0.5,0.5,1,0.9,1.5,1.4
			c0.3-0.7,0.4-1.3,0.8-1.7c0.4-0.4,1-0.6,1.5-0.9c0.1,0.3,0.2,0.6,0.3,0.9c1-0.6,1-0.6,2.1-2.6c-0.3-0.2-0.5-0.4-0.9-0.7
			c0.7,0,1.2,0,1.8,0c-0.1,0.5-0.2,0.9-0.3,1.3c0.7,0,1.3-0.1,1.9-0.1c-0.2,1.8-0.6,2.6-1.2,2.4c-2.1-0.5-2.5,1.3-3.5,2.4
			c-0.3,0.3-0.6,0.7-1,1c-1.1,0.7-1.1,0.9-0.2,1.8c0.3,0.3,0.6,1.1,0.5,1.1c-0.9,0.7,0.1,1.3,0,2c-0.2,0-0.4,0.1-0.6,0.1
			c-1.6,0-3.2,0-4.7,0c-0.4,0.9-0.8,1.6-1.2,2.3c0.4,0.5,0.6,1,0.6,1.5c0,0.6-0.4,1.4-0.2,1.8c0.5,0.8,0.4,1.6,0.4,2.5
			c0,1,0.6,1,1.7,0.3c-1.2-1.6-0.1-2.8,0.8-4.1c0.8-0.1,1.6-0.2,2.4-0.2c0.3,0.5,0,1.6,1.2,1.3c0.7-1.2,0.2-2.9,1.2-3.9
			c1,0.8,1.9,1.4,2.6,1.9c-1.3,1.7-3.6,2.7-4.1,5.4c1.2,0,2.1,0,3,0c0.9,0,2-0.2,2.2,1.3c-1.4,0.3-2.6,0.5-4-0.3
			c-0.6,0.7-1.6,1.3-1.9,2.1c-0.5,1.2-1.9,0.9-2.5,1.9c-0.1,0.1-0.1,0.2-0.1,0.5c0.7,0.2,1.4,0.5,2.2,0.8c-0.2,0.8-0.4,1.6-0.5,2.4
			c-0.8,0.4-2.3,0-1.6,1.8c1.3-0.3,2.3,1.5,3.8,0.6c0.3-0.6,0.1-0.9-0.5-1.2c-1.3-0.7-1.2-0.8-0.5-2.2c0.5,0.3,0.9,0.7,1.8,1.3
			c-0.5-1.7-0.8-3-1.2-4.4c1.3-0.4,2.7,0.2,3.5-1c0.1-0.2,0.9,0,1.4,0c0.4,0.1,0.7,0.2,1.1,0.4c1-1.3,0.4-2.4-0.4-3.4
			c0.2-0.2,0.5-0.4,0.5-0.6c0.3-1.8,1.2-2.6,3-2.4c0.3,0,0.7-0.3,1-0.5c-0.3-0.5-0.6-1-0.9-1.5c0.8-0.5,1.5-0.9,2.3-1.4
			c-0.4-0.9-0.7-1.8-1-2.6l0,0c1,0.1,2.1,0.2,3.1,0.3c0.2-0.3,0.3-1,0.7-1.1c1.3-0.4,1-1.4,1-2.3c0.7-0.1,1.3-0.2,1.9-0.2
			c0.1-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.5-0.5,0.6-0.7c0.4-1.3,0.3-1.3,1.9-1.1c-0.2,0.7-0.2,1.4-0.6,1.9c-1,1.2-1.8,2.4-2.6,3.7
			c-0.5,0.9-1.1,1.6-1.2,2.8c-0.1,1.2-0.6,1.5-1.8,1.4c-0.3,0-0.6-0.1-1-0.2c0,0.9,0.1,1.6,0.1,2.2c0.4,0.2,0.8,0.4,1.4,0.7
			c-4.8,0-5.9,2.2-6.4,5c0.9,0.1,1.7,0.2,2-0.9c0.4-1,1.1-1.1,2-1c-1.5,1.1-2.2,2.9-3.8,4c-1.2,0.8-2.1,2.1-3.8,2
			c0.4,1.1,0.2,1.8-0.6,2c-0.3-0.5-0.2-1.4-1.2-0.8c0,0.3,0.1,0.7-0.1,1c-0.6,1.3-1.3,2.7-2,4c-1.4-0.1-1.8,1.2-2.6,1.9
			c-0.7,0.5-1.7,1.1-1.2,2.2c0.4,1,1.5,0.8,2.4,0.6c0.1,0,0.3,0,0.4,0.1c0.5,0.7,1.1,1.3,1.8,1.6c0.3,0.1,0.9-0.4,1.4-0.6
			c0.8-0.4,1.4-0.2,1.9,0.6c-0.3,0.2-0.6,0.5-1.1,0.8c0.7,0.3,1.2,0.6,1.8,0.8c0.1-1.2,0.9-1.4,1.6-1.7c0,0,0.1,0.1,0.2,0.3
			c-0.2,0.4-0.4,0.9-0.6,1.5c1.8,1.5,4,1.9,6,2.7c0.3,0.1,0.8-0.3,1.2-0.5c0.4,1.4,1.1,1.8,2.2,1.3c0.6-0.3,1-0.8,1.6-1.1
			c0.8-0.5,1.2-1.5,2.3-1.2c0.1-1.9,1.3-2.8,3-2.3c-1.6,2.1-3.1,4.1-4.7,6.1c1.9,0.7,3.8,1.5,5.7,2.2c4.7,1.7,9.4,3.4,14.2,5
			c5,1.7,10.1,3.3,15.2,4.9c1,0.3,2.1,0.3,3.3,0.4c0.4,0.3,1,0.9,1.7,1.2c2.2,0.7,4.5,1.4,6.8,1.9c1.5,0.3,2.1,1.2,2.4,2.6
			c1.8,8.6,3.6,17.3,5.5,25.9c0.7,3.3,1.4,6.6,2.2,9.8c0.3,0.1,0.5,0.2,0.7,0.2c4.5,0.8,9,1.5,13.4,2.3c5.4,0.9,10.9,1.8,16.3,2.7
			c2.4,0.4,4.9,0.9,7.3,1.3c1.1,0.2,1.8,0.7,2.1,1.9c0.5,1.9,1.2,3.7,1.5,5.7c0.3,2.1,1.5,4.1,1.8,6.3c0.3,2.7,1.2,5.2,1.8,7.8
			c0.3,1.4,0.7,2.8,1,4.2c0.3,1.3-0.4,1.8-1.6,1.4c-0.5-0.2-0.9-0.4-1.3-0.6c-1.2-0.6-2.4-1.3-3.6-2c-1.3-0.7-2.5-1.4-3.8-2.1
			c-1.2-0.6-2.5-1.3-3.7-1.9c-1.3-0.7-2.5-1.6-3.8-2c-1.3-0.4-2.3-1.3-3.4-1.9c-6-3.2-12-6.4-18.1-9.6c-1.2-0.7-2.5-1.3-4-2.1
			c0.1,1,0.2,1.6,0.3,2.2c1.3,6,2.7,12,3.8,18c0.5,2.8,1.6,5.3,3.2,7.5c0.2,0.2,0.3,0.5,0.4,0.7c10,16.8,20,33.6,30,50.3
			c0.2,0.3,0.5,0.5,0.8,0.9c0.2-0.4,0.4-0.7,0.4-0.9c0.2-3.1,0.4-6.3,0.6-9.4c0-0.6,0.4-1.1,0.4-1.7c0.1-0.9,0-1.8,0-2.8
			c0-0.4-0.2-0.7-0.1-1.1c0.8-3.4,0.2-6.8,0.3-10.2c0-0.1,0-0.2-0.1-0.2c-0.8-0.7-0.6-1.7-0.6-2.5c0-3.1,0-6.3,0-9.4
			c0-0.4,0.1-0.9,0.2-1.4c0.3,0.2,0.6,0.3,0.7,0.4c0.1,1,0.3,2,0.2,3c0,0.9,0,2.6,0,3.6c0,2,0.1,4.1,0.3,6.1
			c0.1,1.4-0.6,3.3,1.3,4.4c0.2,0.1,0.3,0.8,0.2,1.1c-0.7,2.3-0.5,4.6-0.5,6.9c0,0.4,0.1,1-0.1,1.2c-0.9,1.1-0.6,2.4-0.6,3.6
			c0.1,3.1-0.1,6.3-1.1,9.2c-0.6,1.6-0.3,2.8,0.4,4c0.9,1.4,2.3,1.3,3.2-0.2c0.3-0.5,0.7-1,1-1.6c0.1,0,0.2,0.1,0.4,0.1
			c0,0.5-0.1,0.9-0.1,1.6c0.7,0,1.2,0,1.8,0c0.6,0,1.2,0.1,2.2,0.1c-0.7,0.5-0.9,0.8-1.3,0.9c-0.7,0.2-1.4,0.5-2.1,0.6
			c-1.1,0.1-1.7,0.4-2,1.6c-0.3,1-0.3,1.8,0.3,2.6c0.5,0.6,0.8,1.3,1.2,1.8c0.5-0.1,0.8-0.1,1.7-0.3c-1.1,0.9-1,1.6-0.6,2.3
			c0.9,1.5,1.7,3,2.7,4.4c0.8,1.2,0.9,2.2,0.4,3.5c-0.4,1.2-0.7,2.5-1.1,3.8c-0.4,1.3-0.8,2.7-1.1,4c-0.3,1.3-0.7,2.5-1,3.8
			c-0.4,1.3-1.1,2.7-1,4c0,1.4-1,2.2-1.1,3.4c-0.1,1.1-0.5,2.2-0.8,3.4c1.9,0.7,3.6,1.3,5.3,1.8c1.1,0.4,2.2,1.1,3.4,0.1
			c0.2-0.1,0.5,0,0.8,0.1c0.1,0.5,0.2,0.9,0.3,1.5c0.9-0.1,1.7,1.2,2.6-0.1c-0.3-0.4-0.6-0.8-0.9-1.1c0.1-0.1,0.2-0.3,0.2-0.4
			c0.6,0.2,1.3,0.3,1.8,0.6c0.2,0.2,0.1,0.9,0.1,1.4c0.6,0,1.2-0.1,2.1-0.1c-0.3-0.8-0.6-1.4-0.7-2.1c-0.1-0.6,0.4-1.4,0.2-2
			c-0.3-0.6-0.2-0.9,0.2-1.4c0.2-0.3-0.3-1.1-0.1-1.4c0.7-1.1,0-1.6-0.7-2.3c-0.8-0.7-1.6-1.5-2.4-2.3c3.1-4.2,6.1-8.2,9.2-12.2
			c0.4-0.6,1.3-0.8,1.9-1.2c0.2,2.1,0.4,4,0.6,5.9c-0.1,0-0.4,0-0.7,0.1c-0.5,1.3,0.2,2.2,1.2,3.1c-0.5,0.4-0.9,0.7-1.3,1
			c0.1,0,0.2,0.1,0.4,0.1c-0.1,1.2-0.3,2.4-0.5,3.6c-0.5,0-0.9-0.1-1.4-0.1c0.4,1.3-0.5,2.8,1.2,3.6c-0.5,0.3-0.9,0.5-1.2,0.6
			c0.5,0.8,1,1.4,1.3,2.1c0.4,0.8,0.6,1.6,0.9,2.3c0.7,0.2,1.2,0.3,1.7,0.5c-0.6,0.8-1.2,1.5-1.7,2.2c-1.2-0.1,0.3-1.3-0.6-1.7
			c-1.5,0.6-0.7,1.9-0.8,2.9c0.8,0.2,1.4,0.4,2.1,0.6c-0.1,0.6-0.1,1-0.2,1.5c-0.5,0.6-0.5,0.8,0.1,1.1c-0.1-0.4-0.1-0.8-0.1-1.2
			c1,0.1,2,0.2,3.3,0.3c-0.6,0.7-0.9,1.1-1.2,1.5c0.6,0.5,1.2,0.8,1.8,0.1c0.5-0.7,1.3-1.4,1-2.3c-0.2-0.4-0.6-0.8-0.9-1.2
			c1-0.2,0.2-0.8,0.3-1.3c1.6-0.5,2.4-1.7,2.4-3.4l-0.1,0c1.2,0.6,0.4,1.8,1,2.9c1.5-4.9,2.3-9.6,3.2-14.4c-0.5-0.1-1-0.3-1.9-0.6
			c0.5-0.4,0.8-0.8,1-0.8c1.1,0.1,1.6-0.6,1.7-1.4c0.4-2,1.5-3.9,0.9-6c0-0.2,0-0.4,0.1-0.6c1.1-1.7,1.5-3.7,1.9-5.7
			c1.1-4.7,2.2-9.5,3.3-14.2c0.8-3.7,1.7-7.4,2.6-11.1c3.6-15.2,7.1-30.5,10.7-45.7c2.2-9.3,4.4-18.6,6.6-28
			c1.7-7.5,3.4-15,5.2-22.5c1.7-7.5,3.5-15,5.3-22.6c0.5-2.3,0.7-2.5,3-2.5c5,0,10,0,14.9,0c5,0,10.1-0.1,15.1,0
			c4.9,0.2,9.8-0.7,14.8-0.4c4.5,0.3,9,0.1,13.5,0c1.5,0,3-0.2,4.4-0.7c11.1-3.6,22.2-7.1,33.2-10.9c12.4-4.2,24.6-9.1,36.6-14.4
			c6.4-2.8,12.5-6.1,18.9-9c6.2-2.8,12.2-6.2,17.8-10c8.2-5.6,15.3-12.2,20.3-20.9c1.8-3,3.2-6.2,4.1-9.6c-0.5-0.2-0.8-0.3-1.1-0.4
			c-0.1-0.1-0.2-0.3-0.3-0.4c2.1-0.5,2.4-2.1,2.7-3.7c0.2-1.2,0.5-2.4,0.7-3.7c0.2-1.1,0.3-2.2,0.2-3.3c0-0.5-0.5-0.9-0.7-1.3
			c0.3-0.2,0.9-0.4,0.9-0.7c0.2-1.3,0.5-2.6-0.5-3.7l0,0c0-1.2,0.3-2.5,0-3.6c-0.6-2.3-1.3-4.7-2.4-6.8c-1.3-2.6-3.1-5-4.4-7.7
			c-1.8-3.7-4.8-6.4-7.1-9.6c-1.3-1.9-3.3-3.3-5-4.9c-0.5-0.5-1.1-1-1.8-1.6c-0.4,1.4-1.4,2.1-1.7,3c-0.6,1.9-2.9,2.8-3,4.9
			c0,0.2-0.5,0.3-0.8,0.4c-0.1-0.3-0.5-0.8-0.4-1c0.5-0.8,1.1-1.5,1.6-2.2c0.2-0.3,0.6-0.5,0.7-0.9c0.3-0.7,0.4-1.6,0.8-2.3
			c0.4-0.6,1.2-1,1.8-1.5c0.1-0.1,0.2-0.3,0.4-0.7c-1-0.6-1.9-1.2-2.9-1.8l0.1,0.1c-0.3-0.6-0.6-1.2-0.8-1.7
			c-0.9,0.2-1.5,0.3-2.1,0.5c-0.5,1.2-0.9,2.3,0.6,3l0,0c-1.3,0-0.9,1.8-2.3,2c0.2-1.3,0.3-2.5,0.5-3.9c-0.6,0.5-0.8,0.7-1.4,1.2
			c0.1-0.8,0.2-1.2,0.3-1.6c0.7-0.2,1.2-0.3,2.1-0.5c-0.7-0.5-1.1-0.8-1.8-1.3c0.6-0.5,1.1-0.9,1.7-1.3c-0.7-0.2-1.3-0.4-1.8-0.7
			c-0.1-0.1-0.1-0.5-0.2-0.7c-0.3,0.1-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.2-1.4-0.4c0.7-0.4,1.1-0.6,1.4-0.8c-2.4-1.8-4.7-3.4-7.1-5.2
			c-2,2.2-4.7,3.1-7.3,4c-0.1-0.4-0.3-0.8-0.5-1.3c-0.6,0.2-1.1,0.5-1.7,0.7c-0.4,0-0.7-0.1-1-0.1c0.6-1.1,1.1-2,1.8-3.1
			c0.8,0.4,1.6,0.8,2,1c2.1-0.5,4-1,5.8-1.5c0.1-0.2,0.1-0.3,0.2-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-2.1-1.3-4.1-2.7-6.4-3.5
			c-0.4-0.1-0.7-0.5-1.1-0.8c-1.1-0.9-2-2-3.8-2.1c0.2,1,0.4,1.8,0.6,2.6l0-0.1c-0.3,0.2-0.7,0.4-1.3,0.7c0-1.1,0-2,0-2.9
			c0-1-0.4-1.8-1.3-1.8c-0.9,0-1.2-0.7-1.8-1c-0.4-0.2-0.8-0.4-1.2-0.7c-0.2,0.6-0.3,1.1-0.4,1.3c-0.5-0.5-1.3-1-1.5-1.6
			c-0.5-1.9-2.1-1.9-3.5-2.4c-0.3-0.1-0.8,0-0.9-0.2c-0.8-1.7-2.1-1.3-3.2-0.9c-1-0.9-1.8-1.7-2.7-2.6c-0.5,0.7-0.9,1.1-1.2,1.7
			c0.8,0.8,1.5,1.6,2.4,2.4c-0.6,0.2-1,0.4-1.4,0.5c0.8,0.7,1.5,1.4,2.2,1.9c1.7,1.2,3.3,3,5.8,1.7c-0.2-0.5-0.4-1-0.6-1.4l0,0
			c0.7,0.2,1.3,0.4,2.3,0.7c-1.1,1.2-1.9,2-2.8,3c0.7,0.6,1.5,1.3,2.1,1.9c1.1,0,1.9,0,2.8,0c0.2-0.4,0.4-0.9,0.8-1.6
			c0.6,0.6,1,1.1,1.5,1.7c-1.2,0.7-2.2,1.2-3.5,1.9c1,0.9,1.8,1.6,2.6,2.3c0.4,0.4,0.9,1,1.4,1c0.4,0.1,0.9-0.6,1.5-1.1
			C417.8,33.3,417.9,33.9,418.1,34.5L418.1,34.5z M43.5,127.6c-1.1,0.7-1.8,1.9-3.3,2.1c-0.9,0.1-1.3,1.3-0.9,1.9
			c0.5,0.9,3.2,1.9,3.8,1.2c0.8-0.9,1.1-0.1,1.7,0c0.8-1.7,1.3-2.1,2.7-1.8c-1.4,1.3-1.4,2.8-0.5,4.4c0.8,0,1.7,0,2.5,0
			c0-0.3,0-0.6,0.1-0.8c0.3-0.5,0.6-0.9,1.2-1.8c-1.3,0.5-2.1,0.9-3,1.2c-0.5-1.7,1.1-1.6,1.5-2.4c-0.5-0.4-0.9-0.7-1.6-1.3
			c0.8-0.8,1.5-1.6,2.6-2.6c0.1-0.2,0.2-0.9,0.4-1.5c-1.5,0.2-2.9,0.5-4.3,0.7c-0.6,0.7,0.3,1.9-0.9,2.8c-0.1-0.7-0.2-1.1-0.2-1.6
			C44.6,128,44.1,127.8,43.5,127.6z M396.3,13.9c-2.1-1-4-1.9-5.9-2.7c-1.9-0.8-3.8-1.6-5.7-2.4c-1.9-0.7-3.7-1.7-5.8-2
			c1.5,1,3,1.9,4.6,2.8c1.5,0.8,3,1.4,4.5,2.3c1.5,0.8,2.9,1.8,4.4,2.8c0.5-0.4,0.9-0.7,1.5-1.3c-0.2,0.9-0.3,1.4-0.4,2
			c1,0.7,2,1.3,3,2c0.9,0.6,1.7,1.5,3.1,1.6c-0.6-1.3-1.8-2-1.6-3.5c0.8,0.1,1.5,0.2,2.2,0.3c0-0.1,0-0.2,0.1-0.3
			c-1.1-0.3-2.2-0.6-3.2-0.9c-0.4,0.3-0.8,0.6-1.5,1.2C395.9,14.9,396,14.5,396.3,13.9z M22.3,119.4c2-1.8,2-1.8,2-4
			c-0.1-2.1-1.8-3.3-3.7-2.5c-0.5,0.2-0.8,0.5-1.4,0.1c-0.4-0.3-1.1-0.1-1.7,0c-0.6,0.1-1.1,0.4-1.9,0.8c1.4,1.2,2.6,2.3,3.6,3.2
			c0.9-0.5,1.6-0.9,2.4-1.3c0.2-0.5,0.5-1,0.9-1.9c0.1,0.9,0.1,1.4,0.2,1.9c0.2,0.5,0.5,1,0.7,1.5c-0.9,0.4-1.6,0.8-2.5,1.2
			C21.5,119,21.8,119.2,22.3,119.4z M73.7,144.9c-1.2-1.1-1.2-1.1-1.5-3.4c-2-0.8-3.5,0.3-4.9,1.5c0,0.9-0.1,1.7-0.1,2.7
			c0.7,0.2,1.4,0.4,2.1,0.7c0.2-0.6,0.3-1,0.5-1.5c0.7,1.1,1.3,2,2.7,2.5C71.9,145.9,72.4,145.2,73.7,144.9z M18.8,110.1
			c-1.4-1.7-3.4-1.8-4.8-2.9c-0.4,0.4-0.8,0.7-1.1,1c-0.4,0.3-0.8,0.7-1.2,1c0.6,0.7,1.2,1.1,1.5,1.6c1,1.6,2.9,2,4.3,0.8
			C17.9,111.2,18.3,110.7,18.8,110.1z M30.6,122.4c-0.1-2-0.3-2.2-2.2-2.2c0.8-2.1-1.4-1.6-1.9-2.6c-0.3,1.6-0.6,3.1-0.9,4.3
			C27.3,122.1,28.9,122.2,30.6,122.4z M69.8,136.9c-0.9-1.4-0.9-1.4-2.1-0.6c0.6,0.5,1.2,1,1.7,1.5c0.9,0.9,2,0.8,2.8-0.1
			c0.3-0.3,0.8-0.5,1.2-0.8c0-0.6,0-1.3,0-2c-0.9,0.2-1.8,0.3-2.5,0.5C70.6,135.9,70.3,136.3,69.8,136.9z M53.9,129.6
			c-0.9-1.1,0.9-1.2,0.7-2c-0.7-0.9-1.5-1-2.9-0.5c0.8,0.3,1.2,0.4,1.6,0.5c-0.2,1-0.2,2-0.5,2.8c-0.3,1-0.8,1.8,0.5,3
			c0.1-2.3,1.8-2.8,3.3-3.6C55.6,128.1,54.7,129.8,53.9,129.6z M32.6,123.5c-0.7,1.5,1,0.9,1,1.9c-0.5,0.4-1.1,0.9-1.8,1.4
			c0.6,0.2,1,0.4,1.3,0.5c0.7-0.8,1.3-1.4,2-2.2C34.3,124.7,33.5,124.1,32.6,123.5z M38.6,123c0.1,0.2,0.1,0.4,0.2,0.5
			c1.1-0.4,2.2,0.4,3.7-0.9c-1.6,0.3-1.7-0.8-2.3-1.3C39.6,121.9,39.1,122.5,38.6,123z M64.6,124.9c-1.5,1.4-0.5,2.7-0.6,4
			c1.6-1,1.6-1,1-2.6C64.8,125.9,64.8,125.5,64.6,124.9z M9.5,105.1c0,1.3,0.4,2.2,1.4,2.4c0.5,0.1,1.4,0.1,1.5-1
			C11.5,106.1,10.6,105.6,9.5,105.1z M66.3,141.8c-1.4,0.7-2.3,1.1-3.3,1.6c0,0.1,0.1,0.2,0.1,0.4c0.8,0.2,1.5,0.4,2.4,0.6
			C65.8,143.6,66,142.9,66.3,141.8z M75.8,131.8c-1.8,1.4-1.8,1.4-1.4,2.8c0.7-0.4,1.3-0.9,2.1-1.4C76.2,132.8,76,132.3,75.8,131.8z
			 M75.4,131.3c0.5-0.3,1-0.6,1.5-0.9c-0.5-1.4-0.5-1.4-1.5-1.9C75.4,129.4,75.4,130.3,75.4,131.3z M51.4,126.2
			c0.7,0.5,1.1,0.3,1.7-0.3c0.5-0.6,0.6-1.1,0.2-1.8C52.6,124.9,52,125.6,51.4,126.2z M66,122.3c-0.3-0.8-0.5-1.5-0.8-2.3
			c-0.5,0.3-0.9,0.5-1.3,0.7C64.3,121.7,64.7,122.4,66,122.3z M27.7,98.6c-0.1,0-0.2,0-0.3,0c-0.4,0.6-0.8,1.3-1.2,2
			c0.6,0,1.1,0,1.6,0C27.7,99.8,27.7,99.2,27.7,98.6z M56.4,122.2c-1-0.9-1.5,0.5-2.2,0.3c0.3,0.7,0.6,1.2,0.7,1.6
			C55.4,123.4,55.9,122.8,56.4,122.2z M28.3,103.6c-0.6-0.1-1.2-0.3-2.1-0.5c0.8,1,1.3,1.7,1.8,2.4c0.1-0.1,0.2-0.1,0.3-0.2
			C28.3,104.8,28.3,104.3,28.3,103.6z M63.9,138.4c1.2,2.1,1.2,2.1,2.2,0.7C65.4,138.8,64.8,138.7,63.9,138.4z M51.9,118.2
			c0-0.2,0-0.3-0.1-0.5c-0.5,0-1-0.2-1.3,0c-0.2,0.1-0.2,0.8-0.4,1.4C50.9,118.6,51.4,118.4,51.9,118.2z M60.7,109.1
			c-1.3,1.2-1.3,1.2-0.6,3C60.3,111,60.5,110.1,60.7,109.1z M219.9,327.8c-0.4,1.3-0.6,2.6,0.3,3.8
			C220.1,330.3,220,329.1,219.9,327.8z M58.6,121.2c0-0.8-0.1-1.4-0.1-2C57.1,120.1,57.1,120.1,58.6,121.2z M53.6,135
			c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0.4-0.2,0.9-0.3,1.3c0,0,0.5,0.2,0.5,0.2C53.3,135.9,53.5,135.4,53.6,135z M63.9,134.3
			c0.3,0.8,0.7,1.2,1.4,0.8c0.1-0.1,0.2-0.4,0.2-0.5C65.2,133.7,64.6,134,63.9,134.3z M23.1,111.1c0.2-0.6,0.5-1.3-0.4-1.5
			c-0.1,0-0.5,0.1-0.5,0.2C21.9,110.6,22.4,110.9,23.1,111.1z M64.6,129.6c1.8,0.1,1.8,0.1,1.5-1.2C65.7,128.7,65.3,129,64.6,129.6z
			 M63.8,100.8c0.1-0.1,0.3-0.3,0.4-0.4c-0.3-0.2-0.5-0.4-0.8-0.6c-0.1,0.1-0.2,0.3-0.2,0.3C63.4,100.3,63.7,100.5,63.8,100.8z
			 M72.3,116.6c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.7-0.3,1.3-0.5,2.3C72.7,118,72.2,117.2,72.3,116.6z M19.2,112c0.5-0.1,1.6,1,1.7-0.7
			C20.2,111.6,19.7,111.8,19.2,112z M66.5,134.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2-0.3,0.4-0.6,0.6-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
			C67,133.8,66.7,134,66.5,134.3z M52.6,119.4c-1,0.4-1,0.9-0.3,1.4c0.1,0.1,0.2,0,0.5,0.1C52.7,120.4,52.7,120,52.6,119.4z
			 M69.8,94.1c0.2-0.5,0.4-0.8,0.7-1.3C69.6,93.2,69.6,93.2,69.8,94.1z M26,94.1c0.4,0.2,0.6,0.3,0.8,0.3c0.1,0,0.3-0.3,0.3-0.5
			c-0.1-0.2-0.3-0.3-0.5-0.5C26.4,93.6,26.3,93.8,26,94.1z M57.5,109.7c0,0.2,0,0.3,0,0.5c0.3,0,0.6,0,0.8-0.1c0-0.2,0-0.3,0-0.5
			C58.1,109.7,57.8,109.7,57.5,109.7z"/>
		<path d="M548.3,183.8c18.3,0,36.4,0,54.7,0c-0.2,1.1-0.4,2.1-0.6,3.2c0.3,0.2,0.6,0.3,0.9,0.4c0.9,0.3,1.2,0.9,1,1.8
			c-0.1,0.6-0.3,1.2-0.4,1.8c-5.1,20.3-10.1,40.5-15.2,60.8c-2.6,10.5-5.2,20.9-7.8,31.4c-1.4,5.8-2.9,11.5-4.3,17.3
			c-2.3,9.2-4.7,18.5-7,27.7c-2,7.8-3.9,15.5-5.9,23.3c-0.5,1.9-0.8,3.9-1.3,5.9c-0.7,2.7-0.8,2.8-3.7,2.8c-5,0-10,0-14.9,0
			c-18.7,0-37.5,0-56.2,0c-15.4,0-30.8,0-46.2,0c-2.5,0-4.1-0.6-5.5-2.8c-1.8-2.8-4-5.3-6-7.9c-2.2-2.9-4.3-5.9-6.5-8.9
			c-1.6-2.2-3.4-4.2-5-6.4c-0.7-0.9-1.1-2-1.6-3.1c-0.2-0.5-0.4-1.1-0.8-1.4c-1.1-1.1-0.9-2.2-0.5-3.5c1.5-5.7,2.9-11.4,4.3-17.1
			c1.8-7.3,3.7-14.7,5.5-22c1.6-6.5,3.3-13,4.9-19.5c0.6-2.4,1.1-4.8,1.9-7.2c0.3-0.9,1-1.9,1.7-2.5c1.7-1.6,3.6-3,5.4-4.4
			c5.8-4.7,11.7-9.4,17.5-14.2c1.6-1.3,3.1-2.5,4.7-3.8c0.6-0.5,1.1-1,2-1.8c-0.9-0.1-1.4-0.2-1.8-0.2c-5.7,0-11.3,0-17,0
			c-0.4,0-0.8,0-1.2,0c-1.5-0.1-1.8-0.5-1.5-2c0.1-0.4,0.2-0.8,0.3-1.4c-0.8-0.2-1.5-0.4-2.4-0.6c3.8-15.2,7.6-30.4,11.5-45.8
			c18.2,0,36.4,0,54.7,0c-0.2,1.1-0.3,2.1-0.5,3c0.7,0.5,1.5,0.8,1.8,1.4c0.2,0.6,0,1.5-0.2,2.2c-1.4,5.7-2.9,11.4-4.4,17.1
			c-1.7,6.6-3.3,13.3-4.9,19.9c-5.8,23.4-11.7,46.8-17.6,70.2c-0.8,3.3-1.7,6.6-2.5,9.9c-0.2,0.7-0.3,1.5-0.4,2
			c0.2,0.3,0.2,0.3,0.3,0.4c0,0,0.1,0.1,0.2,0.1c0.5,0.1,1,0.2,1.4,0.2c12.4,0,24.9,0,37.5,0C527.1,268.1,537.6,226,548.3,183.8z
			 M417.3,329.6L417.3,329.6c1.6,1.9,3.2,4,4.9,5.9c1.1,1.3,1.6,1.9,2.2,3.6c0.1,0.2,0.1,0.4,0.3,0.6c1.6,2,3.1,4,4.7,6
			c0.3,0.4,0.8,0.7,0.9,1.1c0.6,1.6,1.5,2.8,3.5,2.9c-0.4,0.2-0.7,0.4-1,0.5c1.2,1.7,2.3,3.3,3.5,4.9c0.2,0.3,0.7,0.5,1.1,0.5
			c0.6,0.1,1.2,0,1.9,0c0.2-0.5,0.3-0.9,0.5-1.4c1-0.2,2.1-0.4,2.7,0.6c0.5,0.8,1,0.8,1.7,0.8c0.9,0,1.8-0.1,2.7,0
			c1,0.1,1.3-0.4,1.6-1.4c-0.1,1.3,0.6,1.5,1.4,1.4c0.6,0,1.1,0,1.7,0c4.1-0.1,8.3-0.2,12.4-0.2c4.5,0,9.1,0.1,13.6,0.2
			c0.9,0,1.6-0.3,2-1.1c0.2-0.3,0.6-0.6,1.1-1.1c0.2,0.9,0.4,1.5,0.6,2.3c1.6-0.6,3.7,1,5-1.3c0,1.1,0.7,1.3,1.6,1.2
			c3.6,0,7.2,0,10.8,0c18.3,0,36.6,0,54.9,0c0.8,0,1.5,0,2.3,0c0.5-1.1,0.9-2.1,1.3-3.1c0.6,0,1.2,0.1,1.7,0.1
			c0.2,0.6,0.4,1.1,0.6,1.6c0.1,0,0.3,0,0.4,0c0.3-1,0.6-2,0.9-3c-0.6-0.1-1-0.1-1.4-0.2c0.1-0.4,0.3-0.8,0.4-1.2
			c0.2-1,1-1.6,1.5-2.4c1.2-1.8,1.1-4.1,2-6c0.5-1.2,0.8-2.5-0.2-3.6l0,0.1c0.7,0.4,1-0.1,1.2-0.7c0.3-1,0.6-2.1,0.8-3.1
			c-0.6-0.2-1-0.3-1.4-0.4l0,0.1c-0.2-0.6-1.7-0.3-1.1-1.5c3-0.4,2.8-0.5,3.7-3.2c0.7-2.1,1-4.2,1.5-6.3c-0.7-0.9-1.4-0.7-2.1,0
			c0.2,0.4,0.4,0.8,0.5,1.1c-0.7,0.7-1.2,1.3-1.9,1.7c-0.6,0.4-1.3,1.3-2.4,0.2c0.6-0.4,1.2-0.7,1.8-1.1c0.3-0.8-0.4-2.2,1.2-2.4
			c-0.1-0.4-0.2-0.8-0.3-1.2c0.4-0.6,0.9-1.3,1.3-1.8c0.8,0.3,1.5,0.7,2.2,0.8c0.7,0.1,0.9-0.5,0.6-1.1l0,0c1.5-3.2,2-6.6,2.6-10.2
			c-0.8,0-1.3,0.1-1.8,0c-0.3-0.1-0.9-0.3-0.9-0.5c0.1-0.8-0.2-1.3-0.9-1.6c0.2-0.3,0.3-0.8,0.5-0.9c0.9-0.2,1.6,0.5,1.5,1.4
			c0,0.3-0.2,0.6-0.3,1.3c0.6-0.4,0.9-0.7,1.2-0.7c1.2-0.1,1.4-1,1.1-1.8c-0.4-0.8-0.8-1.2-0.2-2.2c0.2-0.4-0.7-1.4,0.3-2.1
			c0.1-0.1,0-0.6-0.1-0.7c-0.5-0.4,0-0.6,0-1c0.3-1.4,1.6-1.3,2.6-1.7c0.4-1.7,0.8-3.3,1.2-5c-0.9-0.2-1.5-0.3-2.1-0.3
			c1-1.6,1.9-3.2,3-4.8c-0.8-0.4-1.9-0.7-1.1-1.9c0.5,0.3,1,0.6,1.7,1c0.2-0.7,0.5-1.2,0.5-1.7c-0.2-1.7,0.5-3.2,1.2-4.7
			c0.3-0.6,0.3-1.2,0.5-1.8c0.4-1.1,0.7-2.2-0.3-3c0.6-0.7,1.2-1.3,1.4-2c0.2-0.7,0-1.5,0-2.3c0.8-0.5,1.5-2.3,2-5.7
			c-0.3-0.4-1.4-0.1-1-1.2c0.4,0.1,0.9,0.1,1.4,0.2c0.6-2.8,1.5-5.3,1.8-8c-1.1-0.4-2,0.4-3,0c-0.3-0.1-1,0.7-1.4,1.1
			c-0.5-0.1-0.9-0.2-1.3-0.3l0.1,0.1c0-1.2,1.1-1.4,1.7-1.4c1.6,0.1,2.8-0.8,4.4-1c0.2-1.1,0.3-2.1,0.4-3.1c0.1-0.4,0.2-0.9,0.4-1.3
			c0.2-0.6,0.7-1.2,0.8-1.8c0.9-3.3,1.7-6.6,2.5-9.8c0.8-3.3,1.6-6.6,2.5-9.8c0.9-3.2,1.6-6.5,2.4-9.7c2.7-10.4,5.3-20.9,7.9-31.3
			c0.3-1.4,0.7-2.8,1-4.4c-6.1,0-12,0-17.8,0c-5.9,0-11.8,0.1-17.6,0.1c-5.9,0-11.7,0-17.7,0c-0.2,0.7-0.4,1.3-0.6,2
			c-1.8,7.1-3.6,14.2-5.3,21.3c-6.3,25.1-12.6,50.2-18.9,75.3c-1.9,7.6-3.8,15.1-5.7,22.7c-0.2,0.9-0.2,1.4,1,1.7
			c-0.7,0.2-1,0.3-1.5,0.4c-0.1,0.4-0.2,0.9-0.4,1.4c-0.5,1.4-0.6,1.6-2.2,1.6c-0.5,0-1,0-1.5,0c-12,0-24.1,0-36.1,0
			c-0.7,0-1.5,0-2.2,0c-0.2,0.8-0.3,1.5-0.4,2.2c-0.6-0.1-1-0.2-1.6-0.3c0.4-0.7,0.6-1.2,0.9-1.8c-1.3-0.6-0.8-1.6-0.6-2.5
			c0.4-1.7,0.9-3.5,1.3-5.2c0.5-1.8,0.9-3.6,1.4-5.4c0.4-1.6,0.8-3.3,1.2-5c4.1-16.2,8.2-32.4,12.2-48.6c1.2-5,2.3-10.1,3.5-15.1
			c0.6-2.3,1.2-4.6,1.9-6.9c0.6-2.3,1.3-4.7,1.9-7c0.4-1.5,0.8-3,0.7-4.5c-0.2-1.7,0.5-2.8,1.2-3.9c-0.2-0.6-0.6-1.2-0.5-1.5
			c0.5-1-0.1-1.5-0.6-2c0.3-0.6,0.6-1.1,0.9-1.8c0.4,0.4,0.8,0.8,1.3,1.3c0.4-1.3,0.7-2.6,1-3.8c0.3-1.2,0.6-2.4,0.9-3.6
			c0.3-1.3,0.6-2.5,1-3.8c0.3-1.1,0.2-2.2,0.7-3.2c0.6-1.1,0.7-2.5,1-4c-2.7,0-5.3,0-7.8,0c-2.5,0-5,0-7.4,0c-2.6,0-5.1,0-7.7,0
			c-2.6,0-5.1,0-7.7,0s-5.1,0-7.7,0c-2.5,0-5,0-7.5,0c-2.5,0-4.9,0.1-7.4,0.1c-1,3.9-2,7.6-2.8,11.4c-0.8,3.6-2.4,7-2.3,10.8
			c0,0.2-0.4,0.4-0.5,0.6c-0.6,1.7-1.3,3.3-1.1,5.1c0.6,0.2,1.1,0.3,1.6,0.4c0,0.7-0.1,1.3-0.1,2c-0.1,0-0.2,0-0.4,0
			c-0.1-0.5-0.3-1.1-0.4-1.5c-0.8-0.2-1.4-0.1-1.5,0.7c-0.3,1.5-0.6,3-0.9,4.4l0.1-0.1c-0.6,1.9-1.2,3.9-1.9,5.9
			c0.6,0.2,1.3,0.5,1.4,0.4c0.8-1,1.4-0.1,2.1,0.1c0.7-0.6,1.3-1.2,2.1-1.9c-0.2,1.6-0.4,2.9-0.6,4.3c0,0.1,0,0.4,0,0.4
			c-0.3,0.2-0.6,0.3-1.3,0.7c0.2-0.8,0.5-1.2,0.4-1.4c-0.2-0.4-0.7-1.2-0.8-1.1c-0.8,0.4-1.7,0.2-2.4,0.4c-1.3,0.3-1.8,1.5-1.8,3.1
			c1.3-0.5,2.7,0.7,3.5-1.2c1.4,1,3,1.2,4.7,0.9c0.9-0.2,2-0.6,2.8-0.3c2.4,0.8,4.8,0.3,7.2,0.4c1.4,0.1,2.8-0.1,4.2-0.2
			c0.6-2.5-0.4-5,0.7-7.3c0-0.1,0-0.3-0.1-0.4c-1-1.6,0.4-3,0.4-4.5c0-0.2,0.3-0.3,0.5-0.5c-1,4.3-0.2,8.4-0.3,12.8
			c1.3,0,2.3-0.1,3.3,0c1.1,0.1,1.4,0.9,0.7,1.7c-0.6,0.7-1.3,1.2-2,1.8c-1.2,1-1.9,2.2-1.4,3.4c0.3,0.7,1,1.2,1.3,1.7
			c0,1.5,0,2.7,0,4.1c-1,1.1-1.1,1.2-0.7,2.9c0,0.2,0.2,0.4,0.2,0.5c-1.1,2.5-0.1,5.2-0.8,7.9c-0.6,2.4,0.1,5.2-1.2,7.6
			c-0.2,0.3,0,0.7-0.1,1c0,0.5,0,1-0.2,1.4c-0.5,1.5-0.5,3.1-0.1,4.6l0,0c0,0.2,0.2,0.5,0.1,0.6c-1.4,1.1-1.1,2.6-1,3.9
			c0.6,0.6,1.3,1,1.5,1.6c0.3,0.7,0,1.5-1,1.9c-0.8-0.7-1.7-1.4-2.5-2c-0.3,0.2-0.4,0.2-0.4,0.2c-0.2,0.5-0.3,1-0.4,1.3
			c-0.8-0.1-1.6-0.2-2.4-0.3c-1.4-0.1-1.6-0.3-1.6-2c0.5-0.2,0.9-0.5,1.5-0.8c0.4,0.5,0.9,1,1.4,1.5c0.7-0.3,1.3-0.6,2.2-1
			c-0.4-1.1-0.7-2.1-1.1-3.2c-2.1,0.3-4.1-0.5-6,0.7c-0.5,0.3-1.1-0.1-0.9-0.9c0.8-0.4,1.6-0.9,2.5-1.3c0.4-0.1,1.1-0.1,1.4,0.1
			c0.9,0.7,1.5,0.4,2.4-0.1c1.4-0.8,1.8-2.2,2-3.5c0.2-1,0.2-2,0.8-2.9c0.8-1.1,0.1-2.4,0.6-3.4c0.9-1.8,0.5-3.7,0.7-5.5
			c0.2-1.8,0.3-3.6,0.5-5.4c0.1-1.7,0.3-3.3,0.3-5c0-1.8-0.2-3.7-0.4-5.5c0-0.6-0.1-1.3-0.1-2c-0.3,0.1-0.5,0.1-0.6,0.1
			c-6.6,5.3-13.1,10.6-19.7,15.9c-0.5,0.4-0.9,0.9-1.2,1.4c-0.2,0.2-0.4,0.8-0.3,0.9c0.8,0.8-0.2,1.3-0.3,1.9l0.1,0
			c-0.5-0.4-1-0.9-1.5-1.4c-1.1,0.8-2,1.7-1.4,3.4c0.4,1-0.1,1.4-1.4,1.8c-0.2-0.6-0.5-1.2-0.8-2.1c-1.1,0.8-2.1,1.3-2.7,2.1
			c-0.5,0.6-0.1,1.9-1.1,2.3c-0.8,2.5-0.7,5.1-0.5,7.7c0,0.3,0.3,0.6,0.4,0.9c0.7,1.4,1.2,2.7,0.3,4.2c-0.7-0.2-1.3-0.3-2.1-0.5
			c0.5-0.7,0.8-1.2,1.2-1.7c-0.3-0.4-0.8-0.8-0.9-1.3c-0.1-0.4,0.1-0.9,0.2-1.3c-0.5,0.3-0.8,0.7-0.9,1.1c-0.3,1-0.5,2-0.8,3
			c-0.7,2.7-1.5,5.3-1.9,8c-0.4,2.8-1.5,5.3-2.1,8c-0.1,0.5-0.4,1.3-0.2,1.6c0.4,0.5-0.1,0.5-0.2,0.7c-0.6,1.6-1.6,3.2-0.4,5
			c-0.4,0.1-0.8,0.2-1.2,0.3c-0.2,1-0.3,2.1-0.6,3.2c-0.2,1.1-0.5,2.2-0.8,3.3c0.8-0.1,1.5-0.2,2.4-0.4c-0.9,0.8-1.7,1.2-2.1,1.9
			c-0.8,1.3-1.7,2.8-1.8,4.2c-0.1,1.7-1.5,2.9-1.2,4.5c0,0.2,0.1,0.4,0.2,0.7c0.4-0.3,0.7-0.6,1.1-0.6c0.3-0.1,0.6,0.1,0.9,0.2
			c0.1,0.1,0,0.5-0.1,0.7c-0.1,0.2-0.4,0.5-0.6,0.6c-1.1,0.2-1.2,1.1-1.3,1.9c-0.4-0.1-0.7-0.1-0.9-0.2c-1.2,2-0.1,3.6,0.6,5.5
			c-0.8,0.3-1.6,0.6-2.3,0.9c-0.3,1.1-0.5,2.1-0.8,3.2C415.8,327.9,416.1,328.9,417.3,329.6z M557.9,355.4c0.7,0.2,1.2,0.5,1.7-0.5
			c-0.5,0-0.9-0.1-1.2-0.1C558.2,354.8,558.1,355.1,557.9,355.4z"/>
		<path d="M292.5,233.6c-2.2,0-4.3-0.1-6.4-0.1c-4.4,0-8.7,0.1-13.1,0.1c-0.3,0-0.6,0-0.8,0c-1.4-0.2-1.7-0.5-1.5-2.1
			c0-0.3,0.1-0.7,0.2-1.3c-0.7-0.2-1.5-0.5-2.3-0.7c3.9-15.3,7.9-30.5,11.8-45.8c50.7,0,101.1,0,151.8,0c-0.2,1.1-0.3,2.2-0.5,3.2
			c2.1,1.2,2.2,1.2,1.6,3.5c-4.8,19.2-9.6,38.3-14.4,57.5c-0.4,1.7-1.3,2.9-2.8,3.8c-4.6,2.7-9.1,5.6-13.6,8.4
			c-4.9,3-9.9,6.1-14.8,9.1c-0.4,0.2-0.7,0.6-1.2,1c0.4,0.6,0.8,1.2,1.2,1.7c2.5,3.1,5,6.1,7.6,9.2c2.9,3.5,5.8,7,8.7,10.5
			c0.6,0.7,1.2,1.3,1,2.4c-0.1,0.4,0.3,0.9,0.6,1.3c1,1.1,0.9,2.4,0.6,3.7c-1.1,4.4-2.2,8.7-3.3,13.1c-3.7,14.9-7.4,29.8-11.1,44.7
			c-0.8,3.1-0.8,3.2-4,3.2c-12.1,0-24.2,0-36.3,0c-6.7,0-13.4,0-20.1,0c-19,0-38,0-57,0c-4.2,0-8.4-0.1-12.6-0.1
			c-1.5,0-2.7-0.4-3.5-1.8c-1.7-2.7-3.4-5.4-5-8.1c-2.1-3.4-4.2-6.9-6.1-10.3c-1.3-2.2-2.4-4.5-3.5-6.8c-0.2-0.4-0.1-1.1,0-1.6
			c1.2-5.2,2.4-10.5,3.6-15.7c2.1-8.6,4.3-17.3,6.5-25.9c2.4-9.6,4.8-19.3,7.2-28.9c0.3-1.3,0.9-2.3,2.1-3.2c4-3.1,8-6.4,12-9.6
			c5.8-4.7,11.7-9.4,17.5-14C292.3,234.2,292.3,234,292.5,233.6z M256.8,347.1c0.4-0.4,0.8-0.9,1.2-1.3c1.1,0.1,1.3,0.6,1.1,1.7
			c-0.3,1.2-0.2,2.5-0.4,3.8c0.1,0.1,0.2,0.1,0.4,0.2c0.8-1,1.6-2,2.9-1.2c-0.5,0.6-1,1.2-1.7,1.9c0.8,0.4,1.3,0.6,1.7,0.9
			c0.3,0.2-0.7,0.8,0.2,1.1c0.2,0.1,0.3,0.7,0.5,1.3c2.7,0,5.5,0,8.3,0c3.2,0,6.3-0.1,9.5-0.1c3,0,5.9,0,8.9,0.1
			c0.9,0,1.5-0.2,1.7-0.8c0.6,0.3,1.1,0.7,1.5,0.7c2.8,0.1,5.6,0.1,8.5,0c5.7-0.1,11.3-0.2,17-0.2c3.5,0,7,0.2,10.5,0.2
			c7.6,0,15.3,0,22.9,0c10.7,0,21.4,0,32.1,0c0.7,0,1.5,0,2.4,0c-0.3-0.9-1-1.6-0.2-2.4c0.5,1.2,1.1,2.3,2.6,2.1
			c0.1-0.3,0.2-0.6,0.2-0.9c3.2-12.7,6.4-25.4,9.5-38.2c1-3.9,1.7-7.8,2.7-11.7c0.8-3.3,1.8-6.6,2.7-10c0.2-0.8,0.2-1.5-0.4-2.3
			c-2.7-3.2-5.3-6.6-8.4-9.5c-0.4-0.4-0.5-1.2-0.9-1.7c-0.9-0.9-2-1.7-3-2.4c-1.5,1.1-1.5,1.1-2.3,0.3c0.8-0.4,1.6-0.8,2.6-1.2
			c-2.9-3.5-5.7-6.9-8.5-10.3c-0.1-0.9,0.6-1.3,1.4-1.8c3.4-2.1,6.8-4.2,10.2-6.3c0.6-0.4,1.1-0.8,1.7-1.2c1-0.7,1.5-1.6,0.8-2.8
			c-0.2-0.3-0.1-0.7-0.1-1c0.1-0.9,0.3-1.7,0.4-2.6c0.4-4.5-0.5-8.9-0.6-13.4c0-1,0.3-2-0.5-3c-0.2-0.2,0-0.8,0-1.2
			c0.1-0.8,0.4-1.6,0.4-2.3c0.1-1,0-2,0-3c0.2,0,0.3,0,0.5,0c0.7,1.2,1,2.4,0.6,3.8c-0.3,0.9-0.1,2.4,0,3.2c0.3,2,0.5,4,0.7,6
			c0,0.1,0,0.1,0,0.2c-1.1,1.6,0.6,3,0.2,4.5c0,0.1,0.1,0.3,0.2,0.3c1.4,0.4,0.9,1.4,0.7,2.4c-0.4,2.2-0.5,4.5-0.2,6.9
			c0.4-0.2,0.7-0.3,0.9-0.4c4.8-3,9.6-6,14.5-8.9c0.9-0.5,1.3-1.2,1.6-2.3c3.5-14.3,7-28.7,10.6-43c1.3-5.2,2.7-10.4,4-15.6
			c0.1-0.5,0.2-0.9,0.3-1.6c-0.8,0-1.5,0-2.2,0c-1.6,0.1-3.3,0.3-4.9,0.3c-2.3,0-4.6-0.3-6.9-0.3c-9.2,0-18.4,0-27.6,0
			c-0.7,0-1.7-0.4-2.2,0.6c0,0.1-0.4,0-0.5,0c-0.6-1-1.6-0.6-2.4-0.6c-30,0-60,0-90,0c-1.4,0-2.8,0-4.3,0c-1.7,0-3.4,0-5.2,0
			c0.2,1.3,0.4,2.2,0.5,3.2c-1.5-0.7-1.9-1.7-1.5-3.1c-1,0-1.9,0-2.8,0c-0.4,1.1-0.8,2.2-1,3.2c-0.6,2.9-1.6,5.7-2,8.6
			c-0.4,2.8-1.6,5.4-2.3,8.1c-0.8,2.9-1.5,5.7-2.2,8.6c-0.2,0.8-0.7,1.8,0.7,2.1c0.1,0,0.3,0.5,0.2,0.7c-0.1,0.2-0.4,0.4-0.6,0.4
			c-0.4,0-0.7-0.2-1.1-0.3c-1,4.3-2.3,8.4-3.2,12.9c2,0,3.7,0,5.5,0c-0.1-0.4-0.1-0.8-0.2-1.2c0.7,0,1.4,0,2,0
			c0.2,0.5,0.4,0.9,0.6,1.4c1.6,0,3,0,4.4,0c0.8,0,1.5-0.1,1.1-1.2c-0.5,0.1-1,0.1-1.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.3
			c0.2-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.8-0.1,1.4,0,1.8,0.9c0.2,0.7,0.7,1.3,1.1,1.9c2,0,4.1,0,6.1,0
			c0-0.7,0-1.2,0-1.8c-1-0.2-1.8-0.4-2.7-0.6c1.2-1.1,2.2,0.2,3.3,0.1c0.1,0.9,0.1,1.5,0.2,2.5c0.9,0,1.7,0,2.5,0c0.7,0,1.4-0.2,2,0
			c0.4,0.1,1.1,0.6,1.1,1c0.1,1.1-0.1,2.3-0.2,3.4c-1.3-0.1-0.7-1-0.9-1.9c-1.1,1.5-3.5,1.7-2.9,4.2c-1-0.5-1.8-0.7-2.5,0.5
			c-0.4,0.6-1.3,1-2,1.5c-0.1,0.1-0.1,0.2-0.1,0.5c0.4,0.2,0.9,0.5,1.2,0.6c0.7-0.7,1.3-1.4,2.4-2.4c-0.4,2.1-0.7,3.8-1,5.4
			c-0.6,0.1-1.3,0-0.9-0.6c0.6-1,0.1-1.4-0.6-1.8c-0.8,0.5-0.8,0.5-1.8,0.3c-0.2,0-0.4-0.1-0.5,0c-1.6,0.9-3.6,1.3-4.9,2.8
			c-0.6,0.7-1.3,1.3-2.1,2.1c0.1,0.4,0.3,0.8,0.5,1.3c-0.5,0.5-1,1.1-1.7,1.8c0.5,0.3,0.9,0.6,1.2,0.8c-0.3,0.6-0.6,1-0.8,1.4
			c-0.5,0-0.9-0.1-1.3-0.1c-0.5,1.6,0.8,3.6-1.5,4.5c0.8,1.1,1.2,2.1,0.2,3.3c-0.2,0.2-0.2,0.8-0.1,1.2c0.4,1.1,1,2.2,1.4,3.3
			c0.2,0.5,0.3,1.1,0.4,1.4c-1,1.1-2.3-0.6-3.1,1.1c0.8,0.4,1.6,0.7,2.5,1.1c-1.3,1,0.4,2.7-1.3,3.4c1.2,1,0.4,1.8-0.2,2.7
			c0.7,0.9,1.4,1.7,2.2,2.9c-1.1-0.3-1.8-0.4-2.4-0.6c-0.4-1.1-0.8-2.1-1-3.1c-0.2-1-0.2-2.1-0.3-3.2c-1-0.5-2.9,0.6-3.4-1.5
			c0.6-0.1,1.1-0.3,1.7-0.4c-0.5-1.3-0.3-3,0.2-4c0.1-0.2,0.2-0.6,0.2-0.9c0-0.4,0-0.8,0-1.1c0.1-1.3,0.2-2.6,0.3-4.2
			c0.2-0.2,0.5-0.5,1-2.7c0.4-1.5,0.4-3-0.1-4.7c-0.6,0.5-1.1,0.8-1.5,1.1c-1.2,1.1-2.3,2.2-3.5,3.2c-2.7,2.1-5.5,4.2-8.2,6.2
			c-0.8,0.6-1.2,1.3-1.4,2.2c-1.5,6.4-3.1,12.9-4.7,19.3c-0.3,1.4-1,2.7-0.7,4.2c-1.4,1.7-1.1,3.9-1.9,5.7c-0.4,1-0.4,2.3-0.7,3.4
			c-0.3,1.1-0.8,2.2-1,3.3c-0.2,1.2,0,2.6-0.5,3.5c-0.6,1-0.9,2-1.1,3c-0.4,2.1-1,4.1-1.5,6.1c-1.6,6.1-3.1,12.2-4.6,18.3
			c0,0.2,0,0.4,0,0.6c0.5,0.1,1,0.3,1.5,0.4c0.3,1.3,0,2.1-1.4,2.3c-0.9,0.2-1.1,0.9-0.9,1.7c0.1,0.3,0.3,0.5,0.4,0.7
			c2.4,4.1,4.9,8.2,7.3,12.3c0.7,1.2,2,1.7,3.4,1.4c0.4-0.1,0.8-0.1,1.2-0.1c-0.3,1,0,2-0.7,2.9c-0.4,0.6-0.5,1.7,0.4,2.3
			C257.1,350.7,257,348.9,256.8,347.1z M277.1,247.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.6,0.8,1.3,1.4,2.5,0.9c-0.1-0.2-0.2-0.4-0.4-0.5
			C278.4,247.9,277.8,247.7,277.1,247.4z"/>
		<path d="M790.8,233.9c-0.9,0-1.6,0-2.3,0c-5.3,0-10.5,0-15.8,0c-0.6,0-1.2,0-1.9,0c-0.9-0.1-1.4-0.6-1.3-1.5
			c0.1-0.6,0.1-1.2,0.2-1.9c-0.8-0.1-1.4-0.3-2.3-0.4c3.8-15.3,7.5-30.5,11.2-45.6c1.6-0.5,52.7-0.5,54.7-0.1c-0.1,1-0.3,2-0.4,3
			c2.1,1.1,2.1,1.1,1.6,3.4c-2.3,9.4-4.7,18.8-7,28.2c-7.3,29.1-14.5,58.2-21.8,87.3c-0.3,1.2-0.6,2.4-1,3.9c1,0.1,1.8,0.2,2.6,0.2
			c10.7,0,21.4,0,32.1,0c12.9,0,25.7,0,38.6,0c1.6,0,3.2,0,4.7,0c1,1.2-0.2,2.2-0.1,3.4c0.2,0.1,0.5,0.3,0.8,0.4
			c1.2,0.5,1.4,0.7,1,2c-2.9,11.8-5.7,23.6-8.6,35.5c-0.5,2.1-1,4.3-1.6,6.4c-0.6,2.1-0.9,2.3-3.1,2.3c-4.7,0-9.4-0.1-14.1-0.1
			c-6.6,0-13.3,0.1-19.9,0.1c-8.2,0-16.3,0-24.5,0c-8.8,0-17.7-0.1-26.5-0.1c-5.6,0-11.2,0.1-16.8,0.1c-1.8,0-3.6,0.1-5.4-0.1
			c-0.7-0.1-1.5-0.5-2.1-1c-0.7-0.6-1.1-1.4-1.7-2.1c-4.9-6.6-9.9-13.1-14.8-19.7c-0.6-0.9-1-1.9-1.5-2.9c-0.2-0.4-0.3-1-0.6-1.3
			c-1.2-1.2-1-2.5-0.6-4c1.3-4.9,2.4-9.8,3.6-14.6c1.9-7.5,3.7-15,5.6-22.5c0.9-3.4,1.9-6.8,2.7-10.2c1.7-7,3.4-14.1,5.1-21.1
			c0.3-1.1,0.8-1.8,1.6-2.5c4.8-3.8,9.6-7.6,14.4-11.4c4-3.2,8-6.4,11.9-9.6C788.9,236.1,790.3,235.7,790.8,233.9z M777.1,353.4
			c-0.2-0.2-0.4-0.4-0.8-0.8c0.1,1.1,0.2,2,0.2,2.8c0.8-0.1,1.6-0.3,2.7-0.5C778.4,354.3,777.7,353.8,777.1,353.4
			c1.3-1.2,1.3-1.6,0.1-2.4C777.1,351.8,777.1,352.6,777.1,353.4z M754.4,310L754.4,310c0.1,0.5,0.1,0.8-0.6,1
			c-1.3,0.4-2.4,1.2-2.5,2.7c-1.4,0.4-1.8-0.3-1.7-1.4c-0.8,0.3-1.4,0.5-2,0.7c-0.2,1.4-1.3,2.8,0,4.2c-1,0-1.2,0.6-1.3,1.4
			c-0.9,3.6-1.7,7.1-2.7,10.6c-0.4,1.3-0.1,2.3,0.7,3.4c3.7,4.8,7.3,9.7,11,14.6c1.9,2.5,3.8,5,5.6,7.5c0.7,1,1.6,1.2,2.7,0.5
			c0.6-0.3,1.1-0.9,1.4-1.4c0.5-0.8,1-0.5,1.7-0.2c-0.6,0.6-1,1.1-1.6,1.7c2.9,0.6,4.4,0.3,4.4-0.7c-0.3-0.3-0.7-0.6-1-0.9
			c0.1-0.2,0.2-0.3,0.2-0.5c0.7,0.2,1.4,0.3,2,0.7c0.3,0.2,0,1,0,1.6c0.6-0.1,1.3-0.2,2.1-0.3c0-0.5,0.1-1.2-0.2-1.6
			c-0.4-0.7-0.4-1.4-0.2-2.1c0.2-1,0.1-2,0-3c0-1,0.9-2.4,1.7-2.4c1.1,0,1.5,0.6,1.8,1.6c0.2,0.7,0.8,1.3,1.2,1.9
			c0.1,0.1,0.6,0,0.6,0c0.1-1.5,1.5-0.8,2-1.4c0.3,0.8,0.6,1.5,0.9,2.2c0.3,0.7,0.6,1.4,0.9,2c0.9-0.1,1.5,0.2,2.2,0.8
			c-0.7,0.4-1.3,0.6-1.9,0.9c0.1,0.5,0.2,1,0.2,1.5c1.2,0,2.2,0,3.2,0c1.6,0.1,2.6-0.6,3.2-2.2c0.2-0.4,0.5-0.8,0.7-1.2l-0.1,0.1
			c0.2,0.2,0.5,0.5,0.5,0.7c0.1,0.7,0,1.5,0,2.1c2.4,1.4,4.6-0.4,6.8,0.4c1.4-1.5,1.7-2.2,1.9-3.9c0.1-0.6,0.3-1.2,0.7-1.6
			c0.9-1.1,0.4-2.7,1.6-3.6c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.3-0.1-0.7,0-0.8c1.4-0.6,1-2.3,2-3.2c0.8-0.7,0.5-2.6-0.3-3.2
			c-0.5-0.3-1.1-0.6-1.6-0.9c-0.9-0.5-1.7,0.9-2.6,0c-0.1-0.1-0.5,0.4-0.8,0.5c-1.1,0.5-2.2,1-3.4,1.3c-0.5,0.1-1.2-0.1-1.8-0.2
			c-0.1,0-0.1-0.5-0.1-0.6c1.2-0.4,2.4-0.5,3.2-1.2c0.6-0.5,1.3,0.5,1.7-0.5c0.3-0.8,1.1,0.2,1.7,0.1c1.3-0.1,2.7-0.4,4,0.4
			c0.2,0.1,0.6-0.1,0.9-0.3c0.4-0.3,0.7-0.6,1.1-1c0.8,0.6,1.6,1.2,2.6,1.9c-1,0.1-1.6,0.2-2.4,0.2c0.5,0.4,0.7,0.6,1.2,1
			c-0.8,0.1-1.3,0.2-2.1,0.4c0.6,0.2,1,0.3,1.3,0.4c-0.4,0.4-0.9,0.6-1,1c-0.3,0.7,0.3,1.3,0.7,1.8c-0.6,0.1-1.2,0.1-1.8,0.2
			c-0.5,1.6-0.5,1.6,1.5,3.4c0.4-0.7,0.8-1.4,1.1-1.9c-0.3-0.7-0.6-1.2-0.9-1.7c0.9,0.3,2.1-0.7,2.8,0.6c-0.3,0.2-0.7,0.4-1,0.5
			c0.3,1.3,0.5,2.5,0.8,3.7c0.5,0.1,0.9,0.3,1.4,0.4c-0.4,0.6-0.6,1-0.9,1.5c-0.6-0.6-1-1.3-1.6-1.6c-1-0.5-2.1-0.8-3.5-1.2
			c0.7,1.4-0.5,1.6-1.6,2.5c0.5,0.3,0.9,0.6,1.3,0.6c0.7,0,1.4-0.2,2.2-0.3c0.1,0.6,0.2,1.1,0.2,1.6c-0.9,0.1-2.3-0.1-2.4,1.1
			c-0.1,1-2.2,0.9-1.2,2.4c4,1,4.7,0.9,6.4-0.4c-1-0.1-1.8-0.1-2.7-0.2c-0.3-0.5-0.5-0.9-0.7-1.3c0.3-0.2,0.6-0.3,0.8-0.5
			c-0.1-0.4-0.2-0.8-0.4-1.2c0.4-0.1,0.9-0.2,1.3-0.2c0.4,0,0.8,0.1,1.2,0.2c-0.4,0.3-0.8,0.7-1.3,1.1c0.6,0.4,1,0.7,1.5,1
			c0.1-0.1,0.3-0.2,0.4-0.3c-0.2-0.7-0.4-1.3-0.6-2c1,0.1,1.9,0.3,3.4,0.5c-1,1.2-1.7,2-2.3,2.8c1.3,1.6,3.1,1,4.7,1.1
			c0.2-0.3,0.4-0.7,0.5-1c0.5,0.3,0.9,0.6,1.3,0.9c0.3-0.6,0.5-1,0.7-1.5c-0.3-0.1-0.5-0.2-0.8-0.3c0.2-0.3,0.4-0.5,0.7-1.1
			c0.5,0.9,0.8,1.6,1.2,2.2c0.5-0.2,1.1-0.4,1.9-0.8c-0.1,0.6-0.2,1-0.3,1.4c0.3,0,0.5,0.1,0.8,0.1c1.5,0,3.1-0.1,4.6-0.1
			c1.6,0,3.1,0.2,4.7,0.3c0.9,0.1,1.3-0.5,1.9-1c0.6-0.6,1.1-1.5,2.3-1.1c0.1,0.6,0.2,1.3,0.3,2c0.7,0.1,1.3,0.1,1.9,0.1
			c8.7-0.1,17.5-0.1,26.2-0.3c1.4,0,2.9-0.7,4.1-0.3c1.6,0.5,2.7-0.1,4.1-0.6c0.3,1.3,1.4,0.5,2.2,1c0.2-0.8,0.7-1.5,0.6-2.2
			c-0.1-0.8,0.1-1.4,0.4-2.1c0.3-1,0.7-1.9,1-2.9c2.2-8.7,4.3-17.4,6.5-26.1c0.1-0.5,0.2-1.1,0.3-2.1c-0.8,0.8-1.2,1.4-1.7,1.6
			c-0.9,0.3-0.8,1.3-1.4,1.6c-0.8,0.5-1.7,0.6-2.6,1c-0.4,2-0.5,2.1-2,1.8c0.1-0.3,0.2-0.7,0.4-0.9c0.8-1.3,2.1-2,3.1-3.1
			c0.5-0.5,0.9-0.8,1.5-1.2c1.2-0.7,2.4-1.8,3.1-3c1.2-2.1,0.8-4.6,2.1-7c-3.5,0-6.7,0-10,0c-0.6,0-1.3-0.2-1.5,0.7
			c-0.4-0.8-1.1-0.7-1.8-0.7c-2.8,0-5.7,0-8.5,0.1c-0.4,0-0.8,0.3-1.3,0.4l0.1,0c-0.9-0.2-1.9-0.5-2.8-0.5c-13.9,0-27.8,0-41.7,0
			c-0.1,0-0.2,0.1-0.4,0.2c0,0.3,0.1,0.6,0.2,1.3c-0.7-0.3-1.2-0.5-1.7-0.8c-0.3-0.2-0.5-0.6-0.7-0.6c-2.8,0-5.6,0-8.4,0.3
			c-1.2,0.1-2.7-0.5-2.5-1.5c0.2-1.6,0.6-3.1,1-4.6c0.4-1.5,0.9-3,1.2-4.6c0.1-0.4-0.4-0.8-0.7-1.3c0.3-0.1,0.7-0.3,1.1-0.4
			c1.6-6.4,3.2-12.7,4.8-19.1c1.6-6.3,3.1-12.6,4.7-18.9c1.6-6.4,3.2-12.7,4.8-19.1c1.6-6.4,3.2-12.7,4.7-19.1
			c1.5-6.3,3.2-12.5,4.7-18.8c1.4-6.3,3.2-12.6,4.8-19.1c-17.9,0-35.5,0-53.2,0c-3.6,14.8-7.3,29.5-11,44.7c2.1-0.5,3.9-0.2,5.7-0.2
			c7.1,0,14.1,0,21.2,0c0.6,0,1.4-0.1,1.5,0.7c0,0.4-0.4,1-0.7,1.3c-2.4,2.1-4.9,4.1-7.4,6.1l0-0.1c-0.5,0.4-1,0.7-1.5,1.1
			c-5.4,4.3-10.9,8.7-16.3,13c-3,2.4-6,4.8-9,7.2c0.3,0.4,0.5,0.8,0.9,1.3c-1.5,0.3-2.3,0.9-2.4,2.3c-0.1,1-1.1,2.1,0.5,3.2
			c-1.4,0-1.7,0.8-1.7,1.7c0,0.5,0.2,1,0.1,1.5c-0.1,0.4-0.6,0.8-0.8,1.2c-1.7,6.3-3.2,12.6-3,19.1c0,0.5,0.2,1.3-0.1,1.6
			c-0.7,0.9-0.5,2-0.6,3c-0.2,2.2,0.4,4.5-0.5,6.7c-0.1,0.4,0.2,0.9,0.3,1.3c-1,1-2.3,6.2-2,7.7C753.1,309.7,753.8,309.9,754.4,310z
			 M752.7,293.6c-1.7,5.9-3.2,11.8-4.7,18.2c0.7-0.4,1.1-0.5,1.3-0.7c1.4-1.1,1.8-2.9,2.2-4.4c0.6-2,0.5-4.2,0.7-6.3
			c0-0.5-0.1-1.1,0.1-1.4C753.4,297.3,752.7,295.4,752.7,293.6z M781.4,353.5c-1-0.1-1.2,0.5-1.1,1.3c0,0.3,0.4,0.6,0.6,0.9
			c0.4,0,0.7-0.1,1.1-0.1C780,354.7,781.8,354.2,781.4,353.5z"/>
		<path d="M651,310.1c26.4,0,52.4,0,78.6,0c-0.2,1.3-0.4,2.3-0.6,3.4c0.4,0.2,0.7,0.3,1.1,0.5c0.7,0.3,0.9,0.8,0.8,1.5
			c-0.1,0.6-0.2,1.2-0.4,1.8c-3.3,13.3-6.5,26.6-9.8,39.9c-0.1,0.5-0.2,0.9-0.4,1.4c-0.3,1-0.9,1.5-1.9,1.5c-0.7,0-1.4,0-2.1,0
			c-14.7,0-29.3,0-44,0c-20,0-40.1,0-60.1,0c-2.3,0-3.8-0.7-5.2-2.6c-5-6.8-10.1-13.5-15.1-20.3c-0.7-1-1.1-2.2-1.7-3.3
			c-0.2-0.4-0.3-0.8-0.5-1.1c-1.3-1.3-1-2.7-0.6-4.2c1.2-4.5,2.2-8.9,3.4-13.4c3.2-12.8,6.4-25.6,9.6-38.4c1.2-4.9,2.6-9.7,3.6-14.6
			c0.5-2.3,1.6-3.8,3.3-5.2c4.8-3.8,9.7-7.6,14.5-11.4c4.4-3.5,8.7-7,13-10.6c0.3-0.2,0.5-0.5,0.9-1c-0.8-0.1-1.3-0.2-1.8-0.2
			c-5.7,0-11.3,0-17,0c-0.3,0-0.7,0-1,0c-1.4-0.1-1.7-0.5-1.6-1.8c0-0.5,0.1-1.1,0.2-1.7c-0.8-0.1-1.5-0.2-2.3-0.4
			c3.8-15.3,7.5-30.5,11.2-45.7c1.5-0.4,52.2-0.6,54.7-0.1c-0.2,1-0.3,2-0.5,3c0.7,0.5,1.6,0.8,1.7,1.4c0.2,0.7,0,1.6-0.2,2.4
			c-9.6,38.5-19.2,77-28.8,115.5C651.6,307.5,651.4,308.6,651,310.1z M728.7,311c-1.1,0-1.8,0-2.4,0c-4.6-0.1-9.1,0.1-13.7,0
			c-20.3-0.1-40.5,0-60.8,0c-0.8,0-1.5,0.1-2.3,0.1c-1.6,0-2-0.5-1.6-2c0.1-0.5,0.3-1.1,0.4-1.6c2.7-10.7,5.4-21.4,8-32.1
			c7.3-29.3,14.6-58.6,21.9-87.9c0.2-0.8,0.3-1.6,0.5-2.6c-2.2,0-4.2,0-6.2,0c-1.9,0-3.9,0-5.8,0c-2,0-4,0-6,0c-1.9,0-3.9,0-5.8,0
			c-2,0-4,0-6,0c-1.9,0-3.9,0-5.8,0c-2,0-4-0.1-6,0c-1.9,0.1-3.8,0.7-5.6,0.3c-2-0.5-3.9-0.2-5.9-0.3c-3.7,14.9-7.3,29.6-10.9,44.5
			c0.9,0,1.6,0,2.5,0c0.3-0.8-0.3-2.1,1.2-2.3c0.1,0.7,0.2,1.4,0.3,2.2c0.5,0.1,0.9,0.2,1.3,0.2c4.9,0,9.8,0,14.7,0
			c2.3,0,4.7,0,7,0.1c0.4,0,0.9,0.3,1.1,0.7c0.1,0.2-0.2,0.8-0.4,1.1c-0.2,0.3-0.6,0.5-0.9,0.8c-4.1,3.3-8.2,6.6-12.3,9.8
			c-0.7,0.6-1.5,1.1-1.7,2.2c-0.1,0.4-0.8,0.8-1.3,1.1c-0.4,0.3-1,0.3-1.4,0.7c-4.5,3.3-8.9,6.7-13.2,10c0.1,0.8,0.2,1.2,0.3,1.8
			c-0.8-0.2-1.3-0.3-1.8-0.4c-2,1.5-3.3,3.3-3.8,5.9c-1.2,5.4-2.6,10.8-4,16.2c-3.8,15.1-7.5,30.1-11.3,45.2
			c-0.3,1.1-0.4,2.1,0.7,3.2c-1.2,0-1.6,0.4-1.8,1.3c-0.2,1.1-0.6,2,0.2,3.1c2.4,3.2,5,6.4,7.3,9.6c3,4.2,6.2,8.3,9.3,12.4
			c1.2,1.6,1.9,1.8,3.9,1.3c0.4-0.1,0.8-0.2,1.2-0.2c0.9,0.1,1.9,0.4,2.8,0.4c5.3,0,10.5,0.1,15.8,0c1.8,0,3.6,0.4,5.3-0.4
			c0.2-0.1,0.5,0,0.8,0.1c2.7,0.7,5.5,0.2,8.1,0.4c0.3-0.6,0.6-1.1,1-1.8c0.2,0.8,0.2,1.3,0.3,1.8c2.1,0,4.1,0.1,6.1,0
			c1,0,2.1-0.4,3.2-0.6c0.2,0.8,1.1,0.6,1.9,0.6c4,0,8,0,12,0c3.5,0,7.1,0,10.6,0c3.6,0.1,7.1-0.1,10.7-0.1c8.5,0.1,17,0,25.5,0
			c0.7,0,1.3,0,2.1,0c0.5-2,0.8-3.8,1.4-5.5c0.3-0.9,0-1.4-0.4-2c-0.1-0.1,0-0.3,0.1-0.5c0.3,0,0.6,0,0.6,0c1-2.8,1.7-5.6,2.4-8.3
			c0.7-2.9,1.4-5.8,2.1-8.6c0.7-2.9,1.4-5.7,2-8.6c-0.6-0.3-1.1-0.5-1.6-0.7c-0.5,0.4-1.1,0.8-1.8,1.3c-0.1-0.6-0.3-1.1-0.2-1.1
			c1.1-0.5,2.3-0.9,3.8-1.5C727.4,317.6,727.8,314.4,728.7,311z"/>
		<path d="M812.5,371.2c18.4,0,36.6,0,54.9,0c0,0.7,0,1.3,0.1,2c0.1,0.6,0.3,1.3,0.7,1.6c0.8,0.6,1,1.4,1,2.4c0,3,0,6.1,0,9.1
			c0,1.8-0.4,2.2-2.2,2.3c-4.8,0-9.7-0.1-14.5-0.1c-3,0-5.9,0.1-8.9,0.1c-2.4,0-4.8,0-7.2,0c-0.6,0-1.1,0.2-1.7,0.7
			c0.3,0.1,0.6,0.4,1,0.4c1.1,0.1,2.2,0.1,3.3,0.1c6.5,0,13,0,19.5,0c0.5,0,0.9,0.1,1.6,0.2c0,0.7-0.1,1.4,0,2.1
			c0.1,0.6,0.3,1.3,0.7,1.6c0.8,0.6,0.9,1.3,0.9,2.2c0,3.1,0,6.2,0,9.3c0,1.8-0.4,2.2-2.2,2.2c-2,0-4-0.2-6-0.2
			c-1.7,0-3.3,0.2-5,0.2c-3.9,0-7.9,0-11.8,0c-2.4,0-2.6,0.3-2.6,2.6c0,4.4,0,8.7,0,13.1c0,2.3-0.3,2.6-2.6,2.7c-3.5,0-6.9,0-10.4,0
			c-1.5,0-2.5-0.4-3.1-1.9c-0.2-0.4-0.4-0.9-0.7-1.2c-2.2-2.1-2.9-5-4.3-7.5c-0.4-0.7-0.4-1.6-0.4-2.4c0-6,0-12-0.1-18
			c0-1.7,1.2-2.2,2.2-3c1-0.8,2.1-1.6,3.5-2.6c-0.9-0.2-1.3-0.3-1.7-0.3c-1.9-0.2-2.1-0.4-2.2-2.3c0-0.3-0.1-0.7-0.1-1.1
			c-0.5-0.2-1-0.4-1.5-0.6C812.5,380.2,812.5,375.8,812.5,371.2z M822.4,373.1c-0.7,0.3-1.4,0.4-2.1-0.3c-0.3-0.3-1-0.5-1.5-0.6
			c-0.9-0.1-1.9-0.2-2.9-0.2c-0.9,0-1.8,0-2.7,0c0,1,0,1.7,0,2.5c0.6,0,1.2-0.1,1.9-0.1c0,0.8-0.1,1.4-0.1,2c-1.7,0.5-1.8,0.5-1.8,2
			c0,1.1,0.2,2.1,0.3,3.4c0.7-0.4,1.1-0.7,1.5-0.9c1.2,0.8,1.3,1.9,0.8,3.2c0.8-0.1,1.3-0.1,1.9-0.2c0-1.3,0.4-2.1,1.6-2.3l-0.1-0.1
			c0.1,1.6,1.2,2.1,2.6,2.5c1.3,0.4,1.5,1.1,0.6,2.1c-1,1.2-2.1,2.4-3.2,3.6c0.6,0.9,0.6,0.9-0.1,2.1c-0.5-0.1-0.9-0.1-1.5-0.2
			c0,1.4,0,2.6,0,3.8c-0.6,0.4-1.1,0.7-1.6,0.9c0.9,1.7,0.8,1.7,0.1,3.9c-0.1,0.4-0.1,0.9,0,1.2c0.5,1.1,1.1,2.2,1.6,3.4
			c0.2,0.5,0.2,1.1,0.2,1.4c-1,1.3-2.5-1-3.2,1.1c0.8,0.3,1.7,0.7,2.7,1.1c-1.1,1,0.5,2.8-1.4,3.5c1,1.5,1,1.5-0.1,3.2
			c0.7,0.8,1.4,1.7,2.3,2.7c-0.9-0.1-1.5-0.2-2.1-0.2c0.3,0.9,0.5,1.7,0.9,2.3c0.3,0.5,0.9,1,1.3,1c3.4,0.1,6.7,0.1,10.1,0.1
			c0.9,0,1.9-0.1,2.9-0.1c0-1.2,0.4-2.3-0.1-3c-0.7-1.1,0.7-1.7,0.2-2.5c-0.2-0.3-0.2-0.6-0.4-0.9c-0.1-0.1-0.2-0.4-0.2-0.4
			c1.1-1.3,0.4-2.8,0.5-4.1c0.1-1.9,0-3.7,0-5.6c0-0.8,0.2-1.6,1.2-1.6c1.9-0.1,3.7-0.3,5.6-0.3c3,0,5.9,0.2,8.9,0.2
			c1.1,0,2.2-0.2,3.1-0.3c0.1-0.7,0.2-1.3,0.2-1.9c0.1-0.6,0.3-1.3,0.1-1.8c-0.8-1.7-0.4-3.5-0.5-5.3c0-0.9,0-1.8,0-2.9
			c-1,0-1.7,0-2.4,0c-3.9,0-7.9,0.1-11.8,0c-5-0.1-4.2,0.9-4.3-4.8c0-0.1,0-0.3,0-0.4c0.5-0.9,0.5-2.1,1.8-2.4
			c0.1,0.4,0.2,0.7,0.3,1.1c5.2-0.1,10.4,0.2,15.7-0.1c0-1.6,0.2-3-0.1-4.4c-0.2-1.3,1.2-2.6,0.4-3.6c-1.1-1.4,1.3-2.5,0-3.8
			c-4,0-8,0-12,0c-0.1,0.8-0.2,1.6-0.3,2.3c-1.5-0.4-0.1-1.5-0.8-2.3c-1.2,0-2.6,0-4,0c-0.2,0.6-0.4,1.2-0.6,1.6
			c0.7,1.1,1.3,2,2.2,3.2c-1.2-0.2-1.9-0.4-2.7-0.6c0.4-0.9,0.1-1.4-0.9-1.5c-0.7-0.1-1.3-0.2-2.2-0.4c0.7-0.9,1.1-1.5,1.5-2
			c0-0.2-0.1-0.3-0.1-0.5c-1,0.6-2.1,0.8-3.1,0.3c0,0-0.1,0.1-0.2,0.1c-0.4,0.1-0.9,0.2-1.3,0.2c-0.8-0.1-1.5-0.4-2.2-0.4
			C822.2,372,822,372.5,822.4,373.1L822.4,373.1z M866.6,372c-4.8,0-9.4,0-14,0c-1.4,0-2,0.6-2,2c0,3,0,5.9,0,8.9
			c0,0.3,0.2,0.6,0.3,1c5.3,0,10.5,0,15.8,0C866.6,379.9,866.6,376.1,866.6,372z M852.1,402.5c2.2,0,4.5,0,6.9,0c0-3.9,0-7.8,0-11.9
			c-2.6,0-5.1,0-7.6,0c-0.9,1.7,1,2.1,1.3,3.2c-0.1,0.2-0.2,0.4-0.2,0.5c-0.4,1.7,0.6,3.6-0.7,5.2c-0.2,0.2,0,0.7,0,1
			C851.8,401.1,852,401.7,852.1,402.5z M813.2,410.6c0.2,2.4-0.1,4.8,2.4,6.5c-0.4-2.3-0.7-4.4-1.1-6.5
			C814.2,410.6,813.9,410.6,813.2,410.6z M815,392.6c-2.3,0.8-1.8,2.3-1.8,3.6c0,0.7-0.5,1.6,0.9,2
			C814.4,396.3,814.7,394.6,815,392.6z M813.2,401.8c0.1,0,0.1-0.1,0.2-0.1c0-0.8,0-1.6,0-2.3c-0.1,0-0.1,0-0.2,0
			C813.2,400.2,813.2,401,813.2,401.8z M813.6,383c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0.2,0.5,0.4,0.7,0.7c0.1-0.1,0.2-0.2,0.4-0.4
			C814,383.4,813.8,383.2,813.6,383z"/>
		<path d="M529.8,388.9c-0.5-0.1-0.8-0.3-1.2-0.4c-0.3-0.1-0.8-0.2-0.9-0.5c-0.7-1.7-1.3-3.3-2.4-4.8c0-3.9,0-7.8,0-12
			c0.9,0,1.6-0.1,2.3-0.1c6.7,0,13.3,0,20,0c10.1,0,20.3,0,30.4,0c0.3,0,0.6,0,0.8,0c1.4,0.1,1.8,0.5,1.8,1.9c0,0.9,0.2,1.6,0.9,2.4
			c0.5,0.5,0.7,1.6,0.7,2.5c0.1,4.9,0,9.8,0,14.7c0,8.7,0.1,17.4,0.1,26.1c0,1.7-0.1,3.3-0.2,5c-0.1,1.4-0.5,1.8-1.9,1.9
			c-0.4,0-0.8,0-1.2,0c-14.6,0-29.2,0-43.7,0c-2.1,0-3.6-0.5-4.8-2.2c-0.6-0.8-1.5-1.5-2-2.3c-0.6-0.9-1.1-1.9-1.6-2.9
			c-0.2-0.4-0.3-0.8-0.5-1.1c-1.1-1.3-1.2-2.8-1.2-4.4c0.1-4.6,0-9.3,0-13.9c0-1.6,0-3.3,0.1-4.9c0-0.4,0.2-0.8,0.5-1.1
			c0.6-0.6,1.3-1.1,1.9-1.6C528.5,390.5,529.5,390.1,529.8,388.9z M527.2,399.9c-1.1-0.4-1.1,0.4-1.1,1.1c0,2.7,0,5.4,0,8.1
			c0,2-0.5,4.2,0.2,5.9c0.7,1.9,2.6,3.3,4,5c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.2,0.7,0.5,1.1,0.5c3.6,0,7.2,0,10.8,0
			c-0.2-0.8-0.3-1.5-0.4-2.2c0.7-0.3,1.2-0.4,1.8-0.6c0.4,0.5,0.7,1,1.1,1.7c0.8-0.3,1.5-0.6,2.3-0.9c0.1-1.8,0.1-1.8-1.2-3.7
			c-2.3,0.2-4.8,0.4-7.6,0.6c0.4-0.5,0.5-0.9,0.7-1c0.8-0.4,1.6-0.8,2.4-1.1c0.5-0.1,1.1,0,1.7,0.1c0.5,0.1,1.1,0.4,1.5,0.2
			c1.8-0.5,3.1-2.8,2.8-4.9c-1.2-0.1-2.3-0.2-3.6-0.3c0.1-0.4,0.2-0.5,0.2-0.6c-0.1-5.4-0.1-10.7-0.2-16.1c0-1.9-0.1-3.9-0.1-5.8
			c0-1.9,0.5-2.4,2.4-2.5c1.2,0,2.4,0,3.8,0c0.2-4.1,0.3-8-0.7-11.8c-0.2-0.1-0.4-0.2-0.5-0.2c-3.3,0-6.6-0.1-9.9,0
			c-0.4,0-0.9,0.4-1.2,0.6c-1-0.2-1.9-0.6-2.8-0.6c-2.3,0.1-4.5,0.4-6.8,0c-0.8-0.1-1.6,0-2.5,0.1c0,1.6-0.2,3,0,4.3
			c0.2,1.3,0.5,2.5,0,3.8c-0.1,0.2,0.4,0.7,0.6,1c0.6,0.9,1.2,1.7,1.9,2.8c1.7,0,3.7,0,5.7,0c0.5,0,1.2,0.3,1.3,0.7
			c0.2,0.4-0.1,1-0.4,1.4c-1.1,1.4-2.7,2.1-4.1,3.3c-1.1,0.9-2.2,1.8-3.2,2.6c-0.1,0.8-0.1,1.4-0.2,2.2c-0.6-0.3-1.1-0.5-1.6-0.7
			c0.2,1.1,0.3,2,0.4,3c0.3,0.1,0.6,0.2,0.9,0.3c-0.1,0.5-0.4,1.1-0.2,1.4C528.1,398.9,527.2,399.3,527.2,399.9L527.2,399.9z
			 M550.3,413.3L550.3,413.3c0,0.2,0.1,0.5,0,0.6c-1.4,1.3-1.1,2.8-0.9,4.4c1.3,0.4,2.2,1.1,2.1,2.6c1.5,0,2.9,0,4.4,0
			c-0.2-0.6-0.3-1-0.4-1.5c0.8-0.1,1.6-0.3,2.7-0.5c-0.6,0.8-1,1.3-1.5,2c7.9,0,15.4,0,23.1,0c0-16.3,0-32.5,0-48.6
			c-1.8-0.5-26.4-0.5-28.2,0c0,0.4-0.2,0.9-0.1,1.2c0.8,1.3,0.6,2.6,0.6,4c0,1.6-0.1,3.2,1.5,4c-0.1,0.9-0.2,1.6-0.3,2.6
			c2.1,0,4,0,6,0c1.8,0,2.1,0.2,2.1,2c0,7,0,14,0,20.9c0,1.8-0.3,2-2.1,2.1c-2.3,0-4.7,0-7,0c-0.7,0-1.3,0.1-2.1,0.1
			C550,410.7,549.8,412,550.3,413.3z M560.5,389c-0.4-0.2-0.5-0.3-0.6-0.3c-3.7,0-7.4-0.1-11.2-0.1c-1.3,0-1.6,0.5-1.7,1.9
			c-0.1,2.1-0.1,4.3-0.1,6.4c0,3.6,0,7.3,0,11.1c1,0.2,1.8,0.5,2.6,0.4c3,0,6.1-0.1,9.1-0.2c0.6,0,1.2-0.1,1.8-0.1
			C560.5,401.6,560.5,395.3,560.5,389z M548.8,420.9c-0.6-0.5-0.8-1.4-1.7-0.9c-0.1,0.1-0.2,0.4-0.2,0.5
			C547.3,421.4,548,421,548.8,420.9z"/>
		<path d="M674.4,388.7c-0.3,0-0.7-0.1-1-0.1c-1.7-0.2-1.8-0.4-2.1-2c-0.2-1.4-0.2-1.4-1.6-2.1c0-4.4,0-8.8,0-13.4
			c18.4,0,36.7,0,55.2,0c0,0.8,0,1.5,0.1,2.2c0.1,0.5,0.2,1.2,0.5,1.5c1.2,0.7,1.1,1.8,1.1,2.9c0,15,0,30,0,45c0,0.4,0,0.8,0,1.2
			c-0.1,1-0.6,1.5-1.6,1.6c-0.6,0-1.2,0-1.9,0c-11.1,0-22.1,0-33.2,0c-3.7,0-7.3,0-11,0c-1.4,0-2.5-0.4-3.4-1.5
			c-0.9-1.1-1.8-2.1-2.8-3.1c-0.8-0.9-1.5-1.8-1.6-3c0-0.3-0.1-0.6-0.3-0.8c-1.8-1.5-1.3-3.6-1.3-5.6c-0.1-5.7,0-11.3,0-17
			c0-1.1,0.4-1.8,1.2-2.4c1.3-1,2.5-2.1,3.8-3.1C674.5,389,674.5,388.8,674.4,388.7z M671.5,402.5
			C671.5,402.5,671.5,402.5,671.5,402.5c-0.2,0.5-0.6,1.1-0.6,1.6c-0.2,1.3-0.3,2.6-0.3,3.9c-0.1,1.3-0.1,2.6,0,3.9
			c0.1,1.1-0.3,2.5,1,3.3c-0.3,1.5,0.9,2.1,1.9,2.7c0.5,0.3,0.7,0.4,0.8,0.9c0.6,1.7,1.2,2.2,3,2.1c1.2,0,2.3-0.3,3.5-0.3
			c2.1,0,4.2,0.3,6.3,0.3c5.3,0,10.5,0,15.8,0c0.6,0,1.1-0.4,1.8-0.6c0.2,0.9,1.1,0.6,1.9,0.6c5.3,0,10.6,0,16,0
			c0.5,0,1.1-0.1,1.8-0.1c0-2.2,0-4.2,0-6.3c0-2,0-4,0-6c0-2.1,0.4-4.2-0.1-6.1c-0.4-2,0.2-3.8,0-5.8c-0.1-2.1,0-4.1,0-6.2
			c0-2.1,0-4.1,0-6.2c0-2,0-4,0-6c0-2.1,0-4.1,0-6.3c-3.4,0-6.6,0-9.9,0c-0.1,0.6-0.1,1-0.1,1.3c0.2,3-0.3,5.8-1.2,8.6
			c-0.4,1.2-0.5,2.5-0.7,3.7c-0.5,2.4-1.2,4.8-1.5,7.3c-0.3,1.9-0.6,3.8-1.5,5.6c-0.1,0.2,0,0.4,0,0.6c-0.3,1.7-0.6,3.4-0.9,5.1
			c-0.2,0-0.3,0-0.5,0c-0.1-0.8-0.2-1.7-0.2-2.5c-0.6-0.2-1.2-0.4-2-0.7c0,2.3,0,4.4,0,6.4c0,1.3-0.3,1.6-1.6,1.6
			c-1.8,0-3.6,0-5.4,0c-0.6,0-1.3,0.4-1.7,0.5c-1-0.2-1.9-0.4-2.8-0.4c-1.1-0.1-2.2-0.1-3.3-0.1c-1.8,0-2.2-0.4-2.2-2.2
			c0-6.8,0-13.7,0-20.5c0-2,0.2-2.3,2.3-2.3c4.1,0,8.3,0,12.4,0c2.1,0,2.2,0.1,2.2,2.2c0,3.7,0,7.4,0,11.2c0,1.5,0.8,2.5,1.8,3.4
			c0.5-1.9,1-3.6,1.5-5.4c-0.3-0.2-0.7-0.5-1.3-0.9c0.7-0.4,1.3-0.5,1.5-0.8c0.5-1.4,1-2.8,1.3-4.2c0.5-2.6,0.9-5.3,1.3-8
			c0.2-1.2,0.3-2.5,0.8-3.6c0.8-2,0.3-3.8,0.3-5.7c-2.3-0.5-33.9-0.4-35.4,0c-0.1,0.9-0.1,1.7-0.2,2.6c-0.1,0-0.2,0-0.3,0
			c-0.1-0.9-0.2-1.8-0.2-2.8c-2.2,0-4.2,0-6.3,0c0,4,0,7.8,0,11.9c2.2,0,4.2,0,6.3,0c0.3,0,0.6-0.5,1-0.8c0.3,0.3,0.7,0.7,1,0.8
			c0.6,0.2,1.3,0.1,1.5,0.9c0.2,0.9-0.5,1.2-1,1.6c-1.8,1.4-3.4,3-5.3,4.2c-2.7,1.7-4.1,3.9-3.5,7.2c0.2,0.9,0,1.8,0,2.7
			c0,0.6,0,1.1,0.1,1.7C670.7,402.7,671,402.9,671.5,402.5z M704.7,408.1c0.5-2.7,0.4-17.5-0.1-19.4c-0.5-0.1-1.2-0.2-1.8-0.2
			c-2.9,0-5.8,0-8.7,0c-2.5,0-2.8,0.3-2.8,2.7c0,5,0,10,0,14.9c0,0.6,0.1,1.2,0.2,1.9C696,408.1,700.4,408.1,704.7,408.1z"/>
		<path d="M385.1,388.7c-0.3-0.1-0.5-0.1-0.8-0.2c-2-0.3-2-0.3-2.2-2.2c-0.1-1.2-0.1-1.2-1.6-1.8c0-4.3,0-8.7,0-13.3
			c1.8,0,3.5-0.5,5.3-0.1c0.5,0.1,1.1,0,1.7,0c7.6,0.3,15.2-0.1,22.8,0c7.6,0.1,15.2,0,22.8,0c0.8,0,1.6,0,2.5,0
			c0,0.9,0,1.6,0.1,2.3c0.1,0.5,0.3,1.2,0.6,1.4c1,0.7,1,1.6,1,2.6c0,6.1,0,12.2,0,18.3c0,1.6-0.6,2.6-2,3.2c-2,0.9-3.9,1.8-5.8,2.8
			c-0.7,0.3-1.3,0.7-2.2,1.2c0.7,0.5,1.1,1,1.6,1.3c1.8,1.1,3.7,2.2,5.5,3.2c0.9,0.5,1.6,1,1.5,2.2c-0.1,0.6,0.2,1.3,0.6,1.7
			c1,1,1.1,2.2,1.1,3.4c0,3,0,5.9,0,8.9c0,1.4-0.4,1.9-1.8,2c-3.4,0.1-6.8,0.1-10.2-0.1c-0.9,0-1.8-0.6-2.6-1.1
			c-5.5-3.5-11-7.1-16.5-10.7c-0.6-0.4-1.3-0.9-1.9-1.2c-1.7-0.9-2.3-0.6-2.4,1.2c-0.1,2.3,0,4.6-0.1,6.8c0,1,0,2.1,0,3.1
			c-0.1,1.4-0.4,1.8-1.8,1.8c-4.3,0.1-8.6,0.1-12.9,0.1c-1,0-1.7-0.5-2.2-1.4c-1.5-2.8-3.1-5.5-4.6-8.3c-0.2-0.4-0.2-0.9-0.2-1.4
			c0-4.6,0-9.2,0-13.9c0-1.9,0-3.7-0.1-5.6c-0.1-1.6,0.7-2.7,2-3.5c1.1-0.7,2-1.6,3-2.4C385.3,389,385.2,388.9,385.1,388.7z
			 M382.6,400.3c-0.2,0.1-0.7,0.2-0.7,0.3c-0.2,2.3-0.4,4.7-0.6,7C382.1,405.1,382.8,402.8,382.6,400.3c0.4-0.4,0.8-0.9,1.2-1.3
			c1.2,0.3,1.6,0.8,1.1,1.7c-0.8,1.7-0.9,3-0.2,5c0.5-0.8,1-1.4,1.5-2.1c0.5,0,1,0.1,1.6,0.1c-0.5,0.8-1,1.4-1.5,2.2
			c0.6,0.4,1.1,0.7,1.6,1.1c-0.7,0.9,0.4,1,0.5,1.2c-0.3,1.3-0.6,2.4-0.9,3.4c-0.6,1.8-0.7,3.9-2.9,4.9c-0.4-0.1-0.9-0.3-1.3-0.5
			c-0.9,1.3,0.6,1.6,0.6,2.4c0,1,0.2,1.7,0.8,2.4c4.9,0,9.7,0,14.4,0c0.7-1.1,0.5-1.9-0.1-2.7l-0.1,0.1c0.2-0.4,0.6-0.8,0.5-1.2
			c-0.5-2.9,0.3-5.7,0.1-8.5c-0.1-1.7,0.9-2.2,2.4-1.3c1.2,0.7,2.2,1.5,3.4,2.2c1,0.6,2.1,1.1,3.4,1.7c0.3,0.7,2.6,2.5,5.1,3.6
			l-0.1-0.1c3,2,6,4.1,9.1,6.1c0.4,0.2,0.9,0.3,1.4,0.3c3.1,0,6.2,0,9.3,0c0.5,0,0.9-0.1,1.3-0.2c0-4.1,0-8.1,0-12.1
			c-0.4-0.3-0.7-0.6-1.2-0.8c-3.2-1.9-6.4-3.8-9.6-5.6c-0.9-0.5-1.4-1.2-1.3-2.2c0-0.9,0.8-1.3,1.5-1.5c0.3-0.1,0.5-0.2,0.8-0.3
			c3.2-1.5,6.5-3.1,9.7-4.6c0-7.3,0-14.4,0-21.6c-11.8,0-23.3,0-34.8,0c-1.3,1.7-1.5,1.8-2.3,0c-1.5,0.1-3.1,0.4-4.6,0.3
			c-1-0.1-1.5,1.3-2.6,0.6c-0.6,0.7-1.1,1.4-1.7,2c-0.9,1.1-1.8,2.2-2.8,3.3c-0.2-0.5-0.4-0.8-0.5-1c-0.4-0.3-1.2,1.1-1.3-0.1
			c0.8-0.7,1.5-1.1,2-1.8c0.5-0.7,1.7,0.1,1.8-1.2c-1-0.1-1.1-0.8-1.1-1.6c0-0.1-0.3-0.2-0.4-0.4c-0.2,0.4-0.3,0.6-0.5,1
			c-0.6-1.5-1.3-1.7-2.5-0.7c-0.8,0.7-1.5,1.4-2.3,2c0,3.1,0,6.2,0,9.4c1.7,0,3.4,0.1,5.1,0.1c1.7,0.1,3.4,0.1,5.4,0.2
			c-0.5,0.8-0.9,1.2-1.1,1.7c-0.2,0.6-0.3,1.3-0.2,1.9c0.3,1.3,0.2,1.5-1.5,1.8c-0.1-0.6-0.2-1.1-0.3-1.6c-1.1,0.8-2.1,1.5-3.2,2.2
			c-0.9,0.6-1.5,1.3-1.3,2.6c-0.6,0-1,0-1.4,0c-1.3,0.1-2.1,1.4-1.4,2.3c0.4-0.4,0.8-0.7,1.2-1.1c0.9,1.3,0.1,1.9-0.7,2.4
			c0.9,0.8,2.1,1.2,2,2.7C383,399,382.2,399,382.6,400.3z M386.3,411.3c-0.4-0.1-0.7-0.3-0.9-0.2c-0.4,0.1-0.8,0.3-1.1,0.6
			c0,0,0.1,0.5,0.2,0.5c0.4,0,0.9,0,1.3-0.1C386,412,386.1,411.7,386.3,411.3z M386.2,410.4c-0.1-0.2-0.1-0.4-0.1-0.5
			c-0.5-0.2-0.9-0.4-1.4-0.5c0,0-0.2,0.4-0.3,0.6c0.5,0.2,0.9,0.4,1.4,0.5C385.8,410.6,385.9,410.5,386.2,410.4z M381.4,412.5
			c0.1,0,0.1,0,0.2,0c0-0.9,0-1.7,0-2.6c-0.1,0-0.1,0-0.2,0C381.4,410.7,381.4,411.6,381.4,412.5z M387.8,372.2c0,0.2,0,0.3,0,0.5
			c0.3,0,0.6,0,0.9-0.1c0,0,0-0.3,0-0.3C388.4,372.3,388.1,372.3,387.8,372.2z M383.9,409.1c-0.2-0.1-0.4-0.3-0.5-0.2
			c-0.1,0.2-0.3,0.4-0.3,0.6c0,0.1,0.3,0.2,0.4,0.3C383.6,409.5,383.7,409.4,383.9,409.1z"/>
		<path d="M243.1,388.8c-0.9-0.2-1.3-0.4-1.8-0.4c-1.2,0-1.8-0.6-1.9-1.7c-0.1-0.8,0-1.7-1.2-1.9c-0.2,0-0.4-0.5-0.4-0.8
			c-0.1-0.9-0.2-1.8-0.1-2.7c0-3.2,0.1-6.3,0.2-9.7c1.9-0.1,3.6-0.4,5.3-0.4c15.9,0,31.8,0,47.7,0c0.7,0,1.4,0,2.1,0
			c0.1,0.8,0,1.4,0.1,2c0.2,0.6,0.3,1.4,0.8,1.8c0.7,0.6,0.8,1.4,0.8,2.2c0,6.3,0,12.6,0,18.9c0,1.4-0.6,2.2-1.8,2.7
			c-2.3,1.1-4.6,2.2-6.9,3.3c-6.5,3-13.1,5.9-19.6,8.9c-1.8,0.8-3.6,1.8-5.4,2.7c-1.1,0.5-1.6,1.4-1.6,2.6c0,1.2,0,2.5,0,3.7
			c0,1.1,0,2.2,0,3.3c-0.1,1.7-0.7,2.2-2.4,2.2c-1.4,0-2.8,0.1-4.1,0.1c-2.3,0-4.7-0.1-7,0c-1.8,0.1-2.9-0.6-4-2
			c-1.7-2.1-2.3-4.7-3.8-6.9c-0.3-0.4-0.3-1.1-0.3-1.6c0-4.8,0-9.7,0-14.5c0-1.5,0.4-3,0-4.5c-0.5-2.2,0.7-3.5,2.4-4.7
			C241.1,390.8,241.9,389.8,243.1,388.8z M239.2,398.8c-1.3,1.7-0.3,3.6-0.6,5.1c0.7,0.7,1.2,1.2,2,2c-0.9,0.1-1.4,0.2-1.9,0.3
			c-0.5,3.1,0.4,6.2-0.1,9.2c-0.1,0.5,0.3,1,0.5,1.5c0.3,0.6,0.9,1.1,1.2,1.7c1,1.8,2.2,3.3,4.6,2.2c0.1,0,0.3,0.1,0.4,0.1
			c1.6,0.1,3.1,0.1,4.7,0.2c1,0,2.1,0,3.1,0c0.9,0,1.9-0.8,2.9,0c0.1,0,0.8-0.4,0.8-0.7c0.1-1.5,0.2-3.1,0-4.5
			c-0.1-0.6-1.1-1.1-1.8-1.5c-0.2-0.1-0.6,0.1-0.8-0.1c-0.4-0.3-0.7-0.8-1-1.2c0-0.1,0.2-0.5,0.3-0.5c0.4,0.1,0.8,0.3,1.2,0.4
			c0.2,0.1,0.4,0.1,0.6,0.1c0.5-0.3,1.1-0.7,1.6-1c-0.4-1.4-0.1-1.7,1.4-2.6c3.9-2.3,8.2-3.9,12.3-5.8c3.3-1.5,6.8-2.7,9.8-4.9
			c0.2-0.2,0.5-0.2,0.8-0.3c4-1.1,7.3-3.4,11-4.8c0-7.4,0-14.5,0-21.7c-4.9-0.4-24.4-0.2-25.9,0.3c0.3,0.5,0.6,1.1,0.9,1.6
			c0.6,0.4,1.6-0.2,2.2,0.7c-0.9,0.1-1.8,0.4-2.4,0.1c-0.7-0.3-1.2-1.2-1.7-1.8c0.1-0.3,0.3-0.6,0.5-1.1c-0.9,0.1-1.7,0-2.2,0.4
			c-0.7,0.5-1.1,0.5-1.8,0c-0.4-0.3-1-0.3-1.5-0.3c-1,0-2.1,0.1-3.1,0.1c-5.2,0-10.4-0.1-15.5-0.1c-0.6,0-1.1,0.2-1.8,0.3
			c1.7,2.2,3.5,1.9,5.4,0.9c0.5,1.7,1.4,2.3,3,2.2c0.6-0.1,0.9,0.3,1.2,1.3c-1.3-0.3-2.3-0.5-3.6-0.7c-0.4,0.5-0.9,1.1-1.4,1.8
			c-2.1-0.5-3.7,0.9-5.6,1.5c0.1,1.5-0.4,2.9,0.4,4.4c1.8,0.1,3.7,0.2,5.5,0.3c1.2,0,2.3,0.3,3.4,1.1c-0.2,0.5-0.3,1.2-0.6,1.5
			c-1.2,0.9-2,2.1-2.6,3.5c0,0.1-0.2,0.1-0.4,0.1c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1,1-2.2,1.9-3.2,2.9c-0.6,0.6-0.6,2.3,0.2,2.6
			c1.3,0.6,1.5,1.9,3,2.9c-0.9,0.1-1.3,0.1-1.7,0.1c-0.7,0-1.5-0.1-2.2-0.1C238.9,397.9,238.9,398.3,239.2,398.8L239.2,398.8z
			 M241.7,374.5c-0.8,0.4-1.5,0.8-2.3,1.2c0.4,0.8,0.7,1.4,1.1,2C241.4,376.9,242.5,376.3,241.7,374.5z M238.7,396.7
			c0.1,0,0.1,0,0.2,0c0-0.7,0-1.4,0-2.1c-0.1,0-0.1,0-0.2,0C238.7,395.3,238.7,396,238.7,396.7z"/>
		<path d="M77.4,122.7c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,0.1-0.4,0.4-0.5,0.4c-0.3-0.1-0.5-0.4-0.7-0.6C77,122.7,77.2,122.7,77.4,122.7z
			"/>
		<path d="M226.9,327.9c0.6,0.7,1.2,1.3,1.7,1.9c0,1.2,0,2.2,0,3.2c0.4-0.1,0.7-0.2,1.3-0.4c0.1,1.7,0.3,3.3,0.4,4.9
			c-0.8,0.7-1.4,1.4-2.2,2.1c-0.4-1.5-0.7-2.9-1.1-4.3c0-0.1,0-0.2,0-0.2c1-1.3,0-2.8,0.3-4.2c0.2-0.9-0.4-1.9-0.6-2.9
			C226.7,328,226.8,328,226.9,327.9z"/>
		<path d="M228.4,261.4c-0.7-0.6-1-1.4-0.1-2.5c0.3-0.4,0.5-1.5,0.2-1.9c-1-1.2-0.6-2.7-0.9-4c-0.2-0.8,0.5-1.6,1.6-2.1
			c-0.8,1.6-0.6,2.9-0.2,4.5c0.5,1.6,0.4,3.5,0.5,5.2c0.1,1.8,0,3.7,0,5.5c0.1,1.6-0.2,3.1-1.4,4.5c-0.3-0.2-0.7-0.3-1.2-0.5
			c0.2-0.3,0.4-0.5,0.6-0.7c0.7-0.8,1.5-1.4,0.4-2.6c-0.3-0.3-0.2-1.2-0.1-1.8C228,263.7,228.2,262.7,228.4,261.4z"/>
		<path d="M83.1,117.3c0.3,0,0.7,0,1.1,0.1c0.1-0.7,0.2-1.3,0.4-2c0.7-0.1,1.3-0.1,1.9-0.2c0-1.6,0.5-2.1,3.9-3
			c0.1,1-0.5,1.9-1.4,2.3c-0.9,0.5-2,0.9-2.7,1.6c-0.7,0.7-0.9,1.8-1.4,2.9C84.1,118.7,83.7,117.8,83.1,117.3L83.1,117.3z"/>
		<path d="M88.3,108.4c-0.2,0.3-0.3,0.5-0.5,0.8c-1.3-0.8-1.7,0.5-2.5,0.9c-0.4,0.2-0.9,0.2-1.3,0.2c-0.6,0-1.2-0.1-1.8-0.1
			c-0.2-1.8,2-1.8,2.3-3.2C85.8,107.2,87.2,107,88.3,108.4z M86.5,108.6c0-0.2,0-0.4,0-0.6c-0.4,0-0.9,0.1-1.3,0.1
			c0,0.1,0,0.3,0,0.4C85.6,108.5,86.1,108.5,86.5,108.6z"/>
		<path d="M228.9,346.4c-0.3,0-0.5,0-0.9-0.1c-0.1-0.7,0.6-1.2-0.1-2.1c-0.6-0.8-0.1-2.3-0.1-3.6c1.5,0.8,2,1.6,1.6,3.1
			c-0.2,0.6,0.2,1.3,0.3,2.1C229.6,345.9,229.2,346.2,228.9,346.4C228.8,346.4,228.9,346.4,228.9,346.4z"/>
		<path d="M93.9,111.7c-2.8,0.3-2.8,0.3-4.1-3c0.4,0.4,0.9,0.7,1.3,1.1c0.6,0.6,1.1,0.7,1.8,0.3c0.3-0.2,0.7-0.2,1-0.3
			c0.1,0.4,0.2,0.8,0.3,1.3C94.2,111.2,94,111.5,93.9,111.7z"/>
		<path d="M228.3,243.9c0.5,1.8,0.9,3.1,1.2,4.5c0.1,0.4-0.4,1-0.6,1.5c-0.7-0.3-1.6-1.8-1.5-2.5
			C227.6,246.4,227.9,245.4,228.3,243.9z"/>
		<path d="M83.8,120.6c-0.1,0.4-0.2,0.7-0.4,1.4c-1.2-1.8-3.5-0.9-5.2-2.7c1.5,0.1,2.7,0.6,3.2-1.2c0.6,0.8,1,1.4,1.4,1.8
			C83.1,120.3,83.5,120.4,83.8,120.6L83.8,120.6z"/>
		<path d="M401.4,114.1c-0.3,0.9-0.5,1.6-0.7,2.3c-1.3-0.4-2.4-0.6-3.6-1C398.1,113.8,399.5,113.9,401.4,114.1z"/>
		<path d="M100.8,150c0-0.7,0-1.3,0-2c0.6-0.3,1-1.6,2.3-1C103.4,149.2,102.8,149.9,100.8,150z"/>
		<path d="M443.8,93.4c0.6-2,0.6-2,2.4-1.7c0.3-0.5,0.6-1,0.9-1.5c0.4,0.3,0.8,0.5,1.1,0.8c-0.5,0.6-1,1.5-1.6,1.9
			C445.9,93.2,444.9,93.2,443.8,93.4z"/>
		<path d="M219.9,302.2c1.4-1.3,3.3-0.9,5-1.4c0.2-0.1,0.5,0.3,0.7,0.6c-0.9,0.2-1.4,1.1-2.6,0.6c-0.4-0.2-1.2,0.6-2,1.1
			C220.7,302.8,220.2,302.5,219.9,302.2L219.9,302.2z"/>
		<path d="M69,101.5c1.2-0.3,2.1-0.4,3-0.6c0.5,0.6,0.3,1.1-0.2,1.6c-0.3,0.3-0.7,0.8-1.1,0.8c-0.5,0-1.1-0.2-1.5-0.5
			C69,102.5,69.1,102,69,101.5z"/>
		<path d="M211.2,305.5c-1.7,0.6-3.4,1.2-5.4,2c0.2-0.6,0.2-1.1,0.4-1.3c1.5-1,3.2-1.2,4.8-1.2C211.1,305.1,211.2,305.3,211.2,305.5
			z"/>
		<path d="M102.8,116.3c2.2,1.6,2.2,1.6,2.7,3.4c-0.8-0.8-1.3-1.3-1.9-1.8c-0.7,0.3-1.5,0.7-2.5,1.2
			C101.7,118.1,102.2,117.3,102.8,116.3z"/>
		<path d="M402.3,114c1.6-0.8,2.9-2.1,5.1-1.5c-0.7,0.9-1.4,1.4-2.4,1.4C404.2,113.8,403.4,115.1,402.3,114z"/>
		<path d="M434.9,45.8c0-1.4,1.3-0.7,1.5-1.3c0.9,0.5,1.7,1,2.6,1.6C437.6,46.9,436.3,46.4,434.9,45.8z"/>
		<path d="M434.7,40.7c0.6-0.9,1-1.6,2.2-1.1c0.6,0.3,1.3,0.3,2.2,0.4c-0.3,0.4-0.5,0.8-0.7,0.8C437.2,40.9,436.1,40.8,434.7,40.7z"
			/>
		<path d="M224.7,277.2c0.1-2.3-1.4-4.6-0.2-7C225.3,272.5,224.8,274.8,224.7,277.2z"/>
		<path d="M226.9,302.4c-1-0.8-0.6-1.7-0.5-2.5c0-0.2,1-0.5,1.1-0.4c0.6,0.9,1.2,0.1,1.8,0c0.2,0.9-0.3,1-1,1.2
			C227.5,301,226.7,301.3,226.9,302.4z"/>
		<path d="M450.3,81.9c0.1,0.6,0.2,1.2,0.3,1.9c-0.8-0.1-1.5-0.1-2.4-0.2c0-0.5,0-1,0-1.7C448.9,81.9,449.6,81.9,450.3,81.9z"/>
		<path d="M408.9,119.4c-0.7,1.8-2.1,1.3-3.8,1.1c0.5-0.5,0.7-1,1-1.1c0.9-0.2,1.8-0.3,2.8-0.4C408.9,119.2,408.9,119.3,408.9,119.4
			z"/>
		<path d="M64.3,114.1c0.8-0.1,1.4-0.1,2.3-0.2c-0.6,1.1-1,1.9-1.6,2.9C63.5,116.3,64.6,115.1,64.3,114.1z"/>
		<path d="M71.2,110.7c-0.7-0.1-1.4-0.3-2.2-0.4c0.6-0.9,1-1.5,1.4-2.1c0.4,0.2,0.9,0.4,1.5,0.7C71.6,109.3,71.5,109.8,71.2,110.7z"
			/>
		<path d="M454.4,82.6c-1,2-1,2-2.9,1.6c0.3-0.6,0.6-1.3,0.9-2c0.3,0.1,0.7,0.3,1.1,0.4C453.8,82.6,454.1,82.6,454.4,82.6z"/>
		<path d="M444.4,94.6c0.4,0.9,0.9,1.8-0.2,2.4c-0.9,0.6-1.8,0.3-2.4-0.6c0.6-0.1,1.2-0.2,1.7-0.5C443.9,95.7,444.1,95.1,444.4,94.6
			z"/>
		<path d="M429.4,39.5c0.4-0.7,0.7-1.4,1.2-2.1c1,0,2.1-0.5,3.3,0.8C431.9,37.8,430.8,38.9,429.4,39.5z"/>
		<path d="M303.2,158.1c0.8,1.3,1.8,2.1,1.8,3.4C303.1,161,302.8,160.5,303.2,158.1z"/>
		<path d="M427.4,53.3c0.9-1,1.5-1.6,2.5-2.5c0.2,0.8,0.5,1.4,0.3,1.7C429.8,53.6,428.8,53.4,427.4,53.3z"/>
		<path d="M97.9,139.8c0.1,1.1,0.1,1.8,0.2,2.8c-0.9-0.5-1.5-0.8-2.1-1.1C95.8,140.5,96.7,140.4,97.9,139.8z"/>
		<path d="M75.7,102.7c0,1.6,0,1.6-1.5,2.3c-0.1-0.6-0.5-1.3-0.2-1.6C74.2,103.1,75,103,75.7,102.7z"/>
		<path d="M91.3,140.7c0.2-1.2,0.8-1.9,1.7-2c0.6-0.1,1.1,0.4,1.1,1.3C93.2,140.2,92.4,140.4,91.3,140.7z"/>
		<path d="M219.8,302.2c0,0.1,0.1,0.2,0,0.2c-0.8,0.9-1.8,1.5-3.2,1.7c0.2-1.2,0.7-1.7,1.7-1.7C218.8,302.4,219.3,302.3,219.8,302.2
			C219.9,302.2,219.8,302.2,219.8,302.2z"/>
		<path d="M99.6,121.4c-0.6-0.5-1.4-1-1.4-1.4c0.2-1.2,1,0,1.5-0.2c0.3-0.1,0.6-0.2,0.9-0.3C101.3,120.8,100.3,120.9,99.6,121.4z"/>
		<path d="M84,126.6c-1,0.7-1.6,1.9-3,2.5C81.9,126.3,81.9,126.3,84,126.6L84,126.6z"/>
		<path d="M419.9,106.7c0.6-0.8,0.8-2.1,2.4-2C421.9,106.2,421.4,106.7,419.9,106.7z"/>
		<path d="M291.7,160c1.7,0.9-0.1,1.8,0.1,2.7C290.8,161.4,290.8,161.4,291.7,160z"/>
		<path d="M225.1,278.1c0.8,1.5,0.6,2.6,0.2,3.7C225.1,280.7,224.5,279.6,225.1,278.1z"/>
		<path d="M224.7,247.4c-0.4,0.8-0.7,1.4-1,2.2c-0.4-0.4-0.8-0.7-1.2-1C222.9,247.6,223.6,247.1,224.7,247.4z"/>
		<path d="M455.4,92.5c0.8,1.5,0.8,1.5-1.3,3C454.5,94.5,454.9,93.6,455.4,92.5z"/>
		<path d="M454.6,92.4c-0.6-0.8-0.9-1.3-1.5-2.1c0.9-0.1,1.5-0.1,2.3-0.2C455.2,90.9,455,91.5,454.6,92.4z"/>
		<path d="M197.4,171.4c1.5-0.1,1.8,1.4,2.8,2.2c-0.8-0.3-1.6-0.5-2.5-0.8C197.6,172.3,197.6,171.8,197.4,171.4L197.4,171.4z"/>
		<path d="M455.3,102.1c-1.3-1.9-1.3-2.4,0.1-3C455.6,100.1,456.2,101,455.3,102.1z"/>
		<path d="M404.9,118.3c-0.5,0.1-1,0.1-1.4,0.1c-0.3-1-0.4-1.8,0.9-2.3C404.5,116.9,404.7,117.5,404.9,118.3z"/>
		<path d="M88.2,152.2c-1.1,0.1-1.8,0.2-2.6,0.3C86.1,150.8,86.1,150.8,88.2,152.2z"/>
		<path d="M413.3,122.9c0.8-1,1.1-1.9,2.1-1.7c0.3,0.1,0.5,0.5,1,1.1C415.2,122.5,414.5,122.7,413.3,122.9z"/>
		<path d="M444.7,81.7c1,0,1.7,0.1,2.5,0.1c-0.5,0.7-1.6,0.4-1.5,1.4c0,0-0.7,0.1-0.7,0C444.9,82.8,444.8,82.4,444.7,81.7z"/>
		<path d="M441.9,48.1c0.2-0.8,0.4-1.5,0.6-2.3c0,0,0,0,0,0c0.4,0.2,0.9,0.5,1.7,0.9C443.2,47.2,442.5,47.6,441.9,48.1L441.9,48.1z"
			/>
		<path d="M402,120.8c1-0.2,1.7-0.4,2.8-0.7C403.3,121.9,403.2,122,402,120.8z"/>
		<path d="M433.9,46.9c-0.5,0.1-0.8,0.2-1.4,0.4c-0.1-0.8-0.2-1.6-0.2-2.3c0.2,0,0.3-0.1,0.5-0.1C433.1,45.5,433.5,46.1,433.9,46.9z
			"/>
		<path d="M433.2,86.8c1.3,0.2,1.2,1.2,1.5,2.2C433.6,88.5,433,88,433.2,86.8z"/>
		<path d="M113,148.8c2.3-0.3,2.3-0.3,2.1,1.3C114.5,149.7,113.9,149.4,113,148.8z"/>
		<path d="M399.8,121.4c-0.7,0.5-0.7,0.5-2.2,0.1C398.4,121.2,398.4,121.2,399.8,121.4z"/>
		<path d="M435.5,86.2c1.7,0.4,1.7,0.4,2.1,2.1C436.5,88.1,436,87.4,435.5,86.2z"/>
		<path d="M425.9,40c0.7,0.4,1.2,0.6,1.8,0.9c-0.2,0.2-0.3,0.5-0.4,0.5c-0.6,0-1.2,0-2,0C425.4,40.9,425.6,40.5,425.9,40z"/>
		<path d="M442,75.5c0.5,0.7,1,1.3,1.6,2.1C442.6,77.5,442,76.9,442,75.5z"/>
		<path d="M100.3,132.8c-1.3,0.4-2.2,0.3-2.7-1C98.6,131.6,99.3,132.1,100.3,132.8z"/>
		<path d="M227.1,324.5c0.1-1.4,0.7-1.8,1.8-1.8C228.6,323.9,228.6,323.9,227.1,324.5z"/>
		<path d="M108.9,116.8c-0.3,0.9-0.5,1.5-0.7,2.3c-0.4-0.2-0.8-0.4-0.9-0.6C107,117.5,107.8,117.1,108.9,116.8z"/>
		<path d="M208.9,311.3c-0.9,1-1.8,0.9-2.3-0.4C207.3,311,208.1,311.2,208.9,311.3z"/>
		<path d="M94.5,127.2c-0.7,0.9-1.3,1-1.8,0.4c-0.1-0.1,0-0.5,0.1-0.5C93.3,127.1,93.9,127.2,94.5,127.2z"/>
		<path d="M408.9,119.4c0-0.1,0-0.2-0.1-0.4c0.4-0.2,0.8-0.4,1.2-0.6c0.1,0.1,0.3,0.2,0.4,0.3c-0.1,0.4-0.3,0.8-0.4,1.3
			C409.6,119.8,409.2,119.6,408.9,119.4z"/>
		<path d="M426.4,75.8c0.8-0.2,1.4-0.4,2.2-0.6C427.9,76.7,427.7,76.8,426.4,75.8z"/>
		<path d="M221.6,319.1c0,0.6,0,1,0,1.6c-0.6-0.3-1.1-0.5-1.6-0.7C220.2,318.7,220.9,319.2,221.6,319.1z"/>
		<path d="M409.7,124.6c0.7-0.6,1-0.9,1.4-1.3c0.3,0.4,0.6,0.7,1,1.3C411.3,124.6,410.7,124.6,409.7,124.6z"/>
		<path d="M78.4,104.6c-0.7-0.1-1.3-0.1-1.9-0.2c-0.2-0.7,0.5-1.2,0.5-1C77.2,104.3,78.7,103,78.4,104.6z"/>
		<path d="M224.5,331.4c-0.7-0.9-1.2-1.6-1.8-2.3c0.1-0.1,0.3-0.2,0.4-0.3C223.5,329.6,225.3,329.6,224.5,331.4z"/>
		<path d="M429.8,47.3c-0.8-0.5-1.3-0.8-1.9-1.1C429,45.3,429.3,45.4,429.8,47.3z"/>
		<path d="M91.8,148.4c-1.9-0.2-1.9-0.2-1.1-1.7C91,147.3,91.3,147.8,91.8,148.4z"/>
		<path d="M425.4,114.2c0.5-0.5,0-1.8,1.3-1.7C426.1,113,427,114.5,425.4,114.2z"/>
		<path d="M450.5,93.4c0.2-0.9-0.4-2.6,1.5-2.3C451.5,91.9,451,92.6,450.5,93.4z"/>
		<path d="M461.7,85c-0.8,0.2-1.7,0.5-2.5,0.7c-0.1-0.2-0.1-0.3-0.2-0.5c0.8-0.3,1.7-0.5,2.5-0.8C461.6,84.7,461.6,84.9,461.7,85z"
			/>
		<path d="M227.2,306.3c0.1,0.8,0.2,1.5,0.2,2.2C226.3,306.9,226.3,306.9,227.2,306.3z"/>
		<path d="M232.3,245.5c1.4-0.3,1.4-0.3,1.6,1.4C233.3,246.3,232.9,246,232.3,245.5z"/>
		<path d="M214.9,305.4c-0.2-0.3-0.4-0.6-0.6-1c0,0,0.4-0.3,0.6-0.4c0.1,0.4,0.3,0.8,0.4,1.2C215.2,305.3,215.1,305.3,214.9,305.4z"
			/>
		<path d="M223.5,338.8c0.3-0.8,0.6-1.3,0.8-1.8c0.6,0.5,1,1.1,0.4,1.7C224.5,338.9,224.1,338.8,223.5,338.8z"/>
		<path d="M86.3,136.4c0.4-0.2,0.9-0.4,1.3-0.7c0.1,0.2,0.2,0.5,0.3,0.7c-0.4,0.2-0.9,0.4-1.3,0.6C86.5,136.8,86.4,136.6,86.3,136.4
			z"/>
		<path d="M84,126.7c-0.1-0.6-0.1-1.1-0.2-1.8c0.4,0,0.7,0,1,0C85.4,125.9,84.7,126.2,84,126.7C84,126.6,84,126.7,84,126.7z"/>
		<path d="M416.5,122.7c0.2-0.6,0.4-1,0.6-1.4c0,0,0.6,0.1,0.6,0.2C418,122.2,417.6,122.5,416.5,122.7z"/>
		<path d="M433.5,43.7c-0.6,0-1.1,0-1.6,0C432.8,42.5,432.8,42.5,433.5,43.7z"/>
		<path d="M78.1,109c-0.5,0.6-1,1.6-2.1,0.3C76.9,109.1,77.5,109.1,78.1,109z"/>
		<path d="M434.9,50.4c0.5-1.2,1-1.1,1.7-0.1C435.9,50.3,435.4,50.4,434.9,50.4z"/>
		<path d="M415,109.4c-0.5,1-0.8,1.3-1.4,1c-0.1,0-0.1-0.3-0.1-0.3C413.8,109.8,414.2,109.7,415,109.4z"/>
		<path d="M441.6,49.6c-0.2,0.4-0.3,0.8-0.6,1.4c-0.3-0.4-0.5-0.6-1-1.1C440.8,49.8,441.2,49.7,441.6,49.6
			C441.6,49.6,441.6,49.6,441.6,49.6z"/>
		<path d="M410.1,111.3c0.2,0.3,0.3,0.4,0.5,0.8c-0.5,0.2-0.9,0.4-1.4,0.6c-0.1-0.1-0.2-0.2-0.2-0.3
			C409.4,112,409.7,111.7,410.1,111.3z"/>
		<path d="M91,141.3c0.3,0.6,0.5,1,0.7,1.4c-0.2,0.1-0.4,0.2-0.5,0.2C90.5,142.6,90.5,142.2,91,141.3z"/>
		<path d="M86.4,145.1c0.6-0.7,0.8-1,1.2-1.5C88,144.7,88,145.2,86.4,145.1z"/>
		<path d="M451.5,105.5c-0.6-0.4-0.6-0.9-0.2-1.3c0.1-0.1,0.5,0,0.5,0.1C452.3,104.8,452.2,105.3,451.5,105.5z"/>
		<path d="M406.1,126.7c-1.3-0.4-1.7-0.8-1.2-2C405.3,125.4,405.6,125.9,406.1,126.7z"/>
		<path d="M219.6,148.7c0.5,0.5,1,1,1.6,1.5C219.6,150.2,219.6,150.2,219.6,148.7z"/>
		<path d="M224.5,335.6c-0.8-0.2-1.4-0.4-2.3-0.7C223.3,334.6,224.2,333.9,224.5,335.6z"/>
		<path d="M453.8,77.9c-0.4-0.3-0.8-0.7-1.5-1.2c1.7-0.7,1.4,0.5,1.9,0.8C454.1,77.6,453.9,77.7,453.8,77.9z"/>
		<path d="M139.2,149.5c0,0.5,0,1.1,0,1.6c-0.1,0-0.2,0-0.3,0c0-0.5,0-1.1,0-1.6C139,149.5,139.1,149.5,139.2,149.5z"/>
		<path d="M212.7,311.2c-0.5,0-1,0-1.6,0c0-0.1,0-0.2,0-0.4c0.5,0,1.1-0.1,1.6-0.1C212.7,310.9,212.7,311.1,212.7,311.2z"/>
		<path d="M428.2,67.6c0.7,0.7,1.3,1.2,1.8,1.7c-0.1,0.1-0.2,0.2-0.3,0.3C429.1,69.2,428.1,69.2,428.2,67.6z"/>
		<path d="M451.9,107.3c0.3,0.6,0.5,1,0.6,1.4c-0.2,0.1-0.4,0.2-0.5,0.2C451.4,108.6,451.4,108.1,451.9,107.3z"/>
		<path d="M462.1,91.5c-0.7-0.5-0.5-0.9,0-1.3c0.1-0.1,0.5,0,0.5,0.1C463.1,90.9,462.6,91.2,462.1,91.5z"/>
		<path d="M407.5,116.2c-0.2,1.5-0.9,1.6-1.9,1.3C406.2,117.1,406.7,116.8,407.5,116.2z"/>
		<path d="M409.4,116.3c0.6-0.2,1.1-0.4,1.7-0.7c0,0.1,0.1,0.2,0.1,0.3c-0.5,0.3-1,0.6-1.5,0.9C409.6,116.6,409.5,116.4,409.4,116.3
			z"/>
		<path d="M442.5,81c-0.5,0-1,0-1.5,0c0-0.1,0-0.2,0-0.4c0.5,0,1,0,1.5-0.1C442.5,80.7,442.5,80.9,442.5,81z"/>
		<path d="M431.4,69.1c0.5-0.2,1-0.5,1.6-0.7C432.8,69.4,432.8,69.4,431.4,69.1z"/>
		<path d="M231.3,213.2c-0.3-0.3-0.6-0.6-0.9-0.9c0.1-0.1,0.2-0.2,0.3-0.4c0.3,0.3,0.7,0.6,1,0.8
			C231.6,212.9,231.5,213.1,231.3,213.2z"/>
		<path d="M444.8,43.9c-0.1,0.4-0.1,0.8-0.3,1.1c-0.1,0.2-0.4,0.2-0.6,0.3c-0.1-0.2-0.2-0.6-0.2-0.7
			C444.1,44.4,444.5,44.2,444.8,43.9C444.9,43.9,444.8,43.9,444.8,43.9z"/>
		<path d="M70.6,97.1c0.7-0.4,1.1-0.5,1.5-0.7C72,97.2,72,97.2,70.6,97.1z"/>
		<path d="M419.3,119.5c-0.4,0.2-0.8,0.4-1.2,0.5c0-0.2-0.2-0.5-0.1-0.5c0.4-0.2,0.7-0.3,1.1-0.5
			C419.2,119.2,419.2,119.3,419.3,119.5z"/>
		<path d="M227.5,311.9c-0.3,0-0.6,0-1,0c0.2-0.7,0.5-1.2,0.7-1.8c0.1,0,0.2,0,0.3,0C227.5,310.7,227.5,311.2,227.5,311.9z"/>
		<path d="M137,153.2c1,0,1.7,0.2,1.6,1.6C137.9,154.1,137.5,153.7,137,153.2z"/>
		<path d="M83.9,120.7c0-0.3-0.1-0.6,0.1-0.8c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.4,0.3,0.4,0.4c0,0.2-0.1,0.5-0.3,0.6
			C84.4,120.7,84.1,120.6,83.9,120.7C83.8,120.6,83.9,120.7,83.9,120.7z"/>
		<path d="M228.2,150c-0.4-0.3-0.9-0.6-1.6-1.2c1.4-0.4,1.4,0.5,1.9,0.8C228.4,149.8,228.3,149.9,228.2,150z"/>
		<path d="M422.8,73.4c0.4,0.1,0.8,0.2,1.3,0.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.4-0.2-0.8-0.3-1.2-0.5
			C422.6,73.7,422.7,73.6,422.8,73.4z"/>
		<path d="M88.8,121.2c0.2,0,0.5,0,0.6,0.1c0.3,0.2,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.8-0.6-1.2-0.9
			C88.6,121.5,88.7,121.3,88.8,121.2z"/>
		<path d="M432.4,77.9c0.2-0.7,0.3-1.1,0.5-1.5c0.5,0.2,1,0.3,1.5,0.5C433.7,77.2,433.2,77.5,432.4,77.9z"/>
		<path d="M81.5,123.8c0.4-0.3,0.7-0.5,1-0.8c0.2,0.6,0.3,1,0.5,2C82.3,124.4,82,124.2,81.5,123.8z"/>
		<path d="M201.7,301.9c-0.2,0.4-0.4,0.7-0.6,1.1c0,0-0.5-0.2-0.5-0.2c0.1-0.4,0.3-0.8,0.5-1.2C201.4,301.6,201.5,301.8,201.7,301.9
			z"/>
		<path d="M234.9,224c-0.7,0.7-1.5,0.7-2.6,0.5C233.3,223.7,234.1,223.9,234.9,224L234.9,224z"/>
		<path d="M428.8,59.3c-1.3-0.5-1.4-1-0.6-2C428.4,58,428.6,58.5,428.8,59.3z"/>
		<path d="M215,165.8c0.9-0.4,1.4-0.5,1.5,0.3c0,0.2-0.1,0.3-0.2,0.5C215.9,166.4,215.5,166.1,215,165.8z"/>
		<path d="M98.1,127.9c-0.4,0.5-0.8,1-1.2,1.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.8,1-0.9,1.2-1.6C97.8,127.7,98,127.8,98.1,127.9z"/>
		<path d="M469,80.2c0.1,0.2,0.2,0.4,0.2,0.5c-0.1,0.3-0.3,0.6-0.5,0.9c0,0.1-0.4-0.1-0.5-0.2c0.1-0.3,0.2-0.7,0.4-1
			C468.7,80.4,468.9,80.4,469,80.2C469.1,80.3,469,80.2,469,80.2z"/>
		<path d="M197.5,171.3c-0.6-0.3-1.4-0.5-1.3-1.6C197.2,170,197.7,170.4,197.5,171.3C197.4,171.4,197.5,171.3,197.5,171.3z"/>
		<path d="M438.8,50.1c-0.2,0.8-0.8,1-1.8,0.7c0.6-0.4,1-0.8,1.5-1.1C438.6,49.9,438.7,50,438.8,50.1z"/>
		<path d="M422.1,112.1c-0.1-0.9,0.4-1.3,1.4-1.8C423.3,111.3,422.7,111.7,422.1,112.1L422.1,112.1z"/>
		<path d="M426.1,101.1c0.4,1.2,0.4,1.7-0.8,1.7C425.6,102.3,425.8,101.9,426.1,101.1z"/>
		<path d="M94.9,137.1c0.2-1.1,0.4-1.5,1.1-1.3c0.1,0,0.3,0.6,0.2,0.7C96,136.7,95.6,136.8,94.9,137.1z"/>
		<path d="M80.5,106c-0.8,0.1-1.8,0.7-2.1-0.9C79.2,105.4,79.9,105.7,80.5,106z"/>
		<path d="M228.5,135.3c-0.4,0.2-0.9,0.5-1.4,0.8c0-0.2-0.1-0.6,0-0.7c0.4-0.2,0.9-0.4,1.3-0.5C228.4,135.1,228.4,135.2,228.5,135.3
			z"/>
		<path d="M222.4,228.4c0.2-0.5,0.4-1.1,0.6-1.6c0.1,0,0.2,0.1,0.4,0.1c-0.2,0.6-0.3,1.2-0.5,1.8
			C222.7,228.6,222.6,228.5,222.4,228.4z"/>
		<path d="M145.5,138.9c-0.3,0.4-0.5,0.8-0.9,1.5c-0.1-0.9-0.3-1.5,0.5-1.8C145.2,138.7,145.4,138.8,145.5,138.9z"/>
		<path d="M221.9,316.8c-0.2-0.3-0.4-0.6-0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.2,0.5,0.5,0.8,0.7
			C222.2,316.6,222.1,316.7,221.9,316.8z"/>
		<path d="M203.5,255.3c0.9-1,1.4-0.7,2.2,0.2C204.9,255.4,204.4,255.4,203.5,255.3z"/>
		<path d="M446.3,77.8c0.3-0.2,0.7-0.4,1-0.5c0,0,0.2,0.3,0.2,0.3c-0.3,0.2-0.6,0.5-0.9,0.7C446.5,78.2,446.4,78,446.3,77.8z"/>
		<path d="M432.6,51.1c0,0.6,0.3,1.3-0.2,1.7c-0.2-0.1-0.3-0.1-0.5-0.2c0.1-0.2,0.3-0.4,0.4-0.6C432.4,51.7,432.5,51.4,432.6,51.1z"
			/>
		<path d="M234.9,224.1c0.5-0.9,0.5-0.9,1.5-0.4C235.8,223.9,235.4,223.9,234.9,224.1C234.9,224,234.9,224.1,234.9,224.1z"/>
		<path d="M430.9,58.7c0.2,0.3,0.5,0.5,0.5,0.6c0,0.2-0.2,0.4-0.4,0.6c-0.2-0.2-0.5-0.4-0.7-0.6C430.5,59.2,430.7,59,430.9,58.7z"/>
		<path d="M445.4,60.8c0-0.4,0-0.8,0-1.1c0.1,0,0.2,0,0.3,0c0,0.4,0.1,0.8,0.1,1.1C445.7,60.8,445.6,60.8,445.4,60.8z"/>
		<path d="M436.6,100c-0.7,1.3-1.1,1-1.6,0.1C435.6,100.1,436,100.1,436.6,100z"/>
		<path d="M228.1,272.7c-0.1-0.4-0.1-0.8-0.2-1.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0.4,0.2,0.8,0.4,1.2
			C228.5,272.6,228.3,272.6,228.1,272.7z"/>
		<path d="M429.6,98.1c0.3-0.4,0.6-0.8,0.9-1.2c0.1,0,0.2,0.1,0.3,0.1c0,0.3,0,0.6-0.1,0.8c-0.2,0.2-0.5,0.4-0.8,0.5
			C429.8,98.2,429.7,98.2,429.6,98.1z"/>
		<path d="M456.6,96.5c0,0.4,0,0.8-0.1,1.3c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.2,0.2-0.4,0.3-0.6
			C456.4,96.4,456.5,96.4,456.6,96.5z"/>
		<path d="M144.3,152.5c0.5,0.1,1,0.3,1.6,0.4c-0.1,0.2-0.1,0.4-0.2,0.5c-0.5-0.2-1-0.3-1.6-0.5
			C144.2,152.9,144.2,152.7,144.3,152.5z"/>
		<path d="M435.5,64c0.7,0.2,1.1,0.4,1.7,0.6C436.3,65.1,435.9,65.1,435.5,64z"/>
		<path d="M213.4,304.2c0,0.4,0.1,0.9,0.1,1.3c-0.1,0-0.3,0-0.4,0.1c-0.1-0.4-0.2-0.9-0.3-1.3C213,304.2,213.2,304.2,213.4,304.2z"
			/>
		<path d="M451,80.7c1.1-0.2,1.7-0.3,1.6,0.9C452.1,81.3,451.8,81.1,451,80.7z"/>
		<path d="M422.1,112c-0.4,0.8-1,1.1-1.7,0C420.9,112.1,421.5,112.1,422.1,112C422.1,112.1,422.1,112,422.1,112z"/>
		<path d="M416.7,113.4c-0.8-0.4-1.3-0.7-1.7-0.9C415.7,112.2,416.4,112.1,416.7,113.4z"/>
		<path d="M406.2,105c-0.2,0.5-0.3,1.1-0.5,1.6c-0.1,0-0.2,0-0.4-0.1c0.1-0.6,0.2-1.1,0.3-1.7C405.8,104.9,406,105,406.2,105z"/>
		<path d="M232.2,299.5c-0.4,0.1-0.7,0.3-1.1,0.4c0,0-0.2-0.3-0.2-0.3c0.3-0.2,0.6-0.5,1-0.7C232,299.2,232.1,299.3,232.2,299.5z"/>
		<path d="M429.8,36.4c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.8-0.5,1.2-0.8
			C429.6,36.1,429.7,36.2,429.8,36.4z"/>
		<path d="M450.1,73.4c0.3,0.2,0.5,0.4,0.7,0.6c0,0.1-0.1,0.3-0.2,0.4c-0.2-0.1-0.5-0.2-0.6-0.4C449.9,74,450,73.7,450.1,73.4z"/>
		<path d="M443.8,71.1c-0.3,0-0.7,0-1,0c0-0.1,0-0.2,0-0.3c0.4-0.1,0.7-0.1,1.1-0.2C443.8,70.8,443.8,70.9,443.8,71.1z"/>
		<path d="M192.4,157.2c0,0.4-0.1,0.7-0.1,1.1c-0.1,0-0.2,0-0.4,0c0-0.4,0.1-0.7,0.1-1.1C192.1,157.2,192.3,157.2,192.4,157.2z"/>
		<path d="M399.7,100.1c0.1,0.1,0.3,0.3,0.3,0.5c0,0.3,0,0.6-0.4,1c-0.1-0.5-0.3-0.9-0.4-1.4C399.3,100.2,399.5,100.1,399.7,100.1z"
			/>
		<path d="M456.5,76.9c-0.2,0.4-0.4,0.8-0.7,1.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.4,0.3-0.9,0.4-1.3C456.1,76.7,456.3,76.8,456.5,76.9z
			"/>
		<path d="M457.3,82.1c0.2,0.3,0.4,0.6,0.6,0.9c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2-0.3-0.5-0.5-0.7-0.8
			C456.9,82.5,457.1,82.3,457.3,82.1z"/>
		<path d="M144.1,149.2c-0.3-0.2-0.6-0.4-0.9-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.3,0.2,0.6,0.4,0.8,0.6
			C144.3,149,144.2,149.1,144.1,149.2z"/>
		<path d="M138.2,141.9c-0.3,0.3-0.5,0.5-0.7,0.5c-0.2,0-0.4-0.3-0.4-0.5c0-0.2,0.3-0.5,0.4-0.5
			C137.7,141.4,137.9,141.7,138.2,141.9z"/>
		<path d="M227.6,319.9c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.2,0.3-0.4,0.5-0.5c0.1,0,0.4,0.2,0.4,0.3c0,0.2-0.1,0.4-0.2,0.6
			C228,319.7,227.8,319.8,227.6,319.9z"/>
		<path d="M438.7,98.5c0.3,0,0.7,0,1,0c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.7,0.1-1,0.2C438.8,98.8,438.8,98.6,438.7,98.5z"/>
		<path d="M415.5,87.6c0.1,0.4,0.1,0.8,0.3,1.4C414.7,88.6,415,88.1,415.5,87.6z"/>
		<path d="M90.1,106.5c-0.3,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.2,0.3-0.4,0.5-0.5C89.6,106.2,89.8,106.4,90.1,106.5z
			"/>
		<path d="M76.2,107.9c0.5-0.4,0.9-0.7,1.2-0.9c0.2,0.2,0.3,0.3,0.3,0.3C77.4,107.7,77.2,108.3,76.2,107.9z"/>
		<path d="M428.4,49.3c0.5-0.2,1-0.3,1.6-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.5,0.2-1,0.4-1.5,0.5C428.5,49.6,428.5,49.5,428.4,49.3z"/>
		<path d="M100.1,126.1c-0.3,0.4-0.7,0.8-1,1.2c-0.1-0.1-0.2-0.1-0.3-0.2c0.3-0.4,0.6-0.9,0.9-1.3
			C99.8,125.8,99.9,125.9,100.1,126.1z"/>
		<path d="M105.1,124.5c-0.4,0.3-0.5,0.5-0.7,0.6c-0.1-0.2-0.3-0.3-0.4-0.6c0-0.1,0.2-0.4,0.3-0.4
			C104.5,124.1,104.7,124.3,105.1,124.5z"/>
		<path d="M85.2,123c-0.4,0.2-0.6,0.3-0.8,0.4c0,0-0.3-0.3-0.2-0.4c0.1-0.2,0.3-0.3,0.5-0.3C84.7,122.6,84.9,122.8,85.2,123z"/>
		<path d="M88,122.7c-0.5,0-0.9,0-1.5,0C87.4,121.8,87.4,121.8,88,122.7C88,122.8,88,122.7,88,122.7z"/>
		<path d="M410.2,121.8c-0.3-0.4-0.5-0.6-0.8-1c0.5,0,0.9,0,1.3,0.1C410.5,121.1,410.4,121.3,410.2,121.8z"/>
		<path d="M229.6,308.1c0.5,0.2,0.8,0.4,1.2,0.6c-0.4,0.3-0.6,0.5-1.1,0.9C229.6,309,229.6,308.6,229.6,308.1z"/>
		<path d="M411.2,118.1c0.3-0.2,0.6-0.5,0.9-0.7c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0.3-0.5,0.6-0.8,0.9
			C411.5,118.4,411.3,118.2,411.2,118.1z"/>
		<path d="M415.9,116.1c-0.4,0.2-0.7,0.5-1.1,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.3-0.3,0.6-0.5,1-0.8
			C415.6,115.8,415.8,116,415.9,116.1z"/>
		<path d="M420.9,114.4c0.1,0.4,0.1,0.8,0.2,1.1c0,0-0.3,0.1-0.3,0.1c-0.2-0.3-0.3-0.7-0.5-1.1C420.4,114.6,420.6,114.5,420.9,114.4
			z"/>
		<path d="M103.6,113.8c-0.2,0.2-0.4,0.3-0.7,0.7c-0.3-0.4-0.5-0.7-1-1.4C102.7,113.4,103.1,113.6,103.6,113.8
			C103.6,113.8,103.6,113.8,103.6,113.8z"/>
		<path d="M225.1,286c0-0.4,0-0.7-0.1-1.1c0.1,0,0.3,0,0.4,0c0,0.4,0,0.7,0.1,1.1C225.4,286,225.3,286,225.1,286z"/>
		<path d="M226.1,290.4c-0.8-0.5-0.8-0.5,0-1.3C226.1,289.6,226.1,290,226.1,290.4z"/>
		<path d="M239.5,297.5c-0.3,0.3-0.6,0.5-1.2,1C238.4,297.4,238.9,297.4,239.5,297.5z"/>
		<path d="M206.3,336.6c0.2,0.5,0.4,0.8,0.3,1c0,0.2-0.3,0.3-0.5,0.4c-0.1-0.2-0.3-0.3-0.3-0.5C205.9,337.3,206,337.1,206.3,336.6z"
			/>
		<path d="M304.3,169.1c0.3-1,0.7-0.7,1.3,0C305,169.1,304.7,169.1,304.3,169.1z"/>
		<path d="M209.7,162.2c0.3,0.2,0.6,0.3,0.9,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.3-0.1-0.6-0.2-1-0.3C209.6,162.5,209.7,162.3,209.7,162.2
			z"/>
		<path d="M207.6,161.4c-0.5-0.1-1-0.2-1.5-0.3c0-0.2,0.1-0.3,0.1-0.5c0.5,0.1,1,0.2,1.5,0.3C207.7,161.1,207.6,161.3,207.6,161.4z"
			/>
		<path d="M114,158.4c-0.3-0.1-0.6-0.1-0.9-0.2c0,0,0-0.2,0-0.3c0.3,0.1,0.6,0.1,0.9,0.2C114,158.2,114,158.3,114,158.4z"/>
		<path d="M255.2,156.8c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.1-0.4-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.4
			C254.9,156.5,255,156.7,255.2,156.8z"/>
		<path d="M98.4,156.5c0.3,0.2,0.5,0.3,0.7,0.5c-0.1,0.1-0.2,0.4-0.3,0.4c-0.2-0.1-0.4-0.2-0.6-0.3C98.1,157,98.3,156.8,98.4,156.5z
			"/>
		<path d="M232.6,154.4c0.3,0.1,0.7,0.1,1,0.2c0,0.1,0,0.4-0.1,0.4c-0.3,0-0.6,0-1,0C232.6,154.7,232.6,154.6,232.6,154.4z"/>
		<path d="M192.5,155.3c-0.3-0.3-0.4-0.5-0.6-0.7c0.2-0.1,0.4-0.3,0.6-0.3c0.1,0,0.4,0.3,0.4,0.3C192.9,154.8,192.7,155,192.5,155.3
			z"/>
		<path d="M194.6,154.8c0.5-0.6,0.7-0.8,1.1-1.1c0.2,0.3,0.4,0.5,0.7,0.9C195.8,154.6,195.5,154.7,194.6,154.8z"/>
		<path d="M231,248.4c-0.2-0.2-0.4-0.3-0.5-0.4c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.4,0.2,0.4,0.2C231.3,247.9,231.2,248.1,231,248.4z"
			/>
		<path d="M216.3,253.6c0,0.3,0.1,0.6,0.1,0.9c-0.1,0-0.2,0-0.3,0.1c-0.1-0.3-0.1-0.6-0.2-0.9C216,253.7,216.2,253.6,216.3,253.6z"
			/>
		<path d="M173.7,257.5c0-0.3,0-0.6,0-0.9c0.1,0,0.2,0,0.3,0c0,0.3,0.1,0.6,0.1,0.9C174,257.4,173.9,257.4,173.7,257.5z"/>
		<path d="M229.4,137.5c0,0.4,0,0.8,0,1.2C228.1,138.3,229.4,137.9,229.4,137.5z"/>
		<path d="M100.4,137.3c-0.3,0.1-0.4,0.2-0.6,0.3c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.1,0.3-0.2,0.4-0.3
			C100.2,136.9,100.3,137.1,100.4,137.3z"/>
		<path d="M195.5,259.5c0.9,1,0.4,1.5,0,2C195.5,261,195.5,260.4,195.5,259.5z"/>
		<path d="M450.6,88.2c-0.1-0.3-0.3-0.6-0.4-0.9c0.1,0,0.3-0.1,0.3-0.1c0.2,0.3,0.3,0.6,0.5,0.8C450.9,88.1,450.8,88.2,450.6,88.2z"
			/>
		<path d="M103.9,133.1c-0.6-0.5-0.9-0.7-1.2-1c0.4-0.3,0.6-0.4,1-0.7C103.7,131.9,103.8,132.3,103.9,133.1z"/>
		<path d="M117,129.6c-0.3,0.2-0.5,0.4-0.7,0.4c-0.1,0-0.3-0.3-0.4-0.4c0.2-0.2,0.3-0.4,0.5-0.5C116.6,129.1,116.8,129.4,117,129.6z
			"/>
		<path d="M441.9,48c-0.1,0.5-0.2,1-0.2,1.6c0,0,0,0,0,0c-0.2-0.4-0.4-0.8-0.6-1.2C441.3,48.3,441.6,48.2,441.9,48
			C441.9,48.1,441.9,48,441.9,48z"/>
		<path d="M448.1,88.1c-0.1,0.3-0.3,0.6-0.4,0.9c-0.2-0.1-0.3-0.2-0.5-0.2c0.2-0.3,0.4-0.5,0.6-0.8C448,88,448,88.1,448.1,88.1z"/>
		<path d="M420.5,122.9c0.3,0,0.6,0,0.9,0c0,0.1,0,0.2,0,0.3c-0.3,0-0.6,0-0.9,0C420.5,123.1,420.5,123,420.5,122.9z"/>
		<path d="M412.9,123.9c-0.1-0.3-0.3-0.6-0.4-0.9c0.1,0,0.3-0.1,0.4-0.1c0.2,0.3,0.3,0.6,0.5,0.9C413.2,123.8,413,123.8,412.9,123.9
			z"/>
		<path d="M83.1,117.3c-0.4,0.1-0.8,0.1-1.5,0.2c0.3-0.5,0.5-0.8,0.8-1.2C82.7,116.7,82.9,117,83.1,117.3
			C83.1,117.3,83.1,117.3,83.1,117.3z"/>
		<path d="M425.4,44.9c0.3,0.3,0.5,0.5,0.9,1c-0.6,0.1-0.9,0.1-1.5,0.1C425.1,45.6,425.2,45.3,425.4,44.9z"/>
		<path d="M419.1,114c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.1,0.3-0.1,0.5-0.1
			C419,113.8,419,113.9,419.1,114z"/>
		<path d="M226.9,298.7c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1-0.2-0.1-0.4-0.2-0.7c0.1,0,0.3-0.1,0.3-0.1
			C226.8,298.3,226.8,298.5,226.9,298.7z"/>
		<path d="M54.2,108.4c-0.3,0-0.6,0-1,0c0.1-0.5,0.2-0.9,0.2-1.4c-0.3-0.1-0.6-0.2-0.7-0.1c-1.9,1.7-4.1,1.7-6.4,1.6
			c-1.3-0.1-2.6,0-3.9,0c0-0.2,0-0.3,0-0.5c0.5,0,1.1-0.2,1.6-0.1c1.3,0.1,2.4-0.4,3.5-0.9c0.3-0.1,0.5-0.4,0.7-0.6
			c0.3-0.4,0.5-1,0.8-1.1c2.4-1,3.2-3.5,5.1-5.4c0.2,0.4,0.6,0.8,0.5,1.1c-0.1,0.6-0.5,1.1-0.8,1.8c0.1,0.1,0.4,0.5,0.5,1
			c0.1,0.5-0.3,1.1-0.2,1.5c0.1,0.5,0.8,0.7,0.3,1.4C54.2,107.1,54.3,107.7,54.2,108.4z M50.2,106.4c1-0.8,1.8-1.4,2.8-2.2
			C51.7,104.6,50.6,104.7,50.2,106.4z"/>
		<path d="M51.4,96.9c-0.1-0.3-0.2-0.7-0.2-0.8c-1.1-0.6-2-1.1-3-1.5c-0.3-0.1-0.7-0.3-1-0.2c-1.5,0.2-2.7-0.4-3.4-1.3
			c-1.8,0-3.3,0-4.7,0c0.4-0.9,0.8-1.9,1.3-3.2c0.3,0.6,0.5,1.1,0.7,1.7c1.5-0.2,2.8,1.1,4.4,0.9c0.8,1.5,2.8,0.2,3.8,1.5
			c0.1,0.1,0.9-0.3,1.4-0.4c0.4,0.3,0.8,0.8,1.3,0.9c2,0.5,4.1,0.9,6.1,1.3c0.9,0.2,1.4,0.8,1,1.9c-0.9,0.2-1.6,0.6-2.3-0.7
			c-0.4-0.7-1.7-1.2-2.6-1.2C53.3,95.8,52.4,96.5,51.4,96.9z"/>
		<path d="M60.1,86.3c0.3,0.2,0.7,0.4,0.8,0.7c0.4,1.5,0.3,3-0.7,4.4c1.2,0.8,2.2,0.1,3.2-0.2c-0.1,1-0.2,1.9-0.3,2.8
			c-1,0.6-2,1.2-3.3,1.9c0-2.3,0-4.5,0-6.8c-0.9-0.1-1.6-0.1-2.8-0.2C58.7,88.2,60.4,88.1,60.1,86.3C60.2,86.2,60.1,86.3,60.1,86.3z
			"/>
		<path d="M23.5,80.2c-0.8,0-1.2,1.7-2.3,0.1c-0.3,0.7-0.4,1.2-0.6,1.8c-1.9-1.2-0.6-3-1-4.4c1.5,0.3,2.9,0.6,4.4,0.9
			c-0.5,0.2-0.8,0.3-1.4,0.5C22.9,79.5,23.2,79.9,23.5,80.2C23.5,80.3,23.5,80.2,23.5,80.2z"/>
		<path d="M19.2,93.6c0-1.1,0-2.2,0-3.2c-0.2,0-0.4-0.1-0.4-0.1c-0.4,0.6-0.9,1-1.4,0.3c-0.2-0.3,0.2-1.3,0.3-1.4
			c1.3-0.1,1.3-1.4,2-2.2c1,1,0.6,2.1,0.5,3.1C20,91.3,19.6,92.3,19.2,93.6z"/>
		<path d="M36,111.1c-0.7-0.6-1.4-1.1-2-1.6c0.1-0.1,0.1-0.2,0.2-0.4c1.8,0.4,3.6-0.8,5.4,0.1C38.3,109.9,37.1,110.6,36,111.1z"/>
		<path d="M55.5,84.9c-1.3,0.2-1.9,1.4-3,2.1c-0.6,0.3-1,0.9-1.4,1.5c-0.6,1-1.1,0.7-1.7,0.1c1.2-1.1,2.3-2.2,3.4-3.2
			C53.7,84.6,54.4,84.3,55.5,84.9z"/>
		<path d="M31.1,92.5c-0.7,0.4-1.4,0.8-2.5,1.4c0.5-1.7,1-3,1.4-4.4c0.6,0.6,0.9,0.9,1.4,1.5C31.4,91.2,31.2,91.9,31.1,92.5z"/>
		<path d="M52.1,110.2c0.3,0.4,0.5,0.8,1,1.7c-0.9-0.2-1.3-0.3-1.8-0.4c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0-0.2,0-0.3,0
			c-0.1-0.6-0.4-1.3-0.3-1.9C50.4,109.5,51,109.4,52.1,110.2L52.1,110.2z"/>
		<path d="M55.6,101.7c0.1-1.1,0.1-1.9,0.2-2.8c0-0.2,0.3-0.4,0.5-0.5c0.1,0,0.5,0.2,0.5,0.2c0,0.9-0.1,1.7-0.2,2.6
			C56.6,101.4,56.1,101.5,55.6,101.7z"/>
		<path d="M28.9,77.3c0.3,0.9,0.6,1.8,0.9,2.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.7-0.7-1.3-1.3-2-2C27.7,78,28.4,77.6,28.9,77.3z"/>
		<path d="M37.1,104.3c1.3,1.6,1.4,1.7,0.7,3.3c-0.1,0-0.3,0-0.4-0.1c-0.3-0.3-0.6-0.6-0.9-0.8C36.8,105.9,37,105.1,37.1,104.3z"/>
		<path d="M12.6,79.5c0.2,1.7,0.1,1.8-2.7,1.2C11,80.3,11.7,79.9,12.6,79.5z"/>
		<path d="M14.7,97.5c-1,0.8-1.6,1.4-2.3,2.1C11.5,98.1,12.1,97.5,14.7,97.5z"/>
		<path d="M46.8,101.4c1.2-0.3,1.4-1.7,2.8-1.4c-0.3,0.4-0.5,0.8-0.8,1.2c-0.3,0.4-0.5,0.7-1,1.4C47.4,102.1,47.1,101.8,46.8,101.4z
			"/>
		<path d="M29.8,106.2c0.3-0.2,0.6-0.4,0.9-0.6c0.4-0.3,0.7-0.5,1.1-0.8c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,0.2-0.4,0.4-0.8,0.7
			c0.3,0.2,0.5,0.4,0.9,0.6c-0.5,0.2-0.9,0.5-1.3,0.6C30,107.4,30.1,106.6,29.8,106.2C29.9,106.2,29.8,106.2,29.8,106.2z"/>
		<path d="M31.7,79.9c1-1.8,1.3-1.9,2.8-0.6C33.5,79.4,32.7,79.6,31.7,79.9z"/>
		<path d="M6.4,81.9C7.3,83,7.3,83,5.6,84.4C5.2,83.4,5.2,83.4,6.4,81.9z"/>
		<path d="M31,86.2c0.7,2.1,0.6,2.2-1.8,2.1C29.8,87.6,30.3,87,31,86.2z"/>
		<path d="M8.5,68.5c-0.3-1,0.3-1.7,0.8-2.6C10.2,67.4,10.1,67.5,8.5,68.5L8.5,68.5z"/>
		<path d="M36,119.5c-0.6,0.4-1.2,0.7-1.8,1.1c-0.2-0.5-0.4-0.9-0.6-1.5c0.9,0,1.6,0,2.3,0C35.9,119.3,36,119.4,36,119.5z"/>
		<path d="M31.6,99.9c-0.1-1.3,0.7-1.9,1.8-2.2C32.8,98.5,32.7,99.7,31.6,99.9C31.5,99.9,31.6,99.9,31.6,99.9z"/>
		<path d="M35.7,97.1c0.5-0.3,0.9-0.5,1.6-0.9c0,0.7,0.1,1.3,0.1,2c-0.6-0.2-1.1-0.4-1.6-0.6C35.8,97.5,35.8,97.3,35.7,97.1z"/>
		<path d="M45.7,64.1c-0.9-1.2-0.7-1.6,0.4-2.3c0.1,0.1,0.2,0.2,0.1,0.3C46.2,62.7,46,63.2,45.7,64.1z"/>
		<path d="M21.5,95.5c-0.4,0.9-0.8,1.7-1.2,2.6c0,0.1-0.2,0-0.6,0c0.5-1,0.9-2,1.3-2.9C21.2,95.3,21.3,95.4,21.5,95.5z"/>
		<path d="M32.4,81.7c-1,0.2-1.6,0.3-2.2,0.5c-0.1-0.1-0.1-0.3-0.2-0.4c0.5-0.3,0.9-0.7,1.4-0.9C31.6,80.8,31.9,81.2,32.4,81.7z"/>
		<path d="M8.5,68.4c-0.5,0.8-0.9,1.9-2.5,2.2C6.8,69.5,7.3,68.5,8.5,68.4C8.5,68.5,8.5,68.4,8.5,68.4z"/>
		<path d="M31.5,83.6c0.7,0.3,1.1,0.5,1.4,0.7c0.1,0.1-0.1,0.6-0.2,0.6C31.9,85.2,31.6,84.8,31.5,83.6z"/>
		<path d="M44.8,104.9c-0.6,0-1.2-0.1-2.1-0.2C43.9,104,43.9,104,44.8,104.9z"/>
		<path d="M45.7,80.5c-0.4,0.5-0.7,0.9-1,1.3c-0.1-0.1-0.2-0.1-0.2-0.2C44.4,80.8,44.5,80.3,45.7,80.5z"/>
		<path d="M52.2,110.2c0.5-0.4,0.6-1.5,1.7-1C53.6,110,53,110.3,52.2,110.2C52.1,110.2,52.2,110.2,52.2,110.2z"/>
		<path d="M55.3,86.8c0.4-0.2,0.9-0.4,1.3-0.6c0.1,0.1,0.1,0.3,0.2,0.4c-0.4,0.2-0.8,0.4-1.2,0.6C55.5,87.1,55.4,86.9,55.3,86.8z"/>
		<path d="M28.9,62.1c-0.1-0.3-0.2-0.6-0.2-0.8c0.2,0,0.5-0.1,0.5-0.1c0.1,0.3,0.2,0.5,0.2,0.8C29.2,62,29.1,62,28.9,62.1z"/>
		<path d="M33.2,104.9c-0.3-0.3-0.6-0.6-0.9-1c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.3,0.6,0.6,1,0.9C33.4,104.6,33.3,104.8,33.2,104.9z"/>
		<path d="M11,99c0,0.5,0.1,0.7,0,0.7c-0.3,0.3-0.5,0.5-0.8,0.7c-0.1-0.1-0.2-0.2-0.3-0.3C10.2,99.8,10.5,99.5,11,99z"/>
		<path d="M34.2,114.5c0.3-0.1,0.6-0.2,1.4-0.4c-0.5,0.6-0.7,0.8-0.9,1C34.5,114.9,34.4,114.6,34.2,114.5
			C34.2,114.4,34.2,114.5,34.2,114.5z"/>
		<path d="M34,81.5c-0.3,0.2-0.6,0.3-0.9,0.5c0-0.1-0.2-0.3-0.1-0.3c0.3-0.2,0.5-0.3,0.8-0.5C33.9,81.3,33.9,81.4,34,81.5z"/>
		<path d="M34.9,75.8c-0.2,0.4-0.4,0.8-0.6,1.3c-0.4-0.7-0.4-1.2,0.1-1.5C34.6,75.7,34.8,75.7,34.9,75.8z"/>
		<path d="M24.9,74.6c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0.1,0.3,0.2C25.1,74.2,25,74.4,24.9,74.6z"/>
		<path d="M26.3,88c-0.3,0.1-0.5,0.3-0.8,0.3c-0.1,0-0.2-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.5C25.8,87.5,26,87.7,26.3,88z"/>
		<path d="M33.6,114.4c-0.4,0.8-0.8,0.9-1.5-0.3C32.9,114.3,33.3,114.4,33.6,114.4L33.6,114.4z"/>
		<path d="M33.7,114.5c0.1-0.5,0.2-1,0.2-1.6c0.1,0,0.2,0,0.3,0c0,0.5,0,1,0,1.5c0,0,0.1-0.1,0.1-0.1
			C34,114.4,33.8,114.4,33.7,114.5C33.6,114.4,33.7,114.5,33.7,114.5z"/>
		<path d="M418,34.5c0.5,0.1,0.9,0.1,1.4,0.2c-0.1,0.2-0.1,0.6-0.3,0.6C418.4,35.7,418.4,34.8,418,34.5
			C418.1,34.5,418,34.5,418,34.5z"/>
		<path d="M419.6,28c0.2,0.5,0.4,1,0.5,1.5c0,0.1-0.4,0.3-0.8,0.5C419.4,29.1,419.5,28.5,419.6,28C419.6,27.9,419.6,28,419.6,28z"/>
		<path d="M409.1,22.8c-0.5,0-1-0.1-1.8-0.1C408.6,22,408.6,22,409.1,22.8L409.1,22.8z"/>
		<path d="M426.5,30.9c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3C426.1,30.7,426.3,30.8,426.5,30.9z
			"/>
		<path d="M553.1,263.4c-0.4,0.2-0.8,0.4-1.3,0.6c-1.8,0.7-1.8,0.7-2.2,2.6c-0.4,0-0.9,0.1-1.4,0.1c-0.1-0.5-0.2-1-0.3-1.3
			c-0.8,0-1.6-0.1-2.8-0.2c-0.3,0.1-0.9,0.5-1.6,0.8c-0.1,0-0.3-0.4-0.5-0.6c1-0.9,2.1-1.4,3.4-1.2c1.1,0.1,1.7-0.5,2-1.4
			c1.2-3.3,2-6.6,2.1-10.2c0.1-2.3,0.4-4.6,0.4-6.9c0-0.3,0.3-0.6,0.3-0.9c0.1-0.9,0.4-1.9,0.1-2.7c-0.2-0.7-0.4-1,0-1.6
			c0.3-0.4,0.2-1,0.2-1.6c0-2.4,0-4.8,0-7.3c0-0.9,0.2-1.9-0.6-2.7c-0.2-0.2-0.1-0.5-0.1-0.8c-0.1-2.2-0.1-4.5-0.2-6.7
			c0-1.9,0-3.9,0-6.2c0.6,0.3,1.2,0.4,1.2,0.6c0,1.2-0.1,2.5,0,3.7c0.1,2.7-0.2,5.5,0.7,8.2c0,0.1,0.2,0.3,0.1,0.4
			c-1.6,1.6,0.7,3,0.1,4.6c-0.1,0.2,0.2,0.6,0.4,0.7c1.3,0.3,1,1.4,0.8,2c-0.7,2-0.5,4-0.6,6.1c-0.1,1.7-0.4,3.4-0.5,5.1
			c-0.1,1.7,0,3.3,0,5c0,0.3-0.1,0.7-0.3,1c-0.4,1.1-0.5,1.1,0,2.1c-1.4,2.5-1.9,5.1-2.2,7.6C551.5,262.7,552.3,263.1,553.1,263.4
			L553.1,263.4z"/>
		<path d="M586.1,213c0.8-1.5,1.5-2.6,0.6-4.3c-0.5-1-0.4-2.4-0.7-3.6c-0.2-0.7,0.8-1.7,1.3-2.6c0.3,0.3,0.5,0.4,0.4,0.5
			c-0.7,1.4-1.1,2.8,0,4.1c0.1,2.8,1,5.5,0.4,8.4c-0.1,0.5,0.1,1.1,0.2,1.7c0.1,2.3,0.3,4.7-1.5,6.6c-0.4-0.1-0.8-0.3-1.5-0.6
			c0.2-0.3,0.4-0.5,0.6-0.7c0.9-0.8,1.5-1.5,0.9-3c-0.5-1.2-0.5-2.9,0-4.1C587.3,214.2,586.6,213.7,586.1,213z"/>
		<path d="M560.5,318.5c-1.4-0.3-2-2.3-3.7-1.5c0.2,0.8,0.3,1.6,0.5,2.3c-1.1-0.8-2.7-0.7-3.1-2.5c0.6,0.1,1.1,0.1,1.7,0.2
			c0.1-0.4,0.2-0.8,0.3-1.4c1.8-0.5,3.7-1,5.4-1.5c0.1,1,0.2,1.7,0.3,2.4C560.7,316.7,561,317.8,560.5,318.5z"/>
		<path d="M553.1,263.5c0-0.2-0.1-0.6,0-0.6c1.6-0.5,2.6-1.7,3.5-3.2c0,0.5,0.1,0.9,0.1,1.5c1.2,0.1,2.4,0.1,4.2,0.2
			c-0.7,0.6-1.1,0.9-1.5,1.1c-0.6,0.3-1.2,0.5-1.9,0.6c-1.4,0.1-2.3,0.7-2.5,2.2c0,0.1-0.4,0.1-0.6,0.2
			C554,264.9,554.4,263.5,553.1,263.5C553.1,263.4,553.1,263.5,553.1,263.5z"/>
		<path d="M463.6,267.7c0.6-0.3,1.1-0.5,1.8-0.6c1.1-0.2,2-1.2,3.1-2c0.2,0.3,0.4,0.7,0.7,1.2c1.1-0.9,2.3-1,3.7-0.4
			c-0.9,0.3-1.8,0.7-2.8,0.8c-1.1,0.1-1.7,0.5-2.1,1.5c-0.2,0.6-0.3,1.6-1.6,1.3c0.1-0.5,0.2-0.9,0.2-1.4
			C465.6,268,464.6,267.9,463.6,267.7L463.6,267.7z"/>
		<path d="M443.5,218.5c0.6-0.3,1.1-0.5,1.8-0.9c0.1,0.2,0.2,0.5,0.4,0.8c1.9-0.5-0.4-1.9,0.6-2.6c1.2,0.6,1.3,1,0.7,2.9
			c0.6,1,1.1,2,1.8,3.2c-1.1-0.3-1.8-0.4-2.4-0.6c0.3-0.8,0.3-1.4-0.8-1.4C444.5,220,443.6,219.9,443.5,218.5
			C443.6,218.4,443.5,218.5,443.5,218.5z"/>
		<path d="M471.1,329.2c0.8-0.6,1.6-1.3,2.4-1.9c0.9-0.6,2.1-1,2.7-1.9c0.7-0.9,1.5-1.3,2.6-1.8c0.1,0.7,0.2,1.2,0.2,1.7
			c-1.2,0.5-2.3,1.1-3.1,2.2c-0.1,0.1-0.2,0.3-0.3,0.3c-1.8-0.2-2.8,1.4-4.3,1.8C471.4,329.5,471.3,329.4,471.1,329.2z"/>
		<path d="M418.2,328.4c0-0.1,0-0.2,0-0.3l0,0c0.7-0.5,0.7-1.2,0.3-1.8c-0.6-0.8-0.1-1.2,0.5-1.7c1.6,1.1,0.4,3.1,1.7,4.4
			c-0.6,0.5-1,1-1.5,1.3c-0.6,0.3-0.9,0-0.9-0.6C418.2,329.3,418.2,328.9,418.2,328.4z"/>
		<path d="M587.1,195.7c-0.4,1.2,1.6,2.1,0.3,3.5c0.3,0.3,0.8,0.5,0.9,0.8c0.2,0.6-0.1,1-1.3,1.9c-0.4-0.7-0.7-1.4-1-2.1
			c-0.1-0.3-0.1-0.7,0-1c0.1-0.5,0.5-0.9,0.6-1.4c0.1-0.5,0-1.1,0-1.6C586.7,195.7,586.9,195.7,587.1,195.7z"/>
		<path d="M569,260.8c-2,0.9-3.7,1.7-5.4,2.4c-0.2,0.1-0.7,0-0.7-0.1c-0.1-0.3-0.1-0.7,0-0.9c0.3-0.3,0.7-0.6,1.1-0.8
			c1.2-0.5,2.5-0.9,3.8-1.2C568.1,260.1,568.5,260.5,569,260.8z"/>
		<path d="M465.3,333.6c-0.2-0.7-0.5-1.3-0.7-2c0.1-0.1,0.2-0.2,0.3-0.3c0.6,0.4,1.1,0.8,1.7,1.2c0.6-0.4,1.1-0.8,2-1.4
			c-0.2,0.9-0.3,1.5-0.5,2c-0.1,0.4-1.6,1.5-1.9,1.4C465.8,334.4,465.5,333.9,465.3,333.6L465.3,333.6z"/>
		<path d="M583.2,231.5c-0.2-2.3-1.5-4.5-1.1-6.9c0.1-0.6,0.1-1.1,1.1-0.9C583.2,226.2,583.2,228.8,583.2,231.5z"/>
		<path d="M447.1,269.3c0.8,1.3,0.8,1.3,2.6,0.7c0,0.2,0,0.6,0,0.6c-1.6,0.5-3,1.5-4.9,1.4C445.2,270.6,445.6,269.5,447.1,269.3z"/>
		<path d="M460.9,282c0,0.5-0.1,1,0,1.4c0.3,1.9-0.6,3.1-2.5,3.9c0.6-1.3,0.5-2.6,1.6-3.5c0.2-0.2,0-1,0-1.6
			C460.2,282.2,460.6,282.1,460.9,282C461,282.1,460.9,282,460.9,282z"/>
		<path d="M470.6,319.8c-0.2,0.5-0.3,0.9-0.5,1.1c-0.5,0.5-0.9,0.9-1.5,1.2c-0.4,0.2-1,0.3-1.4,0.4c-0.1-0.4-0.2-0.8-0.3-1.2
			c1.2,0.2,1.2-1.3,2.2-1.5C469.5,319.7,470,319.8,470.6,319.8z"/>
		<path d="M564.9,312.9c0.1,0.8,0.2,1.6,0.3,2.3c-1.1,0.7-1.8,0.3-2.2-0.4c-0.2-0.4-0.2-1.1,0-1.4
			C563.4,312.5,564.1,312.6,564.9,312.9C564.9,313,564.9,312.9,564.9,312.9z"/>
		<path d="M564.9,311.7c1.2,0.1,2.5,0.3,3.7,0.4c0.2,1.2-0.5,1.6-1.4,1.5c-0.8-0.1-1.5-0.4-2.3-0.6c0,0,0,0,0,0
			C564.9,312.5,564.9,312.1,564.9,311.7L564.9,311.7z"/>
		<path d="M565.3,306.1c-0.2,0.6-0.2,1-0.4,1.1c-0.8,0.3-1.3,0.5-1.4,1.6c0,1-1.2,0.8-2.2,0.5c0.8-1.1,1.5-2.1,2.3-3
			C563.8,305.9,564.5,306.1,565.3,306.1z"/>
		<path d="M441.7,255.2c1.2,0.1,1.9,0.9,1.9,2c0,0.8-0.5,1.1-2.2,1C441.5,257.2,441.6,256.2,441.7,255.2
			C441.7,255.2,441.7,255.2,441.7,255.2z"/>
		<path d="M430.2,306.2c-0.7-0.2-1.1-0.4-1.5-0.5c0.2-0.6,0.3-1.4,0.7-1.8c0.5-0.5,1.3-0.6,2-0.9c0.2,0.8,0.2,1.2-0.5,1.3
			C429.9,304.5,429.9,305.1,430.2,306.2z"/>
		<path d="M453.1,293.6c-0.5,1.6-0.8,2.8-1.3,4.3c-0.3-0.4-0.6-0.7-0.7-1C450.9,295.7,451.6,294.6,453.1,293.6z"/>
		<path d="M446.8,276.4c0.9,0,1.6,0,2.4,0c-0.2,1.1-1.1,2,0.3,3c-1.1-0.1-1.1-0.1-1.6-1.2C447.7,277.6,447.3,277.1,446.8,276.4z"/>
		<path d="M451.5,223.4c0-0.9,0-1.5,0-2.3c0.3-0.1,0.8-0.4,1.5-0.7c-0.1,1.1-0.2,2-0.4,3C452.3,223.4,451.8,223.4,451.5,223.4z"/>
		<path d="M429.4,281.8c0.2,0.3,0.3,0.5,0.4,0.7c0.2,0.5,1.5,0.2,1,1.1c-0.2,0.3-1,0.3-1.6,0.4c-0.1,0-0.3-0.1-0.5-0.1
			C428.5,283.2,428.2,282.5,429.4,281.8z"/>
		<path d="M495.3,203.3c-0.4,1-0.8,2-1.2,3.1c-0.4-0.5-0.9-1.1-1.5-1.8C493.8,204.6,493.7,202.5,495.3,203.3z"/>
		<path d="M443.5,252.8c0.9-0.4,1.5-0.7,2.5-1.1c-0.6,1.3-1,2.2-1.5,3.3C443,254.8,443.8,253.7,443.5,252.8z"/>
		<path d="M437,348.9c-0.3,1.1-0.5,1.9-0.9,3c-0.5-0.2-1-0.5-1.4-0.7C434.6,350.6,436,349.2,437,348.9z"/>
		<path d="M471.2,319.3c0.7-0.3,1.1-0.4,1.6-0.6c0.6,1.2-0.1,2.2-0.6,3.3C470.6,321.4,472.6,319.8,471.2,319.3z"/>
		<path d="M573.4,295.4c0.9,1.8-0.7,1.6-1.4,2.2c-0.2-0.5-0.5-0.9-0.7-1.6C572,295.8,572.7,295.6,573.4,295.4z"/>
		<path d="M440.5,300.5c0.4,0.6,0.7,1.1,1,1.5c-1.1,0.4-2,0.7-3.2,1.1C438.8,301.9,439.7,301.4,440.5,300.5z"/>
		<path d="M583.5,236.2c-0.2-1.2-0.4-2.4-0.5-3.7C584.2,232.7,584.4,233.4,583.5,236.2z"/>
		<path d="M578,258.1c-0.7,0.6-1.9,0.3-2.2,1.5c0,0.1-1,0-1.5,0C574.9,257.7,575.2,257.6,578,258.1C578.1,258.1,578,258.1,578,258.1
			z"/>
		<path d="M462.2,350.8c0.6-0.3,1.2-0.5,1.8-0.8c0.4,1.9,0.4,1.9-1.8,2.2C462.2,351.7,462.2,351.3,462.2,350.8z"/>
		<path d="M582,201.6c-0.6-0.4-1-0.7-1.5-1.1c0.7-0.9,1.3-1.8,2.8-1.3c-0.3,0.4-0.6,0.7-0.8,1.1C582.2,200.7,582.1,201.1,582,201.6z
			"/>
		<path d="M444.6,335.4c0.4-1.2,0.7-2.5,1.1-3.9c0.3,0.3,0.7,0.5,0.8,0.8C446.6,332.7,445.5,335,444.6,335.4z"/>
		<path d="M428.1,293.4c0.6-0.9,1.1-2.1,2.7-1.5C430.3,293,429.2,293.1,428.1,293.4z"/>
		<path d="M462.4,279c-0.5,1,0.4,2.7-1.5,3.1c0,0,0.1,0.1,0.1,0.1C460.6,280.6,460.9,279.5,462.4,279L462.4,279z"/>
		<path d="M542.3,342.7c-0.8-0.9-2.5-1.3-2-3.3C540.9,340.7,542.4,341.2,542.3,342.7z"/>
		<path d="M440.3,349.4c1.2,0,1.9,0,2.9,0c-0.6,0.7-1,1.1-1.6,1.8C441.2,350.7,440.9,350.2,440.3,349.4z"/>
		<path d="M551.8,270.5c-1,0.8-1.6,0-1.9-0.5c-0.1-0.2,0.3-0.8,0.6-1.6C551.1,269.3,551.5,269.9,551.8,270.5z"/>
		<path d="M560.7,318.9c0.4,0.3,0.9,0.7,1.4,1.1c-0.5,0.4-0.9,0.7-1.3,1c-1-0.5-0.5-1.2-0.6-1.8C560.4,319.1,560.5,319,560.7,318.9z
			"/>
		<path d="M436.6,272c0.1-1.6,0.2-1.6,2.2-1.5C438.1,271.2,437.8,272.3,436.6,272z"/>
		<path d="M467.6,318.1c-0.7,0.3-1.2,0.5-2,0.9c0.1-0.5,0.1-1,0.3-1.1C466.4,317.5,466.9,317,467.6,318.1z"/>
		<path d="M454.2,222.7c-0.2-0.8-0.9-1.5,0.4-2.4C454.3,221.2,455.8,221.9,454.2,222.7z"/>
		<path d="M454.5,288.1c0.8-0.6,1.2-1,1.7-1.4c0.2,0.6,0.4,1.2,0.7,2.1C456.1,288.6,455.4,288.4,454.5,288.1z"/>
		<path d="M468.3,273.4c0.7-0.1,1.4-0.3,2.2-0.4c-0.1,1-0.8,1.4-1.5,2C468.8,274.4,468.5,273.9,468.3,273.4z"/>
		<path d="M593.2,198c-1,0.7-1.5,0-1.9-0.6c-0.1-0.1,0.1-0.4,0.2-0.5C591.9,196.5,592.2,196.7,593.2,198z"/>
		<path d="M443.3,345.4c-0.5,0.5-1.1,1-1.8,1.7C441.8,345.1,441.9,345,443.3,345.4z"/>
		<path d="M563.4,266.8c1.1-0.1,1.9,1.4,2.9,0.3c-0.1,0.3-0.2,0.8-0.3,0.8C565.1,268,564,268.4,563.4,266.8z"/>
		<path d="M472,342.4c0.6-0.3,1.3-0.5,2.1-0.8C473.8,343,473,343.1,472,342.4z"/>
		<path d="M451.5,216.9c0.8,0.5,0.8,0.5,0.6,2.6c-0.5-0.1-1,0-0.8-0.9C451.5,218.2,451.5,217.6,451.5,216.9z"/>
		<path d="M486,317.3c-0.9,1.4-0.9,1.4-2.2,0.3C484.7,317.5,485.3,317.4,486,317.3z"/>
		<path d="M421.9,318.7c0,0.6-0.1,1.3-0.1,2C420.8,320,420.8,320,421.9,318.7z"/>
		<path d="M436.3,305.2c0.4-0.9,0.7-1.5,1.6-1.5C437.9,305,437.9,305,436.3,305.2z"/>
		<path d="M572.9,258.3c0,1.2,0,1.9,0,2.9c-0.4-0.3-0.8-0.5-1.2-0.7C572.1,259.9,572.4,259.3,572.9,258.3z"/>
		<path d="M443.5,258.9c0.6-0.6,0.9-1.3,1.7-0.9c0.1,0.1,0.2,0.4,0.2,0.5C445.1,259.5,444.4,259.1,443.5,258.9z"/>
		<path d="M469.7,313.3c-0.3-0.8-0.6-1.2-0.8-1.8c0.6,0,1.1,0,1.9,0C470.4,312.2,470.1,312.7,469.7,313.3z"/>
		<path d="M558.2,258.4c-1.1-0.5-1.1-0.5,0.1-2c0,0.5,0.1,0.8,0.1,1C558.3,257.7,558.2,258,558.2,258.4z"/>
		<path d="M571.1,291.1c0.4-0.4,0.8-0.8,1.3-1.3c0.3,1.1,0.2,1.8-0.9,1.7C571.4,291.4,571.3,291.3,571.1,291.1z"/>
		<path d="M437.4,260.8c0.7,0.5,1.8,0.2,2.1,1.8c-1-0.6-1.7-1-2.4-1.4C437.2,261.1,437.3,260.9,437.4,260.8z"/>
		<path d="M434.9,281.3c-0.4-0.9-0.6-1.4-0.9-1.9C435.1,279.5,435.1,279.5,434.9,281.3z"/>
		<path d="M427.7,306.5c-0.2,1-0.6,1.4-1.3,1.2c-0.1,0-0.2-0.6-0.2-0.7C426.6,306.9,427,306.7,427.7,306.5z"/>
		<path d="M557,307.8c0.4,0.1,0.8,0.3,1.3,0.5c0,0-0.1,0.4-0.2,0.5C557.4,309,557.1,308.6,557,307.8z"/>
		<path d="M558.8,313.2c0.7-0.8,1.2-1.3,2-2.1C560.8,312.8,560.2,313.2,558.8,313.2z"/>
		<path d="M449.3,264.2c0.8,0.7,1.3,1.2,2,1.9C449.6,266.2,449.3,265.5,449.3,264.2z"/>
		<path d="M562,317.2c0.4,0.2,0.8,0.4,1.3,0.6c-0.1,0.2-0.2,0.5-0.3,0.5c-0.4-0.1-0.9-0.3-1.3-0.5
			C561.8,317.6,561.9,317.4,562,317.2z"/>
		<path d="M531.1,260.2c0.6-0.2,1.1-0.4,2-0.7c-0.5,0.5-0.6,0.8-0.9,1c-0.2,0.2-0.6,0.2-0.8,0.3
			C531.2,260.6,531.1,260.4,531.1,260.2z"/>
		<path d="M452.3,254.5c-0.3,0.3-0.4,0.6-0.6,0.7c-0.3,0.1-0.6,0.1-0.9,0.1c-0.1,0-0.2-0.4-0.1-0.5c0.2-0.2,0.5-0.4,0.8-0.4
			C451.6,254.3,451.9,254.4,452.3,254.5z"/>
		<path d="M556.8,325.4c0-1,0-1.4,0-1.9c0.1,0,0.3-0.1,0.4-0.1c0.3,0.3,0.6,0.7,0.7,1.1C557.9,324.7,557.4,325,556.8,325.4z"/>
		<path d="M477,335.7c0.9,0,1.4,0,2.1,0c-0.4,0.5-0.6,0.8-0.9,1.1C477.9,336.5,477.6,336.3,477,335.7z"/>
		<path d="M443.3,336.9c0.4-0.2,0.8-0.4,1.2-0.5c0.1,0.2,0.3,0.6,0.3,0.6c-0.4,0.2-0.7,0.4-1.1,0.6
			C443.5,337.4,443.4,337.1,443.3,336.9z"/>
		<path d="M562.7,337.8c-0.5,0.4-1.1,0.7-1.8,1.2c0,0-0.3-0.3-0.6-0.5C561,337.5,561.7,337.2,562.7,337.8
			C562.7,337.9,562.7,337.8,562.7,337.8z"/>
		<path d="M465.7,299.7c0.1-0.7-0.6-1.7,1-1.9c-0.2,0.8-0.4,1.4-0.5,2C465.9,299.7,465.8,299.7,465.7,299.7z"/>
		<path d="M454.7,295.3c0.3-0.6,0.5-1,0.7-1.6c0.2,0.2,0.4,0.3,0.4,0.4c0,0.5,0,0.9,0,1.6C455.4,295.5,455.1,295.4,454.7,295.3z"/>
		<path d="M434,311.7c0-0.5-0.5-1.2,0.4-1.4c0.2,0,0.4,0.1,0.5,0.2c-0.2,0.5-0.4,0.9-0.5,1.4C434.3,311.8,434.2,311.7,434,311.7z"/>
		<path d="M470.6,261.2c-0.2,0.4-0.3,0.9-0.5,1.3c0,0-0.5-0.1-0.7-0.2c0.2-0.5,0.4-0.9,0.6-1.4C470.2,261,470.4,261.1,470.6,261.2z"
			/>
		<path d="M570.3,267.3c-0.6,0-1.2,0-1.8,0c0-0.1,0-0.3,0-0.4c0.6,0,1.2-0.1,1.8-0.1C570.3,266.9,570.3,267.1,570.3,267.3z"/>
		<path d="M573.6,266.8c0,0.6,0,1.1,0,1.6C572.9,268.4,572.9,268,573.6,266.8z"/>
		<path d="M496.1,313.4c0.1-0.4,0.3-0.9,0.4-1.3c0.3,0.1,0.5,0.3,0.8,0.4c-0.2,0.4-0.5,0.8-0.7,1.1
			C496.4,313.5,496.2,313.4,496.1,313.4z"/>
		<path d="M560.8,207.7c0.1-0.4,0.2-0.7,0.3-1c0.5,0.2,0.9,0.4,1.4,0.7c0,0.1-0.1,0.2-0.1,0.4C561.9,207.7,561.4,207.7,560.8,207.7z
			"/>
		<path d="M465.3,333.6c-0.3,0.7-0.7,1.4-1.8,0.8C464.1,333.9,464.4,333.3,465.3,333.6C465.3,333.6,465.3,333.6,465.3,333.6z"/>
		<path d="M462.4,279c0.2-0.5,0.4-1,0.8-1.9C463.3,278.8,463.3,278.8,462.4,279C462.4,279,462.4,279,462.4,279z"/>
		<path d="M559.4,212.6c0.4-0.2,0.7-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4C560,213.1,559.8,212.9,559.4,212.6z
			"/>
		<path d="M471.4,210.2c0.4-0.5,0.6-1.3,1.6-0.8C472.6,210,472.2,210.5,471.4,210.2z"/>
		<path d="M440.9,328.3c-0.5,0.1-0.8,0.2-1,0.2c-0.2,0-0.4-0.5-0.3-0.6c0.2-0.2,0.5-0.5,0.7-0.5
			C440.4,327.4,440.6,327.8,440.9,328.3z"/>
		<path d="M452.5,270c-0.9,0.3-1.3,0.5-1.8,0.7C450.8,269.7,450.8,269.7,452.5,270z"/>
		<path d="M558.6,269.2c-0.6,0.6-1,0.9-1.3,1.2C557.3,269.5,557.3,269.5,558.6,269.2z"/>
		<path d="M560.6,205.3c-0.4,0.5-0.7,1.1-1.7,0.6c0.5-0.4,0.9-0.8,1.4-1.1C560.4,205,560.5,205.2,560.6,205.3z"/>
		<path d="M493.5,345.8c-0.4,0.3-0.6,0.5-0.9,0.6c-0.2,0-0.5-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.4-0.7
			C492.7,345.2,493,345.5,493.5,345.8z"/>
		<path d="M558.4,325.6c0.3-0.6,0.5-1.1,0.7-1.5c0.2,0.1,0.3,0.2,0.3,0.2C559.6,324.9,559.8,325.7,558.4,325.6z"/>
		<path d="M446.2,329c-0.3,0.5-0.5,0.9-0.9,1.6C445,329.5,445,329.5,446.2,329z"/>
		<path d="M505.7,323.1c0.3,0.6,0.6,1,1,1.7C505.7,324.4,505.2,324.2,505.7,323.1z"/>
		<path d="M586.8,224.5c0.3,0.6,0.6,1.1,0.9,1.7C586.6,226.1,586.6,226.1,586.8,224.5z"/>
		<path d="M555.7,329.9c-0.5,0.3-0.8,0.5-1,0.5c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.1,0.4-0.4,0.5-0.4C555,329.4,555.3,329.6,555.7,329.9z
			"/>
		<path d="M563,257c-0.1-0.3-0.3-0.6-0.3-0.9c0-0.2,0.3-0.5,0.6-0.6c0.1,0,0.5,0.2,0.5,0.4c0,0.3-0.2,0.5-0.2,0.8
			C563.4,256.8,563.2,256.9,563,257z"/>
		<path d="M423.6,317.6c-0.3-0.4-0.7-0.7-0.6-0.8c0-0.2,0.4-0.4,0.6-0.6c0.1,0,0.4,0.3,0.4,0.5C424,316.9,423.8,317.1,423.6,317.6z"
			/>
		<path d="M473.3,316.7c-0.4,0.3-0.6,0.6-0.8,0.6c-0.2,0-0.6-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.5-0.7
			C472.5,316.2,472.8,316.4,473.3,316.7z"/>
		<path d="M432.8,321c0.2-0.4,0.4-0.8,0.7-1.4c0.2,0.4,0.4,0.6,0.3,0.7c-0.2,0.3-0.5,0.6-0.8,0.9C433,321.2,432.9,321.1,432.8,321z"
			/>
		<path d="M570.7,261.2c-0.1-0.5-0.3-0.9-0.5-1.5c1.3,0.2,0.9,0.9,0.9,1.4C571,261.1,570.8,261.2,570.7,261.2z"/>
		<path d="M476.9,318.5c-0.4,0.2-0.7,0.5-0.9,0.5c-0.2,0-0.5-0.3-0.5-0.4c0.1-0.3,0.3-0.6,0.5-0.6
			C476.2,317.8,476.4,318.1,476.9,318.5z"/>
		<path d="M443.3,261.2c0.3-0.7,0.5-1.2,1.4-0.9C444.5,261,444.1,261.5,443.3,261.2z"/>
		<path d="M435.7,260c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.6-0.5-0.9-0.8
			C435.4,260.3,435.6,260.2,435.7,260z"/>
		<path d="M444.5,262.8c0.5,0,0.9,0,1.4,0c0,0.1,0,0.2,0,0.4c-0.5,0-0.9,0.1-1.4,0.1C444.5,263.1,444.5,262.9,444.5,262.8z"/>
		<path d="M448.5,226.4c0.3-0.3,0.5-0.5,0.8-0.8c0.1,0.1,0.2,0.2,0.4,0.3c-0.2,0.3-0.4,0.6-0.7,0.9
			C448.8,226.7,448.7,226.6,448.5,226.4z"/>
		<path d="M556.8,193.4c0.9-0.5,1.2-0.2,0.9,0.8C557.3,193.8,557.1,193.6,556.8,193.4z"/>
		<path d="M565.9,317.3c0.7-1.2,1.1-1.1,1.5,0C566.9,317.3,566.5,317.3,565.9,317.3z"/>
		<path d="M482.5,335.6c0.3,0.4,0.6,0.6,1,1.1c-0.7,0-1.2,0-1.9,0C481.9,336.3,482.1,336.1,482.5,335.6z"/>
		<path d="M538.3,336.7c0-0.4,0-0.8,0-1.2c0.1,0,0.2,0,0.3,0c0,0.4,0,0.8,0,1.2C538.6,336.6,538.5,336.6,538.3,336.7z"/>
		<path d="M428.6,315.7c1,0.6,1.1,1,0,1.6C428.6,316.7,428.6,316.2,428.6,315.7z"/>
		<path d="M446.5,336.6c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.2,0.4-0.4,0.5-0.4c0.2,0.1,0.4,0.3,0.5,0.6C447.2,336,446.9,336.2,446.5,336.6
			z"/>
		<path d="M578.7,273.8c-0.3,0.3-0.5,0.5-0.8,0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.5-0.3
			C578.5,273.5,578.6,273.6,578.7,273.8z"/>
		<path d="M521,307.8c0.3,0.2,0.5,0.4,0.8,0.6c0,0-0.1,0.3-0.2,0.3c-0.3-0.1-0.6-0.3-0.9-0.4C520.8,308.1,520.9,307.9,521,307.8z"/>
		<path d="M551.9,212.1c0.4,0.3,0.6,0.5,0.9,0.8c-0.2,0.4-0.5,0.9-0.9,1.7C551.9,213.6,551.9,213,551.9,212.1z"/>
		<path d="M473.1,216.2c-0.4,0.5-0.7,0.8-1.1,1.2C471.7,216.4,472,216.1,473.1,216.2z"/>
		<path d="M418.2,328.4c0,0.4,0,0.9,0,1.3c-0.3,0-0.7-0.1-1-0.1c0,0,0.1,0,0,0C417.6,329.2,417.9,328.8,418.2,328.4z"/>
		<path d="M457.7,300.7c-0.8-0.6-1.2-1-1.9-1.5C457.1,298.9,457.5,299.4,457.7,300.7z"/>
		<path d="M471.3,331.7c-0.3,0.2-0.5,0.3-0.7,0.4c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.2,0.4-0.4,0.7-0.6
			C470.9,331.2,471.1,331.5,471.3,331.7z"/>
		<path d="M583.5,240.4c0-0.4,0-0.9,0-1.3c0.1,0,0.3,0,0.4,0c0,0.5,0.1,0.9,0.1,1.4C583.8,240.4,583.7,240.4,583.5,240.4z"/>
		<path d="M486.9,332.2c-0.4-0.5-0.7-0.7-0.9-1.1C486.7,331,487,331.1,486.9,332.2z"/>
		<path d="M484.1,330.3c0.4,0.4,0.7,0.8,1,1.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.8-0.6-1.1-1
			C483.7,330.8,483.8,330.6,484.1,330.3z"/>
		<path d="M584.5,243.5c0,0.7,0,1.2,0,1.8c-0.4-0.3-0.7-0.5-1.1-0.8C583.8,244.2,584.1,243.9,584.5,243.5z"/>
		<path d="M485.4,337.7c-0.4-0.6-0.5-0.9-0.7-1.2C485.6,336.3,485.9,336.5,485.4,337.7z"/>
		<path d="M564.1,316.1c0.4,0.5,0.7,0.7,0.9,1C564.1,317.2,564.1,317.2,564.1,316.1z"/>
		<path d="M478.7,316.2c0.3,0.3,0.6,0.6,0.9,0.8C478.6,317.2,478.6,317.2,478.7,316.2z"/>
		<path d="M575.5,266.4c-0.3,0.4-0.5,0.6-0.8,1C574.4,266.4,574.4,266.4,575.5,266.4z"/>
		<path d="M553.4,314c0.5-0.3,0.8-0.5,1.2-0.8C554.8,314.3,554.4,314.4,553.4,314z"/>
		<path d="M427,310.8c0.4,1.1,0,1.2-0.9,1.1C426.4,311.4,426.7,311.2,427,310.8z"/>
		<path d="M572.6,212.2c-0.6,0.5-0.9,0.7-1.2,1.1C571.1,212.3,571.3,211.9,572.6,212.2z"/>
		<path d="M481.7,344c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.3,0.2-0.5C481.3,343.8,481.5,343.9,481.7,344z
			"/>
		<path d="M437.7,345.1c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
			C437.4,345.3,437.5,345.2,437.7,345.1z"/>
		<path d="M479.2,342.2c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.3-0.2-0.4-0.3
			C478.8,342.4,479,342.3,479.2,342.2z"/>
		<path d="M531.8,345.3c0.2,0.2,0.4,0.3,0.4,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
			C531.6,345.7,531.7,345.6,531.8,345.3z"/>
		<path d="M493.3,216.1c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.3-0.2-0.4-0.3
			C492.9,216.3,493.1,216.2,493.3,216.1z"/>
		<path d="M472.5,212.8c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C472.8,212.4,472.7,212.6,472.5,212.8z"/>
		<path d="M496.2,340.4c-0.3,0.3-0.4,0.5-0.5,0.5c-0.2,0-0.4-0.2-0.6-0.3c0.1-0.2,0.2-0.4,0.3-0.4
			C495.6,340.1,495.9,340.3,496.2,340.4z"/>
		<path d="M459.3,339.9c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C459.6,339.5,459.4,339.7,459.3,339.9z"/>
		<path d="M447.4,348.4c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C447.6,348.1,447.5,348.2,447.4,348.4z"/>
		<path d="M467.6,204.3c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.3,0.2-0.5
			C467.2,204,467.4,204.1,467.6,204.3z"/>
		<path d="M573.3,199.7c0.2,0.2,0.4,0.3,0.4,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3C573,200.1,573.2,200,573.3,199.7z"
			/>
		<path d="M490.2,350c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2C490.4,349.7,490.3,349.9,490.2,350z"
			/>
		<path d="M494,349.3c0.3,0.1,0.6,0.1,0.8,0.2c-0.1,0.1-0.1,0.3-0.2,0.3c-0.3,0-0.5-0.1-0.8-0.2C493.9,349.6,494,349.5,494,349.3z"
			/>
		<path d="M447.3,338.4c-0.3,0.2-0.4,0.3-0.5,0.3c-0.2,0-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
			C446.9,338.1,447,338.3,447.3,338.4z"/>
		<path d="M584.1,195.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3
			C583.9,195.2,584,195.3,584.1,195.5z"/>
		<path d="M499.4,196c-0.1-0.3-0.2-0.5-0.2-0.6c0-0.1,0.2-0.2,0.4-0.3c0.1,0.2,0.2,0.3,0.2,0.5C499.8,195.7,499.6,195.8,499.4,196z"
			/>
		<path d="M423.9,336c-0.3-0.7-0.6-1.4,0.7-1.3C424.3,335.2,424.1,335.6,423.9,336z"/>
		<path d="M514.7,352.5c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2
			C514.9,352.2,514.8,352.3,514.7,352.5z"/>
		<path d="M473.8,353.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3
			C473.6,352.8,473.6,353,473.8,353.2z"/>
		<path d="M587.9,191.2c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C587.6,191,587.7,191.1,587.9,191.2z"/>
		<path d="M563.3,333.8c0,0.4-0.1,0.8-0.1,1.3C562.7,334.4,562.7,334.4,563.3,333.8C563.4,333.8,563.3,333.8,563.3,333.8z"/>
		<path d="M452.7,353.1c0.1,0.3,0.3,0.4,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
			C452.4,353.4,452.5,353.3,452.7,353.1z"/>
		<path d="M500.5,334c-0.6,0.3-0.8,0.4-1,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4C499.7,333.6,499.9,333.7,500.5,334z"/>
		<path d="M482.3,236.7c0.2,0.1,0.4,0.1,0.4,0.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0-0.3-0.1-0.3-0.1C482.2,237,482.3,236.9,482.3,236.7z"
			/>
		<path d="M494.4,332.9c0,0.3-0.1,0.6-0.1,0.8c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1,0.1-0.2,0.2-0.3C494.1,332.9,494.3,332.9,494.4,332.9
			z"/>
		<path d="M479.4,240.8c-0.2,0.1-0.4,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4C478.9,240.4,479,240.5,479.4,240.8z
			"/>
		<path d="M458.4,242.3c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
			C458.1,242.1,458.2,242.2,458.4,242.3z"/>
		<path d="M553,187.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3C552.8,186.9,552.9,187,553,187.3z"
			/>
		<path d="M556.2,185.8c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
			C555.8,186,555.9,185.9,556.2,185.8z"/>
		<path d="M562.1,328.1c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C562.4,327.8,562.3,327.9,562.1,328.1z"/>
		<path d="M418.2,328.1c-0.5-0.2-1.1-0.5-0.3-1.4C418,327.3,418.1,327.7,418.2,328.1C418.2,328.2,418.2,328.1,418.2,328.1z"/>
		<path d="M481.7,326.2c0.2,0.3,0.4,0.5,0.4,0.7c-0.1,0.2-0.4,0.4-0.5,0.4c-0.2,0-0.3-0.3-0.5-0.4
			C481.2,326.6,481.4,326.5,481.7,326.2z"/>
		<path d="M557.4,250.7c0.1-0.2,0.1-0.4,0.2-0.4c0.2,0,0.4,0.1,0.5,0.1c0,0.1-0.1,0.3-0.1,0.3C557.8,250.8,557.6,250.7,557.4,250.7z
			"/>
		<path d="M451.8,253c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4C451.5,253.2,451.6,253.1,451.8,253z
			"/>
		<path d="M458,258.4c-0.1-0.3-0.3-0.5-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.2,0.3,0.3,0.3,0.5C458.3,258,458.2,258.2,458,258.4z"/>
		<path d="M568.2,319.1c-0.4-0.3-0.9-0.5,0-1.4C568.2,318.4,568.2,318.8,568.2,319.1C568.2,319.1,568.2,319.1,568.2,319.1z"/>
		<path d="M497.9,318.2c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C497.6,317.8,497.7,318,497.9,318.2z"/>
		<path d="M460.5,262.7c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4C460.2,263.1,460.4,263,460.5,262.7
			z"/>
		<path d="M456.1,265.7c-0.2,0.1-0.4,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C455.7,265.2,455.8,265.4,456.1,265.7z"/>
		<path d="M477.8,265.8c-0.3,0.2-0.4,0.3-0.5,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
			C477.3,265.5,477.5,265.7,477.8,265.8z"/>
		<path d="M485.2,314.4c-0.2-0.2-0.3-0.3-0.4-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0.2,0.3,0.2
			C485.5,314.1,485.4,314.2,485.2,314.4z"/>
		<path d="M466.2,312.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C465.8,312,465.9,312.2,466.2,312.4z"/>
		<path d="M564.9,311.7c-0.3-0.2-0.5-0.4-0.8-0.6C564.9,310.8,564.9,310.8,564.9,311.7C564.9,311.7,564.9,311.7,564.9,311.7z"/>
		<path d="M460.9,294c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5C460.6,293.8,460.7,293.9,460.9,294z
			"/>
		<path d="M425.3,305.7c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3
			C424.9,305.4,425.1,305.5,425.3,305.7z"/>
		<path d="M556.6,290.7c0.4,0.2,0.8,0.4,1.2,0.6c0,0.1-0.1,0.2-0.1,0.4c-0.4-0.2-0.9-0.3-1.3-0.5C556.4,291,556.5,290.8,556.6,290.7
			z"/>
		<path d="M539.5,292.1c0-0.3,0.1-0.6,0.1-0.8c0.1,0.2,0.3,0.3,0.4,0.5c0,0.1-0.1,0.2-0.2,0.3C539.7,292.1,539.6,292.1,539.5,292.1z
			"/>
		<path d="M460.9,292.4c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.2-0.4c0-0.1,0.2-0.3,0.3-0.4
			C460.7,292,460.8,292.2,460.9,292.4z"/>
		<path d="M474.8,295.7c0.2,0.2,0.3,0.3,0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3-0.1-0.3-0.2
			C474.6,296.1,474.7,295.9,474.8,295.7z"/>
		<path d="M563.1,292.2c-0.3,0-0.5,0-0.8,0c0,0,0-0.3,0-0.3c0.3-0.1,0.6-0.1,0.8-0.1C563.1,291.9,563.1,292,563.1,292.2z"/>
		<path d="M563.2,294.7c0.3,0.2,0.5,0.4,0.6,0.5c-0.1,0.2-0.3,0.5-0.4,0.5c-0.2,0-0.4-0.2-0.6-0.3C562.8,295.2,563,295,563.2,294.7z
			"/>
		<path d="M341,247.2c-0.8-0.5-1.5-0.1-2.3,0.3c-6.1,3.6-12.1,7.1-18.2,10.7c-1,0.6-1.9,1.2-2.9,1.6c-0.5,0.2-1.2,0.3-1.7,0.1
			c-0.7-0.3-0.7-1.1-0.6-1.8c0.6-2.3,1.1-4.7,1.7-7c1.6-6.6,3.3-13.2,4.9-19.9c0-0.1,0.1-0.3,0.1-0.4c0.5-2,0.5-2,2.5-2
			c0.8,0,1.5,0,2.3,0c5,0,9.9,0,14.9,0c0.5,0,1,0,1.4,0c1-0.1,1.4-0.9,1.1-2c-0.2-0.8-0.5-1.6-0.7-2.4c-0.3-1.2-0.2-2.2,0.9-3.2
			c0.4,2.4,0.8,4.7,1.2,7.3c0.3,0.1,1,0.4,1.5,0.3c1.5-0.5,3,0.2,4.5,0.1c1.6-0.1,3.2,0,4.8,0c1.6,0,3.2,0,4.8,0c1.6,0,3.2,0,4.8,0
			c0.4,0,0.8,0,1.2,0.1c0.8,0.2,1.1,0.9,0.5,1.5c-0.4,0.4-0.9,0.6-1.4,0.9c-4.8,2.8-9.6,5.6-14.4,8.5c-1,0.6-1.7,1.4-2.7,2.2
			c-1-0.5-3.1,0.6-4,2.4C343.3,244.7,341.7,245.3,341,247.2L341,247.2z M319,258c1-0.5,1.5-0.7,2-1c6.5-3.7,13.1-7.4,19.5-11.2
			c3.6-2.1,7.1-4.3,10.7-6.5c2.5-1.5,5-2.9,7.5-4.4c0.5-0.3,0.9-0.7,1.3-1c-0.1-0.1-0.1-0.3-0.2-0.4c-0.7,0-1.5,0-2.2,0
			c-5.7,0-11.4,0-17.2,0c-4.5,0-9,0-13.5,0c-1.8,0-2.1,0.3-2.6,2c-0.1,0.3-0.1,0.5-0.2,0.8c-1.6,6.5-3.2,13-4.8,19.5
			C319.3,256.3,319.2,256.9,319,258z"/>
		<path d="M350,289.7c-0.3-1,0.3-1.2,1-1.4c1.7-0.4,1.7-0.4,1.3-2.1c-0.1-0.2,0-0.7,0-0.8c0.9-0.3,0.5-0.9,0.5-1.5
			c0-0.1,0.4-0.3,0.7-0.5c0.3,0.9,0.5,1.5,0,2.3c-0.2,0.3-0.4,0.7-0.4,1.1c-0.3,2.6-0.4,2.9-2.1,5.6c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.7,3-1.4,6-2.1,9c-0.6,2.3-1.2,4.7-1.8,7c-0.5,1.7-0.8,1.8-2.6,1.8c-3-0.1-6.1,0-9.1,0.1c-1,0-2-0.1-3-0.1
			c-8.9,0-17.8,0-26.7,0c-0.5,0-1,0-1.4,0c-1.3-0.1-1.6-0.5-1.3-1.7c0.4-1.8,1-3.6,1.4-5.4c0.2-0.9,0.8-1.8,0-2.9
			c-0.3-0.5,0.4-1.7,0.7-2.6c0.4-1.3,1-2.5,1.4-3.9c0.3-1,0.4-2,1.7-2.3c0.2-0.1,0.3-0.6,0.6-0.8c0.2-0.1,0.5-0.1,0.7-0.1
			c0,0.3,0,0.6,0,1c7.8,0,15.7,0,23.6,0c0.3-1.8-1.9-1.4-2.3-2.6c1.9-1.3,2.5,1.1,4,1.1c-0.1,0.6-0.3,1-0.4,1.7
			c2.3-0.1,4.4-0.3,6.6-0.3c1.8,0,3.6,0.2,5.5,0.2c1.3,0,1.4,0,2.5-0.9c0.7,0.2,1.4,0.3,2.1,0.5c0.3-0.7,0.5-1.1,0.7-1.8
			C350.9,290,350.4,289.9,350,289.7L350,289.7z M345.6,310.1c1.2-4.3,2.4-8.5,3.3-13.2c-1.1,0-1.9,0-2.8,0c-5.4,0-10.8,0-16.2,0
			c-6.2,0-12.4,0-18.6,0c-2.1,0-2.5,0.3-2.9,1.9c-0.8,3.3-1.6,6.6-2.4,9.9c-0.1,0.4,0,0.8,0,1.3
			C319.3,310.1,332.4,310.1,345.6,310.1z"/>
		<path d="M309.5,281.5c-1,1.4-1.6,2.9-1.1,4.7c2.1,1.1,2.2,1.7,0.4,3.2c-0.8-0.6-1.7-1.2-2.7-1.9c-0.2,0.7-0.4,1.1-0.5,1.6
			c-0.2,0-0.3-0.1-0.5-0.1c-0.7-0.1-1.5-0.2-2.2-0.3c-1.2-0.1-1.4-0.4-1.4-2c0.5-0.2,1.1-0.5,1.7-0.8c0.4,0.6,0.7,1.1,1.1,1.8
			c0.6-0.3,1.3-0.6,2.2-1.1c-0.2-1-0.4-2-0.6-2.9c-2.6,0-4.9,0-7.3,0c0-0.1-0.1-0.3-0.1-0.4c1-0.6,2-1.2,3-1.7c0.3-0.1,0.8,0,1,0.1
			c1.2,1.3,2.5,0.2,3-0.4c0.7-0.9,1.7-2,1.6-3.4c0-0.4-0.1-1,0.1-1.2c1.3-1.7,0.9-3.8,1.6-5.6c0.2-0.5,0.1-1.1,0.1-1.6
			c0.1-0.9,0.5-1.8,0.4-2.7c-0.1-0.8,0.3-1.7,0.2-2.3c-0.3-2,1.3-4,0.2-6c-0.1-0.2,0.1-0.5,0.2-0.8c0.1-0.5,0.4-1.2,0.2-1.6
			c-0.8-1.7-0.4-3.5-0.5-5.3c-0.1-2.3-0.3-4.6-0.6-6.9c-0.3-2.8-0.8-5.7,0.4-8.5c0.1-0.2,0.1-0.4,0-0.6c-1.3-1.7,0.5-3.1,0.6-4.6
			c0-0.1,0.2-0.1,0.5-0.2c0.1,0.4,0.3,0.9,0.2,1.1c-0.8,0.9-0.6,1.9-0.6,2.9c0,2.4,0,4.8,0,7.3c0,1.1,0,2.1,0.5,3.2
			c0.5,1,0.2,2.3,0.2,3.5c0,2,0,2,1.4,3.5c-0.5,1.2-0.9,2.4,0.1,3.7c-0.9,0.9-1.1,1.9-0.9,3.4c0.3,2.2-0.1,4.5-0.2,6.7
			c-0.1,1.8-0.3,3.6-0.4,5.4c-0.1,0.8-0.1,1.5-0.5,2.3c-0.5,1-0.3,2.4-0.8,3.3c-0.9,1.5-0.4,2.9-0.5,4.4
			C308.8,281.1,309.3,281.3,309.5,281.5L309.5,281.5z"/>
		<path d="M288.8,226.4c-0.2,0.7-0.2,1.7-1.4,0.9c-0.6-0.5-1.3,0.9-1.9,0c-0.4-0.6-0.7-1.1-1.2-1.6c-0.7-0.7-1.2-0.6-1.7,0.4
			c-1.9-0.5-3.4-2-2.7-3.4c0.6-1.1,0.1-1.8,0.1-2.7c0-1.9-1-2.9-2.8-3.3c0.3-1,0.3-1,2.1-0.3c0.1-0.2,0.2-0.4,0.3-0.7
			c0.1-0.2,0.2-0.3,0.4-0.7c1.1,1,2.3,1.7,3,2.8c1,1.5,2.7-0.1,3.8,1.5c0.7,1.4-1.9,1.5-1.8,3.1c0.2,0.3,0.6,0.8,0.9,1.3
			c-0.1,0.3-0.3,0.7-0.5,1.1C286.6,225.5,287.3,226.8,288.8,226.4z M283.7,224.6c0-1.8-0.3-2.1-2.5-2.2c0.3,0.8,0.6,1.5,0.9,2.2
			C282.6,224.6,283.1,224.6,283.7,224.6z M283,221.3c0.5-0.5,1-1,1.5-1.5c-0.7-0.5-1.2-0.8-1.7-1.1c-0.2-0.1-0.6,0-0.7,0.1
			c-0.1,0.1-0.2,0.5-0.1,0.7C282.2,220.1,282.5,220.6,283,221.3z"/>
		<path d="M260.6,320.2c0.8-0.9,1.8-0.3,2.7-0.4c0.9-0.2,1.8-0.2,1.2-1.4c1.8-0.5,3.6-1,5.4-1.5c-1.2,1.4-2.4,2.7-3.7,3.9
			c-0.7,0.7-1.7,0.1-2.2,1.2c-0.3,0.7-0.8,1.7-1.8,2.2c-0.8,0.4-1.2,1.4-1.8,2.1c-0.5-0.5-0.9-0.8-1.4-1.2c0.5-0.5,1-1.1,1.6-1.5
			c0.5-0.3,1.1-0.4,1.7-0.7c-0.5-0.5-0.9-0.8-1.2-1.2C260.8,321.1,260.7,320.6,260.6,320.2L260.6,320.2z M262.9,320.5
			c-0.3-0.1-0.5-0.2-0.6-0.2c-0.1,0-0.2,0.2-0.3,0.4c0.2,0.1,0.3,0.2,0.5,0.2C262.6,320.9,262.7,320.7,262.9,320.5z"/>
		<path d="M266.9,349.4c0.6,0.3,1.4-0.2,1.7,0.9c0.1,0.4,0.9,0.7,1.4,1.1c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.3-0.5,0.4-0.8,0.7
			c0-0.4-0.1-0.7-0.1-1.1c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-1.7,0.4-2.7-0.8-3.9-1.5c-0.8-0.5-1.6-1-2.4-1.6
			c-0.5,0.4-0.9,0.8-1.4,1.1c-0.2,0.1-0.6,0.1-0.9,0.1c0-0.3-0.2-0.7-0.1-0.9c0.8-1,0.6-1.9-0.2-3.2c0.8,0.2,1.5,0.1,1.8,0.5
			c0.9,0.9,1.9,1.3,3.2,1.3C265.7,347.1,266.9,348.4,266.9,349.4z"/>
		<path d="M276.2,287.5c0.6-0.8,0.9-1.3,1.4-1.9c-0.3-0.4-0.7-0.9-1.1-1.4c0.1-0.3,0.3-0.7,0.5-1c-0.3-0.1-0.5-0.2-0.9-0.4
			c0.9-1.8-0.1-3.3-0.6-4.9c1.6-0.7-0.5-2.1,0.6-3.1c0.4,0,0.9-0.1,1.5-0.1c0.1,1,0.4,2,0.1,2.7c-0.7,1.5,0.3,2.9,0.1,4.3
			c-0.1,0.9,0,1.6,0.8,2.4c0.6,0.6,1.2,1.7,0.5,2.9c-0.2,0.3-0.1,0.8-0.2,1.2C278,287.9,277.3,287.7,276.2,287.5z"/>
		<path d="M287.7,268.7c-0.4,0-0.8,0-1.4,0c0.1-0.9,0.2-1.6,0.3-2.4c-1.8-0.3-3-1.4-3.4-3.3c1.9-1.2,2.3,1.2,3.8,1.1
			c-0.2,0.6-0.3,1.1-0.5,1.6c0.4,0.1,0.8,0.2,1.2,0.3C287.6,266.9,287.6,267.8,287.7,268.7L287.7,268.7z"/>
		<path d="M284.4,269c-1,1.1,1,3.1-1.5,3.8c0.3-2.2-2.2-2.3-2.6-4c1.1-1.3,1.6-1.3,2.5,0.1c0.5-0.1,0.9-0.2,1.3-0.3
			C284.2,268.8,284.3,268.9,284.4,269z"/>
		<path d="M309.5,281.6c0.2-0.3,0.5-0.9,0.7-0.9c1.6,0.3,2.5-1,3.7-1.7c1.1,1.9,2.8-0.3,4.1,0.8c-1,0.2-1.9,0.4-2.8,0.7
			c-0.5,0.2-1.1,0.5-1.4,1c-0.5,0.6-0.7,1.4-1.1,2.1c-1.7-0.2-0.4-1.2-0.6-1.8C311.2,281.7,310.3,281.6,309.5,281.6
			C309.5,281.5,309.5,281.6,309.5,281.6z"/>
		<path d="M316.6,343.4c0.2-0.5,0.2-0.9,0.4-1c0.6-0.4,1.3-0.8,2-1c1.2-0.3,2.1-1.1,2.7-2.1c0.6-1,1.4-1.4,2.4-1.8
			c0.5,0.8,0.9,1.3-0.2,1.9c-0.9,0.4-1.8,0.8-2.2,1.9c0,0.2-0.3,0.4-0.4,0.4C319.6,341.5,318.4,342.9,316.6,343.4z"/>
		<path d="M263.5,342.2c1.3-0.9,0.7-1.9-0.1-3.1c0.4-0.4,0.7-0.7,1.4-1.2c0.5,1.9,0.8,3.4,1.2,4.8c-1.6,1.6-1.6,1.6-2.4,0.7
			c0.2-0.2,0.5-0.3,0.7-0.5C264,342.7,263.7,342.5,263.5,342.2z"/>
		<path d="M285.8,255.3c-1.1-1.1-1.1-1.3,0.7-2.5c-0.4-0.5-0.7-1-1.1-1.7c1.8-0.2,2.5,0.5,2.6,2.1
			C287.1,254,286.5,254.6,285.8,255.3z"/>
		<path d="M281.8,264.1c-0.1,0.5-0.2,1-0.3,1.5c0.5,0.1,1,0.3,1.9,0.5c-0.9,0.6-1.5,1-2.1,1.4c-1.6-1.3-1.9-1.9-1.5-2.9
			C280.2,263.8,280.9,263.7,281.8,264.1z"/>
		<path d="M282.8,259.1c-1.8-0.7-2.9-1.9-2.7-4.2c1,0.8,2,1.6,2.9,2.6C283.1,257.8,282.8,258.6,282.8,259.1L282.8,259.1z"/>
		<path d="M292.6,283.1c0.5,1.7,1.8,0.6,2.7,1.2c-1.5,0.5-3.1,1.1-4.7,1.6C290.2,284.2,291.5,283.8,292.6,283.1z"/>
		<path d="M310.9,347.2c-0.2-0.5-0.4-1.1-0.7-1.9c0.7,0.4,1.3,0.7,1.9,1c0.6-0.3,1.1-0.6,1.8-1c0.1,1.9-0.9,2.5-2.3,3
			C311.3,347.9,311,347.6,310.9,347.2L310.9,347.2z"/>
		<path d="M362.9,290.2c-2.6,1.2-2.6,1.2-4.9,0c-0.4-0.2-1,0-1.7,0.1c0.3-0.3,0.4-0.7,0.7-0.8c0.4-0.1,0.8-0.1,1.2,0
			C359.7,289.7,361.3,290,362.9,290.2z"/>
		<path d="M259.1,343.2c0.1-0.4,0-0.9,0.2-1.1c0.6-0.6,0.6-1.2,0.4-1.9c-0.3-1.1,0.2-1.9,1.2-2.4c-0.6,1.8,0.1,3.9-1.1,5.6
			C259.6,343.3,259.3,343.3,259.1,343.2z"/>
		<path d="M257.9,305.9c1.1-0.4,1.7-0.2,2.2,1.1c0.4,1,0.4,1.8,0.3,2.7c0,0.2-0.5,0.4-1,0.7c-0.2-1.3-0.4-2.4-0.6-3.4
			C258.7,306.6,258.2,306.2,257.9,305.9L257.9,305.9z"/>
		<path d="M287.6,268.7c0.6,0.8,1.2,1.5,1.7,2.2c-0.5,1.3-1.3,1.4-2.2,1.2C286.1,270.7,287.5,269.8,287.6,268.7
			C287.7,268.7,287.6,268.7,287.6,268.7z"/>
		<path d="M260.8,312.9c0.2,0.4,0.5,0.8,0.7,1.2c-0.3,0.2-0.9,0.5-0.9,0.8c0,1.2-0.9,1.1-2,1.5c0.7-0.8-0.1-1.7,0.9-2.1
			c0.1,0,0.1-0.3,0.1-0.3c-0.4-0.3-1-0.5-0.4-1c0.2-0.2,0.8,0.1,1.2,0.1C260.6,313.2,260.7,313,260.8,312.9L260.8,312.9z"/>
		<path d="M312.4,336.2c0-0.5,0-0.8,0-1.1c0.2-0.6,1.1,0.4,1.3-0.7c0.2-1.1,1.3-1.2,2.4-0.7c-0.1,0.3-0.2,0.7-0.2,0.7
			C314.3,334.3,314.5,337,312.4,336.2z"/>
		<path d="M282,251.9c-0.5-0.5-1.1-1-0.4-1.7c0.4-0.5,0.8-1,1.3-1.5C284.3,249.9,284.2,250.4,282,251.9z"/>
		<path d="M345.4,215.7c-0.8,1.7,0.7,3.5-1.1,4.8c-0.5-0.8-0.4-1.5,0.2-2C343.5,216.9,343.6,216.3,345.4,215.7z"/>
		<path d="M267.2,327.9c0.1,1.4-2.4,1.2-1.7,3.1c-1-0.3-1.4-0.7-1.1-1.5C264.8,328.3,265.7,327.8,267.2,327.9z"/>
		<path d="M275.4,319.8c-0.5-0.1-1-0.2-1.4-0.3c0.3-1.4,1.1-2.2,2.3-2.6c0.2-0.1,0.6,0.2,0.8,0.4c0.1,0.1-0.1,0.5-0.3,0.7
			C275.5,318.9,275.5,318.9,275.4,319.8z"/>
		<path d="M285.9,259.1c-1-1.1-2.3-1.8-2-3.4c1.1-0.1,1.4,0.5,1.6,1.2c0.2,0.5,0.6,0.9,1,1.5C286.5,258.5,286.3,258.7,285.9,259.1z"
			/>
		<path d="M253.5,345.8c-0.6-0.8-1.1-1.6-1.7-2.5c1.8-0.4,2,1.4,3.3,1.8C254.4,345.4,253.9,345.6,253.5,345.8z"/>
		<path d="M258.1,345.9c-0.9,0-1.3,0.3-1.3,1.2c-0.2,0-0.3,0-0.5,0c-0.9-1.3-0.6-2.6,0.1-3.8l-0.1,0.1c0.2,0.2,0.4,0.5,0.6,0.5
			C258.3,344.2,258.2,345,258.1,345.9z"/>
		<path d="M292.4,290.1c0.8,0,1.5,0,2.1,0c-0.1,1.1-0.1,2.1-0.2,3.3c-0.3-0.5-0.6-1-0.9-1.4C293.1,291.4,292.8,290.8,292.4,290.1z"
			/>
		<path d="M297.5,311.7c-1.3-1.5-1-2.7,1-4.6C298.1,308.8,297.8,310.1,297.5,311.7z"/>
		<path d="M263.3,337c-0.8-1.3,0.6-2.2,0.3-3.9C265.1,336.4,265.1,336.4,263.3,337z"/>
		<path d="M269,354.8c-1.9,0.1-2.9-0.5-3.6-2.2C266.8,353.3,268.1,353.4,269,354.8z"/>
		<path d="M282.6,255.2c-0.4-1-0.7-1.6-1-2.4c1,0,1.7,0,2.5,0C284.5,254,283.3,254.2,282.6,255.2z"/>
		<path d="M259.4,301.9c1.5,0.2,1.2,1.4,1.8,2.2c-0.8,0.2-1.5,0.3-2.4,0.5C259,303.6,259.2,302.7,259.4,301.9z"/>
		<path d="M259.2,319.9c-0.8,0.7-1.5,1.4-2.3,2c-0.2,0.2-0.7,0.1-1,0.1c0.1-0.3,0.1-0.8,0.3-1c0.8-0.6,1.7-1.1,2.6-1.7
			C258.9,319.5,259.1,319.7,259.2,319.9z"/>
		<path d="M282.7,259.1c1,0.6,1.1,1.7,1.8,2.7c-1.9,0.3-1.6-1.5-2.7-1.7C282.2,259.7,282.5,259.4,282.7,259.1
			C282.8,259.1,282.7,259.1,282.7,259.1z"/>
		<path d="M340.7,217.1c-0.4,1.1-0.7,2.1-1.1,3.1c-0.4-0.5-0.8-1.1-1.5-1.9C339.3,218.3,339.3,216.5,340.7,217.1z"/>
		<path d="M276.3,297.6c-0.7,0-1.4,0-2.1,0c-0.6-1,0-1.5,0.6-1.9c0.6,0.5,1.1,1,1.6,1.5C276.4,297.3,276.3,297.4,276.3,297.6z"/>
		<path d="M317.2,333.1c0.4-0.2,0.7-0.4,1.1-0.7c0.8,1.2-0.3,2.1-0.6,3.3c-0.4-0.4-1.3-0.5-0.5-1.4
			C317.4,334.1,317.2,333.5,317.2,333.1z"/>
		<path d="M291.2,345.7c1,1.5-0.1,2.6-0.8,3.8C290,348.1,291,347,291.2,345.7z"/>
		<path d="M349,192.2c1.4-0.3,2.4-0.6,3.7-0.9c-0.6,0.9-1.1,1.6-1.6,2.3C350.5,193.2,349.8,192.8,349,192.2z"/>
		<path d="M291.2,265.7c-0.5,1.2-0.9,2.1-1.3,3c-1.1-0.6-0.5-1.5-0.7-2.3C289.9,266.2,290.4,266,291.2,265.7z"/>
		<path d="M318.8,326.7c0.8-0.9,1.3-1.5,1.8-2.2c0.1,0,0.3,0,0.4,0.1c0,0.7,0.1,1.3,0.1,2.1C320.4,326.7,319.8,326.7,318.8,326.7z"
			/>
		<path d="M297.1,234.9c0.3-0.1,0.7-0.3,1.3-0.5c-0.1,1-0.2,1.8-0.3,2.7c-0.4,0-0.8,0-1.2,0C297,236.3,297,235.7,297.1,234.9z"/>
		<path d="M290.6,202.2c1-0.3,2-0.6,3.3-0.9C293,203,292.9,203,290.6,202.2z"/>
		<path d="M283.8,316.9c0.2-0.5,0.3-0.7,0.4-0.8c0.5-0.5,1.1-0.9,1.8-1.5c0.3,0.4,0.6,0.8,0.9,1.2
			C285.8,316.1,284.9,316.4,283.8,316.9z"/>
		<path d="M349,215.4c1-1.2,1.2-1.2,2.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5C350.1,215.9,349.5,215.7,349,215.4z
			"/>
		<path d="M284.7,193.1c-0.8,0-1.7,0-2.6,0C282.9,191.8,282.9,191.8,284.7,193.1z"/>
		<path d="M313.7,287.3c0.8-0.2,1.5-0.4,2.3-0.5c0,1.2-0.8,1.5-1.6,2C314.2,288.2,314,287.7,313.7,287.3z"/>
		<path d="M338.3,281.8c1,0,2.1,0,3.1,0C340.4,282.5,339.4,282.4,338.3,281.8z"/>
		<path d="M279.7,206.1c0.5-1.8,0.5-1.8,3.2-1.3C281.9,205.2,280.9,205.6,279.7,206.1z"/>
		<path d="M256.5,343.3c-0.9,0.1-1-0.3-0.6-0.9c0.3-0.4,0.7-0.7,1.1-1.1c0,0,0.5,0.3,0.5,0.4c0.2,0.8-0.3,1.1-0.9,1.3
			C256.5,343.1,256.5,343.3,256.5,343.3C256.4,343.4,256.5,343.3,256.5,343.3z"/>
		<path d="M284.2,284.4c-0.4,0.4-0.8,0.9-1.3,1.3c-0.2,0.1-0.6,0-0.9,0c0.1-0.3,0-0.9,0.2-1c0.5-0.3,1.1-0.5,1.7-0.7
			C284,284.1,284.1,284.2,284.2,284.4z"/>
		<path d="M329.4,332.1c0.5-1.5,1.4-1,2.3-0.9C331.4,332.6,330.5,332.3,329.4,332.1z"/>
		<path d="M327.9,323c0.9,0.3,1.8-1.4,2.7,0.1C329.7,323.9,328.9,324,327.9,323z"/>
		<path d="M284.1,317.9c-0.7,0.6-1.2,1-1.7,1.3c-0.1,0-0.4-0.3-0.6-0.5c0.4-0.5,0.8-0.9,1.3-1.4
			C283.2,317.4,283.5,317.6,284.1,317.9z"/>
		<path d="M283,197.6c-0.5,0.7-0.9,1.3-1.3,1.9c-0.1,0-0.2-0.1-0.4-0.1c0-0.8,0-1.5,0-2.4C281.9,197.1,282.4,197.3,283,197.6z"/>
		<path d="M282.8,274.6c0.9,0.3,1.8,0.2,2.1,1.3C284.1,275.8,283.1,275.9,282.8,274.6z"/>
		<path d="M273.6,307.1c0.6-1,1.3-1.7,2.7-1.3C275.5,306.7,274.6,306.9,273.6,307.1z"/>
		<path d="M298.6,255.4c-0.2-0.5-0.5-1-0.7-1.6c-0.1-0.2,0.2-0.4,0.3-0.7c0.3,0.1,0.7,0.2,0.8,0.4c0.1,0.6,0,1.2,0,1.8
			C298.9,255.3,298.8,255.3,298.6,255.4z"/>
		<path d="M394.2,187.8c-0.2-1-0.3-1.5-0.5-2.3c0,0,0.4-0.2,0.7-0.3c0.2,0.3,0.5,0.6,0.8,1C394.9,186.5,394.7,187,394.2,187.8z"/>
		<path d="M325,321.8c-0.2,0.8-0.2,1.1-0.3,1.5c0.5,0.1,1,0.2,1.5,0.3c-1,0.5-2,0.7-2.8-0.6C323.8,322.7,324.3,322.4,325,321.8z"/>
		<path d="M259.3,339.8c-1,0.1-1.5,0.2-2.2,0.3c0.3-0.7,0.5-1.2,0.8-1.9C258.3,338.6,258.6,339,259.3,339.8z"/>
		<path d="M260.8,313c0.1-0.4,0.3-0.9,0.4-1.4c0.5,0.2,1,0.4,1.6,0.7C262.3,313.2,261.7,313.4,260.8,313
			C260.8,312.9,260.8,313,260.8,313z"/>
		<path d="M272,265.2c-0.4,0.6-0.6,1-0.8,1.4c-0.4-0.2-0.8-0.4-1.3-0.6C270.2,264.9,271,265.1,272,265.2z"/>
		<path d="M353.4,294.6c0.5,1.2,1.3,1.9,0.1,2.6C352.4,296.4,353.2,295.7,353.4,294.6z"/>
		<path d="M275,273.9c-0.7,0.2-1.4,0.4-2.1,0.5C273.1,273.3,273.1,273.3,275,273.9z"/>
		<path d="M300.5,302.2c0.5-0.8,0.9-1.3,1.3-1.9c0.2,0.8,0.4,1.4,0.7,2.2C301.8,302.3,301.2,302.3,300.5,302.2z"/>
		<path d="M297.4,267.9c-0.1,1-0.4,1.5-1.1,1.2c-0.2-0.1-0.2-0.4-0.3-0.6C296.5,268.3,296.9,268.1,297.4,267.9z"/>
		<path d="M299.8,236.5c0-0.8-0.9-1.3,0-2C300.7,235.4,300.7,235.4,299.8,236.5z"/>
		<path d="M273.2,320.9c-0.5,0.2-1,0.3-1.5,0.5c-0.1-0.2-0.2-0.7-0.2-0.7c0.5-0.2,1-0.4,1.5-0.5
			C273.1,320.4,273.1,320.7,273.2,320.9z"/>
		<path d="M261.5,295.6c0.2,0.5,0.4,0.9,0.6,1.4C261.1,297,261.1,297,261.5,295.6z"/>
		<path d="M267.1,291c-0.5-0.4-1.7,0-1.5-1.4C266.7,289.4,267.2,289.9,267.1,291z"/>
		<path d="M310.8,347.2c-0.1,1.3-0.8,1.2-1.8,0.6C309.6,347.6,310.2,347.4,310.8,347.2C310.9,347.2,310.8,347.2,310.8,347.2z"/>
		<path d="M355,251.7c0,0.6,0,1.1,0,2c-0.8-0.8-0.6-1.4-0.5-2C354.7,251.7,354.8,251.7,355,251.7z"/>
		<path d="M312.6,331c0.9,1.7-1,1-1.3,1.7C311.1,331.7,311.6,331.2,312.6,331z"/>
		<path d="M314.3,325.1c0.7,0.1,1.2,0.1,2,0.2c-0.4,0.7-0.7,1.1-1.1,1.7C314.9,326.3,314.7,325.8,314.3,325.1z"/>
		<path d="M288.2,261.8c-0.2,0.6-0.4,1-0.5,1.4c-0.3-0.2-0.7-0.4-0.8-0.6C286.7,261.9,287,261.6,288.2,261.8z"/>
		<path d="M426.2,193.9c-0.9-1.6,0.2-2.1,0.5-3c0.1,0,0.3,0.1,0.4,0.1C426.8,191.9,426.6,192.7,426.2,193.9z"/>
		<path d="M346.2,323.7c-0.6,0.1-0.9,0.2-1.4,0.3c0-0.7-0.1-1.4-0.1-2c0.1,0,0.2-0.1,0.3-0.1C345.3,322.4,345.7,322.9,346.2,323.7z"
			/>
		<path d="M295.1,278.3c0.4,0.4,0.8,0.8,1.3,1.3C295.1,279.6,295.1,279.6,295.1,278.3z"/>
		<path d="M315.8,274.9c0.4,0.4,0.5,0.9,0,1.3c-0.1,0.1-0.7-0.1-0.8-0.2C314.8,275.3,315.1,274.9,315.8,274.9z"/>
		<path d="M378.9,273.7c-0.8,0.4-1.4,0.6-2.1,1C377.2,273.4,377.2,273.4,378.9,273.7z"/>
		<path d="M350.8,311.1c1.4-0.8,1.4-0.8,1.9,0.7C352.1,311.6,351.6,311.4,350.8,311.1z"/>
		<path d="M275.2,269.4c-0.4,0.6-0.7,1.1-1,1.6C274.1,269.6,274.1,269.6,275.2,269.4z"/>
		<path d="M303.5,314.2c-1.2,0.4-1.4-0.8-2.1-1.3C302.8,312.4,303,313.5,303.5,314.2L303.5,314.2z"/>
		<path d="M397.1,225.9c1.9,0.9,0.3,1.4,0.1,2.1C397.2,227.4,397.2,226.8,397.1,225.9z"/>
		<path d="M328,349.8c0.3,0.2,0.6,0.3,1,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0-1,0-1.4,0c-0.1-0.1-0.2-0.3-0.3-0.4
			C327.4,350.1,327.7,349.9,328,349.8z"/>
		<path d="M328.5,318.8c0.5,0,0.9-0.1,1.4-0.1c0,0.2,0,0.3,0,0.5c-0.5,0-0.9,0.1-1.4,0.1C328.6,319.1,328.5,318.9,328.5,318.8z"/>
		<path d="M328.7,344.3c1.5-0.1,1.9,0.4,2,1.8C330,345.4,329.5,345,328.7,344.3z"/>
		<path d="M261.2,292.1c0.6,0,1.2,0,1.9,0c-0.6,1-1.3,0.7-1.9,0.5C261.2,292.5,261.2,292.3,261.2,292.1z"/>
		<path d="M344.6,192.8c0,0.5,0,0.9,0,1.4c-0.1,0-0.2,0-0.4,0c0-0.5-0.1-0.9-0.1-1.4C344.3,192.8,344.4,192.8,344.6,192.8z"/>
		<path d="M338.4,197.7c-0.5-1-0.2-1.7,0.7-2.2C339.7,196.6,338.3,196.9,338.4,197.7z"/>
		<path d="M341.5,322.1c-0.4,0-0.9,0-1.3,0c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.5-0.3,0.7-0.3c0.2,0,0.5,0.1,0.7,0.2
			C341.6,321.8,341.5,322,341.5,322.1z"/>
		<path d="M288.9,350.8c0.4-0.2,0.7-0.4,1.1-0.6c0,0,0.3,0.4,0.3,0.5c-0.3,0.2-0.7,0.4-1.1,0.6C289.1,351.2,289,351,288.9,350.8z"/>
		<path d="M274.5,214.2c0.2-0.5,0.4-0.9,0.6-1.4c0.2,0.1,0.4,0.2,0.7,0.3c-0.3,0.5-0.5,0.9-0.8,1.4
			C274.9,214.3,274.7,214.2,274.5,214.2z"/>
		<path d="M274.1,329.3c1.3,0.8,0.9,1.2-0.2,1.9C274,330.3,274.1,329.9,274.1,329.3z"/>
		<path d="M307.5,243.2c-0.3,0.5-0.5,1-0.7,1.4c-0.2-0.1-0.4-0.2-0.4-0.2C306.2,243.7,306.2,243.1,307.5,243.2z"/>
		<path d="M288.3,189.8c0.4,0,0.9-0.1,1.5-0.1c-0.2,0.3-0.2,0.6-0.3,0.6c-0.4,0-0.8-0.1-1.2-0.1C288.3,190,288.3,189.9,288.3,189.8z
			"/>
		<path d="M290.8,271.3c0.3,1.7-0.4,1.6-1.5,1.4C289.8,272.2,290.1,271.9,290.8,271.3z"/>
		<path d="M286.7,248.1c-0.5,1.1-0.7,1.1-2.3-0.1C285.5,248,286.1,248.1,286.7,248.1L286.7,248.1z"/>
		<path d="M389.5,283.1c0.5,0,0.9,0,1.4,0c0,0.1,0,0.3,0,0.4c-0.5,0-1,0.1-1.4,0.1C389.5,283.4,389.5,283.2,389.5,283.1z"/>
		<path d="M406.2,219c-0.4,0.3-0.7,0.6-1.1,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.3,0.2-0.6,0.4-0.7c0.2-0.2,0.6-0.2,0.8-0.3
			C406,218.8,406.1,218.9,406.2,219z"/>
		<path d="M341.1,355.2c-0.2-0.5-0.4-0.7-0.4-1c0-0.2,0.4-0.5,0.5-0.4c0.3,0.1,0.6,0.3,0.6,0.5C341.8,354.5,341.4,354.8,341.1,355.2
			z"/>
		<path d="M273.9,275.7c-0.3,0.7-0.6,1.2-1,2C272.7,276.6,272.5,275.9,273.9,275.7z"/>
		<path d="M261.2,310c0.6-0.3,1.2-0.4,2.2,0.4c-1,0.1-1.6,0.1-2.2,0.1C261.2,310.3,261.2,310.2,261.2,310z"/>
		<path d="M327,341.5c-0.2-0.5-0.4-0.7-0.4-1c0-0.2,0.4-0.5,0.5-0.4c0.3,0.1,0.6,0.3,0.6,0.5C327.6,340.9,327.3,341.1,327,341.5z"/>
		<path d="M389.4,188.8c0.3-0.5,0.5-1,0.9-1.7C390.4,188.8,390.4,188.8,389.4,188.8z"/>
		<path d="M252.4,304.3c0.5,0.3,0.9,0.6,1.5,1c-1.2,0.7-1.4-0.2-1.8-0.6C252.2,304.5,252.3,304.4,252.4,304.3z"/>
		<path d="M279.7,333.8c-0.3,0.4-0.6,0.8-1,1.4c-0.5-1,0-1.4,0.5-1.7C279.3,333.6,279.5,333.7,279.7,333.8z"/>
		<path d="M348.7,247.8c-0.3-0.5-1.3-0.6-0.6-1.8c0.4,0.6,0.7,1,1,1.5C349,247.6,348.9,247.7,348.7,247.8z"/>
		<path d="M337.3,283.9c0.4-0.1,0.7-0.2,1.2-0.3c0,0.7,0,1.3,0,2.3C338,285.1,337.7,284.5,337.3,283.9z"/>
		<path d="M291.5,342.2c0,0.6,0.1,0.9,0,1c-0.3,0.3-0.6,0.6-0.9,0.8C290.1,343.1,290.8,342.8,291.5,342.2z"/>
		<path d="M265.5,314c-0.4-0.3-0.8-0.6-1.3-1c1.1-0.5,1.3,0.1,1.7,0.5C265.8,313.6,265.7,313.8,265.5,314z"/>
		<path d="M318.5,223.3c-0.5,0.3-0.9,0.6-1.4,0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.8-0.7,1.2-1
			C318.2,222.9,318.3,223.1,318.5,223.3z"/>
		<path d="M277.5,215.4c-0.2-0.5-0.4-0.8-0.4-1c0-0.2,0.4-0.4,0.5-0.4c0.2,0.1,0.6,0.4,0.6,0.6C278.3,214.8,277.9,215,277.5,215.4z"
			/>
		<path d="M269.8,330.3c-0.3,0.3-0.4,0.6-0.6,0.6c-0.2,0-0.4-0.2-0.6-0.3c0.2-0.3,0.3-0.5,0.5-0.8
			C269.3,330,269.5,330.1,269.8,330.3z"/>
		<path d="M318.8,330.3c-0.4,0.3-0.6,0.6-0.9,0.6c-0.1,0-0.5-0.3-0.5-0.4c0-0.3,0.2-0.6,0.4-0.7C318,329.8,318.4,330.1,318.8,330.3z
			"/>
		<path d="M268.1,298.2c0.4,0.7,1.3,0.7,1,1.7C268.2,299.4,268.2,299.4,268.1,298.2z"/>
		<path d="M271.1,298.5c0.5-0.2,0.9-0.4,1.4-0.6c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,1.2-0.9,0.5-1.4,0.7
			C271.2,298.8,271.1,298.7,271.1,298.5z"/>
		<path d="M353.6,317.9c0.4-0.2,0.7-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4C354.2,318.5,354,318.2,353.6,317.9z
			"/>
		<path d="M395.4,193c-1,0.3-1.7,0.4-1.7-0.9C394.3,192.3,394.7,192.6,395.4,193z"/>
		<path d="M351.2,337.3c0.5,0.3,1.3,0.3,0.6,1.4c-0.4-0.4-0.7-0.8-1-1.1C351,337.5,351.1,337.4,351.2,337.3z"/>
		<path d="M330.5,350.1c0.3,0.1,0.6,0.3,0.9,0.4c0,0-0.1,0.2-0.1,0.4c-0.3-0.1-0.6-0.2-1-0.2C330.4,350.5,330.4,350.3,330.5,350.1z"
			/>
		<path d="M267.3,315.6c-0.3,0.4-0.6,0.7-1,1.2c-0.5-1.3,0.3-1.2,0.6-1.5C267.1,315.4,267.2,315.5,267.3,315.6z"/>
		<path d="M303.4,314.2c0.5,0.1,0.9,0.3,1.5,0.5C303.7,315.1,303.7,315.1,303.4,314.2C303.5,314.2,303.4,314.2,303.4,314.2z"/>
		<path d="M351.2,313.4c0.5,0,0.9,0,1.4,0c0,0.1,0,0.2,0,0.4c-0.5,0-0.9,0.1-1.4,0.1C351.2,313.7,351.2,313.5,351.2,313.4z"/>
		<path d="M324.6,349.6c-0.4,0.5-0.6,0.7-0.9,1.1c-0.3-0.3-0.6-0.5-1.1-1C323.4,349.7,323.9,349.7,324.6,349.6z"/>
		<path d="M374.5,222.5c0,0.4,0,0.9,0,1.3c-0.1,0-0.3,0-0.4,0c0-0.4-0.1-0.9-0.1-1.3C374.1,222.5,374.3,222.5,374.5,222.5z"/>
		<path d="M291.4,268.6c0.3,1,0,1.3-1.1,1.2C290.8,269.4,291,269.1,291.4,268.6z"/>
		<path d="M406.4,220.6c0.5,0.1,0.9,0.3,1.4,0.4c0,0.2-0.1,0.3-0.1,0.5c-0.5,0-0.9-0.1-1.4-0.1C406.3,221.1,406.4,220.8,406.4,220.6
			z"/>
		<path d="M311.4,313.9c-0.4-1.9-0.4-1.9,0.6-2.4C311.8,312.2,311.7,312.9,311.4,313.9z"/>
		<path d="M341.8,327.3c0.1-0.5,0.1-1,0.2-1.8C342.7,326.4,342.7,326.4,341.8,327.3z"/>
		<path d="M419.3,220.9c0-0.5,0-0.9,0-1.4c0.1,0,0.2,0,0.3,0c0,0.5,0,0.9,0,1.4C419.5,220.9,419.4,220.9,419.3,220.9z"/>
		<path d="M291.3,276.9c-0.4,0-0.8,0-1.1,0c0-0.1,0-0.2,0-0.4c0.4,0,0.8-0.1,1.2-0.1C291.3,276.5,291.3,276.7,291.3,276.9z"/>
		<path d="M257.9,305.8c-0.3-0.4-1.3-0.3-0.8-1.4C257.8,304.8,258.3,305,257.9,305.8C257.9,305.9,257.9,305.8,257.9,305.8z"/>
		<path d="M258.1,284.8c-0.9,0.5-1.2,0.2-0.9-0.8C257.5,284.4,257.8,284.6,258.1,284.8z"/>
		<path d="M353.7,303.2c-0.3,0.6-0.5,1-0.8,1.5c-0.2-0.2-0.5-0.4-0.5-0.6C352.4,303.4,352.9,303.2,353.7,303.2z"/>
		<path d="M260.3,287.1c-0.4-0.3-0.6-0.6-1-0.8C260.3,286,260.3,286,260.3,287.1z"/>
		<path d="M355.8,243.9c0-0.4,0-0.8,0-1.1c0.1,0,0.2,0,0.3,0c0.1,0.4,0.1,0.7,0.2,1.1C356.1,243.9,356,243.9,355.8,243.9z"/>
		<path d="M266.2,333.7c0.3-0.4,0.5-0.8,1-1.4c0.1,0.9,0.1,1.5,0.1,2.4C266.7,334.2,266.5,334,266.2,333.7z"/>
		<path d="M290.9,243c-0.2,0-0.4,0.2-0.6,0.1c-0.3-0.2-0.6-0.5-0.8-1c0.3,0.1,0.6,0,0.8,0.2C290.6,242.4,290.7,242.7,290.9,243
			L290.9,243z"/>
		<path d="M294.3,240.6c-0.2-0.9-0.1-1.4,1.1-1.1C294.9,239.9,294.7,240.2,294.3,240.6z"/>
		<path d="M297.6,244c-0.4-0.6-0.6-0.8-0.8-1.1C297.7,242.7,297.7,242.7,297.6,244z"/>
		<path d="M386.6,189c0.2,0.9,0.1,1.4-1,1.1C386,189.7,386.2,189.4,386.6,189z"/>
		<path d="M279.6,325.1c0.2-0.4,0.3-0.7,0.5-1.1c0,0,0.3,0.1,0.3,0.1c-0.1,0.4-0.1,0.8-0.2,1.2C279.9,325.3,279.8,325.2,279.6,325.1
			z"/>
		<path d="M343.9,252.7c-0.3,0.4-0.5,0.7-0.7,1C342.5,252.9,343,252.7,343.9,252.7z"/>
		<path d="M367.1,322.9c-0.5-0.5-0.7-0.8-1-1.1C367.1,321.3,367.3,321.7,367.1,322.9z"/>
		<path d="M400.2,223.9c0.5-0.5,0.8-0.7,1.1-1C401.7,223.9,401.3,224,400.2,223.9z"/>
		<path d="M279.4,269.4c0.1,0.3,0.3,0.6,0.4,0.9c-0.2,0-0.5,0.1-0.5,0.1c-0.1-0.3-0.2-0.6-0.2-0.9
			C279.1,269.5,279.3,269.4,279.4,269.4z"/>
		<path d="M291,220.1c1.1-0.4,1.2,0,1.1,1C291.7,220.7,291.4,220.5,291,220.1z"/>
		<path d="M259.5,310.5c0.3,0.4,0.6,0.7,1,1.2c-1.1,0.4-1.4,0-1.5-0.7C259.2,310.9,259.3,310.7,259.5,310.5z"/>
		<path d="M301.2,307.4c0,0.7,0,1.4,0,2.3C300.4,308.5,300.4,308.5,301.2,307.4L301.2,307.4z"/>
		<path d="M353.9,306.7c-0.3,0.2-0.4,0.5-0.5,0.5c-0.2-0.1-0.4-0.3-0.5-0.4c0,0,0.1-0.3,0.2-0.3
			C353.2,306.5,353.5,306.6,353.9,306.7z"/>
		<path d="M295.8,284.7c0.4-1.3,1-0.9,1.6-0.9C296.9,284.1,296.6,284.3,295.8,284.7z"/>
		<path d="M262.8,302.3c-0.2-0.1-0.5-0.2-0.6-0.3c-0.2-0.2-0.3-0.5-0.1-1c0.3,0.3,0.6,0.7,1,1C262.9,302.1,262.9,302.2,262.8,302.3z
			"/>
		<path d="M259.4,300.2c0.4,0.3,0.6,0.5,1,0.8C259.5,301.4,259.5,301.4,259.4,300.2z"/>
		<path d="M280.4,293c0,0.5,0.1,1,0.1,1.5c-0.1,0-0.2,0-0.4,0.1c-0.1-0.5-0.3-1-0.4-1.5C280,293,280.2,293,280.4,293z"/>
		<path d="M416.9,213.3c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
			C416.7,213.8,416.8,213.6,416.9,213.3z"/>
		<path d="M344.4,214c0.1,0.3,0.3,0.4,0.3,0.6c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.3-0.3-0.4C344.1,214.4,344.2,214.2,344.4,214z"
			/>
		<path d="M289.7,217.1c-0.2-0.3-0.4-0.5-0.4-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1,0.1,0.4,0.1,0.4,0.2
			C289.9,216.4,289.8,216.6,289.7,217.1z"/>
		<path d="M341,247.2c0.4,0,0.8,0.1,1.4,0.1C341.5,248.3,341.3,247.7,341,247.2C341,247.2,341,247.2,341,247.2z"/>
		<path d="M286.4,341.8c-0.4,0.2-0.7,0.4-0.8,0.4c-0.2,0-0.4-0.3-0.5-0.4c0.2-0.1,0.3-0.4,0.5-0.4
			C285.8,341.3,286,341.5,286.4,341.8z"/>
		<path d="M420.3,219.2c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
			C420.5,219.5,420.4,219.4,420.3,219.2z"/>
		<path d="M418.6,211.6c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C418.3,211.3,418.4,211.4,418.6,211.6z"/>
		<path d="M389.5,220c0.2,0.2,0.4,0.3,0.4,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3C389.3,220.5,389.4,220.3,389.5,220z"
			/>
		<path d="M305.3,348.2c-0.1-0.3-0.3-0.5-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4C305.7,347.8,305.5,348,305.3,348.2
			z"/>
		<path d="M289.7,203.1c-0.6,0-1.3,0-1.9,0c0-0.1,0-0.2,0-0.2c0.6,0,1.3,0,1.9,0C289.7,203,289.7,203.1,289.7,203.1z"/>
		<path d="M271.5,349.4c-0.4,0.1-0.7,0.1-0.9,0.1c-0.1,0-0.1-0.3-0.2-0.4c0.2,0,0.4-0.2,0.5-0.1C271.1,349,271.2,349.1,271.5,349.4z
			"/>
		<path d="M283,349.2c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3C282.6,348.9,282.8,349.1,283,349.2z
			"/>
		<path d="M287.4,349.3c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C287,348.9,287.1,349.1,287.4,349.3z"/>
		<path d="M402.1,199.9c-0.3,0.1-0.5,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3
			C401.7,199.6,401.8,199.8,402.1,199.9z"/>
		<path d="M412.6,197.8c0.1,0.2,0.2,0.3,0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.4-0.3
			C412.2,198.1,412.4,198,412.6,197.8z"/>
		<path d="M416.4,196.6c-0.5-0.1-0.9-0.1-1.4-0.2c0-0.2,0.1-0.3,0.1-0.5c0.5,0.1,0.9,0.2,1.4,0.3
			C416.4,196.3,416.4,196.5,416.4,196.6z"/>
		<path d="M305.8,226c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.3,0.1,0.5,0.2C306,225.7,305.9,225.8,305.8,226z"/>
		<path d="M312.4,225.8c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0.2-0.3,0.2-0.2
			C312.1,225.5,312.2,225.7,312.4,225.8z"/>
		<path d="M397.7,195.8c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3
			C397.3,195.5,397.5,195.6,397.7,195.8z"/>
		<path d="M344.8,196.2c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3C345,195.8,344.9,196,344.8,196.2z"
			/>
		<path d="M319.8,226c-0.2-0.2-0.4-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.3c0.2,0,0.3,0.2,0.5,0.3C320.1,225.7,320,225.8,319.8,226z"/>
		<path d="M374.7,225.8c-0.2,0.2-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0.2-0.3,0.2-0.3
			C374.3,225.5,374.4,225.6,374.7,225.8z"/>
		<path d="M419.1,225.6c-0.3,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.2-0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C418.6,225.3,418.8,225.4,419.1,225.6z"/>
		<path d="M405.5,227.1c-0.2-0.3-0.3-0.5-0.4-0.7c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.3,0.3,0.5,0.5C406,226.7,405.8,226.8,405.5,227.1
			z"/>
		<path d="M292.3,352.5c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2
			C292.5,352.2,292.4,352.3,292.3,352.5z"/>
		<path d="M364.3,192.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
			C363.9,192.2,364.1,192.3,364.3,192.4z"/>
		<path d="M314.2,227.6c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
			C313.4,227.2,313.6,227.3,314.2,227.6z"/>
		<path d="M405.2,189.1c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.3,0.2,0.3,0.2
			C405.4,188.8,405.3,188.9,405.2,189.1z"/>
		<path d="M416.9,230c-0.1-0.3-0.3-0.5-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4C417.3,229.6,417.1,229.8,416.9,230z"
			/>
		<path d="M300.7,229.9c-0.3,0.1-0.5,0.2-0.6,0.1c-0.1,0-0.1-0.3-0.1-0.4c0,0,0.2-0.2,0.3-0.1C300.4,229.5,300.5,229.7,300.7,229.9z
			"/>
		<path d="M336.2,189.1c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C336.5,188.8,336.4,188.9,336.2,189.1z"/>
		<path d="M297.9,238.4c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.2-0.4c0-0.1,0.2-0.3,0.3-0.4
			C297.6,238.1,297.8,238.2,297.9,238.4z"/>
		<path d="M322.6,332.1c-0.7,0.5-0.9,0.7-1.1,0.8c-0.1-0.2-0.4-0.4-0.4-0.6c0-0.2,0.3-0.4,0.5-0.5
			C321.8,331.8,322.1,332,322.6,332.1z"/>
		<path d="M265.1,332.9c-0.1-0.3-0.3-0.5-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C265.4,332.5,265.3,332.6,265.1,332.9z"/>
		<path d="M404.6,186.2c-0.3,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.2-0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C404.1,185.9,404.3,186,404.6,186.2z"/>
		<path d="M290.9,243c0.3,0.2,1,0.3,0.4,1.2C291.1,243.6,291,243.3,290.9,243C290.9,243,290.9,243,290.9,243z"/>
		<path d="M349,243.4c-0.2,0.2-0.4,0.4-0.5,0.4c-0.2,0-0.3-0.1-0.5-0.2c0.1-0.1,0.2-0.3,0.4-0.4C348.5,243.1,348.7,243.3,349,243.4z
			"/>
		<path d="M251.9,328.7c0.2-0.2,0.3-0.3,0.4-0.5c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1-0.1,0.3-0.2,0.3C252.2,329,252.1,328.9,251.9,328.7z
			"/>
		<path d="M306.6,246.6c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C306.9,246.2,306.8,246.3,306.6,246.6z"/>
		<path d="M286.8,248.1c0-0.4,0.1-0.8,0.1-1.3C287.4,247.5,287.4,247.5,286.8,248.1C286.7,248.1,286.8,248.1,286.8,248.1z"/>
		<path d="M253.5,325.8c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4C253.2,326.2,253.4,326,253.5,325.8
			z"/>
		<path d="M296.2,250.1c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C296.6,249.8,296.4,249.9,296.2,250.1z"/>
		<path d="M256.6,323.4c0.4,0.2,0.9,0.3,1.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4-0.2-0.9-0.3-1.3-0.5
			C256.5,323.7,256.6,323.6,256.6,323.4z"/>
		<path d="M268.6,323c0.3-0.1,0.5-0.2,0.6-0.2c0.1,0,0.2,0.2,0.2,0.4c0,0-0.2,0.2-0.3,0.2C269,323.3,268.9,323.2,268.6,323z"/>
		<path d="M270.5,253.8c0.2,0.3,0.3,0.4,0.3,0.5c0,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4
			C270.2,254.2,270.4,254.1,270.5,253.8z"/>
		<path d="M260.5,320.1c0,0.4-0.1,0.8-0.1,1.3C259.9,320.7,259.9,320.7,260.5,320.1C260.6,320.2,260.5,320.1,260.5,320.1z"/>
		<path d="M297.8,267.1c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C297.3,266.8,297.5,266.9,297.8,267.1z"/>
		<path d="M355.1,313.1c0.1,0.2,0.2,0.3,0.4,0.5c-0.2,0.1-0.3,0.3-0.5,0.3c-0.1,0-0.3-0.2-0.4-0.3
			C354.7,313.4,354.9,313.3,355.1,313.1z"/>
		<path d="M270.5,273.4c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C270.9,272.9,270.7,273.1,270.5,273.4z"/>
		<path d="M292.2,273.8c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C291.8,273.5,291.9,273.6,292.2,273.8z"/>
		<path d="M288.6,274.8c0.7-0.4,1.1-0.6,1.4-0.8c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.3,0.5-0.5,0.6
			C289.5,275,289.2,274.9,288.6,274.8z"/>
		<path d="M353.4,307.8c0.1,0.2,0.2,0.3,0.2,0.5c0,0-0.3,0.2-0.3,0.2c-0.1-0.1-0.2-0.3-0.3-0.4C353.1,308,353.2,307.9,353.4,307.8z"
			/>
		<path d="M271.4,275.4c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C271,275.1,271.2,275.3,271.4,275.4z"/>
		<path d="M314.8,277.9c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C314.4,277.6,314.5,277.8,314.8,277.9z"/>
		<path d="M384.7,306.1c0.1,0.3,0.3,0.5,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
			C384.4,306.5,384.5,306.4,384.7,306.1z"/>
		<path d="M301.3,307.4c0.1-0.3,0.1-0.7,0.2-1.2C302.4,307.1,301.5,307.1,301.3,307.4C301.2,307.4,301.3,307.4,301.3,307.4z"/>
		<path d="M325.1,279.2c-0.3,0.1-0.5,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C324.7,278.8,324.9,279,325.1,279.2z"/>
		<path d="M332.5,283.7c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C332.8,283.4,332.6,283.5,332.5,283.7z"/>
		<path d="M271.7,303.8c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
			C271.5,304.2,271.6,304.1,271.7,303.8z"/>
		<path d="M310.9,287.2c0-0.5,0-0.9,0-1.4c0.1,0,0.1,0,0.2,0c0,0.5,0,0.9,0,1.4C311,287.2,310.9,287.2,310.9,287.2z"/>
		<path d="M298,301c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4c0.2,0,0.4,0.1,0.5,0.2
			C298,300.7,298,300.9,298,301z"/>
		<path d="M350,289.7c-0.2,0.3-0.4,0.5-0.6,0.8C349.1,289.7,349.1,289.7,350,289.7C350,289.7,350,289.7,350,289.7z"/>
		<path d="M254.4,294.7c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3C254.6,295,254.5,294.9,254.4,294.7z
			"/>
		<path d="M776.9,336.2c-0.3-0.8-0.5-1.5-1-2.7c1.6,1.1,2.8,2,4.4,3c-0.6-1.7-1.1-3.1,0-4.7c-0.6,0.1-1.1,0.1-1.6,0.2
			c-0.3-1.7,0.2-2.4,2.6-3.2c0.2,0.7,0.6,1.1,0.2,2.1c-0.4,1.1,0.3,2.6,0.5,4.1c-0.2,0-0.5,0-1,0c-0.1,1-0.5,2,0.9,2.4l0,0
			c0.1,0.5,0.1,0.9,0.2,1.4l0,0c-0.4,0.1-0.7,0.3-1.3,0.5c1.6,1.5-0.4,2.8,0.1,4.3c-2.8-1.1-3.2-2.1-1.7-3.9c0-0.4,0-0.8,0-1.2
			C778.6,337.5,778.2,336.3,776.9,336.2L776.9,336.2z"/>
		<path d="M788.4,270.9c-0.4-0.2-0.8-0.5-1.3-0.7c0.2-0.3,0.3-0.7,0.5-0.8c0.9-0.7,1.1-1.4,0.7-2.6c-0.4-1.2-0.5-2.9,0-4.1
			c0.6-1.3-0.1-1.9-0.8-2.7c2.2-1.9,0.7-4,0.4-5.9c-0.3-1.6-0.5-3.1,1.3-4.3c-0.1,0.5-0.2,0.8-0.2,1c-0.1,0.8-0.6,1.8-0.3,2.5
			c0.5,1.4,0.9,2.7,0.7,4.2c-0.1,1.1,0.4,2.2,0.4,3.4c0.1,1.4-0.2,2.9,0,4.4C790.2,267.3,789.8,269.3,788.4,270.9z"/>
		<path d="M788.3,344.4c-0.4-2.1-2.2-4.2-0.2-6.5c-0.7-0.7-1.1-1.4-0.7-2.5c0.2-0.6-0.3-1.6-0.4-2.4c-0.1-0.3-0.1-0.7-0.1-1.4
			c1,1.1,1.6,1.9,2.2,2.5c-0.1,1.1-0.3,2.1-0.4,3.3c0.7-0.2,1.1-0.3,1.7-0.4c0.1,1.9,0.3,3.8,0.4,5.4
			C789.8,343.2,789.1,343.8,788.3,344.4z"/>
		<path d="M754.4,310c1.5-0.4,2.8-1,3.2-2.7c0.1-0.2,0.5-0.3,0.9-0.6c-0.1,0.7-0.2,1.2-0.3,1.9c1.3,0,2.6,0,3.8,0
			c0.1,0.1,0.1,0.2,0.2,0.4c-0.3,0.3-0.6,0.6-1,0.7c-0.8,0.3-1.6,0.5-2.3,0.5c-1.4,0-1.9,0.8-2,2.1c0,0.1-0.3,0.3-0.5,0.5
			C755.8,311.9,755.8,310.4,754.4,310C754.4,310,754.4,310,754.4,310z"/>
		<path d="M789.5,352.2c-0.4-0.1-0.8-0.2-1.2-0.3c0.2-1.8,0.2-1.8-1-2.6c1-1.7,1.1-2.2,0.5-3.7c1.3,0.5,2.4,1.7,2.1,2.7
			c-0.4,1.1-0.1,1.9,0.5,2.7C790.1,351.4,789.7,351.8,789.5,352.2C789.4,352.2,789.5,352.2,789.5,352.2z"/>
		<path d="M816.5,349.6c0.2-1.1,0.4-2.2,0.7-3.4c0.4,0.2,0.8,0.4,1.3,0.6c-0.3,0.2-0.5,0.4-0.8,0.7c0.6,0.2,1.1,0.4,1.9,0.7
			c-0.8,0.3-1.3,0.6-1.9,0.8c0.3,1.3,0.9,2.6,0.2,4.1c-0.2-0.1-0.7-0.1-0.7-0.2C817.1,351.9,816,350.9,816.5,349.6
			C816.5,349.6,816.5,349.6,816.5,349.6z"/>
		<path d="M784.4,270.2c0.4,0.5,0.8,0.8,0.8,1.1c0.1,2.4,0,4.8,0,7.4C784.2,277,783.7,272.9,784.4,270.2z"/>
		<path d="M788.5,242.9c-0.1,1.2,1.7,2.1,0.5,3.4c0.3,0.4,0.8,0.7,0.9,1.1c0.1,0.4-0.8,1.4-1.3,1.7C787,247.1,788.2,245,788.5,242.9
			z"/>
		<path d="M824.8,338c0,0-0.2-0.3-0.5-0.5c0.5-0.4,0.9-0.7,1.3-1c0.2-0.2,0.4-0.5,0.6-0.5c0.9,0,1.7,0.4,2.5-0.6
			c0.5-0.6,1.6-0.8,2.8-1.3c-0.3,0.7-0.5,1.3-0.6,1.3c-1.1,0.1-1.8,0.9-2.6,1.4c-0.2,0.1-0.4,0.1-0.6,0.2
			C826.9,337.4,825.9,337.7,824.8,338z"/>
		<path d="M793.5,354.1c-0.5-0.9-0.9-1.7-1.3-2.4c1.2-0.5,2.5-1,3.8-1.5C795.8,352,794.3,352.7,793.5,354.1z"/>
		<path d="M764.4,309.9c0.3-0.4,0.5-0.9,0.9-1.1c1.3-0.5,2.7-1,4.1-1.4c0.6-0.2,1.1,0.1,1,0.8c-1.9,0.6-3.7,1.3-5.6,1.9
			C764.7,310.1,764.5,310,764.4,309.9z"/>
		<path d="M787.2,305.2c-0.9-1.2-1-2.3-0.1-3.5c0.8,0.6,1.6,1,2.7,0.2C789.6,303.8,786.8,303.1,787.2,305.2z"/>
		<path d="M779.4,304.7c2-0.5,4-0.9,6-1.4c0.1,0,0.2,0.3,0.5,0.6c-0.8,0.3-1.5,0.7-2.3,0.7c-1.6,0.2-1.8,0.2-2.8,0.9
			C780.4,305.3,779.9,305,779.4,304.7L779.4,304.7z"/>
		<path d="M813.1,312.6c0.8,0.6,1.1,1.1,1,2c-0.4,1.7-0.8,2.5-1.8,3C812.6,316,812.8,314.4,813.1,312.6z"/>
		<path d="M809.7,342.4c-0.4,0-0.8,0-1.5,0c0.2-0.6,0.3-1.1,0.4-1.6c-0.3-0.1-0.7-0.3-1.1-0.5c0.1-0.2,0.3-0.4,0.4-0.6
			c0.7,0,1.4,0,2.2,0C810,340.7,809.9,341.5,809.7,342.4z"/>
		<path d="M861.1,322c2,0.1,1.5-1.7,2.4-2.9c0.2,1.3,0.6,2.2-0.5,3c-0.1,0.1,0,0.5,0,0.7c0,0.4,0,0.9-0.2,1
			c-0.6,0.3-0.8-0.1-0.9-0.7C861.8,322.8,861.5,322.5,861.1,322z"/>
		<path d="M783.7,248.8c-0.4-0.4-0.9-0.8-1.4-1.3c0.7-0.7,1.1-1.9,2.7-1.4C784.5,247.1,784.1,248,783.7,248.8z"/>
		<path d="M805.2,326.3c0.5-1.2,0.9-2.2,1.4-3.1c0.1-0.2,0.5-0.2,0.8-0.3c0.1,0.2,0.3,0.6,0.2,0.7c-0.4,0.9-0.8,1.8-1.4,2.7
			C806.3,326.4,805.7,326.3,805.2,326.3z"/>
		<path d="M779.4,304.7c0,0.3,0,0.5-0.1,0.8c-1.3-0.4-1.4,1.6-2.6,1.4c-0.2,0-0.6-0.8-0.5-0.8c0.4-0.4,0.9-0.8,1.4-1
			C778.2,304.7,778.8,304.8,779.4,304.7C779.4,304.7,779.4,304.7,779.4,304.7z"/>
		<path d="M796.3,329.3c0.7,0.7,1.5,1.4,2.1,2.1c0.1,0.1,0.1,0.6-0.1,0.7c-0.1,0.1-0.7,0.1-0.7,0c-0.1-0.9-1-0.9-1.6-1.4
			c-0.3-0.2-0.3-0.7-0.4-1.1C795.9,329.5,796.1,329.4,796.3,329.3z"/>
		<path d="M812.2,345.7c0.4,0.8,0.6,1.4,0.9,2c-0.4,0.3-0.8,0.5-1.3,0.8c-0.4-0.7-0.6-1.2-0.9-1.7
			C811.2,346.6,811.6,346.2,812.2,345.7z"/>
		<path d="M787.5,328.6c0-0.4-0.2-0.9-0.1-1.2c0.4-0.7,1-1.1,1.9-0.8C789.3,327.8,788.3,328,787.5,328.6z"/>
		<path d="M775.4,342.4c0,0.8,0,1.2,0,1.7c-0.5,0.2-1,0.3-1.5,0.5c-0.2-0.4-0.5-0.8-0.7-1.3C773.8,343,774.5,342.7,775.4,342.4z"/>
		<path d="M814.2,349.9c-1.1,0.1-1.8,2.1-3.3,0.7C811.9,349.7,812.9,349.3,814.2,349.9z"/>
		<path d="M781.5,324.6c-0.8-0.4-1.3-0.7-1.9-0.9c0.1-1.2,1-0.8,1.8-0.9C781.4,323.4,781.5,323.9,781.5,324.6z"/>
		<path d="M817.9,338.6c0.2,0.4,0.4,0.8,0.6,1.3c-0.4,0.4-0.7,0.8-1.3,1.6c-0.1-1-0.1-1.5-0.2-2.1
			C816.9,338.8,817.1,338.4,817.9,338.6L817.9,338.6z"/>
		<path d="M873,317.2c0.8-0.5,1.6-1,2.4-1.4c0.1,0,0.5,0.4,0.8,0.8c-1.2,0.4-2.1,0.7-3.1,1C873.2,317.4,873.1,317.3,873,317.2z"/>
		<path d="M751.8,316.5c0.3-0.2,0.5-0.4,0.9-0.7c0.3,0.7,0.5,1.3,0.8,2C751.9,317.8,751.9,317.8,751.8,316.5L751.8,316.5z"/>
		<path d="M776.9,336.1c0.6,1.2-0.1,2.4-0.3,3.6c-0.3,0.1-0.6,0.2-1,0.3C776,338.7,776.4,337.4,776.9,336.1
			C776.9,336.2,776.9,336.1,776.9,336.1z"/>
		<path d="M774.7,308.4c-1.2-0.8-1.2-0.8,0-2.9C774.7,306.7,774.7,307.5,774.7,308.4z"/>
		<path d="M785.2,335.1c-0.3,0.3-0.5,0.4-0.9,0.8c-0.1-1.2-1.6-1.3-1.4-3C783.8,333.7,784.4,334.4,785.2,335.1z"/>
		<path d="M862.4,333c0.5,1.5-0.2,2.2-1.4,2.8C860.9,334.6,861.3,333.8,862.4,333z"/>
		<path d="M823,347.5c0.3-0.7,0.5-1.3,0.8-2c0.5,0,1,0.1,1.4,0.1c0.1,0.1,0.2,0.3,0.3,0.4c-0.7,0.6-1.5,1.2-2.2,1.8
			C823.2,347.6,823.1,347.6,823,347.5z"/>
		<path d="M822.2,342.5c0.9,0.4,1.4,0.6,2,0.8c-0.3,0.5-0.6,1-1.1,1.6C822.7,344.1,822.5,343.5,822.2,342.5z"/>
		<path d="M787.2,313.1c0.8,0.1,0.9,0.4,0.4,1c-0.1,0.1,0.2,0.6,0.3,1c-0.5,0-0.8,0-1.3,0C786.9,314.4,787.1,313.7,787.2,313.1z"/>
		<path d="M869.5,351.1c0.5-0.8,1-1.5,1.5-2.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.3,0.9-0.2,2-1.5,2.3C869.8,351.3,869.7,351.2,869.5,351.1
			z"/>
		<path d="M785.5,283.3c-0.3-1.2-0.5-2.4-0.8-3.6c0.3,0,0.6-0.1,1-0.2c0,1.3,0,2.6,0,3.8C785.6,283.3,785.6,283.3,785.5,283.3z"/>
		<path d="M784.3,344.2c-0.3-0.3-0.5-0.4-0.8-0.7c0.2-0.5,0.5-1,0.8-1.6c0.2,0.2,0.4,0.3,0.5,0.5
			C785.3,343.2,785.3,343.2,784.3,344.2z"/>
		<path d="M810.8,337.7c-0.5,0.4-0.9,0.9-1.7,1.6c-0.1-1-0.2-1.5-0.2-2.1c0.6,0,1.1,0,1.6,0C810.6,337.4,810.7,337.6,810.8,337.7z"
			/>
		<path d="M844.3,342.9c0.8,0,1.5,0,2.3,0c0.2,0.6-0.1,0.9-0.8,0.9C845.3,343.7,844.4,344,844.3,342.9z"/>
		<path d="M780.5,347.1c-0.8,0-1.6,0-2.6,0c0.1-0.4,0-0.8,0.2-1C779.1,345.5,779.7,346.6,780.5,347.1z"/>
		<path d="M782,338.8c0.6,0,0.9,1.1,1.6,0.2c0.3,0.3,0.7,0.7,1,1c-0.1,0.1-0.2,0.2-0.3,0.3C783.7,339.7,782,340.7,782,338.8
			C782,338.8,782,338.8,782,338.8z"/>
		<path d="M783.4,224.4c-0.3,0.9-0.5,1.7-0.8,2.7C782.1,225.9,782.1,225.9,783.4,224.4z"/>
		<path d="M795.5,220.9c0,1.9-1.4,1-2.8,1.3c0.6-0.5,0.8-0.9,1-1C794.4,221,795,221,795.5,220.9L795.5,220.9z"/>
		<path d="M868.4,311.5c1.4,0.7,1.4,0.9,0,2.2C868.4,312.9,868.4,312.3,868.4,311.5z"/>
		<path d="M787.2,308.8c0.2,1,0.4,1.8,0.7,2.7c-0.1,0.1-0.4,0.2-1,0.5C787.6,310.8,785.7,310,787.2,308.8z"/>
		<path d="M793.5,243.7c1,0.3,1.5,0.7,1.4,1.7C793.3,245.3,793.3,245.3,793.5,243.7z"/>
		<path d="M765.5,314c0.8,0.1,1.6,0.2,2.4,0.3c0.2,1-0.6,0.8-1.1,0.8C765.9,315.2,765.5,314.8,765.5,314z"/>
		<path d="M804,314.9c0,0.4,0,0.9-0.1,1.6c-0.6-0.2-1.5-0.3-1.6-0.6C802.1,314.7,803.6,315.4,804,314.9z"/>
		<path d="M862,351.1c0,0.6-0.1,1.3-0.1,2C860.9,352.3,860.9,352.3,862,351.1z"/>
		<path d="M817.9,338.6c-0.1-0.8-0.2-1.7-0.3-2.5c0.1,0,0.2-0.1,0.4-0.1c0.4,0.4,0.8,0.9,1.2,1.3
			C818.7,337.8,818.3,338.2,817.9,338.6C817.9,338.6,817.9,338.6,817.9,338.6z"/>
		<path d="M868.5,353.3c-0.9,0.4-1.4,0.6-2.2,0.9C867.2,352.6,867.2,352.6,868.5,353.3z"/>
		<path d="M833.6,321.3c0.6,0,1.3,0,1.9,0c0,0.1,0,0.2,0,0.3c-0.6,0-1.3,0-1.9,0C833.6,321.5,833.6,321.4,833.6,321.3z"/>
		<path d="M760.1,303.6c0,0.6-0.1,1.3-0.1,2C758.7,305.1,758.7,305.1,760.1,303.6z"/>
		<path d="M774.6,337.3c-0.2,0.5-0.5,1.1-0.8,1.8c-0.4-0.3-0.9-0.5-0.8-0.7C773,337.6,773.4,337.5,774.6,337.3z"/>
		<path d="M789.5,311.2c1.7,0.3,1.8,0.3,0.7,1.6C790,312.2,789.8,311.7,789.5,311.2z"/>
		<path d="M855.6,325c-0.3-1.6,1.2-0.9,1.9-1.9C857.5,324.8,856.2,324.5,855.6,325L855.6,325z"/>
		<path d="M793,301.9c-0.5,0.2-1,0.5-1.4,0.7c-0.1-0.2-0.2-0.5-0.3-0.7c0.5-0.2,1-0.3,1.5-0.5C792.8,301.6,792.9,301.7,793,301.9z"
			/>
		<path d="M869.1,338.6c-1.2,0.2-1.2,0.2-2-0.7C868.1,337.3,868.4,338.7,869.1,338.6z"/>
		<path d="M779.1,338.5c0,0.4,0,0.8,0,1.2c-0.1,0-0.1,0-0.2,0c-0.5,0-1.2,0-1.1-0.8C778,338.1,778.6,338.5,779.1,338.5z"/>
		<path d="M794.8,341.3c-0.5,0.1-1,0.3-1.5,0.4c0-0.2-0.2-0.5-0.1-0.5c0.5-0.2,0.9-0.4,1.4-0.6C794.7,340.8,794.7,341,794.8,341.3z"
			/>
		<path d="M785.7,332.3c-0.5,0.2-0.9,0.4-1.4,0.6c-0.1-0.2-0.2-0.4-0.2-0.5c0.2-1,1-0.3,1.4-0.6
			C785.5,331.9,785.6,332.1,785.7,332.3z"/>
		<path d="M781.8,337.5c0.1-0.4,0.2-0.9,0.4-1.5c0.3,0.3,0.9,0.5,0.8,0.7C783,337.4,782.6,337.8,781.8,337.5
			C781.8,337.4,781.8,337.5,781.8,337.5z"/>
		<path d="M786.4,291c0,0.5,0,0.9,0,1.4c-0.3-0.2-0.6-0.3-0.7-0.4C785.1,291.2,785.7,291.1,786.4,291z"/>
		<path d="M776.1,252.9c0,0.5-0.1,0.9-0.1,1.4c-0.1,0-0.2,0-0.4,0c0-0.5,0-0.9,0-1.4C775.8,252.9,775.9,252.9,776.1,252.9z"/>
		<path d="M805.4,328.5c0-0.4,0-0.9,0-1.5c0.8,0.6,0.6,1.1,0.4,1.5C805.7,328.5,805.6,328.5,805.4,328.5z"/>
		<path d="M800.6,327.4c-0.4,0.3-0.6,0.6-0.8,0.6c-0.2,0-0.6-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.5-0.7
			C799.8,326.9,800.1,327.2,800.6,327.4z"/>
		<path d="M764.3,342.4c0.5-0.2,0.8-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4C764.9,343,764.7,342.7,764.3,342.4z
			"/>
		<path d="M795,327.3c-0.4,0.1-0.7,0.2-0.9,0.2c-0.2-0.1-0.3-0.4-0.4-0.6c0.3-0.1,0.5-0.3,0.8-0.4C794.7,326.6,794.8,327,795,327.3z
			"/>
		<path d="M775.6,340c0.3-0.1,0.6-0.2,1-0.3c0.5,0.2,0.6,0.6,0.1,0.8c-0.7,0.2-1.4,0-2.2,0C774.7,339.9,775,339.5,775.6,340z"/>
		<path d="M792.1,210c-0.4-0.3-0.9-0.5-1.5-0.9C791.6,208.9,791.6,208.9,792.1,210z"/>
		<path d="M855.6,325c-0.7,0.2-1.4,0.5-2.1,0.7C853.9,324.6,853.9,324.6,855.6,325L855.6,325z"/>
		<path d="M822.5,334.9c-0.5-0.3-0.9-0.6-1.5-1c1.2-0.7,1.3,0.2,1.7,0.6C822.7,334.6,822.6,334.7,822.5,334.9z"/>
		<path d="M764.9,304.2c-0.2-0.5-0.4-0.8-0.4-1c0-0.2,0.4-0.4,0.5-0.4c0.2,0.1,0.6,0.4,0.6,0.6C765.7,303.6,765.3,303.8,764.9,304.2
			z"/>
		<path d="M811.6,328.3c0.3,0.5,1.2,0.6,0.7,1.9c-0.5-0.6-0.8-1.1-1.1-1.5C811.4,328.5,811.5,328.4,811.6,328.3z"/>
		<path d="M757.4,338c1.5-0.6,1.5,0.3,2,0.6c-0.1,0.1-0.1,0.2-0.2,0.4C758.7,338.7,758.2,338.5,757.4,338z"/>
		<path d="M788.9,322.7c-0.4,0.3-0.7,0.7-0.8,0.6c-0.2,0-0.4-0.4-0.6-0.6c0-0.1,0.3-0.4,0.5-0.4
			C788.3,322.3,788.5,322.5,788.9,322.7z"/>
		<path d="M857.4,352.1c-0.4,0.2-0.7,0.4-0.9,0.4c-0.2,0-0.5-0.4-0.5-0.5c0.1-0.3,0.3-0.6,0.6-0.6
			C856.7,351.4,857,351.8,857.4,352.1z"/>
		<path d="M760,316.8c-0.4,0.3-0.8,0.6-1.2,1c-0.1-0.4-0.3-0.6-0.2-0.7c0.4-0.3,0.8-0.5,1.2-0.8C759.8,316.5,759.9,316.6,760,316.8z
			"/>
		<path d="M809.7,343.9c-0.5,0.2-0.7,0.4-1,0.3c-0.2,0-0.5-0.4-0.4-0.5c0.1-0.2,0.4-0.6,0.6-0.6
			C809.1,343.1,809.3,343.5,809.7,343.9z"/>
		<path d="M849.1,343.3c1.2-0.3,1.9-0.4,1.7,0.9C850.3,344,849.9,343.7,849.1,343.3z"/>
		<path d="M819.3,344.4c0.6,0.2,1.1,0.4,1.6,0.6c-0.1,0.2-0.2,0.4-0.3,0.4C820.1,345.4,819.4,345.7,819.3,344.4z"/>
		<path d="M813.7,335.7c0,0.4,0,0.8,0,1.2c-0.1,0-0.2,0-0.3,0c0-0.4-0.1-0.8-0.1-1.2C813.4,335.7,813.6,335.7,813.7,335.7z"/>
		<path d="M812.7,319.8c-0.3,0.3-0.5,0.5-0.8,0.8c-0.1-0.1-0.2-0.2-0.4-0.3c0.2-0.3,0.5-0.6,0.7-0.9
			C812.4,319.5,812.5,319.6,812.7,319.8z"/>
		<path d="M866.2,320.3c-0.3,0.2-0.5,0.5-0.9,0.8C864.9,320.1,865.2,319.8,866.2,320.3z"/>
		<path d="M779,321.7c0.4-0.5,0.7-0.8,1.1-1.2C780.3,321.5,780.1,321.8,779,321.7z"/>
		<path d="M808.7,224.9c0,0.5,0,0.9,0,1.4c-0.1,0-0.2,0-0.3,0c0-0.5,0-0.9,0-1.4C808.5,224.9,808.6,224.9,808.7,224.9z"/>
		<path d="M815.9,341.7c-0.2-0.3-0.5-0.5-0.8-0.9C816.1,340.5,816.4,340.8,815.9,341.7z"/>
		<path d="M795.6,301c1-0.4,1.3-0.1,1.1,1C796.3,301.6,796,301.4,795.6,301z"/>
		<path d="M790.8,246.9c0.3-0.2,0.5-0.5,1.1-1c-0.1,0.7-0.1,1.1-0.3,1.3c-0.1,0.1-0.5,0.1-0.7,0.1
			C790.9,247.1,790.8,247,790.8,246.9z"/>
		<path d="M792.9,323.4c-0.3-0.3-0.6-0.6-0.9-0.9C792.9,322.4,792.9,322.4,792.9,323.4z"/>
		<path d="M774,259.5c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1-0.2-0.3-0.4-0.3-0.4c0.2-0.2,0.5-0.3,0.7-0.5
			C773.8,259.3,773.9,259.4,774,259.5z"/>
		<path d="M864.9,316.9c-0.4-0.3-0.7-0.5-1-0.7C864.8,315.8,864.8,315.8,864.9,316.9z"/>
		<path d="M879,313.9c0.2,0.9,0.1,1.3-1.1,1.1C878.3,314.6,878.6,314.3,879,313.9z"/>
		<path d="M859.8,314.8c0.3-0.2,0.6-0.4,0.9-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.2-0.5,0.3-0.7,0.5C859.9,315,859.9,314.9,859.8,314.8
			z"/>
		<path d="M782.3,312.4c0.4,0.2,0.8,0.3,1.2,0.5c0,0.1,0,0.2-0.1,0.2c-0.4-0.1-0.9-0.1-1.3-0.2C782.1,312.8,782.2,312.6,782.3,312.4
			z"/>
		<path d="M852.1,348.4c0.2,0.2,0.5,0.3,0.7,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.2-0.4-0.4-0.7-0.6
			C851.9,348.6,852,348.5,852.1,348.4z"/>
		<path d="M859.5,311.5c0.5,0.1,1.3-0.2,1.2,1.2C860.2,312.2,859.9,311.8,859.5,311.5C859.5,311.5,859.5,311.5,859.5,311.5z"/>
		<path d="M819.3,346.8c0-0.7,0.2-1,1.3-0.5c-0.4,0.4-0.7,0.7-0.9,0.9C819.6,347.1,819.5,347,819.3,346.8z"/>
		<path d="M800.6,300c-0.4,0.2-0.8,0.4-1.3,0.7C799.5,299.9,799.5,299.9,800.6,300z"/>
		<path d="M841.6,344.6c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1-0.1-0.3-0.4-0.3-0.4c0.2-0.2,0.5-0.3,0.7-0.5
			C841.4,344.4,841.5,344.5,841.6,344.6z"/>
		<path d="M817.8,343.7c-0.3,0.9-0.7,1.2-1.8,0.4C816.9,343.9,817.3,343.8,817.8,343.7z"/>
		<path d="M827.4,343c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4C827.1,343.2,827.2,343.2,827.4,343z
			"/>
		<path d="M814.5,341.9c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
			C813.7,341.5,813.9,341.6,814.5,341.9z"/>
		<path d="M765.2,346.3c0.3-0.1,0.4-0.3,0.5-0.3c0.1,0,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
			C765.6,346.6,765.4,346.5,765.2,346.3z"/>
		<path d="M823.4,339.1c0.5,0.7,0.7,0.9,0.8,1.1c-0.2,0.1-0.4,0.4-0.6,0.4c-0.2,0-0.4-0.3-0.5-0.5
			C823.1,339.8,823.2,339.6,823.4,339.1z"/>
		<path d="M842.6,347.1c-0.6,0-1.2,0-1.8,0c0-0.1,0-0.2,0-0.3c0.6,0,1.3,0,1.9,0C842.7,346.9,842.7,347,842.6,347.1z"/>
		<path d="M765.1,339.4c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C764.7,339,764.8,339.2,765.1,339.4z"/>
		<path d="M795.6,220.9c0.7-0.4,1.4-0.7,2,0.7C796.7,221.3,796.1,221.1,795.6,220.9C795.5,220.9,795.6,220.9,795.6,220.9z"/>
		<path d="M822.9,221.5c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C822.5,221.2,822.7,221.3,822.9,221.5z"/>
		<path d="M816.5,349.6c-0.4,0-0.8-0.1-1.4-0.2C816,348.5,816.2,349.2,816.5,349.6C816.5,349.6,816.5,349.6,816.5,349.6z"/>
		<path d="M786,350.7c-0.1-0.3-0.2-0.5-0.2-0.6c0-0.1,0.2-0.2,0.4-0.3c0.1,0.2,0.2,0.3,0.2,0.5C786.4,350.4,786.2,350.5,786,350.7z"
			/>
		<path d="M769.8,350.4c-0.2,0.2-0.4,0.3-0.5,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
			C769.4,350.2,769.5,350.3,769.8,350.4z"/>
		<path d="M817.7,335.6c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
			C817.9,335.2,817.8,335.4,817.7,335.6z"/>
		<path d="M844.9,353c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3C844.5,352.7,844.6,352.8,844.9,353z
			"/>
		<path d="M796.5,353.7c-0.4,0.2-0.9,0.4-1.3,0.6c-0.1-0.1-0.1-0.3-0.2-0.4c0.4-0.2,0.9-0.4,1.3-0.6
			C796.4,353.4,796.5,353.6,796.5,353.7z"/>
		<path d="M784.5,354.2c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
			C784.7,354.6,784.6,354.4,784.5,354.2z"/>
		<path d="M834.2,333.1c0.6,0,1.3,0,1.9,0c0,0.1,0,0.2,0,0.2c-0.6,0-1.3,0-1.9,0C834.2,333.3,834.2,333.2,834.2,333.1z"/>
		<path d="M824.3,188.6c-0.2,0.1-0.3,0.2-0.5,0.2c0,0-0.2-0.3-0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.3C824.1,188.3,824.1,188.4,824.3,188.6
			z"/>
		<path d="M790.7,333.4c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
			C790.4,333.2,790.5,333.3,790.7,333.4z"/>
		<path d="M788.6,237.6c0.3,0.3,0.5,0.8,0.3,1.5C788.8,238.3,788.7,237.9,788.6,237.6C788.7,237.5,788.6,237.6,788.6,237.6z"/>
		<path d="M749.1,328.4c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.3,0.1-0.5,0.2-0.8
			C748.9,328.3,749,328.3,749.1,328.4z"/>
		<path d="M792.8,327.6c0.2,0.2,0.3,0.4,0.3,0.5c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4C792.5,328,792.6,327.9,792.8,327.6
			z"/>
		<path d="M798,327.4c-0.2,0.3-0.4,0.5-0.5,0.6c-0.2-0.1-0.5-0.3-0.5-0.4c0-0.2,0.2-0.4,0.3-0.6C797.5,327.1,797.7,327.2,798,327.4z
			"/>
		<path d="M790.3,328.1c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C790.6,327.7,790.4,327.9,790.3,328.1z"/>
		<path d="M820.2,326.5c0.1,0.3,0.3,0.5,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.1-0.3C819.9,326.9,820,326.8,820.2,326.5
			z"/>
		<path d="M810.2,323.4c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1-0.2-0.3-0.3-0.2-0.5c0-0.1,0.2-0.3,0.3-0.4
			C810,323.1,810.1,323.2,810.2,323.4z"/>
		<path d="M802.3,319.8c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.4-0.3C802,320,802.1,319.9,802.3,319.8z
			"/>
		<path d="M784.1,319.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C783.8,319,783.9,319.1,784.1,319.3z"/>
		<path d="M773.7,262.8c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C773.4,262.5,773.5,262.7,773.7,262.8z"/>
		<path d="M751.8,316.4c-0.4,0-0.8-0.1-1.3-0.1C751.2,315.8,751.2,315.8,751.8,316.4C751.8,316.5,751.8,316.4,751.8,316.4z"/>
		<path d="M870.1,313.9c0.2,0.6,0.4,0.7,0.4,0.9c0,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.4-0.2-0.4-0.3C869.6,314.6,869.8,314.5,870.1,313.9
			z"/>
		<path d="M778.3,314.9c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
			C778,314.5,778.1,314.7,778.3,314.9z"/>
		<path d="M769.8,314.2c-0.1,0.5,0,1.2-1.1,0.7C769.2,314.6,769.5,314.4,769.8,314.2L769.8,314.2z"/>
		<path d="M769.9,314.3c0.7-0.6,1.5-0.4,2.2,0.2C771.3,314.4,770.6,314.3,769.9,314.3C769.8,314.2,769.9,314.3,769.9,314.3z"/>
		<path d="M826.8,313.3c-0.2,0.2-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
			C826.4,313,826.6,313.1,826.8,313.3z"/>
		<path d="M788.4,273.4c-0.1-0.4-0.2-0.9-0.3-1.3c0.1,0,0.2-0.1,0.3-0.1c0.2,0.4,0.3,0.8,0.5,1.2
			C788.8,273.3,788.6,273.3,788.4,273.4z"/>
		<path d="M771.8,306.6c1,0.8,1,0.8,0.9,2.2C772.4,308,772.2,307.5,771.8,306.6z"/>
		<path d="M792.8,307.4c-0.1-0.3-0.3-0.5-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.5C793.1,307,792.9,307.2,792.8,307.4
			z"/>
		<path d="M783.3,306c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3C783.5,306.4,783.4,306.3,783.3,306z"
			/>
		<path d="M643.2,324.5c-1.9,0.9-1.5,2.6-1.4,4.1c2.3,1.4,2.4,2.1,0.5,3.6c-0.4-0.3-0.8-0.7-1.2-1c-0.4-0.3-0.8-0.7-1.4-1.2
			c-0.3,0.8-0.4,1.3-0.6,1.8c-1.1-0.2-2.3-0.2-3.3-0.6c-0.8-0.3-0.8-1.2-0.5-2c0.5-0.2,0.9-0.4,1.5-0.6c0.3,0.6,0.6,1.1,0.9,1.7
			c0.7-0.3,1.3-0.5,1.9-0.7c0.3-1.5,0.3-1.5-0.6-3.4c-1.8,0.2-3.7-0.6-5.5,0.5c-0.4,0.3-1.2,0.3-1.5-0.7c0.8-0.5,1.7-1,2.6-1.3
			c0.5-0.2,1.2-0.2,1.8-0.1c1.5,0.4,2.6,0,3.3-1.3c0.5-1,1.2-2,1-3.2c-0.1-0.3,0-0.7,0.2-1c0.9-1.1,0.8-2.5,1.1-3.8
			c0.6-1.9,0.2-3.9,0.6-5.8c0.2-1,0.4-2,0.5-3c0.1-1.1-0.1-2.3,0.5-3.4c0.2-0.3-0.1-0.8-0.2-1.2c0-0.2-0.3-0.4-0.3-0.5
			c1-2.8-0.5-5.6-0.2-8.5c0.2-1.6-0.3-3.2-0.4-4.8c-0.1-2.8-0.3-5.6-0.2-8.4c0-1,0.3-1.8,0.1-2.9c-0.2-1.2,0.7-2.6,1-3.9
			c0.2,0,0.3,0,0.5,0c0.1,0.4,0.3,1,0.1,1.2c-0.8,0.7-0.6,1.5-0.6,2.3c0,2.3-0.1,4.7,0,7c0,1.4-0.3,2.8,0.6,4.2
			c0.3,0.5,0.1,1.5,0.1,2.2c0,2.6,0,2.6,1.4,4.2c0,1.4,0,2.6,0,4.2c-0.8,0.7-1.2,1.6-0.6,3c0.3,0.6-0.2,1.6-0.3,2.5
			c-0.2,3.6-0.4,7.1-0.6,10.7c0,0.2,0,0.5-0.1,0.6c-0.9,1.2-0.8,2.7-1.3,4c-0.6,1.5-0.4,2.8-0.5,4.2
			C642.2,323.9,642.6,324.2,643.2,324.5L643.2,324.5z"/>
		<path d="M614.6,283c0,0.3,0,0.6,0.1,0.8c0.4,0.5,0.9,1,1.5,1.8c-0.7,0.1-1.2,0.1-1.7,0.2c-0.2,1.4-0.2,1.4,0.8,2.5
			c-0.5,0.6-1.1,1.2-1.6,1.8c0.3,0.4,0.7,0.8,1,1.2c-0.2,0.4-0.4,0.7-0.7,1.2c-0.4-0.1-0.9-0.3-1.3-0.4c-0.2,0.2-0.3,0.3-0.3,0.4
			c0,0.9-0.1,1.8-0.1,2.7c0.1,0.9-0.3,1.4-1.3,1.5c0.5,1.2,1.2,2.2,0.2,3.5c-0.2,0.3-0.2,0.9,0,1.3c0.5,1.3,1.1,2.6,1.7,3.8
			c0.1,0.2,0,0.5,0,0.7c-0.8,1.4-2.3-1-3,1c0.7,0.3,1.5,0.7,2.3,1.1c-0.6,1,0.4,2.5-1.1,3.3c0.9,1,0.7,1.9-0.2,2.8
			c0.7,0.9,1.4,1.7,2.3,2.8c-1.1-0.3-1.8-0.5-2.6-0.7c-0.4-2-0.8-4.1-1.2-6.4c-0.8-0.5-2.7,0.8-3.3-1.2c0.5-0.1,1-0.1,1.4-0.3
			c1-0.4-0.1-0.8-0.1-1.3c0-0.4,0-0.8,0.1-1.2c0.3-1.2,0.7-2.3,0.9-3.5c0.2-1.4,0.3-2.6,0-3.9c2.1-2.3,1.2-5.2,1.6-7.9
			c1.4,0.9,1.4,0.9,2.9,0.9c-0.2-0.3-0.3-0.6-0.6-0.8c-2-1.5-2.1-1.6-1.6-4.4c0.8,0.2,0.8,0.2,1.3,0.9c0.9-0.6,1.1-2.2,0.3-3.6
			C612.9,282.8,613.7,282.6,614.6,283L614.6,283z"/>
		<path d="M616.3,268.1c-1.2,0.7-1.8-0.4-2.3-1.1c-0.4-0.6-0.8-1.5,0.1-2.2c0.1-0.1,0.2-0.2,0.2-0.3c-0.3-1.1-0.5-2.4-1.1-3.3
			c-0.5-0.7-0.9-1.9-2.2-1.8c-0.1,0-0.2-0.6-0.4-1.2c0.9,0.3,1.4,0.4,2.1,0.7c0.3-0.4,0.5-0.8,0.8-1.3c0.3,0.1,0.5,0.1,0.6,0.2
			c0.7,0.7,1.3,1.4,2,2.1c0.7,0.7,1.3,1.6,2.6,1.1c0.4-0.2,1,0.2,1.5,0.4c0.6,1.8-1.7,2-1.6,3.4c0,0.8,1.6,1.3,0.5,2.4
			c-0.1,0.1,1.7,1.9,2.2,1.9c0.4,0.1,0.8,0.2,1.2,0.3l0-0.1c-0.2,0.2-0.4,0.6-0.7,0.7c-0.7,0.1-1.5,0.2-2.2,0
			c-0.4-0.1-0.8-0.6-1.1-1C617.9,268.1,617.4,267.4,616.3,268.1L616.3,268.1z M618.1,262.2c-1-0.4-1.7-0.7-2.8-1.1
			c0.4,1.2,0.7,1.9,1.1,3.1C617.1,263.4,617.5,262.9,618.1,262.2z M615.1,265c-0.1,0.1-0.1,0.3-0.2,0.4c0.7,0.4,0.4,2,1.8,1.6
			c0.3-0.1,0.5-0.9,0.7-1.5C616.5,265.3,615.8,265.2,615.1,265z"/>
		<path d="M612.3,330.6c-0.8-0.2-1.5-0.4-2.5-0.7c0.5-0.7,0.9-1.2,1.3-1.9c-0.4-0.7-2.1-1.1-0.5-2.3c-0.4-0.2-0.7-0.3-1-0.4
			c1-1.8-0.2-3.3-0.6-5c0.1-0.1,0.4-0.2,0.6-0.3c-0.1-0.8-0.2-1.7-0.3-2.7c0.7,0,1.2,0,1.9,0c-0.2,1.3-0.4,2.5-0.5,3.7
			c0,0.9,0.4,1.8,0.4,2.8c0.1,1.1,0.1,2.1,0.9,3C613,327.8,612.8,329.3,612.3,330.6z"/>
		<path d="M686.8,332.8c-0.9-0.5-1.1,0.4-1.4,1c-1.1,1.8-1.8,2-4,1.5c0.3-2.2,1.9-0.5,2.9-1.1c0.2-0.3,0.4-0.8,0.6-1.3
			c-0.5-0.3-1-0.6-1.9-1.1c0.8-0.4,1.2-0.7,1.8-0.8c1.4-0.4,1.5-0.6,1.1-2.1c-0.1-0.3-0.1-0.7,0-0.8c0.9-0.3,0.3-1.1,0.5-1.6
			c0.1-0.2,0.5-0.3,1.1-0.6C687.2,328.4,687,330.6,686.8,332.8z"/>
		<path d="M619.7,283.1c0.3-1.7,0.3-1.7,2-1.9c0.3,0,0.5-0.4,0.9-0.8c0.5,0.2,1.1,0.5,1.8,0.8c0.7-0.7,1.3-1.3,2.3-2.3
			c-0.3,2-0.6,3.6-0.9,5.3c0,0.1-0.5,0.1-0.9,0.2c0-0.5,0-0.8,0-1.1c0-1.2-0.3-1.4-1.4-0.9c-0.2,0.1-0.6,0.4-0.7,0.3
			C621.6,281.8,620.7,283.2,619.7,283.1z"/>
		<path d="M643.2,324.5c-0.3-0.7,0-1.2,0.6-1.2c1.6,0.2,2.5-0.7,3.5-1.8c0.4,0.8,0.7,1.4,1.8,0.6c0.6-0.5,1.7-0.2,2.6-0.2
			c0,0.1,0.1,0.3,0.1,0.4c-0.9,0.3-1.8,0.8-2.6,0.8c-1.3,0-1.8,0.6-2.2,1.7c-0.2,0.5-0.1,1.6-1.4,1.1c0.1-0.5,0.1-1,0.2-1.7
			C644.9,324.3,644,324.4,643.2,324.5C643.2,324.5,643.2,324.5,643.2,324.5z"/>
		<path d="M627.7,278.4c-0.8-0.2-1.4-0.4-2.1-0.6c0.5-2.5-2.3-0.7-2.7-2.1c-0.1-1,0.9-1.2,1.3-2c0.4,0.5,0.6,0.8,1.1,1.4
			c0-1.2,0-2,0-2.9c1.2,0.2,1.4,1,1.2,1.7c-0.5,1.4,0.3,2.4,0.8,3.5C627.4,277.7,627.5,278,627.7,278.4z"/>
		<path d="M621.3,310.9c-0.4,0.1-0.9,0.1-1.5,0.2c0.1-0.8,0.2-1.4,0.3-2c-1.5-0.6-2.9-1.4-3.4-3.3c1.6-1.2,2.4,0.7,3.7,1
			c-0.1,0.4-0.2,0.8-0.4,1.3c0.4,0.1,0.8,0.2,1.3,0.3C621.3,309.2,621.3,310,621.3,310.9L621.3,310.9z"/>
		<path d="M613.8,311.4c1.5-1.1,1.6-1.1,2.3,0.1c0.5-0.1,0.9-0.2,1.4-0.4c0.1,1,0.2,1.9,0.2,2.8c0,0.8-0.6,1.1-1.1,1.3
			C615.7,313.9,614.8,312.7,613.8,311.4z"/>
		<path d="M606,276.1c1.4,0.9,2.6,0.4,3.7-0.4c0.4,0.4,0.5,0.6,0.8,2.2c-1.1,0.2-2.2,0.8-3.2,0.6c-0.6-0.1-1-1.3-1.5-2
			C605.8,276.3,605.9,276.2,606,276.1z"/>
		<path d="M615.7,306.7c-0.2,0.5-0.4,0.9-0.5,1.2c0.5,0.3,1,0.5,1.7,0.9c-1.2,0.8-2,1.4-3,0.5c-0.7-0.5-1-1.2-0.7-2
			C613.6,306.4,614.5,306.3,615.7,306.7z"/>
		<path d="M608.7,282.9c0.5-0.3,0.8-0.5,1.4-0.9c0.4,0.4,0.9,0.8,1,1.2c0.1,0.4-0.1,1.1-0.4,1.4c-0.2,0.2-0.9,0.3-1.2,0.1
			c-0.7-0.3-1.3-0.7-1.8-1.2c-0.4-0.4-0.5-1.1-0.5-1.8C607.6,282,608.1,282.4,608.7,282.9z"/>
		<path d="M620.1,295.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-0.4-1-0.8-0.3-1.3c0.6-0.5,0.9,0.2,1.3,0.5c0.8,0.5,1,1.2,0.8,1.9
			c-0.8,0.7-1.5,1.2-2.4,2c-0.1-0.2-0.4-0.5-0.8-1C618.9,296.4,619.4,296,620.1,295.4z"/>
		<path d="M616.3,301.3c-2.1-0.7-3.1-1.9-2.9-3.8C615.2,298.1,616.5,299.8,616.3,301.3L616.3,301.3z"/>
		<path d="M614.6,283c-0.3-0.5-0.7-1-1.1-1.6c0.9-0.5,1.6-1.1,1.4-2.3c0.8,0.1,1.5,0.2,2.3,0.3C616.4,280.7,615.5,281.9,614.6,283
			C614.6,283,614.6,283,614.6,283z"/>
		<path d="M616.3,268.2c1.2,0.5,1.8,1.5,2.4,2.9c-0.6,0.1-1.4,0.3-1.6,0.2c-0.5-0.8-1.3-0.7-2.2-0.6
			C615.4,269.7,615.9,268.9,616.3,268.2C616.3,268.1,616.3,268.2,616.3,268.2z"/>
		<path d="M621.3,310.9c0.3,0.5,0.7,1,1,1.5c0.9,1.8,0.6,2.3-1.6,2.2C619.7,313.2,621,312.1,621.3,310.9L621.3,310.9z"/>
		<path d="M621.6,271.5c-0.6,0.4-1,0.6-1.4,0.9c0.3,0.5,0.5,1,0.8,1.5c-1.4,0.8-1.9-0.9-3.1-0.7
			C618.7,271.7,619.7,270.9,621.6,271.5z"/>
		<path d="M626.3,325.6c0.8,1.3,0.8,1.3,2.6,0.7c-0.2,0.4-0.2,0.8-0.4,0.9c-1.3,0.4-2.5,0.8-3.8,1.2c-0.1,0-0.4-0.1-0.5-0.2
			C624.9,326.3,624.9,326.3,626.3,325.6z"/>
		<path d="M639.5,338.3c0.1,1,0.3,2,0.2,3c-0.1,1.3-1.3,1.7-2.2,2.4c0.3-1.1,0.3-2.3,1.2-3.2c0.7-0.6,0.5-1.1-0.2-1.7
			C639,338.6,639.3,338.5,639.5,338.3C639.6,338.3,639.5,338.3,639.5,338.3z"/>
		<path d="M664.1,332.5c1.7-1,2.6,0.4,4,1c-0.3,0.5-0.5,0.9-0.9,1.6C666.3,333.9,665.5,332.9,664.1,332.5z"/>
		<path d="M690,332.5c1-0.3,2-0.4,3.1-0.1c1,0.3,2.1,0.2,3.3,0.3c-1.5,0.7-2.4,0.9-3.5,0.8c-0.2,0-0.5-0.1-0.7-0.2
			C691.2,332.7,690.9,332.6,690,332.5z"/>
		<path d="M615.9,294.3c-0.6-0.3-1.3-0.6-0.8-1.4c0.3-0.6,0.8-1.1,1.2-1.6C617.7,292.5,617.7,292.5,615.9,294.3z"/>
		<path d="M617.4,298.3c1-0.3,1.4,0.3,1.7,1.2c0.2,0.6,0.5,1.1,0.7,1.6C618.6,301.5,617.8,300.7,617.4,298.3z"/>
		<path d="M607.2,340.3c0.4-1,0.6-1.6,0.9-2.4c0.6,0.6,0.9,0.9,1.2,1.2c0.2,0.2,0.3,0.5,0.5,0.7c-0.3,0.2-0.5,0.4-0.8,0.5
			C608.7,340.5,608.2,340.4,607.2,340.3z"/>
		<path d="M630.9,354.2c-1.2-1.2-0.9-2.9,1.1-4.3C631.6,351.5,631.2,352.7,630.9,354.2z"/>
		<path d="M627.9,332.7c0,1.2,0,2.2,0,3.5c-0.4-0.6-0.7-1-0.9-1.5c-0.3-0.6-0.4-1.2-0.7-2C626.8,332.7,627.4,332.7,627.9,332.7z"/>
		<path d="M623.6,311.1c-1.4-0.2-0.6-1.2-0.9-1.9c0.8-0.4,1.5-0.7,2.5-1.1C624.6,309.3,624.2,310.1,623.6,311.1z"/>
		<path d="M622.5,269.4c-0.3-1.3,0.6-1,1.2-0.8c0.7,0.2,1.3,0.6,2.1,0.9c0,0.4,0,1,0,1.6c-0.1,0-0.2,0.1-0.2,0.1
			c-0.2-0.4-0.4-0.9-0.6-1.4C624.2,269.6,623.3,269.4,622.5,269.4C622.4,269.3,622.5,269.4,622.5,269.4z"/>
		<path d="M616.3,301.3c0.5,0.8,1,1.6,1.4,2.4c0.1,0.2-0.1,0.6-0.1,0.9c-0.3-0.2-0.7-0.2-0.9-0.5c-0.4-0.5-0.6-1-0.9-1.6
			C615.9,302.2,616.1,301.8,616.3,301.3C616.3,301.3,616.3,301.3,616.3,301.3z"/>
		<path d="M609.1,288.4c-0.9,0.2-1.8,0.3-2.7,0.5c-0.1-0.4-0.2-0.8-0.4-1.3c1.1,0.2,2.1,0.3,3.1,0.5
			C609.1,288.2,609.1,288.3,609.1,288.4z"/>
		<path d="M641.9,335.6c-0.3,0.6-0.6,1.1-0.9,1.7c-0.3,0.7-0.6,1.2-1.5,0.9c0,0,0,0,0,0c0.1-0.2,0.2-0.5,0.2-0.7
			C640,336.1,640.3,335.8,641.9,335.6L641.9,335.6z"/>
		<path d="M617.7,295.4c-0.3,1.2-0.3,1.2-1.7,2c-0.1-0.7-0.3-1.3-0.4-2C616.4,295.4,617,295.4,617.7,295.4z"/>
		<path d="M609.8,348.6c-0.8,0.5-1.7,0.9-2.9,1.6C608,348.2,608.2,348.1,609.8,348.6z"/>
		<path d="M616.4,275c-0.6-0.4-1.2-0.8-1.8-1.3c-0.2-0.2-0.1-0.7-0.1-1c0.3,0.1,0.8,0.1,1,0.3C616.1,273.6,616.9,274.1,616.4,275
			L616.4,275z"/>
		<path d="M649.6,329.1c0,1.2-0.9,1.6-1.6,2.4c-0.2-0.7-0.4-1.1-0.6-1.6C648,329.7,648.7,329.5,649.6,329.1z"/>
		<path d="M631.9,276.9c-0.1,1-0.2,1.8-0.3,2.7c-0.4,0-0.8,0-1.2,0c0-0.6,0-1.2,0-2C630.7,277.4,631.2,277.2,631.9,276.9z"/>
		<path d="M608.1,270c1.2,0.2,2.1-0.4,2.6,0.9C609.6,271.5,608.9,271.5,608.1,270z"/>
		<path d="M620,290.2c0.1,0.4,0.2,0.8,0.3,1.2c-1,0.4-1.7,0.3-2.4-0.7C618.7,290.5,619.4,290.3,620,290.2L620,290.2z"/>
		<path d="M605.5,307.9c-0.2,0.5-0.3,1-0.5,1.5c-0.5-0.2-1-0.4-1.5-0.7C603.8,307.7,604.5,307.5,605.5,307.9z"/>
		<path d="M630.3,273.4c1.1,0.4,1.1,0.4,0.8,2.5C629.6,275.4,631.2,274.1,630.3,273.4z"/>
		<path d="M636.1,345.3c-0.8-0.3-1.5-0.5-2.2-0.7c0.6-0.7,1.1-1.1,1.6-1.7C635.6,343.6,635.8,344.2,636.1,345.3z"/>
		<path d="M633.5,279c-0.5-0.9-1.1-1.5,0.2-2.3C633.5,277.5,634.9,278.1,633.5,279z"/>
		<path d="M631.2,296c1.4,0.1,1.4,0.1,1.2,2.2C631.9,297.3,631.6,296.8,631.2,296z"/>
		<path d="M616,316.9c1.1,0.6,1.8,0.9,2.5,1.3c-0.1,0.1-0.1,0.3-0.2,0.4C617.4,318.6,616.6,318.5,616,316.9z"/>
		<path d="M687.2,337.4c0.3,0.6,0.5,1.1,0.8,1.6c-0.3,0.2-0.5,0.5-0.8,0.7c-0.3-0.3-0.5-0.5-0.8-0.7
			C686.7,338.4,686.9,337.9,687.2,337.4z"/>
		<path d="M619.8,276.3c0.7,0.1,1.2,0.5,1.2,1.5c-0.7-0.2-1.2-0.4-1.8-0.6C619.4,276.8,619.6,276.5,619.8,276.3L619.8,276.3z"/>
		<path d="M687.5,345.6c-0.3,0.7-0.5,1.2-0.8,1.8c-0.2-0.2-0.5-0.4-0.6-0.6C685.8,345.9,686.4,345.6,687.5,345.6z"/>
		<path d="M628.6,320.8c0.4,0.5,0.9,1,1.3,1.4C628.8,322.5,628.7,322.4,628.6,320.8z"/>
		<path d="M618.9,220.7c1.7-0.9,1.4,0.7,2,1.1C619.8,222.2,619.5,221.4,618.9,220.7z"/>
		<path d="M621.5,304.2c-0.1,0.5-0.3,1-0.4,1.5c-0.3-0.1-0.7-0.2-0.8-0.4C620.2,304.6,620.3,304,621.5,304.2z"/>
		<path d="M635.1,352.1c-1.8-0.6-0.7-1.3-0.2-2C635,350.7,635.1,351.3,635.1,352.1z"/>
		<path d="M596.5,335.4c-0.5,0-0.9,0.1-1.6,0.1c0.1-0.5,0.2-1.1,0.3-1.1C595.8,334.3,596.4,334.2,596.5,335.4z"/>
		<path d="M631.5,326.4c-0.9,0.4-1.3,0.6-1.9,0.8C629.7,326.1,629.7,326.1,631.5,326.4z"/>
		<path d="M672.1,326.1c0,0.8,0,1.4,0,2C670.6,326.7,670.6,326.7,672.1,326.1z"/>
		<path d="M617.7,327c-0.6,0.5-1.1,0.9-1.7,1.5c-0.1-0.5-0.4-0.9-0.2-1.1C616.1,326.7,616.8,326.6,617.7,327z"/>
		<path d="M616.4,275.1c0.4,0.1,0.9,0.3,1.3,0.4c0,0.1,0,0.3,0,0.4c-0.5,0.2-1,0.4-1.7,0.8C616.2,276,616.3,275.5,616.4,275.1
			C616.4,275,616.4,275.1,616.4,275.1z"/>
		<path d="M631.3,310.4c-0.6,0.9-0.8,1.6-1.7,1.3c-0.1-0.1-0.2-0.7-0.2-0.7C630,310.8,630.5,310.7,631.3,310.4z"/>
		<path d="M649.7,317.6c-0.2,0.4-0.4,0.8-0.6,1.2c0,0-0.4-0.2-0.6-0.3c0.2-0.4,0.4-0.8,0.6-1.2C649.3,317.4,649.5,317.5,649.7,317.6
			z"/>
		<path d="M684.4,354c1.2-1.1,1.2-1.1,1.8,0.4C685.6,354.2,685.1,354.1,684.4,354z"/>
		<path d="M613.9,337.1c-0.2-0.4-0.4-0.8-0.7-1.3c1.5-0.3,1,0.7,1.1,1.2C614.2,337,614,337.1,613.9,337.1z"/>
		<path d="M710,316.6c0.6-0.3,1.3-0.5,1.9-0.8c0.1,0.1,0.1,0.3,0.2,0.4C711.5,316.5,711.1,317.4,710,316.6z"/>
		<path d="M606.4,317c0.2-1.4,1.2-0.8,2-0.7c-0.3,0.1-0.6,0.2-0.9,0.3C607.1,316.7,606.8,316.8,606.4,317z"/>
		<path d="M608.4,312c-0.5,0.4,0,1.7-1.4,1.5C607.5,312.9,607.1,311.9,608.4,312z"/>
		<path d="M601.8,341.1c0.3,0.3,0.7,0.7,1.1,1.1c-0.4,0.2-0.7,0.3-0.7,0.3c-0.3-0.3-0.5-0.7-0.8-1
			C601.6,341.3,601.7,341.2,601.8,341.1z"/>
		<path d="M612,272.1c0.5,0.2,1,0.5,1.6,0.7c-0.3,0.2-0.4,0.3-0.6,0.3C612.3,273.3,611.7,273.2,612,272.1z"/>
		<path d="M675,324.7c-1,0-2,0-3.1,0c0-0.1,0-0.2,0-0.3c1,0,2,0,3.1,0C675,324.5,675,324.6,675,324.7z"/>
		<path d="M623.2,284.4c0.5,0.3,0.9,0.6,1.6,1c-1.3,0.7-1.4-0.2-1.8-0.6C623.1,284.6,623.1,284.5,623.2,284.4z"/>
		<path d="M641,285.7c-0.3,0.7-0.6,1.2-1,2C639.8,286.5,639.6,285.8,641,285.7z"/>
		<path d="M651.9,265.7c-0.4,0.3-0.6,1.3-1.7,0.5c0.6-0.4,1-0.7,1.5-1C651.7,265.4,651.8,265.6,651.9,265.7z"/>
		<path d="M622.1,317.6c0.6-0.5,1-0.8,1.8-1.4C623.4,317.5,623.4,317.5,622.1,317.6z"/>
		<path d="M606.7,319.9c-0.4-0.6-0.8-1.3,0.5-1.4C607,319.1,606.8,319.5,606.7,319.9z"/>
		<path d="M599,332.7c1.2-0.8,1.6-0.3,1.9,1C600.1,333.3,599.5,333,599,332.7z"/>
		<path d="M614.9,316.4c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.6-0.5-0.9-0.8
			C614.6,316.7,614.8,316.6,614.9,316.4z"/>
		<path d="M672.9,351.4c0.9-0.5,1.2-0.2,0.9,0.8C673.5,351.9,673.2,351.6,672.9,351.4z"/>
		<path d="M624.7,315.1c-0.5,0.1-1.1,0.1-1.9,0.2C623.5,314.4,624,314.1,624.7,315.1z"/>
		<path d="M687.5,349.8c-1,0-1,0-0.9-1.1C686.9,349.1,687.1,349.4,687.5,349.8z"/>
		<path d="M612.9,311.9c0.2,0.3,0.5,0.6,0.8,0.9C612.7,313.2,612.4,312.9,612.9,311.9z"/>
		<path d="M610.1,279.2c0.4-0.2,0.7-0.4,1.4-0.7c-0.2,0.7-0.3,1.2-0.5,1.6C610.7,279.8,610.4,279.5,610.1,279.2z"/>
		<path d="M631.1,286.3c-0.4-0.3-0.6-0.6-1-0.9C631.1,285.1,631.1,285.1,631.1,286.3z"/>
		<path d="M593,334.6c1.1-0.4,1.2,0,1.1,1C593.6,335.2,593.4,335,593,334.6z"/>
		<path d="M625.6,263.6c-0.3-0.3-0.6-0.6-0.9-0.9C625.8,262.6,625.8,262.6,625.6,263.6z"/>
		<path d="M619.4,224.9c0.3,0.4,0.6,0.6,0.9,1C619.2,225.9,619.2,225.9,619.4,224.9z"/>
		<path d="M652,272.8c-0.3,0.3-0.6,0.6-0.9,0.9C651,272.7,651,272.7,652,272.8z"/>
		<path d="M629.1,282.3c-0.6,0.4-0.9,0.6-1.2,0.8C627.8,282.3,627.8,282.3,629.1,282.3z"/>
		<path d="M626.5,284.5c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0.3,0.2,0.4,0.3c-0.2,0.2-0.4,0.5-0.6,0.7C626.7,284.7,626.6,284.6,626.5,284.5
			z"/>
		<path d="M679.8,346.1c-0.3-0.3-0.7-0.7-1.2-1.2C679.7,344.7,679.8,345.2,679.8,346.1z"/>
		<path d="M627.3,343.9c0.2,0.3,0.3,0.4,0.3,0.6c0,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.3-0.3-0.4C627,344.4,627.1,344.2,627.3,343.9
			z"/>
		<path d="M631.4,343.6c-0.2,0-0.5,0.1-0.5,0.1c-0.1-0.1-0.1-0.4-0.1-0.5c0-0.1,0.3-0.1,0.4-0.2
			C631.2,343.1,631.3,343.3,631.4,343.6z"/>
		<path d="M669.8,341.9c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.3,0.1,0.5C670.2,341.8,670,341.8,669.8,341.9z"
			/>
		<path d="M676.2,348.6c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.2,0.3,0.3,0.4
			C676.5,348.2,676.4,348.4,676.2,348.6z"/>
		<path d="M630.6,199.9c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
			C630.8,200.3,630.7,200.1,630.6,199.9z"/>
		<path d="M654.4,352.7c-0.2,0-0.5,0.1-0.7,0c-0.1,0-0.1-0.2-0.1-0.3c0.3-0.1,0.6-0.1,0.8-0.2C654.4,352.4,654.4,352.6,654.4,352.7z
			"/>
		<path d="M641.9,335.7c-0.2-1.4-0.2-1.4,0.9-1.9C642.4,334.5,642.2,335.1,641.9,335.7C641.9,335.6,641.9,335.7,641.9,335.7z"/>
		<path d="M644.6,328c0,0.6,0,1.3,0,1.9c-0.1,0-0.2,0-0.2,0c0-0.6,0-1.3,0-1.9C644.5,328,644.6,328,644.6,328z"/>
		<path d="M624.7,325.6c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3C625,325.3,624.9,325.4,624.7,325.6
			z"/>
		<path d="M604.8,324.2c-0.1-0.3-0.2-0.5-0.2-0.6c0-0.1,0.3-0.2,0.4-0.2c0,0,0.2,0.2,0.2,0.3C605.1,323.9,605,324,604.8,324.2z"/>
		<path d="M656.5,321.9c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
			C656.2,322.1,656.3,322,656.5,321.9z"/>
		<path d="M606.4,322c0.2,0.2,0.4,0.4,0.4,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3C606.2,322.5,606.3,322.3,606.4,322z"
			/>
		<path d="M634.7,321.4c0.2,0.3,0.3,0.4,0.3,0.5c0,0.2-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
			C634.4,321.8,634.6,321.7,634.7,321.4z"/>
		<path d="M624.4,257.6c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
			C624.7,257.2,624.5,257.4,624.4,257.6z"/>
		<path d="M623.1,259.5c-0.1-0.3-0.3-0.5-0.2-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.3,0.2,0.4
			C623.4,259.1,623.2,259.2,623.1,259.5z"/>
		<path d="M614.9,320.1c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
			C614.2,319.7,614.4,319.8,614.9,320.1z"/>
		<path d="M646.8,260.5c-0.2,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
			C646.4,260.3,646.5,260.4,646.8,260.5z"/>
		<path d="M605.1,318.1c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
			C604.7,317.8,604.9,318,605.1,318.1z"/>
		<path d="M627.6,315.6c0.3-0.2,0.4-0.3,0.6-0.3c0.2,0,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
			C628.1,315.9,627.9,315.8,627.6,315.6z"/>
		<path d="M609.4,268.7c0.3-0.1,0.4-0.3,0.5-0.3c0.1,0,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
			C609.8,269.1,609.7,268.9,609.4,268.7z"/>
		<path d="M652,268.8c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2C651.6,268.5,651.8,268.7,652,268.8z
			"/>
		<path d="M619.8,276.3c-0.5-0.2-1-0.5-0.3-1.5C619.6,275.5,619.7,275.9,619.8,276.3C619.8,276.3,619.8,276.3,619.8,276.3z"/>
		<path d="M632,280.9c-0.6,0.3-0.8,0.4-1,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4C631.2,280.5,631.3,280.6,632,280.9z"/>
		<path d="M629,286.7c-0.5,0.1-0.7,0.2-0.9,0.2c-0.1,0-0.2-0.3-0.3-0.4c0.2-0.1,0.4-0.2,0.5-0.2C628.5,286.3,628.6,286.5,629,286.7z
			"/>
		<path d="M640.5,288.6c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
			C640.3,288.3,640.4,288.4,640.5,288.6z"/>
		<path d="M620.1,290.2c0.2-0.3,0.3-0.5,0.5-0.8C620.9,290.1,620.9,290.1,620.1,290.2C620,290.2,620.1,290.2,620.1,290.2z"/>
		<path d="M630.2,292c-0.2,0.2-0.3,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3C629.8,291.9,629.9,292,630.2,292z"
			/>
		<path d="M605.3,294.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
			C604.9,294.3,605.1,294.3,605.3,294.5z"/>
		<path d="M824.9,382.3c0.6-2.1,1.5-2.7,2.9-2.2c0.1-0.3,0.2-0.5,0.3-0.9c0.7,0.3,1.3,0.5,2,0.8c0.3-0.3,0.7-0.6,1-1
			c0.3-0.3,0.6-0.7,1.1-1.2c-0.1,1.2-0.1,2.2-0.4,3c-0.4,0.8,0.5,2.2-0.9,2.6c0-0.1-1-2.6-1-2.7c-0.7,0.2-1.3,1.6-2.1,0.3
			c-0.2,0.1-0.4,0.3-0.6,0.4C826.5,381.7,825.8,382,824.9,382.3z"/>
		<path d="M819,412c1.4-1.3,1.7-1.3,2.6,0.2c0.5-0.1,1.1-0.2,1.9-0.4c-0.3,1-0.8,1.7-0.2,2.6c0.6,0.9-0.5,1.5-1.6,2
			c0.2-0.6,0.2-0.8,0.4-1.3C820.6,414.7,820,413.3,819,412z"/>
		<path d="M826.3,411.9c-1.4-0.6-1.4-0.6-0.5-2.3c-0.8-0.4-1.7-0.7-2.4-1.3c-0.6-0.6-0.9-1.5-1.3-2.2c1.9-1.1,2.4,1.1,3.8,1.2
			c-0.2,0.5-0.4,0.8-0.6,1.3c0.6,0.1,1.1,0.2,1.7,0.3c-0.7,1.1-0.7,1.1,0,2C826.8,411.2,826.5,411.5,826.3,411.9L826.3,411.9z"/>
		<path d="M820.6,407.1c-0.1,0.5-0.2,0.9-0.3,1.3c0.5,0.6,1.6-0.2,1.9,1c-0.7,0.3-1.4,0.7-2.2,1.1c-0.8-0.8-2.2-1.3-1.6-2.7
			C818.7,406.8,819.5,406.6,820.6,407.1z"/>
		<path d="M826.9,395.6c-0.7,0.6-1.4,1.3-2.2,2c-0.4-0.2-1.2-0.6-0.5-1.5c0.4-0.5,1-0.8,1.5-1.2c-0.4-0.6-0.8-1.1-1.2-1.8
			c1.3-0.7,1.5,0.4,2,0.7C827.4,394.3,827.4,394.9,826.9,395.6z"/>
		<path d="M818.3,397.7c1.9,0,2.4,1.9,4.1,2.7c-0.5,0.5-0.9,0.8-1.3,1.2C819.6,400.7,818.8,399.4,818.3,397.7z"/>
		<path d="M826.3,411.9c0.9-0.1,1.4,0.3,1.7,1.1c0.2,0.5,0.4,1,0.5,1.5c-0.4,1.1-1.3,1.3-2.4,1c-0.2-0.9-0.3-1.8,0.2-2.7
			C826.5,412.6,826.3,412.2,826.3,411.9C826.3,411.9,826.3,411.9,826.3,411.9z"/>
		<path d="M819.3,381.6c-0.1-0.5-0.2-1-0.3-1.3c0.6-0.8,1.1-1.5,1.6-2.3c0.5,0.1,1.3,0.2,2,0.2c0,0,0.1,0.1,0,0.2
			c-0.1,0.2-0.1,0.5-0.2,0.5C820.8,379.1,820.8,381.2,819.3,381.6C819.2,381.5,819.3,381.6,819.3,381.6z"/>
		<path d="M821,394c-2.1-1.7,0.9-2.2,0.3-3.6C823.3,391.8,823.2,392.3,821,394z"/>
		<path d="M821.8,397.5c-0.7-0.9-1.5-1.4-0.9-2.6c0.7,0,1.5,0,2.7,0C822.9,395.9,822.4,396.6,821.8,397.5z"/>
		<path d="M824.8,401.8c-0.8-1.2-1.5-2.2-2.2-3.2c0.9-0.9,1.5-0.2,1.8,0.3c0.4,0.7,0.2,1.7,1.1,2.1
			C825.4,401.2,825.3,401.3,824.8,401.8z"/>
		<path d="M829.4,411.7c-1.3-0.2-0.6-1.2-0.9-2c0.8-0.4,1.5-0.7,2.4-1.2C830.3,409.8,829.8,410.7,829.4,411.7z"/>
		<path d="M836.7,378.2c0-0.8,0-1.5,0-2.2c0.4-0.2,0.9-0.4,1.7-0.7c-0.1,0.7-0.2,1.2-0.3,1.7c-0.1,0.4-0.2,0.7-0.4,1.3
			C837.5,378.2,837,378.2,836.7,378.2z"/>
		<path d="M823.1,404.8c-1.3,0-1.2-1.7-2.6-2c0.5-0.4,0.8-0.6,1.3-1c0.6,0.9,1.1,1.8,1.7,2.6C823.4,404.5,823.2,404.6,823.1,404.8z"
			/>
		<path d="M823.5,389.8c1,0.1,1.6,0.1,2.4,0.2C825.1,391.3,824.8,391.3,823.5,389.8z"/>
		<path d="M838.8,397.8c-0.5-0.6-0.9-1-1.1-1.5c-0.1-0.2,0.2-0.6,0.4-0.9c0.2,0.2,0.7,0.3,0.7,0.5
			C838.9,396.5,838.8,397.1,838.8,397.8z"/>
		<path d="M827.6,405.3c-0.5,0.4-0.9,0.8-1.1,0.7c-0.3-0.1-0.6-0.5-0.6-0.8c0-0.3,0.4-0.6,0.6-0.8
			C826.8,404.7,827.1,404.9,827.6,405.3z"/>
		<path d="M823.7,419.8c-0.7-0.4-1.5-0.8-2.2-1.2c0.1-0.2,0.2-0.4,0.3-0.5c0.8,0.4,1.5,0.8,2.3,1.2
			C823.9,419.5,823.8,419.6,823.7,419.8z"/>
		<path d="M840.1,375.4c-0.2,0.8,1.1,1.4-0.3,2.2C839.9,376.8,838.4,376.3,840.1,375.4z"/>
		<path d="M816.7,377.3c-0.3,0.5-0.6,1-0.9,1.5c-0.2-0.2-0.4-0.4-0.7-0.6C815.5,377.6,815.7,376.9,816.7,377.3z"/>
		<path d="M828.8,415.7c0.4-0.1,0.9-0.3,1.3-0.4c0,0,0.3,0.4,0.2,0.5C830,416.5,829.5,416.4,828.8,415.7z"/>
		<path d="M822.4,373.1c0.2,0.4,0.4,0.8,0.6,1.3c-0.4,0.2-0.9,0.5-1.4,0.7c-0.1-0.1-0.2-0.1-0.3-0.2
			C821.7,374.3,822.1,373.7,822.4,373.1L822.4,373.1z"/>
		<path d="M825.5,374.6c0.4,0.6,0.7,1,1.3,1.8c-0.9-0.3-1.4-0.5-2-0.7C825,375.4,825.2,375.1,825.5,374.6z"/>
		<path d="M839.5,393.2c0,0.4,0,0.7,0,1.1c-0.1,0-0.2,0-0.3,0c0-0.4-0.1-0.8-0.1-1.1C839.1,393.2,839.3,393.2,839.5,393.2z"/>
		<path d="M829.1,383.5c0.4,0.3,0.8,0.6,1.2,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4-0.3-0.7-0.6-1.1-0.9
			C828.9,383.8,829,383.7,829.1,383.5z"/>
		<path d="M829.7,417.9c-0.4,0.3-0.8,0.6-1.2,1c-0.1-0.3-0.2-0.4-0.2-0.5C828.5,417.8,828.9,417.5,829.7,417.9z"/>
		<path d="M835,381.3c-0.3,0.3-0.6,0.5-1,0.8C834,380.9,834,380.9,835,381.3z"/>
		<path d="M817.8,412.5c0.3,0.3,0.6,0.6,0.9,0.9C817.8,413.8,817.8,413.8,817.8,412.5z"/>
		<path d="M817.7,396.4c0.3-0.3,0.6-0.6,0.9-0.9C818.6,396.4,818.6,396.4,817.7,396.4z"/>
		<path d="M831,412.2c-0.2,0.2-0.3,0.5-0.5,0.7c0,0-0.3-0.2-0.4-0.3c0.2-0.2,0.4-0.5,0.6-0.7C830.8,412,830.9,412.1,831,412.2z"/>
		<path d="M550.3,413.3c0.8-0.1,1.4-0.5,2.3-0.7c1-0.1,1.9-1.2,2.9-1.8c0.4,0.8,0.9,1.5,2,0.5c0.4-0.4,1.5-0.2,2.2-0.2
			c0,0.1,0,0.3,0.1,0.4c-0.9,0.3-1.9,0.6-2.8,0.7c-1,0.1-1.6,0.4-2,1.4c-0.2,0.6-0.7,1.2-1,1.8c-0.2-0.1-0.4-0.1-0.5-0.2
			c0-0.5,0.1-1,0.1-1.6C552.6,413.6,551.5,413.5,550.3,413.3C550.3,413.3,550.3,413.3,550.3,413.3z"/>
		<path d="M552.5,417.6c0,0.6,0,1.3,0,1.9c-0.1,0-0.2,0-0.2,0c0-0.6,0-1.3,0-1.9C552.4,417.6,552.4,417.6,552.5,417.6z"/>
		<path d="M535.9,416.4c-1.6,0.5-3.3,1-5.2,1.6c0.3-1.6,1-2.5,2.2-2.9c0.8,1.1,0.9,1.1,2.8,0.8C535.8,416.1,535.8,416.2,535.9,416.4
			z"/>
		<path d="M527.2,399.9c0.4,0.2,1,0.4,1.3,0.7c0.4,0.4,0.6,1,0.9,1.5c-0.9,1.1-0.9,1.1-2.3,1.1c-0.4-0.8-0.4-1.5,0-2.3
			C527.3,400.7,527.2,400.2,527.2,399.9C527.2,399.9,527.2,399.9,527.2,399.9z"/>
		<path d="M530.3,399.7c-1.2-0.4-0.7-1.3-0.8-2.1c0.7-0.4,1.4-0.7,2.4-1.3C531.2,397.7,530.8,398.7,530.3,399.7z"/>
		<path d="M539.2,385.6c-0.2-0.5-0.5-1-0.7-1.6c0-0.1,0.2-0.5,0.4-0.6c0.2,0,0.6,0.2,0.6,0.3c0.1,0.6,0.1,1.2,0.1,1.8
			C539.5,385.5,539.4,385.5,539.2,385.6z"/>
		<path d="M530.7,405.9c-0.5,0.3-1,0.7-1.5,1c-0.1-0.1-0.2-0.2-0.3-0.4c0.4-0.5,0.5-1.4,1.4-1.2
			C530.5,405.5,530.6,405.7,530.7,405.9z"/>
		<path d="M538.1,399.7c-0.4,0.2-0.8,0.4-1.2,0.5c0,0-0.2-0.4-0.3-0.6c0.4-0.2,0.8-0.4,1.2-0.6C537.9,399.2,538,399.4,538.1,399.7z"
			/>
		<path d="M529.7,403.6c0.5-0.2,0.9-0.4,1.4-0.7c0.1,0.2,0.2,0.5,0.3,0.7c-0.5,0.2-1,0.3-1.5,0.5
			C529.8,403.9,529.7,403.8,529.7,403.6z"/>
		<path d="M536.6,411.5c-0.7-0.2-1.4-0.4-1.3-1.6C536.1,410.4,536.6,410.8,536.6,411.5z"/>
		<path d="M539.8,382.5c0-0.5,0-1,0-1.6C540.7,381.4,540.5,381.9,539.8,382.5z"/>
		<path d="M548,374.3c-0.3-0.7-0.7-1.4,0.5-2C548.2,373.2,548.1,373.8,548,374.3z"/>
		<path d="M536.5,415.8c0.5-0.1,0.9-0.2,1.4-0.3c0,0.1,0.1,0.2,0.1,0.4c-0.4,0.2-0.9,0.4-1.3,0.5C536.7,416.2,536.6,416,536.5,415.8
			z"/>
		<path d="M535.7,373.9c-0.4,0-0.7-0.1-1.1-0.1c0-0.1,0-0.2,0-0.3c0.4,0,0.7,0,1.1,0C535.7,373.6,535.7,373.7,535.7,373.9z"/>
		<path d="M534.7,377c-0.1-0.3-0.1-0.7-0.2-1c0.1,0,0.3,0,0.4-0.1c0.1,0.3,0.1,0.7,0.2,1C535,377,534.9,377,534.7,377z"/>
		<path d="M535.7,404.5c-0.3,0.1-0.5,0.1-0.8,0.2c0,0-0.1-0.2-0.1-0.4c0.3-0.1,0.6-0.1,0.8-0.2C535.6,404.2,535.6,404.3,535.7,404.5
			z"/>
		<path d="M671.5,402.5c1.6-1,3.5-0.8,5.1-1.4c0.4-0.1,1,0.1,1.6,0.1c-0.5,1.5-1.9,0.6-2.4,1.4c-1.3-1.1-1.9,0.5-2.8,0.8
			C672.5,403.1,672,402.8,671.5,402.5C671.5,402.5,671.5,402.5,671.5,402.5z"/>
		<path d="M679.6,399c1.1,1.2,1.1,1.2,2.7,0.9c-0.4,0.8-0.4,0.8-2.7,1.9c0.1,0.4,0.2,0.8,0.4,1.3c-0.2,0-0.4,0.1-0.4,0
			c-0.9-0.9-1-2.2-0.4-3.3C679.3,399.5,679.5,399.2,679.6,399L679.6,399z"/>
		<path d="M706.3,416.4c0.3-2,0.5-3.6,0.7-5.2C708.5,412.2,708.4,414,706.3,416.4z"/>
		<path d="M718,392.5c-0.7,1.1-1.3,2.1-1.9,3.1c-0.8-0.2-0.8-0.6-0.4-1.1c0.5-0.6,1.1-1.1,0.1-1.9c0.4-0.5,0.9-0.9,1.4-1.5
			C717.6,391.7,717.8,392.2,718,392.5z"/>
		<path d="M677.8,375.9c0.8,1.2,0.4,2.5-0.1,3.8c-0.2-1.2-0.4-2.5-0.6-3.7C677.4,375.9,677.6,375.9,677.8,375.9z"/>
		<path d="M680.1,406.8c-0.5,1.3,0.5,2.3-0.3,3.3C680.1,409.1,677.8,408.2,680.1,406.8z"/>
		<path d="M710.8,401.4c0.6-0.7,0.8-1.1,1.3-1.6c0.4,0.5,0.8,0.9,1.3,1.6C712.4,401.4,711.8,401.4,710.8,401.4z"/>
		<path d="M720,385.3c0.8,1.4-0.5,1.8-1,2.9C718.9,387,718.5,385.9,720,385.3z"/>
		<path d="M712,405.6c-0.8,0.7-1.3,1.2-1.9,1.8C710.2,405.9,710.5,405.5,712,405.6z"/>
		<path d="M697.4,413.2c0,0.6,0,1,0,1.4c-0.6,0-1.1,0-1.5,0C695.7,413.9,695.7,413.9,697.4,413.2z"/>
		<path d="M679.2,413.4c0.2-0.7,0.3-1.4,0.5-2.1c1.3,0.4,0.2,1.5,0.9,2.2C679.9,413.5,679.4,413.5,679.2,413.4z"/>
		<path d="M685.6,399.3c-0.1,0.7-0.5,1.1-1.2,0.9c-0.1,0-0.2-0.4-0.2-0.5C684.4,398.7,685,399.1,685.6,399.3z"/>
		<path d="M708.7,410.5c-0.4-0.1-0.9-0.3-1.3-0.4c0,0,0-0.4,0.1-0.5c0.5,0.1,0.9,0.2,1.4,0.3C708.9,410,708.8,410.3,708.7,410.5z"/>
		<path d="M674.5,410.7c0.5,0.2,0.9,0.4,1.5,0.7C674.6,411.7,674.6,411.7,674.5,410.7z"/>
		<path d="M714.4,397.7c-0.2-0.9,0.4-1.1,1.2-1.4C715.7,397.2,715.3,397.7,714.4,397.7L714.4,397.7z"/>
		<path d="M714.3,397.6c0,0.5-0.1,1.1-0.1,1.7C713.7,398.5,713.7,398.5,714.3,397.6C714.4,397.7,714.3,397.6,714.3,397.6z"/>
		<path d="M682.4,409.5c0.9,0.2,1.7,0.4,0.7,1.5C682.9,410.5,682.7,410,682.4,409.5z"/>
		<path d="M678.7,389.4c-0.9-0.5-1.1-0.9-0.1-1.6C678.7,388.4,678.7,388.9,678.7,389.4z"/>
		<path d="M679.7,398.9c-0.5-0.1-1.2,0.1-0.6-1C679.3,398.4,679.5,398.7,679.7,398.9C679.6,399,679.7,398.9,679.7,398.9z"/>
		<path d="M703.6,415c0.3,0,0.6,0,0.9,0c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.6,0.1-0.9,0.2C703.6,415.3,703.6,415.1,703.6,415z"/>
		<path d="M408.3,384c2.1,0,4.3,0,6.4,0c0.9,0,1.7,0,1.9,1.1c0.1,1-0.3,1.5-1.2,1.9c-4.5,2.1-8.9,4.3-13.4,6.4
			c-1.6,0.8-2.6,0.1-2.5-1.6c0-2,0.1-4,0.2-6c0.1-1.5,0.4-1.8,1.8-1.9C403.8,384,406,384,408.3,384C408.3,384,408.3,384,408.3,384z
			 M402.3,391.6c1.8,0.1,5.3-1.5,6.3-3c-1.4,0-2.7,0-4,0C402.4,388.6,402,389.1,402.3,391.6z"/>
		<path d="M391.5,400.4c0.6,0.7,1.6,1.4,1.5,1.8c-0.5,1.7,1.5,0.4,1.7,1.5c0.1,0.5,1.2,0.9,1.9,1.4c-0.1,0.2-0.2,0.5-0.3,0.7
			c-0.2,0.5-0.4,1.2-1.1,0.9c-1-0.4,0-1,0-1.5c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.2-2.3,0.2-3.2-1.2c-0.7-1-2.2-1.4-3.4-2
			c-0.8,0.5-1.4,1-2,1.4c-0.1-0.1-0.2-0.1-0.3-0.2c0.2-0.7,0.4-1.3,0.4-2c0-0.7-0.1-1.3-0.2-2.2c0.3,0,1.1-0.1,1.1,0.1
			c0.8,1.7,2.4,1.5,3.9,1.6C391.4,400.3,391.6,400.4,391.5,400.4z"/>
		<path d="M393.2,419.3c-1.3-0.9-2-1.7-1.1-3.1c0.3-0.6,0-1.5,0.1-2.2c0-0.3,0.3-0.6,0.5-0.9c0.2,0,0.3,0,0.5,0
			c0.1,0.6,0.1,1.2,0.3,1.8c0.1,0.7,0.5,1.4,0.4,2.1C393.8,417.7,393.5,418.4,393.2,419.3z"/>
		<path d="M390.9,391.1c0.4,1.7,0.7,3.2,1,4.7c-0.6,0.6-0.9,1.5-2.1,1.4c-0.1-0.7,1.2-1.2,0.1-1.9c0,0,0.1-0.4,0.2-0.6
			c0.4-0.6,0.5-1.3-0.1-1.8C389,391.9,390,391.7,390.9,391.1z"/>
		<path d="M388.8,417.4c0.5,0.2,0.8,0.3,1.1,0.4c0,0.1,0,0.2,0,0.3c-0.4,0-0.8,0-1.2,0c-0.3-0.1-0.6-0.2-0.8-0.3
			c-0.7,0.9-1.3,1.8-2,2.8c-0.2-1.9,0.3-3.4,1.4-4.7c0.2,0,0.5,0.1,0.8,0.1c0.2-0.6,0.3-1.1,0.5-1.6c0.1,0,0.2,0.1,0.4,0.1
			C388.9,415.4,388.9,416.4,388.8,417.4z"/>
		<path d="M384.4,395.6c1.1-0.8,1.7-1.4,1.1-2.8c-0.2-0.5,0.5-1.5,0.7-2.2c0.1,0,0.3,0,0.4,0.1c0.1,0.2,0.3,0.5,0.2,0.6
			c-0.8,1.2-0.6,2.6-0.7,3.9C386.2,396.5,385.7,396.6,384.4,395.6z"/>
		<path d="M391,383.2c-0.2-0.4-0.6-1-0.5-1.3c0.3-1.3,1.3-1.9,2.5-2.2c0.6,0.6,0.4,1.1-0.2,1.4c-0.8,0.5-1.7,0.8-1.2,2.4
			C391.4,383.3,391.2,383.2,391,383.2z"/>
		<path d="M419,398.6c1,1.4,0.1,4-1.3,4.3C418.1,401.5,418.5,400.2,419,398.6z"/>
		<path d="M393,409.4c0.7,0.5,1.3,1,1.8,1.4c-0.5,0.5-1,1-1.8,1.8c0.1-0.9,0.2-1.4,0.2-1.9c-0.4,0.1-0.7,0.1-1.5,0.2
			C392.4,410.2,392.7,409.8,393,409.4z"/>
		<path d="M395,408.4c-1.9,0.1-2.7-0.4-3.4-1.9c0.6,0.2,1.3,0.3,1.8,0.6C394,407.5,394.5,408,395,408.4z"/>
		<path d="M414,414.8c0.4-0.8,0.7-1.6,1.2-2.7c0.3,0.5,0.7,1.1,0.7,1.1C415.3,413.8,415.1,414.8,414,414.8
			C413.9,414.7,414,414.8,414,414.8z"/>
		<path d="M389.1,410.4c0.7,0.3,1.2,0.6,1.7,0.8c-0.3,0.6-0.6,1.1-1,1.9C389.6,412.1,389.4,411.4,389.1,410.4z"/>
		<path d="M406.6,377.8c-0.4-0.8-0.6-1.4,0.2-1.8c0.2-0.1,0.6,0.1,0.7,0.2C408,377.1,407.1,377.2,406.6,377.8z"/>
		<path d="M396.3,383c-0.5,0.1-0.9,0.3-1.1,0.2c-0.2-0.1-0.3-0.5-0.5-0.7c0.3-0.1,0.6-0.4,0.8-0.3C395.8,382.2,396,382.6,396.3,383z
			"/>
		<path d="M418.2,404.8c-0.8,0-1.3,0-1.9,0C417.3,403.3,417.4,403.3,418.2,404.8z"/>
		<path d="M400.6,383.2c0-0.5-0.1-0.9-0.1-1.5C401.6,382.3,401.6,382.3,400.6,383.2z"/>
		<path d="M393.3,384.7c0,0.9,0,1.5,0,2.2C392.6,386.4,392.6,386.4,393.3,384.7z"/>
		<path d="M395.8,403.6c-0.4-0.9-0.7-1.7,0.8-1.7C396.3,402.6,396,403.1,395.8,403.6z"/>
		<path d="M413.7,394.8c-0.4,0.2-0.7,0.4-0.9,0.5c-0.1,0-0.4-0.4-0.4-0.5c0.1-0.2,0.4-0.5,0.6-0.5
			C413.2,394.2,413.4,394.5,413.7,394.8z"/>
		<path d="M391,416.8c-0.2-0.3-0.5-0.4-0.5-0.6c0-0.2,0.2-0.4,0.3-0.6c0.2,0.2,0.5,0.3,0.7,0.5C391.4,416.3,391.2,416.5,391,416.8z"
			/>
		<path d="M382.2,375.3c0.4,0.2,0.9,0.5,1.5,0.8c-1.2,0.7-1.3-0.2-1.7-0.4C382,375.5,382.1,375.4,382.2,375.3z"/>
		<path d="M418.2,396.8c0.1-0.2,0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.3,1-0.2c-0.3,0.4-0.6,0.7-0.8,1.1C418.4,397,418.3,396.9,418.2,396.8
			z"/>
		<path d="M395,374.6c0.3,0.1,0.6,0.1,0.9,0.2c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0-0.2-0.1-0.3-0.2C395,374.9,395,374.8,395,374.6z"/>
		<path d="M406.9,382.2c0,0.3,0,0.6,0,1c-0.1,0-0.3,0-0.4,0c0-0.3,0-0.6,0-0.9C406.6,382.2,406.8,382.2,406.9,382.2z"/>
		<path d="M422.2,411.8c-0.3,0.1-0.5,0.3-0.7,0.3c-0.1,0-0.2-0.3-0.3-0.4c0-0.1,0.2-0.3,0.2-0.3
			C421.6,411.4,421.7,411.6,422.2,411.8z"/>
		<path d="M399,418.2c-0.4-0.3-0.7-0.5-1.3-0.9C399.1,417,399.1,417,399,418.2C399,418.2,399,418.2,399,418.2z"/>
		<path d="M265.4,384c2.3,0,4.7,0,7,0c1.2,0,1.8,0.8,1.5,1.9c-0.1,0.4-0.5,0.8-0.9,1c-4.6,2.2-9.1,4.4-13.7,6.6
			c-1.5,0.7-2.2,0.3-2.2-1.4c-0.1-2,0-4,0-6c0-1.8,0.3-2,2-2.1C261.1,384,263.2,384,265.4,384z M266,389.2c-0.1-0.2-0.1-0.3-0.2-0.5
			c-0.2-0.1-0.4-0.1-0.6-0.2c-1.1,0-2.2,0-3.3,0c-2.5,0-3,0.9-2.2,3.6C261.9,391.2,264,390.2,266,389.2z"/>
		<path d="M261,400.2c1.9,0.3,1.9,0.3,2.3,2c-2,0.1-3.9,0.3-5.6,2.1c0.1-0.6,0-1,0.2-1.1c0.8-0.9,1.5-1.8,0.6-3
			c-0.1-0.1,0.4-0.6,0.6-1C259.8,399.5,260.4,399.9,261,400.2c-0.8,0.7-0.8,0.7-0.1,1.5C260.9,401.2,260.9,400.7,261,400.2z"/>
		<path d="M245.4,383.7c-0.6-1.3-1.3-1.9-2.4-1.7c-0.3-0.9-0.6-1.7-1-2.7c1.3,0,2.5,0,3.9,0c-0.7,1-0.6,2,0,3.1
			C246,382.5,245.7,383,245.4,383.7z"/>
		<path d="M247.4,378.9c1-0.2,1.9-0.4,2.9-0.7c0.5-1.1,1-2.3,1.5-3.5c0.2,0.1,0.4,0.1,0.5,0.2c-0.5,1.2-0.8,2.5-1.4,3.6
			c-0.6,1.1-1.4,2.1-2.3,3.1c0.1-0.6,0.2-1.3,0.4-2c-0.5-0.2-1.2-0.3-1.8-0.5C247.3,379.1,247.3,379,247.4,378.9z"/>
		<path d="M253.6,397.4c-0.5,0.1-1,0.3-1.7,0.5c0.2-1,0.3-1.9,0.4-2.9c-0.1,0,0.1-0.1,0.1-0.1c0.7,0.6,1.8-0.5,2.3,0.5
			c0.4,0.7,0.6,1.5,0.9,2.2c0,0,0-0.1,0-0.1c-0.6,0.2-1.2,0.5-1.8,0.8C253.8,398,253.6,397.7,253.6,397.4c1,0,1-0.5,0.3-1.3
			C253.8,396.5,253.7,396.9,253.6,397.4z"/>
		<path d="M256.2,399.4c0,0.1,0.1,0.4,0,0.4c-0.9,0.5-1.3,1.4-1.3,2.5c-0.6,0-1,0-1.8,0c0.6-0.3,0.9-0.5,1.4-0.9
			c-0.6-0.3-0.9-0.5-1.5-0.8c0.8-0.7,1.3-1.6,2.5-1.5C255.9,399.2,256,399.3,256.2,399.4z"/>
		<path d="M239.2,398.8c0.4,0,0.9,0.1,1.4,0.1c0.2,0.6,0.4,1.4,0.6,2.3c0.2,0.8-0.6,1.2-1.9,0.9C239.2,401,239.2,399.9,239.2,398.8
			C239.2,398.8,239.2,398.8,239.2,398.8z"/>
		<path d="M248.5,410.6c0,0.9,0,1.5,0,2c-1.8,0.5-1.9,0.5-1.8-1.3C247.2,411.1,247.8,410.9,248.5,410.6z"/>
		<path d="M257.1,404.9c0,0.6,0.1,1.1,0.1,1.7c-0.6,0-1.3,0-2,0C255.4,405.5,255.7,404.7,257.1,404.9z"/>
		<path d="M243.9,414.5c-0.7,1.2-1.5,1.9-3,1.5C241.1,414.9,241.6,414.7,243.9,414.5z"/>
		<path d="M275.8,388.4c0-0.7,0-1.4,0-2.4c0.7,0.6,1.2,1.1,1.9,1.6c-0.6,0.4-1,0.7-1.5,1C276.1,388.6,275.9,388.5,275.8,388.4z"/>
		<path d="M243.9,408.8c0.2,0.3,0.4,0.5,0.6,0.7c-0.5,0.8-1.1,1.5-1.7,2.3C242.7,410.6,242.4,409.4,243.9,408.8z"/>
		<path d="M254.2,378.6c-0.2,2,1.5,0.9,1.8,2c-0.9,0.9-1.9,0.5-3.1-0.1C253.3,379.9,253.6,379.5,254.2,378.6z"/>
		<path d="M263.4,395.3c1,0.8,1.6,1.3,2.2,1.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,0.1-1.1,0.2-1.8,0.3
			C263.5,397.1,263.5,396.4,263.4,395.3z"/>
		<path d="M278.8,379.3c0.2-0.4,0.4-0.6,0.5-0.9c0.9,0.8,2.5,0.3,3.5,1.7C281.4,379.8,280.3,379.6,278.8,379.3z"/>
		<path d="M266.9,402.5c-0.9,0.6-1.3,0.9-1.9,1.3c-0.3-0.8-0.5-1.3-0.9-2.2C265.1,402,265.8,402.2,266.9,402.5z"/>
		<path d="M266,396.6c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.2,0.8-0.4,1.1-0.5c0,0.3,0.1,0.8-0.1,1C267.2,397,266.7,397.7,266,396.6z"/>
		<path d="M277.7,399c-1.3,0-2.4,0.5-3.1-0.9C275.8,397.4,276.5,398.3,277.7,399z"/>
		<path d="M254.2,411.2c0.2-1.1,0.8-1.6,2-1C255.4,410.6,254.8,410.9,254.2,411.2z"/>
		<path d="M245.2,404.2c-0.4,0.2-0.8,0.4-1.3,0.6c-0.1-0.2-0.3-0.6-0.3-0.6c0.4-0.2,0.8-0.4,1.2-0.6
			C245,403.8,245.1,404,245.2,404.2z"/>
		<path d="M257.7,406.9c0.7,0.1,1.3,0.1,1.9,0.2C258.8,408.2,258.8,408.2,257.7,406.9z"/>
		<path d="M251,412.7c-0.6-0.2-1.1-0.4-1.8-0.7c0.2-0.4,0.3-0.8,0.5-0.8C250.4,411.1,250.5,411.2,251,412.7z"/>
		<path d="M250.1,400.1c-0.2-0.5-0.4-0.9-0.6-1.6C250.7,398.7,250.5,399.4,250.1,400.1z"/>
		<path d="M256.2,399.4c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.5,0-1,0.1-1.5c0,0,0,0.1,0,0C256.3,398.1,256.5,398.7,256.2,399.4z"/>
		<path d="M246.9,398.3c-0.4,0-0.8,0-1.2,0c0-0.1,0-0.2,0-0.3c0.4,0,0.8,0,1.2,0C246.9,398.1,246.9,398.2,246.9,398.3z"/>
		<path d="M272,396.4c-0.2-0.2-0.4-0.5-0.9-1c0.7,0.1,1,0.1,1.2,0.3c0.1,0.1,0,0.4,0.1,0.7C272.3,396.3,272.2,396.4,272,396.4z"/>
		<path d="M271.6,373.5c0.4,0,0.8-0.1,1.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.7-0.2
			C271.6,373.7,271.6,373.6,271.6,373.5z"/>
		<path d="M255.8,375.6c0-0.4,0-0.8,0-1.3c0.1,0,0.2,0,0.3,0c0.1,0.4,0.1,0.9,0.2,1.3C256.2,375.6,256,375.6,255.8,375.6z"/>
		<path d="M242.5,408.1c-0.6,1.1-1,1-1.5,0.1C241.5,408.2,241.9,408.2,242.5,408.1z"/>
		<path d="M251,409.9c0.2,0.2,0.5,0.4,0.7,0.6c0,0-0.2,0.3-0.3,0.4c-0.2-0.2-0.4-0.4-0.7-0.6C250.9,410.1,251,410,251,409.9z"/>
		<path d="M290.9,379c1.3,0.3,0.9,1,0.5,1.6C291.2,380.1,291.1,379.6,290.9,379z"/>
	</g>
	<path class="st0-bull" d="M568.2,319.1c0.3,0.7,0.1,1.2-0.6,1.1c-0.7-0.1-1.4-0.5-2.2-0.8c-0.4,0.5-0.8,1.2-1.3,1.8
		c0.1,0.4,0.2,0.8,0.3,1.2c-1.6,0.2-0.9,1.6-1.2,2.4c-0.6,0.4-1.1,0.7-1.8,1.1c1.1,1,1.8,0.2,2.4-0.2c0.7-0.4,1.2-1.1,1.9-1.7
		c-0.1-0.3-0.3-0.7-0.5-1.1c0.7-0.7,1.4-0.9,2.1,0c-0.5,2.1-0.8,4.3-1.5,6.3c-0.9,2.8-0.7,2.8-3.7,3.2c-0.6,1.2,0.9,0.9,1.1,1.5
		c-0.7,0.6-0.7,0.6-0.2,1.2c0-0.5,0.1-0.9,0.1-1.3c0.4,0.1,0.8,0.2,1.4,0.4c-0.3,1.1-0.5,2.1-0.8,3.1c-0.2,0.5-0.5,1-1.2,0.7
		c-1-0.7-1.7-0.4-2.4,0.6c0.3,0.3,0.6,0.5,0.6,0.5c0.7-0.5,1.3-0.8,1.8-1.2c0.9,1.2,0.7,2.4,0.2,3.6c-0.9,1.9-0.8,4.1-2,6
		c-0.5,0.8-1.3,1.4-1.5,2.4c-0.1,0.4-0.3,0.8-0.4,1.2c0.4,0,0.8,0.1,1.4,0.2c-0.3,1-0.6,2-0.9,3c-0.1,0-0.3,0-0.4,0
		c-0.2-0.5-0.3-0.9-0.6-1.6c-0.5,0-1.1-0.1-1.7-0.1c-0.4,1.1-0.9,2-1.3,3.1c-0.8,0-1.5,0-2.3,0c-18.3,0-36.6,0-54.9,0
		c-3.6,0-7.2,0-10.8,0c-0.9,0-1.6-0.1-1.6-1.2c-1.3,2.3-3.3,0.7-5,1.3c-0.2-0.8-0.3-1.4-0.6-2.3c-0.5,0.5-0.9,0.8-1.1,1.1
		c-0.5,0.8-1.1,1.1-2,1.1c-4.5-0.1-9.1-0.2-13.6-0.2c-4.1,0-8.3,0.1-12.4,0.2c-0.6,0-1.1,0-1.7,0c-0.9,0-1.5-0.2-1.4-1.4
		c-0.3,1-0.6,1.5-1.6,1.4c-0.9-0.1-1.8,0-2.7,0c-0.7,0-1.3,0-1.7-0.8c-0.6-1-1.6-0.8-2.7-0.6c-0.2,0.4-0.3,0.9-0.5,1.4
		c-0.7,0-1.3,0.1-1.9,0c-0.4-0.1-0.8-0.2-1.1-0.5c-1.2-1.6-2.3-3.2-3.5-4.9c0.3-0.1,0.6-0.3,1-0.5c-2-0.1-2.9-1.3-3.5-2.9
		c-0.2-0.4-0.6-0.7-0.9-1.1c-1.6-2-3.2-4-4.7-6c-0.1-0.2-0.2-0.4-0.3-0.6c-0.6-1.8-1.1-2.3-2.2-3.6c-1.7-1.9-3.3-4-4.9-5.9
		c0.3,0,0.7,0.1,1,0.1c0,0.7,0.3,0.9,0.9,0.6c0.5-0.3,0.9-0.8,1.5-1.3c-1.3-1.3,0-3.3-1.7-4.4c-0.6,0.4-1.1,0.9-0.5,1.7
		c0.4,0.6,0.4,1.3-0.3,1.8c-0.1-0.4-0.2-0.8-0.3-1.4c-0.8,0.9-0.2,1.1,0.3,1.4c-0.1,0.1-0.1,0.2,0,0.3c-0.3,0.4-0.6,0.8-0.9,1.2
		c-1.2-0.7-1.4-1.7-1-3c0.3-1.1,0.6-2.1,0.8-3.2c0.8-0.3,1.5-0.6,2.3-0.9c-0.6-1.9-1.8-3.5-0.6-5.5c0.2,0,0.5,0.1,0.9,0.2
		c0.1-0.8,0.2-1.7,1.3-1.9c0.2,0,0.5-0.3,0.6-0.6c0.1-0.2,0.2-0.7,0.1-0.7c-0.2-0.2-0.6-0.3-0.9-0.2c-0.3,0.1-0.6,0.4-1.1,0.6
		c-0.1-0.4-0.1-0.5-0.2-0.7c-0.4-1.7,1.1-2.9,1.2-4.5c0.1-1.4,1-2.9,1.8-4.2c0.4-0.7,1.2-1.1,2.1-1.9c-1,0.2-1.7,0.3-2.4,0.4
		c0.3-1.1,0.6-2.2,0.8-3.3c0.2-1.1,0.4-2.2,0.6-3.2c0.5-0.1,0.8-0.2,1.2-0.3c-1.3-1.9-0.2-3.4,0.4-5c0.1-0.3,0.6-0.2,0.2-0.7
		c-0.2-0.3,0.1-1,0.2-1.6c0.5-2.7,1.7-5.2,2.1-8c0.4-2.7,1.2-5.4,1.9-8c0.2-1,0.5-2,0.8-3c0.1-0.4,0.4-0.8,0.9-1.1
		c-0.1,0.4-0.3,0.9-0.2,1.3c0.2,0.5,0.6,0.9,0.9,1.3c-0.3,0.5-0.7,1-1.2,1.7c0.8,0.2,1.4,0.3,2.1,0.5c1-1.4,0.4-2.8-0.3-4.2
		c-0.2-0.3-0.4-0.6-0.4-0.9c-0.1-2.6-0.3-5.2,0.5-7.7c1.1-0.5,0.6-1.7,1.1-2.3c0.6-0.8,1.6-1.3,2.7-2.1c0.4,0.9,0.6,1.5,0.8,2.1
		c1.3-0.4,1.7-0.8,1.4-1.8c-0.6-1.7,0.3-2.6,1.4-3.4c0.5,0.5,1,0.9,1.5,1.4c-0.1,1-0.3,2-0.4,3.1c1.7,0.1,2.2-0.1,2.2-1
		c0-1.2-0.6-1.9-1.9-2c0.1-0.6,1.2-1.1,0.3-1.9c-0.1-0.1,0.1-0.6,0.3-0.9c0.4-0.5,0.7-1,1.2-1.4c6.5-5.3,13.1-10.6,19.7-15.9
		c0.1-0.1,0.2-0.1,0.6-0.1c0,0.7,0.1,1.3,0.1,2c0.1,1.8,0.4,3.7,0.4,5.5c0,1.7-0.2,3.3-0.3,5c-0.1,1.8-0.2,3.6-0.5,5.4
		c-0.2,1.8,0.2,3.7-0.7,5.5c-0.5,1,0.2,2.3-0.6,3.4c-0.6,0.8-0.6,1.9-0.8,2.9c-0.3,1.3-0.7,2.7-2,3.5c-0.9,0.5-1.5,0.9-2.4,0.1
		c-0.3-0.2-1-0.3-1.4-0.1c-0.9,0.3-1.7,0.8-2.5,1.3c-0.2,0.8,0.4,1.2,0.9,0.9c1.9-1.1,3.9-0.4,6-0.7c0.4,1.1,0.7,2.1,1.1,3.2
		c-0.9,0.4-1.5,0.7-2.2,1c-0.5-0.5-1-1.1-1.4-1.5c-0.5,0.3-1,0.5-1.5,0.8c0.1,1.7,0.2,1.8,1.6,2c0.8,0.1,1.5,0.2,2.4,0.3
		c0.1-0.3,0.2-0.8,0.4-1.3c0,0,0.1-0.1,0.4-0.2c0.8,0.6,1.6,1.3,2.5,2c0.9-0.4,1.3-1.2,1-1.9c-0.2-0.6-0.9-1-1.5-1.6
		c-0.1-1.3-0.4-2.8,1-3.9c0.1-0.1-0.1-0.4-0.1-0.6c1,0.1,2,0.3,3,0.4c-0.1,0.5-0.2,0.9-0.2,1.4c1.3,0.3,1.4-0.7,1.6-1.3
		c0.4-1,1-1.4,2.1-1.5c0.9-0.1,1.8-0.5,2.8-0.8c-1.3-0.6-2.5-0.6-3.7,0.4c-0.3-0.5-0.5-0.9-0.7-1.2c-1.1,0.7-2,1.8-3.1,2
		c-0.7,0.1-1.2,0.3-1.8,0.6c-0.4-1.5-0.5-3.1,0.1-4.6c0.2-0.4,0.2-0.9,0.2-1.4c0-0.3-0.1-0.8,0.1-1c1.4-2.4,0.6-5.2,1.2-7.6
		c0.6-2.7-0.4-5.4,0.8-7.9c0.1-0.1-0.1-0.4-0.2-0.5c-0.4-1.7-0.4-1.8,0.7-2.9c0-1.4,0-2.7,0-4.1c-0.3-0.4-1-1-1.3-1.7
		c-0.5-1.2,0.1-2.4,1.4-3.4c0.7-0.5,1.4-1.1,2-1.8c0.7-0.9,0.4-1.6-0.7-1.7c-1-0.1-2,0-3.3,0c0.1-4.4-0.7-8.6,0.3-12.8
		c-0.2,0.2-0.5,0.3-0.5,0.5c0,1.5-1.4,2.9-0.4,4.5c0.1,0.1,0.1,0.3,0.1,0.4c-1.1,2.3-0.1,4.8-0.7,7.3c-1.4,0.1-2.8,0.2-4.2,0.2
		c-2.4-0.1-4.8,0.4-7.2-0.4c-0.8-0.3-1.8,0.2-2.8,0.3c-1.7,0.3-3.3,0.1-4.7-0.9c-0.9,1.8-2.3,0.6-3.5,1.2c0.1-1.6,0.5-2.8,1.8-3.1
		c0.8-0.2,1.6,0.1,2.4-0.4c0,0,0.6,0.7,0.8,1.1c0.1,0.3-0.2,0.6-0.4,1.4c0.7-0.4,1-0.5,1.3-0.7c0.1,0,0-0.3,0-0.4
		c0.2-1.4,0.4-2.7,0.6-4.3c-0.8,0.7-1.5,1.3-2.1,1.9c-0.7-0.2-1.3-1.1-2.1-0.1c-0.1,0.1-0.8-0.2-1.4-0.4c0.6-2,1.3-4,1.9-5.9
		c0,1.4,0.9,1.6,2,1.5c1.1,0,1.1,0.6,0.8,1.4c0.7,0.2,1.4,0.3,2.4,0.6c-0.7-1.2-1.2-2.1-1.8-3.2c0.6-1.9,0.5-2.3-0.7-2.9
		c-1,0.7,1.3,2-0.6,2.6c-0.2-0.3-0.3-0.6-0.4-0.8c-0.7,0.3-1.2,0.6-1.8,0.9c0.3-1.5,0.6-3,0.9-4.4c0.2-0.8,0.7-0.9,1.5-0.7
		c0.1,0.5,0.3,1,0.4,1.5c0.1,0,0.2,0,0.4,0c0-0.6,0.1-1.3,0.1-2c-0.6-0.1-1-0.3-1.6-0.4c-0.2-1.8,0.4-3.4,1.1-5.1
		c0.1-0.2,0.5-0.4,0.5-0.6c-0.1-3.8,1.5-7.2,2.3-10.8c0.8-3.8,1.9-7.5,2.8-11.4c2.5,0,5-0.1,7.4-0.1c2.5,0,5,0,7.5,0
		c2.6,0,5.1,0,7.7,0s5.1,0,7.7,0c2.6,0,5.1,0,7.7,0c2.5,0,5,0,7.4,0c2.5,0,5.1,0,7.8,0c-0.4,1.5-0.4,2.9-1,4
		c-0.5,1.1-0.5,2.2-0.7,3.2c-0.3,1.3-0.6,2.5-1,3.8c-0.3,1.2-0.6,2.4-0.9,3.6c-0.3,1.3-0.7,2.5-1,3.8c-0.5-0.5-0.8-0.8-1.3-1.3
		c-0.4,0.7-0.6,1.2-0.9,1.8c0.5,0.5,1.1,1.1,0.6,2c-0.2,0.3,0.3,0.9,0.5,1.5c-0.7,1.1-1.4,2.2-1.2,3.9c0.1,1.5-0.3,3-0.7,4.5
		c-0.6,2.4-1.3,4.7-1.9,7c-0.6,2.3-1.3,4.6-1.9,6.9c-1.2,5-2.3,10.1-3.5,15.1c-4,16.2-8.1,32.4-12.2,48.6c-0.4,1.6-0.8,3.3-1.2,5
		c-0.5,1.8-0.9,3.6-1.4,5.4c-0.4,1.7-0.9,3.5-1.3,5.2c-0.2,0.9-0.7,1.9,0.6,2.5c-0.3,0.6-0.5,1.1-0.9,1.8c0.6,0.1,1,0.2,1.6,0.3
		c0.1-0.8,0.3-1.4,0.4-2.2c0.8,0,1.5,0,2.2,0c12,0,24.1,0,36.1,0c0.5,0,1,0,1.5,0c1.6-0.1,1.7-0.2,2.2-1.6c0.1-0.5,0.3-0.9,0.4-1.4
		c0.4-0.1,0.8-0.2,1.5-0.4c-1.2-0.3-1.2-0.8-1-1.7c1.9-7.6,3.8-15.1,5.7-22.7c6.3-25.1,12.6-50.2,18.9-75.3
		c1.8-7.1,3.6-14.2,5.3-21.3c0.2-0.7,0.4-1.3,0.6-2c6,0,11.8,0,17.7,0c5.9,0,11.8-0.1,17.6-0.1c5.9,0,11.7,0,17.8,0
		c-0.4,1.6-0.7,3-1,4.4c-2.6,10.4-5.2,20.9-7.9,31.3c-0.8,3.2-1.5,6.5-2.4,9.7c-0.9,3.3-1.7,6.6-2.5,9.8c-0.8,3.3-1.6,6.6-2.5,9.8
		c-0.2,0.6-0.6,1.2-0.8,1.8c-0.2,0.4-0.3,0.9-0.4,1.3c-0.2,1-0.3,2-0.4,3.1c-1.6,0.2-2.9,1.1-4.4,1c-0.6,0-1.7,0.2-1.7,1.4
		c-2.9-0.5-3.2-0.4-3.7,1.4c0.6,0,1.5,0.1,1.5,0c0.3-1.2,1.5-0.9,2.2-1.5c0.4,0.1,0.8,0.2,1.3,0.3c0.4-0.3,1.2-1.2,1.4-1.1
		c1,0.5,1.9-0.4,3,0c-0.3,2.7-1.2,5.2-1.8,8c-0.5-0.1-1-0.1-1.4-0.2c-0.4,1.1,0.7,0.8,1,1.2c-0.5,3.4-1.1,5.3-2,5.7
		c0,0.8,0.2,1.6,0,2.3c-0.2,0.7-0.9,1.2-1.4,2c1,0.8,0.7,1.9,0.3,3c-0.2,0.6-0.2,1.2-0.5,1.8c-0.7,1.5-1.4,3-1.2,4.7
		c0,0.5-0.3,1-0.5,1.7c-0.7-0.4-1.2-0.7-1.7-1c-0.8,1.3,0.3,1.5,1.1,1.9c-1,1.7-2,3.2-3,4.8c0.5,0.1,1.2,0.2,2.1,0.3
		c-0.4,1.6-0.8,3.3-1.2,5c-1,0.4-2.3,0.3-2.6,1.7c-0.1,0.3-0.5,0.6,0,1c0.2,0.1,0.2,0.7,0.1,0.7c-0.9,0.7,0,1.6-0.3,2.1
		c-0.5,1-0.1,1.4,0.2,2.2c0.4,0.8,0.2,1.7-1.1,1.8c-0.3,0-0.5,0.3-1.2,0.7c0.2-0.7,0.3-1,0.3-1.3c0.1-0.9-0.6-1.6-1.5-1.4
		c-0.2,0-0.3,0.6-0.5,0.9c0.7,0.3,0.9,0.8,0.9,1.6c0,0.1,0.6,0.4,0.9,0.5c0.5,0.1,1.1,0,1.8,0C570.2,312.5,569.6,315.9,568.2,319.1
		c0-0.3,0-0.7,0-1.4C567.3,318.6,567.8,318.8,568.2,319.1z M462.4,279c-1.5,0.5-1.8,1.7-1.5,3.1c-0.4,0.1-0.8,0.1-1.1,0.2
		c0,0.6,0.3,1.4,0,1.6c-1.1,0.9-1,2.3-1.6,3.5c1.9-0.8,2.8-2,2.5-3.9c-0.1-0.5,0-1,0-1.4C462.8,281.7,461.9,280,462.4,279
		c0.9-0.2,0.9-0.2,0.8-1.9C462.9,278,462.7,278.5,462.4,279z M564.9,311.7c0-0.9,0-0.9-0.8-0.6C564.4,311.3,564.6,311.5,564.9,311.7
		c0,0.4,0,0.8,0,1.3c-0.7-0.3-1.5-0.4-1.9,0.4c-0.2,0.4-0.2,1.1,0,1.4c0.4,0.7,1.1,1.1,2.2,0.4c-0.1-0.7-0.2-1.5-0.3-2.3
		c0.8,0.2,1.5,0.5,2.3,0.6c0.9,0.1,1.6-0.2,1.4-1.5C567.3,312,566.1,311.8,564.9,311.7z M465.3,333.6c0.3,0.3,0.5,0.8,0.9,0.9
		c0.4,0.2,1.8-0.9,1.9-1.4c0.1-0.5,0.3-1.1,0.5-2c-0.9,0.6-1.4,1-2,1.4c-0.6-0.4-1.1-0.8-1.7-1.2c-0.1,0.1-0.2,0.2-0.3,0.3
		C464.8,332.3,465.1,333,465.3,333.6c-0.9-0.4-1.2,0.3-1.8,0.8C464.6,335,464.9,334.3,465.3,333.6z M553.1,263.5
		c-0.8-0.4-1.7-0.8-2.7-1.3c0.3-2.5,0.9-5.1,2.2-7.6c-0.4-1-0.4-1,0-2.1c0.1-0.3,0.3-0.6,0.3-1c0-1.7-0.1-3.3,0-5
		c0.1-1.7,0.4-3.4,0.5-5.1c0.1-2-0.1-4.1,0.6-6.1c0.2-0.6,0.5-1.7-0.8-2c-0.2,0-0.4-0.5-0.4-0.7c0.5-1.5-1.7-3-0.1-4.6
		c0,0-0.1-0.2-0.1-0.4c-0.9-2.7-0.6-5.5-0.7-8.2c-0.1-1.2,0.1-2.5,0-3.7c0-0.2-0.6-0.3-1.2-0.6c0,2.3,0,4.2,0,6.2
		c0,2.2,0.1,4.5,0.2,6.7c0,0.3,0,0.6,0.1,0.8c0.8,0.8,0.6,1.8,0.6,2.7c0,2.4,0,4.8,0,7.3c0,0.5,0.1,1.2-0.2,1.6
		c-0.5,0.6-0.3,0.9,0,1.6c0.3,0.8,0,1.8-0.1,2.7c0,0.3-0.3,0.6-0.3,0.9c-0.1,2.3-0.3,4.6-0.4,6.9c-0.2,3.6-0.9,6.9-2.1,10.2
		c-0.3,0.9-1,1.5-2,1.4c-1.3-0.2-2.3,0.4-3.4,1.2c0.2,0.2,0.4,0.6,0.5,0.6c0.7-0.3,1.3-0.6,1.6-0.8c1.1,0.1,1.9,0.1,2.8,0.2
		c0.1,0.3,0.2,0.7,0.3,1.3c0.5,0,1-0.1,1.4-0.1c0.4-1.9,0.4-2,2.2-2.6C552.2,263.8,552.7,263.6,553.1,263.5c1.2,0,0.8,1.4,1.4,2
		c0.3-0.1,0.6-0.1,0.6-0.2c0.2-1.4,1-2.1,2.5-2.2c0.6,0,1.3-0.3,1.9-0.6c0.4-0.2,0.7-0.5,1.5-1.1c-1.8-0.1-3-0.1-4.2-0.2
		c-0.1-0.6-0.1-1.1-0.1-1.5c-1,1.4-1.9,2.7-3.5,3.2C553.1,262.9,553.1,263.3,553.1,263.5z M586.1,213c0.5,0.7,1.3,1.2,0.7,2.5
		c-0.5,1.2-0.4,2.9,0,4.1c0.6,1.5,0,2.2-0.9,3c-0.2,0.2-0.3,0.4-0.6,0.7c0.6,0.3,1.1,0.5,1.5,0.6c1.8-1.9,1.6-4.3,1.5-6.6
		c0-0.6-0.3-1.2-0.2-1.7c0.6-2.8-0.3-5.6-0.4-8.4c-1.1-1.4-0.7-2.7,0-4.1c0,0-0.2-0.2-0.4-0.5c-0.5,0.9-1.4,1.9-1.3,2.6
		c0.3,1.2,0.2,2.6,0.7,3.6C587.5,210.4,586.8,211.5,586.1,213z M560.5,318.5c0.5-0.6,0.2-1.8,1.3-1.9c-0.1-0.7-0.2-1.4-0.3-2.4
		c-1.7,0.5-3.6,1-5.4,1.5c-0.1,0.5-0.2,1-0.3,1.4c-0.6-0.1-1.1-0.1-1.7-0.2c0.4,1.7,2.1,1.6,3.1,2.5c-0.2-0.8-0.3-1.5-0.5-2.3
		C558.5,316.2,559,318.2,560.5,318.5z M471.1,329.2c0.1,0.1,0.2,0.3,0.3,0.4c1.5-0.4,2.6-2,4.3-1.8c0.1,0,0.2-0.2,0.3-0.3
		c0.7-1.1,1.9-1.6,3.1-2.2c-0.1-0.5-0.1-1-0.2-1.7c-1.1,0.5-1.9,0.9-2.6,1.8c-0.6,0.8-1.8,1.2-2.7,1.9
		C472.7,328,471.9,328.6,471.1,329.2z M587.1,195.7c-0.2,0-0.3,0-0.5,0c0,0.5,0.1,1.1,0,1.6c-0.1,0.5-0.4,1-0.6,1.4
		c-0.1,0.3-0.1,0.7,0,1c0.3,0.7,0.7,1.4,1,2.1c1.1-0.9,1.5-1.3,1.3-1.9c-0.1-0.3-0.6-0.6-0.9-0.8C588.6,197.9,586.6,197,587.1,195.7
		z M569,260.8c-0.5-0.3-0.9-0.7-1.2-0.7c-1.3,0.3-2.5,0.7-3.8,1.2c-0.4,0.2-0.8,0.5-1.1,0.8c-0.2,0.2-0.1,0.6,0,0.9
		c0,0.1,0.5,0.2,0.7,0.1C565.3,262.5,567,261.7,569,260.8z M583.2,231.5c0-2.6,0-5.2,0-7.8c-1-0.2-1,0.3-1.1,0.9
		C581.7,227,583.1,229.1,583.2,231.5z M447.1,269.3c-1.5,0.3-1.9,1.3-2.3,2.7c1.9,0.1,3.3-0.9,4.9-1.4c0.1,0,0-0.4,0-0.6
		C448,270.6,447.9,270.5,447.1,269.3z M470.6,319.8c-0.6,0-1.1-0.1-1.6,0c-0.9,0.2-0.9,1.7-2.2,1.5c0.1,0.4,0.2,0.8,0.3,1.2
		c0.5-0.1,1-0.2,1.4-0.4c0.6-0.3,1-0.8,1.5-1.2C470.4,320.7,470.4,320.3,470.6,319.8z M565.3,306.1c-0.8,0-1.4-0.1-1.6,0.1
		c-0.8,0.9-1.5,2-2.3,3c1,0.3,2.2,0.5,2.2-0.5c0-1.2,0.6-1.3,1.4-1.6C565,307.1,565,306.7,565.3,306.1z M430.2,306.2
		c-0.3-1.1-0.3-1.7,0.6-1.8c0.8-0.1,0.7-0.6,0.5-1.3c-0.8,0.3-1.6,0.4-2,0.9c-0.4,0.4-0.5,1.2-0.7,1.8
		C429,305.8,429.5,306,430.2,306.2z M453.1,293.6c-1.5,1.1-2.1,2.1-2,3.3c0,0.3,0.4,0.6,0.7,1C452.2,296.4,452.6,295.2,453.1,293.6z
		 M446.8,276.4c0.5,0.7,0.9,1.2,1.2,1.8c0.5,1.1,0.4,1.1,1.6,1.2c-1.4-1-0.5-1.9-0.3-3C448.4,276.4,447.7,276.4,446.8,276.4z
		 M451.5,223.4c0.4,0,0.8,0,1.2,0c0.1-1,0.2-1.9,0.4-3c-0.8,0.4-1.3,0.6-1.5,0.7C451.5,221.9,451.5,222.5,451.5,223.4z M429.4,281.8
		c-1.2,0.7-0.9,1.4-0.7,2.1c0.3,0.1,0.4,0.1,0.5,0.1c0.6-0.1,1.4-0.1,1.6-0.4c0.5-0.9-0.8-0.6-1-1.1
		C429.7,282.3,429.6,282.1,429.4,281.8z M495.3,203.3c-1.6-0.8-1.5,1.3-2.6,1.3c0.6,0.7,1,1.3,1.5,1.8
		C494.6,205.3,494.9,204.3,495.3,203.3z M443.5,252.8c0.3,0.8-0.5,2,1,2.1c0.5-1,0.9-2,1.5-3.3C445,252.1,444.4,252.4,443.5,252.8z
		 M437,348.9c-0.9,0.3-2.3,1.7-2.3,2.3c0.4,0.2,0.9,0.4,1.4,0.7C436.4,350.8,436.7,350,437,348.9z M471.2,319.3
		c1.4,0.6-0.6,2.1,1,2.7c0.5-1.1,1.2-2.1,0.6-3.3C472.4,318.9,471.9,319,471.2,319.3z M573.4,295.4c-0.7,0.2-1.4,0.4-2.1,0.6
		c0.3,0.6,0.5,1.1,0.7,1.6C572.7,296.9,574.3,297.2,573.4,295.4z M440.5,300.5c-0.8,0.9-1.7,1.4-2.2,2.6c1.2-0.4,2.1-0.7,3.2-1.1
		C441.2,301.6,440.9,301.2,440.5,300.5z M583.5,236.2c0.8-2.9,0.7-3.5-0.5-3.7C583.2,233.8,583.4,235,583.5,236.2z M462.2,350.8
		c0,0.5,0,1,0,1.4c2.2-0.3,2.2-0.3,1.8-2.2C463.4,350.3,462.8,350.5,462.2,350.8z M582,201.6c0.1-0.4,0.3-0.9,0.5-1.3
		c0.2-0.4,0.5-0.7,0.8-1.1c-1.5-0.5-2.1,0.4-2.8,1.3C580.9,200.8,581.4,201.1,582,201.6z M444.6,335.4c0.8-0.4,2-2.7,1.9-3.1
		c-0.1-0.3-0.4-0.4-0.8-0.8C445.3,332.9,445,334.2,444.6,335.4z M428.1,293.4c1-0.2,2.1-0.3,2.7-1.5
		C429.2,291.2,428.8,292.5,428.1,293.4z M542.3,342.7c0.1-1.5-1.4-2-2-3.3C539.8,341.4,541.5,341.7,542.3,342.7z M440.3,349.4
		c0.6,0.8,0.9,1.2,1.3,1.8c0.6-0.6,1-1.1,1.6-1.8C442.2,349.4,441.5,349.4,440.3,349.4z M551.8,270.5c-0.3-0.6-0.7-1.2-1.3-2.1
		c-0.3,0.7-0.8,1.3-0.6,1.6C550.2,270.5,550.8,271.3,551.8,270.5z M560.7,318.9c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.6-0.5,1.3,0.6,1.8
		c0.4-0.3,0.8-0.6,1.3-1C561.6,319.6,561.1,319.3,560.7,318.9z M436.6,272c1.2,0.2,1.5-0.8,2.2-1.5
		C436.8,270.4,436.7,270.4,436.6,272z M467.6,318.1c-0.7-1.1-1.2-0.6-1.7-0.3c-0.2,0.2-0.2,0.6-0.3,1.1
		C466.4,318.6,467,318.4,467.6,318.1z M454.2,222.7c1.6-0.8,0.2-1.5,0.4-2.4C453.3,221.2,454,221.9,454.2,222.7z M454.5,288.1
		c0.9,0.3,1.6,0.5,2.4,0.7c-0.3-0.9-0.5-1.5-0.7-2.1C455.8,287.1,455.3,287.5,454.5,288.1z M468.3,273.4c0.3,0.5,0.5,1,0.7,1.5
		c0.7-0.5,1.4-1,1.5-2C469.7,273.1,469,273.3,468.3,273.4z M593.2,198c-1-1.3-1.3-1.5-1.7-1.2c-0.1,0.1-0.3,0.4-0.2,0.5
		C591.7,198,592.2,198.6,593.2,198z M443.3,345.4c-1.3-0.4-1.5-0.3-1.8,1.7C442.2,346.4,442.7,345.9,443.3,345.4z M563.4,266.8
		c0.6,1.5,1.7,1.2,2.6,1.1c0.1,0,0.2-0.5,0.3-0.8C565.3,268.2,564.5,266.7,563.4,266.8z M472,342.4c1,0.6,1.7,0.6,2.1-0.8
		C473.3,341.9,472.7,342.2,472,342.4z M451.5,216.9c0,0.7,0,1.2-0.1,1.7c-0.3,0.9,0.3,0.7,0.8,0.9
		C452.3,217.4,452.3,217.4,451.5,216.9z M486,317.3c-0.7,0.1-1.3,0.2-2.2,0.3C485.2,318.7,485.2,318.7,486,317.3z M421.9,318.7
		c-1.1,1.2-1.1,1.2-0.1,2C421.8,320,421.8,319.4,421.9,318.7z M436.3,305.2c1.6-0.2,1.6-0.2,1.6-1.5
		C437,303.7,436.7,304.3,436.3,305.2z M572.9,258.3c-0.6,1-0.9,1.5-1.2,2.1c0.5,0.3,0.8,0.5,1.2,0.7
		C572.9,260.3,572.9,259.6,572.9,258.3z M443.5,258.9c0.9,0.2,1.5,0.6,1.9-0.3c0.1-0.1-0.1-0.5-0.2-0.5
		C444.4,257.7,444.2,258.3,443.5,258.9z M469.7,313.3c0.4-0.7,0.7-1.1,1.1-1.8c-0.8,0-1.3,0-1.9,0
		C469.1,312.1,469.4,312.6,469.7,313.3z M558.2,258.4c0.1-0.3,0.1-0.7,0.2-1c0-0.3,0-0.5-0.1-1C557.1,257.9,557.1,257.9,558.2,258.4
		z M571.1,291.1c0.1,0.2,0.3,0.3,0.4,0.5c1,0.1,1.1-0.7,0.9-1.7C572,290.3,571.6,290.7,571.1,291.1z M437.4,260.8
		c-0.1,0.2-0.2,0.3-0.4,0.5c0.7,0.4,1.4,0.8,2.4,1.4C439.2,261,438.1,261.2,437.4,260.8z M434.9,281.3c0.2-1.8,0.2-1.8-0.9-1.9
		C434.3,279.9,434.5,280.4,434.9,281.3z M427.7,306.5c-0.6,0.2-1.1,0.4-1.6,0.6c0,0,0.1,0.6,0.2,0.7
		C427.1,307.9,427.5,307.5,427.7,306.5z M557,307.8c0.1,0.8,0.4,1.3,1.1,1c0.1-0.1,0.2-0.5,0.2-0.5
		C557.8,308.1,557.4,307.9,557,307.8z M558.8,313.2c1.4,0,2-0.4,2-2.1C560,311.9,559.5,312.4,558.8,313.2z M449.3,264.2
		c0.1,1.3,0.4,2,2,1.9C450.5,265.3,450,264.9,449.3,264.2z M562,317.2c-0.1,0.2-0.2,0.4-0.3,0.6c0.4,0.2,0.8,0.4,1.3,0.5
		c0,0,0.2-0.3,0.3-0.5C562.9,317.6,562.4,317.4,562,317.2z M531.1,260.2c0.1,0.2,0.2,0.4,0.2,0.6c0.3-0.1,0.6-0.1,0.8-0.3
		c0.3-0.2,0.5-0.5,0.9-1C532.2,259.8,531.6,260,531.1,260.2z M452.3,254.5c-0.5-0.1-0.7-0.2-0.9-0.2c-0.3,0.1-0.6,0.2-0.8,0.4
		c-0.1,0,0.1,0.5,0.1,0.5c0.3,0,0.6,0,0.9-0.1C451.9,255.1,452,254.8,452.3,254.5z M556.8,325.4c0.6-0.4,1.1-0.7,1.1-0.8
		c-0.1-0.4-0.4-0.7-0.7-1.1c0,0-0.2,0-0.4,0.1C556.8,324,556.8,324.4,556.8,325.4z M477,335.7c0.6,0.6,0.9,0.8,1.2,1.1
		c0.3-0.4,0.5-0.7,0.9-1.1C478.4,335.7,477.9,335.7,477,335.7z M443.3,336.9c0.1,0.2,0.2,0.5,0.3,0.7c0.4-0.2,0.8-0.4,1.1-0.6
		c0,0-0.2-0.4-0.3-0.6C444.1,336.5,443.7,336.7,443.3,336.9z M465.7,299.7c0.1,0,0.3,0.1,0.4,0.1c0.2-0.6,0.3-1.3,0.5-2
		C465,298,465.7,299,465.7,299.7z M454.7,295.3c0.4,0.1,0.7,0.2,1.1,0.4c0-0.7,0-1.1,0-1.6c0-0.1-0.2-0.2-0.4-0.4
		C455.2,294.2,455,294.7,454.7,295.3z M434,311.7c0.2,0.1,0.3,0.1,0.5,0.2c0.2-0.5,0.4-0.9,0.5-1.4c-0.2-0.1-0.4-0.2-0.5-0.2
		C433.5,310.5,434,311.1,434,311.7z M470.6,261.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.5-0.4,0.9-0.6,1.4c0.2,0.1,0.7,0.2,0.7,0.2
		C470.3,262.1,470.5,261.6,470.6,261.2z M570.3,267.3c0-0.2,0-0.4,0-0.5c-0.6,0-1.2,0.1-1.8,0.1c0,0.1,0,0.3,0,0.4
		C569.1,267.3,569.7,267.3,570.3,267.3z M573.6,266.8c-0.6,1.1-0.7,1.6,0,1.6C573.6,267.9,573.6,267.5,573.6,266.8z M496.1,313.4
		c0.2,0.1,0.3,0.2,0.5,0.2c0.2-0.4,0.5-0.8,0.7-1.1c-0.3-0.1-0.5-0.3-0.8-0.4C496.4,312.5,496.2,312.9,496.1,313.4z M560.8,207.7
		c0.6,0,1.1,0,1.6,0c0-0.1,0.1-0.2,0.1-0.4c-0.5-0.2-0.9-0.4-1.4-0.7C561,207,560.9,207.3,560.8,207.7z M559.4,212.6
		c0.4,0.3,0.6,0.6,0.8,0.6c0.2,0,0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7C560.1,212,559.8,212.3,559.4,212.6z M471.4,210.2
		c0.9,0.4,1.2-0.2,1.6-0.8C471.9,208.8,471.7,209.6,471.4,210.2z M440.9,328.3c-0.3-0.4-0.4-0.8-0.6-0.8c-0.2,0-0.5,0.2-0.7,0.5
		c-0.1,0.1,0.2,0.5,0.3,0.6C440.1,328.5,440.4,328.4,440.9,328.3z M452.5,270c-1.7-0.3-1.7-0.3-1.8,0.7
		C451.2,270.5,451.6,270.3,452.5,270z M558.6,269.2c-1.2,0.2-1.2,0.2-1.3,1.2C557.6,270.1,557.9,269.8,558.6,269.2z M560.6,205.3
		c-0.1-0.2-0.2-0.3-0.4-0.5c-0.4,0.3-0.8,0.7-1.4,1.1C560,206.4,560.3,205.8,560.6,205.3z M493.5,345.8c-0.5-0.3-0.7-0.6-0.9-0.5
		c-0.2,0-0.4,0.4-0.4,0.7c0,0.1,0.3,0.4,0.5,0.4C492.8,346.3,493,346,493.5,345.8z M558.4,325.6c1.4,0.1,1.1-0.6,1.1-1.3
		c0-0.1-0.2-0.1-0.3-0.2C558.9,324.5,558.7,325,558.4,325.6z M446.2,329c-1.1,0.5-1.1,0.5-0.9,1.6
		C445.6,329.9,445.9,329.4,446.2,329z M505.7,323.1c-0.6,1.1,0,1.3,1,1.7C506.3,324.1,506.1,323.7,505.7,323.1z M586.8,224.5
		c-0.1,1.6-0.1,1.6,0.9,1.7C587.4,225.6,587.1,225.2,586.8,224.5z M555.7,329.9c-0.4-0.3-0.6-0.5-0.9-0.6c-0.2,0-0.5,0.3-0.5,0.4
		c0.1,0.3,0.2,0.6,0.4,0.7C554.9,330.4,555.2,330.2,555.7,329.9z M563,257c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.3,0.3-0.6,0.2-0.8
		c0-0.1-0.4-0.4-0.5-0.4c-0.2,0.1-0.5,0.4-0.6,0.6C562.7,256.4,562.9,256.7,563,257z M423.6,317.6c0.2-0.4,0.4-0.7,0.4-0.9
		c0-0.2-0.4-0.5-0.4-0.5c-0.3,0.1-0.6,0.3-0.6,0.6C422.9,316.9,423.2,317.2,423.6,317.6z M473.3,316.7c-0.5-0.2-0.8-0.5-0.9-0.4
		c-0.2,0.1-0.4,0.4-0.5,0.7c0,0.1,0.4,0.4,0.5,0.4C472.7,317.2,472.9,317,473.3,316.7z M432.8,321c0.1,0.1,0.2,0.1,0.3,0.2
		c0.3-0.3,0.6-0.6,0.8-0.9c0-0.1-0.1-0.3-0.3-0.7C433.2,320.2,433,320.6,432.8,321z M570.7,261.2c0.1,0,0.3-0.1,0.4-0.1
		c0-0.5,0.4-1.2-0.9-1.4C570.4,260.3,570.6,260.7,570.7,261.2z M476.9,318.5c-0.4-0.3-0.7-0.6-0.9-0.6c-0.2,0-0.4,0.4-0.5,0.6
		c0,0.1,0.3,0.4,0.5,0.4C476.2,318.9,476.4,318.7,476.9,318.5z M443.3,261.2c0.8,0.3,1.2-0.1,1.4-0.9
		C443.8,260,443.5,260.5,443.3,261.2z M435.7,260c-0.2,0.1-0.3,0.3-0.5,0.4c0.3,0.3,0.6,0.5,0.9,0.8c0.1-0.1,0.2-0.2,0.3-0.3
		C436.2,260.6,436,260.3,435.7,260z M444.5,262.8c0,0.1,0,0.3,0,0.4c0.5,0,0.9-0.1,1.4-0.1c0-0.1,0-0.2,0-0.4
		C445.4,262.8,445,262.8,444.5,262.8z M448.5,226.4c0.2,0.1,0.3,0.3,0.5,0.4c0.2-0.3,0.4-0.6,0.7-0.9c-0.1-0.1-0.2-0.2-0.4-0.3
		C449,225.9,448.8,226.2,448.5,226.4z M556.8,193.4c0.3,0.2,0.5,0.5,0.9,0.8C558,193.2,557.7,192.9,556.8,193.4z M565.9,317.3
		c0.6,0,1.1,0,1.5,0C567,316.2,566.6,316,565.9,317.3z M482.5,335.6c-0.4,0.4-0.6,0.7-0.9,1.1c0.7,0,1.2,0,1.9,0
		C483.1,336.3,482.8,336,482.5,335.6z M538.3,336.7c0.1,0,0.2,0,0.3,0c0-0.4,0-0.8,0-1.2c-0.1,0-0.2,0-0.3,0
		C538.3,335.9,538.3,336.3,538.3,336.7z M428.6,315.7c0,0.6,0,1,0,1.6C429.8,316.7,429.7,316.2,428.6,315.7z M446.5,336.6
		c0.4-0.4,0.7-0.6,0.7-0.6c-0.1-0.2-0.3-0.5-0.5-0.6c-0.1,0-0.5,0.2-0.5,0.4C446.1,336.1,446.3,336.3,446.5,336.6z M578.7,273.8
		c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.1,0.2,0.2,0.3,0.3
		C578.2,274.3,578.5,274,578.7,273.8z M521,307.8c-0.1,0.2-0.2,0.3-0.3,0.5c0.3,0.1,0.6,0.3,0.9,0.4c0,0,0.2-0.3,0.2-0.3
		C521.5,308.1,521.3,308,521,307.8z M551.9,212.1c0,0.9,0,1.5,0,2.5c0.4-0.8,0.7-1.3,0.9-1.7C552.5,212.6,552.3,212.4,551.9,212.1z
		 M473.1,216.2c-1.1-0.1-1.4,0.2-1.1,1.2C472.4,217,472.7,216.7,473.1,216.2z M457.7,300.7c-0.2-1.3-0.6-1.8-1.9-1.5
		C456.5,299.7,457,300.1,457.7,300.7z M471.3,331.7c-0.2-0.2-0.4-0.5-0.5-0.5c-0.2,0.2-0.5,0.4-0.7,0.6c0.1,0.1,0.3,0.3,0.4,0.3
		C470.8,332.1,471,331.9,471.3,331.7z M583.5,240.4c0.2,0,0.3,0,0.5,0c0-0.5-0.1-0.9-0.1-1.4c-0.1,0-0.3,0-0.4,0
		C583.5,239.5,583.5,239.9,583.5,240.4z M486.9,332.2c0.2-1.1-0.2-1.2-0.9-1.1C486.2,331.5,486.4,331.7,486.9,332.2z M484.1,330.3
		c-0.2,0.3-0.4,0.5-0.4,0.5c0.4,0.3,0.8,0.6,1.1,1c0.1-0.1,0.2-0.2,0.3-0.3C484.8,331.1,484.4,330.7,484.1,330.3z M584.5,243.5
		c-0.5,0.4-0.8,0.7-1.1,1c0.4,0.3,0.7,0.5,1.1,0.8C584.5,244.7,584.5,244.2,584.5,243.5z M485.4,337.7c0.5-1.2,0.2-1.4-0.7-1.2
		C484.9,336.8,485.1,337.1,485.4,337.7z M564.1,316.1c-0.1,1.1-0.1,1.1,0.9,1C564.8,316.8,564.6,316.5,564.1,316.1z M478.7,316.2
		c-0.1,1-0.1,1,0.9,0.8C479.3,316.7,479,316.5,478.7,316.2z M575.5,266.4c-1.1,0-1.1,0-0.8,1C574.9,267,575.2,266.7,575.5,266.4z
		 M553.4,314c1,0.4,1.3,0.2,1.2-0.8C554.2,313.5,553.9,313.7,553.4,314z M427,310.8c-0.3,0.4-0.6,0.7-0.9,1.1
		C427,312,427.4,311.9,427,310.8z M572.6,212.2c-1.3-0.2-1.5,0.1-1.2,1.1C571.7,212.9,572,212.7,572.6,212.2z M481.7,344
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3C481.4,344.4,481.5,344.3,481.7,344z M437.7,345.1
		c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0,0.3-0.1,0.5-0.2C437.9,345.4,437.9,345.3,437.7,345.1z M479.2,342.2
		c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3C479.4,342.5,479.3,342.4,479.2,342.2z M531.8,345.3
		c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5C532.1,345.6,532,345.5,531.8,345.3z M493.3,216.1
		c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3C493.5,216.4,493.4,216.3,493.3,216.1z M472.5,212.8
		c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3C472.2,212.5,472.3,212.6,472.5,212.8z M496.2,340.4
		c-0.4-0.1-0.6-0.2-0.8-0.2c-0.1,0-0.2,0.3-0.3,0.4c0.2,0.1,0.4,0.3,0.6,0.3C495.8,340.9,496,340.6,496.2,340.4z M459.3,339.9
		c0.1-0.3,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4C459,339.5,459.1,339.7,459.3,339.9z M447.4,348.4
		c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3C447.1,348.1,447.2,348.2,447.4,348.4z M467.6,204.3
		c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3C467.3,204.6,467.4,204.5,467.6,204.3z M573.3,199.7
		c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5C573.6,200,573.5,199.9,573.3,199.7z M490.2,350
		c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.3,0.4C489.8,349.8,490,349.9,490.2,350z M494,349.3
		c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.5,0.2,0.8,0.2c0,0,0.1-0.2,0.2-0.3C494.6,349.5,494.3,349.4,494,349.3z M447.3,338.4
		c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3C446.9,338.7,447.1,338.6,447.3,338.4z M584.1,195.5
		c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0.1,0.3,0.2,0.5C583.8,195.7,583.9,195.7,584.1,195.5z M499.4,196
		c0.2-0.2,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.2-0.5c-0.1,0.1-0.3,0.2-0.4,0.3C499.2,195.5,499.3,195.7,499.4,196z M423.9,336
		c0.2-0.4,0.4-0.8,0.7-1.3C423.3,334.6,423.6,335.3,423.9,336z M514.7,352.5c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.1,0-0.2,0.2-0.3,0.4C514.3,352.3,514.4,352.4,514.7,352.5z M473.8,353.2c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
		c0,0.1,0.1,0.3,0.2,0.5C473.4,353.4,473.6,353.3,473.8,353.2z M587.9,191.2c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.3-0.3,0.4
		c0,0.1,0.2,0.3,0.3,0.4C587.6,191.6,587.7,191.4,587.9,191.2z M452.7,353.1c-0.2,0.2-0.3,0.4-0.3,0.5c0,0.1,0.1,0.2,0.2,0.3
		c0.1-0.1,0.4-0.1,0.4-0.3C452.9,353.5,452.8,353.3,452.7,353.1z M500.5,334c-0.6-0.3-0.8-0.4-1-0.4c-0.1,0-0.3,0.2-0.3,0.4
		c0,0.1,0.2,0.4,0.3,0.4C499.7,334.4,499.9,334.3,500.5,334z M482.3,236.7c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0.2,0.1,0.3,0.1
		c0.1-0.2,0.1-0.3,0.2-0.5C482.7,236.8,482.5,236.7,482.3,236.7z M494.4,332.9c-0.1,0-0.2,0-0.4,0c-0.1,0.1-0.2,0.3-0.2,0.3
		c0.1,0.2,0.3,0.3,0.4,0.5C494.3,333.5,494.3,333.2,494.4,332.9z M479.4,240.8c-0.3-0.3-0.5-0.4-0.6-0.5c-0.1,0.1-0.3,0.3-0.3,0.4
		c0,0.1,0.2,0.4,0.3,0.4C478.9,241.1,479.1,240.9,479.4,240.8z M458.4,242.3c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.2,0.3
		c0.1,0.1,0.2,0.2,0.3,0.4C458.2,242.6,458.3,242.5,458.4,242.3z M553,187.3c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
		c0,0.2,0.1,0.3,0.2,0.5C552.7,187.5,552.8,187.4,553,187.3z M556.2,185.8c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4
		c0.2,0,0.3-0.1,0.5-0.2C556.4,186.2,556.3,186,556.2,185.8z M562.1,328.1c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3
		c-0.1,0-0.3,0.2-0.4,0.3C561.8,327.8,561.9,327.9,562.1,328.1z M481.7,326.2c-0.3,0.3-0.5,0.4-0.6,0.6c0.2,0.2,0.3,0.4,0.5,0.4
		c0.2,0,0.5-0.2,0.5-0.4C482.1,326.7,481.8,326.5,481.7,326.2z M557.4,250.7c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1-0.2,0.1-0.3
		c-0.2,0-0.4-0.1-0.5-0.1C557.5,250.3,557.5,250.5,557.4,250.7z M451.8,253c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.2,0.3,0.3,0.4
		c0.1,0,0.3-0.1,0.5-0.2C452,253.3,452,253.2,451.8,253z M458,258.4c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.2-0.3-0.3-0.5
		c-0.1,0.1-0.3,0.2-0.3,0.4C457.7,258,457.9,258.1,458,258.4z M497.9,318.2c-0.2-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2
		c0.1,0.1,0.2,0.3,0.3,0.4C497.4,318.4,497.6,318.3,497.9,318.2z M460.5,262.7c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.1,0.3-0.3,0.3-0.4C460.8,263.1,460.7,262.9,460.5,262.7z M456.1,265.7c-0.3-0.3-0.5-0.4-0.6-0.5c-0.1,0.1-0.3,0.3-0.3,0.4
		c0,0.1,0.2,0.4,0.3,0.4C455.7,265.9,455.9,265.8,456.1,265.7z M477.8,265.8c-0.3-0.1-0.5-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.3,0.3,0.4,0.3C477.4,266.1,477.5,266,477.8,265.8z M485.2,314.4c0.1-0.2,0.3-0.3,0.3-0.5c0,0-0.2-0.2-0.3-0.2
		c-0.2,0-0.3,0.2-0.4,0.2C485,314.1,485.1,314.2,485.2,314.4z M466.2,312.4c-0.2-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2
		c0.1,0.1,0.2,0.3,0.3,0.4C465.7,312.6,465.9,312.5,466.2,312.4z M460.9,294c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5
		c0,0.1,0.2,0.2,0.4,0.3C460.7,294.4,460.8,294.3,460.9,294z M425.3,305.7c-0.3-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.5,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C424.9,306,425,305.8,425.3,305.7z M556.6,290.7c-0.1,0.2-0.2,0.3-0.2,0.5c0.4,0.2,0.9,0.3,1.3,0.5
		c0-0.1,0.1-0.2,0.1-0.4C557.4,291.1,557,290.9,556.6,290.7z M539.5,292.1c0.1,0,0.2,0,0.4,0c0.1-0.1,0.2-0.3,0.2-0.3
		c-0.1-0.2-0.3-0.3-0.4-0.5C539.6,291.6,539.5,291.8,539.5,292.1z M460.9,292.4c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4
		c0,0.1,0.2,0.3,0.2,0.4C460.6,292.6,460.8,292.5,460.9,292.4z M474.8,295.7c-0.1,0.2-0.2,0.4-0.3,0.5c0,0,0.2,0.2,0.3,0.2
		c0.2-0.1,0.3-0.2,0.4-0.3C475.1,296,475,295.9,474.8,295.7z M563.1,292.2c0-0.2,0-0.3,0-0.5c-0.3,0-0.6,0.1-0.8,0.1
		c0,0,0,0.3,0,0.3C562.6,292.2,562.9,292.2,563.1,292.2z M563.2,294.7c-0.2,0.3-0.3,0.5-0.4,0.7c0.2,0.1,0.4,0.3,0.6,0.3
		c0.2,0,0.3-0.3,0.4-0.5C563.6,295.1,563.5,294.9,563.2,294.7z"/>
	<path class="st0-bull" d="M282.5,226c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.5,0.6-0.7,0.9c0.1,0.1,0.2,0.2,0.3,0.3c0.5-0.1,1-0.1,1.5-0.2
		c0.4,1.1-0.3,1.2-1.1,1.2c-1.4,0-2.9,0-4.4,0c-0.2-0.4-0.4-0.9-0.6-1.4c-0.6,0-1.3,0-2,0c0.1,0.4,0.1,0.8,0.2,1.2
		c-1.8,0-3.5,0-5.5,0c0.8-4.5,2.2-8.6,3.2-12.9c0.4,0.1,0.8,0.3,1.1,0.3c0.2,0,0.5-0.2,0.6-0.4c0.1-0.2-0.1-0.6-0.2-0.7
		c-1.4-0.3-0.9-1.3-0.7-2.1c0.7-2.9,1.4-5.8,2.2-8.6c0.8-2.7,1.9-5.4,2.3-8.1c0.4-2.9,1.3-5.7,2-8.6c0.2-1.1,0.7-2.1,1-3.2
		c0.9,0,1.8,0,2.8,0c-0.4,1.3,0,2.4,1.5,3.1c-0.2-1-0.3-2-0.5-3.2c1.9,0,3.6,0,5.2,0c1.4,0,2.8,0,4.3,0c30,0,60,0,90,0
		c0.8,0,1.8-0.5,2.4,0.6c0,0.1,0.4,0.1,0.5,0c0.5-1,1.5-0.6,2.2-0.6c9.2,0,18.4,0,27.6,0c2.3,0,4.6,0.3,6.9,0.3
		c1.6,0,3.3-0.2,4.9-0.3c0.7,0,1.4,0,2.2,0c-0.1,0.7-0.1,1.2-0.3,1.6c-1.3,5.2-2.7,10.4-4,15.6c-3.6,14.3-7.1,28.7-10.6,43
		c-0.2,1-0.7,1.7-1.6,2.3c-4.8,2.9-9.7,5.9-14.5,8.9c-0.2,0.1-0.5,0.2-0.9,0.4c-0.3-2.4-0.2-4.6,0.2-6.9c0.2-0.9,0.7-1.9-0.7-2.4
		c-0.1,0-0.3-0.2-0.2-0.3c0.4-1.5-1.3-3-0.2-4.5c0-0.1,0.1-0.1,0-0.2c-0.2-2-0.4-4-0.7-6c-0.1-0.8-0.2-2.3,0-3.2
		c0.4-1.4,0.1-2.7-0.6-3.8c-0.2,0-0.3,0-0.5,0c0,1,0.1,2,0,3c-0.1,0.8-0.3,1.6-0.4,2.3c-0.1,0.4-0.2,1,0,1.2c0.8,0.9,0.5,2,0.5,3
		c0.1,4.5,1,8.9,0.6,13.4c-0.1,0.9-0.3,1.7-0.4,2.6c0,0.3-0.1,0.7,0.1,1c0.8,1.2,0.2,2.1-0.8,2.8c-0.6,0.4-1.1,0.8-1.7,1.2
		c-3.4,2.1-6.8,4.2-10.2,6.3c-0.7,0.4-1.5,0.9-1.4,1.8c2.8,3.4,5.6,6.8,8.5,10.3c-1,0.5-1.8,0.8-2.6,1.2c0.7,0.8,0.7,0.8,2.3-0.3
		c1,0.8,2,1.5,3,2.4c0.4,0.4,0.5,1.3,0.9,1.7c3.1,2.9,5.7,6.3,8.4,9.5c0.6,0.8,0.6,1.4,0.4,2.3c-0.9,3.3-1.9,6.6-2.7,10
		c-1,3.9-1.7,7.8-2.7,11.7c-3.1,12.7-6.3,25.4-9.5,38.2c-0.1,0.3-0.1,0.5-0.2,0.9c-1.5,0.1-2.1-1-2.6-2.1c-0.9,0.9-0.1,1.5,0.2,2.4
		c-0.9,0-1.6,0-2.4,0c-10.7,0-21.4,0-32.1,0c-7.6,0-15.3,0-22.9,0c-3.5,0-7-0.2-10.5-0.2c-5.7,0-11.3,0.2-17,0.2c-2.8,0-5.6,0-8.5,0
		c-0.5,0-0.9-0.4-1.5-0.7c-0.2,0.6-0.8,0.8-1.7,0.8c-3-0.1-5.9-0.1-8.9-0.1c-3.2,0-6.3,0.1-9.5,0.1c-2.8,0-5.6,0-8.3,0
		c-0.2-0.6-0.2-1.2-0.5-1.3c-0.9-0.3,0.1-0.9-0.2-1.1c-0.4-0.3-0.9-0.5-1.7-0.9c0.7-0.8,1.2-1.4,1.7-1.9c-1.3-0.8-2.1,0.2-2.9,1.2
		c-0.1-0.1-0.2-0.1-0.4-0.2c0.1-1.3,0.1-2.5,0.4-3.8c0.2-1.1,0-1.6-1.1-1.7l0.1,0.1c0.1-0.9,0.2-1.8-1.1-2c-0.2,0-0.4-0.4-0.6-0.5
		c0.1-0.1,0.1-0.3,0.2-0.3c0.7-0.2,1.2-0.5,0.9-1.3c-0.1-0.2-0.5-0.5-0.5-0.4c-0.4,0.3-0.8,0.6-1.1,1.1c-0.4,0.6-0.3,1.1,0.6,0.9
		c-0.7,1.3-1,2.5-0.1,3.8l0.1,0c-0.4,0-0.8,0-1.2,0.1c-1.3,0.3-2.7-0.3-3.4-1.4c-2.4-4.1-4.9-8.2-7.3-12.3c-0.1-0.2-0.3-0.5-0.4-0.7
		c-0.2-0.8-0.1-1.6,0.9-1.7c1.4-0.2,1.7-1,1.4-2.3c-0.5-0.1-1-0.3-1.5-0.4c0-0.3,0-0.5,0-0.6c1.5-6.1,3-12.2,4.6-18.3
		c0.5-2,1.1-4.1,1.5-6.1c0.2-1.1,0.6-2,1.1-3c0.5-1,0.3-2.3,0.5-3.5c0.2-1.1,0.7-2.2,1-3.3c0.3-1.1,0.3-2.4,0.7-3.4
		c0.8-1.9,0.5-4.1,1.9-5.7c-0.4-1.5,0.3-2.8,0.7-4.2c1.6-6.4,3.1-12.9,4.7-19.3c0.2-0.9,0.6-1.6,1.4-2.2c2.8-2,5.5-4.1,8.2-6.2
		c1.2-1,2.3-2.1,3.5-3.2c0.4-0.4,0.9-0.7,1.5-1.1c0.4,1.7,0.4,3.2,0.1,4.7c-0.5,2.2-0.8,2.5-1,2.7c-0.1,1.5-0.2,2.9-0.3,4.2
		c0,0.4,0,0.8,0,1.1c0,0.3,0,0.6-0.2,0.9c-0.6,0.9-0.7,2.7-0.2,4c-0.6,0.1-1.1,0.3-1.7,0.4c0.5,2,2.4,0.9,3.4,1.5
		c0.1,1.1,0.1,2.2,0.3,3.2c0.2,1,0.6,2,1,3.1c0.6,0.1,1.3,0.3,2.4,0.6c-0.9-1.1-1.5-2-2.2-2.9c0.5-0.9,1.4-1.7,0.2-2.7
		c1.7-0.7,0.1-2.5,1.3-3.4c-1-0.4-1.7-0.7-2.5-1.1c0.7-1.7,2.1,0,3.1-1.1c-0.1-0.3-0.2-0.9-0.4-1.4c-0.5-1.1-1-2.2-1.4-3.3
		c-0.1-0.4-0.2-1,0.1-1.2c1-1.1,0.6-2.1-0.2-3.3c2.3-0.9,1-2.9,1.5-4.5c0.4,0,0.8,0.1,1.3,0.1c0.2-0.4,0.4-0.8,0.8-1.4
		c-0.3-0.2-0.7-0.5-1.2-0.8c0.7-0.8,1.2-1.3,1.7-1.8c-0.2-0.5-0.4-1-0.5-1.3c0.8-0.8,1.5-1.4,2.1-2.1c1.3-1.5,3.3-1.9,4.9-2.8
		c0.1-0.1,0.4,0,0.5,0c1,0.2,1,0.2,1.8-0.3c0.7,0.4,1.2,0.8,0.6,1.8c-0.3,0.6,0.4,0.7,0.9,0.6c0.3-1.6,0.6-3.3,1-5.4
		c-1,1-1.7,1.7-2.4,2.4c-0.3-0.2-0.8-0.4-1.2-0.6c0-0.2,0-0.4,0.1-0.5c0.7-0.5,1.6-0.8,2-1.5c0.7-1.3,1.5-1.1,2.5-0.5
		c-0.6-2.6,1.7-2.8,2.9-4.2c0.2,0.9-0.4,1.8,0.9,1.9c0.1-1.1,0.3-2.3,0.2-3.4c0-0.4-0.7-0.9-1.1-1c-0.6-0.1-1.4,0-2,0
		c-0.8,0-1.6,0-2.5,0c-0.1-0.9-0.1-1.6-0.2-2.5c-1.1,0.1-2.1-1.2-3.3-0.1l0-0.1c-1.5,0.4-2.2-0.9-3.3-1.6c0.2-0.4,0.4-0.8,0.5-1.1
		c-0.4-0.5-0.7-1-0.9-1.3c-0.1-1.7,2.5-1.7,1.8-3.1c-1.1-1.6-2.8,0-3.8-1.5c-0.7-1.1-2-1.8-3-2.8c-0.2,0.4-0.3,0.5-0.4,0.7
		c-0.1,0.2-0.2,0.4-0.3,0.7c-1.7-0.7-1.7-0.7-2.1,0.3c1.8,0.4,2.8,1.5,2.8,3.3c0,0.9,0.5,1.6-0.1,2.7
		C279.2,224.1,280.7,225.6,282.5,226L282.5,226z M301.3,307.4c-0.9,1-0.9,1.1,0,2.3C301.2,308.8,301.2,308.1,301.3,307.4
		c0.2-0.3,1.1-0.4,0.2-1.2C301.4,306.7,301.3,307.1,301.3,307.4z M290.9,243c0.1,0.3,0.2,0.7,0.3,1.3
		C291.9,243.3,291.2,243.2,290.9,243c-0.2-0.2-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.1-0.8-0.2c0.2,0.5,0.4,0.8,0.8,1
		C290.5,243.1,290.7,243,290.9,243z M286.8,248.1c-0.7,0-1.3-0.1-2.4-0.1C286.1,249.2,286.2,249.2,286.8,248.1
		c0.6-0.6,0.6-0.6,0.1-1.3C286.8,247.3,286.8,247.7,286.8,248.1z M303.5,314.2c0.2,0.9,0.2,0.9,1.5,0.5
		C304.4,314.5,303.9,314.4,303.5,314.2c-0.5-0.7-0.7-1.8-2.1-1.3C302.1,313.4,302.2,314.6,303.5,314.2z M260.8,313
		c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4,0-1-0.3-1.2-0.1c-0.7,0.5,0,0.7,0.4,1c0,0,0,0.3-0.1,0.3c-1.1,0.4-0.2,1.3-0.9,2.1
		c1.1-0.4,2-0.3,2-1.5c0-0.3,0.6-0.6,0.9-0.8C261.3,313.8,261,313.4,260.8,313c0.8,0.4,1.5,0.2,2-0.7c-0.6-0.2-1.1-0.5-1.6-0.7
		C261.1,312.1,261,312.5,260.8,313z M257.9,305.9c0.4-0.9-0.2-1.1-0.8-1.4C256.6,305.6,257.6,305.5,257.9,305.9
		c0.3,0.4,0.8,0.7,0.9,1.2c0.3,1,0.4,2.1,0.6,3.4c0.5-0.3,1-0.5,1-0.7c0.1-0.9,0-1.8-0.3-2.7C259.6,305.7,259,305.5,257.9,305.9z
		 M310.8,347.2c0.2,0.4,0.5,0.8,0.7,1.2c1.4-0.5,2.5-1.2,2.3-3c-0.7,0.4-1.3,0.7-1.8,1c-0.6-0.3-1.1-0.6-1.9-1
		C310.5,346.2,310.7,346.7,310.8,347.2c-0.6,0.2-1.2,0.4-1.8,0.6C310,348.4,310.8,348.5,310.8,347.2z M282.7,259.1
		c-0.3,0.3-0.5,0.6-0.9,1.1c1.1,0.2,0.8,2,2.7,1.7C283.8,260.8,283.7,259.7,282.7,259.1c0-0.5,0.4-1.3,0.1-1.6
		c-0.8-1-1.9-1.7-2.9-2.6C279.8,257.2,281,258.4,282.7,259.1z M263.5,342.2c0,0.4,0,0.8,0,1.2c0.8,0.9,0.8,0.9,2.4-0.7
		c-0.4-1.4-0.7-2.9-1.2-4.8c-0.6,0.6-1,0.9-1.4,1.2C264.2,340.3,264.8,341.3,263.5,342.2z M287.7,268.7c-0.1,1.1-1.5,2-0.6,3.4
		c0.9,0.2,1.7,0.1,2.2-1.2C288.8,270.2,288.2,269.5,287.7,268.7c-0.1-0.9-0.1-1.8-0.1-2.7c-0.4-0.1-0.8-0.2-1.2-0.3
		c0.1-0.5,0.3-0.9,0.5-1.6c-1.5,0.1-1.9-2.3-3.8-1.1c0.4,2,1.6,3,3.4,3.3c-0.1,0.8-0.2,1.5-0.3,2.4
		C286.9,268.7,287.3,268.7,287.7,268.7z M260.5,320.1c0.2,0.5,0.3,1,0.6,1.4c0.3,0.4,0.7,0.7,1.2,1.2c-0.7,0.2-1.3,0.4-1.7,0.7
		c-0.6,0.4-1.1,1-1.6,1.5c0.5,0.4,0.9,0.8,1.4,1.2c0.6-0.7,1-1.7,1.8-2.1c1-0.5,1.5-1.5,1.8-2.2c0.5-1.1,1.5-0.6,2.2-1.2
		c1.2-1.2,2.5-2.5,3.7-3.9c-1.8,0.5-3.6,1-5.4,1.5c0.6,1.2-0.3,1.3-1.2,1.4C262.4,319.9,261.4,319.2,260.5,320.1
		c-0.7,0.6-0.7,0.6-0.1,1.3C260.5,320.9,260.5,320.5,260.5,320.1z M309.5,281.6c-0.2-0.2-0.7-0.5-0.7-0.7c0.1-1.5-0.4-2.9,0.5-4.4
		c0.6-0.9,0.3-2.3,0.8-3.3c0.4-0.8,0.5-1.5,0.5-2.3c0.1-1.8,0.3-3.6,0.4-5.4c0.1-2.2,0.6-4.5,0.2-6.7c-0.2-1.5-0.1-2.5,0.9-3.4
		c-0.9-1.3-0.5-2.5-0.1-3.7c-1.4-1.5-1.4-1.5-1.4-3.5c0-1.2,0.3-2.5-0.2-3.5c-0.5-1.1-0.5-2.1-0.5-3.2c0-2.4,0-4.8,0-7.3
		c0-1-0.2-2,0.6-2.9c0.1-0.2-0.1-0.7-0.2-1.1c-0.3,0.1-0.5,0.2-0.5,0.2c-0.1,1.6-1.9,2.9-0.6,4.6c0.1,0.1,0.1,0.4,0,0.6
		c-1.2,2.8-0.7,5.7-0.4,8.5c0.2,2.3,0.5,4.6,0.6,6.9c0,1.8-0.3,3.6,0.5,5.3c0.2,0.4-0.1,1-0.2,1.6c0,0.3-0.3,0.6-0.2,0.8
		c1.1,2-0.5,4-0.2,6c0.1,0.6-0.3,1.4-0.2,2.3c0.1,0.9-0.3,1.8-0.4,2.7c-0.1,0.5,0.1,1.1-0.1,1.6c-0.7,1.8-0.3,3.9-1.6,5.6
		c-0.2,0.3-0.1,0.8-0.1,1.2c0.1,1.4-0.9,2.5-1.6,3.4c-0.5,0.6-1.8,1.7-3,0.4c-0.2-0.2-0.7-0.3-1-0.1c-1,0.5-2,1.1-3,1.7
		c0,0.1,0.1,0.3,0.1,0.4c2.3,0,4.7,0,7.3,0c0.2,0.9,0.4,2,0.6,2.9c-0.9,0.4-1.5,0.7-2.2,1.1c-0.4-0.7-0.8-1.2-1.1-1.8
		c-0.6,0.3-1.2,0.5-1.7,0.8c0,1.6,0.2,1.8,1.4,2c0.7,0.1,1.5,0.2,2.2,0.3c0.2,0,0.3,0.1,0.5,0.1c0.2-0.5,0.3-0.9,0.5-1.6
		c1,0.7,1.9,1.3,2.7,1.9c1.8-1.5,1.7-2.1-0.4-3.2C307.8,284.5,308.4,283,309.5,281.6c0.8,0,1.7,0.1,2.5,0.2c0.1,0.6-1.1,1.6,0.6,1.8
		c0.3-0.7,0.6-1.5,1.1-2.1c0.3-0.4,0.9-0.8,1.4-1c0.9-0.3,1.9-0.5,2.8-0.7c-1.3-1.2-3,1.1-4.1-0.8c-1.1,0.7-2,2-3.7,1.7
		C310,280.7,309.7,281.3,309.5,281.6z M350,289.7c0.5,0.2,1,0.4,1.4,0.6c-0.3,0.6-0.5,1.1-0.7,1.8c-0.7-0.2-1.4-0.3-2.1-0.5
		c-1.1,0.9-1.1,1-2.5,0.9c-1.8,0-3.6-0.2-5.5-0.2c-2.2,0-4.4,0.2-6.6,0.3c0.2-0.7,0.3-1.1,0.4-1.7c-1.5,0-2.1-2.4-4-1.1
		c0.4,1.2,2.6,0.8,2.3,2.6c-7.9,0-15.8,0-23.6,0c0-0.4,0-0.7,0-1c-0.2,0-0.5,0-0.7,0.1c-0.2,0.2-0.4,0.7-0.6,0.8
		c-1.3,0.3-1.4,1.3-1.7,2.3c-0.4,1.3-0.9,2.6-1.4,3.9c-0.3,0.9-1,2.1-0.7,2.6c0.7,1.1,0.2,2,0,2.9c-0.4,1.8-1,3.6-1.4,5.4
		c-0.3,1.2,0.1,1.6,1.3,1.7c0.5,0,1,0,1.4,0c8.9,0,17.8,0,26.7,0c1,0,2,0.1,3,0.1c3,0,6.1-0.1,9.1-0.1c1.8,0,2.1-0.1,2.6-1.8
		c0.6-2.3,1.2-4.6,1.8-7c0.7-3,1.4-6,2.1-9c0.1-0.2,0.2-0.5,0.3-0.7c1.7-2.7,1.8-3,2.1-5.6c0-0.4,0.2-0.8,0.4-1.1
		c0.4-0.7,0.3-1.4,0-2.3c-0.4,0.2-0.7,0.4-0.7,0.5c0,0.6,0.4,1.2-0.5,1.5c-0.1,0-0.1,0.5,0,0.8c0.3,1.6,0.4,1.6-1.3,2.1
		C350.3,288.5,349.6,288.7,350,289.7c-0.8,0-0.8,0-0.6,0.8C349.6,290.2,349.8,289.9,350,289.7z M341,247.2c0.3,0.5,0.5,1.1,1.4,0.2
		C341.8,247.3,341.4,247.3,341,247.2c0.6-1.9,2.3-2.5,4.1-2.7c0.9-1.8,3-2.9,4-2.4c1-0.8,1.7-1.7,2.7-2.2c4.8-2.9,9.6-5.7,14.4-8.5
		c0.5-0.3,1-0.5,1.4-0.9c0.6-0.6,0.4-1.3-0.5-1.5c-0.4-0.1-0.8-0.1-1.2-0.1c-1.6,0-3.2,0-4.8,0c-1.6,0-3.2,0-4.8,0
		c-1.6,0-3.2-0.1-4.8,0c-1.5,0.1-2.9-0.6-4.5-0.1c-0.5,0.2-1.2-0.2-1.5-0.3c-0.4-2.6-0.8-4.8-1.2-7.3c-1,1-1.2,2-0.9,3.2
		c0.2,0.8,0.5,1.6,0.7,2.4c0.3,1.1-0.1,1.8-1.1,2c-0.5,0.1-1,0-1.4,0c-5,0-9.9,0-14.9,0c-0.8,0-1.5,0-2.3,0c-2,0-2,0-2.5,2
		c0,0.1-0.1,0.3-0.1,0.4c-1.6,6.6-3.3,13.2-4.9,19.9c-0.6,2.3-1.1,4.7-1.7,7c-0.2,0.7-0.2,1.5,0.6,1.8c0.5,0.2,1.2,0.1,1.7-0.1
		c1-0.5,1.9-1.1,2.9-1.6c6.1-3.6,12.1-7.1,18.2-10.7C339.5,247.1,340.2,246.7,341,247.2z M266.9,349.4c0-1.1-1.2-2.3-2.1-2.3
		c-1.3,0-2.3-0.4-3.2-1.3c-0.3-0.3-1-0.3-1.8-0.5c0.8,1.3,0.9,2.2,0.2,3.2c-0.1,0.2,0,0.6,0.1,0.9c0.3,0,0.7,0,0.9-0.1
		c0.5-0.3,0.8-0.7,1.4-1.1c0.8,0.5,1.6,1.1,2.4,1.6c1.2,0.8,2.3,1.9,3.9,1.5c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
		c0,0.4,0,0.7,0.1,1.1c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.2,0.3-0.6,0.4-0.9c-0.5-0.4-1.3-0.7-1.4-1.1
		C268.4,349.2,267.5,349.8,266.9,349.4z M276.2,287.5c1.1,0.3,1.8,0.4,2.6,0.6c0.1-0.4,0-0.9,0.2-1.2c0.7-1.1,0.1-2.3-0.5-2.9
		c-0.8-0.8-0.9-1.5-0.8-2.4c0.2-1.5-0.8-2.8-0.1-4.3c0.3-0.7,0-1.7-0.1-2.7c-0.6,0-1,0.1-1.5,0.1c-1,0.9,1,2.4-0.6,3.1
		c0.5,1.6,1.6,3.2,0.6,4.9c0.3,0.1,0.6,0.2,0.9,0.4c-0.2,0.4-0.3,0.7-0.5,1c0.4,0.5,0.8,1,1.1,1.4
		C277.1,286.2,276.7,286.7,276.2,287.5z M284.4,269c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.1-0.9,0.2-1.3,0.3c-0.9-1.4-1.4-1.4-2.5-0.1
		c0.4,1.7,2.9,1.8,2.6,4C285.4,272.1,283.3,270.1,284.4,269z M316.6,343.4c1.8-0.5,3-1.9,4.7-1.7c0.1,0,0.4-0.2,0.4-0.4
		c0.3-1.1,1.3-1.5,2.2-1.9c1.1-0.5,0.8-1.1,0.2-1.9c-1,0.4-1.8,0.8-2.4,1.8c-0.6,1-1.5,1.7-2.7,2.1c-0.7,0.2-1.3,0.6-2,1
		C316.8,342.5,316.8,342.9,316.6,343.4z M285.8,255.3c0.7-0.7,1.4-1.3,2.1-2c-0.1-1.6-0.8-2.2-2.6-2.1c0.4,0.6,0.8,1.2,1.1,1.7
		C284.7,254,284.7,254.2,285.8,255.3z M281.8,264.1c-0.9-0.4-1.6-0.3-2,0.5c-0.5,0.9-0.1,1.6,1.5,2.9c0.6-0.4,1.2-0.8,2.1-1.4
		c-0.9-0.2-1.4-0.4-1.9-0.5C281.6,265.1,281.7,264.6,281.8,264.1z M292.6,283.1c-1.1,0.8-2.4,1.1-2,2.8c1.7-0.6,3.2-1.1,4.7-1.6
		C294.4,283.7,293.1,284.8,292.6,283.1z M362.9,290.2c-1.6-0.3-3.2-0.5-4.7-0.8c-0.4-0.1-0.9-0.1-1.2,0c-0.3,0.1-0.4,0.5-0.7,0.8
		c0.7-0.1,1.3-0.3,1.7-0.1C360.3,291.4,360.3,291.4,362.9,290.2z M259.1,343.2c0.2,0.1,0.5,0.1,0.7,0.2c1.3-1.7,0.5-3.7,1.1-5.6
		c-1.1,0.5-1.6,1.2-1.2,2.4c0.2,0.7,0.2,1.3-0.4,1.9C259.1,342.3,259.2,342.8,259.1,343.2z M312.4,336.2c2,0.8,1.9-1.9,3.4-1.8
		c0.1,0,0.2-0.5,0.2-0.7c-1.1-0.5-2.2-0.4-2.4,0.7c-0.2,1.1-1.1,0.1-1.3,0.7C312.4,335.4,312.4,335.8,312.4,336.2z M282,251.9
		c2.2-1.5,2.3-2,0.8-3.3c-0.4,0.5-0.8,1-1.3,1.5C280.9,250.9,281.5,251.4,282,251.9z M345.4,215.7c-1.8,0.7-1.9,1.2-0.9,2.7
		c-0.7,0.6-0.7,1.2-0.2,2C346.2,219.1,344.6,217.4,345.4,215.7z M267.2,327.9c-1.5-0.1-2.4,0.5-2.8,1.6c-0.3,0.9,0.2,1.2,1.1,1.5
		C264.8,329.1,267.3,329.3,267.2,327.9z M275.4,319.8c0.2-0.9,0.2-0.9,1.4-1.8c0.2-0.1,0.3-0.6,0.3-0.7c-0.2-0.2-0.6-0.5-0.8-0.4
		c-1.2,0.4-2,1.2-2.3,2.6C274.4,319.6,274.8,319.7,275.4,319.8z M285.9,259.1c0.3-0.4,0.5-0.6,0.6-0.7c-0.4-0.6-0.8-1-1-1.5
		c-0.3-0.7-0.6-1.4-1.6-1.2C283.7,257.3,284.9,258,285.9,259.1z M253.5,345.8c0.4-0.2,0.9-0.4,1.7-0.7c-1.4-0.4-1.5-2.2-3.3-1.8
		C252.4,344.2,252.9,345,253.5,345.8z M292.4,290.1c0.4,0.7,0.7,1.3,1.1,1.9c0.3,0.5,0.6,0.9,0.9,1.4c0.1-1.2,0.1-2.2,0.2-3.3
		C293.9,290.1,293.2,290.1,292.4,290.1z M297.5,311.7c0.3-1.6,0.6-2.9,1-4.6C296.5,309,296.2,310.1,297.5,311.7z M263.3,337
		c1.8-0.7,1.8-0.7,0.3-3.9C263.8,334.8,262.5,335.8,263.3,337z M269,354.8c-0.9-1.3-2.3-1.5-3.6-2.2
		C266.1,354.3,267.2,354.9,269,354.8z M282.6,255.2c0.6-1.1,1.9-1.2,1.5-2.4c-0.8,0-1.5,0-2.5,0C282,253.6,282.2,254.3,282.6,255.2z
		 M259.4,301.9c-0.2,0.9-0.4,1.7-0.6,2.8c0.9-0.2,1.6-0.3,2.4-0.5C260.6,303.2,260.9,302.1,259.4,301.9z M259.2,319.9
		c-0.2-0.2-0.3-0.4-0.5-0.6c-0.9,0.6-1.8,1.1-2.6,1.7c-0.2,0.2-0.2,0.7-0.3,1c0.4,0,0.8,0.1,1-0.1
		C257.7,321.2,258.5,320.5,259.2,319.9z M340.7,217.1c-1.4-0.6-1.4,1.3-2.6,1.2c0.6,0.8,1.1,1.4,1.5,1.9
		C340,219.1,340.3,218.2,340.7,217.1z M276.3,297.6c0-0.1,0.1-0.3,0.1-0.4c-0.5-0.5-1-1-1.6-1.5c-0.6,0.4-1.2,0.9-0.6,1.9
		C274.9,297.6,275.6,297.6,276.3,297.6z M317.2,333.1c0,0.4,0.2,1,0.1,1.2c-0.8,0.9,0,1,0.5,1.4c0.3-1.1,1.5-2,0.6-3.3
		C317.9,332.7,317.6,332.9,317.2,333.1z M291.2,345.7c-0.3,1.3-1.3,2.4-0.8,3.8C291.2,348.3,292.3,347.2,291.2,345.7z M349,192.2
		c0.9,0.5,1.5,1,2.2,1.4c0.5-0.8,1-1.5,1.6-2.3C351.4,191.6,350.4,191.9,349,192.2z M291.2,265.7c-0.8,0.3-1.4,0.5-2,0.8
		c0.2,0.8-0.4,1.7,0.7,2.3C290.4,267.8,290.7,266.9,291.2,265.7z M318.8,326.7c1,0,1.6,0,2.3,0c0-0.8-0.1-1.4-0.1-2.1
		c-0.1,0-0.3,0-0.4-0.1C320.1,325.2,319.5,325.8,318.8,326.7z M297.1,234.9c-0.1,0.8-0.1,1.4-0.2,2.2c0.4,0,0.8,0,1.2,0
		c0.1-0.9,0.2-1.7,0.3-2.7C297.8,234.6,297.4,234.8,297.1,234.9z M290.6,202.2c2.3,0.8,2.4,0.8,3.3-0.9
		C292.6,201.6,291.6,201.9,290.6,202.2z M283.8,316.9c1.2-0.4,2.1-0.7,3.1-1.1c-0.4-0.5-0.6-0.8-0.9-1.2c-0.7,0.6-1.3,1-1.8,1.5
		C284,316.1,284,316.4,283.8,316.9z M349,215.4c0.6,0.3,1.1,0.6,1.8,0.9c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.1,0.1-0.2,0.2-0.3
		C350.2,214.2,350,214.1,349,215.4z M284.7,193.1c-1.7-1.3-1.7-1.3-2.6,0C283,193.1,283.8,193.1,284.7,193.1z M313.7,287.3
		c0.2,0.5,0.5,0.9,0.7,1.5c0.8-0.6,1.6-0.9,1.6-2C315.2,286.9,314.5,287.1,313.7,287.3z M338.3,281.8c1,0.6,2.1,0.7,3.1,0
		C340.4,281.8,339.4,281.8,338.3,281.8z M279.7,206.1c1.2-0.5,2.2-0.9,3.2-1.3C280.2,204.3,280.2,204.3,279.7,206.1z M284.2,284.4
		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6,0.2-1.2,0.4-1.7,0.7c-0.2,0.1-0.2,0.7-0.2,1c0.3,0,0.7,0.2,0.9,0
		C283.4,285.3,283.8,284.8,284.2,284.4z M329.4,332.1c1.1,0.2,2,0.5,2.3-0.9C330.8,331.1,329.9,330.6,329.4,332.1z M327.9,323
		c0.9,1,1.8,0.9,2.7,0.1C329.7,321.5,328.8,323.3,327.9,323z M284.1,317.9c-0.6-0.3-0.9-0.6-1-0.5c-0.5,0.4-0.9,0.9-1.3,1.4
		c0.2,0.2,0.5,0.5,0.6,0.5C282.9,318.9,283.4,318.5,284.1,317.9z M283,197.6c-0.6-0.2-1.1-0.4-1.6-0.6c0,0.9,0,1.6,0,2.4
		c0.1,0,0.2,0.1,0.4,0.1C282.2,198.9,282.6,198.3,283,197.6z M282.8,274.6c0.3,1.3,1.3,1.2,2.1,1.3
		C284.6,274.8,283.7,274.9,282.8,274.6z M273.6,307.1c1-0.2,1.9-0.4,2.7-1.3C274.9,305.4,274.2,306.1,273.6,307.1z M298.6,255.4
		c0.1,0,0.3,0,0.4,0c0-0.6,0.1-1.2,0-1.8c0-0.2-0.5-0.3-0.8-0.4c-0.1,0.2-0.4,0.5-0.3,0.7C298.1,254.3,298.4,254.8,298.6,255.4z
		 M394.2,187.8c0.5-0.8,0.7-1.2,1-1.6c-0.3-0.4-0.5-0.7-0.8-1c-0.3,0.2-0.6,0.3-0.7,0.3C393.9,186.2,394,186.8,394.2,187.8z
		 M325,321.8c-0.8,0.6-1.2,0.9-1.6,1.2c0.8,1.3,1.8,1,2.8,0.6c-0.5-0.1-1-0.2-1.5-0.3C324.8,322.9,324.9,322.6,325,321.8z
		 M259.3,339.8c-0.7-0.8-1-1.1-1.4-1.6c-0.3,0.7-0.5,1.2-0.8,1.9C257.8,340,258.3,339.9,259.3,339.8z M272,265.2
		c-1-0.1-1.8-0.3-2.1,0.9c0.4,0.2,0.8,0.4,1.3,0.6C271.5,266.2,271.7,265.8,272,265.2z M353.4,294.6c-0.2,1.1-1,1.9,0.1,2.6
		C354.7,296.5,353.9,295.7,353.4,294.6z M275,273.9c-1.9-0.6-1.9-0.6-2.1,0.5C273.6,274.2,274.3,274.1,275,273.9z M300.5,302.2
		c0.7,0.1,1.3,0.2,2,0.3c-0.3-0.8-0.5-1.4-0.7-2.2C301.3,300.9,301,301.4,300.5,302.2z M297.4,267.9c-0.5,0.2-0.9,0.4-1.4,0.6
		c0.1,0.2,0.1,0.6,0.3,0.6C297,269.4,297.3,268.9,297.4,267.9z M299.8,236.5c0.9-1,0.9-1,0-2C298.9,235.1,299.8,235.7,299.8,236.5z
		 M273.2,320.9c-0.1-0.2-0.1-0.5-0.2-0.7c-0.5,0.2-1,0.3-1.5,0.5c0,0,0.1,0.4,0.2,0.7C272.2,321.2,272.7,321.1,273.2,320.9z
		 M261.5,295.6c-0.4,1.4-0.4,1.4,0.6,1.4C262,296.6,261.7,296.1,261.5,295.6z M267.1,291c0.1-1.1-0.4-1.6-1.5-1.4
		C265.4,291,266.6,290.6,267.1,291z M355,251.7c-0.2,0-0.3,0-0.5,0c-0.1,0.6-0.4,1.2,0.5,2C355,252.9,355,252.3,355,251.7z
		 M312.6,331c-1,0.2-1.5,0.6-1.3,1.7C311.6,332,313.5,332.7,312.6,331z M314.3,325.1c0.3,0.7,0.5,1.2,0.9,1.9
		c0.4-0.6,0.7-1.1,1.1-1.7C315.5,325.2,315.1,325.1,314.3,325.1z M288.2,261.8c-1.1-0.2-1.5,0.1-1.3,0.8c0.1,0.3,0.5,0.4,0.8,0.6
		C287.8,262.8,287.9,262.4,288.2,261.8z M426.2,193.9c0.4-1.2,0.6-2.1,0.9-2.9c-0.1,0-0.3-0.1-0.4-0.1
		C426.4,191.8,425.3,192.3,426.2,193.9z M346.2,323.7c-0.5-0.8-0.9-1.4-1.2-1.9c-0.1,0-0.2,0.1-0.3,0.1c0,0.6,0.1,1.3,0.1,2
		C345.3,323.9,345.6,323.8,346.2,323.7z M295.1,278.3c0,1.2,0,1.2,1.3,1.3C295.9,279.2,295.5,278.7,295.1,278.3z M315.8,274.9
		c-0.7,0-1,0.5-0.8,1.1c0,0.2,0.6,0.3,0.8,0.2C316.3,275.8,316.2,275.3,315.8,274.9z M378.9,273.7c-1.7-0.4-1.7-0.4-2.1,1
		C377.5,274.4,378.1,274.1,378.9,273.7z M350.8,311.1c0.8,0.3,1.3,0.5,1.9,0.7C352.2,310.4,352.2,310.4,350.8,311.1z M275.2,269.4
		c-1.1,0.2-1.1,0.2-1,1.6C274.5,270.5,274.8,270,275.2,269.4z M397.1,225.9c0.1,0.9,0.1,1.5,0.1,2.1
		C397.5,227.3,399,226.8,397.1,225.9z M328,349.8c-0.3,0.2-0.6,0.3-1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0,1,0,1.4,0
		c0.1-0.1,0.2-0.3,0.3-0.4C328.6,350.1,328.3,349.9,328,349.8z M328.5,318.8c0,0.2,0,0.3,0,0.5c0.5,0,0.9-0.1,1.4-0.1
		c0-0.2,0-0.3,0-0.5C329.5,318.7,329,318.7,328.5,318.8z M328.7,344.3c0.8,0.7,1.3,1.2,2,1.8C330.7,344.7,330.2,344.2,328.7,344.3z
		 M261.2,292.1c0,0.2,0,0.3,0,0.5c0.6,0.2,1.3,0.5,1.9-0.5C262.3,292.1,261.7,292.1,261.2,292.1z M344.6,192.8c-0.2,0-0.3,0-0.5,0
		c0,0.5,0.1,0.9,0.1,1.4c0.1,0,0.2,0,0.4,0C344.6,193.7,344.6,193.3,344.6,192.8z M338.4,197.7c0-0.8,1.3-1.1,0.7-2.2
		C338.1,196,337.9,196.7,338.4,197.7z M341.5,322.1c0-0.1,0-0.3,0.1-0.4c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.2-0.7,0.3
		c0,0.1,0.1,0.2,0.1,0.3C340.6,322.1,341.1,322.1,341.5,322.1z M288.9,350.8c0.1,0.2,0.2,0.3,0.3,0.5c0.4-0.2,0.7-0.4,1.1-0.6
		c0,0-0.2-0.5-0.3-0.5C289.7,350.4,289.3,350.6,288.9,350.8z M274.5,214.2c0.2,0.1,0.3,0.2,0.5,0.2c0.3-0.5,0.5-0.9,0.8-1.4
		c-0.2-0.1-0.4-0.2-0.7-0.3C274.9,213.2,274.7,213.7,274.5,214.2z M274.1,329.3c-0.1,0.6-0.1,1.1-0.2,1.9
		C275,330.5,275.4,330.1,274.1,329.3z M307.5,243.2c-1.2-0.2-1.2,0.4-1.1,1.1c0,0.1,0.2,0.1,0.4,0.2
		C307,244.2,307.2,243.8,307.5,243.2z M288.3,189.8c0,0.1,0,0.2,0,0.4c0.4,0,0.8,0.1,1.2,0.1c0.1,0,0.1-0.3,0.3-0.6
		C289.2,189.7,288.7,189.7,288.3,189.8z M290.8,271.3c-0.6,0.6-1,0.9-1.5,1.4C290.3,272.9,291,273,290.8,271.3z M389.5,283.1
		c0,0.2,0,0.3,0,0.5c0.5,0,1-0.1,1.4-0.1c0-0.1,0-0.3,0-0.4C390.5,283.1,390,283.1,389.5,283.1z M406.2,219
		c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3,0.1-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.4,0.7c0.1,0.1,0.2,0.2,0.3,0.3
		C405.4,219.6,405.8,219.3,406.2,219z M341.1,355.2c0.3-0.4,0.7-0.7,0.6-0.9c0-0.2-0.4-0.4-0.6-0.5c-0.1,0-0.5,0.3-0.5,0.4
		C340.7,354.5,340.9,354.7,341.1,355.2z M273.9,275.7c-1.4,0.2-1.2,0.9-1,2C273.3,276.9,273.5,276.4,273.9,275.7z M261.2,310
		c0,0.2,0,0.4,0,0.5c0.6,0,1.2-0.1,2.2-0.1C262.4,309.6,261.8,309.7,261.2,310z M327,341.5c0.3-0.4,0.7-0.7,0.7-0.9
		c0-0.2-0.4-0.4-0.6-0.5c-0.1,0-0.5,0.3-0.5,0.4C326.6,340.8,326.8,341,327,341.5z M389.4,188.8c1-0.1,1-0.1,0.9-1.7
		C390,187.8,389.7,188.3,389.4,188.8z M252.4,304.3c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.4,0.6,1.3,1.8,0.6
		C253.3,304.9,252.9,304.6,252.4,304.3z M279.7,333.8c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5,0.4-1,0.7-0.5,1.7
		C279.1,334.7,279.4,334.3,279.7,333.8z M348.7,247.8c0.1-0.1,0.3-0.2,0.4-0.3c-0.3-0.4-0.6-0.9-1-1.5
		C347.5,247.2,348.4,247.3,348.7,247.8z M337.3,283.9c0.4,0.7,0.7,1.2,1.2,2c0-0.9,0-1.5,0-2.3C338.1,283.7,337.8,283.8,337.3,283.9
		z M291.5,342.2c-0.8,0.6-1.4,0.9-0.9,1.8c0.3-0.3,0.6-0.5,0.9-0.8C291.6,343.1,291.5,342.8,291.5,342.2z M265.5,314
		c0.1-0.2,0.2-0.3,0.4-0.5c-0.4-0.4-0.7-1-1.7-0.5C264.7,313.4,265.1,313.7,265.5,314z M318.5,223.3c-0.1-0.2-0.3-0.4-0.4-0.6
		c-0.4,0.3-0.8,0.7-1.2,1c0.1,0.1,0.2,0.3,0.3,0.4C317.5,223.8,318,223.6,318.5,223.3z M277.5,215.4c0.4-0.4,0.7-0.6,0.7-0.8
		c0-0.2-0.3-0.5-0.6-0.6c-0.1,0-0.5,0.2-0.5,0.4C277.2,214.7,277.4,214.9,277.5,215.4z M269.8,330.3c-0.3-0.2-0.5-0.3-0.7-0.5
		c-0.2,0.3-0.3,0.5-0.5,0.8c0.2,0.1,0.4,0.3,0.6,0.3C269.4,330.9,269.5,330.6,269.8,330.3z M318.8,330.3c-0.5-0.2-0.8-0.5-1-0.4
		c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.1,0.4,0.4,0.5,0.4C318.2,330.9,318.4,330.7,318.8,330.3z M268.1,298.2c0.1,1.2,0.1,1.2,1,1.7
		C269.4,299,268.6,298.9,268.1,298.2z M271.1,298.5c0,0.1,0.1,0.3,0.1,0.4c0.5-0.2,1.3,0.5,1.4-0.7c0-0.1-0.1-0.2-0.2-0.4
		C272,298.1,271.5,298.3,271.1,298.5z M353.6,317.9c0.4,0.3,0.6,0.6,0.8,0.6c0.2,0,0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7
		C354.4,317.4,354.1,317.7,353.6,317.9z M395.4,193c-0.7-0.4-1.1-0.6-1.7-0.9C393.7,193.4,394.4,193.2,395.4,193z M351.2,337.3
		c-0.1,0.1-0.2,0.2-0.3,0.3c0.3,0.4,0.6,0.7,1,1.1C352.5,337.6,351.7,337.5,351.2,337.3z M330.5,350.1c0,0.2-0.1,0.4-0.1,0.5
		c0.3,0.1,0.6,0.2,1,0.2c0-0.1,0.1-0.3,0.1-0.4C331.1,350.4,330.8,350.3,330.5,350.1z M267.3,315.6c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.3,0.3-1.2,0.2-0.6,1.5C266.7,316.3,267,315.9,267.3,315.6z M351.2,313.4c0,0.2,0,0.3,0,0.5c0.5,0,0.9-0.1,1.4-0.1
		c0-0.1,0-0.2,0-0.4C352.1,313.4,351.6,313.4,351.2,313.4z M324.6,349.6c-0.8,0-1.2,0.1-2,0.1c0.5,0.5,0.8,0.7,1.1,1
		C324,350.3,324.2,350.1,324.6,349.6z M374.5,222.5c-0.2,0-0.3,0-0.5,0c0,0.4,0.1,0.9,0.1,1.3c0.1,0,0.3,0,0.4,0
		C374.5,223.4,374.5,222.9,374.5,222.5z M291.4,268.6c-0.4,0.4-0.6,0.7-1.1,1.2C291.4,269.9,291.7,269.7,291.4,268.6z M406.4,220.6
		c0,0.2-0.1,0.5-0.1,0.7c0.5,0,0.9,0.1,1.4,0.1c0-0.2,0.1-0.3,0.1-0.5C407.3,220.9,406.9,220.7,406.4,220.6z M311.4,313.9
		c0.2-0.9,0.4-1.6,0.6-2.4C311,311.9,311,311.9,311.4,313.9z M341.8,327.3c0.9-0.8,0.9-0.8,0.2-1.8
		C341.9,326.2,341.9,326.7,341.8,327.3z M419.3,220.9c0.1,0,0.2,0,0.3,0c0-0.5,0-0.9,0-1.4c-0.1,0-0.2,0-0.3,0
		C419.3,220,419.3,220.5,419.3,220.9z M291.3,276.9c0-0.2,0-0.3,0-0.5c-0.4,0-0.8,0.1-1.2,0.1c0,0.1,0,0.2,0,0.4
		C290.6,276.9,291,276.9,291.3,276.9z M258.1,284.8c-0.3-0.2-0.5-0.5-0.9-0.8C256.8,285,257.1,285.3,258.1,284.8z M353.7,303.2
		c-0.8,0-1.2,0.3-1.3,1c0,0.2,0.3,0.3,0.5,0.6C353.2,304.2,353.4,303.7,353.7,303.2z M260.3,287.1c0-1.1,0-1.1-1-0.8
		C259.6,286.5,259.9,286.7,260.3,287.1z M355.8,243.9c0.2,0,0.3,0,0.5-0.1c-0.1-0.4-0.1-0.7-0.2-1.1c-0.1,0-0.2,0-0.3,0
		C355.8,243.1,355.8,243.5,355.8,243.9z M266.2,333.7c0.3,0.2,0.6,0.5,1.1,1c-0.1-0.9-0.1-1.5-0.1-2.4
		C266.7,333,266.5,333.3,266.2,333.7z M294.3,240.6c0.4-0.4,0.6-0.7,1.1-1.1C294.2,239.3,294.1,239.7,294.3,240.6z M297.6,244
		c0.1-1.3,0.1-1.3-0.8-1.1C297,243.1,297.2,243.4,297.6,244z M386.6,189c-0.4,0.4-0.6,0.7-1,1.1C386.7,190.4,386.8,189.9,386.6,189z
		 M279.6,325.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.4,0.2-0.8,0.2-1.2c0,0-0.3-0.1-0.3-0.1C279.9,324.4,279.7,324.8,279.6,325.1z
		 M343.9,252.7c-1,0-1.4,0.2-0.7,1C343.4,253.4,343.6,253.1,343.9,252.7z M367.1,322.9c0.2-1.1,0-1.5-1-1.1
		C366.4,322.1,366.6,322.4,367.1,322.9z M400.2,223.9c1.1,0.1,1.5,0,1.1-1C401,223.2,400.7,223.4,400.2,223.9z M279.4,269.4
		c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3,0.1,0.6,0.2,0.9c0,0,0.4,0,0.5-0.1C279.7,270,279.5,269.7,279.4,269.4z M291,220.1
		c0.4,0.3,0.7,0.6,1.1,1C292.2,220.2,292.1,219.7,291,220.1z M259.5,310.5c-0.2,0.2-0.3,0.3-0.5,0.5c0.1,0.7,0.4,1.1,1.5,0.7
		C260.1,311.2,259.8,310.9,259.5,310.5z M353.9,306.7c-0.4-0.1-0.6-0.2-0.9-0.3c0,0-0.2,0.3-0.2,0.3c0.2,0.2,0.3,0.3,0.5,0.4
		C353.4,307.2,353.6,307,353.9,306.7z M295.8,284.7c0.8-0.4,1.1-0.6,1.6-0.9C296.8,283.7,296.2,283.3,295.8,284.7z M262.8,302.3
		c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.3-0.6-0.7-1-1c-0.1,0.5-0.1,0.8,0.1,1C262.3,302.1,262.6,302.2,262.8,302.3z M259.4,300.2
		c0.1,1.1,0.1,1.1,1,0.8C260.1,300.8,259.8,300.5,259.4,300.2z M280.4,293c-0.2,0-0.4,0-0.6,0.1c0.1,0.5,0.3,1,0.4,1.5
		c0.1,0,0.2,0,0.4-0.1C280.5,294,280.4,293.5,280.4,293z M416.9,213.3c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.2,0.3,0.4
		c0.1-0.1,0.3-0.3,0.3-0.4C417.3,213.7,417.1,213.6,416.9,213.3z M344.4,214c-0.2,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.1,0.3-0.3,0.3-0.4C344.7,214.4,344.5,214.2,344.4,214z M289.7,217.1c0.1-0.4,0.2-0.7,0.2-0.9c0-0.1-0.3-0.1-0.4-0.2
		c-0.1,0.2-0.2,0.3-0.2,0.5C289.3,216.6,289.5,216.7,289.7,217.1z M286.4,341.8c-0.4-0.2-0.7-0.4-0.9-0.4c-0.2,0-0.4,0.3-0.5,0.4
		c0.2,0.1,0.3,0.4,0.5,0.4C285.8,342.2,286,342,286.4,341.8z M420.3,219.2c0.1,0.2,0.2,0.4,0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.3
		c0-0.2-0.1-0.3-0.2-0.5C420.6,219,420.5,219,420.3,219.2z M418.6,211.6c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.3-0.3,0.4
		c0,0.1,0.2,0.3,0.3,0.4C418.4,211.9,418.5,211.8,418.6,211.6z M389.5,220c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0.2,0.2,0.4,0.3
		c0.1-0.2,0.2-0.3,0.2-0.5C389.9,220.3,389.7,220.2,389.5,220z M305.3,348.2c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.2-0.3,0.4C305.1,347.8,305.2,348,305.3,348.2z M289.7,203.1c0-0.1,0-0.2,0-0.2c-0.6,0-1.3,0-1.9,0
		c0,0.1,0,0.2,0,0.2C288.4,203.1,289.1,203.1,289.7,203.1z M271.5,349.4c-0.3-0.3-0.4-0.4-0.6-0.5c-0.2,0-0.4,0.1-0.5,0.1
		c0.1,0.1,0.1,0.4,0.2,0.4C270.8,349.5,271.1,349.5,271.5,349.4z M283,349.2c-0.3-0.2-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.3,0.3,0.4,0.3C282.6,349.5,282.8,349.4,283,349.2z M287.4,349.3c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C286.9,349.5,287.1,349.4,287.4,349.3z M402.1,199.9c-0.3-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.5,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C401.6,200.2,401.8,200.1,402.1,199.9z M412.6,197.8c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3
		c0.1,0,0.3-0.2,0.4-0.3C412.8,198.1,412.7,198,412.6,197.8z M416.4,196.6c0-0.1,0-0.3,0.1-0.4c-0.5-0.1-0.9-0.2-1.4-0.3
		c0,0.2-0.1,0.3-0.1,0.5C415.4,196.5,415.9,196.6,416.4,196.6z M305.8,226c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.1,0-0.2,0.2-0.3,0.4C305.5,225.8,305.6,225.9,305.8,226z M312.4,225.8c-0.2-0.1-0.3-0.3-0.5-0.3c0,0-0.2,0.2-0.2,0.2
		c0,0.2,0.2,0.3,0.2,0.4C312.1,226.1,312.2,226,312.4,225.8z M397.7,195.8c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C397.3,196.1,397.4,195.9,397.7,195.8z M344.8,196.2c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3
		c-0.1,0-0.2,0.2-0.4,0.3C344.4,195.9,344.6,196,344.8,196.2z M319.8,226c0.1-0.2,0.3-0.3,0.4-0.4c-0.2-0.1-0.3-0.3-0.5-0.3
		c-0.1,0-0.3,0.2-0.4,0.3C319.5,225.7,319.6,225.9,319.8,226z M374.7,225.8c-0.2-0.1-0.4-0.2-0.5-0.3c0,0-0.2,0.2-0.2,0.3
		c0,0.1,0.2,0.3,0.3,0.4C374.3,226,374.5,225.9,374.7,225.8z M419.1,225.6c-0.3-0.1-0.5-0.3-0.6-0.3c-0.2,0-0.3,0.2-0.4,0.3
		c0.2,0.1,0.3,0.2,0.5,0.3C418.7,225.9,418.8,225.8,419.1,225.6z M405.5,227.1c0.3-0.3,0.5-0.4,0.7-0.6c-0.1-0.2-0.3-0.5-0.5-0.5
		c-0.2,0-0.4,0.2-0.6,0.3C405.2,226.6,405.3,226.8,405.5,227.1z M292.3,352.5c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.1,0-0.2,0.2-0.3,0.4C292,352.3,292.1,352.4,292.3,352.5z M364.3,192.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5
		c0,0.1,0.2,0.2,0.4,0.3C364,192.8,364.1,192.6,364.3,192.4z M314.2,227.6c-0.6-0.2-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.3-0.3,0.4
		c0,0.1,0.2,0.4,0.3,0.4C313.4,228,313.6,227.8,314.2,227.6z M405.2,189.1c0.1-0.2,0.2-0.4,0.3-0.5c0,0-0.2-0.2-0.3-0.2
		c-0.2,0-0.3,0.2-0.4,0.3C404.9,188.8,405,188.9,405.2,189.1z M416.9,230c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.2-0.3,0.4C416.7,229.6,416.8,229.7,416.9,230z M300.7,229.9c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1,0-0.3,0.1-0.3,0.1
		c0,0.2,0.1,0.4,0.1,0.4C300.2,230.1,300.4,230,300.7,229.9z M336.2,189.1c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3
		c-0.1,0-0.2,0.2-0.4,0.3C335.9,188.8,336,189,336.2,189.1z M297.9,238.4c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4
		c0,0.1,0.2,0.3,0.2,0.4C297.6,238.7,297.7,238.6,297.9,238.4z M322.6,332.1c-0.5-0.1-0.8-0.3-1-0.3c-0.2,0-0.5,0.3-0.5,0.5
		c0,0.2,0.2,0.4,0.4,0.6C321.7,332.8,321.9,332.6,322.6,332.1z M265.1,332.9c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.2-0.3,0.4C264.8,332.4,265,332.6,265.1,332.9z M404.6,186.2c-0.3-0.1-0.5-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.2,0.1,0.3,0.3,0.5,0.3C404.2,186.5,404.3,186.4,404.6,186.2z M349,243.4c-0.3-0.1-0.5-0.2-0.6-0.2c-0.1,0-0.2,0.2-0.4,0.4
		c0.2,0.1,0.3,0.2,0.5,0.2C348.6,243.7,348.8,243.6,349,243.4z M251.9,328.7c0.2,0.1,0.4,0.2,0.5,0.3c0,0,0.2-0.2,0.2-0.3
		c0-0.2-0.2-0.3-0.3-0.4C252.2,328.4,252.1,328.5,251.9,328.7z M306.6,246.6c0.1-0.3,0.3-0.4,0.3-0.6c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.3-0.3,0.4C306.3,246.2,306.5,246.3,306.6,246.6z M253.5,325.8c-0.1,0.3-0.3,0.5-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.1,0.3-0.3,0.3-0.4C253.8,326.2,253.7,326,253.5,325.8z M296.2,250.1c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.2-0.3,0.4C295.9,249.7,296.1,249.9,296.2,250.1z M256.6,323.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.4,0.2,0.9,0.3,1.3,0.5
		c0.1-0.1,0.1-0.3,0.2-0.4C257.5,323.8,257.1,323.6,256.6,323.4z M268.6,323c0.2,0.2,0.4,0.3,0.5,0.3c0.1,0,0.3-0.1,0.3-0.2
		c0-0.1-0.1-0.4-0.2-0.4C269.1,322.8,268.9,322.9,268.6,323z M270.5,253.8c-0.1,0.3-0.3,0.5-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.2,0.3-0.3,0.3-0.5C270.8,254.2,270.7,254,270.5,253.8z M297.8,267.1c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.3,0.3,0.4,0.3C297.3,267.4,297.5,267.2,297.8,267.1z M355.1,313.1c-0.2,0.2-0.4,0.3-0.5,0.4c0.1,0.1,0.3,0.3,0.4,0.3
		c0.2,0,0.3-0.2,0.5-0.3C355.4,313.5,355.2,313.3,355.1,313.1z M270.5,273.4c0.2-0.3,0.3-0.4,0.3-0.6c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.3-0.3,0.4C270.2,272.9,270.4,273.1,270.5,273.4z M292.2,273.8c-0.2-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2
		c0.1,0.1,0.2,0.3,0.3,0.4C291.7,274.1,291.8,273.9,292.2,273.8z M288.6,274.8c0.6,0.1,0.9,0.2,1.1,0.1c0.2-0.1,0.4-0.4,0.5-0.6
		c-0.1-0.1-0.2-0.2-0.2-0.3C289.7,274.3,289.3,274.5,288.6,274.8z M353.4,307.8c-0.2,0.1-0.3,0.2-0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.4
		c0,0,0.3-0.2,0.3-0.2C353.6,308.1,353.4,308,353.4,307.8z M271.4,275.4c-0.2-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C271,275.7,271.1,275.6,271.4,275.4z M314.8,277.9c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C314.3,278.2,314.5,278,314.8,277.9z M384.7,306.1c-0.2,0.3-0.3,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3
		c0.1-0.1,0.3-0.2,0.4-0.3C384.9,306.6,384.8,306.4,384.7,306.1z M325.1,279.2c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C324.7,279.4,324.8,279.3,325.1,279.2z M332.5,283.7c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3
		c-0.1,0-0.2,0.2-0.4,0.3C332.2,283.4,332.3,283.5,332.5,283.7z M271.7,303.8c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.1,0.3-0.3,0.3-0.4C272.1,304.2,271.9,304,271.7,303.8z M310.9,287.2c0.1,0,0.1,0,0.2,0c0-0.5,0-0.9,0-1.4
		c-0.1,0-0.1,0-0.2,0C310.9,286.3,310.9,286.8,310.9,287.2z M298,301c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.2-0.5-0.2
		c-0.1,0-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4C297.7,301.2,297.9,301.1,298,301z M254.4,294.7c0.1,0.2,0.2,0.4,0.3,0.5
		c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.2-0.5C254.7,294.5,254.6,294.6,254.4,294.7z"/>
	<path class="st0-bull" d="M804.8,343c-0.5-0.5-1-1-0.7-1.8c0.1-0.4,0.6-0.6,1-1c-0.3-0.1-0.7-0.2-1.3-0.4c0.8-0.1,1.3-0.2,2.1-0.4
		c-0.4-0.4-0.7-0.6-1.2-1c0.8-0.1,1.5-0.1,2.4-0.2c-1-0.7-1.7-1.3-2.6-1.9c-0.4,0.3-0.7,0.7-1.1,1c-0.3,0.2-0.7,0.4-0.9,0.3
		c-1.3-0.8-2.7-0.5-4-0.4c-0.6,0-1.3-0.9-1.7-0.1c-0.4,1-1.1,0-1.7,0.5c-0.8,0.7-2.1,0.8-3.2,1.2c0,0.1,0,0.6,0.1,0.6
		c0.6,0.1,1.2,0.3,1.8,0.2c1.2-0.3,2.3-0.8,3.4-1.3c0.3-0.1,0.7-0.5,0.8-0.5c0.9,0.9,1.7-0.5,2.6,0c0.5,0.3,1.1,0.5,1.6,0.9
		c0.7,0.5,1,2.4,0.3,3.2c-1,0.9-0.6,2.6-2,3.2c-0.1,0-0.1,0.5,0,0.8c0,0.2,0.2,0.3,0.4,0.5c-1.2,0.9-0.8,2.5-1.6,3.6
		c-0.4,0.4-0.6,1.1-0.7,1.6c-0.2,1.7-0.4,2.4-1.9,3.9c-2.2-0.7-4.4,1.1-6.8-0.4c0-0.6,0-1.3,0-2.1c0-0.2-0.3-0.5-0.5-0.7
		c0.3-0.4,0.6-0.8,1-1.2c-0.6-0.8-0.9-1.6-0.5-2.7c0.3-1-0.7-2.2-2.1-2.7c0.5,1.6,0.4,2-0.5,3.7c1.2,0.8,1.2,0.8,1,2.6
		c0.4,0.1,0.8,0.2,1.2,0.3c-0.2,0.4-0.6,0.8-0.7,1.2c-0.6,1.6-1.6,2.3-3.2,2.2c-1-0.1-2,0-3.2,0c-0.1-0.5-0.2-1-0.2-1.5
		c0.7-0.3,1.2-0.6,1.9-0.9c-0.7-0.6-1.3-0.9-2.2-0.8c-0.3-0.6-0.6-1.3-0.9-2c-0.3-0.7-0.5-1.4-0.9-2.2c-0.6,0.6-1.9,0-2,1.4
		c0,0-0.5,0.1-0.6,0c-0.5-0.6-1-1.2-1.2-1.9c-0.3-1-0.7-1.6-1.8-1.6c-0.8,0-1.8,1.4-1.7,2.4c0,1,0.1,2,0,3c-0.2,0.8-0.2,1.4,0.2,2.1
		c0.2,0.4,0.1,1.1,0.2,1.6c-0.8,0.1-1.4,0.2-2.1,0.3c0-0.6,0.2-1.4,0-1.6c-0.5-0.4-1.3-0.5-2-0.7c-0.1,0.2-0.2,0.3-0.2,0.5
		c0.3,0.3,0.7,0.6,1,0.9c0,1-1.5,1.3-4.4,0.7c0.5-0.6,1-1.1,1.6-1.7c-0.7-0.3-1.2-0.6-1.7,0.2c-0.4,0.6-0.9,1.1-1.4,1.4
		c-1.1,0.7-2,0.4-2.7-0.5c-1.9-2.5-3.7-5-5.6-7.5c-3.7-4.9-7.3-9.7-11-14.6c-0.9-1.1-1.1-2.1-0.7-3.4c0.9-3.5,1.8-7.1,2.7-10.6
		c0.2-0.8,0.4-1.4,1.3-1.4c-1.2-1.4-0.2-2.7,0-4.2c0.6-0.2,1.2-0.4,2-0.7c0,1.1,0.3,1.8,1.7,1.4c0.1-1.5,1.1-2.3,2.5-2.7
		c0.6-0.2,0.7-0.5,0.6-1.1c1.4,0.4,1.3,1.9,2,2.8c0.3-0.2,0.5-0.4,0.5-0.5c0.1-1.3,0.5-2.1,2-2.1c0.8,0,1.6-0.3,2.3-0.5
		c0.4-0.1,0.7-0.5,1-0.7c-0.1-0.1-0.1-0.2-0.2-0.4c-1.2,0-2.4,0-3.8,0c0.1-0.7,0.2-1.2,0.3-1.9c-0.4,0.3-0.9,0.4-0.9,0.6
		c-0.4,1.7-1.7,2.3-3.2,2.7c-0.7-0.1-1.3-0.3-2-0.4c-0.3-1.6,1.1-6.7,2-7.7c-0.1-0.5-0.4-1-0.3-1.3c0.9-2.2,0.3-4.5,0.5-6.7
		c0.1-1-0.2-2,0.6-3c0.3-0.4,0.1-1.1,0.1-1.6c-0.2-6.6,1.2-12.9,3-19.1c0.1-0.4,0.7-0.8,0.8-1.2c0.1-0.5-0.1-1-0.1-1.5
		c0.1-0.9,0.3-1.7,1.7-1.7c-1.7-1.1-0.6-2.2-0.5-3.2c0.1-1.3,1-2,2.4-2.3c-0.4-0.5-0.6-0.9-0.9-1.3c3-2.4,6-4.8,9-7.2
		c5.4-4.4,10.9-8.7,16.3-13c0.5-0.4,1-0.7,1.5-1.1c0.1,0.4,0.1,0.8,0.2,1.5c0.3-0.6,0.1-1.2-0.3-1.5c2.5-2,5-4,7.4-6.1
		c0.4-0.3,0.8-0.9,0.7-1.3c-0.1-0.8-0.9-0.7-1.5-0.7c-7.1,0-14.1,0-21.2,0c-1.8,0-3.6-0.3-5.7,0.2c3.7-15.2,7.4-29.9,11-44.7
		c17.7,0,35.3,0,53.2,0c-1.6,6.5-3.3,12.8-4.8,19.1c-1.4,6.3-3.2,12.5-4.7,18.8c-1.5,6.4-3.1,12.7-4.7,19.1
		c-1.6,6.4-3.2,12.7-4.8,19.1c-1.6,6.3-3.1,12.6-4.7,18.9c-1.6,6.4-3.2,12.7-4.8,19.1c-0.4,0.1-0.8,0.3-1.1,0.4
		c0.2,0.5,0.7,0.9,0.7,1.3c-0.3,1.5-0.9,3-1.2,4.6c-0.4,1.5-0.8,3.1-1,4.6c-0.2,1,1.3,1.6,2.5,1.5c2.8-0.2,5.6-0.2,8.4-0.3
		c0.2,0,0.4,0.4,0.7,0.6c0.5,0.3,1,0.5,1.7,0.8c-0.1-0.7-0.1-1-0.2-1.3c0.1-0.1,0.2-0.2,0.4-0.2c13.9,0,27.8,0,41.7,0
		c0.9,0,1.9,0.3,2.8,0.5c0.3,0.3,0.6,0.7,1.1,1.2c0.1-1.3-0.7-1.1-1.2-1.2c0.4-0.2,0.8-0.4,1.3-0.4c2.8-0.1,5.7-0.1,8.5-0.1
		c0.7,0,1.4-0.1,1.8,0.7c0.2-0.9,0.9-0.7,1.5-0.7c3.2,0,6.5,0,10,0c-1.2,2.3-0.9,4.9-2.1,7c-0.7,1.2-1.9,2.3-3.1,3
		c-0.6,0.3-1,0.7-1.5,1.2c-1,1-2.3,1.8-3.1,3.1c-0.2,0.3-0.2,0.6-0.4,0.9c1.5,0.2,1.6,0.2,2-1.8c0.9-0.3,1.9-0.5,2.6-1
		c0.6-0.3,0.5-1.4,1.4-1.6c0.5-0.1,0.9-0.8,1.7-1.6c-0.1,1-0.2,1.6-0.3,2.1c-2.1,8.7-4.3,17.4-6.5,26.1c-0.2,1-0.6,1.9-1,2.9
		c-0.2,0.7-0.5,1.3-0.4,2.1c0.1,0.7-0.4,1.4-0.6,2.2c-0.8-0.5-1.9,0.2-2.2-1c-1.3,0.5-2.5,1.1-4.1,0.6c-1.2-0.4-2.7,0.3-4.1,0.3
		c-8.7,0.1-17.5,0.2-26.2,0.3c-0.6,0-1.2-0.1-1.9-0.1c-0.1-0.7-0.2-1.4-0.3-2c-1.2-0.4-1.7,0.6-2.3,1.1c-0.6,0.5-1.1,1.1-1.9,1
		c-1.6-0.1-3.1-0.2-4.7-0.3c-1.5,0-3.1,0-4.6,0.1c-0.3,0-0.5,0-0.8-0.1c0.1-0.5,0.2-0.8,0.3-1.4c-0.8,0.3-1.4,0.6-1.9,0.8
		c-0.3-0.6-0.7-1.3-1.2-2.2c-0.3,0.5-0.5,0.8-0.7,1.1c0.3,0.1,0.5,0.2,0.8,0.3c-0.2,0.5-0.4,0.9-0.7,1.5c-0.5-0.3-0.9-0.6-1.3-0.9
		c-0.2,0.3-0.4,0.7-0.5,1c-1.6-0.1-3.4,0.5-4.7-1.1c0.7-0.8,1.4-1.6,2.3-2.8c-1.4-0.2-2.4-0.3-3.4-0.5l0.1,0.1
		c-0.4-0.1-0.8-0.2-1.2-0.2c-0.4,0-0.9,0.1-1.3,0.2l0.1,0.1c-0.1-0.5-0.2-1.1-0.2-1.6c-0.8,0.1-1.5,0.3-2.2,0.3
		c-0.4,0-0.7-0.3-1.3-0.6c1.1-1,2.3-1.2,1.6-2.5c1.4,0.5,2.5,0.7,3.5,1.2c0.6,0.3,1,1,1.6,1.6c0.3-0.5,0.6-0.9,0.9-1.5
		c-0.5-0.1-0.9-0.2-1.4-0.4c-0.2-1.2-0.5-2.4-0.8-3.7c0.3-0.2,0.6-0.3,1-0.5C806.9,342.3,805.7,343.2,804.8,343L804.8,343z
		 M769.9,314.3c-0.3,0.2-0.7,0.4-1.2,0.6C769.8,315.4,769.7,314.7,769.9,314.3c0.7,0,1.5,0.1,2.2,0.2
		C771.3,313.9,770.6,313.7,769.9,314.3z M855.6,325c0.6-0.4,1.8-0.1,1.9-1.9C856.8,324,855.3,323.4,855.6,325
		c-1.8-0.4-1.8-0.4-2.1,0.7C854.3,325.4,854.9,325.2,855.6,325z M795.6,220.9c-0.6,0.1-1.2,0.1-1.8,0.2c-0.3,0.1-0.5,0.4-1,1
		C794.1,221.9,795.5,222.8,795.6,220.9c0.5,0.2,1.1,0.4,2,0.7C797,220.2,796.3,220.5,795.6,220.9z M775.6,340
		c-0.6-0.5-0.9-0.1-1.2,0.5c0.8,0,1.6,0.1,2.2,0c0.6-0.1,0.4-0.5-0.1-0.8c0.1-1.2,0.8-2.3,0.3-3.6c1.4,0.2,1.8,1.3,2.3,2.3
		c-0.5,0-1.1-0.4-1.3,0.4c-0.1,0.8,0.5,0.8,1.1,0.8c0.1,0,0.1,0,0.2,0c-1.5,1.7-1.1,2.7,1.7,3.9c-0.6-1.5,1.5-2.8-0.1-4.3
		c0.6-0.2,1-0.3,1.3-0.5c-0.1,1.9,1.7,0.9,2.3,1.6c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.3-0.7-0.7-1-1c-0.7,0.9-1.1-0.2-1.6-0.2
		c-0.1-0.5-0.1-0.9-0.2-1.4c0.8,0.3,1.2-0.1,1.3-0.8c0-0.2-0.5-0.4-0.8-0.7c-0.2,0.6-0.3,1.1-0.4,1.5c-1.4-0.5-1-1.5-0.9-2.4
		c0.5,0,0.8,0,1,0c-0.2-1.5-0.9-3-0.5-4.1c0.4-1,0-1.4-0.2-2.1c-2.4,0.8-2.9,1.5-2.6,3.2c0.5-0.1,1-0.1,1.6-0.2
		c-1.1,1.6-0.5,3,0,4.7c-1.5-1.1-2.7-1.9-4.4-3c0.4,1.2,0.7,1.9,1,2.7C776.4,337.4,776,338.7,775.6,340z M751.8,316.5
		c-0.6-0.7-0.6-0.7-1.2-0.2C751,316.3,751.4,316.4,751.8,316.5c0.1,1.4,0.1,1.4,1.7,1.4c-0.3-0.7-0.5-1.3-0.8-2
		C752.3,316.1,752,316.3,751.8,316.5z M817.9,338.6c-0.8-0.3-1,0.1-0.9,0.8c0,0.6,0.1,1.1,0.2,2.1c0.6-0.8,1-1.2,1.3-1.6
		C818.2,339.5,818,339,817.9,338.6c0.4-0.4,0.9-0.8,1.3-1.2c-0.4-0.5-0.8-0.9-1.2-1.3c-0.1,0-0.2,0.1-0.4,0.1
		C817.7,337,817.8,337.8,817.9,338.6z M779.4,304.7c0.5,0.3,1,0.6,1.5,0.9c1-0.7,1.2-0.7,2.8-0.9c0.7-0.1,1.4-0.5,2.3-0.7
		c-0.3-0.3-0.4-0.6-0.5-0.6C783.4,303.8,781.4,304.2,779.4,304.7c-0.6,0.1-1.2,0.1-1.7,0.3c-0.5,0.2-1,0.6-1.4,1
		c-0.1,0.1,0.3,0.8,0.5,0.8c1.2,0.2,1.2-1.8,2.6-1.4C779.3,305.2,779.3,304.9,779.4,304.7z M816.5,349.6c-0.3-0.4-0.5-1.2-1.4-0.2
		C815.7,349.5,816.1,349.5,816.5,349.6c-0.5,1.2,0.6,2.2,0.7,3.4c0,0.1,0.5,0.1,0.7,0.2c0.7-1.5,0.1-2.8-0.2-4.1
		c0.6-0.3,1.1-0.5,1.9-0.8c-0.8-0.3-1.3-0.5-1.9-0.7c0.4-0.3,0.6-0.5,0.8-0.7c-0.5-0.2-1-0.5-1.3-0.6
		C816.9,347.5,816.7,348.5,816.5,349.6z M788.4,270.9c1.4-1.7,1.8-3.6,1.5-5.7c-0.2-1.5,0.1-2.9,0-4.4c-0.1-1.1-0.6-2.3-0.4-3.4
		c0.2-1.5-0.2-2.8-0.7-4.2c-0.3-0.7,0.2-1.7,0.3-2.5c0-0.3,0.1-0.5,0.2-1c-1.9,1.2-1.6,2.7-1.3,4.3c0.3,2,1.7,4-0.4,5.9
		c0.7,0.8,1.4,1.3,0.8,2.7c-0.5,1.1-0.4,2.8,0,4.1c0.4,1.2,0.3,1.9-0.7,2.6c-0.2,0.2-0.4,0.5-0.5,0.8
		C787.6,270.5,788,270.7,788.4,270.9z M788.3,344.4c0.8-0.6,1.6-1.2,2.5-1.9c-0.1-1.7-0.2-3.5-0.4-5.4c-0.6,0.1-1.1,0.2-1.7,0.4
		c0.1-1.2,0.3-2.2,0.4-3.3c-0.5-0.6-1.2-1.4-2.2-2.5c0,0.7,0,1,0.1,1.4c0.1,0.8,0.7,1.7,0.4,2.4c-0.5,1.1,0,1.8,0.7,2.5
		C786.1,340.3,787.9,342.3,788.3,344.4z M784.4,270.2c-0.6,2.7-0.2,6.8,0.9,8.5c0-2.7,0-5.1,0-7.4
		C785.2,271,784.8,270.7,784.4,270.2z M788.5,242.9c-0.3,2.1-1.5,4.2,0.1,6.2c0.5-0.3,1.5-1.3,1.3-1.7c-0.1-0.4-0.6-0.8-0.9-1.1
		C790.2,244.9,788.4,244,788.5,242.9z M824.8,338c1.1-0.3,2-0.6,3-0.9c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.6,1.5-1.4,2.6-1.4
		c0.2,0,0.3-0.6,0.6-1.3c-1.2,0.5-2.3,0.7-2.8,1.3c-0.8,0.9-1.6,0.6-2.5,0.6c-0.2,0-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.3,1
		C824.6,337.7,824.8,338,824.8,338z M793.5,354.1c0.8-1.3,2.4-2.1,2.5-4c-1.4,0.6-2.6,1-3.8,1.5C792.6,352.4,793,353.2,793.5,354.1z
		 M764.4,309.9c0.1,0.1,0.3,0.2,0.4,0.3c1.9-0.6,3.7-1.3,5.6-1.9c0.1-0.8-0.4-1-1-0.8c-1.4,0.4-2.8,0.8-4.1,1.4
		C764.9,309,764.7,309.5,764.4,309.9z M787.2,305.2c-0.4-2.1,2.4-1.4,2.7-3.4c-1.1,0.8-1.9,0.4-2.7-0.2
		C786.2,302.9,786.3,304,787.2,305.2z M813.1,312.6c-0.3,1.8-0.6,3.4-0.8,5c1-0.5,1.4-1.3,1.8-3
		C814.2,313.7,813.9,313.2,813.1,312.6z M809.7,342.4c0.1-0.9,0.2-1.7,0.4-2.6c-0.8,0-1.5,0-2.2,0c-0.1,0.2-0.3,0.4-0.4,0.6
		c0.4,0.2,0.8,0.3,1.1,0.5c-0.1,0.6-0.3,1-0.4,1.6C808.9,342.4,809.4,342.4,809.7,342.4z M861.1,322c0.4,0.5,0.7,0.8,0.8,1.2
		c0.1,0.6,0.4,1,0.9,0.7c0.2-0.1,0.1-0.7,0.2-1c0-0.2-0.1-0.6,0-0.7c1.1-0.8,0.7-1.7,0.5-3C862.6,320.3,863.2,322.1,861.1,322z
		 M783.7,248.8c0.4-0.9,0.8-1.8,1.2-2.7c-1.6-0.5-2,0.7-2.7,1.4C782.9,248.1,783.3,248.5,783.7,248.8z M805.2,326.3
		c0.5,0,1.1,0.1,1.2,0c0.5-0.8,1-1.8,1.4-2.7c0.1-0.2-0.1-0.5-0.2-0.7c-0.3,0.1-0.7,0.1-0.8,0.3
		C806.2,324.1,805.7,325.1,805.2,326.3z M796.3,329.3c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.4,0.1,0.9,0.4,1.1c0.5,0.4,1.4,0.4,1.6,1.4
		c0,0,0.6,0.1,0.7,0c0.1-0.1,0.2-0.6,0.1-0.7C797.7,330.6,797,330,796.3,329.3z M812.2,345.7c-0.6,0.5-1,0.8-1.3,1.1
		c0.3,0.6,0.6,1.1,0.9,1.7c0.5-0.3,0.9-0.6,1.3-0.8C812.8,347.1,812.6,346.6,812.2,345.7z M787.5,328.6c0.8-0.6,1.8-0.8,1.9-2
		c-0.9-0.3-1.6,0.1-1.9,0.8C787.3,327.8,787.5,328.2,787.5,328.6z M775.4,342.4c-0.9,0.3-1.5,0.6-2.2,0.9c0.3,0.5,0.5,0.9,0.7,1.3
		c0.5-0.2,1-0.3,1.5-0.5C775.4,343.6,775.4,343.2,775.4,342.4z M814.2,349.9c-1.2-0.6-2.3-0.1-3.3,0.7
		C812.3,352,813,350,814.2,349.9z M781.5,324.6c0-0.7-0.1-1.2-0.1-1.9c-0.8,0.1-1.7-0.3-1.8,0.9
		C780.2,323.9,780.7,324.2,781.5,324.6z M873,317.2c0.1,0.1,0.1,0.3,0.2,0.4c0.9-0.3,1.9-0.6,3.1-1c-0.4-0.4-0.7-0.8-0.8-0.8
		C874.6,316.2,873.8,316.7,873,317.2z M774.7,308.4c0-0.9,0-1.7,0-2.9C773.5,307.6,773.5,307.6,774.7,308.4z M785.2,335.1
		c-0.7-0.7-1.4-1.4-2.3-2.2c-0.3,1.6,1.3,1.8,1.4,3C784.7,335.5,784.9,335.4,785.2,335.1z M862.4,333c-1.1,0.7-1.5,1.6-1.4,2.8
		C862.1,335.3,862.8,334.6,862.4,333z M823,347.5c0.1,0.1,0.2,0.1,0.3,0.2c0.7-0.6,1.5-1.2,2.2-1.8c-0.1-0.1-0.2-0.3-0.3-0.4
		c-0.4,0-0.9-0.1-1.4-0.1C823.5,346.2,823.2,346.8,823,347.5z M822.2,342.5c0.4,1,0.6,1.6,0.9,2.4c0.4-0.6,0.7-1.1,1.1-1.6
		C823.5,343.1,823,342.9,822.2,342.5z M787.2,313.1c-0.2,0.6-0.4,1.3-0.6,2c0.5,0,0.8,0,1.3,0c-0.1-0.4-0.4-0.9-0.3-1
		C788.2,313.5,788,313.2,787.2,313.1z M869.5,351.1c0.1,0.1,0.3,0.2,0.4,0.3c1.2-0.3,1.1-1.5,1.5-2.3c-0.1-0.1-0.3-0.2-0.4-0.3
		C870.5,349.6,870,350.3,869.5,351.1z M785.5,283.3c0.1,0,0.1,0,0.2,0c0-1.2,0-2.5,0-3.8c-0.4,0.1-0.7,0.1-1,0.2
		C785,281,785.2,282.1,785.5,283.3z M784.3,344.2c1-1.1,1-1.1,0.5-1.8c-0.1-0.2-0.3-0.3-0.5-0.5c-0.3,0.6-0.5,1.1-0.8,1.6
		C783.8,343.8,784,344,784.3,344.2z M810.8,337.7c-0.1-0.2-0.2-0.3-0.3-0.5c-0.5,0-1,0-1.6,0c0.1,0.6,0.1,1.1,0.2,2.1
		C809.9,338.6,810.4,338.2,810.8,337.7z M844.3,342.9c0.1,1.1,0.9,0.8,1.6,0.9c0.7,0,1-0.3,0.8-0.9
		C845.9,342.9,845.1,342.9,844.3,342.9z M780.5,347.1c-0.8-0.6-1.4-1.6-2.4-1c-0.2,0.1-0.1,0.6-0.2,1
		C778.8,347.1,779.7,347.1,780.5,347.1z M783.4,224.4c-1.4,1.5-1.4,1.5-0.8,2.7C782.9,226.1,783.2,225.2,783.4,224.4z M868.4,311.5
		c0,0.7,0,1.4,0,2.2C869.8,312.4,869.8,312.2,868.4,311.5z M787.2,308.8c-1.5,1.2,0.4,2-0.3,3.2c0.6-0.3,0.8-0.4,1-0.5
		C787.6,310.6,787.4,309.8,787.2,308.8z M793.5,243.7c-0.2,1.5-0.2,1.5,1.4,1.7C795,244.4,794.4,244.1,793.5,243.7z M765.5,314
		c0.1,0.8,0.5,1.1,1.3,1.1c0.6,0,1.3,0.1,1.1-0.8C767.1,314.2,766.3,314.1,765.5,314z M804,314.9c-0.5,0.6-2-0.1-1.6,1.1
		c0.1,0.3,0.9,0.3,1.6,0.6C804,315.7,804,315.3,804,314.9z M862,351.1c-1.1,1.2-1.1,1.2-0.1,2C861.9,352.4,862,351.7,862,351.1z
		 M868.5,353.3c-1.3-0.7-1.3-0.7-2.2,0.9C867,353.9,867.6,353.7,868.5,353.3z M833.6,321.3c0,0.1,0,0.2,0,0.3c0.6,0,1.3,0,1.9,0
		c0-0.1,0-0.2,0-0.3C834.9,321.3,834.3,321.3,833.6,321.3z M760.1,303.6c-1.4,1.5-1.4,1.5-0.1,2C760,304.9,760.1,304.2,760.1,303.6z
		 M774.6,337.3c-1.3,0.2-1.6,0.3-1.6,1.1c0,0.2,0.5,0.4,0.8,0.7C774.2,338.3,774.4,337.8,774.6,337.3z M789.5,311.2
		c0.2,0.5,0.5,1,0.7,1.6C791.3,311.5,791.3,311.4,789.5,311.2z M793,301.9c-0.1-0.2-0.1-0.3-0.2-0.5c-0.5,0.2-1,0.3-1.5,0.5
		c0.1,0.2,0.2,0.5,0.3,0.7C792,302.4,792.5,302.1,793,301.9z M869.1,338.6c-0.8,0.1-1-1.4-2-0.7
		C867.9,338.8,867.9,338.8,869.1,338.6z M794.8,341.3c-0.1-0.3-0.1-0.5-0.2-0.8c-0.5,0.2-1,0.4-1.4,0.6c0,0,0.1,0.3,0.1,0.5
		C793.8,341.5,794.3,341.4,794.8,341.3z M785.7,332.3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4,0.3-1.2-0.4-1.4,0.6c0,0.2,0.1,0.4,0.2,0.5
		C784.7,332.7,785.2,332.5,785.7,332.3z M786.4,291c-0.6,0.2-1.3,0.2-0.7,1c0.1,0.2,0.4,0.2,0.7,0.4
		C786.4,291.8,786.4,291.5,786.4,291z M776.1,252.9c-0.2,0-0.3,0-0.5,0c0,0.5,0,0.9,0,1.4c0.1,0,0.2,0,0.4,0
		C776,253.9,776,253.4,776.1,252.9z M805.4,328.5c0.1,0,0.3,0,0.4,0c0.2-0.5,0.4-1-0.4-1.5C805.4,327.6,805.4,328.1,805.4,328.5z
		 M800.6,327.4c-0.5-0.2-0.8-0.5-0.9-0.4c-0.2,0.1-0.4,0.4-0.5,0.7c0,0.1,0.4,0.4,0.5,0.4C800,328,800.2,327.8,800.6,327.4z
		 M764.3,342.4c0.4,0.3,0.6,0.6,0.8,0.6c0.2,0,0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7C765,341.9,764.7,342.2,764.3,342.4z
		 M795,327.3c-0.2-0.4-0.4-0.8-0.5-0.7c-0.3,0-0.5,0.2-0.8,0.4c0.1,0.2,0.2,0.5,0.4,0.6C794.4,327.6,794.6,327.4,795,327.3z
		 M792.1,210c-0.4-1.1-0.4-1.1-1.5-0.9C791.2,209.5,791.6,209.7,792.1,210z M822.5,334.9c0.1-0.1,0.2-0.3,0.3-0.4
		c-0.4-0.4-0.6-1.3-1.7-0.6C821.6,334.3,822.1,334.6,822.5,334.9z M764.9,304.2c0.4-0.4,0.7-0.6,0.7-0.8c0-0.2-0.3-0.5-0.6-0.6
		c-0.1,0-0.5,0.2-0.5,0.4C764.6,303.4,764.8,303.7,764.9,304.2z M811.6,328.3c-0.1,0.1-0.2,0.2-0.4,0.3c0.3,0.4,0.6,0.9,1.1,1.5
		C812.9,328.9,811.9,328.8,811.6,328.3z M757.4,338c0.8,0.5,1.3,0.7,1.8,1c0.1-0.1,0.1-0.2,0.2-0.4
		C758.9,338.4,758.8,337.4,757.4,338z M788.9,322.7c-0.4-0.2-0.7-0.4-0.9-0.4c-0.2,0-0.5,0.4-0.5,0.4c0.1,0.3,0.3,0.6,0.6,0.6
		C788.3,323.4,788.5,323,788.9,322.7z M857.4,352.1c-0.4-0.3-0.6-0.7-0.8-0.7c-0.2,0-0.5,0.4-0.6,0.6c0,0.1,0.3,0.5,0.5,0.5
		C856.7,352.5,856.9,352.3,857.4,352.1z M760,316.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4,0.3-0.8,0.5-1.2,0.8c0,0,0.1,0.3,0.2,0.7
		C759.2,317.4,759.6,317.1,760,316.8z M809.7,343.9c-0.4-0.4-0.6-0.7-0.8-0.7c-0.2,0-0.5,0.3-0.6,0.6c0,0.1,0.3,0.5,0.4,0.5
		C808.9,344.2,809.2,344.1,809.7,343.9z M849.1,343.3c0.8,0.4,1.2,0.6,1.7,0.9C851,342.9,850.3,343,849.1,343.3z M819.3,344.4
		c0,1.3,0.8,1,1.3,0.9c0.1,0,0.1-0.2,0.3-0.4C820.4,344.8,820,344.6,819.3,344.4z M813.7,335.7c-0.1,0-0.3,0-0.4,0
		c0,0.4,0.1,0.8,0.1,1.2c0.1,0,0.2,0,0.3,0C813.7,336.5,813.7,336.1,813.7,335.7z M812.7,319.8c-0.2-0.1-0.3-0.3-0.5-0.4
		c-0.2,0.3-0.5,0.6-0.7,0.9c0.1,0.1,0.2,0.2,0.4,0.3C812.2,320.3,812.4,320,812.7,319.8z M866.2,320.3c-0.9-0.5-1.2-0.2-0.9,0.8
		C865.6,320.8,865.9,320.5,866.2,320.3z M779,321.7c1.1,0.1,1.4-0.2,1.1-1.2C779.7,320.9,779.4,321.2,779,321.7z M808.7,224.9
		c-0.1,0-0.2,0-0.3,0c0,0.5,0,0.9,0,1.4c0.1,0,0.2,0,0.3,0C808.7,225.8,808.7,225.4,808.7,224.9z M815.9,341.7
		c0.5-0.9,0.2-1.2-0.8-0.9C815.4,341.2,815.7,341.4,815.9,341.7z M795.6,301c0.4,0.3,0.7,0.6,1.1,1
		C796.9,300.9,796.7,300.6,795.6,301z M790.8,246.9c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.6,0,0.7-0.1c0.2-0.2,0.1-0.5,0.3-1.3
		C791.3,246.4,791,246.6,790.8,246.9z M792.9,323.4c0.1-1,0.1-1-0.9-0.9C792.3,322.8,792.5,323,792.9,323.4z M774,259.5
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0.2,0.3,0.3,0.4C773.5,260,773.7,259.7,774,259.5z M864.9,316.9
		c-0.2-1.1-0.2-1.1-1-0.7C864.2,316.4,864.5,316.6,864.9,316.9z M879,313.9c-0.4,0.4-0.6,0.7-1.1,1.1
		C879,315.2,879.2,314.8,879,313.9z M859.8,314.8c0.1,0.1,0.2,0.2,0.2,0.3c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0,0-0.2-0.1-0.4
		C860.4,314.4,860.1,314.6,859.8,314.8z M782.3,312.4c-0.1,0.2-0.1,0.4-0.2,0.6c0.4,0.1,0.9,0.1,1.3,0.2c0-0.1,0-0.2,0.1-0.2
		C783,312.7,782.7,312.5,782.3,312.4z M852.1,348.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.2,0.4,0.4,0.7,0.6c0.1-0.1,0.2-0.3,0.3-0.4
		C852.6,348.7,852.3,348.6,852.1,348.4z M819.3,346.8c0.1,0.1,0.3,0.3,0.4,0.4c0.3-0.3,0.5-0.5,0.9-0.9
		C819.5,345.8,819.3,346.2,819.3,346.8z M800.6,300c-1.1-0.1-1.1-0.1-1.3,0.7C799.7,300.4,800.1,300.2,800.6,300z M841.6,344.6
		c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0.2,0.3,0.3,0.4C841.1,345,841.4,344.8,841.6,344.6z M817.8,343.7
		c-0.5,0.1-1,0.2-1.8,0.4C817.1,345,817.5,344.7,817.8,343.7z M827.4,343c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4
		c0.1,0,0.3-0.1,0.5-0.2C827.6,343.3,827.5,343.2,827.4,343z M814.5,341.9c-0.6-0.3-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.2-0.3,0.4
		c0,0.1,0.2,0.4,0.3,0.4C813.7,342.3,813.9,342.1,814.5,341.9z M765.2,346.3c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.3-0.2,0.4-0.3
		c-0.1-0.1-0.3-0.3-0.4-0.3C765.6,346,765.4,346.2,765.2,346.3z M823.4,339.1c-0.1,0.5-0.3,0.8-0.3,1c0,0.2,0.3,0.4,0.5,0.5
		c0.2,0,0.4-0.2,0.6-0.4C824,340,823.9,339.8,823.4,339.1z M842.6,347.1c0-0.1,0.1-0.2,0.1-0.3c-0.6,0-1.3,0-1.9,0
		c0,0.1,0,0.2,0,0.3C841.5,347.1,842.1,347.1,842.6,347.1z M765.1,339.4c-0.2-0.2-0.4-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2
		c0.1,0.1,0.2,0.3,0.3,0.4C764.6,339.6,764.7,339.5,765.1,339.4z M822.9,221.5c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C822.4,221.8,822.6,221.6,822.9,221.5z M786,350.7c0.2-0.2,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.2-0.5
		c-0.1,0.1-0.3,0.2-0.4,0.3C785.8,350.2,786,350.4,786,350.7z M769.8,350.4c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.3,0.3,0.4,0.3C769.4,350.8,769.6,350.6,769.8,350.4z M817.7,335.6c0.1-0.2,0.2-0.3,0.3-0.5c-0.1-0.1-0.3-0.3-0.4-0.3
		c-0.1,0-0.2,0.2-0.4,0.3C817.3,335.3,817.5,335.4,817.7,335.6z M844.9,353c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
		c0.1,0.1,0.2,0.3,0.4,0.3C844.4,353.3,844.6,353.1,844.9,353z M796.5,353.7c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4,0.2-0.9,0.4-1.3,0.6
		c0.1,0.1,0.1,0.3,0.2,0.4C795.7,354.1,796.1,353.9,796.5,353.7z M784.5,354.2c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.3
		c0-0.1-0.1-0.3-0.2-0.5C784.8,354,784.7,354.1,784.5,354.2z M834.2,333.1c0,0.1,0,0.2,0,0.2c0.6,0,1.3,0,1.9,0c0-0.1,0-0.2,0-0.2
		C835.4,333.1,834.8,333.1,834.2,333.1z M824.3,188.6c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0,0.2,0.3,0.2,0.3
		C824,188.8,824.1,188.7,824.3,188.6z M790.7,333.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3
		C790.5,333.8,790.6,333.7,790.7,333.4z M749.1,328.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.3-0.2,0.5-0.2,0.8c0,0,0.2,0.1,0.3,0.2
		C749,328.9,749,328.6,749.1,328.4z M792.8,327.6c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4
		C793.1,328,792.9,327.9,792.8,327.6z M798,327.4c-0.3-0.2-0.5-0.3-0.7-0.4c-0.1,0.2-0.3,0.4-0.3,0.6c0,0.2,0.3,0.3,0.5,0.4
		C797.6,327.9,797.7,327.7,798,327.4z M790.3,328.1c0.2-0.3,0.3-0.4,0.3-0.6c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4
		C790,327.7,790.1,327.9,790.3,328.1z M820.2,326.5c-0.2,0.3-0.3,0.4-0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.4-0.2,0.4-0.3
		C820.5,327,820.3,326.8,820.2,326.5z M810.2,323.4c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0.2,0.3,0.2,0.5
		C809.9,323.7,810,323.6,810.2,323.4z M802.3,319.8c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3
		C802.5,320.1,802.4,320,802.3,319.8z M784.1,319.3c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.3,0.3,0.4
		C783.8,319.6,783.9,319.5,784.1,319.3z M773.7,262.8c-0.2-0.2-0.3-0.3-0.4-0.4c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0.2,0.3,0.3,0.4
		C773.4,263.1,773.5,263,773.7,262.8z M870.1,313.9c-0.3,0.6-0.4,0.7-0.4,0.9c0,0.1,0.2,0.3,0.4,0.3c0.1,0,0.4-0.2,0.4-0.3
		C870.5,314.6,870.3,314.5,870.1,313.9z M778.3,314.9c-0.2-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4
		C777.8,315.1,778,315,778.3,314.9z M826.8,313.3c-0.2-0.1-0.4-0.2-0.5-0.3c0,0-0.2,0.2-0.2,0.3c0,0.1,0.2,0.3,0.3,0.4
		C826.5,313.6,826.6,313.5,826.8,313.3z M788.4,273.4c0.2-0.1,0.4-0.1,0.5-0.2c-0.2-0.4-0.3-0.8-0.5-1.2c-0.1,0-0.2,0.1-0.3,0.1
		C788.2,272.5,788.3,272.9,788.4,273.4z M771.8,306.6c0.4,0.9,0.6,1.4,0.9,2.2C772.9,307.4,772.9,307.4,771.8,306.6z M792.8,307.4
		c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.2-0.3-0.3-0.5c-0.1,0.1-0.3,0.2-0.3,0.4C792.5,306.9,792.6,307.1,792.8,307.4z M783.3,306
		c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.2-0.5C783.6,305.9,783.5,305.9,783.3,306z"/>
	<path class="st0-bull" d="M728.7,311c-0.9,3.4-1.3,6.6-2.5,9.2c-1.5,0.6-2.7,1-3.8,1.5c-0.1,0,0.1,0.6,0.2,1.1c0.7-0.5,1.2-0.9,1.8-1.3
		c0.5,0.2,1,0.4,1.6,0.7c-0.7,2.9-1.4,5.7-2,8.6c-0.7,2.9-1.4,5.8-2.1,8.6c-0.7,2.8-1.3,5.6-2.4,8.3c-0.1,0-0.4,0-0.6,0
		c0,0.2-0.1,0.4-0.1,0.5c0.4,0.6,0.7,1.1,0.4,2c-0.6,1.7-0.9,3.5-1.4,5.5c-0.7,0-1.4,0-2.1,0c-8.5,0-17,0.1-25.5,0
		c-3.6,0-7.1,0.1-10.7,0.1c-3.5-0.1-7,0-10.6,0c-4,0-8,0-12,0c-0.8,0-1.7,0.2-1.9-0.6c-1.2,0.2-2.2,0.6-3.2,0.6c-2,0.1-4,0-6.1,0
		c-0.1-0.6-0.2-1-0.3-1.8c-0.4,0.8-0.6,1.2-1,1.8c-2.6-0.1-5.4,0.4-8.1-0.4c-0.3-0.1-0.6-0.2-0.8-0.1c-1.7,0.9-3.5,0.4-5.3,0.4
		c-5.3,0.1-10.5,0-15.8,0c-0.9,0-1.9-0.3-2.8-0.4c-0.4,0-0.8,0.1-1.2,0.2c-2,0.5-2.7,0.3-3.9-1.3c-3.1-4.1-6.3-8.2-9.3-12.4
		c-2.3-3.3-4.9-6.4-7.3-9.6c-0.8-1.1-0.4-2-0.2-3.1c0.2-0.9,0.6-1.4,1.8-1.3c-1.1-1-1-2.1-0.7-3.2c3.8-15.1,7.5-30.1,11.3-45.2
		c1.3-5.4,2.8-10.8,4-16.2c0.5-2.5,1.9-4.3,3.8-5.9c0.5,0.1,1.1,0.2,1.8,0.4c-0.1-0.6-0.2-1.1-0.3-1.8c4.3-3.2,8.7-6.6,13.2-10
		c0.4-0.3,1-0.4,1.4-0.7c0.5-0.3,1.2-0.7,1.3-1.1c0.2-1.1,1-1.6,1.7-2.2c4.1-3.3,8.2-6.6,12.3-9.8c0.3-0.3,0.7-0.5,0.9-0.8
		c0.2-0.3,0.5-0.8,0.4-1.1c-0.2-0.3-0.7-0.7-1.1-0.7c-2.3-0.1-4.7-0.1-7-0.1c-4.9,0-9.8,0-14.7,0c-0.4,0-0.8-0.1-1.3-0.2
		c-0.1-0.8-0.2-1.5-0.3-2.2c-1.5,0.1-0.9,1.4-1.2,2.3c-0.8,0-1.5,0-2.5,0c3.7-14.9,7.3-29.6,10.9-44.5c2,0.2,3.9-0.2,5.9,0.3
		c1.7,0.4,3.7-0.3,5.6-0.3c2-0.1,4,0,6,0c1.9,0,3.9,0,5.8,0c2,0,4,0,6,0c1.9,0,3.9,0,5.8,0c2,0,4,0,6,0c1.9,0,3.9,0,5.8,0
		c2,0,4,0,6.2,0c-0.2,1-0.3,1.8-0.5,2.6c-7.3,29.3-14.6,58.6-21.9,87.9c-2.7,10.7-5.3,21.4-8,32.1c-0.1,0.5-0.3,1.1-0.4,1.6
		c-0.3,1.5,0,2,1.6,2c0.8,0,1.5-0.1,2.3-0.1c20.3,0,40.5-0.1,60.8,0c4.6,0,9.1-0.2,13.7,0C726.9,311,727.6,311,728.7,311z
		 M619.8,276.3c-0.1-0.4-0.2-0.8-0.3-1.5C618.8,275.8,619.3,276,619.8,276.3c-0.1,0.2-0.3,0.5-0.6,0.9c0.6,0.2,1.2,0.4,1.8,0.6
		C620.9,276.8,620.5,276.4,619.8,276.3z M620.1,290.2c-0.7,0.1-1.3,0.3-2.2,0.5c0.7,1,1.4,1.2,2.4,0.7
		C620.2,290.9,620.1,290.5,620.1,290.2c0.8-0.1,0.8-0.1,0.5-0.8C620.4,289.6,620.2,289.9,620.1,290.2z M616.5,275
		c-0.1,0.5-0.3,0.9-0.5,1.6c0.7-0.3,1.2-0.5,1.7-0.8c0-0.1,0-0.3,0-0.4C617.3,275.4,616.8,275.2,616.5,275c0.4-0.9-0.4-1.4-0.9-2
		c-0.2-0.2-0.7-0.2-1-0.3c0,0.4-0.1,0.9,0.1,1C615.2,274.3,615.8,274.6,616.5,275z M641.9,335.7c-1.6,0.1-1.9,0.4-2.2,1.9
		c0,0.2-0.2,0.5-0.2,0.7c-0.3,0.2-0.5,0.4-0.9,0.6c0.7,0.5,0.9,1,0.2,1.7c-0.9,0.8-1,2-1.2,3.2c0.9-0.7,2.1-1.1,2.2-2.4
		c0.1-1-0.1-2-0.2-3c0.9,0.3,1.2-0.2,1.5-0.9C641.3,336.8,641.6,336.2,641.9,335.7c0.2-0.6,0.5-1.1,0.9-1.9
		C641.7,334.3,641.7,334.3,641.9,335.7z M616.3,301.3c-0.2,0.4-0.4,0.8-0.6,1.2c0.3,0.6,0.6,1.2,0.9,1.6c0.2,0.2,0.6,0.3,0.9,0.5
		c0.1-0.3,0.3-0.7,0.1-0.9C617.3,302.9,616.8,302.1,616.3,301.3c0.2-1.5-1.1-3.2-2.9-3.8C613.2,299.5,614.2,300.7,616.3,301.3z
		 M621.3,310.9c-0.2,1.2-1.6,2.3-0.7,3.7c2.3,0,2.6-0.4,1.6-2.2C621.9,311.8,621.6,311.4,621.3,310.9c0-0.9,0-1.7,0-2.5
		c-0.5-0.1-1-0.2-1.3-0.3c0.2-0.5,0.3-0.9,0.4-1.3c-1.3-0.3-2.1-2.2-3.7-1c0.5,1.9,1.9,2.8,3.4,3.3c-0.1,0.7-0.2,1.3-0.3,2
		C620.4,311,620.8,310.9,621.3,310.9z M622.5,269.4c-0.4-0.1-0.8-0.2-1.2-0.3c-0.4-0.1-2.3-1.8-2.2-1.9c1.1-1.1-0.5-1.6-0.5-2.4
		c-0.2-1.4,2.2-1.7,1.6-3.4c-0.5-0.1-1.1-0.5-1.5-0.4c-1.3,0.6-1.9-0.3-2.6-1.1c-0.7-0.7-1.3-1.4-2-2.1c-0.1-0.1-0.4-0.1-0.6-0.2
		c-0.3,0.4-0.5,0.9-0.8,1.3c-0.7-0.2-1.2-0.4-2.1-0.7c0.2,0.6,0.3,1.2,0.4,1.2c1.3-0.2,1.7,1.1,2.2,1.8c0.6,0.9,0.8,2.2,1.1,3.3
		c0,0.1-0.1,0.3-0.2,0.3c-0.9,0.7-0.5,1.6-0.1,2.2c0.5,0.7,1.1,1.8,2.3,1.1c-0.4,0.8-0.8,1.5-1.4,2.5c0.9-0.1,1.7-0.2,2.2,0.6
		c0.1,0.2,0.9-0.1,1.6-0.2c-0.6-1.4-1.2-2.4-2.4-2.9c1.1-0.8,1.7-0.1,2.2,0.8c0.3,0.4,0.7,0.9,1.1,1c0.7,0.2,1.4,0.1,2.2,0
		C622,269.9,622.2,269.5,622.5,269.4c0.9,0.1,1.8,0.2,2.6,0.4c0.2,0.5,0.4,1,0.6,1.4c0.1,0,0.2-0.1,0.2-0.1c0-0.6,0-1.2,0-1.6
		c-0.8-0.4-1.5-0.7-2.1-0.9C623.1,268.4,622.2,268.1,622.5,269.4z M614.6,283c-0.9-0.4-1.7-0.3-2.2,0.7c0.8,1.4,0.6,3-0.3,3.6
		c-0.4-0.7-0.4-0.7-1.3-0.9c-0.5,2.8-0.5,2.8,1.6,4.4c0.2,0.2,0.3,0.5,0.6,0.8c-1.5,0-1.5,0-2.9-0.9c-0.4,2.7,0.5,5.6-1.6,7.9
		c0.3,1.3,0.2,2.5,0,3.9c-0.2,1.2-0.6,2.4-0.9,3.5c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.4,1,0.8,0.1,1.3c-0.4,0.2-0.8,0.2-1.4,0.3
		c0.6,2,2.5,0.7,3.3,1.2c0.5,2.3,0.9,4.4,1.2,6.4c0.8,0.2,1.5,0.4,2.6,0.7c-0.9-1.1-1.6-1.9-2.3-2.8c0.9-0.9,1.1-1.8,0.2-2.8
		c1.5-0.8,0.5-2.4,1.1-3.3c-0.9-0.4-1.6-0.8-2.3-1.1c0.7-2,2.2,0.4,3-1c0-0.1,0.1-0.4,0-0.7c-0.5-1.3-1.2-2.5-1.7-3.8
		c-0.2-0.4-0.2-1.1,0-1.3c1-1.2,0.3-2.3-0.2-3.5c1-0.1,1.4-0.6,1.3-1.5c-0.1-0.9,0-1.8,0.1-2.7c0-0.1,0.1-0.2,0.3-0.4
		c0.4,0.1,0.9,0.3,1.3,0.4c0.2-0.4,0.4-0.8,0.7-1.2c-0.3-0.4-0.7-0.8-1-1.2c0.5-0.6,1.1-1.2,1.6-1.8c-1-1.1-1-1.1-0.8-2.5
		c0.5,0,0.9-0.1,1.7-0.2c-0.6-0.8-1.1-1.2-1.5-1.8C614.6,283.7,614.7,283.3,614.6,283c0.9-1.2,1.7-2.4,2.7-3.6
		c-0.8-0.1-1.5-0.2-2.3-0.3c0.2,1.1-0.6,1.7-1.4,2.3C614,282,614.3,282.5,614.6,283z M643.2,324.5c-0.6-0.3-1-0.7-0.9-1.4
		c0.2-1.4,0-2.7,0.5-4.2c0.5-1.3,0.4-2.8,1.3-4c0.1-0.1,0.1-0.4,0.1-0.6c0.2-3.6,0.4-7.1,0.6-10.7c0-0.8,0.6-1.8,0.3-2.5
		c-0.7-1.4-0.2-2.3,0.6-3c0-1.5,0-2.8,0-4.2c-1.4-1.6-1.4-1.6-1.4-4.2c0-0.8,0.3-1.7-0.1-2.2c-0.9-1.4-0.5-2.8-0.6-4.2
		c-0.1-2.3,0-4.7,0-7c0-0.8-0.2-1.6,0.6-2.3c0.2-0.1-0.1-0.8-0.1-1.2c-0.2,0-0.3,0-0.5,0c-0.4,1.3-1.2,2.7-1,3.9
		c0.2,1.1,0,1.9-0.1,2.9c-0.1,2.8,0.1,5.6,0.2,8.4c0.1,1.6,0.6,3.2,0.4,4.8c-0.4,2.9,1.1,5.6,0.2,8.5c0,0.1,0.2,0.3,0.3,0.5
		c0.1,0.4,0.3,0.9,0.2,1.2c-0.6,1.1-0.4,2.3-0.5,3.4c-0.1,1-0.3,2-0.5,3c-0.4,1.9-0.1,3.9-0.6,5.8c-0.4,1.3-0.2,2.7-1.1,3.8
		c-0.2,0.2-0.3,0.7-0.2,1c0.2,1.3-0.5,2.2-1,3.2c-0.7,1.4-1.8,1.7-3.3,1.3c-0.5-0.1-1.2-0.1-1.8,0.1c-0.9,0.3-1.7,0.9-2.6,1.3
		c0.3,1.1,1.1,1,1.5,0.7c1.8-1.2,3.7-0.3,5.5-0.5c0.9,2,0.9,2,0.6,3.4c-0.6,0.2-1.2,0.5-1.9,0.7c-0.3-0.6-0.6-1.1-0.9-1.7
		c-0.6,0.2-1,0.4-1.5,0.6c-0.3,0.8-0.3,1.7,0.5,2c1,0.4,2.2,0.4,3.3,0.6c0.2-0.5,0.3-1.1,0.6-1.8c0.6,0.5,1,0.9,1.4,1.2
		c0.4,0.3,0.8,0.7,1.2,1c1.9-1.5,1.8-2.2-0.5-3.6C641.7,327.1,641.3,325.4,643.2,324.5c0.8-0.1,1.7-0.2,2.6-0.3
		c-0.1,0.7-0.2,1.2-0.2,1.7c1.3,0.4,1.2-0.7,1.4-1.1c0.4-1.1,0.9-1.7,2.2-1.7c0.9,0,1.8-0.5,2.6-0.8c0-0.1-0.1-0.3-0.1-0.4
		c-0.9,0.1-2-0.2-2.6,0.2c-1.1,0.9-1.4,0.2-1.8-0.6c-1,1.1-1.9,2-3.5,1.8C643.2,323.3,642.9,323.9,643.2,324.5z M612.3,330.6
		c0.5-1.3,0.8-2.7-0.2-3.8c-0.9-0.9-0.9-1.9-0.9-3c-0.1-0.9-0.4-1.8-0.4-2.8c0-1.2,0.3-2.4,0.5-3.7c-0.7,0-1.2,0-1.9,0
		c0.1,1,0.2,1.8,0.3,2.7c-0.2,0.1-0.5,0.2-0.6,0.3c0.3,1.7,1.6,3.2,0.6,5c0.3,0.2,0.6,0.3,1,0.4c-1.6,1.2,0.1,1.6,0.5,2.3
		c-0.5,0.7-0.9,1.2-1.3,1.9C610.8,330.2,611.5,330.4,612.3,330.6z M686.8,332.8c0.2-2.3,0.5-4.4,0.7-6.9c-0.6,0.3-1,0.4-1.1,0.6
		c-0.3,0.5,0.4,1.3-0.5,1.6c-0.1,0,0,0.5,0,0.8c0.4,1.5,0.3,1.7-1.1,2.1c-0.5,0.2-1,0.5-1.8,0.8c0.9,0.5,1.4,0.8,1.9,1.1
		c-0.2,0.5-0.5,1-0.6,1.3c-1,0.6-2.7-1.1-2.9,1.1c2.2,0.5,3,0.3,4-1.5C685.7,333.2,685.8,332.4,686.8,332.8z M619.7,283.1
		c1.1,0.1,1.9-1.3,3.1-0.4c0.1,0.1,0.5-0.2,0.7-0.3c1.1-0.5,1.4-0.2,1.4,0.9c0,0.3,0,0.6,0,1.1c0.4-0.1,0.8-0.1,0.9-0.2
		c0.3-1.6,0.6-3.3,0.9-5.3c-1,1-1.6,1.6-2.3,2.3c-0.6-0.3-1.3-0.6-1.8-0.8c-0.4,0.3-0.6,0.7-0.9,0.8
		C619.9,281.4,619.9,281.4,619.7,283.1z M627.7,278.4c-0.2-0.5-0.3-0.7-0.4-1c-0.5-1.1-1.4-2.1-0.8-3.5c0.2-0.7,0.1-1.5-1.2-1.7
		c0,0.9,0,1.7,0,2.9c-0.5-0.6-0.7-0.9-1.1-1.4c-0.4,0.8-1.4,1-1.3,2c0.4,1.4,3.2-0.4,2.7,2.1C626.2,278,626.8,278.2,627.7,278.4z
		 M613.8,311.4c1,1.3,1.9,2.5,2.9,3.9c0.5-0.2,1.1-0.5,1.1-1.3c0-0.9-0.1-1.8-0.2-2.8c-0.5,0.1-1,0.3-1.4,0.4
		C615.4,310.3,615.3,310.3,613.8,311.4z M606,276.1c-0.1,0.1-0.2,0.3-0.3,0.4c0.5,0.7,0.9,1.9,1.5,2c1,0.2,2.1-0.4,3.2-0.6
		c-0.3-1.6-0.3-1.7-0.8-2.2C608.5,276.4,607.4,277,606,276.1z M615.7,306.7c-1.2-0.4-2-0.3-2.5,0.7c-0.3,0.8,0,1.5,0.7,2
		c1,0.9,1.8,0.3,3-0.5c-0.8-0.4-1.3-0.7-1.7-0.9C615.3,307.6,615.5,307.2,615.7,306.7z M608.7,282.9c-0.6-0.5-1.1-0.8-1.6-1.2
		c-0.1,0.8,0.1,1.4,0.5,1.8c0.5,0.5,1.2,0.9,1.8,1.2c0.3,0.1,1,0.1,1.2-0.1c0.3-0.4,0.5-1,0.4-1.4c-0.1-0.4-0.6-0.8-1-1.2
		C609.5,282.3,609.2,282.6,608.7,282.9z M620.1,295.4c-0.7,0.6-1.2,1-1.8,1.5c0.4,0.5,0.6,0.8,0.8,1c0.9-0.7,1.6-1.3,2.4-2
		c0.2-0.7-0.1-1.4-0.8-1.9c-0.4-0.3-0.7-1-1.3-0.5c-0.7,0.5,0,0.9,0.3,1.3C619.9,295,620,295.2,620.1,295.4z M621.6,271.5
		c-2-0.6-2.9,0.2-3.7,1.6c1.2-0.2,1.7,1.6,3.1,0.7c-0.3-0.5-0.5-1-0.8-1.5C620.7,272.1,621,271.9,621.6,271.5z M626.3,325.6
		c-1.4,0.7-1.4,0.7-2.1,2.5c0.2,0.1,0.4,0.3,0.5,0.2c1.3-0.4,2.6-0.7,3.8-1.2c0.2-0.1,0.2-0.5,0.4-0.9
		C627,326.9,627,326.9,626.3,325.6z M664.1,332.5c1.4,0.3,2.3,1.3,3.2,2.5c0.4-0.6,0.6-1.1,0.9-1.6
		C666.7,333,665.7,331.6,664.1,332.5z M690,332.5c0.9,0.1,1.2,0.2,2.3,0.8c0.2,0.1,0.4,0.2,0.7,0.2c1.2,0.1,2-0.1,3.5-0.8
		c-1.2-0.1-2.3,0-3.3-0.3C692,332.1,691,332.2,690,332.5z M615.9,294.3c1.8-1.8,1.8-1.8,0.4-3c-0.4,0.5-0.8,1-1.2,1.6
		C614.6,293.7,615.3,294,615.9,294.3z M617.4,298.3c0.4,2.4,1.2,3.2,2.4,2.8c-0.3-0.5-0.6-1-0.7-1.6
		C618.8,298.6,618.4,298,617.4,298.3z M607.2,340.3c1,0.1,1.5,0.1,1.9,0.1c0.3,0,0.5-0.3,0.8-0.5c-0.2-0.2-0.3-0.5-0.5-0.7
		c-0.3-0.4-0.7-0.7-1.2-1.2C607.8,338.7,607.6,339.4,607.2,340.3z M630.9,354.2c0.4-1.4,0.7-2.7,1.1-4.3
		C630,351.3,629.6,353,630.9,354.2z M627.9,332.7c-0.5,0-1.1,0-1.6,0c0.3,0.7,0.4,1.4,0.7,2c0.2,0.5,0.6,0.9,0.9,1.5
		C627.9,334.9,627.9,333.9,627.9,332.7z M623.6,311.1c0.5-1,1-1.9,1.6-3.1c-1,0.5-1.7,0.8-2.5,1.1
		C623,309.9,622.3,310.9,623.6,311.1z M609.1,288.4c0-0.1,0-0.2,0-0.3c-1-0.2-2-0.3-3.1-0.5c0.2,0.6,0.3,0.9,0.4,1.3
		C607.3,288.7,608.2,288.6,609.1,288.4z M617.7,295.4c-0.7,0-1.3,0-2.2,0c0.2,0.7,0.3,1.3,0.4,2
		C617.4,296.5,617.4,296.5,617.7,295.4z M609.8,348.6c-1.7-0.6-1.8-0.5-2.9,1.6C608.1,349.6,609,349.1,609.8,348.6z M649.6,329.1
		c-0.9,0.3-1.5,0.6-2.2,0.8c0.2,0.5,0.4,1,0.6,1.6C648.7,330.7,649.5,330.4,649.6,329.1z M631.9,276.9c-0.7,0.3-1.2,0.6-1.5,0.7
		c0,0.8,0,1.4,0,2c0.4,0,0.8,0,1.2,0C631.7,278.7,631.8,277.8,631.9,276.9z M608.1,270c0.8,1.4,1.6,1.5,2.6,0.9
		C610.2,269.6,609.2,270.2,608.1,270z M605.5,307.9c-1-0.3-1.7-0.1-2,0.8c0.6,0.2,1,0.4,1.5,0.7
		C605.2,308.9,605.3,308.4,605.5,307.9z M630.3,273.4c0.8,0.7-0.8,2,0.8,2.5C631.5,273.8,631.5,273.8,630.3,273.4z M636.1,345.3
		c-0.3-1.1-0.5-1.7-0.6-2.4c-0.5,0.5-1,1-1.6,1.7C634.6,344.8,635.3,345,636.1,345.3z M633.5,279c1.3-0.9,0-1.5,0.2-2.3
		C632.4,277.5,633,278.2,633.5,279z M631.2,296c0.4,0.8,0.7,1.4,1.2,2.2C632.6,296.1,632.6,296.1,631.2,296z M616,316.9
		c0.5,1.6,1.4,1.7,2.3,1.7c0.1-0.1,0.1-0.3,0.2-0.4C617.8,317.9,617.2,317.5,616,316.9z M687.2,337.4c-0.3,0.5-0.5,1.1-0.8,1.6
		c0.3,0.2,0.5,0.5,0.8,0.7c0.3-0.2,0.5-0.5,0.8-0.7C687.7,338.5,687.5,337.9,687.2,337.4z M687.5,345.6c-1.1,0-1.6,0.3-1.4,1.2
		c0,0.2,0.3,0.3,0.6,0.6C686.9,346.8,687.1,346.3,687.5,345.6z M628.6,320.8c0.1,1.6,0.2,1.7,1.3,1.4
		C629.4,321.8,629,321.3,628.6,320.8z M618.9,220.7c0.7,0.7,0.9,1.5,2,1.1C620.2,221.4,620.5,219.8,618.9,220.7z M621.5,304.2
		c-1.2-0.2-1.3,0.4-1.1,1.1c0,0.2,0.5,0.3,0.8,0.4C621.3,305.2,621.4,304.7,621.5,304.2z M635.1,352.1c-0.1-0.8-0.1-1.4-0.2-2
		C634.4,350.8,633.3,351.5,635.1,352.1z M596.5,335.4c0-1.2-0.7-1.1-1.3-1c-0.2,0.1-0.2,0.6-0.3,1.1
		C595.5,335.5,596,335.5,596.5,335.4z M631.5,326.4c-1.8-0.2-1.8-0.2-1.9,0.8C630.1,327,630.6,326.8,631.5,326.4z M672.1,326.1
		c-1.4,0.6-1.4,0.6,0,2C672.1,327.4,672.1,326.9,672.1,326.1z M617.7,327c-0.9-0.4-1.6-0.4-2,0.4c-0.1,0.2,0.1,0.6,0.2,1.1
		C616.7,328,617.2,327.5,617.7,327z M631.3,310.4c-0.8,0.3-1.4,0.4-1.9,0.6c0,0,0.1,0.6,0.2,0.7
		C630.6,312.1,630.8,311.3,631.3,310.4z M649.7,317.6c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.4-0.4,0.8-0.6,1.2c0.2,0.1,0.6,0.3,0.6,0.3
		C649.3,318.4,649.5,318,649.7,317.6z M684.4,354c0.7,0.2,1.2,0.3,1.8,0.4C685.6,352.9,685.6,352.9,684.4,354z M613.9,337.1
		c0.1,0,0.3-0.1,0.4-0.1c-0.1-0.5,0.4-1.5-1.1-1.2C613.5,336.3,613.7,336.7,613.9,337.1z M710,316.6c1.1,0.8,1.4-0.2,2.1-0.4
		c-0.1-0.1-0.1-0.3-0.2-0.4C711.3,316.1,710.7,316.4,710,316.6z M606.4,317c0.3-0.1,0.7-0.3,1-0.4c0.3-0.1,0.6-0.2,0.9-0.3
		C607.6,316.1,606.7,315.5,606.4,317z M608.4,312c-1.3-0.2-1,0.9-1.4,1.5C608.5,313.8,608,312.4,608.4,312z M601.8,341.1
		c-0.1,0.1-0.3,0.3-0.4,0.4c0.3,0.3,0.5,0.7,0.8,1c0,0,0.3-0.1,0.7-0.3C602.5,341.7,602.2,341.4,601.8,341.1z M612,272.1
		c-0.3,1,0.2,1.2,1,1.1c0.2,0,0.3-0.2,0.6-0.3C613,272.6,612.6,272.4,612,272.1z M675,324.7c0-0.1,0-0.2,0-0.3c-1,0-2,0-3.1,0
		c0,0.1,0,0.2,0,0.3C673,324.7,674,324.7,675,324.7z M623.2,284.4c-0.1,0.1-0.1,0.3-0.2,0.4c0.4,0.3,0.6,1.3,1.8,0.6
		C624.2,284.9,623.7,284.6,623.2,284.4z M641,285.7c-1.4,0.2-1.2,0.9-1,2C640.4,286.9,640.6,286.4,641,285.7z M651.9,265.7
		c-0.1-0.1-0.2-0.3-0.2-0.4c-0.5,0.3-0.9,0.6-1.5,1C651.3,267,651.5,266,651.9,265.7z M622.1,317.6c1.3-0.1,1.3-0.1,1.8-1.4
		C623.1,316.8,622.7,317.1,622.1,317.6z M606.7,319.9c0.1-0.4,0.3-0.8,0.5-1.4C605.9,318.6,606.3,319.2,606.7,319.9z M599,332.7
		c0.5,0.3,1.1,0.6,1.9,1C600.6,332.4,600.2,332,599,332.7z M614.9,316.4c-0.2,0.1-0.3,0.3-0.5,0.4c0.3,0.3,0.6,0.5,0.9,0.8
		c0.1-0.1,0.2-0.2,0.3-0.3C615.4,317.1,615.2,316.7,614.9,316.4z M672.9,351.4c0.3,0.2,0.5,0.5,0.9,0.8
		C674.2,351.2,673.9,350.9,672.9,351.4z M624.7,315.1c-0.7-1-1.2-0.7-1.9,0.2C623.6,315.2,624.1,315.2,624.7,315.1z M687.5,349.8
		c-0.3-0.4-0.6-0.7-0.9-1.1C686.4,349.8,686.4,349.8,687.5,349.8z M612.9,311.9c-0.5,0.9-0.2,1.2,0.8,0.9
		C613.3,312.5,613.1,312.2,612.9,311.9z M610.1,279.2c0.3,0.3,0.6,0.6,1,0.9c0.1-0.5,0.3-0.9,0.5-1.6
		C610.8,278.8,610.4,279,610.1,279.2z M631.1,286.3c0-1.1,0-1.1-1-0.9C630.5,285.7,630.8,285.9,631.1,286.3z M593,334.6
		c0.4,0.4,0.7,0.6,1.1,1C594.2,334.6,594.1,334.2,593,334.6z M625.6,263.6c0.2-1,0.2-1-0.9-0.9C625.1,263,625.3,263.3,625.6,263.6z
		 M619.4,224.9c-0.1,1-0.1,1,0.9,1C619.9,225.5,619.7,225.2,619.4,224.9z M652,272.8c-1-0.1-1-0.1-0.9,0.9
		C651.5,273.4,651.7,273.2,652,272.8z M629.1,282.3c-1.3-0.1-1.3-0.1-1.2,0.8C628.2,282.9,628.4,282.7,629.1,282.3z M626.5,284.5
		c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.4-0.5,0.6-0.7c-0.1-0.1-0.4-0.3-0.4-0.3C626.8,284,626.6,284.3,626.5,284.5z M679.8,346.1
		c0.1-0.9-0.1-1.4-1.2-1.2C679.1,345.4,679.4,345.8,679.8,346.1z M627.3,343.9c-0.1,0.3-0.3,0.5-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
		c0.1-0.2,0.3-0.3,0.3-0.5C627.6,344.4,627.4,344.2,627.3,343.9z M631.4,343.6c-0.1-0.3-0.1-0.4-0.2-0.6c-0.2,0.1-0.4,0.1-0.4,0.2
		c0,0.2,0,0.4,0.1,0.5C630.9,343.7,631.2,343.6,631.4,343.6z M669.8,341.9c0.2-0.1,0.4-0.1,0.4-0.2c0-0.2-0.1-0.3-0.1-0.5
		c-0.1,0-0.3,0.1-0.3,0.1C669.7,341.5,669.8,341.7,669.8,341.9z M676.2,348.6c0.2-0.2,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.3-0.4
		c-0.1,0.1-0.3,0.2-0.3,0.4C675.9,348.2,676.1,348.3,676.2,348.6z M630.6,199.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.3
		c0-0.1-0.1-0.3-0.2-0.5C631,199.7,630.8,199.8,630.6,199.9z M654.4,352.7c0-0.2,0-0.3,0-0.5c-0.3,0.1-0.6,0.1-0.8,0.2
		c0,0.1,0.1,0.3,0.1,0.3C653.9,352.8,654.2,352.8,654.4,352.7z M644.6,328c-0.1,0-0.2,0-0.2,0c0,0.6,0,1.3,0,1.9c0.1,0,0.2,0,0.2,0
		C644.6,329.3,644.6,328.6,644.6,328z M624.7,325.6c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0.2-0.4,0.3
		C624.4,325.3,624.5,325.4,624.7,325.6z M604.8,324.2c0.2-0.2,0.3-0.4,0.4-0.5c0-0.1-0.1-0.3-0.2-0.3c-0.2,0-0.4,0.1-0.4,0.2
		C604.6,323.7,604.7,323.9,604.8,324.2z M656.5,321.9c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4c0.2,0,0.3-0.1,0.5-0.2
		C656.7,322.2,656.7,322.1,656.5,321.9z M606.4,322c-0.1,0.3-0.2,0.5-0.2,0.6c0,0.1,0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5
		C606.8,322.4,606.6,322.2,606.4,322z M634.7,321.4c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4
		C635,321.8,634.9,321.6,634.7,321.4z M624.4,257.6c0.2-0.3,0.3-0.4,0.3-0.6c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4
		C624.1,257.2,624.2,257.4,624.4,257.6z M623.1,259.5c0.2-0.3,0.3-0.4,0.3-0.5c0-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.3,0.4
		C622.8,259,623,259.2,623.1,259.5z M614.9,320.1c-0.6-0.2-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4
		C614.2,320.5,614.3,320.3,614.9,320.1z M646.8,260.5c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		C646.5,260.9,646.6,260.7,646.8,260.5z M605.1,318.1c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3
		C604.7,318.4,604.9,318.3,605.1,318.1z M627.6,315.6c0.3,0.2,0.4,0.3,0.6,0.3c0.2,0,0.3-0.2,0.4-0.3c-0.1-0.1-0.3-0.3-0.4-0.3
		C628.1,315.3,627.9,315.5,627.6,315.6z M609.4,268.7c0.3,0.2,0.4,0.3,0.5,0.3c0.1,0,0.3-0.2,0.4-0.3c-0.1-0.1-0.3-0.3-0.4-0.3
		C609.8,268.4,609.7,268.6,609.4,268.7z M652,268.8c-0.2-0.2-0.3-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4
		C651.5,269.1,651.7,268.9,652,268.8z M632,280.9c-0.6-0.3-0.8-0.4-1-0.4c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4
		C631.2,281.3,631.4,281.2,632,280.9z M629,286.7c-0.4-0.2-0.5-0.4-0.6-0.4c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.4,0.3,0.4
		C628.3,286.9,628.5,286.8,629,286.7z M640.5,288.6c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.3,0.3,0.4
		C640.2,288.9,640.4,288.8,640.5,288.6z M630.2,292c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		C629.9,292.4,630,292.3,630.2,292z M605.3,294.5c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3
		C605,294.8,605.1,294.7,605.3,294.5z"/>
	<path class="st0-bull" d="M557.9,355.4c0.2-0.3,0.3-0.6,0.4-0.6c0.4,0,0.7,0,1.2,0.1C559.1,355.9,558.6,355.6,557.9,355.4z"/>
	<path class="st0-bull" d="M288.8,226.5c0.9,0.2,1.8,0.4,2.7,0.6c0,0.6,0,1.1,0,1.8c-2.1,0-4.1,0-6.1,0c-0.4-0.7-0.8-1.3-1.1-1.9
		c-0.3-0.9-1-1.1-1.8-0.9l0.1,0c0.6-1,1-1.1,1.7-0.4c0.5,0.5,0.9,1,1.2,1.6c0.6,1,1.3-0.4,1.9,0
		C288.6,228.1,288.6,227.1,288.8,226.5L288.8,226.5z"/>
	<path class="st0-bull" d="M256.8,347.1c0.2,1.8,0.3,3.5-0.6,5.2c-0.9-0.6-0.8-1.7-0.4-2.3c0.7-1,0.4-2,0.7-2.9c0,0-0.1,0-0.1,0
		C256.6,347.2,256.7,347.2,256.8,347.1L256.8,347.1z"/>
	<path class="st0-bull" d="M277.1,247.4c0.6,0.3,1.3,0.5,1.9,0.8c0.2,0.1,0.3,0.3,0.4,0.5c-1.2,0.6-1.9-0.1-2.5-0.9
		C277,247.7,277.1,247.6,277.1,247.4z"/>
	<path class="st0-bull" d="M258.1,345.9c-0.4,0.4-0.8,0.9-1.2,1.3l0.1,0C256.8,346.3,257.2,345.9,258.1,345.9L258.1,345.9z"/>
	<path class="st0-bull" d="M752.7,293.6c0,1.8,0.7,3.7-0.4,5.4c-0.2,0.4-0.1,0.9-0.1,1.4c-0.2,2.1-0.1,4.3-0.7,6.3
		c-0.5,1.5-0.9,3.3-2.2,4.4c-0.2,0.2-0.5,0.3-1.3,0.7C749.5,305.4,751,299.5,752.7,293.6z"/>
	<path class="st0-bull" d="M804.8,351.2c0.1,0.4,0.2,0.8,0.4,1.2c-0.2,0.1-0.5,0.3-0.8,0.5c0.2,0.4,0.4,0.8,0.7,1.3
		c0.8,0,1.7,0.1,2.7,0.2c-1.7,1.3-2.4,1.3-6.4,0.4c-1-1.5,1-1.3,1.2-2.4C802.6,351.2,803.9,351.4,804.8,351.2
		C804.8,351.3,804.8,351.2,804.8,351.2z"/>
	<path class="st0-bull" d="M804.8,343c0.3,0.5,0.5,1.1,0.9,1.7c-0.3,0.5-0.7,1.2-1.1,1.9c-2.1-1.8-2.1-1.8-1.5-3.4
		C803.6,343.1,804.2,343.1,804.8,343C804.8,343,804.8,343,804.8,343z"/>
	<path class="st0-bull" d="M777,353.4c0.7,0.5,1.4,0.9,2.2,1.5c-1.1,0.2-2,0.4-2.7,0.5c-0.1-0.8-0.1-1.7-0.2-2.8
		C776.6,353,776.8,353.2,777,353.4L777,353.4z"/>
	<path class="st0-bull" d="M777.1,353.4c0-0.8,0.1-1.6,0.1-2.4C778.4,351.8,778.3,352.2,777.1,353.4C777,353.4,777.1,353.4,777.1,353.4z"
		/>
	<path class="st0-bull" d="M807.2,351.2c0.2,0.7,0.4,1.3,0.6,2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4-0.3-0.9-0.6-1.5-1
		C806.5,351.9,806.9,351.6,807.2,351.2C807.3,351.2,807.2,351.2,807.2,351.2z"/>
	<path class="st0-bull" d="M781.4,353.5c0.4,0.7-1.4,1.2,0.7,2c-0.4,0-0.7,0.1-1.1,0.1c-0.2-0.3-0.6-0.6-0.6-0.9
		C780.2,354,780.4,353.4,781.4,353.5z"/>
	<path class="st0-bull" d="M263.5,342.2c0.2,0.2,0.5,0.5,0.7,0.7c-0.2,0.2-0.5,0.3-0.7,0.5C263.5,343,263.5,342.6,263.5,342.2z"/>
	<path class="st0-bull" d="M283.7,224.6c-0.6,0-1.1,0-1.6,0c-0.3-0.7-0.6-1.4-0.9-2.2C283.3,222.5,283.6,222.8,283.7,224.6z"/>
	<path class="st0-bull" d="M283,221.3c-0.5-0.7-0.8-1.2-1.1-1.7c-0.1-0.2,0-0.6,0.1-0.7c0.1-0.1,0.5-0.2,0.7-0.1c0.5,0.3,1,0.6,1.7,1.1
		C284,220.4,283.5,220.8,283,221.3z"/>
	<path class="st0-bull" d="M262.9,320.5c-0.2,0.2-0.4,0.4-0.5,0.4c-0.2,0-0.3-0.1-0.5-0.2c0.1-0.1,0.2-0.3,0.3-0.4
		C262.4,320.3,262.6,320.4,262.9,320.5z"/>
	<path class="st0-bull" d="M618.1,262.2c-0.6,0.7-1,1.2-1.7,1.9c-0.4-1.1-0.7-1.8-1.1-3.1C616.4,261.5,617.1,261.8,618.1,262.2z"/>
	<path class="st0-bull" d="M615.1,265c0.7,0.2,1.3,0.4,2.3,0.6c-0.3,0.6-0.4,1.4-0.7,1.5c-1.4,0.4-1-1.2-1.8-1.6
		C615,265.3,615,265.1,615.1,265z"/>
	<path class="st1-bull" d="M253.6,397.4c0.1-0.4,0.2-0.9,0.3-1.3C254.5,396.8,254.5,397.3,253.6,397.4L253.6,397.4z"/>
	<path class="st1-bull" d="M261,400.2c0,0.5-0.1,0.9-0.1,1.5C260.2,400.9,260.2,400.9,261,400.2C261,400.3,261,400.2,261,400.2z"/>
	<path class="st2-bull" d="M86.5,108.5c-0.4,0-0.9,0-1.3-0.1c0-0.1,0-0.3,0-0.4c0.4,0,0.9-0.1,1.3-0.1C86.5,108.2,86.5,108.4,86.5,108.5z
		"/>
	<path class="st2-bull" d="M50.2,106.4c0.4-1.7,1.4-1.8,2.8-2.2C52,105,51.3,105.6,50.2,106.4z"/>
	<path class="st2-bull" d="M222.3,352.8c0,0.4,0.1,0.8,0.1,1.2C221.8,353.6,221.8,353.4,222.3,352.8C222.4,352.8,222.3,352.8,222.3,352.8
		z"/>
	<path class="st2-bull" d="M69.8,94.1c-0.3-0.9-0.3-0.9,0.7-1.3C70.2,93.3,70,93.7,69.8,94.1z"/>
	<path class="st2-bull" d="M222.4,352.8c0.1-0.5,0.1-0.9,0.2-1.5c-0.7-0.2-1.3-0.4-2.1-0.6c0.2-1-0.7-2.3,0.8-2.9
		c0.9,0.4-0.7,1.6,0.6,1.7c0.6-0.7,1.1-1.4,1.7-2.2c-0.5-0.1-1.1-0.3-1.7-0.5c-0.3-0.7-0.5-1.5-0.9-2.3c-0.4-0.7-0.8-1.4-1.3-2.1
		c0.3-0.2,0.6-0.3,1.2-0.6c-1.7-0.8-0.9-2.2-1.2-3.6c0.5,0,0.9,0.1,1.4,0.1c0.2-1.2,0.3-2.4,0.5-3.6c-0.1,0-0.2-0.1-0.4-0.1
		c0.4-0.3,0.8-0.6,1.3-1c-1-0.9-1.7-1.8-1.2-3.1c0.3,0,0.6-0.1,0.7-0.1c-0.2-1.9-0.4-3.7-0.6-5.9c-0.7,0.4-1.5,0.7-1.9,1.2
		c-3.1,4-6,8.1-9.2,12.2c0.9,0.8,1.6,1.6,2.4,2.3c0.7,0.6,1.4,1.2,0.7,2.3c-0.2,0.3,0.3,1.1,0.1,1.4c-0.3,0.5-0.4,0.8-0.2,1.4
		c0.2,0.5-0.2,1.3-0.2,2c0.1,0.7,0.4,1.3,0.7,2.1c-0.9,0.1-1.5,0.1-2.1,0.1c0-0.5,0.1-1.2-0.1-1.4c-0.5-0.4-1.2-0.4-1.8-0.6
		c-0.1,0.1-0.2,0.3-0.2,0.4c0.3,0.4,0.6,0.7,0.9,1.1c-0.9,1.2-1.7-0.1-2.6,0.1c-0.1-0.5-0.2-1-0.3-1.5c-0.3,0-0.7-0.2-0.8-0.1
		c-1.2,0.9-2.3,0.2-3.4-0.1c-1.7-0.6-3.4-1.2-5.3-1.8c0.3-1.2,0.7-2.3,0.8-3.4c0.1-1.2,1.1-2.1,1.1-3.4c0-1.3,0.7-2.6,1-4
		c0.4-1.3,0.7-2.5,1-3.8c0.4-1.3,0.8-2.7,1.1-4c0.4-1.3,0.6-2.5,1.1-3.8c0.4-1.3,0.4-2.4-0.4-3.5c-1-1.4-1.8-3-2.7-4.4
		c-0.5-0.7-0.6-1.4,0.6-2.3c-0.9,0.1-1.2,0.2-1.7,0.3c-0.4-0.6-0.7-1.2-1.2-1.8c-0.6-0.8-0.6-1.6-0.3-2.6c0.3-1.2,1-1.5,2-1.6
		c0.7-0.1,1.4-0.3,2.1-0.6c0.3-0.1,0.6-0.4,1.3-0.9c-1.1-0.1-1.7-0.1-2.2-0.1c-0.5,0-1.1,0-1.8,0c0.1-0.6,0.1-1.1,0.1-1.6
		c-0.1,0-0.2-0.1-0.4-0.1c-0.3,0.5-0.7,1-1,1.6c-0.9,1.5-2.3,1.7-3.2,0.2c-0.7-1.2-1-2.4-0.4-4c1-2.9,1.3-6.1,1.1-9.2
		c0-1.2-0.3-2.5,0.6-3.6c0.2-0.3,0.1-0.8,0.1-1.2c0-2.3-0.1-4.6,0.5-6.9c0.1-0.3,0-1-0.2-1.1c-1.9-1-1.2-3-1.3-4.4
		c-0.1-2-0.3-4.1-0.3-6.1c0-0.9-0.1-2.6,0-3.6c0-1-0.1-2-0.2-3c0-0.1-0.3-0.2-0.7-0.4c-0.1,0.5-0.2,1-0.2,1.4c0,3.1,0,6.3,0,9.4
		c0,0.9-0.3,1.8,0.6,2.5c0,0,0.1,0.1,0.1,0.2c-0.1,3.4,0.5,6.8-0.3,10.2c-0.1,0.3,0.1,0.7,0.1,1.1c0,0.9,0,1.9,0,2.8
		c0,0.6-0.4,1.1-0.4,1.7c-0.2,3.1-0.4,6.3-0.6,9.4c0,0.3-0.2,0.5-0.4,0.9c-0.4-0.4-0.7-0.6-0.8-0.9c-10-16.8-20-33.6-30-50.3
		c-0.1-0.2-0.3-0.5-0.4-0.7c-1.6-2.3-2.6-4.8-3.2-7.5c-1.2-6-2.6-12-3.8-18c-0.1-0.6-0.2-1.2-0.3-2.2c1.5,0.8,2.8,1.4,4,2.1
		c6,3.2,12,6.5,18.1,9.6c1.1,0.6,2.1,1.5,3.4,1.9c1.4,0.4,2.6,1.3,3.8,2c1.2,0.6,2.5,1.3,3.7,1.9c1.3,0.7,2.5,1.4,3.8,2.1
		c1.2,0.7,2.4,1.3,3.6,2c0.4,0.2,0.9,0.4,1.3,0.6c1.2,0.4,1.9-0.1,1.6-1.4c-0.3-1.4-0.7-2.8-1-4.2c-0.6-2.6-1.5-5.1-1.8-7.8
		c-0.2-2.1-1.5-4.1-1.8-6.3c-0.3-2-1.1-3.8-1.5-5.7c-0.3-1.2-1-1.7-2.1-1.9c-2.4-0.4-4.9-0.9-7.3-1.3c-5.4-0.9-10.9-1.8-16.3-2.7
		c-4.5-0.7-9-1.5-13.4-2.3c-0.2,0-0.4-0.1-0.7-0.2c-0.7-3.2-1.5-6.5-2.2-9.8c-1.8-8.6-3.7-17.3-5.5-25.9c-0.3-1.4-0.9-2.3-2.4-2.6
		c-2.3-0.5-4.5-1.2-6.8-1.9c-0.7-0.2-1.3-0.9-1.7-1.2c-1.3-0.2-2.3-0.1-3.3-0.4c-5.1-1.6-10.1-3.2-15.2-4.9c-4.7-1.6-9.5-3.3-14.2-5
		c-1.9-0.7-3.8-1.4-5.7-2.2c1.6-2,3.1-4,4.7-6.1c-1.8-0.5-2.9,0.4-3,2.3c-1.1-0.3-1.6,0.8-2.3,1.2c-0.6,0.3-1,0.9-1.6,1.1
		c-1.1,0.5-1.9,0.1-2.2-1.3c-0.4,0.2-0.9,0.6-1.2,0.5c-2-0.8-4.2-1.2-6-2.7c0.2-0.6,0.4-1.1,0.6-1.5c-0.1-0.2-0.2-0.3-0.2-0.3
		c-0.7,0.3-1.5,0.5-1.6,1.7c-0.6-0.3-1.1-0.5-1.8-0.8c0.5-0.4,0.8-0.6,1.1-0.8c-0.5-0.8-1.1-1-1.9-0.6c-0.5,0.2-1.1,0.7-1.4,0.6
		c-0.7-0.3-1.3-0.9-1.8-1.6c-0.1,0-0.3-0.1-0.4-0.1c-0.9,0.2-1.9,0.4-2.4-0.6c-0.4-1,0.5-1.6,1.2-2.2c0.8-0.7,1.2-2,2.6-1.9
		c0.7-1.3,1.3-2.6,2-4c0.1-0.3,0.1-0.7,0.1-1c1-0.5,1,0.4,1.2,0.8c0.7-0.1,1-0.8,0.6-2c1.7,0.1,2.6-1.2,3.8-2c1.6-1.1,2.3-2.9,3.8-4
		c-0.9-0.1-1.7,0-2,1c-0.4,1-1.2,0.9-2,0.9c0.5-2.8,1.6-5,6.4-5c-0.6-0.3-1-0.4-1.4-0.7c0-0.6-0.1-1.3-0.1-2.2
		c0.4,0.1,0.7,0.2,1,0.2c1.2,0.1,1.7-0.2,1.8-1.4c0.1-1.1,0.6-1.9,1.2-2.8c0.8-1.3,1.7-2.6,2.6-3.7c0.4-0.5,0.4-1.3,0.6-1.9
		c-1.5-0.3-1.5-0.3-1.9,1.1c-0.1,0.3-0.5,0.5-0.6,0.7c-0.1,0.3-0.1,0.7-0.2,1.1c-0.6,0.1-1.2,0.2-1.9,0.2c0.1,0.9,0.4,1.9-1,2.3
		c-0.4,0.1-0.5,0.8-0.7,1.1c-1-0.1-2.1-0.2-3.1-0.3c-0.7-1-0.7-1-1.5,0c0.5,0,1,0,1.5,0c0.3,0.8,0.7,1.7,1,2.6
		c-0.8,0.5-1.5,0.9-2.3,1.4c0.3,0.5,0.6,1,0.9,1.5c-0.3,0.2-0.7,0.5-1,0.5c-1.8-0.2-2.8,0.6-3,2.4c0,0.2-0.3,0.4-0.5,0.6
		c0.8,1,1.4,2.1,0.4,3.4c-0.3-0.1-0.7-0.3-1.1-0.4c-0.5-0.1-1.2-0.2-1.4,0c-0.8,1.2-2.1,0.7-3.5,1c0.4,1.3,0.7,2.6,1.2,4.4
		c-0.9-0.6-1.3-0.9-1.8-1.3c-0.7,1.4-0.8,1.5,0.5,2.2c0.6,0.3,0.9,0.6,0.5,1.2c-1.5,0.9-2.5-0.9-3.8-0.6c-0.7-1.8,0.8-1.4,1.6-1.8
		c0.2-0.8,0.4-1.6,0.5-2.4c-0.8-0.3-1.5-0.5-2.2-0.8c0.1-0.2,0.1-0.4,0.1-0.5c0.6-0.9,2-0.6,2.5-1.9c0.4-0.9,1.3-1.5,1.9-2.1
		c1.4,0.9,2.6,0.7,4,0.3c-0.3-1.5-1.3-1.3-2.2-1.3c-0.9,0-1.8,0-3,0c0.5-2.7,2.8-3.6,4.1-5.4c-0.7-0.5-1.6-1.2-2.6-1.9
		c-0.9,1-0.4,2.7-1.2,3.9c-1.2,0.3-0.9-0.8-1.2-1.3c-0.8,0.1-1.6,0.2-2.4,0.2c-0.9,1.3-2,2.5-0.8,4.1c-1.1,0.8-1.7,0.7-1.7-0.3
		c0-0.9,0.1-1.7-0.4-2.5c-0.2-0.4,0.2-1.2,0.2-1.8c0-0.5-0.2-1-0.6-1.5c0.4-0.7,0.7-1.4,1.2-2.3c1.5,0,3.1,0,4.7,0
		c0.2,0.2,0.4,0.5,0.7,0.6c0.1,0,0.3-0.2,0.5-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c0.2-0.6-0.9-1.3,0-2c0.1-0.1-0.2-0.8-0.5-1.1
		c-0.9-0.9-0.9-1.1,0.2-1.8c0.4-0.2,0.6-0.6,1-1c1-1,1.4-2.8,3.5-2.4c0.6,0.1,0.9-0.7,1.2-2.4c-0.6,0-1.2,0.1-1.9,0.1
		c0.1-0.5,0.2-0.9,0.3-1.3c-0.5,0-1,0-1.8,0c0.4,0.3,0.7,0.5,0.9,0.7c-1.1,2-1.1,2-2.1,2.6c-0.1-0.3-0.2-0.6-0.3-0.9
		c-0.5,0.3-1.1,0.5-1.5,0.9c-0.4,0.4-0.5,1-0.8,1.7c-0.5-0.5-1-0.9-1.5-1.4c0.9-0.9,1.8-1.7,2.8-2.7c-0.6-0.8-1.1-0.5-1.8,0.1
		c-0.7,0.6-1.4,1.5-2.6,0.9c-0.1-0.4-0.2-0.9-0.4-1.4c-0.8,0-1.5,0-2.6,0c0.9,0.7,2.3,0.6,2.1,2c-1,0.3-2,0.6-3,0.9
		c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1,0.1-0.2,0.1-0.4,0.2c0,0.6,0,1.1,0,1.7c1.1-0.1,2.1-0.2,3.2-0.3c0,0.1,0.1,0.2,0.1,0.4
		c-0.8,0.5-1.7,1.1-2.9,1.8c-2.2-0.3-3.4,0.2-3.8,1.8c-0.6-0.6-1.2-1.1-1.9-1.7c0.3-1.2-0.1-2.6,0.7-3.8c0.1-0.2,0.1-0.6,0-0.9
		c-0.4-1.9-0.2-3.6,1-5.2c0.2-0.2,0.5-0.5,0.4-0.7c-0.5-2.5,1.9-4.3,1.9-6.7c0-1.1,0.9-2.2,1.4-3.3c0.2-0.5,0.3-1,0.6-1.4
		c0.2-0.4,0.9-0.9,1-0.8c0.9,0.8,1.4-0.5,2.2-0.4c2.5,1.7,5,3.4,7.5,5.1c2.5,1.7,5,3.4,7.6,4.9c2.6,1.5,5.3,2.7,8,4.1
		c-0.2,0.5-0.3,0.8-0.4,1.2c1.5-0.5,2.7,0,3.9,0.6c1,0.5,1.2,2.1,0.5,3c-0.3,0.4-0.8,0.8-1.2,1.2c0.5,0.3,1,0.6,1.7,1
		c0-0.5,0-0.9,0-1.5c0.9-0.2,2-0.3,1.7-1.6c1.9-0.1,1.9-0.1,2.6,1.7c-0.4-0.2-0.9-0.4-1.7-0.8c0.5,0.7,0.8,1.1,1,1.4
		c0.3-0.3,0.5-0.5,0.7-0.7c0.2-0.1,0.5-0.4,0.6-0.3c1.2,0.8,2.5,1.3,4,1.4c0.4,0,0.8,0.3,1.2,0.4c6.5,2.7,13,5.3,19.5,8
		c1.2,0.5,2.3,0.3,3.4-0.1c3.9-1.5,7.7-2.9,11.8-4.4c1,0.9,2.1,1.8,3.3,2.7c1.1,0.9,2.3,1.8,3.3,2.8c1.4,1.5,2.9,1.5,4.7,0.9
		c3.5-1.2,7.1-2.3,10.6-3.4c14.2-4.5,28.4-9,42.6-13.6c7.6-2.4,15.1-4.9,22.6-7.3c0.4-0.1,0.8-0.2,1.2-0.3c2.1,1.5,4.1,3.1,6.2,4.6
		c1.9,1.3,1.9,1.3,3.8,0c1.7-1.2,3.5-2.4,5.2-3.8c0.8-0.6,1.5-0.7,2.4-0.4c16.5,5.6,32.9,11.1,49.4,16.7c7.5,2.5,15,5,22.5,7.7
		c1.7,0.6,2.9,0.4,4.3-0.9c2-1.9,4.3-3.7,6.6-5.6c3.7,1.4,7.6,2.7,11.5,4.2c1.6,0.6,2.9,0.7,4.5,0c11.9-4.6,23.5-10,34.7-16.2
		c8.8-5,17.2-10.5,24.9-17c3.2-2.7,5.8-5.8,8.6-8.8c2.1-2.2,3.6-4.8,5.2-7.4c0.9-1.4,1.1-3.2,2.6-4.1c0.4-0.3,0.8-0.6,1.3-0.8
		c-0.3-0.3-0.8-0.5-0.9-0.9c-0.1-0.4,0-1,0.2-1.5c0.2-0.8,0.5-1.6,0.6-2.4c0.1-0.5,0-0.9,0.7-1.2c0.8-0.3,0.5-1.3,0.2-2
		c-0.9-1.9-0.9-2.4,0.7-3.7c0.2-0.2,0.2-0.6,0.3-0.8c-0.5,0-0.9,0-1.6-0.1c-0.2-1.2-0.4-2.5-0.7-3.9c0.7-0.1,1.4-0.1,2.1-0.2
		c-1.2-1.6-2.3-3-3.3-4.5c0.1-0.2,0.3-0.4,0.4-0.5c-0.7-1.2-1.4-2.4-2.1-3.6l0,0c0.5-0.3,1.1-0.6,1.6-0.9c-0.1-2.3-0.6-4.4-2-6.2
		c0,0,0,0,0,0c0.6-0.2,1.1-0.5,1.7-0.7c0.2,0.5,0.3,0.9,0.5,1.3c2.7-0.9,5.3-1.8,7.3-4c2.4,1.7,4.6,3.4,7.1,5.2
		c-0.3,0.2-0.7,0.4-1.4,0.8c0.8,0.2,1.1,0.4,1.4,0.4c0.3,0,0.6-0.2,0.9-0.3c0,0.2,0,0.6,0.2,0.7c0.6,0.3,1.2,0.4,1.8,0.7
		c-0.6,0.5-1.1,0.8-1.7,1.3c0.7,0.5,1.1,0.8,1.8,1.3c-0.9,0.2-1.5,0.4-2.1,0.5c-0.1,0.4-0.1,0.8-0.3,1.6c0.6-0.5,0.8-0.7,1.4-1.2
		c-0.2,1.4-0.3,2.6-0.5,3.9c1.4-0.2,1-2,2.3-2c-0.2,0.8-0.4,1.5-0.6,2.3c-0.3,0.1-0.6,0.2-0.9,0.3c0.2,0.4,0.4,0.8,0.6,1.2
		c-0.4,0.1-0.9,0.2-1.5,0.3c0.4,0.5,0.7,0.8,1,1.1c0.2-0.6,0.4-1,0.6-1.4c0.1-0.5,0.2-1,0.2-1.6c0.7-0.4,1.4-0.8,2.3-1.3
		c-0.8-0.4-1.2-0.7-1.7-0.9c-1.5-0.7-1.1-1.8-0.6-3c0.6-0.1,1.2-0.3,2.1-0.5c0.3,0.5,0.6,1.1,0.8,1.7c-0.4,0.2-0.8,0.4-1.1,0.7
		c-0.1,0.1,0.1,0.4,0.2,0.7c0.2-0.1,0.6-0.1,0.6-0.3c0.2-0.3,0.2-0.7,0.3-1.1c1,0.6,1.9,1.2,2.9,1.8c-0.2,0.4-0.2,0.6-0.4,0.7
		c-0.6,0.5-1.4,0.9-1.8,1.5c-0.4,0.6-0.5,1.5-0.8,2.3c-0.1,0.3-0.5,0.6-0.7,0.9c-0.6,0.7-1.2,1.4-1.6,2.2c-0.1,0.2,0.3,0.6,0.4,1
		c0.3-0.1,0.8-0.2,0.8-0.4c0.2-2.2,2.4-3,3-4.9c0.3-0.9,1.3-1.7,1.7-3c0.7,0.7,1.3,1.1,1.8,1.6c1.7,1.6,3.6,3.1,5,4.9
		c2.3,3.2,5.3,6,7.1,9.6c1.3,2.6,3.1,5,4.4,7.7c1,2.2,1.8,4.5,2.4,6.8c0.3,1.1,0,2.4,0,3.6c-0.2,0.1-0.4,0.2-0.5,0.3
		c-0.2,0.3-0.3,0.6-0.4,1c0.2,0.1,0.5,0.2,0.5,0.2c0.2-0.3,0.4-0.6,0.5-0.9c0-0.1-0.1-0.4-0.2-0.5c1,1.1,0.7,2.5,0.5,3.7
		c-0.1,0.3-0.7,0.5-0.9,0.7c0.2,0.4,0.7,0.9,0.7,1.3c0,1.1-0.1,2.2-0.2,3.3c-0.2,1.2-0.4,2.4-0.7,3.7c-0.3,1.6-0.6,3.1-2.7,3.7
		c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.1,0.7,0.2,1.1,0.4c-0.9,3.4-2.4,6.5-4.1,9.6c-5,8.7-12.1,15.3-20.3,20.9c-5.7,3.9-11.6,7.2-17.8,10
		c-6.3,2.9-12.5,6.1-18.9,9c-12,5.4-24.1,10.2-36.6,14.4c-11,3.7-22.1,7.3-33.2,10.9c-1.4,0.5-3,0.7-4.4,0.7c-4.5,0.1-9,0.2-13.5,0
		c-4.9-0.3-9.8,0.5-14.8,0.4c-5-0.2-10.1,0-15.1,0c-5,0-10,0-14.9,0c-2.3,0-2.5,0.2-3,2.5c-1.8,7.5-3.5,15-5.3,22.6
		c-1.7,7.5-3.4,15-5.2,22.5c-2.2,9.3-4.4,18.6-6.6,28c-3.6,15.2-7.1,30.5-10.7,45.7c-0.9,3.7-1.7,7.4-2.6,11.1
		c-1.1,4.7-2.2,9.5-3.3,14.2c-0.5,1.9-0.9,3.9-1.9,5.7c-0.1,0.2-0.1,0.4-0.1,0.6c0.6,2.1-0.6,4-0.9,6c-0.2,0.9-0.6,1.5-1.7,1.4
		c-0.3,0-0.5,0.4-1,0.8c0.8,0.3,1.4,0.4,1.9,0.6c-1,4.8-1.7,9.5-3.2,14.4c-0.6-1.1,0.2-2.4-1-2.9c0.4-0.2,0.8-0.5,0.9-0.6
		c-0.2-0.8-0.5-1.5-0.3-2.1c0.4-1.5-0.2-2.4-1.6-3.1c0,1.3-0.4,2.8,0.1,3.6c0.7,0.9-0.1,1.4,0.1,2.1c0.4,0,0.7,0.1,0.9,0.1
		c0,1.7-0.8,2.9-2.4,3.4c-0.1,0.5,0.7,1.1-0.3,1.3c0.3,0.4,0.8,0.7,0.9,1.2c0.3,1-0.4,1.7-1,2.3c-0.6,0.7-1.2,0.4-1.8-0.1
		c0.3-0.4,0.6-0.8,1.2-1.5C224.3,352.9,223.3,352.9,222.4,352.8L222.4,352.8z M422.1,112c0.6-0.3,1.2-0.7,1.4-1.7
		C422.5,110.7,422,111.2,422.1,112c-0.5,0-1.1,0-1.7,0C421.1,113.1,421.6,112.9,422.1,112z M234.9,224.1c-0.8-0.2-1.7-0.4-2.6,0.4
		C233.4,224.7,234.2,224.8,234.9,224.1c0.4-0.2,0.9-0.3,1.5-0.4C235.4,223.2,235.4,223.2,234.9,224.1z M197.4,171.4
		c0.2-1-0.3-1.4-1.2-1.7C196.1,170.8,196.9,171,197.4,171.4c0.1,0.4,0.2,0.9,0.3,1.4c0.8,0.3,1.7,0.5,2.5,0.8
		C199.2,172.8,198.9,171.3,197.4,171.4z M84,126.7c-2.2-0.4-2.2-0.4-3,2.5C82.4,128.5,83,127.3,84,126.7c0.6-0.4,1.3-0.8,0.8-1.8
		c-0.3,0-0.6,0-1,0C83.9,125.5,84,126.1,84,126.7z M408.9,119.4c0.4,0.2,0.7,0.4,1.1,0.6c0.1-0.4,0.3-0.8,0.4-1.3
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0.1-1.9,0.2-2.8,0.4c-0.3,0.1-0.5,0.5-1,1.1
		C406.8,120.7,408.2,121.2,408.9,119.4z M219.8,302.2c0.4,0.3,0.9,0.6,1.2,0.9c0.8-0.4,1.6-1.3,2-1.1c1.1,0.5,1.7-0.4,2.6-0.6
		c-0.2-0.3-0.5-0.6-0.7-0.6C223.2,301.3,221.3,300.9,219.8,302.2c-0.5,0.1-1,0.2-1.5,0.2c-1,0-1.5,0.5-1.7,1.7
		c1.4-0.2,2.4-0.8,3.2-1.7C219.9,302.3,219.8,302.3,219.8,302.2z M83.9,120.7c-0.4-0.2-0.8-0.4-1.1-0.7c-0.4-0.5-0.8-1-1.4-1.8
		c-0.5,1.8-1.7,1.2-3.2,1.2c1.7,1.7,4,0.8,5.2,2.7C83.6,121.3,83.7,121,83.9,120.7c0.2-0.1,0.5,0,0.7-0.1c0.2-0.1,0.3-0.4,0.3-0.6
		c0-0.1-0.2-0.4-0.4-0.4c-0.2,0-0.5,0.1-0.6,0.3C83.8,120.1,83.9,120.4,83.9,120.7z M83.1,117.3c-0.2-0.3-0.4-0.7-0.6-1.1
		c-0.3,0.5-0.5,0.7-0.8,1.2C82.3,117.4,82.7,117.3,83.1,117.3c0.7,0.5,1,1.4,1.7,1.7c0.5-1.1,0.7-2.2,1.4-2.9
		c0.7-0.7,1.8-1.1,2.7-1.6c0.9-0.5,1.5-1.4,1.4-2.3c-3.3,0.9-3.9,1.4-3.9,3c-0.6,0.1-1.2,0.1-1.9,0.2c-0.1,0.7-0.3,1.4-0.4,2
		C83.7,117.4,83.4,117.3,83.1,117.3z M226.9,327.9c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,1,0.8,2,0.6,2.9c-0.3,1.4,0.7,2.9-0.3,4.2
		c0,0,0,0.1,0,0.2c0.4,1.4,0.7,2.8,1.1,4.3c0.8-0.8,1.5-1.4,2.2-2.1c-0.1-1.5-0.2-3.2-0.4-4.9c-0.6,0.2-0.9,0.3-1.3,0.4
		c0-1.1,0-2.1,0-3.2C228.2,329.2,227.5,328.5,226.9,327.9z M228.4,261.4c-0.2,1.3-0.3,2.3-0.4,3.3c0,0.6-0.2,1.5,0.1,1.8
		c1.1,1.2,0.3,1.8-0.4,2.6c-0.2,0.2-0.4,0.5-0.6,0.7c0.5,0.2,0.8,0.4,1.2,0.5c1.1-1.4,1.4-2.8,1.4-4.5c-0.1-1.8,0-3.7,0-5.5
		c-0.1-1.7,0-3.6-0.5-5.2c-0.5-1.5-0.6-2.8,0.2-4.5c-1.1,0.5-1.9,1.3-1.6,2.1c0.3,1.3-0.1,2.8,0.9,4c0.3,0.4,0.2,1.4-0.2,1.9
		C227.4,260,227.7,260.8,228.4,261.4z M88.3,108.4c-1.1-1.4-2.5-1.2-3.8-1.3c-0.3,1.4-2.5,1.4-2.3,3.2c0.6,0,1.2,0.1,1.8,0.1
		c0.5,0,1,0,1.3-0.2c0.8-0.4,1.3-1.7,2.5-0.9C88,109,88.2,108.7,88.3,108.4z M93.9,111.7c0.1-0.2,0.3-0.4,0.3-0.7
		c0-0.4-0.2-0.8-0.3-1.3c-0.3,0.1-0.7,0.1-1,0.3c-0.7,0.4-1.3,0.4-1.8-0.3c-0.4-0.4-0.8-0.8-1.3-1.1C91.1,112,91.1,112,93.9,111.7z
		 M228.3,243.9c-0.4,1.5-0.7,2.5-0.9,3.5c-0.1,0.7,0.8,2.2,1.5,2.5c0.2-0.5,0.6-1,0.6-1.5C229.2,247,228.8,245.7,228.3,243.9z
		 M401.4,114.1c-1.9-0.2-3.4-0.3-4.4,1.3c1.2,0.3,2.3,0.6,3.6,1C400.9,115.7,401.1,115,401.4,114.1z M100.8,150c2-0.1,2.6-0.9,2.3-3
		c-1.3-0.6-1.7,0.6-2.3,1C100.8,148.8,100.8,149.4,100.8,150z M443.8,93.3c1.1-0.2,2.1-0.1,2.8-0.5c0.7-0.4,1.1-1.2,1.6-1.9
		c-0.4-0.2-0.7-0.5-1.1-0.8c-0.3,0.5-0.6,1-0.9,1.5C444.4,91.3,444.4,91.3,443.8,93.3z M69,101.5c0.1,0.5,0,1,0.2,1.2
		c0.4,0.3,1,0.6,1.5,0.5c0.4,0,0.7-0.5,1.1-0.8c0.5-0.5,0.7-1,0.2-1.6C71.1,101,70.2,101.2,69,101.5z M211.2,305.5
		c0-0.2-0.1-0.3-0.1-0.5c-1.7,0-3.4,0.2-4.8,1.2c-0.3,0.2-0.3,0.7-0.4,1.3C207.8,306.7,209.5,306.1,211.2,305.5z M102.7,116.3
		c-0.6,0.9-1,1.8-1.7,2.8c1.1-0.5,1.8-0.9,2.5-1.2c0.5,0.5,1,1,1.9,1.8C105,118,105,118,102.7,116.3z M402.3,114
		c1.1,1.2,1.8-0.2,2.7-0.1c1,0,1.7-0.5,2.4-1.4C405.3,111.9,404,113.2,402.3,114z M434.9,45.8c1.3,0.6,2.6,1.1,4.1,0.3
		c-0.9-0.6-1.7-1-2.6-1.6C436.2,45.1,434.9,44.4,434.9,45.8z M434.7,40.7c1.4,0.1,2.5,0.2,3.6,0.1c0.2,0,0.4-0.4,0.7-0.8
		c-0.9-0.2-1.7-0.1-2.2-0.4C435.7,39.1,435.3,39.7,434.7,40.7z M224.7,277.2c0-2.3,0.6-4.7-0.2-7
		C223.3,272.6,224.9,274.9,224.7,277.2z M226.9,302.4c-0.1-1.2,0.7-1.4,1.4-1.6c0.6-0.2,1.2-0.3,1-1.2c-0.6,0-1.2,0.9-1.8,0
		c-0.1-0.1-1.1,0.2-1.1,0.4C226.2,300.7,225.9,301.6,226.9,302.4z M450.3,81.9c-0.7,0-1.4,0-2.1,0c0,0.6,0,1.2,0,1.7
		c0.8,0.1,1.5,0.1,2.4,0.2C450.5,83.1,450.4,82.5,450.3,81.9z M64.3,114.1c0.3,1-0.9,2.2,0.8,2.7c0.5-1,1-1.8,1.6-2.9
		C65.7,114,65.1,114,64.3,114.1z M71.2,110.7c0.3-0.8,0.5-1.4,0.6-1.8c-0.6-0.3-1-0.5-1.5-0.7c-0.4,0.6-0.8,1.2-1.4,2.1
		C69.8,110.4,70.4,110.5,71.2,110.7z M454.4,82.6c-0.4,0-0.6,0.1-0.9,0c-0.4-0.1-0.7-0.3-1.1-0.4c-0.3,0.7-0.6,1.4-0.9,2
		C453.4,84.5,453.4,84.5,454.4,82.6z M444.4,94.6c-0.3,0.5-0.5,1.1-0.9,1.4c-0.5,0.3-1.1,0.3-1.7,0.5c0.6,0.9,1.5,1.2,2.4,0.6
		C445.2,96.4,444.8,95.5,444.4,94.6z M429.4,39.5c1.4-0.6,2.5-1.7,4.5-1.3c-1.3-1.3-2.4-0.8-3.3-0.8
		C430.1,38.1,429.8,38.8,429.4,39.5z M303.2,158.1c-0.4,2.5-0.1,3,1.8,3.4C305,160.1,304,159.4,303.2,158.1z M427.4,53.3
		c1.4,0.1,2.4,0.3,2.8-0.8c0.1-0.4-0.1-0.9-0.3-1.7C428.9,51.7,428.3,52.3,427.4,53.3z M97.9,139.8c-1.2,0.5-2.1,0.6-1.9,1.8
		c0.6,0.3,1.2,0.6,2.1,1.1C98,141.7,97.9,140.9,97.9,139.8z M75.7,102.7c-0.7,0.3-1.5,0.3-1.7,0.7c-0.2,0.3,0.1,1,0.2,1.6
		C75.7,104.4,75.7,104.4,75.7,102.7z M91.3,140.7c1-0.3,1.9-0.5,2.8-0.7c0-0.9-0.5-1.4-1.1-1.3C92.1,138.8,91.5,139.5,91.3,140.7z
		 M99.6,121.4c0.7-0.5,1.8-0.6,1.1-1.9c-0.3,0.1-0.6,0.2-0.9,0.3c-0.5,0.2-1.4-1-1.5,0.2C98.1,120.4,99,120.9,99.6,121.4z
		 M419.9,106.7c1.5,0,2-0.5,2.4-2C420.7,104.5,420.5,105.8,419.9,106.7z M291.7,160c-1,1.4-1,1.4,0.1,2.7
		C291.6,161.9,293.4,161,291.7,160z M225.1,278.1c-0.6,1.5,0,2.6,0.2,3.7C225.7,280.7,225.9,279.6,225.1,278.1z M224.7,247.4
		c-1.2-0.3-1.8,0.2-2.2,1.2c0.4,0.3,0.8,0.6,1.2,1C224.1,248.8,224.4,248.2,224.7,247.4z M455.4,92.5c-0.5,1.1-0.9,2.1-1.3,3
		C456.2,94,456.2,94,455.4,92.5z M454.6,92.4c0.3-0.9,0.5-1.5,0.8-2.3c-0.8,0.1-1.4,0.1-2.3,0.2C453.7,91,454.1,91.6,454.6,92.4z
		 M455.3,102.1c0.8-1.1,0.2-2,0.1-3C454,99.7,454,100.2,455.3,102.1z M404.9,118.3c-0.2-0.8-0.3-1.4-0.5-2.1
		c-1.3,0.5-1.2,1.3-0.9,2.3C403.9,118.4,404.3,118.4,404.9,118.3z M88.2,152.2c-2.2-1.5-2.2-1.5-2.6,0.3
		C86.4,152.4,87.1,152.3,88.2,152.2z M413.3,122.9c1.2-0.2,1.9-0.4,3-0.6c-0.5-0.5-0.7-1-1-1.1C414.4,121,414.1,121.9,413.3,122.9z
		 M444.7,81.7c0.1,0.7,0.1,1.1,0.3,1.5c0,0.1,0.7,0,0.7,0c0-0.9,1-0.7,1.5-1.4C446.4,81.8,445.7,81.8,444.7,81.7z M402,120.8
		c1.2,1.2,1.3,1.1,2.8-0.7C403.7,120.4,402.9,120.6,402,120.8z M433.9,46.9c-0.4-0.8-0.8-1.4-1.2-2c-0.2,0-0.3,0.1-0.5,0.1
		c0.1,0.7,0.1,1.4,0.2,2.3C433.1,47.1,433.4,47,433.9,46.9z M433.2,86.8c-0.3,1.3,0.4,1.8,1.5,2.2C434.5,88,434.6,87,433.2,86.8z
		 M112.9,148.8c0.9,0.6,1.5,0.9,2.1,1.3C115.2,148.5,115.2,148.5,112.9,148.8z M399.8,121.4c-1.3-0.3-1.3-0.3-2.2,0.1
		C399.1,121.9,399.1,121.9,399.8,121.4z M435.5,86.2c0.5,1.2,1.1,1.8,2.1,2.1C437.1,86.7,437.1,86.7,435.5,86.2z M425.9,40
		c-0.2,0.5-0.4,0.9-0.7,1.4c0.8,0,1.4,0,2,0c0.1,0,0.2-0.3,0.4-0.5C427.1,40.6,426.6,40.4,425.9,40z M442,75.5c0,1.4,0.6,2,1.6,2.1
		C443,76.8,442.5,76.1,442,75.5z M100.3,132.8c-1-0.7-1.7-1.2-2.7-1C98.1,133.1,99,133.1,100.3,132.8z M227.1,324.5
		c1.5-0.6,1.5-0.6,1.8-1.8C227.8,322.7,227.2,323.1,227.1,324.5z M108.9,116.8c-1.1,0.3-1.8,0.6-1.6,1.7c0.1,0.2,0.5,0.4,0.9,0.6
		C108.4,118.3,108.6,117.7,108.9,116.8z M208.9,311.3c-0.8-0.2-1.6-0.3-2.3-0.4C207.1,312.2,208,312.4,208.9,311.3z M94.5,127.2
		c-0.6-0.1-1.2-0.1-1.8-0.1c0,0-0.1,0.4-0.1,0.5C93.2,128.2,93.8,128.1,94.5,127.2z M426.4,75.8c1.3,1,1.5,0.9,2.2-0.6
		C427.8,75.4,427.2,75.6,426.4,75.8z M221.6,319.1c-0.7,0.1-1.4-0.4-1.6,0.9c0.5,0.2,1,0.4,1.6,0.7
		C221.6,320,221.6,319.6,221.6,319.1z M409.7,124.6c1,0,1.6,0,2.4,0c-0.4-0.5-0.7-0.9-1-1.3C410.6,123.7,410.4,123.9,409.7,124.6z
		 M78.4,104.6c0.3-1.6-1.2-0.2-1.3-1.2c0-0.2-0.7,0.3-0.5,1C77.1,104.5,77.6,104.5,78.4,104.6z M224.5,331.4c0.8-1.8-1-1.7-1.4-2.5
		c-0.1,0.1-0.3,0.2-0.4,0.3C223.2,329.8,223.8,330.5,224.5,331.4z M429.8,47.3c-0.5-1.9-0.8-2-1.9-1.1
		C428.5,46.6,429,46.9,429.8,47.3z M91.8,148.4c-0.4-0.6-0.8-1.1-1.1-1.7C89.8,148.3,89.8,148.3,91.8,148.4z M425.4,114.2
		c1.6,0.2,0.7-1.2,1.3-1.7C425.3,112.4,425.9,113.7,425.4,114.2z M450.5,93.4c0.5-0.8,1-1.5,1.5-2.3
		C450.1,90.8,450.7,92.4,450.5,93.4z M461.7,85c-0.1-0.2-0.1-0.3-0.2-0.5c-0.8,0.3-1.7,0.5-2.5,0.8c0.1,0.2,0.1,0.3,0.2,0.5
		C460,85.5,460.8,85.3,461.7,85z M227.2,306.3c-0.9,0.6-0.9,0.6,0.2,2.2C227.4,307.8,227.3,307.2,227.2,306.3z M232.3,245.5
		c0.6,0.5,1,0.8,1.6,1.4C233.7,245.2,233.7,245.2,232.3,245.5z M214.9,305.4c0.1-0.1,0.3-0.1,0.4-0.2c-0.1-0.4-0.3-0.8-0.4-1.2
		c-0.2,0.1-0.6,0.4-0.6,0.4C214.5,304.8,214.7,305.1,214.9,305.4z M223.5,338.8c0.6,0,1,0.1,1.1-0.1c0.6-0.7,0.2-1.2-0.4-1.7
		C224.1,337.5,223.9,338,223.5,338.8z M86.3,136.4c0.1,0.2,0.2,0.4,0.3,0.6c0.4-0.2,0.9-0.4,1.3-0.6c-0.1-0.2-0.2-0.5-0.3-0.7
		C87.1,136,86.7,136.2,86.3,136.4z M416.5,122.7c1-0.2,1.5-0.4,1.2-1.2c-0.1-0.1-0.6-0.2-0.6-0.2
		C416.9,121.7,416.7,122.1,416.5,122.7z M433.5,43.7c-0.8-1.1-0.8-1.1-1.6,0C432.5,43.7,433,43.7,433.5,43.7z M78.1,109
		c-0.6,0.1-1.2,0.2-2.1,0.3C77.1,110.6,77.6,109.6,78.1,109z M434.9,50.4c0.5,0,1-0.1,1.7-0.1C435.9,49.3,435.4,49.2,434.9,50.4z
		 M415,109.4c-0.7,0.3-1.1,0.4-1.5,0.6c0,0,0,0.3,0.1,0.3C414.1,110.7,414.5,110.4,415,109.4z M410.1,111.3
		c-0.4,0.4-0.8,0.7-1.1,1.1c0.1,0.1,0.2,0.2,0.2,0.3c0.4-0.2,0.9-0.4,1.4-0.6C410.4,111.7,410.3,111.6,410.1,111.3z M91,141.3
		c-0.5,0.9-0.6,1.4,0.2,1.6c0.1,0,0.3-0.1,0.5-0.2C91.5,142.3,91.3,141.9,91,141.3z M86.4,145.1c1.6,0.2,1.6-0.4,1.2-1.5
		C87.3,144.1,87,144.4,86.4,145.1z M451.5,105.5c0.7-0.3,0.7-0.7,0.4-1.3c-0.1-0.1-0.5-0.1-0.5-0.1
		C450.9,104.7,450.9,105.1,451.5,105.5z M406.1,126.7c-0.5-0.8-0.8-1.3-1.2-2C404.4,125.9,404.8,126.3,406.1,126.7z M219.6,148.7
		c0,1.5,0,1.5,1.6,1.5C220.6,149.7,220.2,149.2,219.6,148.7z M224.5,335.6c-0.3-1.6-1.2-0.9-2.3-0.7
		C223.1,335.1,223.7,335.3,224.5,335.6z M453.8,77.9c0.1-0.1,0.3-0.3,0.4-0.4c-0.5-0.3-0.2-1.5-1.9-0.8
		C452.9,77.2,453.4,77.5,453.8,77.9z M139.2,149.5c-0.1,0-0.2,0-0.3,0c0,0.5,0,1.1,0,1.6c0.1,0,0.2,0,0.3,0
		C139.2,150.6,139.2,150.1,139.2,149.5z M212.7,311.2c0-0.1,0-0.3,0-0.4c-0.5,0-1.1,0.1-1.6,0.1c0,0.1,0,0.2,0,0.4
		C211.6,311.2,212.1,311.2,212.7,311.2z M428.2,67.6c-0.1,1.6,0.9,1.6,1.4,2.1c0.1-0.1,0.2-0.2,0.3-0.3
		C429.4,68.9,428.9,68.3,428.2,67.6z M451.9,107.3c-0.5,0.9-0.6,1.3,0.1,1.6c0.1,0,0.3-0.1,0.5-0.2
		C452.4,108.3,452.2,107.9,451.9,107.3z M462.1,91.5c0.5-0.3,0.9-0.6,0.5-1.3c-0.1-0.1-0.4-0.2-0.5-0.1
		C461.7,90.6,461.5,91.1,462.1,91.5z M407.5,116.2c-0.8,0.6-1.4,0.9-1.9,1.3C406.6,117.8,407.3,117.7,407.5,116.2z M409.4,116.3
		c0.1,0.2,0.2,0.4,0.3,0.5c0.5-0.3,1-0.6,1.5-0.9c0-0.1-0.1-0.2-0.1-0.3C410.6,115.8,410,116,409.4,116.3z M442.5,81
		c0-0.1,0-0.3,0-0.4c-0.5,0-1,0-1.5,0.1c0,0.1,0,0.2,0,0.4C441.5,81,442,81,442.5,81z M431.4,69.1c1.4,0.3,1.4,0.3,1.6-0.7
		C432.4,68.6,431.9,68.9,431.4,69.1z M231.3,213.2c0.1-0.2,0.3-0.3,0.4-0.5c-0.3-0.3-0.7-0.6-1-0.8c-0.1,0.1-0.2,0.2-0.3,0.4
		C230.7,212.6,231,212.9,231.3,213.2z M70.6,97.1c1.4,0.1,1.4,0.1,1.5-0.7C71.7,96.5,71.3,96.7,70.6,97.1z M419.3,119.5
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4,0.1-0.8,0.3-1.1,0.5c0,0,0.1,0.3,0.1,0.5C418.5,119.8,418.9,119.6,419.3,119.5z M227.5,311.9
		c0-0.7,0-1.2,0-1.8c-0.1,0-0.2,0-0.3,0c-0.2,0.6-0.4,1.2-0.7,1.8C226.9,311.9,227.2,311.9,227.5,311.9z M137,153.2
		c0.5,0.5,0.9,0.9,1.6,1.6C138.7,153.4,138,153.2,137,153.2z M228.2,150c0.1-0.1,0.2-0.2,0.2-0.3c-0.4-0.3-0.5-1.2-1.9-0.8
		C227.3,149.4,227.8,149.7,228.2,150z M422.8,73.4c-0.1,0.2-0.1,0.4-0.2,0.5c0.4,0.2,0.8,0.3,1.2,0.5c0.1-0.2,0.1-0.5,0.2-0.7
		C423.6,73.6,423.2,73.5,422.8,73.4z M88.8,121.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.3,0.8,0.6,1.2,0.9c0.1-0.1,0.2-0.2,0.3-0.3
		c-0.2-0.3-0.4-0.6-0.7-0.9C89.2,121.2,89,121.2,88.8,121.2z M432.4,77.9c0.8-0.4,1.4-0.7,2-1c-0.6-0.2-1-0.3-1.5-0.5
		C432.7,76.8,432.6,77.2,432.4,77.9z M81.5,123.8c0.5,0.3,0.8,0.6,1.5,1.1c-0.2-0.9-0.4-1.4-0.5-2C82.2,123.3,81.9,123.5,81.5,123.8
		z M201.7,301.9c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0.4-0.4,0.8-0.5,1.2c0,0,0.4,0.2,0.5,0.2C201.3,302.6,201.5,302.2,201.7,301.9z
		 M428.8,59.3c-0.2-0.8-0.4-1.3-0.6-2C427.4,58.3,427.6,58.8,428.8,59.3z M215,165.8c0.5,0.4,0.9,0.6,1.3,0.9
		c0.1-0.2,0.2-0.4,0.2-0.5C216.4,165.3,215.9,165.4,215,165.8z M98.1,127.9c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.7-1.2,0.8-1.2,1.6
		c0.1,0.1,0.3,0.2,0.4,0.3C97.3,128.9,97.7,128.4,98.1,127.9z M438.8,50.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.3-0.9,0.7-1.5,1.1
		C438,51.1,438.6,50.9,438.8,50.1z M426.1,101.1c-0.4,0.8-0.6,1.2-0.8,1.7C426.5,102.8,426.5,102.3,426.1,101.1z M94.9,137.1
		c0.7-0.3,1-0.4,1.4-0.6c0.1,0-0.1-0.6-0.2-0.7C95.4,135.6,95.1,136,94.9,137.1z M80.5,106c-0.6-0.3-1.3-0.6-2.1-0.9
		C78.7,106.6,79.7,106.1,80.5,106z M228.5,135.3c0-0.1,0-0.3-0.1-0.4c-0.4,0.2-0.9,0.3-1.3,0.5c-0.1,0.1,0,0.5,0,0.7
		C227.6,135.8,228,135.6,228.5,135.3z M222.4,228.4c0.1,0.1,0.3,0.2,0.4,0.3c0.2-0.6,0.3-1.2,0.5-1.8c-0.1,0-0.2-0.1-0.4-0.1
		C222.8,227.3,222.6,227.8,222.4,228.4z M145.5,138.9c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7,0.2-0.5,0.9-0.5,1.8
		C145,139.7,145.3,139.3,145.5,138.9z M221.9,316.8c0.2-0.1,0.3-0.3,0.5-0.4c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1,0.1-0.2,0.2-0.3,0.3
		C221.5,316.3,221.7,316.5,221.9,316.8z M203.5,255.3c0.8,0.1,1.4,0.1,2.2,0.2C205,254.7,204.5,254.4,203.5,255.3z M446.3,77.8
		c0.1,0.2,0.2,0.3,0.3,0.5c0.3-0.2,0.6-0.4,0.9-0.7c0,0-0.2-0.3-0.2-0.3C446.9,77.5,446.6,77.7,446.3,77.8z M432.5,51.1
		c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0.1,0.3,0.1,0.5,0.2C432.8,52.4,432.5,51.7,432.5,51.1z M430.9,58.7
		c-0.3,0.3-0.4,0.5-0.6,0.7c0.2,0.2,0.5,0.4,0.7,0.6c0.1-0.2,0.4-0.4,0.4-0.6C431.5,59.1,431.2,59,430.9,58.7z M445.4,60.8
		c0.1,0,0.3,0,0.4,0c0-0.4-0.1-0.8-0.1-1.1c-0.1,0-0.2,0-0.3,0C445.4,60,445.4,60.4,445.4,60.8z M436.6,100c-0.7,0-1,0.1-1.6,0.1
		C435.6,101,436,101.4,436.6,100z M228.1,272.7c0.2,0,0.3,0,0.5-0.1c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1,0-0.2,0.1-0.3,0.1
		C228,271.9,228,272.3,228.1,272.7z M429.6,98.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3-0.2,0.6-0.3,0.8-0.5c0.1-0.2,0.1-0.5,0.1-0.8
		c-0.1,0-0.2-0.1-0.3-0.1C430.2,97.3,429.9,97.7,429.6,98.1z M456.6,96.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.3,0.4-0.3,0.6
		c0,0.3,0.1,0.5,0.2,0.8c0.1,0,0.2,0,0.3,0C456.6,97.3,456.6,96.9,456.6,96.4z M144.3,152.5c0,0.2-0.1,0.3-0.1,0.5
		c0.5,0.2,1,0.3,1.6,0.5c0.1-0.2,0.1-0.4,0.2-0.5C145.3,152.8,144.8,152.7,144.3,152.5z M435.5,64c0.4,1.2,0.9,1.1,1.7,0.6
		C436.5,64.3,436.2,64.2,435.5,64z M213.4,304.2c-0.2,0-0.4,0-0.6,0.1c0.1,0.4,0.2,0.9,0.3,1.3c0.1,0,0.3,0,0.4-0.1
		C213.5,305,213.4,304.6,213.4,304.2z M451,80.7c0.7,0.4,1.1,0.6,1.6,0.9C452.8,80.4,452.2,80.5,451,80.7z M416.7,113.4
		c-0.4-1.3-1-1.3-1.7-0.9C415.5,112.7,415.9,113,416.7,113.4z M406.2,105c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0.6-0.2,1.1-0.3,1.7
		c0.1,0,0.2,0,0.4,0.1C405.8,106.1,406,105.5,406.2,105z M232.2,299.5c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3,0.2-0.7,0.4-1,0.7
		c0,0,0.2,0.3,0.2,0.3C231.5,299.8,231.8,299.7,232.2,299.5z M429.8,36.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4,0.3-0.8,0.5-1.2,0.8
		c0.1,0.1,0.2,0.3,0.3,0.4C429,36.9,429.4,36.7,429.8,36.4z M450.1,73.4c-0.1,0.3-0.2,0.6-0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.4
		c0.1-0.1,0.3-0.4,0.2-0.4C450.6,73.8,450.4,73.7,450.1,73.4z M443.8,71.1c0-0.2,0-0.3,0-0.5c-0.4,0.1-0.7,0.1-1.1,0.2
		c0,0.1,0,0.2,0,0.3C443.1,71.1,443.4,71.1,443.8,71.1z M192.4,157.2c-0.1,0-0.3,0-0.4-0.1c0,0.4-0.1,0.7-0.1,1.1c0.1,0,0.2,0,0.4,0
		C192.3,157.9,192.3,157.6,192.4,157.2z M399.7,100.1c-0.2,0-0.4,0.1-0.5,0.1c0.1,0.5,0.3,0.9,0.4,1.4c0.4-0.4,0.5-0.7,0.4-1
		C399.9,100.4,399.8,100.2,399.7,100.1z M456.4,76.9c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1,0.4-0.3,0.9-0.4,1.3c0.1,0,0.2,0.1,0.2,0.1
		C456,77.6,456.2,77.2,456.4,76.9z M457.3,82.1c-0.2,0.2-0.4,0.4-0.6,0.6c0.2,0.3,0.5,0.5,0.7,0.8c0.2-0.2,0.4-0.3,0.5-0.5
		C457.7,82.7,457.5,82.4,457.3,82.1z M144.1,149.2c0.1-0.1,0.2-0.2,0.3-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1,0.1-0.2,0.3-0.3,0.4
		C143.5,148.8,143.8,149,144.1,149.2z M138.2,141.9c-0.3-0.2-0.5-0.5-0.7-0.5c-0.2,0-0.5,0.3-0.4,0.5c0,0.2,0.3,0.5,0.4,0.5
		C137.7,142.4,137.9,142.2,138.2,141.9z M227.6,319.9c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1-0.3-0.3-0.4-0.3
		c-0.2,0.1-0.4,0.3-0.5,0.5C227.4,319.4,227.6,319.7,227.6,319.9z M438.7,98.5c0,0.2,0,0.3,0.1,0.5c0.3-0.1,0.7-0.1,1-0.2
		c0-0.1,0-0.2,0-0.3C439.4,98.5,439.1,98.5,438.7,98.5z M415.5,87.6c-0.5,0.5-0.8,1,0.3,1.4C415.7,88.4,415.6,88,415.5,87.6z
		 M90.1,106.5c-0.3-0.1-0.5-0.3-0.6-0.3c-0.2,0.1-0.3,0.3-0.5,0.5c0.1,0.1,0.3,0.3,0.4,0.3C89.6,106.9,89.8,106.8,90.1,106.5z
		 M76.2,107.9c1,0.4,1.2-0.2,1.5-0.7c0,0-0.1-0.1-0.3-0.3C77,107.2,76.7,107.5,76.2,107.9z M428.4,49.3c0,0.2,0.1,0.3,0.1,0.5
		c0.5-0.2,1-0.4,1.5-0.5c0-0.1-0.1-0.3-0.1-0.4C429.5,49,428.9,49.1,428.4,49.3z M100.1,126.1c-0.1-0.1-0.3-0.2-0.4-0.3
		c-0.3,0.4-0.6,0.9-0.9,1.3c0.1,0.1,0.2,0.1,0.3,0.2C99.4,126.8,99.7,126.4,100.1,126.1z M105.1,124.5c-0.4-0.2-0.6-0.3-0.8-0.3
		c-0.1,0-0.4,0.3-0.3,0.4c0.1,0.2,0.3,0.4,0.4,0.6C104.6,124.9,104.7,124.8,105.1,124.5z M85.2,123c-0.3-0.2-0.5-0.4-0.6-0.4
		c-0.2,0-0.4,0.2-0.5,0.3c0,0.1,0.2,0.4,0.2,0.4C84.6,123.3,84.8,123.2,85.2,123z M410.2,121.8c0.2-0.5,0.3-0.7,0.5-0.9
		c-0.4,0-0.7,0-1.3-0.1C409.7,121.2,409.9,121.4,410.2,121.8z M229.5,308.1c0.1,0.6,0.1,0.9,0.2,1.5c0.4-0.4,0.7-0.6,1.1-0.9
		C230.3,308.5,230,308.3,229.5,308.1z M411.2,118.1c0.1,0.1,0.3,0.3,0.4,0.4c0.3-0.3,0.5-0.6,0.8-0.9c-0.1-0.1-0.2-0.2-0.2-0.3
		C411.8,117.6,411.5,117.8,411.2,118.1z M415.9,116.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.3,0.3-0.6,0.5-1,0.8c0.1,0.1,0.2,0.2,0.3,0.3
		C415.2,116.6,415.5,116.3,415.9,116.1z M420.9,114.4c-0.2,0.1-0.4,0.1-0.7,0.2c0.2,0.4,0.3,0.7,0.5,1.1c0,0,0.3-0.1,0.3-0.1
		C421,115.2,420.9,114.8,420.9,114.4z M225.1,286c0.1,0,0.3,0,0.4,0c0-0.4,0-0.7-0.1-1.1c-0.1,0-0.3,0-0.4,0
		C225.1,285.3,225.1,285.7,225.1,286z M226.1,290.4c0-0.4,0-0.8,0-1.3C225.2,289.9,225.2,289.9,226.1,290.4z M239.5,297.5
		c-0.6-0.1-1.2-0.2-1.2,1C238.9,298.1,239.2,297.8,239.5,297.5z M206.3,336.6c-0.3,0.5-0.4,0.7-0.5,0.9c0,0.2,0.2,0.3,0.3,0.5
		c0.2-0.1,0.5-0.2,0.5-0.4C206.7,337.4,206.5,337.1,206.3,336.6z M304.3,169.1c0.4,0,0.8,0,1.3,0C305,168.4,304.6,168.1,304.3,169.1
		z M209.7,162.2c-0.1,0.2-0.1,0.3-0.2,0.5c0.3,0.1,0.6,0.2,1,0.3c0-0.1,0.1-0.2,0.1-0.3C210.3,162.5,210,162.3,209.7,162.2z
		 M207.6,161.4c0-0.2,0.1-0.3,0.1-0.5c-0.5-0.1-1-0.2-1.5-0.3c0,0.2-0.1,0.3-0.1,0.5C206.6,161.2,207.1,161.3,207.6,161.4z
		 M114,158.4c0-0.1,0-0.2,0.1-0.3c-0.3-0.1-0.6-0.1-0.9-0.2c0,0.1,0,0.3,0,0.3C113.4,158.3,113.7,158.4,114,158.4z M255.2,156.8
		c-0.2-0.1-0.4-0.3-0.5-0.3c-0.1,0-0.2,0.2-0.4,0.4c0.2,0.1,0.4,0.3,0.5,0.4C255,157.1,255.1,157,255.2,156.8z M98.4,156.5
		c-0.1,0.2-0.3,0.5-0.2,0.5c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2-0.3,0.3-0.4C98.9,156.9,98.7,156.7,98.4,156.5z M232.6,154.4
		c0,0.2,0,0.4,0,0.5c0.3,0,0.7,0,1,0c0,0,0-0.2,0.1-0.4C233.3,154.5,232.9,154.4,232.6,154.4z M192.5,155.3c0.2-0.3,0.4-0.5,0.5-0.7
		c0-0.1-0.3-0.4-0.4-0.3c-0.2,0-0.4,0.2-0.6,0.3C192,154.8,192.2,155,192.5,155.3z M194.6,154.8c0.9-0.1,1.2-0.1,1.7-0.2
		c-0.3-0.4-0.5-0.6-0.7-0.9C195.4,154,195.2,154.2,194.6,154.8z M231,248.4c0.1-0.3,0.3-0.5,0.3-0.7c0,0-0.3-0.3-0.4-0.2
		c-0.2,0.1-0.3,0.3-0.5,0.5C230.6,248.1,230.8,248.2,231,248.4z M216.3,253.6c-0.2,0-0.3,0.1-0.5,0.1c0.1,0.3,0.1,0.6,0.2,0.9
		c0.1,0,0.2,0,0.3-0.1C216.4,254.2,216.4,253.9,216.3,253.6z M173.7,257.4c0.1,0,0.3,0,0.4,0c0-0.3-0.1-0.6-0.1-0.9
		c-0.1,0-0.2,0-0.3,0C173.7,256.8,173.7,257.1,173.7,257.4z M229.4,137.5c-0.1,0.4-1.3,0.8,0,1.2
		C229.4,138.3,229.4,137.9,229.4,137.5z M100.4,137.3c-0.2-0.3-0.3-0.5-0.4-0.7c-0.1,0.1-0.4,0.2-0.4,0.3c0,0.2,0.1,0.4,0.2,0.7
		C100,137.5,100.2,137.4,100.4,137.3z M195.5,259.5c0,0.9,0,1.5,0,2C195.9,261,196.4,260.5,195.5,259.5z M450.6,88.2
		c0.2-0.1,0.3-0.1,0.5-0.2c-0.2-0.3-0.3-0.6-0.5-0.8c0,0-0.2,0.1-0.3,0.1C450.3,87.6,450.5,87.9,450.6,88.2z M103.9,133.1
		c-0.1-0.8-0.1-1.1-0.2-1.6c-0.4,0.2-0.6,0.4-1,0.7C103,132.4,103.2,132.6,103.9,133.1z M117,129.6c-0.3-0.2-0.5-0.5-0.6-0.4
		c-0.2,0.1-0.4,0.3-0.5,0.5c0.1,0.1,0.3,0.4,0.4,0.4C116.6,129.9,116.7,129.8,117,129.6z M448.1,88.1c-0.1-0.1-0.2-0.1-0.2-0.2
		c-0.2,0.3-0.4,0.5-0.6,0.8c0.2,0.1,0.3,0.2,0.5,0.2C447.8,88.7,448,88.4,448.1,88.1z M420.5,122.9c0,0.1,0,0.2,0,0.3
		c0.3,0,0.6,0,0.9,0c0-0.1,0-0.2,0-0.3C421.1,122.9,420.8,122.9,420.5,122.9z M412.9,123.9c0.2-0.1,0.3-0.1,0.5-0.2
		c-0.2-0.3-0.3-0.6-0.5-0.9c0,0-0.2,0.1-0.4,0.1C412.6,123.3,412.7,123.6,412.9,123.9z M425.4,44.9c-0.2,0.4-0.3,0.7-0.6,1.1
		c0.6-0.1,0.9-0.1,1.5-0.1C425.9,45.5,425.7,45.3,425.4,44.9z M419.1,113.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.4,0-0.5,0.1
		c-0.1,0.1-0.2,0.3-0.3,0.5c0.1,0.1,0.2,0.1,0.3,0.2C418.6,114.3,418.8,114.1,419.1,113.9z M226.9,298.7c-0.1-0.2-0.1-0.4-0.2-0.5
		c0,0-0.2,0-0.3,0.1c0.1,0.2,0.1,0.4,0.2,0.7C226.6,298.8,226.7,298.8,226.9,298.7z"/>
	<path class="st2-bull" d="M29.8,106.2c0.3-1.4-0.4-2.7-0.8-3.9c-0.1-0.2-0.1-0.5-0.2-0.8c0.8,0,1.5,0,2.4,0c0.1-0.5,0.2-1,0.4-1.6
		c1.2-0.2,1.3-1.4,1.9-2.1c-1.1,0.3-1.9,0.9-1.8,2.2c-0.2-0.2-0.3-0.4-0.5-0.6c-0.8,0.3-0.9,1.8-2.1,1.3c-0.5-0.7,0.1-1.3,0-2.1
		c-0.1-0.7,0.6-1.8,1.5-2.4c1.5-1.2,3.2-2.2,4.1-4.2c0.4-0.9,1.1-1.6,1.7-2.4c-0.6-0.8-2.3,0.1-1.7-1.8c-0.9,0.4-1.4,0.6-2,0.9
		c0.4,0.4,0.8,0.7,1,1.1c0.2,0.4,0.2,0.9,0.3,1.5c-2.5,2.6-4.9,5.3-8.2,6.9c0.4-1.4,1.2-2.3,2.6-2.6c0.3-1.5-0.1-2.6-1.5-3.3
		c0.3-1,1.4-2-0.2-3.2c-0.4,1.2-0.9,2.3-1.3,3.6c-0.3,0-0.7-0.1-1.2-0.1c-0.6,1.4,0.6,3.3-1.4,3.9c0.3,0.9,0.5,1.6,0.8,2.5
		c-1.7,0-3,0.5-4.6,0.3c-1.6-0.2-3.5,0.4-5,1.1c-1.1,0.5-1.8,1.7-2.8,2.7c-1.2-1.5-3.3-1.8-3.9-3.9c0,0.5,0,1,0,1.9
		c-0.7-0.6-1.1-1-1.6-1.4c1.1-1.5,2.3-2.6,4.1-2.9c0.5-0.1,1-0.4,1.4-0.7c0.3-0.2,0.4-0.8,0.6-0.8c1.1,0,1.4-0.7,1.5-1.6
		c0-0.2,0.5-0.3,0.8-0.5c-1.1-0.7-3-0.5-3.6,0.5c-0.6,1-1.4,1.4-2.5,1.5c-0.3,0-0.7,0.1-1,0.3c-1,0.7-2,0.7-2.9,0.1
		c-1-0.8-1.3-2.1-1.2-3.3c0.2-1.6,0.9-2.9,2.8-3c0.6-0.1,0.9-0.3,1.3-0.8c0.5-0.6,1.3-0.9,2-1.2c1.8-0.7,3.6-1.5,5.5-1.9
		c1.6-0.4,3.2-0.7,4.8-0.5c1.2,0.2,2.3,1.1,3.3,1.7c0.3,1.9-0.2,3.4-1.4,4.8c-0.1,0.1-0.1,0.4-0.1,0.6c0,0.2,0.2,0.4,0.7,0.9
		c0.2-0.5,0.3-1,0.5-1.5c0.3,1.2,0.8,2.2-0.1,3.1c0.2,0.2,0.5,0.4,1,0.7c0.3-3,0.6-5.7,1-8.7c0.9-0.5,1.8,0,2.8,0.4
		c0-0.4,0-0.7-0.1-1c0.9-0.5,1.2,0.4,1.7,0.7c1.9,0.1,1.9-1.5,2.2-2.7c0.1-0.2-0.4-0.5-0.8-1.1c-0.3,0.8-0.4,1.3-0.5,1.6
		c-0.9,0.2-1.7,0.5-2.6,0.7c-0.4-0.9-1.9-1.3-1.4-2.6c-0.5-0.2-0.9-0.3-1.2-0.6c-0.3-0.4-0.5-0.9-0.8-1.3c-0.3-0.4-0.6-0.8-0.9-1.2
		c0.6-0.2,1-0.3,1.4-0.5c-1.5-0.3-2.9-0.6-4.4-0.9c0.4,1.4-1,3.2,1,4.4c0.2-0.6,0.4-1.1,0.6-1.8c1,1.6,1.5,0,2.3-0.1
		c-0.2,0.7-0.4,1.5-0.8,2c-1.1,1.6-2.3,1.6-3.7,0.1c-1.8,1.4-4.1,0.9-6.2,1.5c-1.5,0.4-3.1,0.6-4.6,1.5c-2,1.3-4.1,2.5-6.5,3.9
		c0.1-0.7,0.1-1.3,0.3-1.3c1-0.4,0.3-1,0.2-1.5C2.6,86.2,3,86,3.4,85.9c-0.2-0.4-0.3-0.7-0.5-1c-0.6,0.4-1.1,0.7-1.8,1.2
		c-0.6-3.2-0.2-6.1,1.3-9.1c-0.5-0.5-0.9-1.2,0-1.9c0.2-0.2,0.5-0.5,0.6-0.8c0.7-4.1,3.2-7.3,5.5-10.6c4.7-6.7,10.4-12.5,16.7-17.7
		c8.6-7.2,17.9-13.4,27.6-19c9.1-5.2,18.6-9.8,28.2-13.8c4.3-1.8,8.7-3.5,13-5.2c0.4-0.1,0.8-0.2,1.2,0c-0.6,0.3-1.3,0.7-1.9,1
		c-7.4,3.8-14.6,8.1-21.3,13.1c-7.4,5.5-14.2,11.6-19.1,19.6c-3.6,5.9-5.6,12.3-5,19.3c0.2,2,0.7,4.1,1,6.2c-0.5,0.3-1.5,0.5-1,1.7
		c0.4,0,0.9,0,1.3,0.2c0.5,0.3,0.9,0.8,1.1,1.3c0.1,0.4-0.2,1-0.5,1.4c-0.8,0.9-1.7,1.7-2.8,2.9c-0.8,0.1-1.8,0.6-2.4,1.9
		c-0.2,0.5-1,0.7-1.5,1.1c0.1,0.2,0.3,0.4,0.4,0.6c-2,0.9-2.2,3-3.6,4.4c-1.2,1.2-2.1,2.8-3.3,4.4c-0.3-0.5-0.5-0.7-0.8-1.2
		c0.2,1,0.3,1.7,0.4,2.7c1.9-1.4,3.6-2.6,4.5-4.8c0.9-2.2,2.6-3.7,4.9-4.5c-0.2-0.3-0.3-0.5-0.6-1c1.4,0.4,2.6,1,3.9,0.2
		c-0.7-1-1.7-0.3-2.7-0.8c-0.1-0.2-0.2-0.7-0.3-1.2c2.5-0.4,3.8-2.2,5-4.3c1,1.6,2,3,2.9,4.5c0.2,0.4,0.3,1.2,0.1,1.4
		c-1.7,1.4-2.3,3.7-4.1,4.9c-0.4,0.3-1,0.4-1.5,0.5c-0.5,0.1-1.1,0.1-1.7,0.2c0.4,1,1,1.9,0.9,2.7c-0.1,0.7-1.1,1.3-1.8,2.2
		c-0.3-0.5-0.5-0.9-0.8-1.3c-0.5,0-1.1,0.1-1.6,0.1c-0.6,1.1,0.2,1.9,0.6,2.7c1.1,0.3,1.9,0.1,2.7-0.7c1.6-1.7,3.9-2.9,4.1-5.7
		c0.5-0.1,1-0.2,1.6-0.3c-0.2-2.3,2.1-2.8,3.2-4.5c0.6,0.8,1.2,1.4,1.6,2c-0.5,1.3-2.7,0.6-2.2,2.5c1.5,0.6,1.9-1,3-1.6
		c0.6,0.9,1.3,1.8,1.9,2.8c-0.4,0.6-0.8,1.2-1.4,2c0.8-0.1,1.3-0.2,1.7-0.2c0.2,1.9-1.5,2-3.1,2.8c1.2,0.1,1.8,0.1,2.8,0.2
		c0,2.3,0,4.5,0,6.8c1.3-0.8,2.3-1.4,3.3-1.9c0.1-0.9,0.2-1.8,0.3-2.8c-1,0.3-2,1-3.2,0.2c0.9-1.3,1.1-2.8,0.7-4.4
		c-0.1-0.3-0.5-0.5-0.8-0.7c0.9-1.1,0.9-1.1,2.3,0.1c-0.2,0.3-0.3,0.5-0.5,0.9c0.2,0.1,0.4,0.2,0.5,0.2c0.6-0.3,1-0.3,1.5,0.2
		c1.1,1,2.3,2,3.4,3c1,0.9,0.8,1.1-0.3,1.9c-0.6,0.5-1.7,0.6-1.5,1.9c0.1,0.4-0.7,0.9-1.1,1.4l0,0c-0.2,0.6-0.3,1.3-0.5,2
		c-1,0.1-1.1-0.8-1.4-1.3c-0.9,0.5-1.6,0.9-2.7,1.4c0.1,0.6,0.2,1.3,0.3,2.1c0.1,0.9,0.1,0.9-1,1c-0.2,0-0.6,0.2-0.7,0.3
		c-0.4,1.1-1,2.3-1,3.4c0,2.2-0.3,4.2-1.4,6c-0.4,0.7-0.5,1.7-0.7,2.5c0.2,0.1,0.3,0.1,0.5,0.2c0.7-0.9,1.3-1.8,2.1-2.9
		c0.3,0.3,0.5,0.7,0.7,0.9c0.7,0.9-1.5,1.8,0,2.6c-0.1,1-0.3,2-0.4,2.7c-0.9,0.2-1.5,0.3-2.4,0.5c0.1-0.4,0.1-1,0.2-1.6
		c0.1-0.5,0.3-1.1,0.5-1.7c-1.6-0.1-1.8,1-2.3,1.7c-0.5,0.6-0.8,1.3-1.1,2c-0.2-0.1-0.4-0.1-0.4-0.1c-0.6-2.1-1.4-2.3-3.1-0.9
		c-0.3,0.2-0.5,0.4-1,0.6c0.1-0.4,0.2-0.7,0.4-1.2c-0.7-0.3-1.4-0.7-2.1-0.7c-0.8,0-1.4,0.4-1.6,1.3c-0.1,0.2-0.7,0.3-1.1,0.3
		c-1,0.1-1.4-0.8-2.2-1.4c1.1-0.3,1.5,1.3,2.5,0.3c-0.3-0.3-0.7-0.6-1-0.9c1.5-1,2.9-2,4.4-3.1c-0.3-0.4-0.5-0.9-0.6-0.8
		c-0.5,0-1.1,0.2-1.5,0.4c-0.4,0.3-0.7,0.7-1.1,1.1c-1.4,1.7-1.8,1.7-2.9-0.2c-1.3,0-1.1,1.2-1.4,2c-0.5,0.3-1.3,0-1.8,1
		c-0.4,1-0.9,0.1-1.1-0.5c-0.7,0.3-1.3,0.6-2.2,1c0.7,0.2,1.2,0.4,1.6,0.5c-0.6,1.3-0.6,1.3,0.6,3.2c2-0.5,0.2-2.2,0.8-3.1
		c0.6-0.2,1.2-0.5,1.8-0.7c0.6,0.6,0.6,2,1.9,1.4c0.2-0.1,0.7,0.5,1,0.8c0.5,0.5,1,0.9,1.5,1.4c0.7-0.8,0.6-2.2,2.2-1.6
		c-0.1,0.5,0,1.3-0.3,1.4c-1.2,0.5-1.6,1.6-2.1,2.6c-0.1,0.2-0.4,0.2-0.9,0.4c0.1-0.7,0.2-1.1,0.3-1.7c-0.3,0.1-0.6,0-0.7,0.1
		c-0.4,0.7-0.8,1.1-1.6,0.3c-0.4-0.4-1.2-0.4-1.9-0.6c-0.2,0.7-0.4,1.3-0.8,2.3c-0.5-0.9-0.8-1.5-1.1-2.2c-0.9,0.5-2,1.1-3.1,1.8
		c-1.2-1.4-2.7-2.3-3.5-4c-0.4-0.8-1.4-1.4-2.2-2c-0.8-0.6-1.7-1.2-2.7-1.8c0.3-0.6,1.5-0.3,1.3-1.2c0.5,0.1,1,0.3,1.6,0.5
		c-0.1-0.8-0.1-1.7-0.2-2.6c0.9,0.7,1.5,1.5,2.6,0.9c1.1-0.7,2.8-0.8,2.2-2.9c0.9-0.4,1.7-0.7,2.6-1c-0.2-0.4-0.4-0.9-0.6-1.4
		c-0.2,0.3-0.4,0.5-0.7,0.9c-0.2-0.3-0.4-0.5-0.7-0.9c-0.6,1.2-1.1,2.4-1.7,3.5c-0.2-0.3-1.5-0.1-1-1.4c-1.4-0.3-1.4,1-1.8,1.8
		c-1.5-0.2-1.2-1.4-1.6-2.2c-0.6-0.3-2.1,0.1-1.4-1.6c1.1,0.9,1.7,0,2.3-0.7C29.4,105.9,29.6,106.1,29.8,106.2c0.3,0.4,0.2,1.2,1,1
		c0.4-0.1,0.8-0.4,1.3-0.6c-0.4-0.3-0.6-0.4-0.9-0.6c0.4-0.3,0.6-0.5,0.8-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4,0.3-0.7,0.5-1.1,0.8
		C30.4,105.8,30.1,106,29.8,106.2z M33.7,114.5c-0.4-0.1-0.8-0.2-1.5-0.3C32.8,115.3,33.2,115.3,33.7,114.5c0.1-0.1,0.4-0.1,0.6-0.1
		c0.1,0.2,0.3,0.5,0.4,0.7c0.2-0.2,0.4-0.5,0.9-1c-0.8,0.2-1.1,0.3-1.4,0.4c0-0.5,0-1,0-1.5c-0.1,0-0.2,0-0.3,0
		C33.8,113.5,33.7,114,33.7,114.5z M8.5,68.4c1.7-0.9,1.8-1.1,0.9-2.5C8.9,66.7,8.2,67.4,8.5,68.4c-1.2,0.1-1.6,1.1-2.5,2.2
		C7.6,70.3,7.9,69.3,8.5,68.4z M52.2,110.2c-1.2-0.8-1.8-0.7-1.9,0.6c-0.1,0.6,0.2,1.3,0.3,1.9c0.1,0,0.2,0,0.3,0
		c0.1-0.4,0.3-0.8,0.4-1.3c0.4,0.1,0.9,0.2,1.8,0.4C52.6,111,52.4,110.6,52.2,110.2c0.8,0.1,1.3-0.2,1.7-1
		C52.8,108.7,52.7,109.8,52.2,110.2z M54.2,108.4c0.1-0.7,0-1.3,0.2-1.6c0.5-0.7-0.2-1-0.3-1.4c-0.1-0.5,0.2-1,0.2-1.5
		c-0.1-0.5-0.4-0.9-0.5-1c0.4-0.7,0.7-1.3,0.8-1.8c0.1-0.3-0.3-0.7-0.5-1.1c-1.9,1.8-2.7,4.3-5.1,5.4c-0.4,0.2-0.5,0.8-0.8,1.1
		c-0.2,0.2-0.4,0.5-0.7,0.6c-1.1,0.5-2.2,1-3.5,0.9c-0.5-0.1-1.1,0.1-1.6,0.1c0,0.2,0,0.3,0,0.5c1.3,0,2.6-0.1,3.9,0
		c2.3,0.1,4.6,0.1,6.4-1.6c0.1-0.1,0.4,0.1,0.7,0.1c-0.1,0.5-0.2,0.9-0.2,1.4C53.7,108.4,54,108.4,54.2,108.4z M51.4,96.9
		c1-0.4,1.9-1.2,2.8-1.2c0.9,0,2.2,0.5,2.6,1.2c0.7,1.3,1.4,0.9,2.3,0.7c0.5-1.2,0-1.7-1-1.9c-2-0.5-4.1-0.8-6.1-1.3
		c-0.5-0.1-0.9-0.6-1.3-0.9c-0.5,0.2-1.3,0.6-1.4,0.4c-1-1.3-3,0-3.8-1.5c-1.6,0.3-2.9-1-4.4-0.9c-0.3-0.6-0.5-1.1-0.7-1.7
		c-0.5,1.3-0.9,2.3-1.3,3.2c1.4,0,2.9,0,4.7,0c0.8,0.9,1.9,1.5,3.4,1.3c0.3,0,0.7,0.1,1,0.2c0.9,0.5,1.9,1,3,1.5
		C51.2,96.3,51.3,96.7,51.4,96.9z M19.2,93.6c0.4-1.3,0.8-2.3,1-3.4c0.1-1,0.5-2.1-0.5-3.1c-0.6,0.9-0.7,2.1-2,2.2
		c-0.2,0-0.5,1.1-0.3,1.4c0.5,0.8,1,0.3,1.4-0.3c0,0,0.2,0,0.4,0.1C19.2,91.4,19.2,92.5,19.2,93.6z M36,111.1
		c1.1-0.6,2.3-1.2,3.6-1.8c-1.9-0.9-3.7,0.2-5.4-0.1c-0.1,0.1-0.1,0.2-0.2,0.4C34.7,110,35.3,110.5,36,111.1z M55.5,84.9
		c-1.1-0.5-1.9-0.3-2.6,0.4c-1.1,1.1-2.2,2.1-3.4,3.2c0.6,0.5,1.1,0.9,1.7-0.1c0.3-0.6,0.8-1.1,1.4-1.5
		C53.6,86.3,54.2,85.1,55.5,84.9z M31.1,92.5c0.1-0.6,0.2-1.3,0.3-1.6c-0.5-0.5-0.8-0.9-1.4-1.5c-0.5,1.4-0.9,2.7-1.4,4.4
		C29.7,93.3,30.4,92.9,31.1,92.5z M55.6,101.7c0.5-0.2,1-0.3,1-0.5c0.2-0.9,0.2-1.7,0.2-2.6c0-0.1-0.4-0.3-0.5-0.2
		c-0.2,0.1-0.5,0.3-0.5,0.5C55.7,99.8,55.7,100.6,55.6,101.7z M28.9,77.3c-0.5,0.3-1.1,0.7-1.7,1c0.7,0.7,1.4,1.3,2,2
		c0.1-0.1,0.4-0.2,0.6-0.3C29.5,79.1,29.2,78.3,28.9,77.3z M37.1,104.3c-0.2,0.8-0.3,1.6-0.5,2.4c0.3,0.3,0.6,0.5,0.9,0.8
		c0.1,0.1,0.2,0.1,0.4,0.1C38.5,106,38.5,105.9,37.1,104.3z M12.6,79.5c-0.8,0.4-1.6,0.7-2.7,1.2C12.6,81.3,12.7,81.3,12.6,79.5z
		 M14.7,97.5c-2.6,0-3.2,0.5-2.3,2.1C13,99,13.7,98.4,14.7,97.5z M46.7,101.4c0.4,0.5,0.6,0.8,0.9,1.2c0.5-0.7,0.8-1,1-1.4
		c0.3-0.4,0.6-0.8,0.8-1.2C48.1,99.7,47.9,101.1,46.7,101.4z M31.6,79.9c1.1-0.2,1.9-0.4,2.8-0.6C33,78,32.6,78,31.6,79.9z
		 M6.4,81.9c-1.3,1.5-1.3,1.5-0.9,2.5C7.3,83,7.3,83,6.4,81.9z M31,86.2c-0.7,0.8-1.2,1.4-1.8,2.1C31.6,88.4,31.7,88.3,31,86.2z
		 M36,119.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.7,0-1.4,0-2.3,0c0.2,0.6,0.4,1,0.6,1.5C34.9,120.3,35.4,119.9,36,119.5z M35.7,97.1
		c0,0.2,0.1,0.3,0.1,0.5c0.5,0.2,0.9,0.3,1.6,0.6c0-0.7-0.1-1.3-0.1-2C36.7,96.6,36.2,96.9,35.7,97.1z M45.7,64.1
		c0.3-0.8,0.4-1.4,0.6-1.9c0-0.1-0.1-0.2-0.1-0.3C45,62.5,44.9,62.9,45.7,64.1z M21.5,95.5c-0.2-0.1-0.3-0.2-0.5-0.2
		c-0.4,0.9-0.8,1.9-1.3,2.9c0.4,0,0.6,0,0.6,0C20.7,97.2,21.1,96.3,21.5,95.5z M32.4,81.7c-0.5-0.5-0.9-0.9-1-0.8
		c-0.5,0.2-1,0.6-1.4,0.9c0.1,0.1,0.1,0.3,0.2,0.4C30.8,82,31.5,81.9,32.4,81.7z M31.5,83.6c0.2,1.2,0.5,1.6,1.1,1.4
		c0.1,0,0.3-0.6,0.2-0.6C32.5,84.1,32.1,83.9,31.5,83.6z M44.8,104.9c-0.9-0.9-0.9-0.9-2.1-0.2C43.6,104.8,44.2,104.9,44.8,104.9z
		 M45.7,80.5c-1.2-0.2-1.4,0.3-1.2,1.1c0,0.1,0.1,0.1,0.2,0.2C45,81.4,45.3,81,45.7,80.5z M55.3,86.8c0.1,0.1,0.2,0.3,0.3,0.4
		c0.4-0.2,0.8-0.4,1.2-0.6c-0.1-0.1-0.1-0.3-0.2-0.4C56.1,86.4,55.7,86.6,55.3,86.8z M28.9,62.1c0.2,0,0.3-0.1,0.5-0.1
		c-0.1-0.3-0.1-0.6-0.2-0.8c0,0-0.3,0.1-0.5,0.1C28.7,61.5,28.8,61.8,28.9,62.1z M33.2,104.9c0.1-0.1,0.3-0.3,0.4-0.4
		c-0.3-0.3-0.6-0.6-1-0.9c-0.1,0.1-0.2,0.2-0.3,0.3C32.6,104.2,32.9,104.6,33.2,104.9z M11,99c-0.5,0.5-0.8,0.8-1.1,1.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.6-0.5,0.8-0.7C11.1,99.7,11.1,99.5,11,99z M34,81.5c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.3,0.2-0.5,0.3-0.8,0.5c0,0,0.1,0.2,0.1,0.3C33.4,81.8,33.7,81.6,34,81.5z M34.9,75.8c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.5,0.3-0.5,0.8-0.1,1.5C34.6,76.6,34.7,76.2,34.9,75.8z M24.9,74.6c0.1-0.2,0.2-0.4,0.3-0.5c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.2,0-0.3,0.2-0.4,0.3C24.6,74.3,24.7,74.4,24.9,74.6z M26.3,88c-0.3-0.3-0.5-0.5-0.5-0.5c-0.2,0.1-0.4,0.3-0.6,0.5
		c0.1,0.1,0.2,0.4,0.3,0.3C25.7,88.3,25.9,88.1,26.3,88z"/>
	<path class="st2-bull" d="M419.6,28c-0.2-0.8-0.4-1.6-0.6-2.6c1.8,0.2,2.7,1.2,3.8,2.1c0.3,0.3,0.7,0.6,1.1,0.8c2.3,0.8,4.3,2.2,6.4,3.5
		c0.2,0.1,0.5,0.3,0.7,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-1.8,0.5-3.7,0.9-5.8,1.5c-0.4-0.2-1.2-0.6-2-1c-0.6,1.1-1.2,2-1.8,3.1
		c0.3,0,0.6,0.1,1,0.1c0,0,0,0,0,0c0.2,1,0.2,1-0.7,1.7c1.5,1,1.4,1.4-0.5,2.4c-0.8-1.1-2.1-2.1-2.2-3.3c-0.2-1.3-0.9-1.4-1.8-1.8
		c0.3-0.5,0.4-1.2,1.2-0.8c0.3,0.3,0.3,1.2,1.1,0.9c0.2-0.1,0.2-0.4,0.3-0.6c-0.5-0.1-0.9-0.1-1.4-0.2c-0.1-0.6-0.2-1.2-0.3-2
		c-0.7,0.5-1.2,1.1-1.5,1.1c-0.5-0.1-0.9-0.7-1.4-1c-0.8-0.7-1.5-1.4-2.6-2.3c1.3-0.7,2.3-1.3,3.5-1.9c-0.5-0.6-0.9-1-1.5-1.7
		c-0.4,0.8-0.6,1.2-0.8,1.6c-0.9,0-1.7,0-2.8,0c-0.6-0.5-1.4-1.2-2.1-1.9c0.9-0.9,1.7-1.8,2.8-3c-1-0.3-1.6-0.5-2.3-0.7
		c-0.5-0.8-0.5-0.8-1.7-0.1c0.8,0,1.3,0.1,1.8,0.1c0.2,0.5,0.4,1,0.6,1.4c-2.5,1.3-4.1-0.5-5.8-1.7c-0.8-0.5-1.4-1.2-2.2-1.9
		c0.4-0.1,0.8-0.3,1.4-0.5c-0.8-0.8-1.5-1.6-2.4-2.4c0.4-0.5,0.8-1,1.2-1.7c0.9,0.9,1.7,1.7,2.7,2.6c1-0.4,2.4-0.8,3.2,0.9
		c0.1,0.2,0.6,0.1,0.9,0.2c1.3,0.5,3,0.5,3.5,2.4c0.2,0.6,0.9,1.1,1.5,1.6c0.1-0.3,0.2-0.7,0.4-1.3c0.4,0.2,0.8,0.5,1.2,0.7
		c0.6,0.3,1,1,1.8,1c0.9,0,1.3,0.9,1.3,1.8c0,0.9,0,1.7,0,2.9C418.9,28.3,419.3,28.1,419.6,28c-0.1,0.6-0.2,1.1-0.3,1.9
		c0.3-0.2,0.8-0.4,0.8-0.5C420,28.9,419.8,28.5,419.6,28z M426.5,30.9c-0.2-0.1-0.4-0.2-0.5-0.2c0,0-0.2,0.2-0.2,0.3
		c0.1,0.2,0.2,0.3,0.3,0.4C426.2,31.2,426.3,31.1,426.5,30.9z"/>
	<path class="st2-bull" d="M43.5,127.6c0.6,0.2,1.1,0.4,1.6,0.6c0.1,0.5,0.1,0.9,0.2,1.6c1.2-0.9,0.4-2,0.9-2.8c1.4-0.2,2.9-0.5,4.3-0.7
		c-0.1,0.6-0.3,1.3-0.4,1.5c-1,1-1.7,1.8-2.6,2.6c0.7,0.6,1.2,0.9,1.6,1.3c-0.4,0.8-2,0.7-1.5,2.4c0.9-0.4,1.7-0.7,3-1.2
		c-0.6,0.9-0.9,1.3-1.2,1.8c-0.1,0.2-0.1,0.5-0.1,0.8c-0.9,0-1.7,0-2.5,0c-0.9-1.6-0.9-3,0.5-4.4c-1.3-0.4-1.9,0-2.7,1.8
		c-0.5-0.2-0.9-0.9-1.7,0c-0.6,0.7-3.3-0.3-3.8-1.2c-0.4-0.6,0-1.8,0.9-1.9C41.7,129.5,42.3,128.3,43.5,127.6z"/>
	<path class="st2-bull" d="M396.3,13.8c-0.2,0.7-0.3,1-0.6,1.8c0.7-0.6,1-0.9,1.5-1.2c1,0.3,2.1,0.6,3.2,0.9c0,0.1,0,0.2-0.1,0.3
		c-0.7-0.1-1.5-0.2-2.2-0.3c-0.2,1.5,1,2.2,1.6,3.5c-1.4-0.1-2.2-1.1-3.1-1.6c-1-0.6-2-1.3-3-2c0.1-0.5,0.2-1.1,0.4-2
		c-0.6,0.5-1,0.9-1.5,1.3c-1.5-1-2.9-1.9-4.4-2.8c-1.5-0.8-3-1.5-4.5-2.3c-1.6-0.8-3.1-1.7-4.6-2.8c2.1,0.3,3.9,1.2,5.8,2
		c1.9,0.7,3.8,1.5,5.7,2.4C392.2,12,394.2,12.9,396.3,13.8z"/>
	<path class="st2-bull" d="M22.3,119.4c-0.4-0.2-0.8-0.4-1.5-0.8c0.9-0.5,1.6-0.8,2.5-1.2c-0.2-0.5-0.5-1-0.7-1.5
		c-0.1-0.5-0.1-1.1-0.2-1.9c-0.4,0.8-0.6,1.4-0.9,1.9c-0.8,0.4-1.5,0.8-2.4,1.3c-1-0.9-2.2-2-3.6-3.2c0.8-0.3,1.3-0.6,1.9-0.8
		c0.6-0.1,1.3-0.3,1.7,0c0.6,0.4,0.9,0.1,1.4-0.1c1.9-0.9,3.6,0.3,3.7,2.5C24.3,117.6,24.3,117.6,22.3,119.4z"/>
	<path class="st2-bull" d="M73.6,144.9c-1.2,0.3-1.7,1-1.3,2.4c-1.3-0.5-2-1.4-2.7-2.5c-0.2,0.5-0.3,0.9-0.5,1.5
		c-0.7-0.2-1.3-0.4-2.1-0.7c0-1,0.1-1.8,0.1-2.7c1.4-1.2,2.9-2.3,4.9-1.5C72.5,143.8,72.5,143.8,73.6,144.9z"/>
	<path class="st2-bull" d="M16.2,104.9c0.9-0.1,1.8-0.1,3-0.2c0.3-2.5,2.1-3.9,4.3-5.5c0.1,1.2-0.8,1.9-0.2,2.8c0.1,0.1,0,0.5-0.1,0.6
		c-1.7,0.8-1.3,3-2.7,3.9c-0.1,0.1-0.2,0.3-0.3,0.5c-1.2-0.1-2.3-0.2-3.6-0.3C16.4,106.2,16.3,105.6,16.2,104.9L16.2,104.9z"/>
	<path class="st2-bull" d="M18.8,110.1c-0.6,0.6-0.9,1.1-1.4,1.5c-1.4,1.2-3.3,0.8-4.3-0.8c-0.3-0.5-0.9-1-1.5-1.6c0.4-0.3,0.8-0.6,1.2-1
		c0.4-0.3,0.7-0.6,1.1-1C15.5,108.3,17.5,108.4,18.8,110.1z"/>
	<path class="st2-bull" d="M30.6,122.4c-1.7-0.2-3.3-0.3-5.1-0.5c0.3-1.2,0.6-2.7,0.9-4.3c0.5,1,2.8,0.5,1.9,2.6
		C30.3,120.2,30.5,120.4,30.6,122.4z"/>
	<path class="st2-bull" d="M16.2,104.9c-1.5,0.4-2.6-0.3-3.8-1.3c1.1-0.9,1.7-2.5,3.5-2.4c0.4,0.6,0.9,1.2,1.4,1.8
		C16.9,103.7,16.6,104.3,16.2,104.9C16.2,104.9,16.2,104.9,16.2,104.9z"/>
	<path class="st2-bull" d="M69.8,136.9c0.4-0.6,0.7-1,1.1-1.5c0.8-0.1,1.6-0.3,2.5-0.5c0,0.7,0,1.3,0,2c-0.4,0.2-0.9,0.4-1.2,0.8
		c-0.9,0.9-2,1-2.8,0.1c-0.5-0.5-1.1-1-1.7-1.5C69,135.5,69,135.5,69.8,136.9z"/>
	<path class="st2-bull" d="M53.9,129.6c0.8,0.2,1.7-1.4,2.7,0.2c-1.5,0.8-3.2,1.3-3.3,3.6c-1.2-1.1-0.8-2-0.5-3c0.3-0.8,0.3-1.7,0.5-2.8
		c-0.3-0.1-0.8-0.3-1.6-0.5c1.3-0.4,2.2-0.4,2.9,0.5C54.7,128.4,52.9,128.5,53.9,129.6z"/>
	<path class="st2-bull" d="M217.3,347.4c0.7,0.6,1.4,1.2,2.4,2c-0.9,0-1.4,0-2.2,0c0.5,0.9,0.8,1.7,1.2,2.5c-0.8,0.1-0.8,0.1-2.9-2
		c0.4-0.3,0.8-0.7,1.3-1.1c-0.1-0.4-0.3-0.9-0.6-1.8C217,347.3,217.2,347.4,217.3,347.4L217.3,347.4z"/>
	<path class="st2-bull" d="M32.6,123.5c0.9,0.6,1.7,1.1,2.5,1.7c-0.7,0.7-1.3,1.4-2,2.2c-0.3-0.1-0.8-0.3-1.3-0.5c0.7-0.6,1.3-1,1.8-1.4
		C33.6,124.5,31.9,125.1,32.6,123.5z"/>
	<path class="st2-bull" d="M38.6,123c0.5-0.6,1.1-1.1,1.7-1.7c0.6,0.5,0.6,1.7,2.3,1.3c-1.5,1.4-2.7,0.6-3.7,0.9
		C38.7,123.4,38.6,123.2,38.6,123z"/>
	<path class="st2-bull" d="M64.6,124.9c0.2,0.7,0.2,1.1,0.4,1.4c0.6,1.6,0.6,1.6-1,2.6C64,127.6,63,126.3,64.6,124.9z"/>
	<path class="st2-bull" d="M9.5,105.1c1.1,0.5,2,1,3,1.5c-0.2,1-1.1,1.1-1.5,1C9.9,107.3,9.5,106.4,9.5,105.1z"/>
	<path class="st2-bull" d="M66.3,141.8c-0.3,1.1-0.5,1.8-0.8,2.5c-0.9-0.2-1.7-0.4-2.4-0.6c0-0.1-0.1-0.2-0.1-0.4
		C63.9,142.9,64.9,142.5,66.3,141.8z"/>
	<path class="st2-bull" d="M75.8,131.8c0.3,0.6,0.5,1,0.6,1.4c-0.7,0.5-1.4,0.9-2.1,1.4C74,133.2,74,133.2,75.8,131.8z"/>
	<path class="st2-bull" d="M75.4,131.3c0-1,0-1.8,0-2.8c1,0.5,1,0.5,1.5,1.9C76.4,130.7,75.9,130.9,75.4,131.3z"/>
	<path class="st2-bull" d="M66,96.2c0.5,1.4-1.1,1.6-1.6,2.5c0-1,0-2,0-2.9l0,0C65.2,96.3,65.4,96.4,66,96.2L66,96.2z"/>
	<path class="st2-bull" d="M51.4,126.2c0.6-0.7,1.2-1.3,1.8-2.1c0.4,0.7,0.3,1.2-0.2,1.8C52.5,126.5,52.1,126.8,51.4,126.2z"/>
	<path class="st2-bull" d="M66,122.3c-1.3,0.1-1.7-0.6-2.1-1.6c0.4-0.2,0.7-0.4,1.3-0.7C65.5,120.8,65.7,121.5,66,122.3z"/>
	<path class="st2-bull" d="M27.7,98.6c0,0.6,0,1.2,0,2c-0.5,0-0.9,0-1.6,0c0.5-0.7,0.9-1.4,1.2-2C27.5,98.6,27.6,98.6,27.7,98.6z"/>
	<path class="st2-bull" d="M56.4,122.2c-0.5,0.7-1,1.3-1.5,1.9c-0.2-0.3-0.4-0.9-0.7-1.6C54.9,122.7,55.4,121.3,56.4,122.2z"/>
	<path class="st2-bull" d="M23.4,107.4c-0.3,0-0.7,0-1.1-0.1c0.1-0.7,0.1-1.3,0.3-1.8c0.1-0.2,0.5-0.3,0.8-0.5c0.1,0.3,0.4,0.6,0.4,0.8
		C23.7,106.4,23.5,106.9,23.4,107.4L23.4,107.4z"/>
	<path class="st2-bull" d="M28.3,103.6c0,0.7,0,1.2,0,1.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5-0.7-1-1.4-1.8-2.4
		C27.1,103.4,27.7,103.5,28.3,103.6z"/>
	<path class="st2-bull" d="M66.1,96.2c0.1-1.1,0.4-2.1,1.6-2.9C67.6,94.7,67.3,95.7,66.1,96.2C66,96.2,66.1,96.2,66.1,96.2z"/>
	<path class="st2-bull" d="M63.9,138.4c1,0.3,1.6,0.5,2.2,0.7C65.1,140.4,65.1,140.4,63.9,138.4z"/>
	<path class="st2-bull" d="M217.4,347.5c0-0.8,0-1.7,0-2.5c0.3,0.2,0.7,0.6,1.3,1.1C217.9,346.3,218.9,347.8,217.4,347.5
		C217.3,347.4,217.4,347.5,217.4,347.5z"/>
	<path class="st2-bull" d="M51.9,118.2c-0.5,0.2-1,0.5-1.8,0.9c0.2-0.7,0.1-1.3,0.4-1.4c0.3-0.2,0.9,0,1.3,0
		C51.9,117.8,51.9,118,51.9,118.2z"/>
	<path class="st2-bull" d="M60.7,109c-0.2,1.1-0.4,2-0.6,3C59.4,110.3,59.4,110.3,60.7,109z"/>
	<path class="st2-bull" d="M219.9,327.8c0.1,1.3,0.2,2.5,0.3,3.8C219.3,330.4,219.6,329.1,219.9,327.8z"/>
	<path class="st2-bull" d="M58.6,121.2c-1.6-1.1-1.6-1.1-0.1-2C58.6,119.8,58.6,120.4,58.6,121.2z"/>
	<path class="st2-bull" d="M53.6,135c-0.2,0.4-0.4,0.9-0.6,1.3c0,0-0.5-0.2-0.5-0.2c0.1-0.5,0.2-0.9,0.3-1.3
		C53.2,134.8,53.4,134.9,53.6,135z"/>
	<path class="st2-bull" d="M63.9,134.3c0.7-0.3,1.3-0.5,1.6,0.3c0.1,0.1,0,0.4-0.2,0.5C64.7,135.5,64.3,135.1,63.9,134.3z"/>
	<path class="st2-bull" d="M23.1,111.1c-0.7-0.2-1.2-0.5-0.9-1.3c0-0.1,0.4-0.3,0.5-0.2C23.6,109.8,23.3,110.4,23.1,111.1z"/>
	<path class="st2-bull" d="M64.6,129.6c0.7-0.5,1.1-0.8,1.5-1.2C66.5,129.7,66.5,129.7,64.6,129.6z"/>
	<path class="st2-bull" d="M23.3,107.4c0.5,0.9,0.5,0.9-0.6,1.4C22.4,108,23,107.8,23.3,107.4C23.4,107.4,23.3,107.4,23.3,107.4z"/>
	<path class="st1-bull" d="M422.6,43.3c-0.3-0.5-0.5-1-0.9-1.7c0.3-0.1,0.6-0.2,1.2-0.5C422.8,42.1,422.7,42.7,422.6,43.3
		C422.5,43.4,422.6,43.3,422.6,43.3z"/>
	<path class="st2-bull" d="M63.8,100.8c-0.2-0.2-0.4-0.5-0.6-0.7c0,0,0.1-0.2,0.2-0.3c0.3,0.2,0.5,0.4,0.8,0.6
		C64.1,100.5,64,100.6,63.8,100.8z"/>
	<path class="st2-bull" d="M72.3,116.5c-0.1,0.6,0.4,1.5-0.9,2.2c0.2-1,0.3-1.6,0.5-2.3C72,116.5,72.2,116.5,72.3,116.5z"/>
	<path class="st2-bull" d="M19.2,112c0.5-0.2,1-0.4,1.7-0.7C20.8,113,19.6,111.9,19.2,112z"/>
	<path class="st2-bull" d="M66.5,134.3c0.3-0.3,0.5-0.5,0.8-0.8c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.3-0.4,0.6-0.6,0.9
		C66.8,134.5,66.6,134.4,66.5,134.3z"/>
	<path class="st2-bull" d="M52.6,119.4c0.1,0.6,0.1,1,0.2,1.5c-0.3,0-0.4,0-0.5-0.1C51.6,120.3,51.7,119.8,52.6,119.4z"/>
	<path class="st2-bull" d="M819.3,381.6c-1.3,0.2-1.7,1-1.6,2.3c-0.6,0.1-1.1,0.1-1.9,0.2c0.5-1.3,0.4-2.4-0.8-3.2
		c-0.4,0.2-0.8,0.5-1.5,0.9c-0.1-1.3-0.3-2.4-0.3-3.4c0-1.4,0.1-1.5,1.8-2c0-0.6,0.1-1.2,0.1-2c-0.7,0-1.2,0.1-1.9,0.1
		c0-0.9,0-1.6,0-2.5c1,0,1.9,0,2.7,0c1,0,1.9,0.1,2.9,0.2c0.5,0.1,1.1,0.2,1.5,0.6c0.7,0.7,1.3,0.6,2.1,0.3
		c-0.4,0.6-0.7,1.2-1.1,1.8c0.1,0.1,0.2,0.1,0.3,0.2c0.5-0.3,1-0.5,1.4-0.7c-0.2-0.5-0.4-0.9-0.6-1.3c-0.4-0.6-0.3-1.1,0.5-1
		c0.7,0.1,1.5,0.3,2.2,0.4c0.4,0,0.9-0.1,1.3-0.2c0.1,0,0.1-0.1,0.2-0.1c1,0.5,2,0.3,3.1-0.3c0,0.2,0.1,0.3,0.1,0.5
		c-0.4,0.6-0.8,1.2-1.5,2c0.9,0.2,1.6,0.3,2.2,0.4c0.9,0.1,1.2,0.6,0.9,1.5c0.8,0.2,1.5,0.3,2.7,0.6c-0.8-1.2-1.5-2.1-2.2-3.2
		c0.1-0.4,0.3-0.9,0.6-1.6c1.4,0,2.8,0,4,0c0.8,0.7-0.7,1.8,0.8,2.3c0.1-0.7,0.2-1.4,0.3-2.3c4,0,8.1,0,12,0c1.2,1.3-1.2,2.4,0,3.8
		c0.8,1-0.7,2.3-0.4,3.6c0.2,1.3,0.1,2.7,0.1,4.4c-5.3,0.2-10.5-0.1-15.7,0.1c-0.1-0.4-0.2-0.7-0.3-1.1c-1.3,0.3-1.3,1.5-1.8,2.4
		c-0.1,0.1,0,0.3,0,0.4c0.2,5.6-0.6,4.7,4.3,4.8c3.9,0.1,7.9,0,11.8,0c0.7,0,1.5,0,2.4,0c0,1.2,0,2,0,2.9c0.1,1.8-0.3,3.6,0.5,5.3
		c0.2,0.5,0,1.2-0.1,1.8c-0.1,0.6-0.1,1.1-0.2,1.9c-0.9,0.1-2,0.3-3.1,0.3c-3,0-5.9-0.2-8.9-0.2c-1.9,0-3.7,0.2-5.6,0.3
		c-1,0.1-1.2,0.8-1.2,1.6c0,1.9,0.1,3.7,0,5.6c-0.1,1.4,0.6,2.9-0.5,4.1c0,0,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.4,0.9
		c0.5,0.9-0.8,1.5-0.2,2.5c0.4,0.7,0.1,1.9,0.1,3c-1,0-1.9,0.1-2.9,0.1c-3.4,0-6.7,0-10.1-0.1c-0.5,0-1-0.6-1.3-1
		c-0.4-0.6-0.6-1.4-0.9-2.3c0.7,0.1,1.3,0.1,2.1,0.2c-0.9-1-1.6-1.8-2.3-2.7c1.1-1.7,1.1-1.7,0.1-3.2c1.8-0.7,0.3-2.5,1.4-3.5
		c-1-0.4-1.8-0.7-2.7-1.1c0.7-2.1,2.1,0.2,3.2-1.1c0-0.2,0-0.8-0.2-1.4c-0.5-1.2-1.1-2.2-1.6-3.4c-0.2-0.3-0.2-0.8,0-1.2
		c0.7-2.2,0.7-2.2-0.1-3.9c0.5-0.3,1-0.6,1.6-0.9c0-1.2,0-2.4,0-3.8c0.6,0.1,1,0.1,1.5,0.2c0.7-1.2,0.7-1.2,0.1-2.1
		c1.1-1.2,2.1-2.4,3.2-3.6c0.9-1,0.7-1.7-0.6-2.1C820.4,383.6,819.3,383.1,819.3,381.6c1.5-0.3,1.5-2.5,3.2-2.7
		c0.1,0,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0-0.2c-0.7-0.1-1.5-0.2-2-0.2c-0.5,0.8-1,1.5-1.6,2.3C819,380.6,819.2,381.1,819.3,381.6z
		 M826.3,411.9c0,0.3,0.1,0.7,0,1c-0.6,0.9-0.5,1.8-0.2,2.7c1.1,0.3,2,0.1,2.4-1c-0.2-0.5-0.4-1-0.5-1.5
		C827.7,412.2,827.1,411.8,826.3,411.9c0.2-0.3,0.4-0.7,0.7-1.1c-0.6-0.9-0.6-0.9,0-2c-0.6-0.1-1.1-0.2-1.7-0.3
		c0.2-0.5,0.4-0.8,0.6-1.3c-1.4-0.1-1.9-2.2-3.8-1.2c0.4,0.8,0.7,1.7,1.3,2.2c0.6,0.6,1.6,0.9,2.4,1.3
		C825,411.2,825,411.2,826.3,411.9z M824.9,382.3c0.9-0.3,1.6-0.6,2.2-0.8c0.2-0.1,0.4-0.3,0.6-0.4c0.8,1.3,1.4-0.1,2.1-0.3
		c0,0.1,1,2.6,1,2.7c1.4-0.5,0.6-1.8,0.9-2.6c0.4-0.8,0.3-1.8,0.4-3c-0.5,0.6-0.8,0.9-1.1,1.2c-0.3,0.3-0.7,0.7-1,1
		c-0.7-0.3-1.3-0.5-2-0.8c-0.1,0.4-0.2,0.6-0.3,0.9C826.4,379.6,825.5,380.2,824.9,382.3z M819,412c0.9,1.3,1.5,2.7,3,3.2
		c-0.1,0.4-0.2,0.7-0.4,1.3c1.1-0.5,2.2-1.1,1.6-2c-0.6-1-0.1-1.6,0.2-2.6c-0.8,0.2-1.3,0.3-1.9,0.4
		C820.7,410.8,820.4,410.7,819,412z M820.6,407.1c-1.1-0.5-2-0.2-2.3,0.7c-0.6,1.5,0.8,1.9,1.6,2.7c0.8-0.4,1.5-0.7,2.2-1.1
		c-0.3-1.3-1.4-0.5-1.9-1C820.4,408,820.5,407.5,820.6,407.1z M826.9,395.6c0.5-0.7,0.5-1.3-0.3-1.7c-0.6-0.3-0.8-1.4-2-0.7
		c0.4,0.6,0.8,1.2,1.2,1.8c-0.5,0.4-1.1,0.7-1.5,1.2c-0.7,0.9,0.1,1.3,0.5,1.5C825.5,396.9,826.2,396.3,826.9,395.6z M818.3,397.7
		c0.5,1.6,1.2,3,2.8,3.8c0.4-0.3,0.7-0.7,1.3-1.2C820.7,399.7,820.2,397.7,818.3,397.7z M821,394c2.3-1.7,2.4-2.2,0.3-3.6
		C821.9,391.8,818.9,392.3,821,394z M821.8,397.5c0.6-0.9,1.2-1.6,1.9-2.6c-1.2,0-2,0-2.7,0C820.2,396.1,821.1,396.6,821.8,397.5z
		 M824.8,401.8c0.5-0.5,0.6-0.7,0.7-0.8c-0.9-0.4-0.7-1.4-1.1-2.1c-0.3-0.5-0.8-1.2-1.8-0.3C823.3,399.6,824,400.7,824.8,401.8z
		 M829.4,411.7c0.5-1,0.9-1.9,1.6-3.2c-1,0.5-1.7,0.9-2.4,1.2C828.7,410.4,828.1,411.5,829.4,411.7z M836.7,378.2
		c0.3,0,0.8,0.1,1,0.1c0.2-0.6,0.3-0.9,0.4-1.3c0.1-0.5,0.2-0.9,0.3-1.7c-0.8,0.4-1.3,0.6-1.7,0.7
		C836.7,376.7,836.7,377.4,836.7,378.2z M823.1,404.8c0.1-0.1,0.3-0.3,0.4-0.4c-0.5-0.8-1.1-1.7-1.7-2.6c-0.5,0.4-0.8,0.6-1.3,1
		C821.9,403.1,821.8,404.8,823.1,404.8z M823.5,389.8c1.4,1.5,1.6,1.5,2.4,0.2C825.1,389.9,824.5,389.9,823.5,389.8z M838.8,397.8
		c0-0.8,0.1-1.4,0-2c0-0.2-0.5-0.3-0.7-0.5c-0.1,0.3-0.5,0.7-0.4,0.9C837.9,396.8,838.4,397.2,838.8,397.8z M827.6,405.3
		c-0.5-0.4-0.8-0.7-1.1-0.9c-0.2,0.3-0.6,0.6-0.6,0.8c0,0.3,0.3,0.7,0.6,0.8C826.8,406.1,827.1,405.7,827.6,405.3z M823.7,419.8
		c0.1-0.2,0.2-0.3,0.3-0.5c-0.8-0.4-1.5-0.8-2.3-1.2c-0.1,0.2-0.2,0.4-0.3,0.5C822.2,419,823,419.4,823.7,419.8z M840,375.4
		c-1.7,0.9-0.1,1.4-0.3,2.2C841.2,376.8,839.9,376.2,840,375.4z M816.7,377.3c-1.1-0.4-1.2,0.4-1.7,0.9c0.3,0.2,0.5,0.5,0.7,0.6
		C816.1,378.2,816.4,377.8,816.7,377.3z M828.8,415.7c0.7,0.7,1.2,0.8,1.6,0.1c0-0.1-0.2-0.5-0.2-0.5
		C829.7,415.4,829.2,415.5,828.8,415.7z M825.5,374.6c-0.3,0.6-0.5,0.8-0.7,1.1c0.6,0.2,1,0.4,2,0.7
		C826.2,375.6,825.9,375.2,825.5,374.6z M839.5,393.2c-0.2,0-0.3,0-0.5,0c0,0.4,0.1,0.8,0.1,1.1c0.1,0,0.2,0,0.3,0
		C839.5,393.9,839.5,393.6,839.5,393.2z M829.1,383.5c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.3,0.7,0.6,1.1,0.9c0.1-0.1,0.2-0.3,0.3-0.4
		C829.8,384.1,829.4,383.8,829.1,383.5z M829.7,417.9c-0.8-0.4-1.2-0.1-1.4,0.5c0,0.1,0.1,0.2,0.2,0.5
		C828.9,418.5,829.2,418.2,829.7,417.9z M835,381.3c-1-0.5-1-0.5-1,0.8C834.4,381.8,834.6,381.6,835,381.3z M817.8,412.5
		c-0.1,1.3-0.1,1.3,0.9,0.9C818.4,413.1,818.2,412.8,817.8,412.5z M817.7,396.4c1,0,1,0,0.9-0.9C818.2,395.8,818,396,817.7,396.4z
		 M831,412.2c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2,0.2-0.4,0.5-0.6,0.7c0.1,0.1,0.4,0.3,0.4,0.3C830.7,412.7,830.9,412.5,831,412.2z"/>
	<path class="st2-bull" d="M866.6,372c0,4.1,0,7.9,0,11.9c-5.3,0-10.5,0-15.8,0c-0.1-0.4-0.3-0.7-0.3-1c0-3,0-5.9,0-8.9c0-1.4,0.6-2,2-2
		C857.2,371.9,861.8,372,866.6,372z"/>
	<path class="st2-bull" d="M852.1,402.5c-0.1-0.8-0.2-1.3-0.3-1.9c0-0.3-0.2-0.8,0-1c1.3-1.6,0.3-3.5,0.7-5.2c0-0.2,0.2-0.4,0.2-0.5
		c-0.4-1.1-2.2-1.5-1.3-3.2c2.5,0,5,0,7.6,0c0,4.1,0,7.9,0,11.9C856.6,402.5,854.3,402.5,852.1,402.5z"/>
	<path class="st2-bull" d="M550.3,413.3c-0.6-1.3-0.3-2.6-0.1-4.2c0.7-0.1,1.4-0.1,2.1-0.1c2.3,0,4.7,0,7,0c1.8,0,2-0.3,2.1-2.1
		c0-7,0-14,0-20.9c0-1.8-0.3-2-2.1-2c-1.9,0-3.8,0-6,0c0.1-0.9,0.2-1.7,0.3-2.6c-1.6-0.8-1.5-2.4-1.5-4c0-1.3,0.2-2.7-0.6-4
		c-0.2-0.3,0-0.8,0.1-1.2c1.8-0.5,26.4-0.5,28.2,0c0,16.1,0,32.3,0,48.6c-7.7,0-15.3,0-23.1,0c0.5-0.7,0.9-1.2,1.5-2
		c-1.1,0.2-1.9,0.3-2.7,0.5c0.1,0.5,0.3,0.9,0.4,1.5c-1.5,0-2.9,0-4.4,0c0.1-1.4-0.8-2.1-2.1-2.6c-0.1-1.6-0.4-3.2,0.9-4.4
		C550.5,413.9,550.4,413.5,550.3,413.3c1.1,0.1,2.3,0.3,3.3,0.4c0,0.6-0.1,1.1-0.1,1.6c0.2,0.1,0.4,0.1,0.5,0.2
		c0.3-0.6,0.8-1.2,1-1.8c0.3-1,1-1.3,2-1.4c0.9-0.1,1.9-0.5,2.8-0.7c0-0.1,0-0.3-0.1-0.4c-0.8,0-1.8-0.2-2.2,0.2
		c-1.1,1-1.6,0.3-2-0.5c-1,0.7-1.9,1.7-2.9,1.8C551.8,412.8,551.1,413.2,550.3,413.3z M552.5,417.6c-0.1,0-0.2,0-0.2,0
		c0,0.6,0,1.3,0,1.9c0.1,0,0.2,0,0.2,0C552.5,418.9,552.5,418.3,552.5,417.6z"/>
	<path class="st2-bull" d="M527.2,399.9c0.1-0.6,0.9-1,0.3-1.8c-0.2-0.2,0.1-0.9,0.2-1.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1-1-0.3-1.9-0.4-3
		c0.5,0.2,1,0.4,1.6,0.7c0.1-0.8,0.1-1.4,0.2-2.2c0.9-0.8,2.1-1.7,3.2-2.6c1.3-1.1,3-1.9,4.1-3.3c0.3-0.4,0.6-1.1,0.4-1.4
		c-0.2-0.4-0.9-0.7-1.3-0.7c-2-0.1-4,0-5.7,0c-0.7-1.1-1.3-1.9-1.9-2.8c-0.2-0.3-0.7-0.8-0.6-1c0.5-1.3,0.2-2.5,0-3.8
		c-0.2-1.3,0-2.7,0-4.3c0.9,0,1.8-0.2,2.5-0.1c2.3,0.4,4.5,0.1,6.8,0c0.9,0,1.8,0.4,2.8,0.6c0.3-0.2,0.7-0.6,1.2-0.6
		c3.3,0,6.6,0,9.9,0c0.2,0,0.4,0.1,0.5,0.2c0.9,3.8,0.8,7.7,0.7,11.8c-1.4,0-2.6,0-3.8,0c-1.9,0-2.4,0.5-2.4,2.5
		c0,1.9,0.1,3.9,0.1,5.8c0.1,5.4,0.1,10.7,0.2,16.1c0,0.1-0.1,0.3-0.2,0.6c1.2,0.1,2.4,0.2,3.6,0.3c0.3,2.1-1,4.4-2.8,4.9
		c-0.5,0.1-1-0.2-1.5-0.2c-0.6-0.1-1.2-0.3-1.7-0.1c-0.8,0.2-1.6,0.7-2.4,1.1c-0.2,0.1-0.4,0.5-0.7,1c2.8-0.2,5.2-0.4,7.6-0.6
		c1.3,1.9,1.3,1.9,1.2,3.7c-0.7,0.3-1.5,0.6-2.3,0.9c-0.4-0.7-0.8-1.2-1.1-1.7c-0.6,0.2-1.1,0.4-1.8,0.6c0.1,0.7,0.3,1.4,0.4,2.2
		c-3.6,0-7.2,0-10.8,0c-0.4,0-0.7-0.3-1.1-0.5c-0.2-0.1-0.4-0.4-0.6-0.6c-1.4-1.6-3.2-3.1-4-5c-0.7-1.7-0.2-3.9-0.2-5.9
		c0-2.7,0-5.4,0-8.1C526.1,400.2,526.1,399.5,527.2,399.9c0,0.3,0.1,0.7-0.1,1.1c-0.4,0.8-0.4,1.5,0,2.3c1.4,0,1.4,0,2.3-1.1
		c-0.3-0.5-0.5-1.1-0.9-1.5C528.2,400.3,527.6,400.2,527.2,399.9z M535.9,416.4c-0.1-0.1-0.1-0.3-0.2-0.4c-2,0.3-2,0.3-2.8-0.8
		c-1.2,0.4-1.9,1.2-2.2,2.9C532.6,417.4,534.3,416.9,535.9,416.4z M530.3,399.7c0.5-1.1,1-2,1.6-3.3c-1,0.5-1.7,0.9-2.4,1.3
		C529.6,398.4,529.1,399.3,530.3,399.7z M539.2,385.6c0.1,0,0.3,0,0.4-0.1c0-0.6,0-1.2-0.1-1.8c0-0.1-0.4-0.3-0.6-0.3
		c-0.2,0-0.4,0.4-0.4,0.6C538.7,384.5,539,385,539.2,385.6z M530.7,405.9c-0.1-0.2-0.2-0.3-0.3-0.5c-1-0.2-1,0.7-1.4,1.2
		c0.1,0.1,0.2,0.2,0.3,0.4C529.7,406.5,530.2,406.2,530.7,405.9z M538.1,399.7c-0.1-0.2-0.2-0.4-0.3-0.7c-0.4,0.2-0.8,0.4-1.2,0.6
		c0.1,0.2,0.3,0.6,0.3,0.6C537.3,400,537.7,399.8,538.1,399.7z M529.7,403.6c0.1,0.2,0.1,0.3,0.2,0.5c0.5-0.2,1-0.3,1.5-0.5
		c-0.1-0.2-0.2-0.5-0.3-0.7C530.6,403.2,530.1,403.4,529.7,403.6z M536.6,411.5c0-0.7-0.5-1.1-1.3-1.6
		C535.2,411.1,535.9,411.3,536.6,411.5z M539.8,382.5c0.7-0.6,0.9-1.1,0-1.6C539.8,381.5,539.8,382,539.8,382.5z M548,374.3
		c0.1-0.6,0.3-1.1,0.5-2C547.3,372.9,547.7,373.6,548,374.3z M536.5,415.8c0.1,0.2,0.2,0.4,0.2,0.6c0.4-0.2,0.9-0.4,1.3-0.5
		c0-0.1-0.1-0.2-0.1-0.4C537.5,415.6,537,415.7,536.5,415.8z M535.7,373.9c0-0.1,0-0.3,0-0.4c-0.4,0-0.7,0-1.1,0c0,0.1,0,0.2,0,0.3
		C535,373.8,535.3,373.8,535.7,373.9z M534.7,377c0.1,0,0.3,0,0.4-0.1c-0.1-0.3-0.1-0.7-0.2-1c-0.1,0-0.3,0-0.4,0.1
		C534.6,376.3,534.7,376.7,534.7,377z M535.7,404.5c0-0.1,0-0.2-0.1-0.4c-0.3,0.1-0.6,0.1-0.8,0.2c0,0.1,0.1,0.4,0.1,0.4
		C535.1,404.6,535.4,404.5,535.7,404.5z"/>
	<path class="st2-bull" d="M671.5,402.5c-0.5,0.3-0.8,0.2-0.9-0.3c-0.1-0.5-0.1-1.1-0.1-1.7c0-0.9,0.1-1.8,0-2.7
		c-0.6-3.3,0.8-5.4,3.5-7.2c1.9-1.2,3.6-2.8,5.3-4.2c0.5-0.4,1.2-0.8,1-1.6c-0.2-0.8-0.9-0.7-1.5-0.9c-0.4-0.1-0.7-0.5-1-0.8
		c-0.3,0.3-0.6,0.8-1,0.8c-2.1,0.1-4.1,0-6.3,0c0-4,0-7.9,0-11.9c2.1,0,4.1,0,6.3,0c0.1,0.9,0.2,1.9,0.2,2.8c0.1,0,0.2,0,0.3,0
		c0.1-0.9,0.1-1.7,0.2-2.6c1.5-0.4,33-0.5,35.4,0c0,1.9,0.5,3.8-0.3,5.7c-0.5,1.1-0.6,2.4-0.8,3.6c-0.4,2.7-0.8,5.3-1.3,8
		c-0.3,1.4-0.8,2.8-1.3,4.2c-0.1,0.3-0.7,0.4-1.5,0.8c0.5,0.4,1,0.7,1.3,0.9c-0.5,1.8-1,3.5-1.5,5.4c-1.1-0.9-1.8-1.9-1.8-3.4
		c0-3.7,0-7.4,0-11.2c0-2.1-0.1-2.2-2.2-2.2c-4.1,0-8.3,0-12.4,0c-2,0-2.3,0.2-2.3,2.3c0,6.8,0,13.7,0,20.5c0,1.8,0.4,2.2,2.2,2.2
		c1.1,0,2.2,0,3.3,0.1c0.9,0.1,1.7,0.3,2.8,0.4c0.4-0.1,1.1-0.5,1.7-0.5c1.8-0.1,3.6,0,5.4,0c1.3,0,1.6-0.3,1.6-1.6
		c0-2.1,0-4.1,0-6.4c0.8,0.3,1.3,0.5,2,0.7c0.1,0.8,0.2,1.7,0.2,2.5c0.2,0,0.3,0,0.5,0c0.3-1.7,0.6-3.4,0.9-5.1c0-0.2-0.1-0.4,0-0.6
		c0.9-1.8,1.2-3.7,1.5-5.6c0.4-2.4,1-4.9,1.5-7.3c0.3-1.2,0.4-2.5,0.7-3.7c0.9-2.8,1.4-5.7,1.2-8.6c0-0.3,0.1-0.7,0.1-1.3
		c3.2,0,6.4,0,9.9,0c0,2.2,0,4.3,0,6.3c0,2,0,4,0,6c0,2.1,0,4.1,0,6.2c0,2.1-0.1,4.1,0,6.2c0.1,1.9-0.5,3.8-0.1,5.8
		c0.4,2,0.1,4.1,0.1,6.1c0,2,0,4,0,6c0,2.1,0,4.1,0,6.3c-0.7,0.1-1.3,0.1-1.8,0.1c-5.3,0-10.6,0-16,0c-0.8,0-1.7,0.3-1.9-0.6
		c-0.7,0.2-1.3,0.6-1.8,0.6c-5.3,0-10.5,0.1-15.8,0c-2.1,0-4.2-0.3-6.3-0.3c-1.2,0-2.3,0.3-3.5,0.3c-1.8,0-2.4-0.4-3-2.1
		c-0.2-0.5-0.4-0.7-0.8-0.9c-1-0.6-2.2-1.1-1.9-2.7c-1.3-0.8-0.9-2.2-1-3.3c-0.1-1.3-0.1-2.6,0-3.9c0.1-1.3,0.2-2.6,0.3-3.9
		C670.9,403.6,671.2,403.1,671.5,402.5c0.5,0.3,1.1,0.6,1.6,0.8c0.9-0.3,1.5-1.9,2.8-0.8c0.5-0.8,2,0.1,2.4-1.4
		c-0.6-0.1-1.2-0.2-1.6-0.1C674.9,401.7,673,401.6,671.5,402.5z M714.3,397.6c1,0.1,1.3-0.4,1.2-1.4
		C714.8,396.5,714.2,396.8,714.3,397.6c-0.7,0.9-0.7,0.9-0.1,1.7C714.3,398.7,714.3,398.1,714.3,397.6z M679.6,399
		c-0.2-0.3-0.3-0.6-0.5-1.1C678.5,399,679.2,398.9,679.6,399c-0.1,0.2-0.3,0.5-0.5,0.8c-0.6,1.1-0.5,2.4,0.4,3.3
		c0.1,0.1,0.2,0,0.4,0c-0.1-0.4-0.2-0.9-0.4-1.3c2.3-1,2.3-1,2.7-1.9C680.7,400.1,680.7,400.1,679.6,399z M706.3,416.4
		c2.1-2.4,2.2-4.2,0.7-5.2C706.8,412.8,706.6,414.3,706.3,416.4z M718,392.5c-0.2-0.3-0.4-0.8-0.7-1.4c-0.5,0.6-1,1-1.4,1.5
		c0.9,0.8,0.4,1.3-0.1,1.9c-0.5,0.5-0.4,0.9,0.4,1.1C716.7,394.6,717.3,393.6,718,392.5z M677.8,375.9c-0.2,0-0.4,0-0.6,0.1
		c0.2,1.2,0.4,2.5,0.6,3.7C678.2,378.4,678.6,377.1,677.8,375.9z M680.1,406.8c-2.3,1.4,0,2.3-0.3,3.3
		C680.6,409.1,679.7,408.1,680.1,406.8z M710.8,401.4c1,0,1.6,0,2.6,0c-0.6-0.7-0.9-1.1-1.3-1.6
		C711.6,400.3,711.4,400.7,710.8,401.4z M720,385.3c-1.4,0.6-1,1.6-1,2.9C719.5,387.2,720.7,386.7,720,385.3z M712,405.6
		c-1.5-0.1-1.8,0.3-1.9,1.8C710.7,406.8,711.3,406.3,712,405.6z M697.4,413.2c-1.7,0.7-1.7,0.7-1.5,1.4c0.5,0,1,0,1.5,0
		C697.4,414.2,697.4,413.8,697.4,413.2z M679.2,413.4c0.3,0,0.7,0.1,1.4,0.2c-0.7-0.7,0.4-1.8-0.9-2.2
		C679.5,412.1,679.3,412.7,679.2,413.4z M685.6,399.3c-0.6-0.1-1.2-0.5-1.4,0.4c0,0.2,0.1,0.5,0.2,0.5
		C685.1,400.4,685.4,400,685.6,399.3z M708.7,410.5c0.1-0.2,0.1-0.5,0.2-0.7c-0.5-0.1-0.9-0.2-1.4-0.3c0,0.2-0.1,0.5-0.1,0.5
		C707.8,410.2,708.3,410.4,708.7,410.5z M674.5,410.7c0.1,1,0.1,1,1.5,0.7C675.4,411.1,674.9,410.9,674.5,410.7z M682.4,409.5
		c0.2,0.5,0.4,0.9,0.7,1.5C684.2,409.9,683.4,409.7,682.4,409.5z M678.7,389.4c0-0.6,0-1-0.1-1.6
		C677.6,388.5,677.8,388.9,678.7,389.4z M703.6,414.9c0,0.2,0,0.3,0.1,0.5c0.3-0.1,0.6-0.1,0.9-0.2c0-0.1,0-0.2,0-0.3
		C704.2,414.9,703.9,414.9,703.6,414.9z"/>
	<path class="st2-bull" d="M399,418.2c0.6,0.8,0.8,1.6,0.1,2.7c-4.7,0-9.5,0-14.4,0c-0.6-0.7-0.8-1.5-0.8-2.4c0-0.7-1.5-1.1-0.6-2.4
		c0.4,0.1,0.9,0.3,1.3,0.5c2.2-0.9,2.3-3,2.9-4.9c0.3-1.1,0.6-2.2,0.9-3.4c-0.2-0.2-1.2-0.3-0.5-1.2c-0.5-0.3-1-0.7-1.6-1.1
		c0.5-0.8,1-1.4,1.5-2.2c-0.5,0-1.1-0.1-1.6-0.1c-0.5,0.7-1,1.4-1.5,2.1c-0.7-2-0.6-3.3,0.2-5c0.4-0.9,0.1-1.4-1.1-1.7c0,0,0,0,0,0
		c0.2-1.4-1.1-1.8-2-2.7c0.8-0.5,1.7-1.1,0.7-2.4c-0.4,0.4-0.8,0.8-1.2,1.1c-0.6-0.8,0.1-2.1,1.4-2.3c0.4,0,0.8,0,1.4,0
		c-0.2-1.3,0.4-2,1.3-2.6c1.1-0.7,2.1-1.5,3.2-2.2c0.1,0.5,0.2,1.1,0.3,1.6c1.8-0.3,1.9-0.4,1.5-1.8c-0.1-0.6,0-1.3,0.2-1.9
		c0.1-0.5,0.5-0.9,1.1-1.7c-2-0.1-3.7-0.1-5.4-0.2c-1.7-0.1-3.3-0.1-5.1-0.1c0-3.1,0-6.2,0-9.4c0.7-0.7,1.5-1.4,2.3-2
		c1.2-1,1.8-0.8,2.5,0.7c0.2-0.3,0.3-0.6,0.5-1c0.2,0.1,0.4,0.3,0.4,0.4c0,0.8,0.1,1.5,1.1,1.6c-0.1,1.3-1.3,0.5-1.8,1.2
		c-0.4,0.7-1.2,1.1-2,1.8c0.1,1.2,0.9-0.2,1.3,0.1c0.1,0.2,0.3,0.5,0.5,1c1-1.2,1.9-2.3,2.8-3.3c0.6-0.7,1.1-1.4,1.7-2
		c1.1,0.7,1.6-0.7,2.6-0.6c1.5,0.1,3.1-0.2,4.6-0.3c0.7,1.8,0.9,1.7,2.3,0c11.5,0,23,0,34.8,0c0,7.2,0,14.3,0,21.6
		c-3.3,1.6-6.5,3.1-9.7,4.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.7,0.3-1.5,0.7-1.5,1.5c0,0.9,0.5,1.7,1.3,2.2c3.2,1.9,6.4,3.7,9.6,5.6
		c0.4,0.2,0.8,0.5,1.2,0.8c0,4,0,8,0,12.1c-0.4,0.1-0.8,0.2-1.3,0.2c-3.1,0-6.2,0-9.3,0c-0.5,0-1,0-1.4-0.3c-3-2-6.1-4-9.1-6.1
		c1.1,0.1,1.4-0.9,2-1.5c0.1-0.1-0.4-0.6-0.7-1.1c-0.5,1.1-0.9,1.9-1.2,2.7c-2.6-1.2-4.9-2.9-5.1-3.6c-1.3-0.6-2.4-1.1-3.4-1.7
		c-1.2-0.7-2.3-1.5-3.4-2.2c-1.5-0.9-2.5-0.4-2.4,1.3c0.1,2.8-0.6,5.7-0.1,8.5C399.6,417.4,399.2,417.8,399,418.2
		c0.1-1.2,0.1-1.2-1.3-0.9C398.3,417.6,398.7,417.9,399,418.2z M408.3,384C408.3,384,408.3,384,408.3,384c-2.3,0-4.6,0-6.8,0
		c-1.5,0-1.8,0.3-1.8,1.9c-0.1,2-0.2,4-0.2,6c0,1.8,1,2.4,2.5,1.6c4.5-2.1,8.9-4.3,13.4-6.4c0.9-0.4,1.3-0.9,1.2-1.9
		c-0.2-1.1-1-1.1-1.9-1.1C412.6,384,410.5,384,408.3,384z M391.5,400.4c0,0-0.2,0-0.4,0c-1.5,0-3,0.1-3.9-1.6
		c-0.1-0.2-0.8-0.1-1.1-0.1c0.1,0.8,0.2,1.5,0.2,2.2c-0.1,0.7-0.3,1.4-0.4,2c0.1,0.1,0.2,0.1,0.3,0.2c0.6-0.4,1.3-0.9,2-1.4
		c1.1,0.7,2.7,1,3.4,2c0.9,1.4,2.1,1,3.2,1.2c0.1,0,0.2,0.2,0.3,0.2c0.1,0.5-1,1.1,0,1.5c0.7,0.3,0.9-0.4,1.1-0.9
		c0.1-0.2,0.2-0.5,0.3-0.7c-0.7-0.5-1.8-0.9-1.9-1.4c-0.2-1.1-2.1,0.2-1.7-1.5C393.2,401.8,392.2,401.1,391.5,400.4z M393.2,419.3
		c0.2-0.8,0.6-1.6,0.6-2.3c0-0.7-0.3-1.4-0.4-2.1c-0.1-0.6-0.2-1.2-0.3-1.8c-0.2,0-0.3,0-0.5,0c-0.2,0.3-0.5,0.6-0.5,0.9
		c-0.1,0.7,0.2,1.6-0.1,2.2C391.3,417.6,392,418.4,393.2,419.3z M390.9,391.1c-0.9,0.6-1.9,0.8-0.9,1.8c0.6,0.6,0.5,1.2,0.1,1.8
		c-0.1,0.2-0.2,0.5-0.2,0.6c1,0.6-0.2,1.2-0.1,1.9c1.2,0.1,1.5-0.7,2.1-1.4C391.6,394.3,391.3,392.8,390.9,391.1z M388.8,417.4
		c0-1,0.1-2,0.1-3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0.5-0.3,1-0.5,1.6c-0.3,0-0.6-0.1-0.8-0.1c-1.1,1.4-1.6,2.9-1.4,4.7
		c0.7-1,1.3-1.8,2-2.8c0.2,0.1,0.5,0.3,0.8,0.3c0.4,0.1,0.8,0,1.2,0c0-0.1,0-0.2,0-0.3C389.6,417.7,389.3,417.6,388.8,417.4z
		 M384.4,395.6c1.3,1,1.7,0.8,1.9-0.4c0.1-1.3-0.1-2.7,0.7-3.9c0.1-0.1-0.1-0.4-0.2-0.6c-0.1,0-0.3,0-0.4-0.1
		c-0.3,0.7-1,1.7-0.7,2.2C386.1,394.2,385.6,394.8,384.4,395.6z M391,383.2c0.2,0.1,0.4,0.2,0.7,0.2c-0.5-1.5,0.4-1.9,1.2-2.4
		c0.5-0.3,0.8-0.8,0.2-1.4c-1.2,0.3-2.2,1-2.5,2.2C390.4,382.2,390.8,382.7,391,383.2z M419,398.6c-0.5,1.6-0.9,2.9-1.3,4.3
		C419.1,402.6,420,400,419,398.6z M393,409.4c-0.3,0.4-0.6,0.8-1.2,1.5c0.8-0.1,1.1-0.2,1.5-0.2c-0.1,0.5-0.1,1-0.2,1.9
		c0.8-0.8,1.3-1.3,1.8-1.8C394.3,410.4,393.7,409.9,393,409.4z M395,408.4c-0.5-0.5-1-1-1.5-1.3c-0.5-0.3-1.2-0.4-1.8-0.6
		C392.3,408,393.1,408.5,395,408.4z M389.1,410.4c0.2,1,0.4,1.7,0.7,2.7c0.4-0.8,0.7-1.3,1-1.9C390.3,410.9,389.8,410.7,389.1,410.4
		z M406.6,377.8c0.5-0.6,1.4-0.7,0.9-1.6c-0.1-0.2-0.5-0.3-0.7-0.2C406,376.3,406.2,376.9,406.6,377.8z M396.3,383
		c-0.4-0.4-0.5-0.8-0.8-0.8c-0.2-0.1-0.6,0.2-0.8,0.3c0.2,0.3,0.3,0.6,0.5,0.7C395.4,383.3,395.8,383.1,396.3,383z M418.2,404.8
		c-0.8-1.4-0.9-1.4-1.9,0C416.9,404.8,417.4,404.8,418.2,404.8z M400.6,383.1c1-0.9,1-0.9-0.1-1.5
		C400.5,382.2,400.6,382.7,400.6,383.1z M393.3,384.7c-0.7,1.7-0.7,1.7,0,2.2C393.3,386.2,393.3,385.6,393.3,384.7z M395.8,403.6
		c0.2-0.5,0.5-1,0.8-1.7C395.1,401.9,395.4,402.7,395.8,403.6z M413.7,394.8c-0.3-0.3-0.6-0.6-0.7-0.6c-0.2,0.1-0.5,0.3-0.6,0.5
		c0,0.1,0.3,0.5,0.4,0.5C413.1,395.2,413.3,395,413.7,394.8z M391,416.8c0.2-0.3,0.4-0.5,0.5-0.7c-0.2-0.2-0.5-0.3-0.7-0.5
		c-0.1,0.2-0.3,0.4-0.3,0.6C390.5,416.4,390.7,416.5,391,416.8z M382.2,375.2c-0.1,0.1-0.1,0.2-0.2,0.4c0.4,0.2,0.6,1.1,1.7,0.4
		C383,375.7,382.6,375.5,382.2,375.2z M418.2,396.8c0.1,0.1,0.2,0.1,0.3,0.2c0.3-0.4,0.6-0.7,0.8-1.1c-0.5-0.1-0.8,0-1,0.2
		C418.3,396.3,418.3,396.6,418.2,396.8z M395,374.6c0,0.1,0,0.2,0,0.4c0.1,0.1,0.3,0.2,0.3,0.2c0.2-0.1,0.4-0.3,0.6-0.4
		C395.6,374.7,395.3,374.7,395,374.6z M406.9,382.2c-0.1,0-0.3,0-0.4,0c0,0.3,0,0.6,0,0.9c0,0,0.2,0,0.4,0
		C406.9,382.8,406.9,382.5,406.9,382.2z M422.2,411.8c-0.4-0.3-0.6-0.4-0.7-0.5c0,0-0.2,0.2-0.2,0.3c0,0.2,0.1,0.4,0.3,0.4
		C421.7,412.1,421.9,412,422.2,411.8z"/>
	<path class="st2-bull" d="M239.2,398.8c-0.3-0.5-0.2-1,0.5-1c0.7,0,1.5,0.1,2.2,0.1c0.4,0,0.8-0.1,1.7-0.1c-1.4-1-1.7-2.3-3-2.9
		c-0.7-0.3-0.8-2-0.2-2.6c1-1,2.1-1.9,3.2-2.9c0.2,0.3,0.4,0.5,0.6,0.6c0.1,0,0.3,0,0.4-0.1c0.6-1.4,1.4-2.6,2.6-3.5
		c0.4-0.3,0.4-0.9,0.6-1.5c-1.1-0.8-2.2-1.1-3.4-1.1c-1.8-0.1-3.7-0.2-5.5-0.3c-0.8-1.5-0.4-3-0.4-4.4c1.9-0.6,3.5-2,5.6-1.5
		c0.5-0.7,1-1.3,1.4-1.8c1.2,0.3,2.2,0.5,3.6,0.7c-0.3-1-0.6-1.4-1.2-1.3c-1.5,0.2-2.5-0.4-3-2.2c-1.9,1-3.8,1.2-5.4-0.9
		c0.7-0.1,1.3-0.3,1.8-0.3c5.2,0,10.4,0,15.5,0.1c1,0,2.1-0.1,3.1-0.1c0.5,0,1.1,0.1,1.5,0.3c0.7,0.5,1.1,0.4,1.8,0
		c0.5-0.3,1.3-0.2,2.2-0.4c-0.3,0.5-0.4,0.8-0.5,1.1c0.5,0.6,1,1.4,1.7,1.8c0.6,0.3,1.5,0,2.4-0.1c-0.6-1-1.6-0.3-2.2-0.7
		c-0.3-0.5-0.6-1.1-0.9-1.6c1.5-0.5,21-0.7,25.9-0.3c0,7.1,0,14.3,0,21.7c-3.7,1.4-7,3.7-11,4.8c-0.3,0.1-0.6,0.1-0.8,0.3
		c-3,2.2-6.5,3.3-9.8,4.9c-4.1,1.9-8.3,3.6-12.3,5.8c-1.6,0.9-1.8,1.2-1.4,2.6c-0.5,0.3-1,0.6-1.6,1c-0.2,0-0.4-0.1-0.6-0.1
		c-0.4-0.1-0.8-0.3-1.2-0.4c0,0-0.3,0.4-0.3,0.5c0.3,0.4,0.6,0.9,1,1.2c0.2,0.1,0.6,0,0.8,0.1c0.7,0.5,1.7,0.9,1.8,1.5
		c0.2,1.5,0.1,3,0,4.5c0,0.3-0.8,0.7-0.8,0.7c-1-0.8-1.9,0-2.9,0c-1,0-2.1,0-3.1,0c-1.6,0-3.1-0.1-4.7-0.2c-0.1,0-0.3-0.1-0.4-0.1
		c-2.4,1.1-3.5-0.4-4.6-2.2c-0.3-0.6-0.9-1.1-1.2-1.7c-0.3-0.5-0.6-1-0.5-1.5c0.4-3.1-0.4-6.2,0.1-9.2c0.5-0.1,1-0.1,1.9-0.3
		c-0.8-0.8-1.3-1.2-2-2C238.9,402.4,237.9,400.5,239.2,398.8c0,1.1,0,2.2,0,3.3c1.3,0.3,2.1-0.1,1.9-0.9c-0.1-0.8-0.4-1.6-0.6-2.3
		C240,398.9,239.6,398.9,239.2,398.8z M255.7,399.1c-1.2-0.1-1.7,0.8-2.5,1.5c0.5,0.3,0.9,0.5,1.5,0.8c-0.5,0.3-0.9,0.5-1.4,0.9
		c0.8,0,1.2,0,1.8,0c0-1.1,0.3-1.9,1.3-2.5c0,0,0-0.3,0-0.4c0.3-0.7,0.1-1.3-0.5-1.8c-0.3-0.7-0.5-1.5-0.9-2.2
		c-0.5-1-1.6,0.1-2.3-0.5c0,0-0.2,0.1-0.1,0.1c-0.1,1-0.3,1.9-0.4,2.9c0.7-0.2,1.2-0.3,1.7-0.5l0,0c0.1,0.3,0.2,0.7,0.4,1
		c0.7-0.3,1.3-0.5,1.8-0.8C255.7,398.1,255.7,398.6,255.7,399.1z M261,400.2L261,400.2c-0.6-0.3-1.2-0.7-1.9-1.1
		c-0.2,0.4-0.7,0.9-0.6,1c1,1.3,0.2,2.1-0.6,3c-0.2,0.2-0.1,0.5-0.2,1.1c1.7-1.8,3.6-2,5.6-2.1C262.9,400.5,262.9,400.5,261,400.2z
		 M265.4,384c-2.1,0-4.3,0-6.4,0c-1.8,0-2,0.3-2,2.1c0,2,0,4,0,6c0,1.7,0.7,2.1,2.2,1.4c4.6-2.2,9.2-4.4,13.7-6.6
		c0.4-0.2,0.8-0.6,0.9-1c0.4-1.1-0.2-1.8-1.5-1.9C270.1,384,267.7,384,265.4,384z M245.4,383.7c0.3-0.7,0.7-1.1,0.6-1.3
		c-0.6-1-0.7-2,0-3.1c-1.4,0-2.6,0-3.9,0c0.4,1,0.7,1.8,1,2.7C244.1,381.8,244.8,382.4,245.4,383.7z M247.4,378.9
		c-0.1,0.1-0.1,0.2-0.2,0.3c0.6,0.2,1.3,0.4,1.8,0.5c-0.1,0.7-0.3,1.3-0.4,2c1-1,1.8-2,2.3-3.1c0.6-1.2,0.9-2.4,1.4-3.6
		c-0.2-0.1-0.4-0.1-0.5-0.2c-0.5,1.2-1,2.3-1.5,3.5C249.3,378.5,248.3,378.7,247.4,378.9z M248.5,410.6c-0.8,0.3-1.3,0.5-1.8,0.7
		c0,1.8,0,1.8,1.8,1.3C248.5,412.1,248.5,411.5,248.5,410.6z M257.1,404.9c-1.4-0.2-1.8,0.5-1.9,1.7c0.8,0,1.4,0,2,0
		C257.2,406,257.2,405.5,257.1,404.9z M243.9,414.5c-2.2,0.2-2.7,0.5-3,1.5C242.4,416.4,243.2,415.6,243.9,414.5z M275.8,388.4
		c0.2,0.1,0.3,0.2,0.5,0.3c0.4-0.3,0.9-0.6,1.5-1c-0.7-0.6-1.2-1-1.9-1.6C275.8,387,275.8,387.7,275.8,388.4z M243.9,408.8
		c-1.5,0.6-1.2,1.8-1,3c0.6-0.8,1.1-1.5,1.7-2.3C244.3,409.3,244.1,409.1,243.9,408.8z M254.2,378.6c-0.6,0.9-1,1.4-1.3,1.9
		c1.2,0.6,2.2,1,3.1,0.1C255.8,379.4,254,380.6,254.2,378.6z M263.4,395.3c0.1,1.1,0.1,1.7,0.2,2.5c0.7-0.1,1.3-0.2,1.8-0.3
		c0.1-0.1,0.1-0.3,0.2-0.4C265,396.7,264.4,396.2,263.4,395.3z M278.8,379.3c1.5,0.3,2.6,0.5,4.1,0.8c-1.1-1.4-2.6-0.9-3.5-1.7
		C279.2,378.7,279,378.9,278.8,379.3z M266.9,402.5c-1.2-0.4-1.8-0.6-2.8-0.8c0.4,0.9,0.6,1.4,0.9,2.2
		C265.6,403.5,266,403.2,266.9,402.5z M266,396.6c0.7,1.2,1.2,0.4,1.6-0.1c0.2-0.2,0.1-0.7,0.1-1c-0.4,0.2-0.8,0.3-1.1,0.5
		C266.3,396.1,266.2,396.4,266,396.6z M277.7,399c-1.2-0.7-1.8-1.6-3.1-0.9C275.2,399.5,276.3,399,277.7,399z M254.2,411.2
		c0.6-0.3,1.2-0.6,2-1C255,409.6,254.4,410.1,254.2,411.2z M245.2,404.2c-0.1-0.2-0.2-0.4-0.3-0.7c-0.4,0.2-0.8,0.4-1.2,0.6
		c0,0,0.2,0.4,0.3,0.6C244.3,404.6,244.7,404.4,245.2,404.2z M257.7,406.9c1.1,1.3,1.1,1.3,1.9,0.2C259,407,258.5,406.9,257.7,406.9
		z M251,412.7c-0.5-1.5-0.6-1.6-1.3-1.5c-0.2,0-0.3,0.5-0.5,0.8C249.9,412.3,250.4,412.5,251,412.7z M250.1,400.1
		c0.5-0.7,0.6-1.4-0.6-1.6C249.7,399.2,249.9,399.6,250.1,400.1z M246.9,398.3c0-0.1,0-0.2,0-0.3c-0.4,0-0.8,0-1.2,0
		c0,0.1,0,0.2,0,0.3C246.1,398.3,246.5,398.3,246.9,398.3z M272,396.4c0.1,0,0.3-0.1,0.4-0.1c0-0.2,0.1-0.6-0.1-0.7
		c-0.2-0.2-0.5-0.1-1.2-0.3C271.6,395.9,271.8,396.2,272,396.4z M271.6,373.5c0,0.1,0,0.2,0,0.3c0.2,0.1,0.4,0.2,0.7,0.2
		c0.2,0,0.4-0.2,0.7-0.3c0-0.1-0.1-0.2-0.1-0.3C272.4,373.4,272,373.5,271.6,373.5z M255.8,375.6c0.2,0,0.3,0,0.5,0
		c-0.1-0.4-0.1-0.9-0.2-1.3c-0.1,0-0.2,0-0.3,0C255.8,374.8,255.8,375.2,255.8,375.6z M242.5,408.1c-0.6,0.1-1.1,0.1-1.5,0.1
		C241.5,409.1,241.9,409.2,242.5,408.1z M251,409.9c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.2,0.4,0.4,0.7,0.6c0.1-0.1,0.3-0.4,0.3-0.4
		C251.5,410.2,251.3,410.1,251,409.9z M290.9,379c0.2,0.6,0.3,1.1,0.5,1.6C291.7,379.9,292.2,379.3,290.9,379z"/>
	<path class="st1-bull" d="M813.2,410.6c0.7,0,1.1,0,1.3,0c0.4,2.2,0.7,4.2,1.1,6.5C813.1,415.4,813.4,413,813.2,410.6z"/>
	<path class="st1-bull" d="M815,392.6c-0.3,2-0.6,3.7-0.9,5.6c-1.4-0.4-0.9-1.4-0.9-2C813.2,394.9,812.7,393.3,815,392.6z"/>
	<path class="st1-bull" d="M813.2,401.8c0-0.8,0-1.6,0-2.4c0.1,0,0.1,0,0.2,0c0,0.8,0,1.6,0,2.3C813.4,401.7,813.3,401.7,813.2,401.8z"/>
	<path class="st1-bull" d="M813.6,382.9c0.2,0.2,0.4,0.5,0.6,0.7c-0.1,0.1-0.2,0.2-0.4,0.4c-0.2-0.2-0.5-0.4-0.7-0.7
		C813.3,383.2,813.4,383.1,813.6,382.9z"/>
	<path class="st2-bull" d="M548.8,420.9c-0.8,0.2-1.5,0.5-1.9-0.4c-0.1-0.1,0-0.5,0.2-0.5C548,419.5,548.2,420.3,548.8,420.9z"/>
	<path class="st3-bull" d="M382.5,400.2c0.3,2.5-0.4,4.9-1.3,7.3c0.2-2.3,0.4-4.7,0.6-7C381.9,400.4,382.3,400.4,382.5,400.2L382.5,400.2
		z"/>
	<path class="st2-bull" d="M386.3,411.3c-0.2,0.3-0.3,0.7-0.5,0.7c-0.4,0.1-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.5-0.2-0.5
		c0.4-0.2,0.7-0.4,1.1-0.6C385.7,411.1,385.9,411.2,386.3,411.3z"/>
	<path class="st3-bull" d="M386.2,410.4c-0.2,0.1-0.4,0.2-0.5,0.2c-0.5-0.1-0.9-0.3-1.4-0.5c0.1-0.2,0.3-0.7,0.3-0.6
		c0.5,0.1,1,0.3,1.4,0.5C386.1,409.9,386.1,410.1,386.2,410.4z"/>
	<path class="st3-bull" d="M381.4,412.5c0-0.9,0-1.7,0-2.6c0.1,0,0.1,0,0.2,0c0,0.9,0,1.7,0,2.6C381.6,412.5,381.5,412.5,381.4,412.5z"/>
	<path class="st3-bull" d="M382.6,400.3c-0.4-1.2,0.4-1.3,1.3-1.2c0,0,0,0,0,0C383.4,399.4,383,399.8,382.6,400.3
		C382.5,400.2,382.6,400.3,382.6,400.3z"/>
	<path class="st4-bull" d="M387.8,372.2c0.3,0,0.6,0,0.8,0.1c0,0,0,0.3,0,0.3c-0.3,0-0.6,0.1-0.9,0.1
		C387.8,372.6,387.8,372.4,387.8,372.2z"/>
	<path class="st3-bull" d="M383.9,409c-0.2,0.3-0.3,0.5-0.4,0.7c-0.1-0.1-0.4-0.2-0.4-0.3c0-0.2,0.2-0.4,0.3-0.6
		C383.4,408.8,383.7,409,383.9,409z"/>
	<path class="st3-bull" d="M241.7,374.5c0.8,1.8-0.3,2.5-1.2,3.2c-0.3-0.6-0.7-1.3-1.1-2C240.2,375.3,240.9,374.9,241.7,374.5z"/>
	<path class="st1-bull" d="M238.7,396.7c0-0.7,0-1.4,0-2.1c0.1,0,0.1,0,0.2,0c0,0.7,0,1.4,0,2.1C238.8,396.7,238.7,396.7,238.7,396.7z"/>
	<path class="st2-bull" d="M26,94.1c0.3-0.3,0.5-0.5,0.6-0.7c0.2,0.2,0.4,0.3,0.5,0.5c0,0.1-0.2,0.5-0.3,0.5C26.6,94.4,26.4,94.2,26,94.1
		z"/>
	<path class="st2-bull" d="M57.5,109.7c0.3,0,0.5-0.1,0.8-0.1c0,0.2,0,0.3,0,0.5c-0.3,0-0.6,0-0.8,0.1C57.6,110.1,57.5,109.9,57.5,109.7z
		"/>
</g>
</svg>

    </div>
  )
}

export default BullProof
