import React from 'react'
import "./BullProof.css"
const BullProofWhite = () => {
  return (
    <div>
     
	 <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 884.7 425.6" style={{ enableBackground: 'new 0 0 884.7 425.6' }}
	 space="preserve">

<g>
	<g>
		<g>
			<path class="st0-bull-white" d="M199.46,347.03c-0.8-0.4-1.4-0.6-2.1-1c0.4-1.4,0.7-2.8,1.1-4.2c1.7-6,3.3-12,5-17.9
				c0.4-1.3,0.2-2.4-0.5-3.5c-10.7-17.8-21.3-35.6-31.9-53.4c-3-5.1-6.1-10.2-9.1-15.4c-1.5-2.6-2.7-5.4-3.3-8.4
				c-1.1-5.4-2.4-10.7-3.6-16.1c-0.4-1.9-0.6-3.9-1-6.2c2.1,1.1,4,2.1,5.8,3.1c1.8,0.9,3.5,1.9,5.3,2.8c9.4,5,18.7,10,28.1,15.1
				c1.4,0.7,2.8,1.3,4.5,2.1c-0.2-1-0.3-1.6-0.4-2.1c-1.5-6.1-3.1-12.2-4.5-18.3c-0.3-1.2-0.9-1.8-2.1-2c-3.9-0.6-7.8-1.3-11.7-2
				c-7.7-1.3-15.4-2.6-23.1-3.8c-1.6-0.3-2.7-0.9-2.9-2.7c0-0.3-0.1-0.6-0.3-0.8c-1.4-1.1-1.4-2.8-1.7-4.3c-2-9.1-4.2-18.1-5.9-27.3
				c-0.5-2.9-1.9-3.9-4.4-4.7c-9.2-2.8-18.3-5.8-27.4-8.8c-5.1-1.7-10.2-3.5-15.3-5.3c-4.5-1.6-8.9-3.5-13.3-5.3
				c-5.2-2.1-10.5-4.3-15.6-6.5c-2.3-1-4.5-2.1-6.7-3.2c-4.3-2.1-8.7-4.2-13-6.3c-1.1-0.5-2.1-1.3-3.3-1.8
				c-7.6-3.5-14.4-8.5-21.1-13.5c-5.8-4.4-10.9-9.5-14.8-15.7c-2.7-4.3-5-8.7-7-13.3c-1.6-3.8-2.5-7.8-3.1-11.8
				c-0.6-4.6,0.5-8.9,2.5-13c3.4-7.3,8.5-13.4,14-19.1c5.5-5.8,11.7-10.8,18.2-15.4c6.1-4.3,12.5-8.1,19-11.8
				c8.3-4.9,17.1-8.9,26-12.7c6.8-2.9,13.6-5.7,20.6-8c3.3-1.1,6.6-2.3,9.9-3.5c0.6-0.2,1.2-0.7,1.8-1c0.2-0.1,0.6,0.1,0.9,0.3
				c-9.3,4.2-18.7,8.2-27.3,13.7c6.9-2.3,13.6-5.3,20.6-7.2c0.1,0.2,0.1,0.3,0.2,0.5c-1,0.6-2,1.2-3,1.7
				c-7.7,3.7-15.2,7.6-22.4,12.2c-6.6,4.3-12.9,9-18.4,14.7c-4.7,4.8-8.7,10.2-10.9,16.7c-2,5.7-2.2,11.5-0.3,17.3
				c0.2,0.7,0.5,1.3,0.8,1.9c1.9,4.1,4.7,7.5,7.6,10.7c2.2,2.5,4.9,4.6,7.4,6.8c6.1,5.3,13,9.5,19.9,13.6c3,1.8,6,3.6,9.1,5.3
				c2.6,1.4,5.4,2.6,8.1,3.9c2.8,1.3,5.6,2.6,8.5,3.8c5.2,2.2,10.4,4.2,15.7,6.3c1.2,0.5,2.2,0.4,3.4,0c3.5-1.4,7.1-2.6,10.7-4
				c1.1-0.4,1.9-0.4,2.8,0.4c1.8,1.7,3.8,3.2,5.6,4.9c1.2,1.1,2.3,1.2,3.8,0.8c5.3-1.7,10.5-3.4,15.8-5c9.2-2.9,18.4-5.9,27.6-8.8
				c5.4-1.7,10.8-3.5,16.1-5.3c5.5-1.8,11.1-3.5,16.6-5.3c1.1-0.3,1.9-0.2,2.8,0.6c2.1,1.7,4.3,3.2,6.6,4.9c2.3-1.7,4.6-3.2,6.7-4.9
				c1-0.8,1.7-0.8,2.8-0.4c8.9,3.1,17.8,6.1,26.8,9.1c8.9,3,17.8,5.9,26.7,8.9c6.2,2.1,12.3,4.2,18.4,6.4c1.4,0.5,2.4,0.5,3.5-0.6
				c1.9-1.9,4.1-3.5,6.1-5.3c0.8-0.7,1.5-0.6,2.3-0.3c3.7,1.4,7.4,2.7,11.1,4.1c1.1,0.4,2.1,0.5,3.2,0c5-2.1,10.1-4.1,15-6.3
				c5.1-2.3,10.2-4.9,15.2-7.5c2.1-1.1,4.1-2.5,6.2-3.7c2.4-1.4,4.9-2.6,7.2-4c5.7-3.7,11.3-7.4,16.3-12c2-1.8,3.9-3.7,5.8-5.5
				c0.3-0.3,0.4-0.8,0.6-1.2c0.1-0.2,0.1-0.4,0.2-0.5c2.4-1.8,4.8-4.7,6.3-7.5c0.8-1.5,1.9-2.9,2.5-4.5c0.8-2.1,1.3-4.2,1.7-6.4
				c0.9-4.3,0.4-8.6-0.7-12.8c-0.9-3.2-2.4-6.2-4.6-8.8c-2.6-3.1-5.3-6-8-8.9c-5-5.3-11.2-9.2-17.3-13.1c-6.8-4.3-13.9-8-21.1-11.5
				c-0.8-0.4-1.5-0.8-2.2-1.5c2.9,0.2,5.6,1.4,8.5,2.1c-1.6-1.4-3.6-2.2-5.5-3.1s-3.8-1.9-5.8-2.8s-4-1.7-6-2.9
				c0.9,0.2,1.8,0.4,2.7,0.7c6.9,2.5,13.9,4.9,20.8,7.6c4,1.6,7.9,3.5,11.9,5.2c0.9,0.4,1.9,0.5,2.7,0.9c6,3,12,6.1,18,9.2
				c1.5,0.8,3,1.7,4.4,2.6c2.4,1.4,5,2.6,7.3,4.2c3.3,2.2,6.4,4.7,9.6,7.1c2.3,1.8,4.7,3.6,6.9,5.5c2.7,2.4,5.3,5,7.9,7.5
				c0.5,0.5,1.1,0.9,1.9,1.5c-0.1,1.6,1.8,2.3,2.7,3.7c1,1.6,2.4,3,3.4,4.6c3.1,4.7,5.7,9.6,7.7,14.9c2.3,6,2.4,12.1,1.4,18.4
				c-1.6,10.1-5.7,18.9-12.4,26.5c-4.1,4.6-8.8,8.6-13.9,12.1c-5.8,3.9-11.9,7.2-18.2,10.4c-9.3,4.7-18.7,9-28.3,12.9
				c-8.7,3.5-17.5,6.9-26.3,10.1c-9,3.2-18.1,6.1-27.1,9.1c-2.8,0.9-5.5,1.7-8.3,2.5c-1,0.3-2,0.4-3.1,0.4
				c-12.8,0.1-25.6,0.2-38.4,0.3c-6.4,0-12.7,0-19.1,0c-2.7,0-3,0.3-3.7,2.9c-3.1,13.3-6.2,26.7-9.3,40c-2.4,10.2-4.8,20.4-7.2,30.7
				c-4.1,17.7-8.2,35.4-12.4,53.1c-1.6,6.7-3.1,13.3-4.7,20c-2.1,8.8-4.2,17.6-6.2,26.4c-0.4,1.7-1,3.3-2.1,4.7
				c-0.7,0.8-1.2,1.8-1.9,2.7c-0.9,1.3-1.3,1.5-2.8,1s-2.9-1.2-4.4-1.7c-1.8-0.6-3.7-1.2-5.5-1.8c-5.1-1.7-10.2-3.4-15.4-5.1
				c-1.6-0.5-1.8-0.8-1.6-2.6C199.36,347.73,199.36,347.43,199.46,347.03z M23.46,107.23c-0.4,0.4-1,0.7-0.7,1.4
				C23.86,108.13,23.86,108.13,23.46,107.23c0.1-0.5,0.3-1,0.4-1.5c0-0.3-0.2-0.6-0.4-0.8c-0.3,0.2-0.7,0.2-0.8,0.5
				c-0.2,0.5-0.2,1.1-0.3,1.8C22.76,107.13,23.16,107.23,23.46,107.23z M66.16,96.03c-0.6,0.2-0.9,0.1-1.7-0.5
				c0.4-0.5,1.2-1,1.1-1.4c-0.2-1.3,0.9-1.4,1.5-1.9c1.1-0.8,1.2-1,0.3-1.9c-1.1-1-2.3-2-3.4-3c-0.5-0.4-0.9-0.5-1.5-0.2
				c-0.1,0.1-0.3-0.1-0.5-0.2c0.2-0.3,0.4-0.6,0.5-0.9c-1.4-1.1-1.4-1.1-2.3-0.1l0.1-0.1c-0.5,0.1-1,0.1-1.7,0.2
				c0.6-0.8,1-1.4,1.4-2c-0.7-1-1.3-1.9-1.9-2.8c-1.2,0.6-1.5,2.2-3,1.6c-0.5-1.9,1.8-1.3,2.2-2.5c-0.5-0.6-1-1.3-1.6-2
				c-1,1.8-3.4,2.2-3.2,4.5c-0.6,0.1-1.1,0.2-1.6,0.3c-0.2,2.8-2.4,4-4.1,5.7c-0.7,0.8-1.6,1-2.7,0.7c-0.4-0.8-1.2-1.5-0.6-2.7
				c0.6,0,1.2-0.1,1.6-0.1c0.3,0.5,0.5,0.9,0.8,1.3c0.7-0.8,1.7-1.4,1.8-2.2s-0.5-1.7-0.9-2.7c0.6-0.1,1.1,0,1.7-0.2
				c0.5-0.1,1.1-0.2,1.5-0.5c1.8-1.2,2.4-3.5,4.1-4.9c0.2-0.2,0.2-1-0.1-1.4c-0.9-1.2-1.9-2.6-2.9-4.2c-1.2,2.1-2.5,3.9-5,4.3
				c0.1,0.6,0.2,1,0.3,1.2c0.9,0.5,2-0.1,2.7,0.8c-1.3,0.8-2.5,0.2-3.9-0.2c0.3,0.5,0.4,0.7,0.6,1c-2.3,0.8-4,2.3-4.9,4.5
				c-0.9,2.1-2.6,3.4-4.5,4.8c-0.1-0.9-0.3-1.7-0.4-2.7c0.3,0.5,0.5,0.7,0.8,1.2c1.2-1.7,2.1-3.2,3.3-4.4c1.3-1.3,1.6-3.5,3.6-4.4
				c-0.2-0.2-0.3-0.4-0.4-0.6c0.5-0.4,1.2-0.6,1.5-1.1c0.6-1.4,1.7-1.9,2.4-1.9c1.2-1.2,2.1-2,2.8-2.9c0.3-0.4,0.6-1,0.5-1.4
				c-0.1-0.5-0.6-1-1.1-1.3c-0.3-0.2-0.9-0.2-1.3-0.2c-0.5-1.3,0.4-1.5,1-1.7c-0.4-2.2-0.8-4.2-1-6.2c-0.6-7,1.4-13.4,5-19.3
				c4.9-8,11.7-14.1,19.1-19.6c6.7-5,13.9-9.2,21.3-13.1c0.6-0.3,1.3-0.7,1.9-1c-0.5-0.2-0.9-0.1-1.2,0c-4.4,1.7-8.7,3.4-13,5.2
				c-9.7,4.1-19.1,8.6-28.2,13.8c-9.7,5.6-19,11.7-27.6,19c-6.2,5.3-12,11-16.7,17.7c-2.5,3.2-5,6.4-5.7,10.5
				c-0.1,0.3-0.3,0.6-0.6,0.8c-0.9,0.7-0.4,1.4,0,1.9c-1.5,3-1.9,5.9-1.3,9.1c0.6-0.4,1.2-0.8,1.8-1.2c0.2,0.4,0.3,0.7,0.5,1
				c-0.3,0.3-0.7,0.5-1.1,0.6c0.1,0.6,0.8,1.1-0.2,1.6c-0.2,0.1-0.2,0.7-0.3,1.3c2.4-1.4,4.5-2.6,6.5-3.9c1.4-0.9,3-1.1,4.6-1.5
				c2.1-0.5,4.4,0,6.2-1.5c1.5,1.5,2.6,1.5,3.7-0.1c0.4-0.6,0.5-1.4,0.8-2l0,0c0.2,0.5,0.4,1,0.7,1.3c0.2,0.3,0.7,0.4,1.2,0.6
				c-0.5,1.3,1,1.7,1.4,2.6c0.8-0.2,1.6-0.4,2.6-0.7c0.1-0.3,0.3-0.8,0.5-1.6c0.4,0.5,0.8,0.9,0.8,1.1c-0.4,1.2-0.3,2.8-2.2,2.7
				c-0.5-0.3-0.8-1.3-1.7-0.7c0,0.3,0,0.6,0.1,1c-1-0.4-1.9-0.9-2.8-0.4c-0.3,2.9-0.6,5.7-1,8.7c-0.5-0.4-0.8-0.6-1-0.7
				c0.9-0.9,0.4-1.9,0.1-3.1c-0.2,0.5-0.3,1-0.5,1.5c-0.5-0.6-0.7-0.7-0.7-0.9c0-0.2,0-0.5,0.1-0.6c1.2-1.4,1.7-2.9,1.4-4.8
				c-1.1-0.6-2.2-1.5-3.3-1.7c-1.6-0.2-3.2,0.1-4.8,0.5c-1.9,0.5-3.7,1.2-5.5,1.9c-0.7,0.3-1.6,0.6-2,1.2c-0.5,0.6-0.8,0.9-1.4,0.9
				c-1.8,0.2-2.6,1.4-2.8,3c-0.1,1.3,0.2,2.6,1.2,3.3c0.8,0.7,1.9,0.7,2.9,0c0.3-0.2,0.6-0.2,1-0.3c1.1-0.1,1.9-0.5,2.5-1.5
				s2.5-1.1,3.6-0.5c-0.3,0.2-0.8,0.3-0.8,0.5c-0.2,0.9-0.5,1.5-1.5,1.6c-0.2,0-0.3,0.6-0.6,0.8c-0.4,0.3-0.9,0.6-1.4,0.7
				c-1.8,0.3-3,1.4-4.1,2.9c0.5,0.5,1,0.8,1.6,1.4c0-0.9,0-1.4,0-1.9c0.6,2.1,2.7,2.4,3.9,3.9c1-1,1.7-2.2,2.8-2.7
				c1.6-0.7,3.4-1.3,5-1.1c1.6,0.2,2.9-0.3,4.6-0.3c-0.3-0.9-0.5-1.6-0.8-2.5c2.1-0.6,0.8-2.5,1.4-3.9c0.4,0,0.9,0.1,1.2,0.1
				c0.5-1.3,0.9-2.4,1.3-3.6c1.6,1.2,0.4,2.2,0.2,3.2c1.5,0.6,1.9,1.7,1.5,3.3c-1.4,0.3-2.2,1.3-2.6,2.6c3.3-1.6,5.7-4.3,8.2-6.9
				c-0.1-0.5-0.1-1.1-0.3-1.5c-0.2-0.4-0.6-0.7-1-1.1c0.6-0.2,1.1-0.5,2-0.9c-0.6,1.9,1.1,1.1,1.7,1.8c-0.6,0.8-1.3,1.5-1.7,2.4
				c-0.9,1.9-2.6,3-4.1,4.2c-0.9,0.7-1.6,1.7-1.5,2.4c0.1,0.7-0.5,1.4,0,2.1c1.2,0.5,1.3-1,2.1-1.3c0.2,0.2,0.3,0.4,0.5,0.6
				l-0.1-0.1c-0.1,0.5-0.2,1.1-0.4,1.6c-0.9,0-1.6,0-2.4,0c0.1,0.4,0.2,0.6,0.2,0.8c0.4,1.3,1.1,2.5,0.8,3.9l0.1-0.1
				c-0.2-0.1-0.5-0.3-0.8-0.5c-0.6,0.7-1.2,1.6-2.3,0.7c-0.6,1.7,0.8,1.3,1.4,1.6c0.4,0.8,0.1,2.1,1.6,2.2c0.4-0.8,0.4-2.1,1.8-1.8
				c-0.5,1.3,0.8,1.1,1,1.4c0.6-1.1,1.1-2.3,1.7-3.5c0.3,0.4,0.5,0.7,0.7,0.9c0.3-0.4,0.5-0.6,0.7-0.9c0.2,0.5,0.4,1,0.6,1.4
				c-0.9,0.3-1.7,0.7-2.6,1c0.6,2-1.1,2.2-2.2,2.9c-1.1,0.6-1.7-0.2-2.6-0.9c0.1,0.9,0.1,1.8,0.2,2.6c-0.6-0.2-1.1-0.3-1.6-0.5
				c0.2,1-1,0.6-1.3,1.2c0.9,0.6,1.9,1.2,2.7,1.8s1.8,1.2,2.2,2c0.8,1.7,2.4,2.6,3.5,4c1.1-0.6,2.1-1.2,3.1-1.8
				c0.4,0.7,0.6,1.2,1.1,2.2c0.3-1,0.5-1.6,0.8-2.3c0.7,0.2,1.5,0.2,1.9,0.6c0.8,0.8,1.1,0.4,1.6-0.3c0.1-0.1,0.3-0.1,0.7-0.1
				c-0.1,0.6-0.2,1-0.3,1.7c0.5-0.2,0.8-0.2,0.9-0.4c0.5-1,0.9-2.1,2.1-2.6c0.2-0.1,0.2-0.9,0.3-1.4c-1.6-0.5-1.4,0.9-2.2,1.6
				c-0.5-0.5-1-1-1.5-1.4c-0.3-0.3-0.8-0.9-1-0.8c-1.3,0.6-1.3-0.7-1.9-1.4c-0.6,0.2-1.2,0.5-1.8,0.7c-0.7,1,1.2,2.7-0.8,3.1
				c-1.2-1.8-1.2-1.8-0.6-3.2c-0.5-0.2-1-0.3-1.6-0.5c0.9-0.4,1.5-0.7,2.2-1c0.2,0.6,0.7,1.4,1.1,0.5c0.4-1,1.2-0.7,1.8-1
				c0.3-0.8,0.1-1.9,1.4-2c1.1,1.9,1.5,1.9,2.9,0.2c0.3-0.4,0.6-0.9,1.1-1.1c0.4-0.3,1-0.4,1.5-0.4c0.2,0,0.4,0.5,0.6,0.8
				c-1.5,1.1-2.9,2.1-4.4,3.1c0.3,0.3,0.6,0.6,1,0.9c-1,1-1.4-0.6-2.5-0.3c0.9,0.6,1.2,1.5,2.2,1.4c0.4,0,1-0.1,1.1-0.3
				c0.2-0.9,0.9-1.3,1.6-1.3s1.4,0.5,2.1,0.7c-0.2,0.5-0.3,0.8-0.4,1.2c0.5-0.1,0.7-0.4,1-0.6c1.7-1.4,2.5-1.2,3.1,0.9
				c0,0.1,0.2,0.1,0.4,0.1c0.4-0.6,0.7-1.4,1.1-2c0.5-0.7,0.8-1.8,2.3-1.7c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0.6-0.2,1.2-0.2,1.6
				c0.9-0.2,1.5-0.3,2.4-0.5c0.1-0.7,0.3-1.7,0.4-2.7c-1.5-0.9,0.7-1.8,0-2.6c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8,1-1.4,2-2.1,2.9
				c-0.2-0.1-0.3-0.1-0.5-0.2c0.2-0.9,0.2-1.8,0.7-2.5c1.2-1.9,1.4-3.9,1.4-6c0-1.2,0.6-2.3,1-3.4c0.1-0.2,0.4-0.3,0.7-0.3
				c1.1-0.1,1.1-0.1,1-1c-0.1-0.8-0.2-1.6-0.3-2.1c1.1-0.6,1.8-1,2.7-1.4c0.3,0.5,0.4,1.4,1.4,1.3c0.2-0.7,0.3-1.3,0.5-2
				c0,1,0,2,0,2.9C64.96,97.63,66.56,97.43,66.16,96.03c1.2-0.5,1.6-1.5,1.6-2.9C66.56,93.93,66.16,94.93,66.16,96.03z
				 M217.46,347.33c-0.2-0.1-0.4-0.2-1-0.5c0.3,0.9,0.5,1.4,0.6,1.8c-0.5,0.4-0.9,0.8-1.3,1.1c2.2,2.1,2.2,2.1,2.9,2
				c-0.4-0.8-0.7-1.5-1.2-2.5c0.8,0,1.4,0,2.2,0C218.76,348.43,218.06,347.83,217.46,347.33c1.5,0.3,0.5-1.2,1.3-1.4
				c-0.6-0.5-1-0.8-1.3-1.1C217.46,345.63,217.46,346.53,217.46,347.33z M16.26,104.73c0.4-0.6,0.8-1.3,1.1-1.9
				c-0.5-0.6-0.9-1.2-1.4-1.8c-1.8-0.1-2.4,1.4-3.5,2.4C13.66,104.43,14.76,105.13,16.26,104.73c0.1,0.6,0.2,1.3,0.3,1.9
				c1.3,0.1,2.5,0.2,3.6,0.3c0.1-0.2,0.1-0.4,0.3-0.5c1.4-1,1-3.2,2.7-3.9c0.1,0,0.2-0.4,0.1-0.6c-0.6-0.9,0.3-1.6,0.2-2.8
				c-2.1,1.5-4,2.9-4.3,5.5C18.06,104.63,17.16,104.73,16.26,104.73z M418.16,34.33c-0.8-0.4-0.8,0.3-1.2,0.8
				c0.9,0.4,1.6,0.5,1.8,1.8c0.2,1.2,1.4,2.2,2.2,3.3c1.9-1.1,2-1.4,0.5-2.4c0.9-0.6,0.9-0.6,0.7-1.7c1.4,1.8,1.9,4,2,6.2
				c-0.5,0.3-1.1,0.6-1.6,0.9c0.1-0.6,0.2-1.3,0.4-2.2c-0.6,0.3-0.9,0.4-1.2,0.5c0.3,0.6,0.6,1.1,0.9,1.7c0.7,1.2,1.4,2.4,2.1,3.6
				c-0.1,0.1-0.3,0.3-0.4,0.5c1.1,1.4,2.1,2.9,3.3,4.5c-0.8,0.1-1.5,0.1-2.1,0.2c0.2,1.4,0.5,2.7,0.7,3.9c0.6,0,1.1,0,1.6,0.1
				c-0.1,0.3-0.1,0.7-0.3,0.8c-1.6,1.3-1.6,1.7-0.7,3.7c0.3,0.7,0.6,1.6-0.2,2c-0.7,0.3-0.6,0.7-0.7,1.2c-0.2,0.8-0.4,1.6-0.6,2.4
				c-0.1,0.5-0.3,1.1-0.2,1.5s0.7,0.7,0.9,0.9c-0.4,0.3-0.8,0.6-1.3,0.8c-1.5,0.9-1.8,2.7-2.6,4.1c-1.6,2.6-3.1,5.1-5.2,7.4
				c-2.8,3-5.4,6.1-8.6,8.8c-7.7,6.5-16.1,12.1-24.9,17c-11.1,6.3-22.7,11.6-34.7,16.2c-1.6,0.6-3,0.6-4.5,0
				c-3.8-1.5-7.7-2.9-11.5-4.2c-2.3,2-4.5,3.7-6.6,5.6c-1.3,1.3-2.5,1.5-4.3,0.9c-7.5-2.6-15-5.1-22.5-7.7
				c-16.5-5.5-32.9-11.1-49.4-16.7c-1-0.3-1.7-0.2-2.4,0.4c-1.7,1.3-3.5,2.5-5.2,3.8c-1.9,1.3-1.9,1.3-3.8,0c-2.1-1.5-4.1-3-6.2-4.6
				c-0.4,0.1-0.8,0.2-1.2,0.3c-7.5,2.4-15.1,4.9-22.6,7.3c-14.2,4.5-28.4,9-42.6,13.6c-3.5,1.1-7.1,2.2-10.6,3.4
				c-1.8,0.6-3.3,0.5-4.7-0.9c-1-1-2.2-1.9-3.3-2.8s-2.2-1.8-3.3-2.7c-4.1,1.5-8,3-11.8,4.4c-1.1,0.4-2.2,0.6-3.4,0.1
				c-6.5-2.7-13-5.3-19.5-8c-0.4-0.2-0.8-0.4-1.2-0.4c-1.5-0.1-2.8-0.6-4-1.4c-0.1-0.1-0.4,0.2-0.6,0.3l0,0
				c-0.7-1.7-0.7-1.7-2.6-1.7c0.3,1.3-0.8,1.5-1.7,1.6c0,0.6,0,1,0,1.5c-0.7-0.4-1.2-0.7-1.7-1c0.4-0.4,0.9-0.7,1.2-1.2
				c0.7-0.9,0.4-2.5-0.5-3c-1.2-0.6-2.4-1.1-3.9-0.6c0.1-0.4,0.3-0.7,0.4-1.2c-2.7-1.4-5.4-2.6-8-4.1s-5-3.3-7.6-4.9
				c-2.5-1.7-5-3.4-7.5-5.1c-0.8-0.1-1.3,1.2-2.2,0.4c-0.1-0.1-0.7,0.5-1,0.8c-0.3,0.4-0.4,0.9-0.6,1.4c-0.5,1.1-1.3,2.2-1.4,3.3
				c0,2.5-2.4,4.2-1.9,6.7c0,0.2-0.3,0.4-0.4,0.7c-1.2,1.6-1.4,3.3-1,5.2c0.1,0.3,0.1,0.7,0,0.9c-0.8,1.2-0.4,2.6-0.7,3.8
				c0.7,0.6,1.2,1.2,1.9,1.7c0.3-1.6,1.5-2.1,3.8-1.8c1.2-0.7,2-1.3,2.9-1.8c0-0.1-0.1-0.2-0.1-0.4c-1,0.1-2.1,0.2-3.2,0.3
				c0-0.5,0-1.1,0-1.7c0.1-0.1,0.2-0.1,0.4-0.2c0.2,0.3,0.3,0.6,0.4,0.7c1-0.3,2-0.6,3-0.9c0.2-1.4-1.2-1.3-2.1-2c1.1,0,1.8,0,2.6,0
				c0.1,0.5,0.3,1,0.4,1.4c1.2,0.5,1.9-0.3,2.6-0.9s1.1-0.8,1.8-0.1c-1,0.9-1.8,1.8-2.8,2.7c0.5,0.5,1,0.9,1.5,1.4
				c0.3-0.7,0.4-1.3,0.8-1.7s1-0.6,1.5-0.9c0.1,0.3,0.2,0.6,0.3,0.9c1-0.6,1-0.6,2.1-2.6c-0.3-0.2-0.5-0.4-0.9-0.7
				c0.7,0,1.2,0,1.8,0c-0.1,0.5-0.2,0.9-0.3,1.3c0.7,0,1.3-0.1,1.9-0.1c-0.2,1.8-0.6,2.6-1.2,2.4c-2.1-0.5-2.5,1.3-3.5,2.4
				c-0.3,0.3-0.6,0.7-1,1c-1.1,0.7-1.1,0.9-0.2,1.8c0.3,0.3,0.6,1.1,0.5,1.1c-0.9,0.7,0.1,1.3,0,2c-0.2,0-0.4,0.1-0.6,0.1
				c-1.6,0-3.2,0-4.7,0c-0.4,0.9-0.8,1.6-1.2,2.3c0.4,0.5,0.6,1,0.6,1.5c0,0.6-0.4,1.4-0.2,1.8c0.5,0.8,0.4,1.6,0.4,2.5
				c0,1,0.6,1,1.7,0.3c-1.2-1.6-0.1-2.8,0.8-4.1c0.8-0.1,1.6-0.2,2.4-0.2c0.3,0.5,0,1.6,1.2,1.3c0.7-1.2,0.2-2.9,1.2-3.9
				c1,0.8,1.9,1.4,2.6,1.9c-1.3,1.7-3.6,2.7-4.1,5.4c1.2,0,2.1,0,3,0s2-0.2,2.2,1.3c-1.4,0.3-2.6,0.5-4-0.3
				c-0.6,0.7-1.6,1.3-1.9,2.1c-0.5,1.2-1.9,0.9-2.5,1.9c-0.1,0.1-0.1,0.2-0.1,0.5c0.7,0.2,1.4,0.5,2.2,0.8c-0.2,0.8-0.4,1.6-0.5,2.4
				c-0.8,0.4-2.3,0-1.6,1.8c1.3-0.3,2.3,1.5,3.8,0.6c0.3-0.6,0.1-0.9-0.5-1.2c-1.3-0.7-1.2-0.8-0.5-2.2c0.5,0.3,0.9,0.7,1.8,1.3
				c-0.5-1.7-0.8-3-1.2-4.4c1.3-0.4,2.7,0.2,3.5-1c0.1-0.2,0.9,0,1.4,0c0.4,0.1,0.7,0.2,1.1,0.4c1-1.3,0.4-2.4-0.4-3.4
				c0.2-0.2,0.5-0.4,0.5-0.6c0.3-1.8,1.2-2.6,3-2.4c0.3,0,0.7-0.3,1-0.5c-0.3-0.5-0.6-1-0.9-1.5c0.8-0.5,1.5-0.9,2.3-1.4
				c-0.4-0.9-0.7-1.8-1-2.6l0,0c1,0.1,2.1,0.2,3.1,0.3c0.2-0.3,0.3-1,0.7-1.1c1.3-0.4,1-1.4,1-2.3c0.7-0.1,1.3-0.2,1.9-0.2
				c0.1-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.5-0.5,0.6-0.7c0.4-1.3,0.3-1.3,1.9-1.1c-0.2,0.7-0.2,1.4-0.6,1.9c-1,1.2-1.8,2.4-2.6,3.7
				c-0.5,0.9-1.1,1.6-1.2,2.8s-0.6,1.5-1.8,1.4c-0.3,0-0.6-0.1-1-0.2c0,0.9,0.1,1.6,0.1,2.2c0.4,0.2,0.8,0.4,1.4,0.7
				c-4.8,0-5.9,2.2-6.4,5c0.9,0.1,1.7,0.2,2-0.9c0.4-1,1.1-1.1,2-1c-1.5,1.1-2.2,2.9-3.8,4c-1.2,0.8-2.1,2.1-3.8,2
				c0.4,1.1,0.2,1.8-0.6,2c-0.3-0.5-0.2-1.4-1.2-0.8c0,0.3,0.1,0.7-0.1,1c-0.6,1.3-1.3,2.7-2,4c-1.4-0.1-1.8,1.2-2.6,1.9
				c-0.7,0.5-1.7,1.1-1.2,2.2c0.4,1,1.5,0.8,2.4,0.6c0.1,0,0.3,0,0.4,0.1c0.5,0.7,1.1,1.3,1.8,1.6c0.3,0.1,0.9-0.4,1.4-0.6
				c0.8-0.4,1.4-0.2,1.9,0.6c-0.3,0.2-0.6,0.5-1.1,0.8c0.7,0.3,1.2,0.6,1.8,0.8c0.1-1.2,0.9-1.4,1.6-1.7c0,0,0.1,0.1,0.2,0.3
				c-0.2,0.4-0.4,0.9-0.6,1.5c1.8,1.5,4,1.9,6,2.7c0.3,0.1,0.8-0.3,1.2-0.5c0.4,1.4,1.1,1.8,2.2,1.3c0.6-0.3,1-0.8,1.6-1.1
				c0.8-0.5,1.2-1.5,2.3-1.2c0.1-1.9,1.3-2.8,3-2.3c-1.6,2.1-3.1,4.1-4.7,6.1c1.9,0.7,3.8,1.5,5.7,2.2c4.7,1.7,9.4,3.4,14.2,5
				c5,1.7,10.1,3.3,15.2,4.9c1,0.3,2.1,0.3,3.3,0.4c0.4,0.3,1,0.9,1.7,1.2c2.2,0.7,4.5,1.4,6.8,1.9c1.5,0.3,2.1,1.2,2.4,2.6
				c1.8,8.6,3.6,17.3,5.5,25.9c0.7,3.3,1.4,6.6,2.2,9.8c0.3,0.1,0.5,0.2,0.7,0.2c4.5,0.8,9,1.5,13.4,2.3c5.4,0.9,10.9,1.8,16.3,2.7
				c2.4,0.4,4.9,0.9,7.3,1.3c1.1,0.2,1.8,0.7,2.1,1.9c0.5,1.9,1.2,3.7,1.5,5.7c0.3,2.1,1.5,4.1,1.8,6.3c0.3,2.7,1.2,5.2,1.8,7.8
				c0.3,1.4,0.7,2.8,1,4.2c0.3,1.3-0.4,1.8-1.6,1.4c-0.5-0.2-0.9-0.4-1.3-0.6c-1.2-0.6-2.4-1.3-3.6-2c-1.3-0.7-2.5-1.4-3.8-2.1
				c-1.2-0.6-2.5-1.3-3.7-1.9c-1.3-0.7-2.5-1.6-3.8-2s-2.3-1.3-3.4-1.9c-6-3.2-12-6.4-18.1-9.6c-1.2-0.7-2.5-1.3-4-2.1
				c0.1,1,0.2,1.6,0.3,2.2c1.3,6,2.7,12,3.8,18c0.5,2.8,1.6,5.3,3.2,7.5c0.2,0.2,0.3,0.5,0.4,0.7c10,16.8,20,33.6,30,50.3
				c0.2,0.3,0.5,0.5,0.8,0.9c0.2-0.4,0.4-0.7,0.4-0.9c0.2-3.1,0.4-6.3,0.6-9.4c0-0.6,0.4-1.1,0.4-1.7c0.1-0.9,0-1.8,0-2.8
				c0-0.4-0.2-0.7-0.1-1.1c0.8-3.4,0.2-6.8,0.3-10.2c0-0.1,0-0.2-0.1-0.2c-0.8-0.7-0.6-1.7-0.6-2.5c0-3.1,0-6.3,0-9.4
				c0-0.4,0.1-0.9,0.2-1.4c0.3,0.2,0.6,0.3,0.7,0.4c0.1,1,0.3,2,0.2,3c0,0.9,0,2.6,0,3.6c0,2,0.1,4.1,0.3,6.1
				c0.1,1.4-0.6,3.3,1.3,4.4c0.2,0.1,0.3,0.8,0.2,1.1c-0.7,2.3-0.5,4.6-0.5,6.9c0,0.4,0.1,1-0.1,1.2c-0.9,1.1-0.6,2.4-0.6,3.6
				c0.1,3.1-0.1,6.3-1.1,9.2c-0.6,1.6-0.3,2.8,0.4,4c0.9,1.4,2.3,1.3,3.2-0.2c0.3-0.5,0.7-1,1-1.6c0.1,0,0.2,0.1,0.4,0.1
				c0,0.5-0.1,0.9-0.1,1.6c0.7,0,1.2,0,1.8,0s1.2,0.1,2.2,0.1c-0.7,0.5-0.9,0.8-1.3,0.9c-0.7,0.2-1.4,0.5-2.1,0.6
				c-1.1,0.1-1.7,0.4-2,1.6c-0.3,1-0.3,1.8,0.3,2.6c0.5,0.6,0.8,1.3,1.2,1.8c0.5-0.1,0.8-0.1,1.7-0.3c-1.1,0.9-1,1.6-0.6,2.3
				c0.9,1.5,1.7,3,2.7,4.4c0.8,1.2,0.9,2.2,0.4,3.5c-0.4,1.2-0.7,2.5-1.1,3.8c-0.4,1.3-0.8,2.7-1.1,4s-0.7,2.5-1,3.8
				c-0.4,1.3-1.1,2.7-1,4c0,1.4-1,2.2-1.1,3.4c-0.1,1.1-0.5,2.2-0.8,3.4c1.9,0.7,3.6,1.3,5.3,1.8c1.1,0.4,2.2,1.1,3.4,0.1
				c0.2-0.1,0.5,0,0.8,0.1c0.1,0.5,0.2,0.9,0.3,1.5c0.9-0.1,1.7,1.2,2.6-0.1c-0.3-0.4-0.6-0.8-0.9-1.1c0.1-0.1,0.2-0.3,0.2-0.4
				c0.6,0.2,1.3,0.3,1.8,0.6c0.2,0.2,0.1,0.9,0.1,1.4c0.6,0,1.2-0.1,2.1-0.1c-0.3-0.8-0.6-1.4-0.7-2.1c-0.1-0.6,0.4-1.4,0.2-2
				c-0.3-0.6-0.2-0.9,0.2-1.4c0.2-0.3-0.3-1.1-0.1-1.4c0.7-1.1,0-1.6-0.7-2.3c-0.8-0.7-1.6-1.5-2.4-2.3c3.1-4.2,6.1-8.2,9.2-12.2
				c0.4-0.6,1.3-0.8,1.9-1.2c0.2,2.1,0.4,4,0.6,5.9c-0.1,0-0.4,0-0.7,0.1c-0.5,1.3,0.2,2.2,1.2,3.1c-0.5,0.4-0.9,0.7-1.3,1
				c0.1,0,0.2,0.1,0.4,0.1c-0.1,1.2-0.3,2.4-0.5,3.6c-0.5,0-0.9-0.1-1.4-0.1c0.4,1.3-0.5,2.8,1.2,3.6c-0.5,0.3-0.9,0.5-1.2,0.6
				c0.5,0.8,1,1.4,1.3,2.1c0.4,0.8,0.6,1.6,0.9,2.3c0.7,0.2,1.2,0.3,1.7,0.5c-0.6,0.8-1.2,1.5-1.7,2.2c-1.2-0.1,0.3-1.3-0.6-1.7
				c-1.5,0.6-0.7,1.9-0.8,2.9c0.8,0.2,1.4,0.4,2.1,0.6c-0.1,0.6-0.1,1-0.2,1.5c-0.5,0.6-0.5,0.8,0.1,1.1c-0.1-0.4-0.1-0.8-0.1-1.2
				c1,0.1,2,0.2,3.3,0.3c-0.6,0.7-0.9,1.1-1.2,1.5c0.6,0.5,1.2,0.8,1.8,0.1c0.5-0.7,1.3-1.4,1-2.3c-0.2-0.4-0.6-0.8-0.9-1.2
				c1-0.2,0.2-0.8,0.3-1.3c1.6-0.5,2.4-1.7,2.4-3.4h-0.1c1.2,0.6,0.4,1.8,1,2.9c1.5-4.9,2.3-9.6,3.2-14.4c-0.5-0.1-1-0.3-1.9-0.6
				c0.5-0.4,0.8-0.8,1-0.8c1.1,0.1,1.6-0.6,1.7-1.4c0.4-2,1.5-3.9,0.9-6c0-0.2,0-0.4,0.1-0.6c1.1-1.7,1.5-3.7,1.9-5.7
				c1.1-4.7,2.2-9.5,3.3-14.2c0.8-3.7,1.7-7.4,2.6-11.1c3.6-15.2,7.1-30.5,10.7-45.7c2.2-9.3,4.4-18.6,6.6-28
				c1.7-7.5,3.4-15,5.2-22.5c1.7-7.5,3.5-15,5.3-22.6c0.5-2.3,0.7-2.5,3-2.5c5,0,10,0,14.9,0c5,0,10.1-0.1,15.1,0
				c4.9,0.2,9.8-0.7,14.8-0.4c4.5,0.3,9,0.1,13.5,0c1.5,0,3-0.2,4.4-0.7c11.1-3.6,22.2-7.1,33.2-10.9c12.4-4.2,24.6-9.1,36.6-14.4
				c6.4-2.8,12.5-6.1,18.9-9c6.2-2.8,12.2-6.2,17.8-10c8.2-5.6,15.3-12.2,20.3-20.9c1.8-3,3.2-6.2,4.1-9.6c-0.5-0.2-0.8-0.3-1.1-0.4
				c-0.1-0.1-0.2-0.3-0.3-0.4c2.1-0.5,2.4-2.1,2.7-3.7c0.2-1.2,0.5-2.4,0.7-3.7c0.2-1.1,0.3-2.2,0.2-3.3c0-0.5-0.5-0.9-0.7-1.3
				c0.3-0.2,0.9-0.4,0.9-0.7c0.2-1.3,0.5-2.6-0.5-3.7l0,0c0-1.2,0.3-2.5,0-3.6c-0.6-2.3-1.3-4.7-2.4-6.8c-1.3-2.6-3.1-5-4.4-7.7
				c-1.8-3.7-4.8-6.4-7.1-9.6c-1.3-1.9-3.3-3.3-5-4.9c-0.5-0.5-1.1-1-1.8-1.6c-0.4,1.4-1.4,2.1-1.7,3c-0.6,1.9-2.9,2.8-3,4.9
				c0,0.2-0.5,0.3-0.8,0.4c-0.1-0.3-0.5-0.8-0.4-1c0.5-0.8,1.1-1.5,1.6-2.2c0.2-0.3,0.6-0.5,0.7-0.9c0.3-0.7,0.4-1.6,0.8-2.3
				c0.4-0.6,1.2-1,1.8-1.5c0.1-0.1,0.2-0.3,0.4-0.7c-1-0.6-1.9-1.2-2.9-1.8l0.1,0.1c-0.3-0.6-0.6-1.2-0.8-1.7
				c-0.9,0.2-1.5,0.3-2.1,0.5c-0.5,1.2-0.9,2.3,0.6,3l0,0c-1.3,0-0.9,1.8-2.3,2c0.2-1.3,0.3-2.5,0.5-3.9c-0.6,0.5-0.8,0.7-1.4,1.2
				c0.1-0.8,0.2-1.2,0.3-1.6c0.7-0.2,1.2-0.3,2.1-0.5c-0.7-0.5-1.1-0.8-1.8-1.3c0.6-0.5,1.1-0.9,1.7-1.3c-0.7-0.2-1.3-0.4-1.8-0.7
				c-0.1-0.1-0.1-0.5-0.2-0.7c-0.3,0.1-0.6,0.3-0.9,0.3s-0.6-0.2-1.4-0.4c0.7-0.4,1.1-0.6,1.4-0.8c-2.4-1.8-4.7-3.4-7.1-5.2
				c-2,2.2-4.7,3.1-7.3,4c-0.1-0.4-0.3-0.8-0.5-1.3c-0.6,0.2-1.1,0.5-1.7,0.7c-0.4,0-0.7-0.1-1-0.1c0.6-1.1,1.1-2,1.8-3.1
				c0.8,0.4,1.6,0.8,2,1c2.1-0.5,4-1,5.8-1.5c0.1-0.2,0.1-0.3,0.2-0.5c-0.2-0.1-0.5-0.2-0.7-0.4c-2.1-1.3-4.1-2.7-6.4-3.5
				c-0.4-0.1-0.7-0.5-1.1-0.8c-1.1-0.9-2-2-3.8-2.1c0.2,1,0.4,1.8,0.6,2.6v-0.1c-0.3,0.2-0.7,0.4-1.3,0.7c0-1.1,0-2,0-2.9
				c0-1-0.4-1.8-1.3-1.8c-0.9,0-1.2-0.7-1.8-1c-0.4-0.2-0.8-0.4-1.2-0.7c-0.2,0.6-0.3,1.1-0.4,1.3c-0.5-0.5-1.3-1-1.5-1.6
				c-0.5-1.9-2.1-1.9-3.5-2.4c-0.3-0.1-0.8,0-0.9-0.2c-0.8-1.7-2.1-1.3-3.2-0.9c-1-0.9-1.8-1.7-2.7-2.6c-0.5,0.7-0.9,1.1-1.2,1.7
				c0.8,0.8,1.5,1.6,2.4,2.4c-0.6,0.2-1,0.4-1.4,0.5c0.8,0.7,1.5,1.4,2.2,1.9c1.7,1.2,3.3,3,5.8,1.7c-0.2-0.5-0.4-1-0.6-1.4l0,0
				c0.7,0.2,1.3,0.4,2.3,0.7c-1.1,1.2-1.9,2-2.8,3c0.7,0.6,1.5,1.3,2.1,1.9c1.1,0,1.9,0,2.8,0c0.2-0.4,0.4-0.9,0.8-1.6
				c0.6,0.6,1,1.1,1.5,1.7c-1.2,0.7-2.2,1.2-3.5,1.9c1,0.9,1.8,1.6,2.6,2.3c0.4,0.4,0.9,1,1.4,1c0.4,0.1,0.9-0.6,1.5-1.1
				C417.86,33.13,417.96,33.73,418.16,34.33L418.16,34.33z M43.56,127.43c-1.1,0.7-1.8,1.9-3.3,2.1c-0.9,0.1-1.3,1.3-0.9,1.9
				c0.5,0.9,3.2,1.9,3.8,1.2c0.8-0.9,1.1-0.1,1.7,0c0.8-1.7,1.3-2.1,2.7-1.8c-1.4,1.3-1.4,2.8-0.5,4.4c0.8,0,1.7,0,2.5,0
				c0-0.3,0-0.6,0.1-0.8c0.3-0.5,0.6-0.9,1.2-1.8c-1.3,0.5-2.1,0.9-3,1.2c-0.5-1.7,1.1-1.6,1.5-2.4c-0.5-0.4-0.9-0.7-1.6-1.3
				c0.8-0.8,1.5-1.6,2.6-2.6c0.1-0.2,0.2-0.9,0.4-1.5c-1.5,0.2-2.9,0.5-4.3,0.7c-0.6,0.7,0.3,1.9-0.9,2.8c-0.1-0.7-0.2-1.1-0.2-1.6
				C44.66,127.83,44.16,127.63,43.56,127.43z M396.36,13.73c-2.1-1-4-1.9-5.9-2.7c-1.9-0.8-3.8-1.6-5.7-2.4c-1.9-0.7-3.7-1.7-5.8-2
				c1.5,1,3,1.9,4.6,2.8c1.5,0.8,3,1.4,4.5,2.3c1.5,0.8,2.9,1.8,4.4,2.8c0.5-0.4,0.9-0.7,1.5-1.3c-0.2,0.9-0.3,1.4-0.4,2
				c1,0.7,2,1.3,3,2c0.9,0.6,1.7,1.5,3.1,1.6c-0.6-1.3-1.8-2-1.6-3.5c0.8,0.1,1.5,0.2,2.2,0.3c0-0.1,0-0.2,0.1-0.3
				c-1.1-0.3-2.2-0.6-3.2-0.9c-0.4,0.3-0.8,0.6-1.5,1.2C395.96,14.73,396.06,14.33,396.36,13.73z M22.36,119.23c2-1.8,2-1.8,2-4
				c-0.1-2.1-1.8-3.3-3.7-2.5c-0.5,0.2-0.8,0.5-1.4,0.1c-0.4-0.3-1.1-0.1-1.7,0s-1.1,0.4-1.9,0.8c1.4,1.2,2.6,2.3,3.6,3.2
				c0.9-0.5,1.6-0.9,2.4-1.3c0.2-0.5,0.5-1,0.9-1.9c0.1,0.9,0.1,1.4,0.2,1.9c0.2,0.5,0.5,1,0.7,1.5c-0.9,0.4-1.6,0.8-2.5,1.2
				C21.56,118.83,21.86,119.03,22.36,119.23z M73.76,144.73c-1.2-1.1-1.2-1.1-1.5-3.4c-2-0.8-3.5,0.3-4.9,1.5c0,0.9-0.1,1.7-0.1,2.7
				c0.7,0.2,1.4,0.4,2.1,0.7c0.2-0.6,0.3-1,0.5-1.5c0.7,1.1,1.3,2,2.7,2.5C71.96,145.73,72.46,145.03,73.76,144.73z M18.86,109.93
				c-1.4-1.7-3.4-1.8-4.8-2.9c-0.4,0.4-0.8,0.7-1.1,1c-0.4,0.3-0.8,0.7-1.2,1c0.6,0.7,1.2,1.1,1.5,1.6c1,1.6,2.9,2,4.3,0.8
				C17.96,111.03,18.36,110.53,18.86,109.93z M30.66,122.23c-0.1-2-0.3-2.2-2.2-2.2c0.8-2.1-1.4-1.6-1.9-2.6
				c-0.3,1.6-0.6,3.1-0.9,4.3C27.36,121.93,28.96,122.03,30.66,122.23z M69.86,136.73c-0.9-1.4-0.9-1.4-2.1-0.6
				c0.6,0.5,1.2,1,1.7,1.5c0.9,0.9,2,0.8,2.8-0.1c0.3-0.3,0.8-0.5,1.2-0.8c0-0.6,0-1.3,0-2c-0.9,0.2-1.8,0.3-2.5,0.5
				C70.66,135.73,70.36,136.13,69.86,136.73z M53.96,129.43c-0.9-1.1,0.9-1.2,0.7-2c-0.7-0.9-1.5-1-2.9-0.5c0.8,0.3,1.2,0.4,1.6,0.5
				c-0.2,1-0.2,2-0.5,2.8c-0.3,1-0.8,1.8,0.5,3c0.1-2.3,1.8-2.8,3.3-3.6C55.66,127.93,54.76,129.63,53.96,129.43z M32.66,123.33
				c-0.7,1.5,1,0.9,1,1.9c-0.5,0.4-1.1,0.9-1.8,1.4c0.6,0.2,1,0.4,1.3,0.5c0.7-0.8,1.3-1.4,2-2.2
				C34.36,124.53,33.56,123.93,32.66,123.33z M38.66,122.83c0.1,0.2,0.1,0.4,0.2,0.5c1.1-0.4,2.2,0.4,3.7-0.9
				c-1.6,0.3-1.7-0.8-2.3-1.3C39.66,121.73,39.16,122.33,38.66,122.83z M64.66,124.73c-1.5,1.4-0.5,2.7-0.6,4c1.6-1,1.6-1,1-2.6
				C64.86,125.73,64.86,125.33,64.66,124.73z M9.56,104.93c0,1.3,0.4,2.2,1.4,2.4c0.5,0.1,1.4,0.1,1.5-1
				C11.56,105.93,10.66,105.43,9.56,104.93z M66.36,141.63c-1.4,0.7-2.3,1.1-3.3,1.6c0,0.1,0.1,0.2,0.1,0.4c0.8,0.2,1.5,0.4,2.4,0.6
				C65.86,143.43,66.06,142.73,66.36,141.63z M75.86,131.63c-1.8,1.4-1.8,1.4-1.4,2.8c0.7-0.4,1.3-0.9,2.1-1.4
				C76.26,132.63,76.06,132.13,75.86,131.63z M75.46,131.13c0.5-0.3,1-0.6,1.5-0.9c-0.5-1.4-0.5-1.4-1.5-1.9
				C75.46,129.23,75.46,130.13,75.46,131.13z M51.46,126.03c0.7,0.5,1.1,0.3,1.7-0.3c0.5-0.6,0.6-1.1,0.2-1.8
				C52.66,124.73,52.06,125.43,51.46,126.03z M66.06,122.13c-0.3-0.8-0.5-1.5-0.8-2.3c-0.5,0.3-0.9,0.5-1.3,0.7
				C64.36,121.53,64.76,122.23,66.06,122.13z M27.76,98.43c-0.1,0-0.2,0-0.3,0c-0.4,0.6-0.8,1.3-1.2,2c0.6,0,1.1,0,1.6,0
				C27.76,99.63,27.76,99.03,27.76,98.43z M56.46,122.03c-1-0.9-1.5,0.5-2.2,0.3c0.3,0.7,0.6,1.2,0.7,1.6
				C55.46,123.23,55.96,122.63,56.46,122.03z M28.36,103.43c-0.6-0.1-1.2-0.3-2.1-0.5c0.8,1,1.3,1.7,1.8,2.4
				c0.1-0.1,0.2-0.1,0.3-0.2C28.36,104.63,28.36,104.13,28.36,103.43z M63.96,138.23c1.2,2.1,1.2,2.1,2.2,0.7
				C65.46,138.63,64.86,138.53,63.96,138.23z M51.96,118.03c0-0.2,0-0.3-0.1-0.5c-0.5,0-1-0.2-1.3,0c-0.2,0.1-0.2,0.8-0.4,1.4
				C50.96,118.43,51.46,118.23,51.96,118.03z M60.76,108.93c-1.3,1.2-1.3,1.2-0.6,3C60.36,110.83,60.56,109.93,60.76,108.93z
				 M219.96,327.63c-0.4,1.3-0.6,2.6,0.3,3.8C220.16,330.13,220.06,328.93,219.96,327.63z M58.66,121.03c0-0.8-0.1-1.4-0.1-2
				C57.16,119.93,57.16,119.93,58.66,121.03z M53.66,134.83c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0.4-0.2,0.9-0.3,1.3l0.5,0.2
				C53.36,135.73,53.56,135.23,53.66,134.83z M63.96,134.13c0.3,0.8,0.7,1.2,1.4,0.8c0.1-0.1,0.2-0.4,0.2-0.5
				C65.26,133.53,64.66,133.83,63.96,134.13z M23.16,110.93c0.2-0.6,0.5-1.3-0.4-1.5c-0.1,0-0.5,0.1-0.5,0.2
				C21.96,110.43,22.46,110.73,23.16,110.93z M64.66,129.43c1.8,0.1,1.8,0.1,1.5-1.2C65.76,128.53,65.36,128.83,64.66,129.43z
				 M63.86,100.63c0.1-0.1,0.3-0.3,0.4-0.4c-0.3-0.2-0.5-0.4-0.8-0.6c-0.1,0.1-0.2,0.3-0.2,0.3
				C63.46,100.13,63.76,100.33,63.86,100.63z M72.36,116.43c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0.7-0.3,1.3-0.5,2.3
				C72.76,117.83,72.26,117.03,72.36,116.43z M19.26,111.83c0.5-0.1,1.6,1,1.7-0.7C20.26,111.43,19.76,111.63,19.26,111.83z
				 M66.56,134.13c0.2,0.1,0.3,0.2,0.5,0.3c0.2-0.3,0.4-0.6,0.6-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
				C67.06,133.63,66.76,133.83,66.56,134.13z M52.66,119.23c-1,0.4-1,0.9-0.3,1.4c0.1,0.1,0.2,0,0.5,0.1
				C52.76,120.23,52.76,119.83,52.66,119.23z M69.86,93.93c0.2-0.5,0.4-0.8,0.7-1.3C69.66,93.03,69.66,93.03,69.86,93.93z
				 M26.06,93.93c0.4,0.2,0.6,0.3,0.8,0.3c0.1,0,0.3-0.3,0.3-0.5c-0.1-0.2-0.3-0.3-0.5-0.5C26.46,93.43,26.36,93.63,26.06,93.93z
				 M57.56,109.53c0,0.2,0,0.3,0,0.5c0.3,0,0.6,0,0.8-0.1c0-0.2,0-0.3,0-0.5C58.16,109.53,57.86,109.53,57.56,109.53z"/>
			<path class="st0-bull-white" d="M548.36,183.63c18.3,0,36.4,0,54.7,0c-0.2,1.1-0.4,2.1-0.6,3.2c0.3,0.2,0.6,0.3,0.9,0.4
				c0.9,0.3,1.2,0.9,1,1.8c-0.1,0.6-0.3,1.2-0.4,1.8c-5.1,20.3-10.1,40.5-15.2,60.8c-2.6,10.5-5.2,20.9-7.8,31.4
				c-1.4,5.8-2.9,11.5-4.3,17.3c-2.3,9.2-4.7,18.5-7,27.7c-2,7.8-3.9,15.5-5.9,23.3c-0.5,1.9-0.8,3.9-1.3,5.9
				c-0.7,2.7-0.8,2.8-3.7,2.8c-5,0-10,0-14.9,0c-18.7,0-37.5,0-56.2,0c-15.4,0-30.8,0-46.2,0c-2.5,0-4.1-0.6-5.5-2.8
				c-1.8-2.8-4-5.3-6-7.9c-2.2-2.9-4.3-5.9-6.5-8.9c-1.6-2.2-3.4-4.2-5-6.4c-0.7-0.9-1.1-2-1.6-3.1c-0.2-0.5-0.4-1.1-0.8-1.4
				c-1.1-1.1-0.9-2.2-0.5-3.5c1.5-5.7,2.9-11.4,4.3-17.1c1.8-7.3,3.7-14.7,5.5-22c1.6-6.5,3.3-13,4.9-19.5c0.6-2.4,1.1-4.8,1.9-7.2
				c0.3-0.9,1-1.9,1.7-2.5c1.7-1.6,3.6-3,5.4-4.4c5.8-4.7,11.7-9.4,17.5-14.2c1.6-1.3,3.1-2.5,4.7-3.8c0.6-0.5,1.1-1,2-1.8
				c-0.9-0.1-1.4-0.2-1.8-0.2c-5.7,0-11.3,0-17,0c-0.4,0-0.8,0-1.2,0c-1.5-0.1-1.8-0.5-1.5-2c0.1-0.4,0.2-0.8,0.3-1.4
				c-0.8-0.2-1.5-0.4-2.4-0.6c3.8-15.2,7.6-30.4,11.5-45.8c18.2,0,36.4,0,54.7,0c-0.2,1.1-0.3,2.1-0.5,3c0.7,0.5,1.5,0.8,1.8,1.4
				c0.2,0.6,0,1.5-0.2,2.2c-1.4,5.7-2.9,11.4-4.4,17.1c-1.7,6.6-3.3,13.3-4.9,19.9c-5.8,23.4-11.7,46.8-17.6,70.2
				c-0.8,3.3-1.7,6.6-2.5,9.9c-0.2,0.7-0.3,1.5-0.4,2c0.2,0.3,0.2,0.3,0.3,0.4c0,0,0.1,0.1,0.2,0.1c0.5,0.1,1,0.2,1.4,0.2
				c12.4,0,24.9,0,37.5,0C527.16,267.93,537.66,225.83,548.36,183.63z M417.36,329.43L417.36,329.43c1.6,1.9,3.2,4,4.9,5.9
				c1.1,1.3,1.6,1.9,2.2,3.6c0.1,0.2,0.1,0.4,0.3,0.6c1.6,2,3.1,4,4.7,6c0.3,0.4,0.8,0.7,0.9,1.1c0.6,1.6,1.5,2.8,3.5,2.9
				c-0.4,0.2-0.7,0.4-1,0.5c1.2,1.7,2.3,3.3,3.5,4.9c0.2,0.3,0.7,0.5,1.1,0.5c0.6,0.1,1.2,0,1.9,0c0.2-0.5,0.3-0.9,0.5-1.4
				c1-0.2,2.1-0.4,2.7,0.6c0.5,0.8,1,0.8,1.7,0.8c0.9,0,1.8-0.1,2.7,0c1,0.1,1.3-0.4,1.6-1.4c-0.1,1.3,0.6,1.5,1.4,1.4
				c0.6,0,1.1,0,1.7,0c4.1-0.1,8.3-0.2,12.4-0.2c4.5,0,9.1,0.1,13.6,0.2c0.9,0,1.6-0.3,2-1.1c0.2-0.3,0.6-0.6,1.1-1.1
				c0.2,0.9,0.4,1.5,0.6,2.3c1.6-0.6,3.7,1,5-1.3c0,1.1,0.7,1.3,1.6,1.2c3.6,0,7.2,0,10.8,0c18.3,0,36.6,0,54.9,0c0.8,0,1.5,0,2.3,0
				c0.5-1.1,0.9-2.1,1.3-3.1c0.6,0,1.2,0.1,1.7,0.1c0.2,0.6,0.4,1.1,0.6,1.6c0.1,0,0.3,0,0.4,0c0.3-1,0.6-2,0.9-3
				c-0.6-0.1-1-0.1-1.4-0.2c0.1-0.4,0.3-0.8,0.4-1.2c0.2-1,1-1.6,1.5-2.4c1.2-1.8,1.1-4.1,2-6c0.5-1.2,0.8-2.5-0.2-3.6v0.1
				c0.7,0.4,1-0.1,1.2-0.7c0.3-1,0.6-2.1,0.8-3.1c-0.6-0.2-1-0.3-1.4-0.4v0.1c-0.2-0.6-1.7-0.3-1.1-1.5c3-0.4,2.8-0.5,3.7-3.2
				c0.7-2.1,1-4.2,1.5-6.3c-0.7-0.9-1.4-0.7-2.1,0c0.2,0.4,0.4,0.8,0.5,1.1c-0.7,0.7-1.2,1.3-1.9,1.7c-0.6,0.4-1.3,1.3-2.4,0.2
				c0.6-0.4,1.2-0.7,1.8-1.1c0.3-0.8-0.4-2.2,1.2-2.4c-0.1-0.4-0.2-0.8-0.3-1.2c0.4-0.6,0.9-1.3,1.3-1.8c0.8,0.3,1.5,0.7,2.2,0.8
				s0.9-0.5,0.6-1.1l0,0c1.5-3.2,2-6.6,2.6-10.2c-0.8,0-1.3,0.1-1.8,0c-0.3-0.1-0.9-0.3-0.9-0.5c0.1-0.8-0.2-1.3-0.9-1.6
				c0.2-0.3,0.3-0.8,0.5-0.9c0.9-0.2,1.6,0.5,1.5,1.4c0,0.3-0.2,0.6-0.3,1.3c0.6-0.4,0.9-0.7,1.2-0.7c1.2-0.1,1.4-1,1.1-1.8
				c-0.4-0.8-0.8-1.2-0.2-2.2c0.2-0.4-0.7-1.4,0.3-2.1c0.1-0.1,0-0.6-0.1-0.7c-0.5-0.4,0-0.6,0-1c0.3-1.4,1.6-1.3,2.6-1.7
				c0.4-1.7,0.8-3.3,1.2-5c-0.9-0.2-1.5-0.3-2.1-0.3c1-1.6,1.9-3.2,3-4.8c-0.8-0.4-1.9-0.7-1.1-1.9c0.5,0.3,1,0.6,1.7,1
				c0.2-0.7,0.5-1.2,0.5-1.7c-0.2-1.7,0.5-3.2,1.2-4.7c0.3-0.6,0.3-1.2,0.5-1.8c0.4-1.1,0.7-2.2-0.3-3c0.6-0.7,1.2-1.3,1.4-2
				s0-1.5,0-2.3c0.8-0.5,1.5-2.3,2-5.7c-0.3-0.4-1.4-0.1-1-1.2c0.4,0.1,0.9,0.1,1.4,0.2c0.6-2.8,1.5-5.3,1.8-8c-1.1-0.4-2,0.4-3,0
				c-0.3-0.1-1,0.7-1.4,1.1c-0.5-0.1-0.9-0.2-1.3-0.3l0.1,0.1c0-1.2,1.1-1.4,1.7-1.4c1.6,0.1,2.8-0.8,4.4-1c0.2-1.1,0.3-2.1,0.4-3.1
				c0.1-0.4,0.2-0.9,0.4-1.3c0.2-0.6,0.7-1.2,0.8-1.8c0.9-3.3,1.7-6.6,2.5-9.8c0.8-3.3,1.6-6.6,2.5-9.8c0.9-3.2,1.6-6.5,2.4-9.7
				c2.7-10.4,5.3-20.9,7.9-31.3c0.3-1.4,0.7-2.8,1-4.4c-6.1,0-12,0-17.8,0c-5.9,0-11.8,0.1-17.6,0.1c-5.9,0-11.7,0-17.7,0
				c-0.2,0.7-0.4,1.3-0.6,2c-1.8,7.1-3.6,14.2-5.3,21.3c-6.3,25.1-12.6,50.2-18.9,75.3c-1.9,7.6-3.8,15.1-5.7,22.7
				c-0.2,0.9-0.2,1.4,1,1.7c-0.7,0.2-1,0.3-1.5,0.4c-0.1,0.4-0.2,0.9-0.4,1.4c-0.5,1.4-0.6,1.6-2.2,1.6c-0.5,0-1,0-1.5,0
				c-12,0-24.1,0-36.1,0c-0.7,0-1.5,0-2.2,0c-0.2,0.8-0.3,1.5-0.4,2.2c-0.6-0.1-1-0.2-1.6-0.3c0.4-0.7,0.6-1.2,0.9-1.8
				c-1.3-0.6-0.8-1.6-0.6-2.5c0.4-1.7,0.9-3.5,1.3-5.2c0.5-1.8,0.9-3.6,1.4-5.4c0.4-1.6,0.8-3.3,1.2-5c4.1-16.2,8.2-32.4,12.2-48.6
				c1.2-5,2.3-10.1,3.5-15.1c0.6-2.3,1.2-4.6,1.9-6.9c0.6-2.3,1.3-4.7,1.9-7c0.4-1.5,0.8-3,0.7-4.5c-0.2-1.7,0.5-2.8,1.2-3.9
				c-0.2-0.6-0.6-1.2-0.5-1.5c0.5-1-0.1-1.5-0.6-2c0.3-0.6,0.6-1.1,0.9-1.8c0.4,0.4,0.8,0.8,1.3,1.3c0.4-1.3,0.7-2.6,1-3.8
				s0.6-2.4,0.9-3.6c0.3-1.3,0.6-2.5,1-3.8c0.3-1.1,0.2-2.2,0.7-3.2c0.6-1.1,0.7-2.5,1-4c-2.7,0-5.3,0-7.8,0s-5,0-7.4,0
				c-2.6,0-5.1,0-7.7,0c-2.6,0-5.1,0-7.7,0c-2.6,0-5.1,0-7.7,0c-2.5,0-5,0-7.5,0s-4.9,0.1-7.4,0.1c-1,3.9-2,7.6-2.8,11.4
				c-0.8,3.6-2.4,7-2.3,10.8c0,0.2-0.4,0.4-0.5,0.6c-0.6,1.7-1.3,3.3-1.1,5.1c0.6,0.2,1.1,0.3,1.6,0.4c0,0.7-0.1,1.3-0.1,2
				c-0.1,0-0.2,0-0.4,0c-0.1-0.5-0.3-1.1-0.4-1.5c-0.8-0.2-1.4-0.1-1.5,0.7c-0.3,1.5-0.6,3-0.9,4.4l0.1-0.1
				c-0.6,1.9-1.2,3.9-1.9,5.9c0.6,0.2,1.3,0.5,1.4,0.4c0.8-1,1.4-0.1,2.1,0.1c0.7-0.6,1.3-1.2,2.1-1.9c-0.2,1.6-0.4,2.9-0.6,4.3
				c0,0.1,0,0.4,0,0.4c-0.3,0.2-0.6,0.3-1.3,0.7c0.2-0.8,0.5-1.2,0.4-1.4c-0.2-0.4-0.7-1.2-0.8-1.1c-0.8,0.4-1.7,0.2-2.4,0.4
				c-1.3,0.3-1.8,1.5-1.8,3.1c1.3-0.5,2.7,0.7,3.5-1.2c1.4,1,3,1.2,4.7,0.9c0.9-0.2,2-0.6,2.8-0.3c2.4,0.8,4.8,0.3,7.2,0.4
				c1.4,0.1,2.8-0.1,4.2-0.2c0.6-2.5-0.4-5,0.7-7.3c0-0.1,0-0.3-0.1-0.4c-1-1.6,0.4-3,0.4-4.5c0-0.2,0.3-0.3,0.5-0.5
				c-1,4.3-0.2,8.4-0.3,12.8c1.3,0,2.3-0.1,3.3,0c1.1,0.1,1.4,0.9,0.7,1.7c-0.6,0.7-1.3,1.2-2,1.8c-1.2,1-1.9,2.2-1.4,3.4
				c0.3,0.7,1,1.2,1.3,1.7c0,1.5,0,2.7,0,4.1c-1,1.1-1.1,1.2-0.7,2.9c0,0.2,0.2,0.4,0.2,0.5c-1.1,2.5-0.1,5.2-0.8,7.9
				c-0.6,2.4,0.1,5.2-1.2,7.6c-0.2,0.3,0,0.7-0.1,1c0,0.5,0,1-0.2,1.4c-0.5,1.5-0.5,3.1-0.1,4.6l0,0c0,0.2,0.2,0.5,0.1,0.6
				c-1.4,1.1-1.1,2.6-1,3.9c0.6,0.6,1.3,1,1.5,1.6c0.3,0.7,0,1.5-1,1.9c-0.8-0.7-1.7-1.4-2.5-2c-0.3,0.2-0.4,0.2-0.4,0.2
				c-0.2,0.5-0.3,1-0.4,1.3c-0.8-0.1-1.6-0.2-2.4-0.3c-1.4-0.1-1.6-0.3-1.6-2c0.5-0.2,0.9-0.5,1.5-0.8c0.4,0.5,0.9,1,1.4,1.5
				c0.7-0.3,1.3-0.6,2.2-1c-0.4-1.1-0.7-2.1-1.1-3.2c-2.1,0.3-4.1-0.5-6,0.7c-0.5,0.3-1.1-0.1-0.9-0.9c0.8-0.4,1.6-0.9,2.5-1.3
				c0.4-0.1,1.1-0.1,1.4,0.1c0.9,0.7,1.5,0.4,2.4-0.1c1.4-0.8,1.8-2.2,2-3.5c0.2-1,0.2-2,0.8-2.9c0.8-1.1,0.1-2.4,0.6-3.4
				c0.9-1.8,0.5-3.7,0.7-5.5s0.3-3.6,0.5-5.4c0.1-1.7,0.3-3.3,0.3-5c0-1.8-0.2-3.7-0.4-5.5c0-0.6-0.1-1.3-0.1-2
				c-0.3,0.1-0.5,0.1-0.6,0.1c-6.6,5.3-13.1,10.6-19.7,15.9c-0.5,0.4-0.9,0.9-1.2,1.4c-0.2,0.2-0.4,0.8-0.3,0.9
				c0.8,0.8-0.2,1.3-0.3,1.9h0.1c-0.5-0.4-1-0.9-1.5-1.4c-1.1,0.8-2,1.7-1.4,3.4c0.4,1-0.1,1.4-1.4,1.8c-0.2-0.6-0.5-1.2-0.8-2.1
				c-1.1,0.8-2.1,1.3-2.7,2.1c-0.5,0.6-0.1,1.9-1.1,2.3c-0.8,2.5-0.7,5.1-0.5,7.7c0,0.3,0.3,0.6,0.4,0.9c0.7,1.4,1.2,2.7,0.3,4.2
				c-0.7-0.2-1.3-0.3-2.1-0.5c0.5-0.7,0.8-1.2,1.2-1.7c-0.3-0.4-0.8-0.8-0.9-1.3c-0.1-0.4,0.1-0.9,0.2-1.3c-0.5,0.3-0.8,0.7-0.9,1.1
				c-0.3,1-0.5,2-0.8,3c-0.7,2.7-1.5,5.3-1.9,8c-0.4,2.8-1.5,5.3-2.1,8c-0.1,0.5-0.4,1.3-0.2,1.6c0.4,0.5-0.1,0.5-0.2,0.7
				c-0.6,1.6-1.6,3.2-0.4,5c-0.4,0.1-0.8,0.2-1.2,0.3c-0.2,1-0.3,2.1-0.6,3.2c-0.2,1.1-0.5,2.2-0.8,3.3c0.8-0.1,1.5-0.2,2.4-0.4
				c-0.9,0.8-1.7,1.2-2.1,1.9c-0.8,1.3-1.7,2.8-1.8,4.2c-0.1,1.7-1.5,2.9-1.2,4.5c0,0.2,0.1,0.4,0.2,0.7c0.4-0.3,0.7-0.6,1.1-0.6
				c0.3-0.1,0.6,0.1,0.9,0.2c0.1,0.1,0,0.5-0.1,0.7s-0.4,0.5-0.6,0.6c-1.1,0.2-1.2,1.1-1.3,1.9c-0.4-0.1-0.7-0.1-0.9-0.2
				c-1.2,2-0.1,3.6,0.6,5.5c-0.8,0.3-1.6,0.6-2.3,0.9c-0.3,1.1-0.5,2.1-0.8,3.2C415.86,327.73,416.16,328.73,417.36,329.43z
				 M557.96,355.23c0.7,0.2,1.2,0.5,1.7-0.5c-0.5,0-0.9-0.1-1.2-0.1C558.26,354.63,558.16,354.93,557.96,355.23z"/>
			<path class="st0-bull-white" d="M292.56,233.43c-2.2,0-4.3-0.1-6.4-0.1c-4.4,0-8.7,0.1-13.1,0.1c-0.3,0-0.6,0-0.8,0
				c-1.4-0.2-1.7-0.5-1.5-2.1c0-0.3,0.1-0.7,0.2-1.3c-0.7-0.2-1.5-0.5-2.3-0.7c3.9-15.3,7.9-30.5,11.8-45.8c50.7,0,101.1,0,151.8,0
				c-0.2,1.1-0.3,2.2-0.5,3.2c2.1,1.2,2.2,1.2,1.6,3.5c-4.8,19.2-9.6,38.3-14.4,57.5c-0.4,1.7-1.3,2.9-2.8,3.8
				c-4.6,2.7-9.1,5.6-13.6,8.4c-4.9,3-9.9,6.1-14.8,9.1c-0.4,0.2-0.7,0.6-1.2,1c0.4,0.6,0.8,1.2,1.2,1.7c2.5,3.1,5,6.1,7.6,9.2
				c2.9,3.5,5.8,7,8.7,10.5c0.6,0.7,1.2,1.3,1,2.4c-0.1,0.4,0.3,0.9,0.6,1.3c1,1.1,0.9,2.4,0.6,3.7c-1.1,4.4-2.2,8.7-3.3,13.1
				c-3.7,14.9-7.4,29.8-11.1,44.7c-0.8,3.1-0.8,3.2-4,3.2c-12.1,0-24.2,0-36.3,0c-6.7,0-13.4,0-20.1,0c-19,0-38,0-57,0
				c-4.2,0-8.4-0.1-12.6-0.1c-1.5,0-2.7-0.4-3.5-1.8c-1.7-2.7-3.4-5.4-5-8.1c-2.1-3.4-4.2-6.9-6.1-10.3c-1.3-2.2-2.4-4.5-3.5-6.8
				c-0.2-0.4-0.1-1.1,0-1.6c1.2-5.2,2.4-10.5,3.6-15.7c2.1-8.6,4.3-17.3,6.5-25.9c2.4-9.6,4.8-19.3,7.2-28.9
				c0.3-1.3,0.9-2.3,2.1-3.2c4-3.1,8-6.4,12-9.6c5.8-4.7,11.7-9.4,17.5-14C292.36,234.03,292.36,233.83,292.56,233.43z
				 M256.86,346.93c0.4-0.4,0.8-0.9,1.2-1.3c1.1,0.1,1.3,0.6,1.1,1.7c-0.3,1.2-0.2,2.5-0.4,3.8c0.1,0.1,0.2,0.1,0.4,0.2
				c0.8-1,1.6-2,2.9-1.2c-0.5,0.6-1,1.2-1.7,1.9c0.8,0.4,1.3,0.6,1.7,0.9c0.3,0.2-0.7,0.8,0.2,1.1c0.2,0.1,0.3,0.7,0.5,1.3
				c2.7,0,5.5,0,8.3,0c3.2,0,6.3-0.1,9.5-0.1c3,0,5.9,0,8.9,0.1c0.9,0,1.5-0.2,1.7-0.8c0.6,0.3,1.1,0.7,1.5,0.7
				c2.8,0.1,5.6,0.1,8.5,0c5.7-0.1,11.3-0.2,17-0.2c3.5,0,7,0.2,10.5,0.2c7.6,0,15.3,0,22.9,0c10.7,0,21.4,0,32.1,0
				c0.7,0,1.5,0,2.4,0c-0.3-0.9-1-1.6-0.2-2.4c0.5,1.2,1.1,2.3,2.6,2.1c0.1-0.3,0.2-0.6,0.2-0.9c3.2-12.7,6.4-25.4,9.5-38.2
				c1-3.9,1.7-7.8,2.7-11.7c0.8-3.3,1.8-6.6,2.7-10c0.2-0.8,0.2-1.5-0.4-2.3c-2.7-3.2-5.3-6.6-8.4-9.5c-0.4-0.4-0.5-1.2-0.9-1.7
				c-0.9-0.9-2-1.7-3-2.4c-1.5,1.1-1.5,1.1-2.3,0.3c0.8-0.4,1.6-0.8,2.6-1.2c-2.9-3.5-5.7-6.9-8.5-10.3c-0.1-0.9,0.6-1.3,1.4-1.8
				c3.4-2.1,6.8-4.2,10.2-6.3c0.6-0.4,1.1-0.8,1.7-1.2c1-0.7,1.5-1.6,0.8-2.8c-0.2-0.3-0.1-0.7-0.1-1c0.1-0.9,0.3-1.7,0.4-2.6
				c0.4-4.5-0.5-8.9-0.6-13.4c0-1,0.3-2-0.5-3c-0.2-0.2,0-0.8,0-1.2c0.1-0.8,0.4-1.6,0.4-2.3c0.1-1,0-2,0-3c0.2,0,0.3,0,0.5,0
				c0.7,1.2,1,2.4,0.6,3.8c-0.3,0.9-0.1,2.4,0,3.2c0.3,2,0.5,4,0.7,6c0,0.1,0,0.1,0,0.2c-1.1,1.6,0.6,3,0.2,4.5
				c0,0.1,0.1,0.3,0.2,0.3c1.4,0.4,0.9,1.4,0.7,2.4c-0.4,2.2-0.5,4.5-0.2,6.9c0.4-0.2,0.7-0.3,0.9-0.4c4.8-3,9.6-6,14.5-8.9
				c0.9-0.5,1.3-1.2,1.6-2.3c3.5-14.3,7-28.7,10.6-43c1.3-5.2,2.7-10.4,4-15.6c0.1-0.5,0.2-0.9,0.3-1.6c-0.8,0-1.5,0-2.2,0
				c-1.6,0.1-3.3,0.3-4.9,0.3c-2.3,0-4.6-0.3-6.9-0.3c-9.2,0-18.4,0-27.6,0c-0.7,0-1.7-0.4-2.2,0.6c0,0.1-0.4,0-0.5,0
				c-0.6-1-1.6-0.6-2.4-0.6c-30,0-60,0-90,0c-1.4,0-2.8,0-4.3,0c-1.7,0-3.4,0-5.2,0c0.2,1.3,0.4,2.2,0.5,3.2
				c-1.5-0.7-1.9-1.7-1.5-3.1c-1,0-1.9,0-2.8,0c-0.4,1.1-0.8,2.2-1,3.2c-0.6,2.9-1.6,5.7-2,8.6c-0.4,2.8-1.6,5.4-2.3,8.1
				c-0.8,2.9-1.5,5.7-2.2,8.6c-0.2,0.8-0.7,1.8,0.7,2.1c0.1,0,0.3,0.5,0.2,0.7s-0.4,0.4-0.6,0.4c-0.4,0-0.7-0.2-1.1-0.3
				c-1,4.3-2.3,8.4-3.2,12.9c2,0,3.7,0,5.5,0c-0.1-0.4-0.1-0.8-0.2-1.2c0.7,0,1.4,0,2,0c0.2,0.5,0.4,0.9,0.6,1.4c1.6,0,3,0,4.4,0
				c0.8,0,1.5-0.1,1.1-1.2c-0.5,0.1-1,0.1-1.5,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.3,0.5-0.6,0.7-0.9s0.4-0.6,0.6-0.9
				c0.8-0.1,1.4,0,1.8,0.9c0.2,0.7,0.7,1.3,1.1,1.9c2,0,4.1,0,6.1,0c0-0.7,0-1.2,0-1.8c-1-0.2-1.8-0.4-2.7-0.6
				c1.2-1.1,2.2,0.2,3.3,0.1c0.1,0.9,0.1,1.5,0.2,2.5c0.9,0,1.7,0,2.5,0c0.7,0,1.4-0.2,2,0c0.4,0.1,1.1,0.6,1.1,1
				c0.1,1.1-0.1,2.3-0.2,3.4c-1.3-0.1-0.7-1-0.9-1.9c-1.1,1.5-3.5,1.7-2.9,4.2c-1-0.5-1.8-0.7-2.5,0.5c-0.4,0.6-1.3,1-2,1.5
				c-0.1,0.1-0.1,0.2-0.1,0.5c0.4,0.2,0.9,0.5,1.2,0.6c0.7-0.7,1.3-1.4,2.4-2.4c-0.4,2.1-0.7,3.8-1,5.4c-0.6,0.1-1.3,0-0.9-0.6
				c0.6-1,0.1-1.4-0.6-1.8c-0.8,0.5-0.8,0.5-1.8,0.3c-0.2,0-0.4-0.1-0.5,0c-1.6,0.9-3.6,1.3-4.9,2.8c-0.6,0.7-1.3,1.3-2.1,2.1
				c0.1,0.4,0.3,0.8,0.5,1.3c-0.5,0.5-1,1.1-1.7,1.8c0.5,0.3,0.9,0.6,1.2,0.8c-0.3,0.6-0.6,1-0.8,1.4c-0.5,0-0.9-0.1-1.3-0.1
				c-0.5,1.6,0.8,3.6-1.5,4.5c0.8,1.1,1.2,2.1,0.2,3.3c-0.2,0.2-0.2,0.8-0.1,1.2c0.4,1.1,1,2.2,1.4,3.3c0.2,0.5,0.3,1.1,0.4,1.4
				c-1,1.1-2.3-0.6-3.1,1.1c0.8,0.4,1.6,0.7,2.5,1.1c-1.3,1,0.4,2.7-1.3,3.4c1.2,1,0.4,1.8-0.2,2.7c0.7,0.9,1.4,1.7,2.2,2.9
				c-1.1-0.3-1.8-0.4-2.4-0.6c-0.4-1.1-0.8-2.1-1-3.1s-0.2-2.1-0.3-3.2c-1-0.5-2.9,0.6-3.4-1.5c0.6-0.1,1.1-0.3,1.7-0.4
				c-0.5-1.3-0.3-3,0.2-4c0.1-0.2,0.2-0.6,0.2-0.9c0-0.4,0-0.8,0-1.1c0.1-1.3,0.2-2.6,0.3-4.2c0.2-0.2,0.5-0.5,1-2.7
				c0.4-1.5,0.4-3-0.1-4.7c-0.6,0.5-1.1,0.8-1.5,1.1c-1.2,1.1-2.3,2.2-3.5,3.2c-2.7,2.1-5.5,4.2-8.2,6.2c-0.8,0.6-1.2,1.3-1.4,2.2
				c-1.5,6.4-3.1,12.9-4.7,19.3c-0.3,1.4-1,2.7-0.7,4.2c-1.4,1.7-1.1,3.9-1.9,5.7c-0.4,1-0.4,2.3-0.7,3.4c-0.3,1.1-0.8,2.2-1,3.3
				c-0.2,1.2,0,2.6-0.5,3.5c-0.6,1-0.9,2-1.1,3c-0.4,2.1-1,4.1-1.5,6.1c-1.6,6.1-3.1,12.2-4.6,18.3c0,0.2,0,0.4,0,0.6
				c0.5,0.1,1,0.3,1.5,0.4c0.3,1.3,0,2.1-1.4,2.3c-0.9,0.2-1.1,0.9-0.9,1.7c0.1,0.3,0.3,0.5,0.4,0.7c2.4,4.1,4.9,8.2,7.3,12.3
				c0.7,1.2,2,1.7,3.4,1.4c0.4-0.1,0.8-0.1,1.2-0.1c-0.3,1,0,2-0.7,2.9c-0.4,0.6-0.5,1.7,0.4,2.3
				C257.16,350.53,257.06,348.73,256.86,346.93z M277.16,247.23c-0.1,0.1-0.1,0.3-0.2,0.4c0.6,0.8,1.3,1.4,2.5,0.9
				c-0.1-0.2-0.2-0.4-0.4-0.5C278.46,247.73,277.86,247.53,277.16,247.23z"/>
			<path class="st0-bull-white" d="M790.86,233.73c-0.9,0-1.6,0-2.3,0c-5.3,0-10.5,0-15.8,0c-0.6,0-1.2,0-1.9,0c-0.9-0.1-1.4-0.6-1.3-1.5
				c0.1-0.6,0.1-1.2,0.2-1.9c-0.8-0.1-1.4-0.3-2.3-0.4c3.8-15.3,7.5-30.5,11.2-45.6c1.6-0.5,52.7-0.5,54.7-0.1c-0.1,1-0.3,2-0.4,3
				c2.1,1.1,2.1,1.1,1.6,3.4c-2.3,9.4-4.7,18.8-7,28.2c-7.3,29.1-14.5,58.2-21.8,87.3c-0.3,1.2-0.6,2.4-1,3.9c1,0.1,1.8,0.2,2.6,0.2
				c10.7,0,21.4,0,32.1,0c12.9,0,25.7,0,38.6,0c1.6,0,3.2,0,4.7,0c1,1.2-0.2,2.2-0.1,3.4c0.2,0.1,0.5,0.3,0.8,0.4
				c1.2,0.5,1.4,0.7,1,2c-2.9,11.8-5.7,23.6-8.6,35.5c-0.5,2.1-1,4.3-1.6,6.4c-0.6,2.1-0.9,2.3-3.1,2.3c-4.7,0-9.4-0.1-14.1-0.1
				c-6.6,0-13.3,0.1-19.9,0.1c-8.2,0-16.3,0-24.5,0c-8.8,0-17.7-0.1-26.5-0.1c-5.6,0-11.2,0.1-16.8,0.1c-1.8,0-3.6,0.1-5.4-0.1
				c-0.7-0.1-1.5-0.5-2.1-1c-0.7-0.6-1.1-1.4-1.7-2.1c-4.9-6.6-9.9-13.1-14.8-19.7c-0.6-0.9-1-1.9-1.5-2.9c-0.2-0.4-0.3-1-0.6-1.3
				c-1.2-1.2-1-2.5-0.6-4c1.3-4.9,2.4-9.8,3.6-14.6c1.9-7.5,3.7-15,5.6-22.5c0.9-3.4,1.9-6.8,2.7-10.2c1.7-7,3.4-14.1,5.1-21.1
				c0.3-1.1,0.8-1.8,1.6-2.5c4.8-3.8,9.6-7.6,14.4-11.4c4-3.2,8-6.4,11.9-9.6C788.96,235.93,790.36,235.53,790.86,233.73z
				 M777.16,353.23c-0.2-0.2-0.4-0.4-0.8-0.8c0.1,1.1,0.2,2,0.2,2.8c0.8-0.1,1.6-0.3,2.7-0.5
				C778.46,354.13,777.76,353.63,777.16,353.23c1.3-1.2,1.3-1.6,0.1-2.4C777.16,351.63,777.16,352.43,777.16,353.23z M754.46,309.83
				L754.46,309.83c0.1,0.5,0.1,0.8-0.6,1c-1.3,0.4-2.4,1.2-2.5,2.7c-1.4,0.4-1.8-0.3-1.7-1.4c-0.8,0.3-1.4,0.5-2,0.7
				c-0.2,1.4-1.3,2.8,0,4.2c-1,0-1.2,0.6-1.3,1.4c-0.9,3.6-1.7,7.1-2.7,10.6c-0.4,1.3-0.1,2.3,0.7,3.4c3.7,4.8,7.3,9.7,11,14.6
				c1.9,2.5,3.8,5,5.6,7.5c0.7,1,1.6,1.2,2.7,0.5c0.6-0.3,1.1-0.9,1.4-1.4c0.5-0.8,1-0.5,1.7-0.2c-0.6,0.6-1,1.1-1.6,1.7
				c2.9,0.6,4.4,0.3,4.4-0.7c-0.3-0.3-0.7-0.6-1-0.9c0.1-0.2,0.2-0.3,0.2-0.5c0.7,0.2,1.4,0.3,2,0.7c0.3,0.2,0,1,0,1.6
				c0.6-0.1,1.3-0.2,2.1-0.3c0-0.5,0.1-1.2-0.2-1.6c-0.4-0.7-0.4-1.4-0.2-2.1c0.2-1,0.1-2,0-3c0-1,0.9-2.4,1.7-2.4
				c1.1,0,1.5,0.6,1.8,1.6c0.2,0.7,0.8,1.3,1.2,1.9c0.1,0.1,0.6,0,0.6,0c0.1-1.5,1.5-0.8,2-1.4c0.3,0.8,0.6,1.5,0.9,2.2
				s0.6,1.4,0.9,2c0.9-0.1,1.5,0.2,2.2,0.8c-0.7,0.4-1.3,0.6-1.9,0.9c0.1,0.5,0.2,1,0.2,1.5c1.2,0,2.2,0,3.2,0
				c1.6,0.1,2.6-0.6,3.2-2.2c0.2-0.4,0.5-0.8,0.7-1.2l-0.1,0.1c0.2,0.2,0.5,0.5,0.5,0.7c0.1,0.7,0,1.5,0,2.1
				c2.4,1.4,4.6-0.4,6.8,0.4c1.4-1.5,1.7-2.2,1.9-3.9c0.1-0.6,0.3-1.2,0.7-1.6c0.9-1.1,0.4-2.7,1.6-3.6c-0.1-0.2-0.3-0.3-0.4-0.5
				c-0.1-0.3-0.1-0.7,0-0.8c1.4-0.6,1-2.3,2-3.2c0.8-0.7,0.5-2.6-0.3-3.2c-0.5-0.3-1.1-0.6-1.6-0.9c-0.9-0.5-1.7,0.9-2.6,0
				c-0.1-0.1-0.5,0.4-0.8,0.5c-1.1,0.5-2.2,1-3.4,1.3c-0.5,0.1-1.2-0.1-1.8-0.2c-0.1,0-0.1-0.5-0.1-0.6c1.2-0.4,2.4-0.5,3.2-1.2
				c0.6-0.5,1.3,0.5,1.7-0.5c0.3-0.8,1.1,0.2,1.7,0.1c1.3-0.1,2.7-0.4,4,0.4c0.2,0.1,0.6-0.1,0.9-0.3c0.4-0.3,0.7-0.6,1.1-1
				c0.8,0.6,1.6,1.2,2.6,1.9c-1,0.1-1.6,0.2-2.4,0.2c0.5,0.4,0.7,0.6,1.2,1c-0.8,0.1-1.3,0.2-2.1,0.4c0.6,0.2,1,0.3,1.3,0.4
				c-0.4,0.4-0.9,0.6-1,1c-0.3,0.7,0.3,1.3,0.7,1.8c-0.6,0.1-1.2,0.1-1.8,0.2c-0.5,1.6-0.5,1.6,1.5,3.4c0.4-0.7,0.8-1.4,1.1-1.9
				c-0.3-0.7-0.6-1.2-0.9-1.7c0.9,0.3,2.1-0.7,2.8,0.6c-0.3,0.2-0.7,0.4-1,0.5c0.3,1.3,0.5,2.5,0.8,3.7c0.5,0.1,0.9,0.3,1.4,0.4
				c-0.4,0.6-0.6,1-0.9,1.5c-0.6-0.6-1-1.3-1.6-1.6c-1-0.5-2.1-0.8-3.5-1.2c0.7,1.4-0.5,1.6-1.6,2.5c0.5,0.3,0.9,0.6,1.3,0.6
				c0.7,0,1.4-0.2,2.2-0.3c0.1,0.6,0.2,1.1,0.2,1.6c-0.9,0.1-2.3-0.1-2.4,1.1c-0.1,1-2.2,0.9-1.2,2.4c4,1,4.7,0.9,6.4-0.4
				c-1-0.1-1.8-0.1-2.7-0.2c-0.3-0.5-0.5-0.9-0.7-1.3c0.3-0.2,0.6-0.3,0.8-0.5c-0.1-0.4-0.2-0.8-0.4-1.2c0.4-0.1,0.9-0.2,1.3-0.2
				c0.4,0,0.8,0.1,1.2,0.2c-0.4,0.3-0.8,0.7-1.3,1.1c0.6,0.4,1,0.7,1.5,1c0.1-0.1,0.3-0.2,0.4-0.3c-0.2-0.7-0.4-1.3-0.6-2
				c1,0.1,1.9,0.3,3.4,0.5c-1,1.2-1.7,2-2.3,2.8c1.3,1.6,3.1,1,4.7,1.1c0.2-0.3,0.4-0.7,0.5-1c0.5,0.3,0.9,0.6,1.3,0.9
				c0.3-0.6,0.5-1,0.7-1.5c-0.3-0.1-0.5-0.2-0.8-0.3c0.2-0.3,0.4-0.5,0.7-1.1c0.5,0.9,0.8,1.6,1.2,2.2c0.5-0.2,1.1-0.4,1.9-0.8
				c-0.1,0.6-0.2,1-0.3,1.4c0.3,0,0.5,0.1,0.8,0.1c1.5,0,3.1-0.1,4.6-0.1c1.6,0,3.1,0.2,4.7,0.3c0.9,0.1,1.3-0.5,1.9-1
				c0.6-0.6,1.1-1.5,2.3-1.1c0.1,0.6,0.2,1.3,0.3,2c0.7,0.1,1.3,0.1,1.9,0.1c8.7-0.1,17.5-0.1,26.2-0.3c1.4,0,2.9-0.7,4.1-0.3
				c1.6,0.5,2.7-0.1,4.1-0.6c0.3,1.3,1.4,0.5,2.2,1c0.2-0.8,0.7-1.5,0.6-2.2c-0.1-0.8,0.1-1.4,0.4-2.1c0.3-1,0.7-1.9,1-2.9
				c2.2-8.7,4.3-17.4,6.5-26.1c0.1-0.5,0.2-1.1,0.3-2.1c-0.8,0.8-1.2,1.4-1.7,1.6c-0.9,0.3-0.8,1.3-1.4,1.6c-0.8,0.5-1.7,0.6-2.6,1
				c-0.4,2-0.5,2.1-2,1.8c0.1-0.3,0.2-0.7,0.4-0.9c0.8-1.3,2.1-2,3.1-3.1c0.5-0.5,0.9-0.8,1.5-1.2c1.2-0.7,2.4-1.8,3.1-3
				c1.2-2.1,0.8-4.6,2.1-7c-3.5,0-6.7,0-10,0c-0.6,0-1.3-0.2-1.5,0.7c-0.4-0.8-1.1-0.7-1.8-0.7c-2.8,0-5.7,0-8.5,0.1
				c-0.4,0-0.8,0.3-1.3,0.4h0.1c-0.9-0.2-1.9-0.5-2.8-0.5c-13.9,0-27.8,0-41.7,0c-0.1,0-0.2,0.1-0.4,0.2c0,0.3,0.1,0.6,0.2,1.3
				c-0.7-0.3-1.2-0.5-1.7-0.8c-0.3-0.2-0.5-0.6-0.7-0.6c-2.8,0-5.6,0-8.4,0.3c-1.2,0.1-2.7-0.5-2.5-1.5c0.2-1.6,0.6-3.1,1-4.6
				c0.4-1.5,0.9-3,1.2-4.6c0.1-0.4-0.4-0.8-0.7-1.3c0.3-0.1,0.7-0.3,1.1-0.4c1.6-6.4,3.2-12.7,4.8-19.1c1.6-6.3,3.1-12.6,4.7-18.9
				c1.6-6.4,3.2-12.7,4.8-19.1c1.6-6.4,3.2-12.7,4.7-19.1c1.5-6.3,3.2-12.5,4.7-18.8c1.4-6.3,3.2-12.6,4.8-19.1
				c-17.9,0-35.5,0-53.2,0c-3.6,14.8-7.3,29.5-11,44.7c2.1-0.5,3.9-0.2,5.7-0.2c7.1,0,14.1,0,21.2,0c0.6,0,1.4-0.1,1.5,0.7
				c0,0.4-0.4,1-0.7,1.3c-2.4,2.1-4.9,4.1-7.4,6.1v-0.1c-0.5,0.4-1,0.7-1.5,1.1c-5.4,4.3-10.9,8.7-16.3,13c-3,2.4-6,4.8-9,7.2
				c0.3,0.4,0.5,0.8,0.9,1.3c-1.5,0.3-2.3,0.9-2.4,2.3c-0.1,1-1.1,2.1,0.5,3.2c-1.4,0-1.7,0.8-1.7,1.7c0,0.5,0.2,1,0.1,1.5
				c-0.1,0.4-0.6,0.8-0.8,1.2c-1.7,6.3-3.2,12.6-3,19.1c0,0.5,0.2,1.3-0.1,1.6c-0.7,0.9-0.5,2-0.6,3c-0.2,2.2,0.4,4.5-0.5,6.7
				c-0.1,0.4,0.2,0.9,0.3,1.3c-1,1-2.3,6.2-2,7.7C753.16,309.53,753.86,309.73,754.46,309.83z M752.76,293.43
				c-1.7,5.9-3.2,11.8-4.7,18.2c0.7-0.4,1.1-0.5,1.3-0.7c1.4-1.1,1.8-2.9,2.2-4.4c0.6-2,0.5-4.2,0.7-6.3c0-0.5-0.1-1.1,0.1-1.4
				C753.46,297.13,752.76,295.23,752.76,293.43z M781.46,353.33c-1-0.1-1.2,0.5-1.1,1.3c0,0.3,0.4,0.6,0.6,0.9
				c0.4,0,0.7-0.1,1.1-0.1C780.06,354.53,781.86,354.03,781.46,353.33z"/>
			<path class="st0-bull-white" d="M651.06,309.93c26.4,0,52.4,0,78.6,0c-0.2,1.3-0.4,2.3-0.6,3.4c0.4,0.2,0.7,0.3,1.1,0.5
				c0.7,0.3,0.9,0.8,0.8,1.5c-0.1,0.6-0.2,1.2-0.4,1.8c-3.3,13.3-6.5,26.6-9.8,39.9c-0.1,0.5-0.2,0.9-0.4,1.4
				c-0.3,1-0.9,1.5-1.9,1.5c-0.7,0-1.4,0-2.1,0c-14.7,0-29.3,0-44,0c-20,0-40.1,0-60.1,0c-2.3,0-3.8-0.7-5.2-2.6
				c-5-6.8-10.1-13.5-15.1-20.3c-0.7-1-1.1-2.2-1.7-3.3c-0.2-0.4-0.3-0.8-0.5-1.1c-1.3-1.3-1-2.7-0.6-4.2c1.2-4.5,2.2-8.9,3.4-13.4
				c3.2-12.8,6.4-25.6,9.6-38.4c1.2-4.9,2.6-9.7,3.6-14.6c0.5-2.3,1.6-3.8,3.3-5.2c4.8-3.8,9.7-7.6,14.5-11.4c4.4-3.5,8.7-7,13-10.6
				c0.3-0.2,0.5-0.5,0.9-1c-0.8-0.1-1.3-0.2-1.8-0.2c-5.7,0-11.3,0-17,0c-0.3,0-0.7,0-1,0c-1.4-0.1-1.7-0.5-1.6-1.8
				c0-0.5,0.1-1.1,0.2-1.7c-0.8-0.1-1.5-0.2-2.3-0.4c3.8-15.3,7.5-30.5,11.2-45.7c1.5-0.4,52.2-0.6,54.7-0.1c-0.2,1-0.3,2-0.5,3
				c0.7,0.5,1.6,0.8,1.7,1.4c0.2,0.7,0,1.6-0.2,2.4c-9.6,38.5-19.2,77-28.8,115.5C651.66,307.33,651.46,308.43,651.06,309.93z
				 M728.76,310.83c-1.1,0-1.8,0-2.4,0c-4.6-0.1-9.1,0.1-13.7,0c-20.3-0.1-40.5,0-60.8,0c-0.8,0-1.5,0.1-2.3,0.1c-1.6,0-2-0.5-1.6-2
				c0.1-0.5,0.3-1.1,0.4-1.6c2.7-10.7,5.4-21.4,8-32.1c7.3-29.3,14.6-58.6,21.9-87.9c0.2-0.8,0.3-1.6,0.5-2.6c-2.2,0-4.2,0-6.2,0
				c-1.9,0-3.9,0-5.8,0c-2,0-4,0-6,0c-1.9,0-3.9,0-5.8,0c-2,0-4,0-6,0c-1.9,0-3.9,0-5.8,0c-2,0-4-0.1-6,0c-1.9,0.1-3.8,0.7-5.6,0.3
				c-2-0.5-3.9-0.2-5.9-0.3c-3.7,14.9-7.3,29.6-10.9,44.5c0.9,0,1.6,0,2.5,0c0.3-0.8-0.3-2.1,1.2-2.3c0.1,0.7,0.2,1.4,0.3,2.2
				c0.5,0.1,0.9,0.2,1.3,0.2c4.9,0,9.8,0,14.7,0c2.3,0,4.7,0,7,0.1c0.4,0,0.9,0.3,1.1,0.7c0.1,0.2-0.2,0.8-0.4,1.1
				c-0.2,0.3-0.6,0.5-0.9,0.8c-4.1,3.3-8.2,6.6-12.3,9.8c-0.7,0.6-1.5,1.1-1.7,2.2c-0.1,0.4-0.8,0.8-1.3,1.1c-0.4,0.3-1,0.3-1.4,0.7
				c-4.5,3.3-8.9,6.7-13.2,10c0.1,0.8,0.2,1.2,0.3,1.8c-0.8-0.2-1.3-0.3-1.8-0.4c-2,1.5-3.3,3.3-3.8,5.9c-1.2,5.4-2.6,10.8-4,16.2
				c-3.8,15.1-7.5,30.1-11.3,45.2c-0.3,1.1-0.4,2.1,0.7,3.2c-1.2,0-1.6,0.4-1.8,1.3c-0.2,1.1-0.6,2,0.2,3.1c2.4,3.2,5,6.4,7.3,9.6
				c3,4.2,6.2,8.3,9.3,12.4c1.2,1.6,1.9,1.8,3.9,1.3c0.4-0.1,0.8-0.2,1.2-0.2c0.9,0.1,1.9,0.4,2.8,0.4c5.3,0,10.5,0.1,15.8,0
				c1.8,0,3.6,0.4,5.3-0.4c0.2-0.1,0.5,0,0.8,0.1c2.7,0.7,5.5,0.2,8.1,0.4c0.3-0.6,0.6-1.1,1-1.8c0.2,0.8,0.2,1.3,0.3,1.8
				c2.1,0,4.1,0.1,6.1,0c1,0,2.1-0.4,3.2-0.6c0.2,0.8,1.1,0.6,1.9,0.6c4,0,8,0,12,0c3.5,0,7.1,0,10.6,0c3.6,0.1,7.1-0.1,10.7-0.1
				c8.5,0.1,17,0,25.5,0c0.7,0,1.3,0,2.1,0c0.5-2,0.8-3.8,1.4-5.5c0.3-0.9,0-1.4-0.4-2c-0.1-0.1,0-0.3,0.1-0.5c0.3,0,0.6,0,0.6,0
				c1-2.8,1.7-5.6,2.4-8.3c0.7-2.9,1.4-5.8,2.1-8.6c0.7-2.9,1.4-5.7,2-8.6c-0.6-0.3-1.1-0.5-1.6-0.7c-0.5,0.4-1.1,0.8-1.8,1.3
				c-0.1-0.6-0.3-1.1-0.2-1.1c1.1-0.5,2.3-0.9,3.8-1.5C727.46,317.43,727.86,314.23,728.76,310.83z"/>
			<path class="st0-bull-white" d="M812.56,371.03c18.4,0,36.6,0,54.9,0c0,0.7,0,1.3,0.1,2c0.1,0.6,0.3,1.3,0.7,1.6c0.8,0.6,1,1.4,1,2.4
				c0,3,0,6.1,0,9.1c0,1.8-0.4,2.2-2.2,2.3c-4.8,0-9.7-0.1-14.5-0.1c-3,0-5.9,0.1-8.9,0.1c-2.4,0-4.8,0-7.2,0
				c-0.6,0-1.1,0.2-1.7,0.7c0.3,0.1,0.6,0.4,1,0.4c1.1,0.1,2.2,0.1,3.3,0.1c6.5,0,13,0,19.5,0c0.5,0,0.9,0.1,1.6,0.2
				c0,0.7-0.1,1.4,0,2.1c0.1,0.6,0.3,1.3,0.7,1.6c0.8,0.6,0.9,1.3,0.9,2.2c0,3.1,0,6.2,0,9.3c0,1.8-0.4,2.2-2.2,2.2
				c-2,0-4-0.2-6-0.2c-1.7,0-3.3,0.2-5,0.2c-3.9,0-7.9,0-11.8,0c-2.4,0-2.6,0.3-2.6,2.6c0,4.4,0,8.7,0,13.1c0,2.3-0.3,2.6-2.6,2.7
				c-3.5,0-6.9,0-10.4,0c-1.5,0-2.5-0.4-3.1-1.9c-0.2-0.4-0.4-0.9-0.7-1.2c-2.2-2.1-2.9-5-4.3-7.5c-0.4-0.7-0.4-1.6-0.4-2.4
				c0-6,0-12-0.1-18c0-1.7,1.2-2.2,2.2-3s2.1-1.6,3.5-2.6c-0.9-0.2-1.3-0.3-1.7-0.3c-1.9-0.2-2.1-0.4-2.2-2.3c0-0.3-0.1-0.7-0.1-1.1
				c-0.5-0.2-1-0.4-1.5-0.6C812.56,380.03,812.56,375.63,812.56,371.03z M822.46,372.93c-0.7,0.3-1.4,0.4-2.1-0.3
				c-0.3-0.3-1-0.5-1.5-0.6c-0.9-0.1-1.9-0.2-2.9-0.2c-0.9,0-1.8,0-2.7,0c0,1,0,1.7,0,2.5c0.6,0,1.2-0.1,1.9-0.1
				c0,0.8-0.1,1.4-0.1,2c-1.7,0.5-1.8,0.5-1.8,2c0,1.1,0.2,2.1,0.3,3.4c0.7-0.4,1.1-0.7,1.5-0.9c1.2,0.8,1.3,1.9,0.8,3.2
				c0.8-0.1,1.3-0.1,1.9-0.2c0-1.3,0.4-2.1,1.6-2.3l-0.1-0.1c0.1,1.6,1.2,2.1,2.6,2.5c1.3,0.4,1.5,1.1,0.6,2.1
				c-1,1.2-2.1,2.4-3.2,3.6c0.6,0.9,0.6,0.9-0.1,2.1c-0.5-0.1-0.9-0.1-1.5-0.2c0,1.4,0,2.6,0,3.8c-0.6,0.4-1.1,0.7-1.6,0.9
				c0.9,1.7,0.8,1.7,0.1,3.9c-0.1,0.4-0.1,0.9,0,1.2c0.5,1.1,1.1,2.2,1.6,3.4c0.2,0.5,0.2,1.1,0.2,1.4c-1,1.3-2.5-1-3.2,1.1
				c0.8,0.3,1.7,0.7,2.7,1.1c-1.1,1,0.5,2.8-1.4,3.5c1,1.5,1,1.5-0.1,3.2c0.7,0.8,1.4,1.7,2.3,2.7c-0.9-0.1-1.5-0.2-2.1-0.2
				c0.3,0.9,0.5,1.7,0.9,2.3c0.3,0.5,0.9,1,1.3,1c3.4,0.1,6.7,0.1,10.1,0.1c0.9,0,1.9-0.1,2.9-0.1c0-1.2,0.4-2.3-0.1-3
				c-0.7-1.1,0.7-1.7,0.2-2.5c-0.2-0.3-0.2-0.6-0.4-0.9c-0.1-0.1-0.2-0.4-0.2-0.4c1.1-1.3,0.4-2.8,0.5-4.1c0.1-1.9,0-3.7,0-5.6
				c0-0.8,0.2-1.6,1.2-1.6c1.9-0.1,3.7-0.3,5.6-0.3c3,0,5.9,0.2,8.9,0.2c1.1,0,2.2-0.2,3.1-0.3c0.1-0.7,0.2-1.3,0.2-1.9
				c0.1-0.6,0.3-1.3,0.1-1.8c-0.8-1.7-0.4-3.5-0.5-5.3c0-0.9,0-1.8,0-2.9c-1,0-1.7,0-2.4,0c-3.9,0-7.9,0.1-11.8,0
				c-5-0.1-4.2,0.9-4.3-4.8c0-0.1,0-0.3,0-0.4c0.5-0.9,0.5-2.1,1.8-2.4c0.1,0.4,0.2,0.7,0.3,1.1c5.2-0.1,10.4,0.2,15.7-0.1
				c0-1.6,0.2-3-0.1-4.4c-0.2-1.3,1.2-2.6,0.4-3.6c-1.1-1.4,1.3-2.5,0-3.8c-4,0-8,0-12,0c-0.1,0.8-0.2,1.6-0.3,2.3
				c-1.5-0.4-0.1-1.5-0.8-2.3c-1.2,0-2.6,0-4,0c-0.2,0.6-0.4,1.2-0.6,1.6c0.7,1.1,1.3,2,2.2,3.2c-1.2-0.2-1.9-0.4-2.7-0.6
				c0.4-0.9,0.1-1.4-0.9-1.5c-0.7-0.1-1.3-0.2-2.2-0.4c0.7-0.9,1.1-1.5,1.5-2c0-0.2-0.1-0.3-0.1-0.5c-1,0.6-2.1,0.8-3.1,0.3
				c0,0-0.1,0.1-0.2,0.1c-0.4,0.1-0.9,0.2-1.3,0.2c-0.8-0.1-1.5-0.4-2.2-0.4C822.26,371.83,822.06,372.33,822.46,372.93
				L822.46,372.93z M866.66,371.83c-4.8,0-9.4,0-14,0c-1.4,0-2,0.6-2,2c0,3,0,5.9,0,8.9c0,0.3,0.2,0.6,0.3,1c5.3,0,10.5,0,15.8,0
				C866.66,379.73,866.66,375.93,866.66,371.83z M852.16,402.33c2.2,0,4.5,0,6.9,0c0-3.9,0-7.8,0-11.9c-2.6,0-5.1,0-7.6,0
				c-0.9,1.7,1,2.1,1.3,3.2c-0.1,0.2-0.2,0.4-0.2,0.5c-0.4,1.7,0.6,3.6-0.7,5.2c-0.2,0.2,0,0.7,0,1
				C851.86,400.93,852.06,401.53,852.16,402.33z M813.26,410.43c0.2,2.4-0.1,4.8,2.4,6.5c-0.4-2.3-0.7-4.4-1.1-6.5
				C814.26,410.43,813.96,410.43,813.26,410.43z M815.06,392.43c-2.3,0.8-1.8,2.3-1.8,3.6c0,0.7-0.5,1.6,0.9,2
				C814.46,396.13,814.76,394.43,815.06,392.43z M813.26,401.63c0.1,0,0.1-0.1,0.2-0.1c0-0.8,0-1.6,0-2.3c-0.1,0-0.1,0-0.2,0
				C813.26,400.03,813.26,400.83,813.26,401.63z M813.66,382.83c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0.2,0.5,0.4,0.7,0.7
				c0.1-0.1,0.2-0.2,0.4-0.4C814.06,383.23,813.86,383.03,813.66,382.83z"/>
			<path class="st0-bull-white" d="M529.86,388.73c-0.5-0.1-0.8-0.3-1.2-0.4c-0.3-0.1-0.8-0.2-0.9-0.5c-0.7-1.7-1.3-3.3-2.4-4.8
				c0-3.9,0-7.8,0-12c0.9,0,1.6-0.1,2.3-0.1c6.7,0,13.3,0,20,0c10.1,0,20.3,0,30.4,0c0.3,0,0.6,0,0.8,0c1.4,0.1,1.8,0.5,1.8,1.9
				c0,0.9,0.2,1.6,0.9,2.4c0.5,0.5,0.7,1.6,0.7,2.5c0.1,4.9,0,9.8,0,14.7c0,8.7,0.1,17.4,0.1,26.1c0,1.7-0.1,3.3-0.2,5
				c-0.1,1.4-0.5,1.8-1.9,1.9c-0.4,0-0.8,0-1.2,0c-14.6,0-29.2,0-43.7,0c-2.1,0-3.6-0.5-4.8-2.2c-0.6-0.8-1.5-1.5-2-2.3
				c-0.6-0.9-1.1-1.9-1.6-2.9c-0.2-0.4-0.3-0.8-0.5-1.1c-1.1-1.3-1.2-2.8-1.2-4.4c0.1-4.6,0-9.3,0-13.9c0-1.6,0-3.3,0.1-4.9
				c0-0.4,0.2-0.8,0.5-1.1c0.6-0.6,1.3-1.1,1.9-1.6C528.56,390.33,529.56,389.93,529.86,388.73z M527.26,399.73
				c-1.1-0.4-1.1,0.4-1.1,1.1c0,2.7,0,5.4,0,8.1c0,2-0.5,4.2,0.2,5.9c0.7,1.9,2.6,3.3,4,5c0.2,0.2,0.4,0.4,0.6,0.6
				c0.3,0.2,0.7,0.5,1.1,0.5c3.6,0,7.2,0,10.8,0c-0.2-0.8-0.3-1.5-0.4-2.2c0.7-0.3,1.2-0.4,1.8-0.6c0.4,0.5,0.7,1,1.1,1.7
				c0.8-0.3,1.5-0.6,2.3-0.9c0.1-1.8,0.1-1.8-1.2-3.7c-2.3,0.2-4.8,0.4-7.6,0.6c0.4-0.5,0.5-0.9,0.7-1c0.8-0.4,1.6-0.8,2.4-1.1
				c0.5-0.1,1.1,0,1.7,0.1c0.5,0.1,1.1,0.4,1.5,0.2c1.8-0.5,3.1-2.8,2.8-4.9c-1.2-0.1-2.3-0.2-3.6-0.3c0.1-0.4,0.2-0.5,0.2-0.6
				c-0.1-5.4-0.1-10.7-0.2-16.1c0-1.9-0.1-3.9-0.1-5.8c0-1.9,0.5-2.4,2.4-2.5c1.2,0,2.4,0,3.8,0c0.2-4.1,0.3-8-0.7-11.8
				c-0.2-0.1-0.4-0.2-0.5-0.2c-3.3,0-6.6-0.1-9.9,0c-0.4,0-0.9,0.4-1.2,0.6c-1-0.2-1.9-0.6-2.8-0.6c-2.3,0.1-4.5,0.4-6.8,0
				c-0.8-0.1-1.6,0-2.5,0.1c0,1.6-0.2,3,0,4.3s0.5,2.5,0,3.8c-0.1,0.2,0.4,0.7,0.6,1c0.6,0.9,1.2,1.7,1.9,2.8c1.7,0,3.7,0,5.7,0
				c0.5,0,1.2,0.3,1.3,0.7c0.2,0.4-0.1,1-0.4,1.4c-1.1,1.4-2.7,2.1-4.1,3.3c-1.1,0.9-2.2,1.8-3.2,2.6c-0.1,0.8-0.1,1.4-0.2,2.2
				c-0.6-0.3-1.1-0.5-1.6-0.7c0.2,1.1,0.3,2,0.4,3c0.3,0.1,0.6,0.2,0.9,0.3c-0.1,0.5-0.4,1.1-0.2,1.4
				C528.16,398.73,527.26,399.13,527.26,399.73L527.26,399.73z M550.36,413.13L550.36,413.13c0,0.2,0.1,0.5,0,0.6
				c-1.4,1.3-1.1,2.8-0.9,4.4c1.3,0.4,2.2,1.1,2.1,2.6c1.5,0,2.9,0,4.4,0c-0.2-0.6-0.3-1-0.4-1.5c0.8-0.1,1.6-0.3,2.7-0.5
				c-0.6,0.8-1,1.3-1.5,2c7.9,0,15.4,0,23.1,0c0-16.3,0-32.5,0-48.6c-1.8-0.5-26.4-0.5-28.2,0c0,0.4-0.2,0.9-0.1,1.2
				c0.8,1.3,0.6,2.6,0.6,4c0,1.6-0.1,3.2,1.5,4c-0.1,0.9-0.2,1.6-0.3,2.6c2.1,0,4,0,6,0c1.8,0,2.1,0.2,2.1,2c0,7,0,14,0,20.9
				c0,1.8-0.3,2-2.1,2.1c-2.3,0-4.7,0-7,0c-0.7,0-1.3,0.1-2.1,0.1C550.06,410.53,549.86,411.83,550.36,413.13z M560.56,388.83
				c-0.4-0.2-0.5-0.3-0.6-0.3c-3.7,0-7.4-0.1-11.2-0.1c-1.3,0-1.6,0.5-1.7,1.9c-0.1,2.1-0.1,4.3-0.1,6.4c0,3.6,0,7.3,0,11.1
				c1,0.2,1.8,0.5,2.6,0.4c3,0,6.1-0.1,9.1-0.2c0.6,0,1.2-0.1,1.8-0.1C560.56,401.43,560.56,395.13,560.56,388.83z M548.86,420.73
				c-0.6-0.5-0.8-1.4-1.7-0.9c-0.1,0.1-0.2,0.4-0.2,0.5C547.36,421.23,548.06,420.83,548.86,420.73z"/>
			<path class="st0-bull-white" d="M674.46,388.53c-0.3,0-0.7-0.1-1-0.1c-1.7-0.2-1.8-0.4-2.1-2c-0.2-1.4-0.2-1.4-1.6-2.1c0-4.4,0-8.8,0-13.4
				c18.4,0,36.7,0,55.2,0c0,0.8,0,1.5,0.1,2.2c0.1,0.5,0.2,1.2,0.5,1.5c1.2,0.7,1.1,1.8,1.1,2.9c0,15,0,30,0,45c0,0.4,0,0.8,0,1.2
				c-0.1,1-0.6,1.5-1.6,1.6c-0.6,0-1.2,0-1.9,0c-11.1,0-22.1,0-33.2,0c-3.7,0-7.3,0-11,0c-1.4,0-2.5-0.4-3.4-1.5
				c-0.9-1.1-1.8-2.1-2.8-3.1c-0.8-0.9-1.5-1.8-1.6-3c0-0.3-0.1-0.6-0.3-0.8c-1.8-1.5-1.3-3.6-1.3-5.6c-0.1-5.7,0-11.3,0-17
				c0-1.1,0.4-1.8,1.2-2.4c1.3-1,2.5-2.1,3.8-3.1C674.56,388.83,674.56,388.63,674.46,388.53z M671.56,402.33L671.56,402.33
				c-0.2,0.5-0.6,1.1-0.6,1.6c-0.2,1.3-0.3,2.6-0.3,3.9c-0.1,1.3-0.1,2.6,0,3.9c0.1,1.1-0.3,2.5,1,3.3c-0.3,1.5,0.9,2.1,1.9,2.7
				c0.5,0.3,0.7,0.4,0.8,0.9c0.6,1.7,1.2,2.2,3,2.1c1.2,0,2.3-0.3,3.5-0.3c2.1,0,4.2,0.3,6.3,0.3c5.3,0,10.5,0,15.8,0
				c0.6,0,1.1-0.4,1.8-0.6c0.2,0.9,1.1,0.6,1.9,0.6c5.3,0,10.6,0,16,0c0.5,0,1.1-0.1,1.8-0.1c0-2.2,0-4.2,0-6.3c0-2,0-4,0-6
				c0-2.1,0.4-4.2-0.1-6.1c-0.4-2,0.2-3.8,0-5.8c-0.1-2.1,0-4.1,0-6.2c0-2.1,0-4.1,0-6.2c0-2,0-4,0-6c0-2.1,0-4.1,0-6.3
				c-3.4,0-6.6,0-9.9,0c-0.1,0.6-0.1,1-0.1,1.3c0.2,3-0.3,5.8-1.2,8.6c-0.4,1.2-0.5,2.5-0.7,3.7c-0.5,2.4-1.2,4.8-1.5,7.3
				c-0.3,1.9-0.6,3.8-1.5,5.6c-0.1,0.2,0,0.4,0,0.6c-0.3,1.7-0.6,3.4-0.9,5.1c-0.2,0-0.3,0-0.5,0c-0.1-0.8-0.2-1.7-0.2-2.5
				c-0.6-0.2-1.2-0.4-2-0.7c0,2.3,0,4.4,0,6.4c0,1.3-0.3,1.6-1.6,1.6c-1.8,0-3.6,0-5.4,0c-0.6,0-1.3,0.4-1.7,0.5
				c-1-0.2-1.9-0.4-2.8-0.4c-1.1-0.1-2.2-0.1-3.3-0.1c-1.8,0-2.2-0.4-2.2-2.2c0-6.8,0-13.7,0-20.5c0-2,0.2-2.3,2.3-2.3
				c4.1,0,8.3,0,12.4,0c2.1,0,2.2,0.1,2.2,2.2c0,3.7,0,7.4,0,11.2c0,1.5,0.8,2.5,1.8,3.4c0.5-1.9,1-3.6,1.5-5.4
				c-0.3-0.2-0.7-0.5-1.3-0.9c0.7-0.4,1.3-0.5,1.5-0.8c0.5-1.4,1-2.8,1.3-4.2c0.5-2.6,0.9-5.3,1.3-8c0.2-1.2,0.3-2.5,0.8-3.6
				c0.8-2,0.3-3.8,0.3-5.7c-2.3-0.5-33.9-0.4-35.4,0c-0.1,0.9-0.1,1.7-0.2,2.6c-0.1,0-0.2,0-0.3,0c-0.1-0.9-0.2-1.8-0.2-2.8
				c-2.2,0-4.2,0-6.3,0c0,4,0,7.8,0,11.9c2.2,0,4.2,0,6.3,0c0.3,0,0.6-0.5,1-0.8c0.3,0.3,0.7,0.7,1,0.8c0.6,0.2,1.3,0.1,1.5,0.9
				c0.2,0.9-0.5,1.2-1,1.6c-1.8,1.4-3.4,3-5.3,4.2c-2.7,1.7-4.1,3.9-3.5,7.2c0.2,0.9,0,1.8,0,2.7c0,0.6,0,1.1,0.1,1.7
				C670.76,402.53,671.06,402.73,671.56,402.33z M704.76,407.93c0.5-2.7,0.4-17.5-0.1-19.4c-0.5-0.1-1.2-0.2-1.8-0.2
				c-2.9,0-5.8,0-8.7,0c-2.5,0-2.8,0.3-2.8,2.7c0,5,0,10,0,14.9c0,0.6,0.1,1.2,0.2,1.9C696.06,407.93,700.46,407.93,704.76,407.93z"
				/>
			<path class="st0-bull-white" d="M385.16,388.53c-0.3-0.1-0.5-0.1-0.8-0.2c-2-0.3-2-0.3-2.2-2.2c-0.1-1.2-0.1-1.2-1.6-1.8c0-4.3,0-8.7,0-13.3
				c1.8,0,3.5-0.5,5.3-0.1c0.5,0.1,1.1,0,1.7,0c7.6,0.3,15.2-0.1,22.8,0s15.2,0,22.8,0c0.8,0,1.6,0,2.5,0c0,0.9,0,1.6,0.1,2.3
				c0.1,0.5,0.3,1.2,0.6,1.4c1,0.7,1,1.6,1,2.6c0,6.1,0,12.2,0,18.3c0,1.6-0.6,2.6-2,3.2c-2,0.9-3.9,1.8-5.8,2.8
				c-0.7,0.3-1.3,0.7-2.2,1.2c0.7,0.5,1.1,1,1.6,1.3c1.8,1.1,3.7,2.2,5.5,3.2c0.9,0.5,1.6,1,1.5,2.2c-0.1,0.6,0.2,1.3,0.6,1.7
				c1,1,1.1,2.2,1.1,3.4c0,3,0,5.9,0,8.9c0,1.4-0.4,1.9-1.8,2c-3.4,0.1-6.8,0.1-10.2-0.1c-0.9,0-1.8-0.6-2.6-1.1
				c-5.5-3.5-11-7.1-16.5-10.7c-0.6-0.4-1.3-0.9-1.9-1.2c-1.7-0.9-2.3-0.6-2.4,1.2c-0.1,2.3,0,4.6-0.1,6.8c0,1,0,2.1,0,3.1
				c-0.1,1.4-0.4,1.8-1.8,1.8c-4.3,0.1-8.6,0.1-12.9,0.1c-1,0-1.7-0.5-2.2-1.4c-1.5-2.8-3.1-5.5-4.6-8.3c-0.2-0.4-0.2-0.9-0.2-1.4
				c0-4.6,0-9.2,0-13.9c0-1.9,0-3.7-0.1-5.6c-0.1-1.6,0.7-2.7,2-3.5c1.1-0.7,2-1.6,3-2.4C385.36,388.83,385.26,388.73,385.16,388.53
				z M382.66,400.13c-0.2,0.1-0.7,0.2-0.7,0.3c-0.2,2.3-0.4,4.7-0.6,7C382.16,404.93,382.86,402.63,382.66,400.13
				c0.4-0.4,0.8-0.9,1.2-1.3c1.2,0.3,1.6,0.8,1.1,1.7c-0.8,1.7-0.9,3-0.2,5c0.5-0.8,1-1.4,1.5-2.1c0.5,0,1,0.1,1.6,0.1
				c-0.5,0.8-1,1.4-1.5,2.2c0.6,0.4,1.1,0.7,1.6,1.1c-0.7,0.9,0.4,1,0.5,1.2c-0.3,1.3-0.6,2.4-0.9,3.4c-0.6,1.8-0.7,3.9-2.9,4.9
				c-0.4-0.1-0.9-0.3-1.3-0.5c-0.9,1.3,0.6,1.6,0.6,2.4c0,1,0.2,1.7,0.8,2.4c4.9,0,9.7,0,14.4,0c0.7-1.1,0.5-1.9-0.1-2.7l-0.1,0.1
				c0.2-0.4,0.6-0.8,0.5-1.2c-0.5-2.9,0.3-5.7,0.1-8.5c-0.1-1.7,0.9-2.2,2.4-1.3c1.2,0.7,2.2,1.5,3.4,2.2c1,0.6,2.1,1.1,3.4,1.7
				c0.3,0.7,2.6,2.5,5.1,3.6l-0.1-0.1c3,2,6,4.1,9.1,6.1c0.4,0.2,0.9,0.3,1.4,0.3c3.1,0,6.2,0,9.3,0c0.5,0,0.9-0.1,1.3-0.2
				c0-4.1,0-8.1,0-12.1c-0.4-0.3-0.7-0.6-1.2-0.8c-3.2-1.9-6.4-3.8-9.6-5.6c-0.9-0.5-1.4-1.2-1.3-2.2c0-0.9,0.8-1.3,1.5-1.5
				c0.3-0.1,0.5-0.2,0.8-0.3c3.2-1.5,6.5-3.1,9.7-4.6c0-7.3,0-14.4,0-21.6c-11.8,0-23.3,0-34.8,0c-1.3,1.7-1.5,1.8-2.3,0
				c-1.5,0.1-3.1,0.4-4.6,0.3c-1-0.1-1.5,1.3-2.6,0.6c-0.6,0.7-1.1,1.4-1.7,2c-0.9,1.1-1.8,2.2-2.8,3.3c-0.2-0.5-0.4-0.8-0.5-1
				c-0.4-0.3-1.2,1.1-1.3-0.1c0.8-0.7,1.5-1.1,2-1.8s1.7,0.1,1.8-1.2c-1-0.1-1.1-0.8-1.1-1.6c0-0.1-0.3-0.2-0.4-0.4
				c-0.2,0.4-0.3,0.6-0.5,1c-0.6-1.5-1.3-1.7-2.5-0.7c-0.8,0.7-1.5,1.4-2.3,2c0,3.1,0,6.2,0,9.4c1.7,0,3.4,0.1,5.1,0.1
				c1.7,0.1,3.4,0.1,5.4,0.2c-0.5,0.8-0.9,1.2-1.1,1.7c-0.2,0.6-0.3,1.3-0.2,1.9c0.3,1.3,0.2,1.5-1.5,1.8c-0.1-0.6-0.2-1.1-0.3-1.6
				c-1.1,0.8-2.1,1.5-3.2,2.2c-0.9,0.6-1.5,1.3-1.3,2.6c-0.6,0-1,0-1.4,0c-1.3,0.1-2.1,1.4-1.4,2.3c0.4-0.4,0.8-0.7,1.2-1.1
				c0.9,1.3,0.1,1.9-0.7,2.4c0.9,0.8,2.1,1.2,2,2.7C383.06,398.83,382.26,398.83,382.66,400.13z M386.36,411.13
				c-0.4-0.1-0.7-0.3-0.9-0.2c-0.4,0.1-0.8,0.3-1.1,0.6c0,0,0.1,0.5,0.2,0.5c0.4,0,0.9,0,1.3-0.1
				C386.06,411.83,386.16,411.53,386.36,411.13z M386.26,410.23c-0.1-0.2-0.1-0.4-0.1-0.5c-0.5-0.2-0.9-0.4-1.4-0.5
				c0,0-0.2,0.4-0.3,0.6c0.5,0.2,0.9,0.4,1.4,0.5C385.86,410.43,385.96,410.33,386.26,410.23z M381.46,412.33c0.1,0,0.1,0,0.2,0
				c0-0.9,0-1.7,0-2.6c-0.1,0-0.1,0-0.2,0C381.46,410.53,381.46,411.43,381.46,412.33z M387.86,372.03c0,0.2,0,0.3,0,0.5
				c0.3,0,0.6,0,0.9-0.1v-0.3C388.46,372.13,388.16,372.13,387.86,372.03z M383.96,408.93c-0.2-0.1-0.4-0.3-0.5-0.2
				c-0.1,0.2-0.3,0.4-0.3,0.6c0,0.1,0.3,0.2,0.4,0.3C383.66,409.33,383.76,409.23,383.96,408.93z"/>
			<path class="st0-bull-white" d="M243.16,388.63c-0.9-0.2-1.3-0.4-1.8-0.4c-1.2,0-1.8-0.6-1.9-1.7c-0.1-0.8,0-1.7-1.2-1.9
				c-0.2,0-0.4-0.5-0.4-0.8c-0.1-0.9-0.2-1.8-0.1-2.7c0-3.2,0.1-6.3,0.2-9.7c1.9-0.1,3.6-0.4,5.3-0.4c15.9,0,31.8,0,47.7,0
				c0.7,0,1.4,0,2.1,0c0.1,0.8,0,1.4,0.1,2c0.2,0.6,0.3,1.4,0.8,1.8c0.7,0.6,0.8,1.4,0.8,2.2c0,6.3,0,12.6,0,18.9
				c0,1.4-0.6,2.2-1.8,2.7c-2.3,1.1-4.6,2.2-6.9,3.3c-6.5,3-13.1,5.9-19.6,8.9c-1.8,0.8-3.6,1.8-5.4,2.7c-1.1,0.5-1.6,1.4-1.6,2.6
				s0,2.5,0,3.7c0,1.1,0,2.2,0,3.3c-0.1,1.7-0.7,2.2-2.4,2.2c-1.4,0-2.8,0.1-4.1,0.1c-2.3,0-4.7-0.1-7,0c-1.8,0.1-2.9-0.6-4-2
				c-1.7-2.1-2.3-4.7-3.8-6.9c-0.3-0.4-0.3-1.1-0.3-1.6c0-4.8,0-9.7,0-14.5c0-1.5,0.4-3,0-4.5c-0.5-2.2,0.7-3.5,2.4-4.7
				C241.16,390.63,241.96,389.63,243.16,388.63z M239.26,398.63c-1.3,1.7-0.3,3.6-0.6,5.1c0.7,0.7,1.2,1.2,2,2
				c-0.9,0.1-1.4,0.2-1.9,0.3c-0.5,3.1,0.4,6.2-0.1,9.2c-0.1,0.5,0.3,1,0.5,1.5c0.3,0.6,0.9,1.1,1.2,1.7c1,1.8,2.2,3.3,4.6,2.2
				c0.1,0,0.3,0.1,0.4,0.1c1.6,0.1,3.1,0.1,4.7,0.2c1,0,2.1,0,3.1,0c0.9,0,1.9-0.8,2.9,0c0.1,0,0.8-0.4,0.8-0.7
				c0.1-1.5,0.2-3.1,0-4.5c-0.1-0.6-1.1-1.1-1.8-1.5c-0.2-0.1-0.6,0.1-0.8-0.1c-0.4-0.3-0.7-0.8-1-1.2c0-0.1,0.2-0.5,0.3-0.5
				c0.4,0.1,0.8,0.3,1.2,0.4c0.2,0.1,0.4,0.1,0.6,0.1c0.5-0.3,1.1-0.7,1.6-1c-0.4-1.4-0.1-1.7,1.4-2.6c3.9-2.3,8.2-3.9,12.3-5.8
				c3.3-1.5,6.8-2.7,9.8-4.9c0.2-0.2,0.5-0.2,0.8-0.3c4-1.1,7.3-3.4,11-4.8c0-7.4,0-14.5,0-21.7c-4.9-0.4-24.4-0.2-25.9,0.3
				c0.3,0.5,0.6,1.1,0.9,1.6c0.6,0.4,1.6-0.2,2.2,0.7c-0.9,0.1-1.8,0.4-2.4,0.1c-0.7-0.3-1.2-1.2-1.7-1.8c0.1-0.3,0.3-0.6,0.5-1.1
				c-0.9,0.1-1.7,0-2.2,0.4c-0.7,0.5-1.1,0.5-1.8,0c-0.4-0.3-1-0.3-1.5-0.3c-1,0-2.1,0.1-3.1,0.1c-5.2,0-10.4-0.1-15.5-0.1
				c-0.6,0-1.1,0.2-1.8,0.3c1.7,2.2,3.5,1.9,5.4,0.9c0.5,1.7,1.4,2.3,3,2.2c0.6-0.1,0.9,0.3,1.2,1.3c-1.3-0.3-2.3-0.5-3.6-0.7
				c-0.4,0.5-0.9,1.1-1.4,1.8c-2.1-0.5-3.7,0.9-5.6,1.5c0.1,1.5-0.4,2.9,0.4,4.4c1.8,0.1,3.7,0.2,5.5,0.3c1.2,0,2.3,0.3,3.4,1.1
				c-0.2,0.5-0.3,1.2-0.6,1.5c-1.2,0.9-2,2.1-2.6,3.5c0,0.1-0.2,0.1-0.4,0.1c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1,1-2.2,1.9-3.2,2.9
				c-0.6,0.6-0.6,2.3,0.2,2.6c1.3,0.6,1.5,1.9,3,2.9c-0.9,0.1-1.3,0.1-1.7,0.1c-0.7,0-1.5-0.1-2.2-0.1
				C238.96,397.73,238.96,398.13,239.26,398.63L239.26,398.63z M241.76,374.33c-0.8,0.4-1.5,0.8-2.3,1.2c0.4,0.8,0.7,1.4,1.1,2
				C241.46,376.73,242.56,376.13,241.76,374.33z M238.76,396.53c0.1,0,0.1,0,0.2,0c0-0.7,0-1.4,0-2.1c-0.1,0-0.1,0-0.2,0
				C238.76,395.13,238.76,395.83,238.76,396.53z"/>
			<path class="st0-bull-white" d="M77.46,122.53c0.2,0.1,0.4,0.2,0.6,0.3c-0.2,0.1-0.4,0.4-0.5,0.4c-0.3-0.1-0.5-0.4-0.7-0.6
				C77.06,122.53,77.26,122.53,77.46,122.53z"/>
			<path class="st0-bull-white" d="M226.96,327.73c0.6,0.7,1.2,1.3,1.7,1.9c0,1.2,0,2.2,0,3.2c0.4-0.1,0.7-0.2,1.3-0.4c0.1,1.7,0.3,3.3,0.4,4.9
				c-0.8,0.7-1.4,1.4-2.2,2.1c-0.4-1.5-0.7-2.9-1.1-4.3c0-0.1,0-0.2,0-0.2c1-1.3,0-2.8,0.3-4.2c0.2-0.9-0.4-1.9-0.6-2.9
				C226.76,327.83,226.86,327.83,226.96,327.73z"/>
			<path class="st0-bull-white" d="M228.46,261.23c-0.7-0.6-1-1.4-0.1-2.5c0.3-0.4,0.5-1.5,0.2-1.9c-1-1.2-0.6-2.7-0.9-4
				c-0.2-0.8,0.5-1.6,1.6-2.1c-0.8,1.6-0.6,2.9-0.2,4.5c0.5,1.6,0.4,3.5,0.5,5.2c0.1,1.8,0,3.7,0,5.5c0.1,1.6-0.2,3.1-1.4,4.5
				c-0.3-0.2-0.7-0.3-1.2-0.5c0.2-0.3,0.4-0.5,0.6-0.7c0.7-0.8,1.5-1.4,0.4-2.6c-0.3-0.3-0.2-1.2-0.1-1.8
				C228.06,263.53,228.26,262.53,228.46,261.23z"/>
			<path class="st0-bull-white" d="M83.16,117.13c0.3,0,0.7,0,1.1,0.1c0.1-0.7,0.2-1.3,0.4-2c0.7-0.1,1.3-0.1,1.9-0.2c0-1.6,0.5-2.1,3.9-3
				c0.1,1-0.5,1.9-1.4,2.3c-0.9,0.5-2,0.9-2.7,1.6s-0.9,1.8-1.4,2.9C84.16,118.53,83.76,117.63,83.16,117.13L83.16,117.13z"/>
			<path class="st0-bull-white" d="M88.36,108.23c-0.2,0.3-0.3,0.5-0.5,0.8c-1.3-0.8-1.7,0.5-2.5,0.9c-0.4,0.2-0.9,0.2-1.3,0.2
				c-0.6,0-1.2-0.1-1.8-0.1c-0.2-1.8,2-1.8,2.3-3.2C85.86,107.03,87.26,106.83,88.36,108.23z M86.56,108.43c0-0.2,0-0.4,0-0.6
				c-0.4,0-0.9,0.1-1.3,0.1c0,0.1,0,0.3,0,0.4C85.66,108.33,86.16,108.33,86.56,108.43z"/>
			<path class="st0-bull-white" d="M228.96,346.23c-0.3,0-0.5,0-0.9-0.1c-0.1-0.7,0.6-1.2-0.1-2.1c-0.6-0.8-0.1-2.3-0.1-3.6
				c1.5,0.8,2,1.6,1.6,3.1c-0.2,0.6,0.2,1.3,0.3,2.1C229.66,345.73,229.26,346.03,228.96,346.23
				C228.86,346.23,228.96,346.23,228.96,346.23z"/>
			<path class="st0-bull-white" d="M93.96,111.53c-2.8,0.3-2.8,0.3-4.1-3c0.4,0.4,0.9,0.7,1.3,1.1c0.6,0.6,1.1,0.7,1.8,0.3
				c0.3-0.2,0.7-0.2,1-0.3c0.1,0.4,0.2,0.8,0.3,1.3C94.26,111.03,94.06,111.33,93.96,111.53z"/>
			<path class="st0-bull-white" d="M228.36,243.73c0.5,1.8,0.9,3.1,1.2,4.5c0.1,0.4-0.4,1-0.6,1.5c-0.7-0.3-1.6-1.8-1.5-2.5
				C227.66,246.23,227.96,245.23,228.36,243.73z"/>
			<path class="st0-bull-white" d="M83.86,120.43c-0.1,0.4-0.2,0.7-0.4,1.4c-1.2-1.8-3.5-0.9-5.2-2.7c1.5,0.1,2.7,0.6,3.2-1.2
				c0.6,0.8,1,1.4,1.4,1.8C83.16,120.13,83.56,120.23,83.86,120.43L83.86,120.43z"/>
			<path class="st0-bull-white" d="M401.46,113.93c-0.3,0.9-0.5,1.6-0.7,2.3c-1.3-0.4-2.4-0.6-3.6-1C398.16,113.63,399.56,113.73,401.46,113.93
				z"/>
			<path class="st0-bull-white" d="M100.86,149.83c0-0.7,0-1.3,0-2c0.6-0.3,1-1.6,2.3-1C103.46,149.03,102.86,149.73,100.86,149.83z"/>
			<path class="st0-bull-white" d="M443.86,93.23c0.6-2,0.6-2,2.4-1.7c0.3-0.5,0.6-1,0.9-1.5c0.4,0.3,0.8,0.5,1.1,0.8c-0.5,0.6-1,1.5-1.6,1.9
				C445.96,93.03,444.96,93.03,443.86,93.23z"/>
			<path class="st0-bull-white" d="M219.96,302.03c1.4-1.3,3.3-0.9,5-1.4c0.2-0.1,0.5,0.3,0.7,0.6c-0.9,0.2-1.4,1.1-2.6,0.6
				c-0.4-0.2-1.2,0.6-2,1.1C220.76,302.63,220.26,302.33,219.96,302.03L219.96,302.03z"/>
			<path class="st0-bull-white" d="M69.06,101.33c1.2-0.3,2.1-0.4,3-0.6c0.5,0.6,0.3,1.1-0.2,1.6c-0.3,0.3-0.7,0.8-1.1,0.8
				c-0.5,0-1.1-0.2-1.5-0.5C69.06,102.33,69.16,101.83,69.06,101.33z"/>
			<path class="st0-bull-white" d="M211.26,305.33c-1.7,0.6-3.4,1.2-5.4,2c0.2-0.6,0.2-1.1,0.4-1.3c1.5-1,3.2-1.2,4.8-1.2
				C211.16,304.93,211.26,305.13,211.26,305.33z"/>
			<path class="st0-bull-white" d="M102.86,116.13c2.2,1.6,2.2,1.6,2.7,3.4c-0.8-0.8-1.3-1.3-1.9-1.8c-0.7,0.3-1.5,0.7-2.5,1.2
				C101.76,117.93,102.26,117.13,102.86,116.13z"/>
			<path class="st0-bull-white" d="M402.36,113.83c1.6-0.8,2.9-2.1,5.1-1.5c-0.7,0.9-1.4,1.4-2.4,1.4
				C404.26,113.63,403.46,114.93,402.36,113.83z"/>
			<path class="st0-bull-white" d="M434.96,45.63c0-1.4,1.3-0.7,1.5-1.3c0.9,0.5,1.7,1,2.6,1.6C437.66,46.73,436.36,46.23,434.96,45.63z"/>
			<path class="st0-bull-white" d="M434.76,40.53c0.6-0.9,1-1.6,2.2-1.1c0.6,0.3,1.3,0.3,2.2,0.4c-0.3,0.4-0.5,0.8-0.7,0.8
				C437.26,40.73,436.16,40.63,434.76,40.53z"/>
			<path class="st0-bull-white" d="M224.76,277.03c0.1-2.3-1.4-4.6-0.2-7C225.36,272.33,224.86,274.63,224.76,277.03z"/>
			<path class="st0-bull-white" d="M226.96,302.23c-1-0.8-0.6-1.7-0.5-2.5c0-0.2,1-0.5,1.1-0.4c0.6,0.9,1.2,0.1,1.8,0c0.2,0.9-0.3,1-1,1.2
				C227.56,300.83,226.76,301.13,226.96,302.23z"/>
			<path class="st0-bull-white" d="M450.36,81.73c0.1,0.6,0.2,1.2,0.3,1.9c-0.8-0.1-1.5-0.1-2.4-0.2c0-0.5,0-1,0-1.7
				C448.96,81.73,449.66,81.73,450.36,81.73z"/>
			<path class="st0-bull-white" d="M408.96,119.23c-0.7,1.8-2.1,1.3-3.8,1.1c0.5-0.5,0.7-1,1-1.1c0.9-0.2,1.8-0.3,2.8-0.4
				C408.96,119.03,408.96,119.13,408.96,119.23z"/>
			<path class="st0-bull-white" d="M64.36,113.93c0.8-0.1,1.4-0.1,2.3-0.2c-0.6,1.1-1,1.9-1.6,2.9C63.56,116.13,64.66,114.93,64.36,113.93z"/>
			<path class="st0-bull-white" d="M71.26,110.53c-0.7-0.1-1.4-0.3-2.2-0.4c0.6-0.9,1-1.5,1.4-2.1c0.4,0.2,0.9,0.4,1.5,0.7
				C71.66,109.13,71.56,109.63,71.26,110.53z"/>
			<path class="st0-bull-white" d="M454.46,82.43c-1,2-1,2-2.9,1.6c0.3-0.6,0.6-1.3,0.9-2c0.3,0.1,0.7,0.3,1.1,0.4
				C453.86,82.43,454.16,82.43,454.46,82.43z"/>
			<path class="st0-bull-white" d="M444.46,94.43c0.4,0.9,0.9,1.8-0.2,2.4c-0.9,0.6-1.8,0.3-2.4-0.6c0.6-0.1,1.2-0.2,1.7-0.5
				C443.96,95.53,444.16,94.93,444.46,94.43z"/>
			<path class="st0-bull-white" d="M429.46,39.33c0.4-0.7,0.7-1.4,1.2-2.1c1,0,2.1-0.5,3.3,0.8C431.96,37.63,430.86,38.73,429.46,39.33z"/>
			<path class="st0-bull-white" d="M303.26,157.93c0.8,1.3,1.8,2.1,1.8,3.4C303.16,160.83,302.86,160.33,303.26,157.93z"/>
			<path class="st0-bull-white" d="M427.46,53.13c0.9-1,1.5-1.6,2.5-2.5c0.2,0.8,0.5,1.4,0.3,1.7C429.86,53.43,428.86,53.23,427.46,53.13z"/>
			<path class="st0-bull-white" d="M97.96,139.63c0.1,1.1,0.1,1.8,0.2,2.8c-0.9-0.5-1.5-0.8-2.1-1.1C95.86,140.33,96.76,140.23,97.96,139.63z"
				/>
			<path class="st0-bull-white" d="M75.76,102.53c0,1.6,0,1.6-1.5,2.3c-0.1-0.6-0.5-1.3-0.2-1.6C74.26,102.93,75.06,102.83,75.76,102.53z"/>
			<path class="st0-bull-white" d="M91.36,140.53c0.2-1.2,0.8-1.9,1.7-2c0.6-0.1,1.1,0.4,1.1,1.3C93.26,140.03,92.46,140.23,91.36,140.53z"/>
			<path class="st0-bull-white" d="M219.86,302.03c0,0.1,0.1,0.2,0,0.2c-0.8,0.9-1.8,1.5-3.2,1.7c0.2-1.2,0.7-1.7,1.7-1.7
				C218.86,302.23,219.36,302.13,219.86,302.03C219.96,302.03,219.86,302.03,219.86,302.03z"/>
			<path class="st0-bull-white" d="M99.66,121.23c-0.6-0.5-1.4-1-1.4-1.4c0.2-1.2,1,0,1.5-0.2c0.3-0.1,0.6-0.2,0.9-0.3
				C101.36,120.63,100.36,120.73,99.66,121.23z"/>
			<path class="st0-bull-white" d="M84.06,126.43c-1,0.7-1.6,1.9-3,2.5C81.96,126.13,81.96,126.13,84.06,126.43L84.06,126.43z"/>
			<path class="st0-bull-white" d="M419.96,106.53c0.6-0.8,0.8-2.1,2.4-2C421.96,106.03,421.46,106.53,419.96,106.53z"/>
			<path class="st0-bull-white" d="M291.76,159.83c1.7,0.9-0.1,1.8,0.1,2.7C290.86,161.23,290.86,161.23,291.76,159.83z"/>
			<path class="st0-bull-white" d="M225.16,277.93c0.8,1.5,0.6,2.6,0.2,3.7C225.16,280.53,224.56,279.43,225.16,277.93z"/>
			<path class="st0-bull-white" d="M224.76,247.23c-0.4,0.8-0.7,1.4-1,2.2c-0.4-0.4-0.8-0.7-1.2-1C222.96,247.43,223.66,246.93,224.76,247.23z"
				/>
			<path class="st0-bull-white" d="M455.46,92.33c0.8,1.5,0.8,1.5-1.3,3C454.56,94.33,454.96,93.43,455.46,92.33z"/>
			<path class="st0-bull-white" d="M454.66,92.23c-0.6-0.8-0.9-1.3-1.5-2.1c0.9-0.1,1.5-0.1,2.3-0.2C455.26,90.73,455.06,91.33,454.66,92.23z"
				/>
			<path class="st0-bull-white" d="M197.46,171.23c1.5-0.1,1.8,1.4,2.8,2.2c-0.8-0.3-1.6-0.5-2.5-0.8
				C197.66,172.13,197.66,171.63,197.46,171.23L197.46,171.23z"/>
			<path class="st0-bull-white" d="M455.36,101.93c-1.3-1.9-1.3-2.4,0.1-3C455.66,99.93,456.26,100.83,455.36,101.93z"/>
			<path class="st0-bull-white" d="M404.96,118.13c-0.5,0.1-1,0.1-1.4,0.1c-0.3-1-0.4-1.8,0.9-2.3C404.56,116.73,404.76,117.33,404.96,118.13z"
				/>
			<path class="st0-bull-white" d="M88.26,152.03c-1.1,0.1-1.8,0.2-2.6,0.3C86.16,150.63,86.16,150.63,88.26,152.03z"/>
			<path class="st0-bull-white" d="M413.36,122.73c0.8-1,1.1-1.9,2.1-1.7c0.3,0.1,0.5,0.5,1,1.1C415.26,122.33,414.56,122.53,413.36,122.73z"/>
			<path class="st0-bull-white" d="M444.76,81.53c1,0,1.7,0.1,2.5,0.1c-0.5,0.7-1.6,0.4-1.5,1.4c0,0-0.7,0.1-0.7,0
				C444.96,82.63,444.86,82.23,444.76,81.53z"/>
			<path class="st0-bull-white" d="M441.96,47.93c0.2-0.8,0.4-1.5,0.6-2.3l0,0c0.4,0.2,0.9,0.5,1.7,0.9C443.26,47.03,442.56,47.43,441.96,47.93
				L441.96,47.93z"/>
			<path class="st0-bull-white" d="M402.06,120.63c1-0.2,1.7-0.4,2.8-0.7C403.36,121.73,403.26,121.83,402.06,120.63z"/>
			<path class="st0-bull-white" d="M433.96,46.73c-0.5,0.1-0.8,0.2-1.4,0.4c-0.1-0.8-0.2-1.6-0.2-2.3c0.2,0,0.3-0.1,0.5-0.1
				C433.16,45.33,433.56,45.93,433.96,46.73z"/>
			<path class="st0-bull-white" d="M433.26,86.63c1.3,0.2,1.2,1.2,1.5,2.2C433.66,88.33,433.06,87.83,433.26,86.63z"/>
			<path class="st0-bull-white" d="M113.06,148.63c2.3-0.3,2.3-0.3,2.1,1.3C114.56,149.53,113.96,149.23,113.06,148.63z"/>
			<path class="st0-bull-white" d="M399.86,121.23c-0.7,0.5-0.7,0.5-2.2,0.1C398.46,121.03,398.46,121.03,399.86,121.23z"/>
			<path class="st0-bull-white" d="M435.56,86.03c1.7,0.4,1.7,0.4,2.1,2.1C436.56,87.93,436.06,87.23,435.56,86.03z"/>
			<path class="st0-bull-white" d="M425.96,39.83c0.7,0.4,1.2,0.6,1.8,0.9c-0.2,0.2-0.3,0.5-0.4,0.5c-0.6,0-1.2,0-2,0
				C425.46,40.73,425.66,40.33,425.96,39.83z"/>
			<path class="st0-bull-white" d="M442.06,75.33c0.5,0.7,1,1.3,1.6,2.1C442.66,77.33,442.06,76.73,442.06,75.33z"/>
			<path class="st0-bull-white" d="M100.36,132.63c-1.3,0.4-2.2,0.3-2.7-1C98.66,131.43,99.36,131.93,100.36,132.63z"/>
			<path class="st0-bull-white" d="M227.16,324.33c0.1-1.4,0.7-1.8,1.8-1.8C228.66,323.73,228.66,323.73,227.16,324.33z"/>
			<path class="st0-bull-white" d="M108.96,116.63c-0.3,0.9-0.5,1.5-0.7,2.3c-0.4-0.2-0.8-0.4-0.9-0.6
				C107.06,117.33,107.86,116.93,108.96,116.63z"/>
			<path class="st0-bull-white" d="M208.96,311.13c-0.9,1-1.8,0.9-2.3-0.4C207.36,310.83,208.16,311.03,208.96,311.13z"/>
			<path class="st0-bull-white" d="M94.56,127.03c-0.7,0.9-1.3,1-1.8,0.4c-0.1-0.1,0-0.5,0.1-0.5C93.36,126.93,93.96,127.03,94.56,127.03z"/>
			<path class="st0-bull-white" d="M408.96,119.23c0-0.1,0-0.2-0.1-0.4c0.4-0.2,0.8-0.4,1.2-0.6c0.1,0.1,0.3,0.2,0.4,0.3
				c-0.1,0.4-0.3,0.8-0.4,1.3C409.66,119.63,409.26,119.43,408.96,119.23z"/>
			<path class="st0-bull-white" d="M426.46,75.63c0.8-0.2,1.4-0.4,2.2-0.6C427.96,76.53,427.76,76.63,426.46,75.63z"/>
			<path class="st0-bull-white" d="M221.66,318.93c0,0.6,0,1,0,1.6c-0.6-0.3-1.1-0.5-1.6-0.7C220.26,318.53,220.96,319.03,221.66,318.93z"/>
			<path class="st0-bull-white" d="M409.76,124.43c0.7-0.6,1-0.9,1.4-1.3c0.3,0.4,0.6,0.7,1,1.3C411.36,124.43,410.76,124.43,409.76,124.43z"/>
			<path class="st0-bull-white" d="M78.46,104.43c-0.7-0.1-1.3-0.1-1.9-0.2c-0.2-0.7,0.5-1.2,0.5-1C77.26,104.13,78.76,102.83,78.46,104.43z"/>
			<path class="st0-bull-white" d="M224.56,331.23c-0.7-0.9-1.2-1.6-1.8-2.3c0.1-0.1,0.3-0.2,0.4-0.3
				C223.56,329.43,225.36,329.43,224.56,331.23z"/>
			<path class="st0-bull-white" d="M429.86,47.13c-0.8-0.5-1.3-0.8-1.9-1.1C429.06,45.13,429.36,45.23,429.86,47.13z"/>
			<path class="st0-bull-white" d="M91.86,148.23c-1.9-0.2-1.9-0.2-1.1-1.7C91.06,147.13,91.36,147.63,91.86,148.23z"/>
			<path class="st0-bull-white" d="M425.46,114.03c0.5-0.5,0-1.8,1.3-1.7C426.16,112.83,427.06,114.33,425.46,114.03z"/>
			<path class="st0-bull-white" d="M450.56,93.23c0.2-0.9-0.4-2.6,1.5-2.3C451.56,91.73,451.06,92.43,450.56,93.23z"/>
			<path class="st0-bull-white" d="M461.76,84.83c-0.8,0.2-1.7,0.5-2.5,0.7c-0.1-0.2-0.1-0.3-0.2-0.5c0.8-0.3,1.7-0.5,2.5-0.8
				C461.66,84.53,461.66,84.73,461.76,84.83z"/>
			<path class="st0-bull-white" d="M227.26,306.13c0.1,0.8,0.2,1.5,0.2,2.2C226.36,306.73,226.36,306.73,227.26,306.13z"/>
			<path class="st0-bull-white" d="M232.36,245.33c1.4-0.3,1.4-0.3,1.6,1.4C233.36,246.13,232.96,245.83,232.36,245.33z"/>
			<path class="st0-bull-white" d="M214.96,305.23c-0.2-0.3-0.4-0.6-0.6-1c0,0,0.4-0.3,0.6-0.4c0.1,0.4,0.3,0.8,0.4,1.2
				C215.26,305.13,215.16,305.13,214.96,305.23z"/>
			<path class="st0-bull-white" d="M223.56,338.63c0.3-0.8,0.6-1.3,0.8-1.8c0.6,0.5,1,1.1,0.4,1.7C224.56,338.73,224.16,338.63,223.56,338.63z"
				/>
			<path class="st0-bull-white" d="M86.36,136.23c0.4-0.2,0.9-0.4,1.3-0.7c0.1,0.2,0.2,0.5,0.3,0.7c-0.4,0.2-0.9,0.4-1.3,0.6
				C86.56,136.63,86.46,136.43,86.36,136.23z"/>
			<path class="st0-bull-white" d="M84.06,126.53c-0.1-0.6-0.1-1.1-0.2-1.8c0.4,0,0.7,0,1,0C85.46,125.73,84.76,126.03,84.06,126.53
				C84.06,126.43,84.06,126.53,84.06,126.53z"/>
			<path class="st0-bull-white" d="M416.56,122.53c0.2-0.6,0.4-1,0.6-1.4c0,0,0.6,0.1,0.6,0.2C418.06,122.03,417.66,122.33,416.56,122.53z"/>
			<path class="st0-bull-white" d="M433.56,43.53c-0.6,0-1.1,0-1.6,0C432.86,42.33,432.86,42.33,433.56,43.53z"/>
			<path class="st0-bull-white" d="M78.16,108.83c-0.5,0.6-1,1.6-2.1,0.3C76.96,108.93,77.56,108.93,78.16,108.83z"/>
			<path class="st0-bull-white" d="M434.96,50.23c0.5-1.2,1-1.1,1.7-0.1C435.96,50.13,435.46,50.23,434.96,50.23z"/>
			<path class="st0-bull-white" d="M415.06,109.23c-0.5,1-0.8,1.3-1.4,1c-0.1,0-0.1-0.3-0.1-0.3C413.86,109.63,414.26,109.53,415.06,109.23z"/>
			<path class="st0-bull-white" d="M441.66,49.43c-0.2,0.4-0.3,0.8-0.6,1.4c-0.3-0.4-0.5-0.6-1-1.1C440.86,49.63,441.26,49.53,441.66,49.43
				L441.66,49.43z"/>
			<path class="st0-bull-white" d="M410.16,111.13c0.2,0.3,0.3,0.4,0.5,0.8c-0.5,0.2-0.9,0.4-1.4,0.6c-0.1-0.1-0.2-0.2-0.2-0.3
				C409.46,111.83,409.76,111.53,410.16,111.13z"/>
			<path class="st0-bull-white" d="M91.06,141.13c0.3,0.6,0.5,1,0.7,1.4c-0.2,0.1-0.4,0.2-0.5,0.2C90.56,142.43,90.56,142.03,91.06,141.13z"/>
			<path class="st0-bull-white" d="M86.46,144.93c0.6-0.7,0.8-1,1.2-1.5C88.06,144.53,88.06,145.03,86.46,144.93z"/>
			<path class="st0-bull-white" d="M451.56,105.33c-0.6-0.4-0.6-0.9-0.2-1.3c0.1-0.1,0.5,0,0.5,0.1C452.36,104.63,452.26,105.13,451.56,105.33z
				"/>
			<path class="st0-bull-white" d="M406.16,126.53c-1.3-0.4-1.7-0.8-1.2-2C405.36,125.23,405.66,125.73,406.16,126.53z"/>
			<path class="st0-bull-white" d="M219.66,148.53c0.5,0.5,1,1,1.6,1.5C219.66,150.03,219.66,150.03,219.66,148.53z"/>
			<path class="st0-bull-white" d="M224.56,335.43c-0.8-0.2-1.4-0.4-2.3-0.7C223.36,334.43,224.26,333.73,224.56,335.43z"/>
			<path class="st0-bull-white" d="M453.86,77.73c-0.4-0.3-0.8-0.7-1.5-1.2c1.7-0.7,1.4,0.5,1.9,0.8C454.16,77.43,453.96,77.53,453.86,77.73z"
				/>
			<path class="st0-bull-white" d="M139.26,149.33c0,0.5,0,1.1,0,1.6c-0.1,0-0.2,0-0.3,0c0-0.5,0-1.1,0-1.6
				C139.06,149.33,139.16,149.33,139.26,149.33z"/>
			<path class="st0-bull-white" d="M212.76,311.03c-0.5,0-1,0-1.6,0c0-0.1,0-0.2,0-0.4c0.5,0,1.1-0.1,1.6-0.1
				C212.76,310.73,212.76,310.93,212.76,311.03z"/>
			<path class="st0-bull-white" d="M428.26,67.43c0.7,0.7,1.3,1.2,1.8,1.7c-0.1,0.1-0.2,0.2-0.3,0.3C429.16,69.03,428.16,69.03,428.26,67.43z"
				/>
			<path class="st0-bull-white" d="M451.96,107.13c0.3,0.6,0.5,1,0.6,1.4c-0.2,0.1-0.4,0.2-0.5,0.2C451.46,108.43,451.46,107.93,451.96,107.13z
				"/>
			<path class="st0-bull-white" d="M462.16,91.33c-0.7-0.5-0.5-0.9,0-1.3c0.1-0.1,0.5,0,0.5,0.1C463.16,90.73,462.66,91.03,462.16,91.33z"/>
			<path class="st0-bull-white" d="M407.56,116.03c-0.2,1.5-0.9,1.6-1.9,1.3C406.26,116.93,406.76,116.63,407.56,116.03z"/>
			<path class="st0-bull-white" d="M409.46,116.13c0.6-0.2,1.1-0.4,1.7-0.7c0,0.1,0.1,0.2,0.1,0.3c-0.5,0.3-1,0.6-1.5,0.9
				C409.66,116.43,409.56,116.23,409.46,116.13z"/>
			<path class="st0-bull-white" d="M442.56,80.83c-0.5,0-1,0-1.5,0c0-0.1,0-0.2,0-0.4c0.5,0,1,0,1.5-0.1
				C442.56,80.53,442.56,80.73,442.56,80.83z"/>
			<path class="st0-bull-white" d="M431.46,68.93c0.5-0.2,1-0.5,1.6-0.7C432.86,69.23,432.86,69.23,431.46,68.93z"/>
			<path class="st0-bull-white" d="M231.36,213.03c-0.3-0.3-0.6-0.6-0.9-0.9c0.1-0.1,0.2-0.2,0.3-0.4c0.3,0.3,0.7,0.6,1,0.8
				C231.66,212.73,231.56,212.93,231.36,213.03z"/>
			<path class="st0-bull-white" d="M444.86,43.73c-0.1,0.4-0.1,0.8-0.3,1.1c-0.1,0.2-0.4,0.2-0.6,0.3c-0.1-0.2-0.2-0.6-0.2-0.7
				C444.16,44.23,444.56,44.03,444.86,43.73C444.96,43.73,444.86,43.73,444.86,43.73z"/>
			<path class="st0-bull-white" d="M70.66,96.93c0.7-0.4,1.1-0.5,1.5-0.7C72.06,97.03,72.06,97.03,70.66,96.93z"/>
			<path class="st0-bull-white" d="M419.36,119.33c-0.4,0.2-0.8,0.4-1.2,0.5c0-0.2-0.2-0.5-0.1-0.5c0.4-0.2,0.7-0.3,1.1-0.5
				C419.26,119.03,419.26,119.13,419.36,119.33z"/>
			<path class="st0-bull-white" d="M227.56,311.73c-0.3,0-0.6,0-1,0c0.2-0.7,0.5-1.2,0.7-1.8c0.1,0,0.2,0,0.3,0
				C227.56,310.53,227.56,311.03,227.56,311.73z"/>
			<path class="st0-bull-white" d="M137.06,153.03c1,0,1.7,0.2,1.6,1.6C137.96,153.93,137.56,153.53,137.06,153.03z"/>
			<path class="st0-bull-white" d="M83.96,120.53c0-0.3-0.1-0.6,0.1-0.8c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.4,0.3,0.4,0.4c0,0.2-0.1,0.5-0.3,0.6
				C84.46,120.53,84.16,120.43,83.96,120.53C83.86,120.43,83.96,120.53,83.96,120.53z"/>
			<path class="st0-bull-white" d="M228.26,149.83c-0.4-0.3-0.9-0.6-1.6-1.2c1.4-0.4,1.4,0.5,1.9,0.8
				C228.46,149.63,228.36,149.73,228.26,149.83z"/>
			<path class="st0-bull-white" d="M422.86,73.23c0.4,0.1,0.8,0.2,1.3,0.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.4-0.2-0.8-0.3-1.2-0.5
				C422.66,73.53,422.76,73.43,422.86,73.23z"/>
			<path class="st0-bull-white" d="M88.86,121.03c0.2,0,0.5,0,0.6,0.1c0.3,0.2,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.4-0.3-0.8-0.6-1.2-0.9C88.66,121.33,88.76,121.13,88.86,121.03z"/>
			<path class="st0-bull-white" d="M432.46,77.73c0.2-0.7,0.3-1.1,0.5-1.5c0.5,0.2,1,0.3,1.5,0.5C433.76,77.03,433.26,77.33,432.46,77.73z"/>
			<path class="st0-bull-white" d="M81.56,123.63c0.4-0.3,0.7-0.5,1-0.8c0.2,0.6,0.3,1,0.5,2C82.36,124.23,82.06,124.03,81.56,123.63z"/>
			<path class="st0-bull-white" d="M201.76,301.73c-0.2,0.4-0.4,0.7-0.6,1.1l-0.5-0.2c0.1-0.4,0.3-0.8,0.5-1.2
				C201.46,301.43,201.56,301.63,201.76,301.73z"/>
			<path class="st0-bull-white" d="M234.96,223.83c-0.7,0.7-1.5,0.7-2.6,0.5C233.36,223.53,234.16,223.73,234.96,223.83L234.96,223.83z"/>
			<path class="st0-bull-white" d="M428.86,59.13c-1.3-0.5-1.4-1-0.6-2C428.46,57.83,428.66,58.33,428.86,59.13z"/>
			<path class="st0-bull-white" d="M215.06,165.63c0.9-0.4,1.4-0.5,1.5,0.3c0,0.2-0.1,0.3-0.2,0.5C215.96,166.23,215.56,165.93,215.06,165.63z"
				/>
			<path class="st0-bull-white" d="M98.16,127.73c-0.4,0.5-0.8,1-1.2,1.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.8,1-0.9,1.2-1.6
				C97.86,127.53,98.06,127.63,98.16,127.73z"/>
			<path class="st0-bull-white" d="M469.06,80.03c0.1,0.2,0.2,0.4,0.2,0.5c-0.1,0.3-0.3,0.6-0.5,0.9c0,0.1-0.4-0.1-0.5-0.2
				c0.1-0.3,0.2-0.7,0.4-1C468.76,80.23,468.96,80.23,469.06,80.03C469.16,80.13,469.06,80.03,469.06,80.03z"/>
			<path class="st0-bull-white" d="M197.56,171.13c-0.6-0.3-1.4-0.5-1.3-1.6C197.26,169.83,197.76,170.23,197.56,171.13
				C197.46,171.23,197.56,171.13,197.56,171.13z"/>
			<path class="st0-bull-white" d="M438.86,49.93c-0.2,0.8-0.8,1-1.8,0.7c0.6-0.4,1-0.8,1.5-1.1C438.66,49.73,438.76,49.83,438.86,49.93z"/>
			<path class="st0-bull-white" d="M422.16,111.93c-0.1-0.9,0.4-1.3,1.4-1.8C423.36,111.13,422.76,111.53,422.16,111.93L422.16,111.93z"/>
			<path class="st0-bull-white" d="M426.16,100.93c0.4,1.2,0.4,1.7-0.8,1.7C425.66,102.13,425.86,101.73,426.16,100.93z"/>
			<path class="st0-bull-white" d="M94.96,136.93c0.2-1.1,0.4-1.5,1.1-1.3c0.1,0,0.3,0.6,0.2,0.7C96.06,136.53,95.66,136.63,94.96,136.93z"/>
			<path class="st0-bull-white" d="M80.56,105.83c-0.8,0.1-1.8,0.7-2.1-0.9C79.26,105.23,79.96,105.53,80.56,105.83z"/>
			<path class="st0-bull-white" d="M228.56,135.13c-0.4,0.2-0.9,0.5-1.4,0.8c0-0.2-0.1-0.6,0-0.7c0.4-0.2,0.9-0.4,1.3-0.5
				C228.46,134.93,228.46,135.03,228.56,135.13z"/>
			<path class="st0-bull-white" d="M222.46,228.23c0.2-0.5,0.4-1.1,0.6-1.6c0.1,0,0.2,0.1,0.4,0.1c-0.2,0.6-0.3,1.2-0.5,1.8
				C222.76,228.43,222.66,228.33,222.46,228.23z"/>
			<path class="st0-bull-white" d="M145.56,138.73c-0.3,0.4-0.5,0.8-0.9,1.5c-0.1-0.9-0.3-1.5,0.5-1.8
				C145.26,138.53,145.46,138.63,145.56,138.73z"/>
			<path class="st0-bull-white" d="M221.96,316.63c-0.2-0.3-0.4-0.6-0.6-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.2,0.5,0.5,0.8,0.7
				C222.26,316.43,222.16,316.53,221.96,316.63z"/>
			<path class="st0-bull-white" d="M203.56,255.13c0.9-1,1.4-0.7,2.2,0.2C204.96,255.23,204.46,255.23,203.56,255.13z"/>
			<path class="st0-bull-white" d="M446.36,77.63c0.3-0.2,0.7-0.4,1-0.5l0.2,0.3c-0.3,0.2-0.6,0.5-0.9,0.7
				C446.56,78.03,446.46,77.83,446.36,77.63z"/>
			<path class="st0-bull-white" d="M432.66,50.93c0,0.6,0.3,1.3-0.2,1.7c-0.2-0.1-0.3-0.1-0.5-0.2c0.1-0.2,0.3-0.4,0.4-0.6
				C432.46,51.53,432.56,51.23,432.66,50.93z"/>
			<path class="st0-bull-white" d="M234.96,223.93c0.5-0.9,0.5-0.9,1.5-0.4C235.86,223.73,235.46,223.73,234.96,223.93
				C234.96,223.83,234.96,223.93,234.96,223.93z"/>
			<path class="st0-bull-white" d="M430.96,58.53c0.2,0.3,0.5,0.5,0.5,0.6c0,0.2-0.2,0.4-0.4,0.6c-0.2-0.2-0.5-0.4-0.7-0.6
				C430.56,59.03,430.76,58.83,430.96,58.53z"/>
			<path class="st0-bull-white" d="M445.46,60.63c0-0.4,0-0.8,0-1.1c0.1,0,0.2,0,0.3,0c0,0.4,0.1,0.8,0.1,1.1
				C445.76,60.63,445.66,60.63,445.46,60.63z"/>
			<path class="st0-bull-white" d="M436.66,99.83c-0.7,1.3-1.1,1-1.6,0.1C435.66,99.93,436.06,99.93,436.66,99.83z"/>
			<path class="st0-bull-white" d="M228.16,272.53c-0.1-0.4-0.1-0.8-0.2-1.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0.4,0.2,0.8,0.4,1.2
				C228.56,272.43,228.36,272.43,228.16,272.53z"/>
			<path class="st0-bull-white" d="M429.66,97.93c0.3-0.4,0.6-0.8,0.9-1.2c0.1,0,0.2,0.1,0.3,0.1c0,0.3,0,0.6-0.1,0.8c-0.2,0.2-0.5,0.4-0.8,0.5
				C429.86,98.03,429.76,98.03,429.66,97.93z"/>
			<path class="st0-bull-white" d="M456.66,96.33c0,0.4,0,0.8-0.1,1.3c-0.1,0-0.2,0-0.3,0c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.2,0.2-0.4,0.3-0.6
				C456.46,96.23,456.56,96.23,456.66,96.33z"/>
			<path class="st0-bull-white" d="M144.36,152.33c0.5,0.1,1,0.3,1.6,0.4c-0.1,0.2-0.1,0.4-0.2,0.5c-0.5-0.2-1-0.3-1.6-0.5
				C144.26,152.73,144.26,152.53,144.36,152.33z"/>
			<path class="st0-bull-white" d="M435.56,63.83c0.7,0.2,1.1,0.4,1.7,0.6C436.36,64.93,435.96,64.93,435.56,63.83z"/>
			<path class="st0-bull-white" d="M213.46,304.03c0,0.4,0.1,0.9,0.1,1.3c-0.1,0-0.3,0-0.4,0.1c-0.1-0.4-0.2-0.9-0.3-1.3
				C213.06,304.03,213.26,304.03,213.46,304.03z"/>
			<path class="st0-bull-white" d="M451.06,80.53c1.1-0.2,1.7-0.3,1.6,0.9C452.16,81.13,451.86,80.93,451.06,80.53z"/>
			<path class="st0-bull-white" d="M422.16,111.83c-0.4,0.8-1,1.1-1.7,0C420.96,111.93,421.56,111.93,422.16,111.83
				C422.16,111.93,422.16,111.83,422.16,111.83z"/>
			<path class="st0-bull-white" d="M416.76,113.23c-0.8-0.4-1.3-0.7-1.7-0.9C415.76,112.03,416.46,111.93,416.76,113.23z"/>
			<path class="st0-bull-white" d="M406.26,104.83c-0.2,0.5-0.3,1.1-0.5,1.6c-0.1,0-0.2,0-0.4-0.1c0.1-0.6,0.2-1.1,0.3-1.7
				C405.86,104.73,406.06,104.83,406.26,104.83z"/>
			<path class="st0-bull-white" d="M232.26,299.33c-0.4,0.1-0.7,0.3-1.1,0.4l-0.2-0.3c0.3-0.2,0.6-0.5,1-0.7
				C232.06,299.03,232.16,299.13,232.26,299.33z"/>
			<path class="st0-bull-white" d="M429.86,36.23c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.8-0.5,1.2-0.8
				C429.66,35.93,429.76,36.03,429.86,36.23z"/>
			<path class="st0-bull-white" d="M450.16,73.23c0.3,0.2,0.5,0.4,0.7,0.6c0,0.1-0.1,0.3-0.2,0.4c-0.2-0.1-0.5-0.2-0.6-0.4
				C449.96,73.83,450.06,73.53,450.16,73.23z"/>
			<path class="st0-bull-white" d="M443.86,70.93c-0.3,0-0.7,0-1,0c0-0.1,0-0.2,0-0.3c0.4-0.1,0.7-0.1,1.1-0.2
				C443.86,70.63,443.86,70.73,443.86,70.93z"/>
			<path class="st0-bull-white" d="M192.46,157.03c0,0.4-0.1,0.7-0.1,1.1c-0.1,0-0.2,0-0.4,0c0-0.4,0.1-0.7,0.1-1.1
				C192.16,157.03,192.36,157.03,192.46,157.03z"/>
			<path class="st0-bull-white" d="M399.76,99.93c0.1,0.1,0.3,0.3,0.3,0.5c0,0.3,0,0.6-0.4,1c-0.1-0.5-0.3-0.9-0.4-1.4
				C399.36,100.03,399.56,99.93,399.76,99.93z"/>
			<path class="st0-bull-white" d="M456.56,76.73c-0.2,0.4-0.4,0.8-0.7,1.2c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.4,0.3-0.9,0.4-1.3
				C456.16,76.53,456.36,76.63,456.56,76.73z"/>
			<path class="st0-bull-white" d="M457.36,81.93c0.2,0.3,0.4,0.6,0.6,0.9c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2-0.3-0.5-0.5-0.7-0.8
				C456.96,82.33,457.16,82.13,457.36,81.93z"/>
			<path class="st0-bull-white" d="M144.16,149.03c-0.3-0.2-0.6-0.4-0.9-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.3,0.2,0.6,0.4,0.8,0.6
				C144.36,148.83,144.26,148.93,144.16,149.03z"/>
			<path class="st0-bull-white" d="M138.26,141.73c-0.3,0.3-0.5,0.5-0.7,0.5s-0.4-0.3-0.4-0.5s0.3-0.5,0.4-0.5
				C137.76,141.23,137.96,141.53,138.26,141.73z"/>
			<path class="st0-bull-white" d="M227.66,319.73c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.2,0.3-0.4,0.5-0.5c0.1,0,0.4,0.2,0.4,0.3
				c0,0.2-0.1,0.4-0.2,0.6C228.06,319.53,227.86,319.63,227.66,319.73z"/>
			<path class="st0-bull-white" d="M438.76,98.33c0.3,0,0.7,0,1,0c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.7,0.1-1,0.2
				C438.86,98.63,438.86,98.43,438.76,98.33z"/>
			<path class="st0-bull-white" d="M415.56,87.43c0.1,0.4,0.1,0.8,0.3,1.4C414.76,88.43,415.06,87.93,415.56,87.43z"/>
			<path class="st0-bull-white" d="M90.16,106.33c-0.3,0.2-0.5,0.4-0.7,0.5c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.2,0.3-0.4,0.5-0.5
				C89.66,106.03,89.86,106.23,90.16,106.33z"/>
			<path class="st0-bull-white" d="M76.26,107.73c0.5-0.4,0.9-0.7,1.2-0.9c0.2,0.2,0.3,0.3,0.3,0.3C77.46,107.53,77.26,108.13,76.26,107.73z"/>
			<path class="st0-bull-white" d="M428.46,49.13c0.5-0.2,1-0.3,1.6-0.5c0,0.1,0.1,0.3,0.1,0.4c-0.5,0.2-1,0.4-1.5,0.5
				C428.56,49.43,428.56,49.33,428.46,49.13z"/>
			<path class="st0-bull-white" d="M100.16,125.93c-0.3,0.4-0.7,0.8-1,1.2c-0.1-0.1-0.2-0.1-0.3-0.2c0.3-0.4,0.6-0.9,0.9-1.3
				C99.86,125.63,99.96,125.73,100.16,125.93z"/>
			<path class="st0-bull-white" d="M105.16,124.33c-0.4,0.3-0.5,0.5-0.7,0.6c-0.1-0.2-0.3-0.3-0.4-0.6c0-0.1,0.2-0.4,0.3-0.4
				C104.56,123.93,104.76,124.13,105.16,124.33z"/>
			<path class="st0-bull-white" d="M85.26,122.83c-0.4,0.2-0.6,0.3-0.8,0.4c0,0-0.3-0.3-0.2-0.4c0.1-0.2,0.3-0.3,0.5-0.3
				C84.76,122.43,84.96,122.63,85.26,122.83z"/>
			<path class="st0-bull-white" d="M88.06,122.53c-0.5,0-0.9,0-1.5,0C87.46,121.63,87.46,121.63,88.06,122.53
				C88.06,122.63,88.06,122.53,88.06,122.53z"/>
			<path class="st0-bull-white" d="M410.26,121.63c-0.3-0.4-0.5-0.6-0.8-1c0.5,0,0.9,0,1.3,0.1C410.56,120.93,410.46,121.13,410.26,121.63z"/>
			<path class="st0-bull-white" d="M229.66,307.93c0.5,0.2,0.8,0.4,1.2,0.6c-0.4,0.3-0.6,0.5-1.1,0.9
				C229.66,308.83,229.66,308.43,229.66,307.93z"/>
			<path class="st0-bull-white" d="M411.26,117.93c0.3-0.2,0.6-0.5,0.9-0.7c0.1,0.1,0.2,0.2,0.2,0.3c-0.3,0.3-0.5,0.6-0.8,0.9
				C411.56,118.23,411.36,118.03,411.26,117.93z"/>
			<path class="st0-bull-white" d="M415.96,115.93c-0.4,0.2-0.7,0.5-1.1,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.3-0.3,0.6-0.5,1-0.8
				C415.66,115.63,415.86,115.83,415.96,115.93z"/>
			<path class="st0-bull-white" d="M420.96,114.23c0.1,0.4,0.1,0.8,0.2,1.1l-0.3,0.1c-0.2-0.3-0.3-0.7-0.5-1.1
				C420.46,114.43,420.66,114.33,420.96,114.23z"/>
			<path class="st0-bull-white" d="M103.66,113.63c-0.2,0.2-0.4,0.3-0.7,0.7c-0.3-0.4-0.5-0.7-1-1.4C102.76,113.23,103.16,113.43,103.66,113.63
				L103.66,113.63z"/>
			<path class="st0-bull-white" d="M225.16,285.83c0-0.4,0-0.7-0.1-1.1c0.1,0,0.3,0,0.4,0c0,0.4,0,0.7,0.1,1.1
				C225.46,285.83,225.36,285.83,225.16,285.83z"/>
			<path class="st0-bull-white" d="M226.16,290.23c-0.8-0.5-0.8-0.5,0-1.3C226.16,289.43,226.16,289.83,226.16,290.23z"/>
			<path class="st0-bull-white" d="M239.56,297.33c-0.3,0.3-0.6,0.5-1.2,1C238.46,297.23,238.96,297.23,239.56,297.33z"/>
			<path class="st0-bull-white" d="M206.36,336.43c0.2,0.5,0.4,0.8,0.3,1c0,0.2-0.3,0.3-0.5,0.4c-0.1-0.2-0.3-0.3-0.3-0.5
				C205.96,337.13,206.06,336.93,206.36,336.43z"/>
			<path class="st0-bull-white" d="M304.36,168.93c0.3-1,0.7-0.7,1.3,0C305.06,168.93,304.76,168.93,304.36,168.93z"/>
			<path class="st0-bull-white" d="M209.76,162.03c0.3,0.2,0.6,0.3,0.9,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.3-0.1-0.6-0.2-1-0.3
				C209.66,162.33,209.76,162.13,209.76,162.03z"/>
			<path class="st0-bull-white" d="M207.66,161.23c-0.5-0.1-1-0.2-1.5-0.3c0-0.2,0.1-0.3,0.1-0.5c0.5,0.1,1,0.2,1.5,0.3
				C207.76,160.93,207.66,161.13,207.66,161.23z"/>
			<path class="st0-bull-white" d="M114.06,158.23c-0.3-0.1-0.6-0.1-0.9-0.2c0,0,0-0.2,0-0.3c0.3,0.1,0.6,0.1,0.9,0.2
				C114.06,158.03,114.06,158.13,114.06,158.23z"/>
			<path class="st0-bull-white" d="M255.26,156.63c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2-0.1-0.4-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.4
				C254.96,156.33,255.06,156.53,255.26,156.63z"/>
			<path class="st0-bull-white" d="M98.46,156.33c0.3,0.2,0.5,0.3,0.7,0.5c-0.1,0.1-0.2,0.4-0.3,0.4c-0.2-0.1-0.4-0.2-0.6-0.3
				C98.16,156.83,98.36,156.63,98.46,156.33z"/>
			<path class="st0-bull-white" d="M232.66,154.23c0.3,0.1,0.7,0.1,1,0.2c0,0.1,0,0.4-0.1,0.4c-0.3,0-0.6,0-1,0
				C232.66,154.53,232.66,154.43,232.66,154.23z"/>
			<path class="st0-bull-white" d="M192.56,155.13c-0.3-0.3-0.4-0.5-0.6-0.7c0.2-0.1,0.4-0.3,0.6-0.3c0.1,0,0.4,0.3,0.4,0.3
				C192.96,154.63,192.76,154.83,192.56,155.13z"/>
			<path class="st0-bull-white" d="M194.66,154.63c0.5-0.6,0.7-0.8,1.1-1.1c0.2,0.3,0.4,0.5,0.7,0.9C195.86,154.43,195.56,154.53,194.66,154.63
				z"/>
			<path class="st0-bull-white" d="M231.06,248.23c-0.2-0.2-0.4-0.3-0.5-0.4c0.1-0.2,0.3-0.4,0.5-0.5c0.1,0,0.4,0.2,0.4,0.2
				C231.36,247.73,231.26,247.93,231.06,248.23z"/>
			<path class="st0-bull-white" d="M216.36,253.43c0,0.3,0.1,0.6,0.1,0.9c-0.1,0-0.2,0-0.3,0.1c-0.1-0.3-0.1-0.6-0.2-0.9
				C216.06,253.53,216.26,253.43,216.36,253.43z"/>
			<path class="st0-bull-white" d="M173.76,257.33c0-0.3,0-0.6,0-0.9c0.1,0,0.2,0,0.3,0c0,0.3,0.1,0.6,0.1,0.9
				C174.06,257.23,173.96,257.23,173.76,257.33z"/>
			<path class="st0-bull-white" d="M229.46,137.33c0,0.4,0,0.8,0,1.2C228.16,138.13,229.46,137.73,229.46,137.33z"/>
			<path class="st0-bull-white" d="M100.46,137.13c-0.3,0.1-0.4,0.2-0.6,0.3c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.1,0.3-0.2,0.4-0.3
				C100.26,136.73,100.36,136.93,100.46,137.13z"/>
			<path class="st0-bull-white" d="M195.56,259.33c0.9,1,0.4,1.5,0,2C195.56,260.83,195.56,260.23,195.56,259.33z"/>
			<path class="st0-bull-white" d="M450.66,88.03c-0.1-0.3-0.3-0.6-0.4-0.9c0.1,0,0.3-0.1,0.3-0.1c0.2,0.3,0.3,0.6,0.5,0.8
				C450.96,87.93,450.86,88.03,450.66,88.03z"/>
			<path class="st0-bull-white" d="M103.96,132.93c-0.6-0.5-0.9-0.7-1.2-1c0.4-0.3,0.6-0.4,1-0.7C103.76,131.73,103.86,132.13,103.96,132.93z"
				/>
			<path class="st0-bull-white" d="M117.06,129.43c-0.3,0.2-0.5,0.4-0.7,0.4c-0.1,0-0.3-0.3-0.4-0.4c0.2-0.2,0.3-0.4,0.5-0.5
				C116.66,128.93,116.86,129.23,117.06,129.43z"/>
			<path class="st0-bull-white" d="M441.96,47.83c-0.1,0.5-0.2,1-0.2,1.6l0,0c-0.2-0.4-0.4-0.8-0.6-1.2C441.36,48.13,441.66,48.03,441.96,47.83
				C441.96,47.93,441.96,47.83,441.96,47.83z"/>
			<path class="st0-bull-white" d="M448.16,87.93c-0.1,0.3-0.3,0.6-0.4,0.9c-0.2-0.1-0.3-0.2-0.5-0.2c0.2-0.3,0.4-0.5,0.6-0.8
				C448.06,87.83,448.06,87.93,448.16,87.93z"/>
			<path class="st0-bull-white" d="M420.56,122.73c0.3,0,0.6,0,0.9,0c0,0.1,0,0.2,0,0.3c-0.3,0-0.6,0-0.9,0
				C420.56,122.93,420.56,122.83,420.56,122.73z"/>
			<path class="st0-bull-white" d="M412.96,123.73c-0.1-0.3-0.3-0.6-0.4-0.9c0.1,0,0.3-0.1,0.4-0.1c0.2,0.3,0.3,0.6,0.5,0.9
				C413.26,123.63,413.06,123.63,412.96,123.73z"/>
			<path class="st0-bull-white" d="M83.16,117.13c-0.4,0.1-0.8,0.1-1.5,0.2c0.3-0.5,0.5-0.8,0.8-1.2C82.76,116.53,82.96,116.83,83.16,117.13
				L83.16,117.13z"/>
			<path class="st0-bull-white" d="M425.46,44.73c0.3,0.3,0.5,0.5,0.9,1c-0.6,0.1-0.9,0.1-1.5,0.1C425.16,45.43,425.26,45.13,425.46,44.73z"/>
			<path class="st0-bull-white" d="M419.16,113.83c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.2,0.2-0.4,0.3-0.5s0.3-0.1,0.5-0.1
				C419.06,113.63,419.06,113.73,419.16,113.83z"/>
			<path class="st0-bull-white" d="M226.96,298.53c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1-0.2-0.1-0.4-0.2-0.7c0.1,0,0.3-0.1,0.3-0.1
				C226.86,298.13,226.86,298.33,226.96,298.53z"/>
			<path class="st0-bull-white" d="M54.26,108.23c-0.3,0-0.6,0-1,0c0.1-0.5,0.2-0.9,0.2-1.4c-0.3-0.1-0.6-0.2-0.7-0.1c-1.9,1.7-4.1,1.7-6.4,1.6
				c-1.3-0.1-2.6,0-3.9,0c0-0.2,0-0.3,0-0.5c0.5,0,1.1-0.2,1.6-0.1c1.3,0.1,2.4-0.4,3.5-0.9c0.3-0.1,0.5-0.4,0.7-0.6
				c0.3-0.4,0.5-1,0.8-1.1c2.4-1,3.2-3.5,5.1-5.4c0.2,0.4,0.6,0.8,0.5,1.1c-0.1,0.6-0.5,1.1-0.8,1.8c0.1,0.1,0.4,0.5,0.5,1
				s-0.3,1.1-0.2,1.5c0.1,0.5,0.8,0.7,0.3,1.4C54.26,106.93,54.36,107.53,54.26,108.23z M50.26,106.23c1-0.8,1.8-1.4,2.8-2.2
				C51.76,104.43,50.66,104.53,50.26,106.23z"/>
			<path class="st0-bull-white" d="M51.46,96.73c-0.1-0.3-0.2-0.7-0.2-0.8c-1.1-0.6-2-1.1-3-1.5c-0.3-0.1-0.7-0.3-1-0.2
				c-1.5,0.2-2.7-0.4-3.4-1.3c-1.8,0-3.3,0-4.7,0c0.4-0.9,0.8-1.9,1.3-3.2c0.3,0.6,0.5,1.1,0.7,1.7c1.5-0.2,2.8,1.1,4.4,0.9
				c0.8,1.5,2.8,0.2,3.8,1.5c0.1,0.1,0.9-0.3,1.4-0.4c0.4,0.3,0.8,0.8,1.3,0.9c2,0.5,4.1,0.9,6.1,1.3c0.9,0.2,1.4,0.8,1,1.9
				c-0.9,0.2-1.6,0.6-2.3-0.7c-0.4-0.7-1.7-1.2-2.6-1.2S52.46,96.33,51.46,96.73z"/>
			<path class="st0-bull-white" d="M60.16,86.13c0.3,0.2,0.7,0.4,0.8,0.7c0.4,1.5,0.3,3-0.7,4.4c1.2,0.8,2.2,0.1,3.2-0.2
				c-0.1,1-0.2,1.9-0.3,2.8c-1,0.6-2,1.2-3.3,1.9c0-2.3,0-4.5,0-6.8c-0.9-0.1-1.6-0.1-2.8-0.2C58.76,88.03,60.46,87.93,60.16,86.13
				C60.26,86.03,60.16,86.13,60.16,86.13z"/>
			<path class="st0-bull-white" d="M23.56,80.03c-0.8,0-1.2,1.7-2.3,0.1c-0.3,0.7-0.4,1.2-0.6,1.8c-1.9-1.2-0.6-3-1-4.4
				c1.5,0.3,2.9,0.6,4.4,0.9c-0.5,0.2-0.8,0.3-1.4,0.5C22.96,79.33,23.26,79.73,23.56,80.03C23.56,80.13,23.56,80.03,23.56,80.03z"
				/>
			<path class="st0-bull-white" d="M19.26,93.43c0-1.1,0-2.2,0-3.2c-0.2,0-0.4-0.1-0.4-0.1c-0.4,0.6-0.9,1-1.4,0.3c-0.2-0.3,0.2-1.3,0.3-1.4
				c1.3-0.1,1.3-1.4,2-2.2c1,1,0.6,2.1,0.5,3.1C20.06,91.13,19.66,92.13,19.26,93.43z"/>
			<path class="st0-bull-white" d="M36.06,110.93c-0.7-0.6-1.4-1.1-2-1.6c0.1-0.1,0.1-0.2,0.2-0.4c1.8,0.4,3.6-0.8,5.4,0.1
				C38.36,109.73,37.16,110.43,36.06,110.93z"/>
			<path class="st0-bull-white" d="M55.56,84.73c-1.3,0.2-1.9,1.4-3,2.1c-0.6,0.3-1,0.9-1.4,1.5c-0.6,1-1.1,0.7-1.7,0.1
				c1.2-1.1,2.3-2.2,3.4-3.2C53.76,84.43,54.46,84.13,55.56,84.73z"/>
			<path class="st0-bull-white" d="M31.16,92.33c-0.7,0.4-1.4,0.8-2.5,1.4c0.5-1.7,1-3,1.4-4.4c0.6,0.6,0.9,0.9,1.4,1.5
				C31.46,91.03,31.26,91.73,31.16,92.33z"/>
			<path class="st0-bull-white" d="M52.16,110.03c0.3,0.4,0.5,0.8,1,1.7c-0.9-0.2-1.3-0.3-1.8-0.4c-0.1,0.4-0.3,0.8-0.4,1.3c-0.1,0-0.2,0-0.3,0
				c-0.1-0.6-0.4-1.3-0.3-1.9C50.46,109.33,51.06,109.23,52.16,110.03L52.16,110.03z"/>
			<path class="st0-bull-white" d="M55.66,101.53c0.1-1.1,0.1-1.9,0.2-2.8c0-0.2,0.3-0.4,0.5-0.5c0.1,0,0.5,0.2,0.5,0.2c0,0.9-0.1,1.7-0.2,2.6
				C56.66,101.23,56.16,101.33,55.66,101.53z"/>
			<path class="st0-bull-white" d="M28.96,77.13c0.3,0.9,0.6,1.8,0.9,2.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.7-0.7-1.3-1.3-2-2
				C27.76,77.83,28.46,77.43,28.96,77.13z"/>
			<path class="st0-bull-white" d="M37.16,104.13c1.3,1.6,1.4,1.7,0.7,3.3c-0.1,0-0.3,0-0.4-0.1c-0.3-0.3-0.6-0.6-0.9-0.8
				C36.86,105.73,37.06,104.93,37.16,104.13z"/>
			<path class="st0-bull-white" d="M12.66,79.33c0.2,1.7,0.1,1.8-2.7,1.2C11.06,80.13,11.76,79.73,12.66,79.33z"/>
			<path class="st0-bull-white" d="M14.76,97.33c-1,0.8-1.6,1.4-2.3,2.1C11.56,97.93,12.16,97.33,14.76,97.33z"/>
			<path class="st0-bull-white" d="M46.86,101.23c1.2-0.3,1.4-1.7,2.8-1.4c-0.3,0.4-0.5,0.8-0.8,1.2c-0.3,0.4-0.5,0.7-1,1.4
				C47.46,101.93,47.16,101.63,46.86,101.23z"/>
			<path class="st0-bull-white" d="M29.86,106.03c0.3-0.2,0.6-0.4,0.9-0.6c0.4-0.3,0.7-0.5,1.1-0.8c0.1,0.1,0.2,0.3,0.3,0.4
				c-0.2,0.2-0.4,0.4-0.8,0.7c0.3,0.2,0.5,0.4,0.9,0.6c-0.5,0.2-0.9,0.5-1.3,0.6C30.06,107.23,30.16,106.43,29.86,106.03
				C29.96,106.03,29.86,106.03,29.86,106.03z"/>
			<path class="st0-bull-white" d="M31.76,79.73c1-1.8,1.3-1.9,2.8-0.6C33.56,79.23,32.76,79.43,31.76,79.73z"/>
			<path class="st0-bull-white" d="M6.46,81.73c0.9,1.1,0.9,1.1-0.8,2.5C5.26,83.23,5.26,83.23,6.46,81.73z"/>
			<path class="st0-bull-white" d="M31.06,86.03c0.7,2.1,0.6,2.2-1.8,2.1C29.86,87.43,30.36,86.83,31.06,86.03z"/>
			<path class="st0-bull-white" d="M8.56,68.33c-0.3-1,0.3-1.7,0.8-2.6C10.26,67.23,10.16,67.33,8.56,68.33L8.56,68.33z"/>
			<path class="st0-bull-white" d="M36.06,119.33c-0.6,0.4-1.2,0.7-1.8,1.1c-0.2-0.5-0.4-0.9-0.6-1.5c0.9,0,1.6,0,2.3,0
				C35.96,119.13,36.06,119.23,36.06,119.33z"/>
			<path class="st0-bull-white" d="M31.66,99.73c-0.1-1.3,0.7-1.9,1.8-2.2C32.86,98.33,32.76,99.53,31.66,99.73
				C31.56,99.73,31.66,99.73,31.66,99.73z"/>
			<path class="st0-bull-white" d="M35.76,96.93c0.5-0.3,0.9-0.5,1.6-0.9c0,0.7,0.1,1.3,0.1,2c-0.6-0.2-1.1-0.4-1.6-0.6
				C35.86,97.33,35.86,97.13,35.76,96.93z"/>
			<path class="st0-bull-white" d="M45.76,63.93c-0.9-1.2-0.7-1.6,0.4-2.3c0.1,0.1,0.2,0.2,0.1,0.3C46.26,62.53,46.06,63.03,45.76,63.93z"/>
			<path class="st0-bull-white" d="M21.56,95.33c-0.4,0.9-0.8,1.7-1.2,2.6c0,0.1-0.2,0-0.6,0c0.5-1,0.9-2,1.3-2.9
				C21.26,95.13,21.36,95.23,21.56,95.33z"/>
			<path class="st0-bull-white" d="M32.46,81.53c-1,0.2-1.6,0.3-2.2,0.5c-0.1-0.1-0.1-0.3-0.2-0.4c0.5-0.3,0.9-0.7,1.4-0.9
				C31.66,80.63,31.96,81.03,32.46,81.53z"/>
			<path class="st0-bull-white" d="M8.56,68.23c-0.5,0.8-0.9,1.9-2.5,2.2C6.86,69.33,7.36,68.33,8.56,68.23C8.56,68.33,8.56,68.23,8.56,68.23z"
				/>
			<path class="st0-bull-white" d="M31.56,83.43c0.7,0.3,1.1,0.5,1.4,0.7c0.1,0.1-0.1,0.6-0.2,0.6C31.96,85.03,31.66,84.63,31.56,83.43z"/>
			<path class="st0-bull-white" d="M44.86,104.73c-0.6,0-1.2-0.1-2.1-0.2C43.96,103.83,43.96,103.83,44.86,104.73z"/>
			<path class="st0-bull-white" d="M45.76,80.33c-0.4,0.5-0.7,0.9-1,1.3c-0.1-0.1-0.2-0.1-0.2-0.2C44.46,80.63,44.56,80.13,45.76,80.33z"/>
			<path class="st0-bull-white" d="M52.26,110.03c0.5-0.4,0.6-1.5,1.7-1C53.66,109.83,53.06,110.13,52.26,110.03
				C52.16,110.03,52.26,110.03,52.26,110.03z"/>
			<path class="st0-bull-white" d="M55.36,86.63c0.4-0.2,0.9-0.4,1.3-0.6c0.1,0.1,0.1,0.3,0.2,0.4c-0.4,0.2-0.8,0.4-1.2,0.6
				C55.56,86.93,55.46,86.73,55.36,86.63z"/>
			<path class="st0-bull-white" d="M28.96,61.93c-0.1-0.3-0.2-0.6-0.2-0.8c0.2,0,0.5-0.1,0.5-0.1c0.1,0.3,0.2,0.5,0.2,0.8
				C29.26,61.83,29.16,61.83,28.96,61.93z"/>
			<path class="st0-bull-white" d="M33.26,104.73c-0.3-0.3-0.6-0.6-0.9-1c0.1-0.1,0.2-0.2,0.3-0.3c0.3,0.3,0.6,0.6,1,0.9
				C33.46,104.43,33.36,104.63,33.26,104.73z"/>
			<path class="st0-bull-white" d="M11.06,98.83c0,0.5,0.1,0.7,0,0.7c-0.3,0.3-0.5,0.5-0.8,0.7c-0.1-0.1-0.2-0.2-0.3-0.3
				C10.26,99.63,10.56,99.33,11.06,98.83z"/>
			<path class="st0-bull-white" d="M34.26,114.33c0.3-0.1,0.6-0.2,1.4-0.4c-0.5,0.6-0.7,0.8-0.9,1C34.56,114.73,34.46,114.43,34.26,114.33
				C34.26,114.23,34.26,114.33,34.26,114.33z"/>
			<path class="st0-bull-white" d="M34.06,81.33c-0.3,0.2-0.6,0.3-0.9,0.5c0-0.1-0.2-0.3-0.1-0.3c0.3-0.2,0.5-0.3,0.8-0.5
				C33.96,81.13,33.96,81.23,34.06,81.33z"/>
			<path class="st0-bull-white" d="M34.96,75.63c-0.2,0.4-0.4,0.8-0.6,1.3c-0.4-0.7-0.4-1.2,0.1-1.5C34.66,75.53,34.86,75.53,34.96,75.63z"/>
			<path class="st0-bull-white" d="M24.96,74.43c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.2,0.1,0.3,0.2
				C25.16,74.03,25.06,74.23,24.96,74.43z"/>
			<path class="st0-bull-white" d="M26.36,87.83c-0.3,0.1-0.5,0.3-0.8,0.3c-0.1,0-0.2-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.5
				C25.86,87.33,26.06,87.53,26.36,87.83z"/>
			<path class="st0-bull-white" d="M33.66,114.23c-0.4,0.8-0.8,0.9-1.5-0.3C32.96,114.13,33.36,114.23,33.66,114.23L33.66,114.23z"/>
			<path class="st0-bull-white" d="M33.76,114.33c0.1-0.5,0.2-1,0.2-1.6c0.1,0,0.2,0,0.3,0c0,0.5,0,1,0,1.5l0.1-0.1
				C34.06,114.23,33.86,114.23,33.76,114.33C33.66,114.23,33.76,114.33,33.76,114.33z"/>
			<path class="st0-bull-white" d="M418.06,34.33c0.5,0.1,0.9,0.1,1.4,0.2c-0.1,0.2-0.1,0.6-0.3,0.6C418.46,35.53,418.46,34.63,418.06,34.33
				C418.16,34.33,418.06,34.33,418.06,34.33z"/>
			<path class="st0-bull-white" d="M419.66,27.83c0.2,0.5,0.4,1,0.5,1.5c0,0.1-0.4,0.3-0.8,0.5C419.46,28.93,419.56,28.33,419.66,27.83
				C419.66,27.73,419.66,27.83,419.66,27.83z"/>
			<path class="st0-bull-white" d="M409.16,22.63c-0.5,0-1-0.1-1.8-0.1C408.66,21.83,408.66,21.83,409.16,22.63L409.16,22.63z"/>
			<path class="st0-bull-white" d="M426.56,30.73c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
				C426.16,30.53,426.36,30.63,426.56,30.73z"/>
			<path class="st0-bull-white" d="M553.16,263.23c-0.4,0.2-0.8,0.4-1.3,0.6c-1.8,0.7-1.8,0.7-2.2,2.6c-0.4,0-0.9,0.1-1.4,0.1
				c-0.1-0.5-0.2-1-0.3-1.3c-0.8,0-1.6-0.1-2.8-0.2c-0.3,0.1-0.9,0.5-1.6,0.8c-0.1,0-0.3-0.4-0.5-0.6c1-0.9,2.1-1.4,3.4-1.2
				c1.1,0.1,1.7-0.5,2-1.4c1.2-3.3,2-6.6,2.1-10.2c0.1-2.3,0.4-4.6,0.4-6.9c0-0.3,0.3-0.6,0.3-0.9c0.1-0.9,0.4-1.9,0.1-2.7
				c-0.2-0.7-0.4-1,0-1.6c0.3-0.4,0.2-1,0.2-1.6c0-2.4,0-4.8,0-7.3c0-0.9,0.2-1.9-0.6-2.7c-0.2-0.2-0.1-0.5-0.1-0.8
				c-0.1-2.2-0.1-4.5-0.2-6.7c0-1.9,0-3.9,0-6.2c0.6,0.3,1.2,0.4,1.2,0.6c0,1.2-0.1,2.5,0,3.7c0.1,2.7-0.2,5.5,0.7,8.2
				c0,0.1,0.2,0.3,0.1,0.4c-1.6,1.6,0.7,3,0.1,4.6c-0.1,0.2,0.2,0.6,0.4,0.7c1.3,0.3,1,1.4,0.8,2c-0.7,2-0.5,4-0.6,6.1
				c-0.1,1.7-0.4,3.4-0.5,5.1s0,3.3,0,5c0,0.3-0.1,0.7-0.3,1c-0.4,1.1-0.5,1.1,0,2.1c-1.4,2.5-1.9,5.1-2.2,7.6
				C551.56,262.53,552.36,262.93,553.16,263.23L553.16,263.23z"/>
			<path class="st0-bull-white" d="M586.16,212.83c0.8-1.5,1.5-2.6,0.6-4.3c-0.5-1-0.4-2.4-0.7-3.6c-0.2-0.7,0.8-1.7,1.3-2.6
				c0.3,0.3,0.5,0.4,0.4,0.5c-0.7,1.4-1.1,2.8,0,4.1c0.1,2.8,1,5.5,0.4,8.4c-0.1,0.5,0.1,1.1,0.2,1.7c0.1,2.3,0.3,4.7-1.5,6.6
				c-0.4-0.1-0.8-0.3-1.5-0.6c0.2-0.3,0.4-0.5,0.6-0.7c0.9-0.8,1.5-1.5,0.9-3c-0.5-1.2-0.5-2.9,0-4.1
				C587.36,214.03,586.66,213.53,586.16,212.83z"/>
			<path class="st0-bull-white" d="M560.56,318.33c-1.4-0.3-2-2.3-3.7-1.5c0.2,0.8,0.3,1.6,0.5,2.3c-1.1-0.8-2.7-0.7-3.1-2.5
				c0.6,0.1,1.1,0.1,1.7,0.2c0.1-0.4,0.2-0.8,0.3-1.4c1.8-0.5,3.7-1,5.4-1.5c0.1,1,0.2,1.7,0.3,2.4
				C560.76,316.53,561.06,317.63,560.56,318.33z"/>
			<path class="st0-bull-white" d="M553.16,263.33c0-0.2-0.1-0.6,0-0.6c1.6-0.5,2.6-1.7,3.5-3.2c0,0.5,0.1,0.9,0.1,1.5c1.2,0.1,2.4,0.1,4.2,0.2
				c-0.7,0.6-1.1,0.9-1.5,1.1c-0.6,0.3-1.2,0.5-1.9,0.6c-1.4,0.1-2.3,0.7-2.5,2.2c0,0.1-0.4,0.1-0.6,0.2
				C554.06,264.73,554.46,263.33,553.16,263.33C553.16,263.23,553.16,263.33,553.16,263.33z"/>
			<path class="st0-bull-white" d="M463.66,267.53c0.6-0.3,1.1-0.5,1.8-0.6c1.1-0.2,2-1.2,3.1-2c0.2,0.3,0.4,0.7,0.7,1.2c1.1-0.9,2.3-1,3.7-0.4
				c-0.9,0.3-1.8,0.7-2.8,0.8c-1.1,0.1-1.7,0.5-2.1,1.5c-0.2,0.6-0.3,1.6-1.6,1.3c0.1-0.5,0.2-0.9,0.2-1.4
				C465.66,267.83,464.66,267.73,463.66,267.53L463.66,267.53z"/>
			<path class="st0-bull-white" d="M443.56,218.33c0.6-0.3,1.1-0.5,1.8-0.9c0.1,0.2,0.2,0.5,0.4,0.8c1.9-0.5-0.4-1.9,0.6-2.6
				c1.2,0.6,1.3,1,0.7,2.9c0.6,1,1.1,2,1.8,3.2c-1.1-0.3-1.8-0.4-2.4-0.6c0.3-0.8,0.3-1.4-0.8-1.4
				C444.56,219.83,443.66,219.73,443.56,218.33C443.66,218.23,443.56,218.33,443.56,218.33z"/>
			<path class="st0-bull-white" d="M471.16,329.03c0.8-0.6,1.6-1.3,2.4-1.9c0.9-0.6,2.1-1,2.7-1.9c0.7-0.9,1.5-1.3,2.6-1.8
				c0.1,0.7,0.2,1.2,0.2,1.7c-1.2,0.5-2.3,1.1-3.1,2.2c-0.1,0.1-0.2,0.3-0.3,0.3c-1.8-0.2-2.8,1.4-4.3,1.8
				C471.46,329.33,471.36,329.23,471.16,329.03z"/>
			<path class="st0-bull-white" d="M418.26,328.23c0-0.1,0-0.2,0-0.3l0,0c0.7-0.5,0.7-1.2,0.3-1.8c-0.6-0.8-0.1-1.2,0.5-1.7
				c1.6,1.1,0.4,3.1,1.7,4.4c-0.6,0.5-1,1-1.5,1.3c-0.6,0.3-0.9,0-0.9-0.6C418.26,329.13,418.26,328.73,418.26,328.23z"/>
			<path class="st0-bull-white" d="M587.16,195.53c-0.4,1.2,1.6,2.1,0.3,3.5c0.3,0.3,0.8,0.5,0.9,0.8c0.2,0.6-0.1,1-1.3,1.9
				c-0.4-0.7-0.7-1.4-1-2.1c-0.1-0.3-0.1-0.7,0-1c0.1-0.5,0.5-0.9,0.6-1.4s0-1.1,0-1.6C586.76,195.53,586.96,195.53,587.16,195.53z"
				/>
			<path class="st0-bull-white" d="M569.06,260.63c-2,0.9-3.7,1.7-5.4,2.4c-0.2,0.1-0.7,0-0.7-0.1c-0.1-0.3-0.1-0.7,0-0.9
				c0.3-0.3,0.7-0.6,1.1-0.8c1.2-0.5,2.5-0.9,3.8-1.2C568.16,259.93,568.56,260.33,569.06,260.63z"/>
			<path class="st0-bull-white" d="M465.36,333.43c-0.2-0.7-0.5-1.3-0.7-2c0.1-0.1,0.2-0.2,0.3-0.3c0.6,0.4,1.1,0.8,1.7,1.2
				c0.6-0.4,1.1-0.8,2-1.4c-0.2,0.9-0.3,1.5-0.5,2c-0.1,0.4-1.6,1.5-1.9,1.4C465.86,334.23,465.56,333.73,465.36,333.43
				L465.36,333.43z"/>
			<path class="st0-bull-white" d="M583.26,231.33c-0.2-2.3-1.5-4.5-1.1-6.9c0.1-0.6,0.1-1.1,1.1-0.9
				C583.26,226.03,583.26,228.63,583.26,231.33z"/>
			<path class="st0-bull-white" d="M447.16,269.13c0.8,1.3,0.8,1.3,2.6,0.7c0,0.2,0,0.6,0,0.6c-1.6,0.5-3,1.5-4.9,1.4
				C445.26,270.43,445.66,269.33,447.16,269.13z"/>
			<path class="st0-bull-white" d="M460.96,281.83c0,0.5-0.1,1,0,1.4c0.3,1.9-0.6,3.1-2.5,3.9c0.6-1.3,0.5-2.6,1.6-3.5c0.2-0.2,0-1,0-1.6
				C460.26,282.03,460.66,281.93,460.96,281.83C461.06,281.93,460.96,281.83,460.96,281.83z"/>
			<path class="st0-bull-white" d="M470.66,319.63c-0.2,0.5-0.3,0.9-0.5,1.1c-0.5,0.5-0.9,0.9-1.5,1.2c-0.4,0.2-1,0.3-1.4,0.4
				c-0.1-0.4-0.2-0.8-0.3-1.2c1.2,0.2,1.2-1.3,2.2-1.5C469.56,319.53,470.06,319.63,470.66,319.63z"/>
			<path class="st0-bull-white" d="M564.96,312.73c0.1,0.8,0.2,1.6,0.3,2.3c-1.1,0.7-1.8,0.3-2.2-0.4c-0.2-0.4-0.2-1.1,0-1.4
				C563.46,312.33,564.16,312.43,564.96,312.73C564.96,312.83,564.96,312.73,564.96,312.73z"/>
			<path class="st0-bull-white" d="M564.96,311.53c1.2,0.1,2.5,0.3,3.7,0.4c0.2,1.2-0.5,1.6-1.4,1.5c-0.8-0.1-1.5-0.4-2.3-0.6l0,0
				C564.96,312.33,564.96,311.93,564.96,311.53L564.96,311.53z"/>
			<path class="st0-bull-white" d="M565.36,305.93c-0.2,0.6-0.2,1-0.4,1.1c-0.8,0.3-1.3,0.5-1.4,1.6c0,1-1.2,0.8-2.2,0.5c0.8-1.1,1.5-2.1,2.3-3
				C563.86,305.73,564.56,305.93,565.36,305.93z"/>
			<path class="st0-bull-white" d="M441.76,255.03c1.2,0.1,1.9,0.9,1.9,2c0,0.8-0.5,1.1-2.2,1C441.56,257.03,441.66,256.03,441.76,255.03
				L441.76,255.03z"/>
			<path class="st0-bull-white" d="M430.26,306.03c-0.7-0.2-1.1-0.4-1.5-0.5c0.2-0.6,0.3-1.4,0.7-1.8c0.5-0.5,1.3-0.6,2-0.9
				c0.2,0.8,0.2,1.2-0.5,1.3C429.96,304.33,429.96,304.93,430.26,306.03z"/>
			<path class="st0-bull-white" d="M453.16,293.43c-0.5,1.6-0.8,2.8-1.3,4.3c-0.3-0.4-0.6-0.7-0.7-1C450.96,295.53,451.66,294.43,453.16,293.43
				z"/>
			<path class="st0-bull-white" d="M446.86,276.23c0.9,0,1.6,0,2.4,0c-0.2,1.1-1.1,2,0.3,3c-1.1-0.1-1.1-0.1-1.6-1.2
				C447.76,277.43,447.36,276.93,446.86,276.23z"/>
			<path class="st0-bull-white" d="M451.56,223.23c0-0.9,0-1.5,0-2.3c0.3-0.1,0.8-0.4,1.5-0.7c-0.1,1.1-0.2,2-0.4,3
				C452.36,223.23,451.86,223.23,451.56,223.23z"/>
			<path class="st0-bull-white" d="M429.46,281.63c0.2,0.3,0.3,0.5,0.4,0.7c0.2,0.5,1.5,0.2,1,1.1c-0.2,0.3-1,0.3-1.6,0.4
				c-0.1,0-0.3-0.1-0.5-0.1C428.56,283.03,428.26,282.33,429.46,281.63z"/>
			<path class="st0-bull-white" d="M495.36,203.13c-0.4,1-0.8,2-1.2,3.1c-0.4-0.5-0.9-1.1-1.5-1.8C493.86,204.43,493.76,202.33,495.36,203.13z"
				/>
			<path class="st0-bull-white" d="M443.56,252.63c0.9-0.4,1.5-0.7,2.5-1.1c-0.6,1.3-1,2.2-1.5,3.3C443.06,254.63,443.86,253.53,443.56,252.63z
				"/>
			<path class="st0-bull-white" d="M437.06,348.73c-0.3,1.1-0.5,1.9-0.9,3c-0.5-0.2-1-0.5-1.4-0.7C434.66,350.43,436.06,349.03,437.06,348.73z"
				/>
			<path class="st0-bull-white" d="M471.26,319.13c0.7-0.3,1.1-0.4,1.6-0.6c0.6,1.2-0.1,2.2-0.6,3.3C470.66,321.23,472.66,319.63,471.26,319.13
				z"/>
			<path class="st0-bull-white" d="M573.46,295.23c0.9,1.8-0.7,1.6-1.4,2.2c-0.2-0.5-0.5-0.9-0.7-1.6
				C572.06,295.63,572.76,295.43,573.46,295.23z"/>
			<path class="st0-bull-white" d="M440.56,300.33c0.4,0.6,0.7,1.1,1,1.5c-1.1,0.4-2,0.7-3.2,1.1C438.86,301.73,439.76,301.23,440.56,300.33z"
				/>
			<path class="st0-bull-white" d="M583.56,236.03c-0.2-1.2-0.4-2.4-0.5-3.7C584.26,232.53,584.46,233.23,583.56,236.03z"/>
			<path class="st0-bull-white" d="M578.06,257.93c-0.7,0.6-1.9,0.3-2.2,1.5c0,0.1-1,0-1.5,0C574.96,257.53,575.26,257.43,578.06,257.93
				C578.16,257.93,578.06,257.93,578.06,257.93z"/>
			<path class="st0-bull-white" d="M462.26,350.63c0.6-0.3,1.2-0.5,1.8-0.8c0.4,1.9,0.4,1.9-1.8,2.2C462.26,351.53,462.26,351.13,462.26,350.63
				z"/>
			<path class="st0-bull-white" d="M582.06,201.43c-0.6-0.4-1-0.7-1.5-1.1c0.7-0.9,1.3-1.8,2.8-1.3c-0.3,0.4-0.6,0.7-0.8,1.1
				C582.26,200.53,582.16,200.93,582.06,201.43z"/>
			<path class="st0-bull-white" d="M444.66,335.23c0.4-1.2,0.7-2.5,1.1-3.9c0.3,0.3,0.7,0.5,0.8,0.8C446.66,332.53,445.56,334.83,444.66,335.23
				z"/>
			<path class="st0-bull-white" d="M428.16,293.23c0.6-0.9,1.1-2.1,2.7-1.5C430.36,292.83,429.26,292.93,428.16,293.23z"/>
			<path class="st0-bull-white" d="M462.46,278.83c-0.5,1,0.4,2.7-1.5,3.1l0.1,0.1C460.66,280.43,460.96,279.33,462.46,278.83L462.46,278.83z"
				/>
			<path class="st0-bull-white" d="M542.36,342.53c-0.8-0.9-2.5-1.3-2-3.3C540.96,340.53,542.46,341.03,542.36,342.53z"/>
			<path class="st0-bull-white" d="M440.36,349.23c1.2,0,1.9,0,2.9,0c-0.6,0.7-1,1.1-1.6,1.8C441.26,350.53,440.96,350.03,440.36,349.23z"/>
			<path class="st0-bull-white" d="M551.86,270.33c-1,0.8-1.6,0-1.9-0.5c-0.1-0.2,0.3-0.8,0.6-1.6C551.16,269.13,551.56,269.73,551.86,270.33z"
				/>
			<path class="st0-bull-white" d="M560.76,318.73c0.4,0.3,0.9,0.7,1.4,1.1c-0.5,0.4-0.9,0.7-1.3,1c-1-0.5-0.5-1.2-0.6-1.8
				C560.46,318.93,560.56,318.83,560.76,318.73z"/>
			<path class="st0-bull-white" d="M436.66,271.83c0.1-1.6,0.2-1.6,2.2-1.5C438.16,271.03,437.86,272.13,436.66,271.83z"/>
			<path class="st0-bull-white" d="M467.66,317.93c-0.7,0.3-1.2,0.5-2,0.9c0.1-0.5,0.1-1,0.3-1.1C466.46,317.33,466.96,316.83,467.66,317.93z"
				/>
			<path class="st0-bull-white" d="M454.26,222.53c-0.2-0.8-0.9-1.5,0.4-2.4C454.36,221.03,455.86,221.73,454.26,222.53z"/>
			<path class="st0-bull-white" d="M454.56,287.93c0.8-0.6,1.2-1,1.7-1.4c0.2,0.6,0.4,1.2,0.7,2.1C456.16,288.43,455.46,288.23,454.56,287.93z"
				/>
			<path class="st0-bull-white" d="M468.36,273.23c0.7-0.1,1.4-0.3,2.2-0.4c-0.1,1-0.8,1.4-1.5,2C468.86,274.23,468.56,273.73,468.36,273.23z"
				/>
			<path class="st0-bull-white" d="M593.26,197.83c-1,0.7-1.5,0-1.9-0.6c-0.1-0.1,0.1-0.4,0.2-0.5C591.96,196.33,592.26,196.53,593.26,197.83z"
				/>
			<path class="st0-bull-white" d="M443.36,345.23c-0.5,0.5-1.1,1-1.8,1.7C441.86,344.93,441.96,344.83,443.36,345.23z"/>
			<path class="st0-bull-white" d="M563.46,266.63c1.1-0.1,1.9,1.4,2.9,0.3c-0.1,0.3-0.2,0.8-0.3,0.8
				C565.16,267.83,564.06,268.23,563.46,266.63z"/>
			<path class="st0-bull-white" d="M472.06,342.23c0.6-0.3,1.3-0.5,2.1-0.8C473.86,342.83,473.06,342.93,472.06,342.23z"/>
			<path class="st0-bull-white" d="M451.56,216.73c0.8,0.5,0.8,0.5,0.6,2.6c-0.5-0.1-1,0-0.8-0.9C451.56,218.03,451.56,217.43,451.56,216.73z"
				/>
			<path class="st0-bull-white" d="M486.06,317.13c-0.9,1.4-0.9,1.4-2.2,0.3C484.76,317.33,485.36,317.23,486.06,317.13z"/>
			<path class="st0-bull-white" d="M421.96,318.53c0,0.6-0.1,1.3-0.1,2C420.86,319.83,420.86,319.83,421.96,318.53z"/>
			<path class="st0-bull-white" d="M436.36,305.03c0.4-0.9,0.7-1.5,1.6-1.5C437.96,304.83,437.96,304.83,436.36,305.03z"/>
			<path class="st0-bull-white" d="M572.96,258.13c0,1.2,0,1.9,0,2.9c-0.4-0.3-0.8-0.5-1.2-0.7C572.16,259.73,572.46,259.13,572.96,258.13z"/>
			<path class="st0-bull-white" d="M443.56,258.73c0.6-0.6,0.9-1.3,1.7-0.9c0.1,0.1,0.2,0.4,0.2,0.5C445.16,259.33,444.46,258.93,443.56,258.73
				z"/>
			<path class="st0-bull-white" d="M469.76,313.13c-0.3-0.8-0.6-1.2-0.8-1.8c0.6,0,1.1,0,1.9,0C470.46,312.03,470.16,312.53,469.76,313.13z"/>
			<path class="st0-bull-white" d="M558.26,258.23c-1.1-0.5-1.1-0.5,0.1-2c0,0.5,0.1,0.8,0.1,1C558.36,257.53,558.26,257.83,558.26,258.23z"/>
			<path class="st0-bull-white" d="M571.16,290.93c0.4-0.4,0.8-0.8,1.3-1.3c0.3,1.1,0.2,1.8-0.9,1.7C571.46,291.23,571.36,291.13,571.16,290.93
				z"/>
			<path class="st0-bull-white" d="M437.46,260.63c0.7,0.5,1.8,0.2,2.1,1.8c-1-0.6-1.7-1-2.4-1.4C437.26,260.93,437.36,260.73,437.46,260.63z"
				/>
			<path class="st0-bull-white" d="M434.96,281.13c-0.4-0.9-0.6-1.4-0.9-1.9C435.16,279.33,435.16,279.33,434.96,281.13z"/>
			<path class="st0-bull-white" d="M427.76,306.33c-0.2,1-0.6,1.4-1.3,1.2c-0.1,0-0.2-0.6-0.2-0.7C426.66,306.73,427.06,306.53,427.76,306.33z"
				/>
			<path class="st0-bull-white" d="M557.06,307.63c0.4,0.1,0.8,0.3,1.3,0.5c0,0-0.1,0.4-0.2,0.5C557.46,308.83,557.16,308.43,557.06,307.63z"/>
			<path class="st0-bull-white" d="M558.86,313.03c0.7-0.8,1.2-1.3,2-2.1C560.86,312.63,560.26,313.03,558.86,313.03z"/>
			<path class="st0-bull-white" d="M449.36,264.03c0.8,0.7,1.3,1.2,2,1.9C449.66,266.03,449.36,265.33,449.36,264.03z"/>
			<path class="st0-bull-white" d="M562.06,317.03c0.4,0.2,0.8,0.4,1.3,0.6c-0.1,0.2-0.2,0.5-0.3,0.5c-0.4-0.1-0.9-0.3-1.3-0.5
				C561.86,317.43,561.96,317.23,562.06,317.03z"/>
			<path class="st0-bull-white" d="M531.16,260.03c0.6-0.2,1.1-0.4,2-0.7c-0.5,0.5-0.6,0.8-0.9,1c-0.2,0.2-0.6,0.2-0.8,0.3
				C531.26,260.43,531.16,260.23,531.16,260.03z"/>
			<path class="st0-bull-white" d="M452.36,254.33c-0.3,0.3-0.4,0.6-0.6,0.7c-0.3,0.1-0.6,0.1-0.9,0.1c-0.1,0-0.2-0.4-0.1-0.5
				c0.2-0.2,0.5-0.4,0.8-0.4C451.66,254.13,451.96,254.23,452.36,254.33z"/>
			<path class="st0-bull-white" d="M556.86,325.23c0-1,0-1.4,0-1.9c0.1,0,0.3-0.1,0.4-0.1c0.3,0.3,0.6,0.7,0.7,1.1
				C557.96,324.53,557.46,324.83,556.86,325.23z"/>
			<path class="st0-bull-white" d="M477.06,335.53c0.9,0,1.4,0,2.1,0c-0.4,0.5-0.6,0.8-0.9,1.1C477.96,336.33,477.66,336.13,477.06,335.53z"/>
			<path class="st0-bull-white" d="M443.36,336.73c0.4-0.2,0.8-0.4,1.2-0.5c0.1,0.2,0.3,0.6,0.3,0.6c-0.4,0.2-0.7,0.4-1.1,0.6
				C443.56,337.23,443.46,336.93,443.36,336.73z"/>
			<path class="st0-bull-white" d="M562.76,337.63c-0.5,0.4-1.1,0.7-1.8,1.2c0,0-0.3-0.3-0.6-0.5C561.06,337.33,561.76,337.03,562.76,337.63
				C562.76,337.73,562.76,337.63,562.76,337.63z"/>
			<path class="st0-bull-white" d="M465.76,299.53c0.1-0.7-0.6-1.7,1-1.9c-0.2,0.8-0.4,1.4-0.5,2C465.96,299.53,465.86,299.53,465.76,299.53z"
				/>
			<path class="st0-bull-white" d="M454.76,295.13c0.3-0.6,0.5-1,0.7-1.6c0.2,0.2,0.4,0.3,0.4,0.4c0,0.5,0,0.9,0,1.6
				C455.46,295.33,455.16,295.23,454.76,295.13z"/>
			<path class="st0-bull-white" d="M434.06,311.53c0-0.5-0.5-1.2,0.4-1.4c0.2,0,0.4,0.1,0.5,0.2c-0.2,0.5-0.4,0.9-0.5,1.4
				C434.36,311.63,434.26,311.53,434.06,311.53z"/>
			<path class="st0-bull-white" d="M470.66,261.03c-0.2,0.4-0.3,0.9-0.5,1.3c0,0-0.5-0.1-0.7-0.2c0.2-0.5,0.4-0.9,0.6-1.4
				C470.26,260.83,470.46,260.93,470.66,261.03z"/>
			<path class="st0-bull-white" d="M570.36,267.13c-0.6,0-1.2,0-1.8,0c0-0.1,0-0.3,0-0.4c0.6,0,1.2-0.1,1.8-0.1
				C570.36,266.73,570.36,266.93,570.36,267.13z"/>
			<path class="st0-bull-white" d="M573.66,266.63c0,0.6,0,1.1,0,1.6C572.96,268.23,572.96,267.83,573.66,266.63z"/>
			<path class="st0-bull-white" d="M496.16,313.23c0.1-0.4,0.3-0.9,0.4-1.3c0.3,0.1,0.5,0.3,0.8,0.4c-0.2,0.4-0.5,0.8-0.7,1.1
				C496.46,313.33,496.26,313.23,496.16,313.23z"/>
			<path class="st0-bull-white" d="M560.86,207.53c0.1-0.4,0.2-0.7,0.3-1c0.5,0.2,0.9,0.4,1.4,0.7c0,0.1-0.1,0.2-0.1,0.4
				C561.96,207.53,561.46,207.53,560.86,207.53z"/>
			<path class="st0-bull-white" d="M465.36,333.43c-0.3,0.7-0.7,1.4-1.8,0.8C464.16,333.73,464.46,333.13,465.36,333.43L465.36,333.43z"/>
			<path class="st0-bull-white" d="M462.46,278.83c0.2-0.5,0.4-1,0.8-1.9C463.36,278.63,463.36,278.63,462.46,278.83L462.46,278.83z"/>
			<path class="st0-bull-white" d="M559.46,212.43c0.4-0.2,0.7-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4
				C560.06,212.93,559.86,212.73,559.46,212.43z"/>
			<path class="st0-bull-white" d="M471.46,210.03c0.4-0.5,0.6-1.3,1.6-0.8C472.66,209.83,472.26,210.33,471.46,210.03z"/>
			<path class="st0-bull-white" d="M440.96,328.13c-0.5,0.1-0.8,0.2-1,0.2s-0.4-0.5-0.3-0.6c0.2-0.2,0.5-0.5,0.7-0.5
				C440.46,327.23,440.66,327.63,440.96,328.13z"/>
			<path class="st0-bull-white" d="M452.56,269.83c-0.9,0.3-1.3,0.5-1.8,0.7C450.86,269.53,450.86,269.53,452.56,269.83z"/>
			<path class="st0-bull-white" d="M558.66,269.03c-0.6,0.6-1,0.9-1.3,1.2C557.36,269.33,557.36,269.33,558.66,269.03z"/>
			<path class="st0-bull-white" d="M560.66,205.13c-0.4,0.5-0.7,1.1-1.7,0.6c0.5-0.4,0.9-0.8,1.4-1.1
				C560.46,204.83,560.56,205.03,560.66,205.13z"/>
			<path class="st0-bull-white" d="M493.56,345.63c-0.4,0.3-0.6,0.5-0.9,0.6c-0.2,0-0.5-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.4-0.7
				C492.76,345.03,493.06,345.33,493.56,345.63z"/>
			<path class="st0-bull-white" d="M558.46,325.43c0.3-0.6,0.5-1.1,0.7-1.5c0.2,0.1,0.3,0.2,0.3,0.2C559.66,324.73,559.86,325.53,558.46,325.43
				z"/>
			<path class="st0-bull-white" d="M446.26,328.83c-0.3,0.5-0.5,0.9-0.9,1.6C445.06,329.33,445.06,329.33,446.26,328.83z"/>
			<path class="st0-bull-white" d="M505.76,322.93c0.3,0.6,0.6,1,1,1.7C505.76,324.23,505.26,324.03,505.76,322.93z"/>
			<path class="st0-bull-white" d="M586.86,224.33c0.3,0.6,0.6,1.1,0.9,1.7C586.66,225.93,586.66,225.93,586.86,224.33z"/>
			<path class="st0-bull-white" d="M555.76,329.73c-0.5,0.3-0.8,0.5-1,0.5c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.1,0.4-0.4,0.5-0.4
				C555.06,329.23,555.36,329.43,555.76,329.73z"/>
			<path class="st0-bull-white" d="M563.06,256.83c-0.1-0.3-0.3-0.6-0.3-0.9c0-0.2,0.3-0.5,0.6-0.6c0.1,0,0.5,0.2,0.5,0.4
				c0,0.3-0.2,0.5-0.2,0.8C563.46,256.63,563.26,256.73,563.06,256.83z"/>
			<path class="st0-bull-white" d="M423.66,317.43c-0.3-0.4-0.7-0.7-0.6-0.8c0-0.2,0.4-0.4,0.6-0.6c0.1,0,0.4,0.3,0.4,0.5
				C424.06,316.73,423.86,316.93,423.66,317.43z"/>
			<path class="st0-bull-white" d="M473.36,316.53c-0.4,0.3-0.6,0.6-0.8,0.6s-0.6-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.5-0.7
				C472.56,316.03,472.86,316.23,473.36,316.53z"/>
			<path class="st0-bull-white" d="M432.86,320.83c0.2-0.4,0.4-0.8,0.7-1.4c0.2,0.4,0.4,0.6,0.3,0.7c-0.2,0.3-0.5,0.6-0.8,0.9
				C433.06,321.03,432.96,320.93,432.86,320.83z"/>
			<path class="st0-bull-white" d="M570.76,261.03c-0.1-0.5-0.3-0.9-0.5-1.5c1.3,0.2,0.9,0.9,0.9,1.4
				C571.06,260.93,570.86,261.03,570.76,261.03z"/>
			<path class="st0-bull-white" d="M476.96,318.33c-0.4,0.2-0.7,0.5-0.9,0.5s-0.5-0.3-0.5-0.4c0.1-0.3,0.3-0.6,0.5-0.6
				C476.26,317.63,476.46,317.93,476.96,318.33z"/>
			<path class="st0-bull-white" d="M443.36,261.03c0.3-0.7,0.5-1.2,1.4-0.9C444.56,260.83,444.16,261.33,443.36,261.03z"/>
			<path class="st0-bull-white" d="M435.76,259.83c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.6-0.5-0.9-0.8
				C435.46,260.13,435.66,260.03,435.76,259.83z"/>
			<path class="st0-bull-white" d="M444.56,262.63c0.5,0,0.9,0,1.4,0c0,0.1,0,0.2,0,0.4c-0.5,0-0.9,0.1-1.4,0.1
				C444.56,262.93,444.56,262.73,444.56,262.63z"/>
			<path class="st0-bull-white" d="M448.56,226.23c0.3-0.3,0.5-0.5,0.8-0.8c0.1,0.1,0.2,0.2,0.4,0.3c-0.2,0.3-0.4,0.6-0.7,0.9
				C448.86,226.53,448.76,226.43,448.56,226.23z"/>
			<path class="st0-bull-white" d="M556.86,193.23c0.9-0.5,1.2-0.2,0.9,0.8C557.36,193.63,557.16,193.43,556.86,193.23z"/>
			<path class="st0-bull-white" d="M565.96,317.13c0.7-1.2,1.1-1.1,1.5,0C566.96,317.13,566.56,317.13,565.96,317.13z"/>
			<path class="st0-bull-white" d="M482.56,335.43c0.3,0.4,0.6,0.6,1,1.1c-0.7,0-1.2,0-1.9,0C481.96,336.13,482.16,335.93,482.56,335.43z"/>
			<path class="st0-bull-white" d="M538.36,336.53c0-0.4,0-0.8,0-1.2c0.1,0,0.2,0,0.3,0c0,0.4,0,0.8,0,1.2
				C538.66,336.43,538.56,336.43,538.36,336.53z"/>
			<path class="st0-bull-white" d="M428.66,315.53c1,0.6,1.1,1,0,1.6C428.66,316.53,428.66,316.03,428.66,315.53z"/>
			<path class="st0-bull-white" d="M446.56,336.43c-0.1-0.3-0.3-0.5-0.3-0.8c0-0.2,0.4-0.4,0.5-0.4c0.2,0.1,0.4,0.3,0.5,0.6
				C447.26,335.83,446.96,336.03,446.56,336.43z"/>
			<path class="st0-bull-white" d="M578.76,273.63c-0.3,0.3-0.5,0.5-0.8,0.8c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.2,0.2-0.4,0.3-0.6
				c0.1-0.1,0.3-0.2,0.5-0.3C578.56,273.33,578.66,273.43,578.76,273.63z"/>
			<path class="st0-bull-white" d="M521.06,307.63c0.3,0.2,0.5,0.4,0.8,0.6c0,0-0.1,0.3-0.2,0.3c-0.3-0.1-0.6-0.3-0.9-0.4
				C520.86,307.93,520.96,307.73,521.06,307.63z"/>
			<path class="st0-bull-white" d="M551.96,211.93c0.4,0.3,0.6,0.5,0.9,0.8c-0.2,0.4-0.5,0.9-0.9,1.7
				C551.96,213.43,551.96,212.83,551.96,211.93z"/>
			<path class="st0-bull-white" d="M473.16,216.03c-0.4,0.5-0.7,0.8-1.1,1.2C471.76,216.23,472.06,215.93,473.16,216.03z"/>
			<path class="st0-bull-white" d="M418.26,328.23c0,0.4,0,0.9,0,1.3c-0.3,0-0.7-0.1-1-0.1c0,0,0.1,0,0,0
				C417.66,329.03,417.96,328.63,418.26,328.23z"/>
			<path class="st0-bull-white" d="M457.76,300.53c-0.8-0.6-1.2-1-1.9-1.5C457.16,298.73,457.56,299.23,457.76,300.53z"/>
			<path class="st0-bull-white" d="M471.36,331.53c-0.3,0.2-0.5,0.3-0.7,0.4c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.2,0.4-0.4,0.7-0.6
				C470.96,331.03,471.16,331.33,471.36,331.53z"/>
			<path class="st0-bull-white" d="M583.56,240.23c0-0.4,0-0.9,0-1.3c0.1,0,0.3,0,0.4,0c0,0.5,0.1,0.9,0.1,1.4
				C583.86,240.23,583.76,240.23,583.56,240.23z"/>
			<path class="st0-bull-white" d="M486.96,332.03c-0.4-0.5-0.7-0.7-0.9-1.1C486.76,330.83,487.06,330.93,486.96,332.03z"/>
			<path class="st0-bull-white" d="M484.16,330.13c0.4,0.4,0.7,0.8,1,1.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.3-0.8-0.6-1.1-1
				C483.76,330.63,483.86,330.43,484.16,330.13z"/>
			<path class="st0-bull-white" d="M584.56,243.33c0,0.7,0,1.2,0,1.8c-0.4-0.3-0.7-0.5-1.1-0.8C583.86,244.03,584.16,243.73,584.56,243.33z"/>
			<path class="st0-bull-white" d="M485.46,337.53c-0.4-0.6-0.5-0.9-0.7-1.2C485.66,336.13,485.96,336.33,485.46,337.53z"/>
			<path class="st0-bull-white" d="M564.16,315.93c0.4,0.5,0.7,0.7,0.9,1C564.16,317.03,564.16,317.03,564.16,315.93z"/>
			<path class="st0-bull-white" d="M478.76,316.03c0.3,0.3,0.6,0.6,0.9,0.8C478.66,317.03,478.66,317.03,478.76,316.03z"/>
			<path class="st0-bull-white" d="M575.56,266.23c-0.3,0.4-0.5,0.6-0.8,1C574.46,266.23,574.46,266.23,575.56,266.23z"/>
			<path class="st0-bull-white" d="M553.46,313.83c0.5-0.3,0.8-0.5,1.2-0.8C554.86,314.13,554.46,314.23,553.46,313.83z"/>
			<path class="st0-bull-white" d="M427.06,310.63c0.4,1.1,0,1.2-0.9,1.1C426.46,311.23,426.76,311.03,427.06,310.63z"/>
			<path class="st0-bull-white" d="M572.66,212.03c-0.6,0.5-0.9,0.7-1.2,1.1C571.16,212.13,571.36,211.73,572.66,212.03z"/>
			<path class="st0-bull-white" d="M481.76,343.83c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.3,0.2-0.5
				C481.36,343.63,481.56,343.73,481.76,343.83z"/>
			<path class="st0-bull-white" d="M437.76,344.93c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
				C437.46,345.13,437.56,345.03,437.76,344.93z"/>
			<path class="st0-bull-white" d="M479.26,342.03c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.3-0.2-0.4-0.3
				C478.86,342.23,479.06,342.13,479.26,342.03z"/>
			<path class="st0-bull-white" d="M531.86,345.13c0.2,0.2,0.4,0.3,0.4,0.5s-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
				C531.66,345.53,531.76,345.43,531.86,345.13z"/>
			<path class="st0-bull-white" d="M493.36,215.93c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.3-0.2-0.4-0.3
				C492.96,216.13,493.16,216.03,493.36,215.93z"/>
			<path class="st0-bull-white" d="M472.56,212.63c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C472.86,212.23,472.76,212.43,472.56,212.63z"/>
			<path class="st0-bull-white" d="M496.26,340.23c-0.3,0.3-0.4,0.5-0.5,0.5c-0.2,0-0.4-0.2-0.6-0.3c0.1-0.2,0.2-0.4,0.3-0.4
				C495.66,339.93,495.96,340.13,496.26,340.23z"/>
			<path class="st0-bull-white" d="M459.36,339.73c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C459.66,339.33,459.46,339.53,459.36,339.73z"/>
			<path class="st0-bull-white" d="M447.46,348.23c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C447.66,347.93,447.56,348.03,447.46,348.23z"/>
			<path class="st0-bull-white" d="M467.66,204.13c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.2,0.1-0.3,0.2-0.5
				C467.26,203.83,467.46,203.93,467.66,204.13z"/>
			<path class="st0-bull-white" d="M573.36,199.53c0.2,0.2,0.4,0.3,0.4,0.5s-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
				C573.06,199.93,573.26,199.83,573.36,199.53z"/>
			<path class="st0-bull-white" d="M490.26,349.83c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2
				C490.46,349.53,490.36,349.73,490.26,349.83z"/>
			<path class="st0-bull-white" d="M494.06,349.13c0.3,0.1,0.6,0.1,0.8,0.2c-0.1,0.1-0.1,0.3-0.2,0.3c-0.3,0-0.5-0.1-0.8-0.2
				C493.96,349.43,494.06,349.33,494.06,349.13z"/>
			<path class="st0-bull-white" d="M447.36,338.23c-0.3,0.2-0.4,0.3-0.5,0.3c-0.2,0-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
				C446.96,337.93,447.06,338.13,447.36,338.23z"/>
			<path class="st0-bull-white" d="M584.16,195.33c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3
				C583.96,195.03,584.06,195.13,584.16,195.33z"/>
			<path class="st0-bull-white" d="M499.46,195.83c-0.1-0.3-0.2-0.5-0.2-0.6s0.2-0.2,0.4-0.3c0.1,0.2,0.2,0.3,0.2,0.5
				C499.86,195.53,499.66,195.63,499.46,195.83z"/>
			<path class="st0-bull-white" d="M423.96,335.83c-0.3-0.7-0.6-1.4,0.7-1.3C424.36,335.03,424.16,335.43,423.96,335.83z"/>
			<path class="st0-bull-white" d="M514.76,352.33c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2
				C514.96,352.03,514.86,352.13,514.76,352.33z"/>
			<path class="st0-bull-white" d="M473.86,353.03c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3
				C473.66,352.63,473.66,352.83,473.86,353.03z"/>
			<path class="st0-bull-white" d="M587.96,191.03c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.3-0.3-0.4s0.2-0.3,0.3-0.4
				C587.66,190.83,587.76,190.93,587.96,191.03z"/>
			<path class="st0-bull-white" d="M563.36,333.63c0,0.4-0.1,0.8-0.1,1.3C562.76,334.23,562.76,334.23,563.36,333.63
				C563.46,333.63,563.36,333.63,563.36,333.63z"/>
			<path class="st0-bull-white" d="M452.76,352.93c0.1,0.3,0.3,0.4,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
				C452.46,353.23,452.56,353.13,452.76,352.93z"/>
			<path class="st0-bull-white" d="M500.56,333.83c-0.6,0.3-0.8,0.4-1,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
				C499.76,333.43,499.96,333.53,500.56,333.83z"/>
			<path class="st0-bull-white" d="M482.36,236.53c0.2,0.1,0.4,0.1,0.4,0.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0-0.3-0.1-0.3-0.1
				C482.26,236.83,482.36,236.73,482.36,236.53z"/>
			<path class="st0-bull-white" d="M494.46,332.73c0,0.3-0.1,0.6-0.1,0.8c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1,0.1-0.2,0.2-0.3
				C494.16,332.73,494.36,332.73,494.46,332.73z"/>
			<path class="st0-bull-white" d="M479.46,240.63c-0.2,0.1-0.4,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C478.96,240.23,479.06,240.33,479.46,240.63z"/>
			<path class="st0-bull-white" d="M458.46,242.13c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
				C458.16,241.93,458.26,242.03,458.46,242.13z"/>
			<path class="st0-bull-white" d="M553.06,187.13c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.1,0.2-0.2,0.4-0.3
				C552.86,186.73,552.96,186.83,553.06,187.13z"/>
			<path class="st0-bull-white" d="M556.26,185.63c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.4,0.2-0.5,0.2s-0.2-0.2-0.3-0.4
				C555.86,185.83,555.96,185.73,556.26,185.63z"/>
			<path class="st0-bull-white" d="M562.16,327.93c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C562.46,327.63,562.36,327.73,562.16,327.93z"/>
			<path class="st0-bull-white" d="M418.26,327.93c-0.5-0.2-1.1-0.5-0.3-1.4C418.06,327.13,418.16,327.53,418.26,327.93
				C418.26,328.03,418.26,327.93,418.26,327.93z"/>
			<path class="st0-bull-white" d="M481.76,326.03c0.2,0.3,0.4,0.5,0.4,0.7c-0.1,0.2-0.4,0.4-0.5,0.4c-0.2,0-0.3-0.3-0.5-0.4
				C481.26,326.43,481.46,326.33,481.76,326.03z"/>
			<path class="st0-bull-white" d="M557.46,250.53c0.1-0.2,0.1-0.4,0.2-0.4c0.2,0,0.4,0.1,0.5,0.1c0,0.1-0.1,0.3-0.1,0.3
				C557.86,250.63,557.66,250.53,557.46,250.53z"/>
			<path class="st0-bull-white" d="M451.86,252.83c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
				C451.56,253.03,451.66,252.93,451.86,252.83z"/>
			<path class="st0-bull-white" d="M458.06,258.23c-0.1-0.3-0.3-0.5-0.3-0.6s0.2-0.3,0.3-0.4c0.1,0.2,0.3,0.3,0.3,0.5
				C458.36,257.83,458.26,258.03,458.06,258.23z"/>
			<path class="st0-bull-white" d="M568.26,318.93c-0.4-0.3-0.9-0.5,0-1.4C568.26,318.23,568.26,318.63,568.26,318.93L568.26,318.93z"/>
			<path class="st0-bull-white" d="M497.96,318.03c-0.3,0.1-0.5,0.2-0.6,0.2s-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C497.66,317.63,497.76,317.83,497.96,318.03z"/>
			<path class="st0-bull-white" d="M460.56,262.53c0.2,0.2,0.3,0.4,0.3,0.5s-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C460.26,262.93,460.46,262.83,460.56,262.53z"/>
			<path class="st0-bull-white" d="M456.16,265.53c-0.2,0.1-0.4,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C455.76,265.03,455.86,265.23,456.16,265.53z"/>
			<path class="st0-bull-white" d="M477.86,265.63c-0.3,0.2-0.4,0.3-0.5,0.3s-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
				C477.36,265.33,477.56,265.53,477.86,265.63z"/>
			<path class="st0-bull-white" d="M485.26,314.23c-0.2-0.2-0.3-0.3-0.4-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0.2,0.3,0.2
				C485.56,313.93,485.46,314.03,485.26,314.23z"/>
			<path class="st0-bull-white" d="M466.26,312.23c-0.3,0.1-0.5,0.2-0.6,0.2s-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C465.86,311.83,465.96,312.03,466.26,312.23z"/>
			<path class="st0-bull-white" d="M564.96,311.53c-0.3-0.2-0.5-0.4-0.8-0.6C564.96,310.63,564.96,310.63,564.96,311.53L564.96,311.53z"/>
			<path class="st0-bull-white" d="M460.96,293.83c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
				C460.66,293.63,460.76,293.73,460.96,293.83z"/>
			<path class="st0-bull-white" d="M425.36,305.53c-0.3,0.1-0.5,0.3-0.6,0.3s-0.3-0.2-0.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3
				C424.96,305.23,425.16,305.33,425.36,305.53z"/>
			<path class="st0-bull-white" d="M556.66,290.53c0.4,0.2,0.8,0.4,1.2,0.6c0,0.1-0.1,0.2-0.1,0.4c-0.4-0.2-0.9-0.3-1.3-0.5
				C556.46,290.83,556.56,290.63,556.66,290.53z"/>
			<path class="st0-bull-white" d="M539.56,291.93c0-0.3,0.1-0.6,0.1-0.8c0.1,0.2,0.3,0.3,0.4,0.5c0,0.1-0.1,0.2-0.2,0.3
				C539.76,291.93,539.66,291.93,539.56,291.93z"/>
			<path class="st0-bull-white" d="M460.96,292.23c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.2-0.4c0-0.1,0.2-0.3,0.3-0.4
				C460.76,291.83,460.86,292.03,460.96,292.23z"/>
			<path class="st0-bull-white" d="M474.86,295.53c0.2,0.2,0.3,0.3,0.4,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.3-0.1-0.3-0.2
				C474.66,295.93,474.76,295.73,474.86,295.53z"/>
			<path class="st0-bull-white" d="M563.16,292.03c-0.3,0-0.5,0-0.8,0v-0.3c0.3-0.1,0.6-0.1,0.8-0.1C563.16,291.73,563.16,291.83,563.16,292.03
				z"/>
			<path class="st0-bull-white" d="M563.26,294.53c0.3,0.2,0.5,0.4,0.6,0.5c-0.1,0.2-0.3,0.5-0.4,0.5c-0.2,0-0.4-0.2-0.6-0.3
				C562.86,295.03,563.06,294.83,563.26,294.53z"/>
			<path class="st0-bull-white" d="M341.06,247.03c-0.8-0.5-1.5-0.1-2.3,0.3c-6.1,3.6-12.1,7.1-18.2,10.7c-1,0.6-1.9,1.2-2.9,1.6
				c-0.5,0.2-1.2,0.3-1.7,0.1c-0.7-0.3-0.7-1.1-0.6-1.8c0.6-2.3,1.1-4.7,1.7-7c1.6-6.6,3.3-13.2,4.9-19.9c0-0.1,0.1-0.3,0.1-0.4
				c0.5-2,0.5-2,2.5-2c0.8,0,1.5,0,2.3,0c5,0,9.9,0,14.9,0c0.5,0,1,0,1.4,0c1-0.1,1.4-0.9,1.1-2c-0.2-0.8-0.5-1.6-0.7-2.4
				c-0.3-1.2-0.2-2.2,0.9-3.2c0.4,2.4,0.8,4.7,1.2,7.3c0.3,0.1,1,0.4,1.5,0.3c1.5-0.5,3,0.2,4.5,0.1c1.6-0.1,3.2,0,4.8,0
				s3.2,0,4.8,0s3.2,0,4.8,0c0.4,0,0.8,0,1.2,0.1c0.8,0.2,1.1,0.9,0.5,1.5c-0.4,0.4-0.9,0.6-1.4,0.9c-4.8,2.8-9.6,5.6-14.4,8.5
				c-1,0.6-1.7,1.4-2.7,2.2c-1-0.5-3.1,0.6-4,2.4C343.36,244.53,341.76,245.13,341.06,247.03L341.06,247.03z M319.06,257.83
				c1-0.5,1.5-0.7,2-1c6.5-3.7,13.1-7.4,19.5-11.2c3.6-2.1,7.1-4.3,10.7-6.5c2.5-1.5,5-2.9,7.5-4.4c0.5-0.3,0.9-0.7,1.3-1
				c-0.1-0.1-0.1-0.3-0.2-0.4c-0.7,0-1.5,0-2.2,0c-5.7,0-11.4,0-17.2,0c-4.5,0-9,0-13.5,0c-1.8,0-2.1,0.3-2.6,2
				c-0.1,0.3-0.1,0.5-0.2,0.8c-1.6,6.5-3.2,13-4.8,19.5C319.36,256.13,319.26,256.73,319.06,257.83z"/>
			<path class="st0-bull-white" d="M350.06,289.53c-0.3-1,0.3-1.2,1-1.4c1.7-0.4,1.7-0.4,1.3-2.1c-0.1-0.2,0-0.7,0-0.8c0.9-0.3,0.5-0.9,0.5-1.5
				c0-0.1,0.4-0.3,0.7-0.5c0.3,0.9,0.5,1.5,0,2.3c-0.2,0.3-0.4,0.7-0.4,1.1c-0.3,2.6-0.4,2.9-2.1,5.6c-0.1,0.2-0.2,0.5-0.3,0.7
				c-0.7,3-1.4,6-2.1,9c-0.6,2.3-1.2,4.7-1.8,7c-0.5,1.7-0.8,1.8-2.6,1.8c-3-0.1-6.1,0-9.1,0.1c-1,0-2-0.1-3-0.1
				c-8.9,0-17.8,0-26.7,0c-0.5,0-1,0-1.4,0c-1.3-0.1-1.6-0.5-1.3-1.7c0.4-1.8,1-3.6,1.4-5.4c0.2-0.9,0.8-1.8,0-2.9
				c-0.3-0.5,0.4-1.7,0.7-2.6c0.4-1.3,1-2.5,1.4-3.9c0.3-1,0.4-2,1.7-2.3c0.2-0.1,0.3-0.6,0.6-0.8c0.2-0.1,0.5-0.1,0.7-0.1
				c0,0.3,0,0.6,0,1c7.8,0,15.7,0,23.6,0c0.3-1.8-1.9-1.4-2.3-2.6c1.9-1.3,2.5,1.1,4,1.1c-0.1,0.6-0.3,1-0.4,1.7
				c2.3-0.1,4.4-0.3,6.6-0.3c1.8,0,3.6,0.2,5.5,0.2c1.3,0,1.4,0,2.5-0.9c0.7,0.2,1.4,0.3,2.1,0.5c0.3-0.7,0.5-1.1,0.7-1.8
				C350.96,289.83,350.46,289.73,350.06,289.53L350.06,289.53z M345.66,309.93c1.2-4.3,2.4-8.5,3.3-13.2c-1.1,0-1.9,0-2.8,0
				c-5.4,0-10.8,0-16.2,0c-6.2,0-12.4,0-18.6,0c-2.1,0-2.5,0.3-2.9,1.9c-0.8,3.3-1.6,6.6-2.4,9.9c-0.1,0.4,0,0.8,0,1.3
				C319.36,309.93,332.46,309.93,345.66,309.93z"/>
			<path class="st0-bull-white" d="M309.56,281.33c-1,1.4-1.6,2.9-1.1,4.7c2.1,1.1,2.2,1.7,0.4,3.2c-0.8-0.6-1.7-1.2-2.7-1.9
				c-0.2,0.7-0.4,1.1-0.5,1.6c-0.2,0-0.3-0.1-0.5-0.1c-0.7-0.1-1.5-0.2-2.2-0.3c-1.2-0.1-1.4-0.4-1.4-2c0.5-0.2,1.1-0.5,1.7-0.8
				c0.4,0.6,0.7,1.1,1.1,1.8c0.6-0.3,1.3-0.6,2.2-1.1c-0.2-1-0.4-2-0.6-2.9c-2.6,0-4.9,0-7.3,0c0-0.1-0.1-0.3-0.1-0.4
				c1-0.6,2-1.2,3-1.7c0.3-0.1,0.8,0,1,0.1c1.2,1.3,2.5,0.2,3-0.4c0.7-0.9,1.7-2,1.6-3.4c0-0.4-0.1-1,0.1-1.2
				c1.3-1.7,0.9-3.8,1.6-5.6c0.2-0.5,0.1-1.1,0.1-1.6c0.1-0.9,0.5-1.8,0.4-2.7c-0.1-0.8,0.3-1.7,0.2-2.3c-0.3-2,1.3-4,0.2-6
				c-0.1-0.2,0.1-0.5,0.2-0.8c0.1-0.5,0.4-1.2,0.2-1.6c-0.8-1.7-0.4-3.5-0.5-5.3c-0.1-2.3-0.3-4.6-0.6-6.9c-0.3-2.8-0.8-5.7,0.4-8.5
				c0.1-0.2,0.1-0.4,0-0.6c-1.3-1.7,0.5-3.1,0.6-4.6c0-0.1,0.2-0.1,0.5-0.2c0.1,0.4,0.3,0.9,0.2,1.1c-0.8,0.9-0.6,1.9-0.6,2.9
				c0,2.4,0,4.8,0,7.3c0,1.1,0,2.1,0.5,3.2c0.5,1,0.2,2.3,0.2,3.5c0,2,0,2,1.4,3.5c-0.5,1.2-0.9,2.4,0.1,3.7
				c-0.9,0.9-1.1,1.9-0.9,3.4c0.3,2.2-0.1,4.5-0.2,6.7c-0.1,1.8-0.3,3.6-0.4,5.4c-0.1,0.8-0.1,1.5-0.5,2.3c-0.5,1-0.3,2.4-0.8,3.3
				c-0.9,1.5-0.4,2.9-0.5,4.4C308.86,280.93,309.36,281.13,309.56,281.33L309.56,281.33z"/>
			<path class="st0-bull-white" d="M288.86,226.23c-0.2,0.7-0.2,1.7-1.4,0.9c-0.6-0.5-1.3,0.9-1.9,0c-0.4-0.6-0.7-1.1-1.2-1.6
				c-0.7-0.7-1.2-0.6-1.7,0.4c-1.9-0.5-3.4-2-2.7-3.4c0.6-1.1,0.1-1.8,0.1-2.7c0-1.9-1-2.9-2.8-3.3c0.3-1,0.3-1,2.1-0.3
				c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.2-0.3,0.4-0.7c1.1,1,2.3,1.7,3,2.8c1,1.5,2.7-0.1,3.8,1.5c0.7,1.4-1.9,1.5-1.8,3.1
				c0.2,0.3,0.6,0.8,0.9,1.3c-0.1,0.3-0.3,0.7-0.5,1.1C286.66,225.33,287.36,226.63,288.86,226.23z M283.76,224.43
				c0-1.8-0.3-2.1-2.5-2.2c0.3,0.8,0.6,1.5,0.9,2.2C282.66,224.43,283.16,224.43,283.76,224.43z M283.06,221.13c0.5-0.5,1-1,1.5-1.5
				c-0.7-0.5-1.2-0.8-1.7-1.1c-0.2-0.1-0.6,0-0.7,0.1c-0.1,0.1-0.2,0.5-0.1,0.7C282.26,219.93,282.56,220.43,283.06,221.13z"/>
			<path class="st0-bull-white" d="M260.66,320.03c0.8-0.9,1.8-0.3,2.7-0.4c0.9-0.2,1.8-0.2,1.2-1.4c1.8-0.5,3.6-1,5.4-1.5
				c-1.2,1.4-2.4,2.7-3.7,3.9c-0.7,0.7-1.7,0.1-2.2,1.2c-0.3,0.7-0.8,1.7-1.8,2.2c-0.8,0.4-1.2,1.4-1.8,2.1
				c-0.5-0.5-0.9-0.8-1.4-1.2c0.5-0.5,1-1.1,1.6-1.5c0.5-0.3,1.1-0.4,1.7-0.7c-0.5-0.5-0.9-0.8-1.2-1.2
				C260.86,320.93,260.76,320.43,260.66,320.03L260.66,320.03z M262.96,320.33c-0.3-0.1-0.5-0.2-0.6-0.2s-0.2,0.2-0.3,0.4
				c0.2,0.1,0.3,0.2,0.5,0.2C262.66,320.73,262.76,320.53,262.96,320.33z"/>
			<path class="st0-bull-white" d="M266.96,349.23c0.6,0.3,1.4-0.2,1.7,0.9c0.1,0.4,0.9,0.7,1.4,1.1c-0.1,0.3-0.2,0.6-0.4,0.9
				c-0.2,0.3-0.5,0.4-0.8,0.7c0-0.4-0.1-0.7-0.1-1.1c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-1.7,0.4-2.7-0.8-3.9-1.5
				c-0.8-0.5-1.6-1-2.4-1.6c-0.5,0.4-0.9,0.8-1.4,1.1c-0.2,0.1-0.6,0.1-0.9,0.1c0-0.3-0.2-0.7-0.1-0.9c0.8-1,0.6-1.9-0.2-3.2
				c0.8,0.2,1.5,0.1,1.8,0.5c0.9,0.9,1.9,1.3,3.2,1.3C265.76,346.93,266.96,348.23,266.96,349.23z"/>
			<path class="st0-bull-white" d="M276.26,287.33c0.6-0.8,0.9-1.3,1.4-1.9c-0.3-0.4-0.7-0.9-1.1-1.4c0.1-0.3,0.3-0.7,0.5-1
				c-0.3-0.1-0.5-0.2-0.9-0.4c0.9-1.8-0.1-3.3-0.6-4.9c1.6-0.7-0.5-2.1,0.6-3.1c0.4,0,0.9-0.1,1.5-0.1c0.1,1,0.4,2,0.1,2.7
				c-0.7,1.5,0.3,2.9,0.1,4.3c-0.1,0.9,0,1.6,0.8,2.4c0.6,0.6,1.2,1.7,0.5,2.9c-0.2,0.3-0.1,0.8-0.2,1.2
				C278.06,287.73,277.36,287.53,276.26,287.33z"/>
			<path class="st0-bull-white" d="M287.76,268.53c-0.4,0-0.8,0-1.4,0c0.1-0.9,0.2-1.6,0.3-2.4c-1.8-0.3-3-1.4-3.4-3.3c1.9-1.2,2.3,1.2,3.8,1.1
				c-0.2,0.6-0.3,1.1-0.5,1.6c0.4,0.1,0.8,0.2,1.2,0.3C287.66,266.73,287.66,267.63,287.76,268.53L287.76,268.53z"/>
			<path class="st0-bull-white" d="M284.46,268.83c-1,1.1,1,3.1-1.5,3.8c0.3-2.2-2.2-2.3-2.6-4c1.1-1.3,1.6-1.3,2.5,0.1
				c0.5-0.1,0.9-0.2,1.3-0.3C284.26,268.63,284.36,268.73,284.46,268.83z"/>
			<path class="st0-bull-white" d="M309.56,281.43c0.2-0.3,0.5-0.9,0.7-0.9c1.6,0.3,2.5-1,3.7-1.7c1.1,1.9,2.8-0.3,4.1,0.8
				c-1,0.2-1.9,0.4-2.8,0.7c-0.5,0.2-1.1,0.5-1.4,1c-0.5,0.6-0.7,1.4-1.1,2.1c-1.7-0.2-0.4-1.2-0.6-1.8
				C311.26,281.53,310.36,281.43,309.56,281.43C309.56,281.33,309.56,281.43,309.56,281.43z"/>
			<path class="st0-bull-white" d="M316.66,343.23c0.2-0.5,0.2-0.9,0.4-1c0.6-0.4,1.3-0.8,2-1c1.2-0.3,2.1-1.1,2.7-2.1c0.6-1,1.4-1.4,2.4-1.8
				c0.5,0.8,0.9,1.3-0.2,1.9c-0.9,0.4-1.8,0.8-2.2,1.9c0,0.2-0.3,0.4-0.4,0.4C319.66,341.33,318.46,342.73,316.66,343.23z"/>
			<path class="st0-bull-white" d="M263.56,342.03c1.3-0.9,0.7-1.9-0.1-3.1c0.4-0.4,0.7-0.7,1.4-1.2c0.5,1.9,0.8,3.4,1.2,4.8
				c-1.6,1.6-1.6,1.6-2.4,0.7c0.2-0.2,0.5-0.3,0.7-0.5C264.06,342.53,263.76,342.33,263.56,342.03z"/>
			<path class="st0-bull-white" d="M285.86,255.13c-1.1-1.1-1.1-1.3,0.7-2.5c-0.4-0.5-0.7-1-1.1-1.7c1.8-0.2,2.5,0.5,2.6,2.1
				C287.16,253.83,286.56,254.43,285.86,255.13z"/>
			<path class="st0-bull-white" d="M281.86,263.93c-0.1,0.5-0.2,1-0.3,1.5c0.5,0.1,1,0.3,1.9,0.5c-0.9,0.6-1.5,1-2.1,1.4
				c-1.6-1.3-1.9-1.9-1.5-2.9C280.26,263.63,280.96,263.53,281.86,263.93z"/>
			<path class="st0-bull-white" d="M282.86,258.93c-1.8-0.7-2.9-1.9-2.7-4.2c1,0.8,2,1.6,2.9,2.6C283.16,257.63,282.86,258.43,282.86,258.93
				L282.86,258.93z"/>
			<path class="st0-bull-white" d="M292.66,282.93c0.5,1.7,1.8,0.6,2.7,1.2c-1.5,0.5-3.1,1.1-4.7,1.6
				C290.26,284.03,291.56,283.63,292.66,282.93z"/>
			<path class="st0-bull-white" d="M310.96,347.03c-0.2-0.5-0.4-1.1-0.7-1.9c0.7,0.4,1.3,0.7,1.9,1c0.6-0.3,1.1-0.6,1.8-1
				c0.1,1.9-0.9,2.5-2.3,3C311.36,347.73,311.06,347.43,310.96,347.03L310.96,347.03z"/>
			<path class="st0-bull-white" d="M362.96,290.03c-2.6,1.2-2.6,1.2-4.9,0c-0.4-0.2-1,0-1.7,0.1c0.3-0.3,0.4-0.7,0.7-0.8c0.4-0.1,0.8-0.1,1.2,0
				C359.76,289.53,361.36,289.83,362.96,290.03z"/>
			<path class="st0-bull-white" d="M259.16,343.03c0.1-0.4,0-0.9,0.2-1.1c0.6-0.6,0.6-1.2,0.4-1.9c-0.3-1.1,0.2-1.9,1.2-2.4
				c-0.6,1.8,0.1,3.9-1.1,5.6C259.66,343.13,259.36,343.13,259.16,343.03z"/>
			<path class="st0-bull-white" d="M257.96,305.73c1.1-0.4,1.7-0.2,2.2,1.1c0.4,1,0.4,1.8,0.3,2.7c0,0.2-0.5,0.4-1,0.7
				c-0.2-1.3-0.4-2.4-0.6-3.4C258.76,306.43,258.26,306.03,257.96,305.73L257.96,305.73z"/>
			<path class="st0-bull-white" d="M287.66,268.53c0.6,0.8,1.2,1.5,1.7,2.2c-0.5,1.3-1.3,1.4-2.2,1.2
				C286.16,270.53,287.56,269.63,287.66,268.53C287.76,268.53,287.66,268.53,287.66,268.53z"/>
			<path class="st0-bull-white" d="M260.86,312.73c0.2,0.4,0.5,0.8,0.7,1.2c-0.3,0.2-0.9,0.5-0.9,0.8c0,1.2-0.9,1.1-2,1.5
				c0.7-0.8-0.1-1.7,0.9-2.1c0.1,0,0.1-0.3,0.1-0.3c-0.4-0.3-1-0.5-0.4-1c0.2-0.2,0.8,0.1,1.2,0.1
				C260.66,313.03,260.76,312.83,260.86,312.73L260.86,312.73z"/>
			<path class="st0-bull-white" d="M312.46,336.03c0-0.5,0-0.8,0-1.1c0.2-0.6,1.1,0.4,1.3-0.7c0.2-1.1,1.3-1.2,2.4-0.7
				c-0.1,0.3-0.2,0.7-0.2,0.7C314.36,334.13,314.56,336.83,312.46,336.03z"/>
			<path class="st0-bull-white" d="M282.06,251.73c-0.5-0.5-1.1-1-0.4-1.7c0.4-0.5,0.8-1,1.3-1.5C284.36,249.73,284.26,250.23,282.06,251.73z"
				/>
			<path class="st0-bull-white" d="M345.46,215.53c-0.8,1.7,0.7,3.5-1.1,4.8c-0.5-0.8-0.4-1.5,0.2-2C343.56,216.73,343.66,216.13,345.46,215.53
				z"/>
			<path class="st0-bull-white" d="M267.26,327.73c0.1,1.4-2.4,1.2-1.7,3.1c-1-0.3-1.4-0.7-1.1-1.5C264.86,328.13,265.76,327.63,267.26,327.73z
				"/>
			<path class="st0-bull-white" d="M275.46,319.63c-0.5-0.1-1-0.2-1.4-0.3c0.3-1.4,1.1-2.2,2.3-2.6c0.2-0.1,0.6,0.2,0.8,0.4
				c0.1,0.1-0.1,0.5-0.3,0.7C275.56,318.73,275.56,318.73,275.46,319.63z"/>
			<path class="st0-bull-white" d="M285.96,258.93c-1-1.1-2.3-1.8-2-3.4c1.1-0.1,1.4,0.5,1.6,1.2c0.2,0.5,0.6,0.9,1,1.5
				C286.56,258.33,286.36,258.53,285.96,258.93z"/>
			<path class="st0-bull-white" d="M253.56,345.63c-0.6-0.8-1.1-1.6-1.7-2.5c1.8-0.4,2,1.4,3.3,1.8C254.46,345.23,253.96,345.43,253.56,345.63z
				"/>
			<path class="st0-bull-white" d="M258.16,345.73c-0.9,0-1.3,0.3-1.3,1.2c-0.2,0-0.3,0-0.5,0c-0.9-1.3-0.6-2.6,0.1-3.8l-0.1,0.1
				c0.2,0.2,0.4,0.5,0.6,0.5C258.36,344.03,258.26,344.83,258.16,345.73z"/>
			<path class="st0-bull-white" d="M292.46,289.93c0.8,0,1.5,0,2.1,0c-0.1,1.1-0.1,2.1-0.2,3.3c-0.3-0.5-0.6-1-0.9-1.4
				C293.16,291.23,292.86,290.63,292.46,289.93z"/>
			<path class="st0-bull-white" d="M297.56,311.53c-1.3-1.5-1-2.7,1-4.6C298.16,308.63,297.86,309.93,297.56,311.53z"/>
			<path class="st0-bull-white" d="M263.36,336.83c-0.8-1.3,0.6-2.2,0.3-3.9C265.16,336.23,265.16,336.23,263.36,336.83z"/>
			<path class="st0-bull-white" d="M269.06,354.63c-1.9,0.1-2.9-0.5-3.6-2.2C266.86,353.13,268.16,353.23,269.06,354.63z"/>
			<path class="st0-bull-white" d="M282.66,255.03c-0.4-1-0.7-1.6-1-2.4c1,0,1.7,0,2.5,0C284.56,253.83,283.36,254.03,282.66,255.03z"/>
			<path class="st0-bull-white" d="M259.46,301.73c1.5,0.2,1.2,1.4,1.8,2.2c-0.8,0.2-1.5,0.3-2.4,0.5
				C259.06,303.43,259.26,302.53,259.46,301.73z"/>
			<path class="st0-bull-white" d="M259.26,319.73c-0.8,0.7-1.5,1.4-2.3,2c-0.2,0.2-0.7,0.1-1,0.1c0.1-0.3,0.1-0.8,0.3-1
				c0.8-0.6,1.7-1.1,2.6-1.7C258.96,319.33,259.16,319.53,259.26,319.73z"/>
			<path class="st0-bull-white" d="M282.76,258.93c1,0.6,1.1,1.7,1.8,2.7c-1.9,0.3-1.6-1.5-2.7-1.7C282.26,259.53,282.56,259.23,282.76,258.93
				C282.86,258.93,282.76,258.93,282.76,258.93z"/>
			<path class="st0-bull-white" d="M340.76,216.93c-0.4,1.1-0.7,2.1-1.1,3.1c-0.4-0.5-0.8-1.1-1.5-1.9
				C339.36,218.13,339.36,216.33,340.76,216.93z"/>
			<path class="st0-bull-white" d="M276.36,297.43c-0.7,0-1.4,0-2.1,0c-0.6-1,0-1.5,0.6-1.9c0.6,0.5,1.1,1,1.6,1.5
				C276.46,297.13,276.36,297.23,276.36,297.43z"/>
			<path class="st0-bull-white" d="M317.26,332.93c0.4-0.2,0.7-0.4,1.1-0.7c0.8,1.2-0.3,2.1-0.6,3.3c-0.4-0.4-1.3-0.5-0.5-1.4
				C317.46,333.93,317.26,333.33,317.26,332.93z"/>
			<path class="st0-bull-white" d="M291.26,345.53c1,1.5-0.1,2.6-0.8,3.8C290.06,347.93,291.06,346.83,291.26,345.53z"/>
			<path class="st0-bull-white" d="M349.06,192.03c1.4-0.3,2.4-0.6,3.7-0.9c-0.6,0.9-1.1,1.6-1.6,2.3
				C350.56,193.03,349.86,192.63,349.06,192.03z"/>
			<path class="st0-bull-white" d="M291.26,265.53c-0.5,1.2-0.9,2.1-1.3,3c-1.1-0.6-0.5-1.5-0.7-2.3C289.96,266.03,290.46,265.83,291.26,265.53
				z"/>
			<path class="st0-bull-white" d="M318.86,326.53c0.8-0.9,1.3-1.5,1.8-2.2c0.1,0,0.3,0,0.4,0.1c0,0.7,0.1,1.3,0.1,2.1
				C320.46,326.53,319.86,326.53,318.86,326.53z"/>
			<path class="st0-bull-white" d="M297.16,234.73c0.3-0.1,0.7-0.3,1.3-0.5c-0.1,1-0.2,1.8-0.3,2.7c-0.4,0-0.8,0-1.2,0
				C297.06,236.13,297.06,235.53,297.16,234.73z"/>
			<path class="st0-bull-white" d="M290.66,202.03c1-0.3,2-0.6,3.3-0.9C293.06,202.83,292.96,202.83,290.66,202.03z"/>
			<path class="st0-bull-white" d="M283.86,316.73c0.2-0.5,0.3-0.7,0.4-0.8c0.5-0.5,1.1-0.9,1.8-1.5c0.3,0.4,0.6,0.8,0.9,1.2
				C285.86,315.93,284.96,316.23,283.86,316.73z"/>
			<path class="st0-bull-white" d="M349.06,215.23c1-1.2,1.2-1.2,2.3,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.3,0.5
				C350.16,215.73,349.56,215.53,349.06,215.23z"/>
			<path class="st0-bull-white" d="M284.76,192.93c-0.8,0-1.7,0-2.6,0C282.96,191.63,282.96,191.63,284.76,192.93z"/>
			<path class="st0-bull-white" d="M313.76,287.13c0.8-0.2,1.5-0.4,2.3-0.5c0,1.2-0.8,1.5-1.6,2C314.26,288.03,314.06,287.53,313.76,287.13z"/>
			<path class="st0-bull-white" d="M338.36,281.63c1,0,2.1,0,3.1,0C340.46,282.33,339.46,282.23,338.36,281.63z"/>
			<path class="st0-bull-white" d="M279.76,205.93c0.5-1.8,0.5-1.8,3.2-1.3C281.96,205.03,280.96,205.43,279.76,205.93z"/>
			<path class="st0-bull-white" d="M256.56,343.13c-0.9,0.1-1-0.3-0.6-0.9c0.3-0.4,0.7-0.7,1.1-1.1c0,0,0.5,0.3,0.5,0.4
				c0.2,0.8-0.3,1.1-0.9,1.3C256.56,342.93,256.56,343.13,256.56,343.13C256.46,343.23,256.56,343.13,256.56,343.13z"/>
			<path class="st0-bull-white" d="M284.26,284.23c-0.4,0.4-0.8,0.9-1.3,1.3c-0.2,0.1-0.6,0-0.9,0c0.1-0.3,0-0.9,0.2-1c0.5-0.3,1.1-0.5,1.7-0.7
				C284.06,283.93,284.16,284.03,284.26,284.23z"/>
			<path class="st0-bull-white" d="M329.46,331.93c0.5-1.5,1.4-1,2.3-0.9C331.46,332.43,330.56,332.13,329.46,331.93z"/>
			<path class="st0-bull-white" d="M327.96,322.83c0.9,0.3,1.8-1.4,2.7,0.1C329.76,323.73,328.96,323.83,327.96,322.83z"/>
			<path class="st0-bull-white" d="M284.16,317.73c-0.7,0.6-1.2,1-1.7,1.3c-0.1,0-0.4-0.3-0.6-0.5c0.4-0.5,0.8-0.9,1.3-1.4
				C283.26,317.23,283.56,317.43,284.16,317.73z"/>
			<path class="st0-bull-white" d="M283.06,197.43c-0.5,0.7-0.9,1.3-1.3,1.9c-0.1,0-0.2-0.1-0.4-0.1c0-0.8,0-1.5,0-2.4
				C281.96,196.93,282.46,197.13,283.06,197.43z"/>
			<path class="st0-bull-white" d="M282.86,274.43c0.9,0.3,1.8,0.2,2.1,1.3C284.16,275.63,283.16,275.73,282.86,274.43z"/>
			<path class="st0-bull-white" d="M273.66,306.93c0.6-1,1.3-1.7,2.7-1.3C275.56,306.53,274.66,306.73,273.66,306.93z"/>
			<path class="st0-bull-white" d="M298.66,255.23c-0.2-0.5-0.5-1-0.7-1.6c-0.1-0.2,0.2-0.4,0.3-0.7c0.3,0.1,0.7,0.2,0.8,0.4
				c0.1,0.6,0,1.2,0,1.8C298.96,255.13,298.86,255.13,298.66,255.23z"/>
			<path class="st0-bull-white" d="M394.26,187.63c-0.2-1-0.3-1.5-0.5-2.3c0,0,0.4-0.2,0.7-0.3c0.2,0.3,0.5,0.6,0.8,1
				C394.96,186.33,394.76,186.83,394.26,187.63z"/>
			<path class="st0-bull-white" d="M325.06,321.63c-0.2,0.8-0.2,1.1-0.3,1.5c0.5,0.1,1,0.2,1.5,0.3c-1,0.5-2,0.7-2.8-0.6
				C323.86,322.53,324.36,322.23,325.06,321.63z"/>
			<path class="st0-bull-white" d="M259.36,339.63c-1,0.1-1.5,0.2-2.2,0.3c0.3-0.7,0.5-1.2,0.8-1.9C258.36,338.43,258.66,338.83,259.36,339.63z
				"/>
			<path class="st0-bull-white" d="M260.86,312.83c0.1-0.4,0.3-0.9,0.4-1.4c0.5,0.2,1,0.4,1.6,0.7C262.36,313.03,261.76,313.23,260.86,312.83
				C260.86,312.73,260.86,312.83,260.86,312.83z"/>
			<path class="st0-bull-white" d="M272.06,265.03c-0.4,0.6-0.6,1-0.8,1.4c-0.4-0.2-0.8-0.4-1.3-0.6C270.26,264.73,271.06,264.93,272.06,265.03
				z"/>
			<path class="st0-bull-white" d="M353.46,294.43c0.5,1.2,1.3,1.9,0.1,2.6C352.46,296.23,353.26,295.53,353.46,294.43z"/>
			<path class="st0-bull-white" d="M275.06,273.73c-0.7,0.2-1.4,0.4-2.1,0.5C273.16,273.13,273.16,273.13,275.06,273.73z"/>
			<path class="st0-bull-white" d="M300.56,302.03c0.5-0.8,0.9-1.3,1.3-1.9c0.2,0.8,0.4,1.4,0.7,2.2C301.86,302.13,301.26,302.13,300.56,302.03
				z"/>
			<path class="st0-bull-white" d="M297.46,267.73c-0.1,1-0.4,1.5-1.1,1.2c-0.2-0.1-0.2-0.4-0.3-0.6C296.56,268.13,296.96,267.93,297.46,267.73
				z"/>
			<path class="st0-bull-white" d="M299.86,236.33c0-0.8-0.9-1.3,0-2C300.76,235.23,300.76,235.23,299.86,236.33z"/>
			<path class="st0-bull-white" d="M273.26,320.73c-0.5,0.2-1,0.3-1.5,0.5c-0.1-0.2-0.2-0.7-0.2-0.7c0.5-0.2,1-0.4,1.5-0.5
				C273.16,320.23,273.16,320.53,273.26,320.73z"/>
			<path class="st0-bull-white" d="M261.56,295.43c0.2,0.5,0.4,0.9,0.6,1.4C261.16,296.83,261.16,296.83,261.56,295.43z"/>
			<path class="st0-bull-white" d="M267.16,290.83c-0.5-0.4-1.7,0-1.5-1.4C266.76,289.23,267.26,289.73,267.16,290.83z"/>
			<path class="st0-bull-white" d="M310.86,347.03c-0.1,1.3-0.8,1.2-1.8,0.6C309.66,347.43,310.26,347.23,310.86,347.03
				C310.96,347.03,310.86,347.03,310.86,347.03z"/>
			<path class="st0-bull-white" d="M355.06,251.53c0,0.6,0,1.1,0,2c-0.8-0.8-0.6-1.4-0.5-2C354.76,251.53,354.86,251.53,355.06,251.53z"/>
			<path class="st0-bull-white" d="M312.66,330.83c0.9,1.7-1,1-1.3,1.7C311.16,331.53,311.66,331.03,312.66,330.83z"/>
			<path class="st0-bull-white" d="M314.36,324.93c0.7,0.1,1.2,0.1,2,0.2c-0.4,0.7-0.7,1.1-1.1,1.7C314.96,326.13,314.76,325.63,314.36,324.93z
				"/>
			<path class="st0-bull-white" d="M288.26,261.63c-0.2,0.6-0.4,1-0.5,1.4c-0.3-0.2-0.7-0.4-0.8-0.6C286.76,261.73,287.06,261.43,288.26,261.63
				z"/>
			<path class="st0-bull-white" d="M426.26,193.73c-0.9-1.6,0.2-2.1,0.5-3c0.1,0,0.3,0.1,0.4,0.1C426.86,191.73,426.66,192.53,426.26,193.73z"
				/>
			<path class="st0-bull-white" d="M346.26,323.53c-0.6,0.1-0.9,0.2-1.4,0.3c0-0.7-0.1-1.4-0.1-2c0.1,0,0.2-0.1,0.3-0.1
				C345.36,322.23,345.76,322.73,346.26,323.53z"/>
			<path class="st0-bull-white" d="M295.16,278.13c0.4,0.4,0.8,0.8,1.3,1.3C295.16,279.43,295.16,279.43,295.16,278.13z"/>
			<path class="st0-bull-white" d="M315.86,274.73c0.4,0.4,0.5,0.9,0,1.3c-0.1,0.1-0.7-0.1-0.8-0.2C314.86,275.13,315.16,274.73,315.86,274.73z
				"/>
			<path class="st0-bull-white" d="M378.96,273.53c-0.8,0.4-1.4,0.6-2.1,1C377.26,273.23,377.26,273.23,378.96,273.53z"/>
			<path class="st0-bull-white" d="M350.86,310.93c1.4-0.8,1.4-0.8,1.9,0.7C352.16,311.43,351.66,311.23,350.86,310.93z"/>
			<path class="st0-bull-white" d="M275.26,269.23c-0.4,0.6-0.7,1.1-1,1.6C274.16,269.43,274.16,269.43,275.26,269.23z"/>
			<path class="st0-bull-white" d="M303.56,314.03c-1.2,0.4-1.4-0.8-2.1-1.3C302.86,312.23,303.06,313.33,303.56,314.03L303.56,314.03z"/>
			<path class="st0-bull-white" d="M397.16,225.73c1.9,0.9,0.3,1.4,0.1,2.1C397.26,227.23,397.26,226.63,397.16,225.73z"/>
			<path class="st0-bull-white" d="M328.06,349.63c0.3,0.2,0.6,0.3,1,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0-1,0-1.4,0c-0.1-0.1-0.2-0.3-0.3-0.4
				C327.46,349.93,327.76,349.73,328.06,349.63z"/>
			<path class="st0-bull-white" d="M328.56,318.63c0.5,0,0.9-0.1,1.4-0.1c0,0.2,0,0.3,0,0.5c-0.5,0-0.9,0.1-1.4,0.1
				C328.66,318.93,328.56,318.73,328.56,318.63z"/>
			<path class="st0-bull-white" d="M328.76,344.13c1.5-0.1,1.9,0.4,2,1.8C330.06,345.23,329.56,344.83,328.76,344.13z"/>
			<path class="st0-bull-white" d="M261.26,291.93c0.6,0,1.2,0,1.9,0c-0.6,1-1.3,0.7-1.9,0.5C261.26,292.33,261.26,292.13,261.26,291.93z"/>
			<path class="st0-bull-white" d="M344.66,192.63c0,0.5,0,0.9,0,1.4c-0.1,0-0.2,0-0.4,0c0-0.5-0.1-0.9-0.1-1.4
				C344.36,192.63,344.46,192.63,344.66,192.63z"/>
			<path class="st0-bull-white" d="M338.46,197.53c-0.5-1-0.2-1.7,0.7-2.2C339.76,196.43,338.36,196.73,338.46,197.53z"/>
			<path class="st0-bull-white" d="M341.56,321.93c-0.4,0-0.9,0-1.3,0c0-0.1-0.1-0.2-0.1-0.3c0.2-0.1,0.5-0.3,0.7-0.3s0.5,0.1,0.7,0.2
				C341.66,321.63,341.56,321.83,341.56,321.93z"/>
			<path class="st0-bull-white" d="M288.96,350.63c0.4-0.2,0.7-0.4,1.1-0.6c0,0,0.3,0.4,0.3,0.5c-0.3,0.2-0.7,0.4-1.1,0.6
				C289.16,351.03,289.06,350.83,288.96,350.63z"/>
			<path class="st0-bull-white" d="M274.56,214.03c0.2-0.5,0.4-0.9,0.6-1.4c0.2,0.1,0.4,0.2,0.7,0.3c-0.3,0.5-0.5,0.9-0.8,1.4
				C274.96,214.13,274.76,214.03,274.56,214.03z"/>
			<path class="st0-bull-white" d="M274.16,329.13c1.3,0.8,0.9,1.2-0.2,1.9C274.06,330.13,274.16,329.73,274.16,329.13z"/>
			<path class="st0-bull-white" d="M307.56,243.03c-0.3,0.5-0.5,1-0.7,1.4c-0.2-0.1-0.4-0.2-0.4-0.2C306.26,243.53,306.26,242.93,307.56,243.03
				z"/>
			<path class="st0-bull-white" d="M288.36,189.63c0.4,0,0.9-0.1,1.5-0.1c-0.2,0.3-0.2,0.6-0.3,0.6c-0.4,0-0.8-0.1-1.2-0.1
				C288.36,189.83,288.36,189.73,288.36,189.63z"/>
			<path class="st0-bull-white" d="M290.86,271.13c0.3,1.7-0.4,1.6-1.5,1.4C289.86,272.03,290.16,271.73,290.86,271.13z"/>
			<path class="st0-bull-white" d="M286.76,247.93c-0.5,1.1-0.7,1.1-2.3-0.1C285.56,247.83,286.16,247.93,286.76,247.93L286.76,247.93z"/>
			<path class="st0-bull-white" d="M389.56,282.93c0.5,0,0.9,0,1.4,0c0,0.1,0,0.3,0,0.4c-0.5,0-1,0.1-1.4,0.1
				C389.56,283.23,389.56,283.03,389.56,282.93z"/>
			<path class="st0-bull-white" d="M406.26,218.83c-0.4,0.3-0.7,0.6-1.1,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c0.1-0.3,0.2-0.6,0.4-0.7
				c0.2-0.2,0.6-0.2,0.8-0.3C406.06,218.63,406.16,218.73,406.26,218.83z"/>
			<path class="st0-bull-white" d="M341.16,355.03c-0.2-0.5-0.4-0.7-0.4-1c0-0.2,0.4-0.5,0.5-0.4c0.3,0.1,0.6,0.3,0.6,0.5
				C341.86,354.33,341.46,354.63,341.16,355.03z"/>
			<path class="st0-bull-white" d="M273.96,275.53c-0.3,0.7-0.6,1.2-1,2C272.76,276.43,272.56,275.73,273.96,275.53z"/>
			<path class="st0-bull-white" d="M261.26,309.83c0.6-0.3,1.2-0.4,2.2,0.4c-1,0.1-1.6,0.1-2.2,0.1C261.26,310.13,261.26,310.03,261.26,309.83z
				"/>
			<path class="st0-bull-white" d="M327.06,341.33c-0.2-0.5-0.4-0.7-0.4-1c0-0.2,0.4-0.5,0.5-0.4c0.3,0.1,0.6,0.3,0.6,0.5
				C327.66,340.73,327.36,340.93,327.06,341.33z"/>
			<path class="st0-bull-white" d="M389.46,188.63c0.3-0.5,0.5-1,0.9-1.7C390.46,188.63,390.46,188.63,389.46,188.63z"/>
			<path class="st0-bull-white" d="M252.46,304.13c0.5,0.3,0.9,0.6,1.5,1c-1.2,0.7-1.4-0.2-1.8-0.6C252.26,304.33,252.36,304.23,252.46,304.13z
				"/>
			<path class="st0-bull-white" d="M279.76,333.63c-0.3,0.4-0.6,0.8-1,1.4c-0.5-1,0-1.4,0.5-1.7C279.36,333.43,279.56,333.53,279.76,333.63z"/>
			<path class="st0-bull-white" d="M348.76,247.63c-0.3-0.5-1.3-0.6-0.6-1.8c0.4,0.6,0.7,1,1,1.5C349.06,247.43,348.96,247.53,348.76,247.63z"
				/>
			<path class="st0-bull-white" d="M337.36,283.73c0.4-0.1,0.7-0.2,1.2-0.3c0,0.7,0,1.3,0,2.3C338.06,284.93,337.76,284.33,337.36,283.73z"/>
			<path class="st0-bull-white" d="M291.56,342.03c0,0.6,0.1,0.9,0,1c-0.3,0.3-0.6,0.6-0.9,0.8C290.16,342.93,290.86,342.63,291.56,342.03z"/>
			<path class="st0-bull-white" d="M265.56,313.83c-0.4-0.3-0.8-0.6-1.3-1c1.1-0.5,1.3,0.1,1.7,0.5C265.86,313.43,265.76,313.63,265.56,313.83z
				"/>
			<path class="st0-bull-white" d="M318.56,223.13c-0.5,0.3-0.9,0.6-1.4,0.8c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.8-0.7,1.2-1
				C318.26,222.73,318.36,222.93,318.56,223.13z"/>
			<path class="st0-bull-white" d="M277.56,215.23c-0.2-0.5-0.4-0.8-0.4-1s0.4-0.4,0.5-0.4c0.2,0.1,0.6,0.4,0.6,0.6
				C278.36,214.63,277.96,214.83,277.56,215.23z"/>
			<path class="st0-bull-white" d="M269.86,330.13c-0.3,0.3-0.4,0.6-0.6,0.6s-0.4-0.2-0.6-0.3c0.2-0.3,0.3-0.5,0.5-0.8
				C269.36,329.83,269.56,329.93,269.86,330.13z"/>
			<path class="st0-bull-white" d="M318.86,330.13c-0.4,0.3-0.6,0.6-0.9,0.6c-0.1,0-0.5-0.3-0.5-0.4c0-0.3,0.2-0.6,0.4-0.7
				C318.06,329.63,318.46,329.93,318.86,330.13z"/>
			<path class="st0-bull-white" d="M268.16,298.03c0.4,0.7,1.3,0.7,1,1.7C268.26,299.23,268.26,299.23,268.16,298.03z"/>
			<path class="st0-bull-white" d="M271.16,298.33c0.5-0.2,0.9-0.4,1.4-0.6c0.1,0.1,0.2,0.3,0.2,0.4c-0.1,1.2-0.9,0.5-1.4,0.7
				C271.26,298.63,271.16,298.53,271.16,298.33z"/>
			<path class="st0-bull-white" d="M353.66,317.73c0.4-0.2,0.7-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4
				C354.26,318.33,354.06,318.03,353.66,317.73z"/>
			<path class="st0-bull-white" d="M395.46,192.83c-1,0.3-1.7,0.4-1.7-0.9C394.36,192.13,394.76,192.43,395.46,192.83z"/>
			<path class="st0-bull-white" d="M351.26,337.13c0.5,0.3,1.3,0.3,0.6,1.4c-0.4-0.4-0.7-0.8-1-1.1C351.06,337.33,351.16,337.23,351.26,337.13z
				"/>
			<path class="st0-bull-white" d="M330.56,349.93c0.3,0.1,0.6,0.3,0.9,0.4c0,0-0.1,0.2-0.1,0.4c-0.3-0.1-0.6-0.2-1-0.2
				C330.46,350.33,330.46,350.13,330.56,349.93z"/>
			<path class="st0-bull-white" d="M267.36,315.43c-0.3,0.4-0.6,0.7-1,1.2c-0.5-1.3,0.3-1.2,0.6-1.5C267.16,315.23,267.26,315.33,267.36,315.43
				z"/>
			<path class="st0-bull-white" d="M303.46,314.03c0.5,0.1,0.9,0.3,1.5,0.5C303.76,314.93,303.76,314.93,303.46,314.03
				C303.56,314.03,303.46,314.03,303.46,314.03z"/>
			<path class="st0-bull-white" d="M351.26,313.23c0.5,0,0.9,0,1.4,0c0,0.1,0,0.2,0,0.4c-0.5,0-0.9,0.1-1.4,0.1
				C351.26,313.53,351.26,313.33,351.26,313.23z"/>
			<path class="st0-bull-white" d="M324.66,349.43c-0.4,0.5-0.6,0.7-0.9,1.1c-0.3-0.3-0.6-0.5-1.1-1C323.46,349.53,323.96,349.53,324.66,349.43
				z"/>
			<path class="st0-bull-white" d="M374.56,222.33c0,0.4,0,0.9,0,1.3c-0.1,0-0.3,0-0.4,0c0-0.4-0.1-0.9-0.1-1.3
				C374.16,222.33,374.36,222.33,374.56,222.33z"/>
			<path class="st0-bull-white" d="M291.46,268.43c0.3,1,0,1.3-1.1,1.2C290.86,269.23,291.06,268.93,291.46,268.43z"/>
			<path class="st0-bull-white" d="M406.46,220.43c0.5,0.1,0.9,0.3,1.4,0.4c0,0.2-0.1,0.3-0.1,0.5c-0.5,0-0.9-0.1-1.4-0.1
				C406.36,220.93,406.46,220.63,406.46,220.43z"/>
			<path class="st0-bull-white" d="M311.46,313.73c-0.4-1.9-0.4-1.9,0.6-2.4C311.86,312.03,311.76,312.73,311.46,313.73z"/>
			<path class="st0-bull-white" d="M341.86,327.13c0.1-0.5,0.1-1,0.2-1.8C342.76,326.23,342.76,326.23,341.86,327.13z"/>
			<path class="st0-bull-white" d="M419.36,220.73c0-0.5,0-0.9,0-1.4c0.1,0,0.2,0,0.3,0c0,0.5,0,0.9,0,1.4
				C419.56,220.73,419.46,220.73,419.36,220.73z"/>
			<path class="st0-bull-white" d="M291.36,276.73c-0.4,0-0.8,0-1.1,0c0-0.1,0-0.2,0-0.4c0.4,0,0.8-0.1,1.2-0.1
				C291.36,276.33,291.36,276.53,291.36,276.73z"/>
			<path class="st0-bull-white" d="M257.96,305.63c-0.3-0.4-1.3-0.3-0.8-1.4C257.86,304.63,258.36,304.83,257.96,305.63
				C257.96,305.73,257.96,305.63,257.96,305.63z"/>
			<path class="st0-bull-white" d="M258.16,284.63c-0.9,0.5-1.2,0.2-0.9-0.8C257.56,284.23,257.86,284.43,258.16,284.63z"/>
			<path class="st0-bull-white" d="M353.76,303.03c-0.3,0.6-0.5,1-0.8,1.5c-0.2-0.2-0.5-0.4-0.5-0.6C352.46,303.23,352.96,303.03,353.76,303.03
				z"/>
			<path class="st0-bull-white" d="M260.36,286.93c-0.4-0.3-0.6-0.6-1-0.8C260.36,285.83,260.36,285.83,260.36,286.93z"/>
			<path class="st0-bull-white" d="M355.86,243.73c0-0.4,0-0.8,0-1.1c0.1,0,0.2,0,0.3,0c0.1,0.4,0.1,0.7,0.2,1.1
				C356.16,243.73,356.06,243.73,355.86,243.73z"/>
			<path class="st0-bull-white" d="M266.26,333.53c0.3-0.4,0.5-0.8,1-1.4c0.1,0.9,0.1,1.5,0.1,2.4C266.76,334.03,266.56,333.83,266.26,333.53z"
				/>
			<path class="st0-bull-white" d="M290.96,242.83c-0.2,0-0.4,0.2-0.6,0.1c-0.3-0.2-0.6-0.5-0.8-1c0.3,0.1,0.6,0,0.8,0.2
				C290.66,242.23,290.76,242.53,290.96,242.83L290.96,242.83z"/>
			<path class="st0-bull-white" d="M294.36,240.43c-0.2-0.9-0.1-1.4,1.1-1.1C294.96,239.73,294.76,240.03,294.36,240.43z"/>
			<path class="st0-bull-white" d="M297.66,243.83c-0.4-0.6-0.6-0.8-0.8-1.1C297.76,242.53,297.76,242.53,297.66,243.83z"/>
			<path class="st0-bull-white" d="M386.66,188.83c0.2,0.9,0.1,1.4-1,1.1C386.06,189.53,386.26,189.23,386.66,188.83z"/>
			<path class="st0-bull-white" d="M279.66,324.93c0.2-0.4,0.3-0.7,0.5-1.1l0.3,0.1c-0.1,0.4-0.1,0.8-0.2,1.2
				C279.96,325.13,279.86,325.03,279.66,324.93z"/>
			<path class="st0-bull-white" d="M343.96,252.53c-0.3,0.4-0.5,0.7-0.7,1C342.56,252.73,343.06,252.53,343.96,252.53z"/>
			<path class="st0-bull-white" d="M367.16,322.73c-0.5-0.5-0.7-0.8-1-1.1C367.16,321.13,367.36,321.53,367.16,322.73z"/>
			<path class="st0-bull-white" d="M400.26,223.73c0.5-0.5,0.8-0.7,1.1-1C401.76,223.73,401.36,223.83,400.26,223.73z"/>
			<path class="st0-bull-white" d="M279.46,269.23c0.1,0.3,0.3,0.6,0.4,0.9c-0.2,0-0.5,0.1-0.5,0.1c-0.1-0.3-0.2-0.6-0.2-0.9
				C279.16,269.33,279.36,269.23,279.46,269.23z"/>
			<path class="st0-bull-white" d="M291.06,219.93c1.1-0.4,1.2,0,1.1,1C291.76,220.53,291.46,220.33,291.06,219.93z"/>
			<path class="st0-bull-white" d="M259.56,310.33c0.3,0.4,0.6,0.7,1,1.2c-1.1,0.4-1.4,0-1.5-0.7C259.26,310.73,259.36,310.53,259.56,310.33z"
				/>
			<path class="st0-bull-white" d="M301.26,307.23c0,0.7,0,1.4,0,2.3C300.46,308.33,300.46,308.33,301.26,307.23L301.26,307.23z"/>
			<path class="st0-bull-white" d="M353.96,306.53c-0.3,0.2-0.4,0.5-0.5,0.5c-0.2-0.1-0.4-0.3-0.5-0.4c0,0,0.1-0.3,0.2-0.3
				C353.26,306.33,353.56,306.43,353.96,306.53z"/>
			<path class="st0-bull-white" d="M295.86,284.53c0.4-1.3,1-0.9,1.6-0.9C296.96,283.93,296.66,284.13,295.86,284.53z"/>
			<path class="st0-bull-white" d="M262.86,302.13c-0.2-0.1-0.5-0.2-0.6-0.3c-0.2-0.2-0.3-0.5-0.1-1c0.3,0.3,0.6,0.7,1,1
				C262.96,301.93,262.96,302.03,262.86,302.13z"/>
			<path class="st0-bull-white" d="M259.46,300.03c0.4,0.3,0.6,0.5,1,0.8C259.56,301.23,259.56,301.23,259.46,300.03z"/>
			<path class="st0-bull-white" d="M280.46,292.83c0,0.5,0.1,1,0.1,1.5c-0.1,0-0.2,0-0.4,0.1c-0.1-0.5-0.3-1-0.4-1.5
				C280.06,292.83,280.26,292.83,280.46,292.83z"/>
			<path class="st0-bull-white" d="M416.96,213.13c0.2,0.2,0.3,0.4,0.3,0.5s-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C416.76,213.63,416.86,213.43,416.96,213.13z"/>
			<path class="st0-bull-white" d="M344.46,213.83c0.1,0.3,0.3,0.4,0.3,0.6c0,0.1-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.3-0.3-0.4
				C344.16,214.23,344.26,214.03,344.46,213.83z"/>
			<path class="st0-bull-white" d="M289.76,216.93c-0.2-0.3-0.4-0.5-0.4-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1,0.1,0.4,0.1,0.4,0.2
				C289.96,216.23,289.86,216.43,289.76,216.93z"/>
			<path class="st0-bull-white" d="M341.06,247.03c0.4,0,0.8,0.1,1.4,0.1C341.56,248.13,341.36,247.53,341.06,247.03L341.06,247.03z"/>
			<path class="st0-bull-white" d="M286.46,341.63c-0.4,0.2-0.7,0.4-0.8,0.4c-0.2,0-0.4-0.3-0.5-0.4c0.2-0.1,0.3-0.4,0.5-0.4
				C285.86,341.13,286.06,341.33,286.46,341.63z"/>
			<path class="st0-bull-white" d="M420.36,219.03c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
				C420.56,219.33,420.46,219.23,420.36,219.03z"/>
			<path class="st0-bull-white" d="M418.66,211.43c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C418.36,211.13,418.46,211.23,418.66,211.43z"/>
			<path class="st0-bull-white" d="M389.56,219.83c0.2,0.2,0.4,0.3,0.4,0.5c0,0.1-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
				C389.36,220.33,389.46,220.13,389.56,219.83z"/>
			<path class="st0-bull-white" d="M305.36,348.03c-0.1-0.3-0.3-0.5-0.3-0.6s0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C305.76,347.63,305.56,347.83,305.36,348.03z"/>
			<path class="st0-bull-white" d="M289.76,202.93c-0.6,0-1.3,0-1.9,0c0-0.1,0-0.2,0-0.2c0.6,0,1.3,0,1.9,0
				C289.76,202.83,289.76,202.93,289.76,202.93z"/>
			<path class="st0-bull-white" d="M271.56,349.23c-0.4,0.1-0.7,0.1-0.9,0.1c-0.1,0-0.1-0.3-0.2-0.4c0.2,0,0.4-0.2,0.5-0.1
				C271.16,348.83,271.26,348.93,271.56,349.23z"/>
			<path class="st0-bull-white" d="M283.06,349.03c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C282.66,348.73,282.86,348.93,283.06,349.03z"/>
			<path class="st0-bull-white" d="M287.46,349.13c-0.3,0.1-0.5,0.3-0.6,0.3s-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C287.06,348.73,287.16,348.93,287.46,349.13z"/>
			<path class="st0-bull-white" d="M402.16,199.73c-0.3,0.1-0.5,0.3-0.6,0.2c-0.1,0-0.3-0.2-0.4-0.3c0.2-0.1,0.3-0.3,0.5-0.3
				C401.76,199.43,401.86,199.63,402.16,199.73z"/>
			<path class="st0-bull-white" d="M412.66,197.63c0.1,0.2,0.2,0.3,0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.4-0.3
				C412.26,197.93,412.46,197.83,412.66,197.63z"/>
			<path class="st0-bull-white" d="M416.46,196.43c-0.5-0.1-0.9-0.1-1.4-0.2c0-0.2,0.1-0.3,0.1-0.5c0.5,0.1,0.9,0.2,1.4,0.3
				C416.46,196.13,416.46,196.33,416.46,196.43z"/>
			<path class="st0-bull-white" d="M305.86,225.83c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0,0.3,0.1,0.5,0.2
				C306.06,225.53,305.96,225.63,305.86,225.83z"/>
			<path class="st0-bull-white" d="M312.46,225.63c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1-0.1-0.2-0.3-0.2-0.4s0.2-0.3,0.2-0.2
				C312.16,225.33,312.26,225.53,312.46,225.63z"/>
			<path class="st0-bull-white" d="M397.76,195.63c-0.3,0.1-0.5,0.3-0.6,0.3s-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3
				C397.36,195.33,397.56,195.43,397.76,195.63z"/>
			<path class="st0-bull-white" d="M344.86,196.03c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C345.06,195.63,344.96,195.83,344.86,196.03z"/>
			<path class="st0-bull-white" d="M319.86,225.83c-0.2-0.2-0.4-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.3c0.2,0,0.3,0.2,0.5,0.3
				C320.16,225.53,320.06,225.63,319.86,225.83z"/>
			<path class="st0-bull-white" d="M374.76,225.63c-0.2,0.2-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1,0.2-0.3,0.2-0.3
				C374.36,225.33,374.46,225.43,374.76,225.63z"/>
			<path class="st0-bull-white" d="M419.16,225.43c-0.3,0.2-0.4,0.3-0.6,0.3s-0.3-0.2-0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C418.66,225.13,418.86,225.23,419.16,225.43z"/>
			<path class="st0-bull-white" d="M405.56,226.93c-0.2-0.3-0.3-0.5-0.4-0.7c0.2-0.1,0.4-0.3,0.6-0.3s0.3,0.3,0.5,0.5
				C406.06,226.53,405.86,226.63,405.56,226.93z"/>
			<path class="st0-bull-white" d="M292.36,352.33c-0.2-0.1-0.4-0.2-0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2,0,0.3,0.1,0.5,0.2
				C292.56,352.03,292.46,352.13,292.36,352.33z"/>
			<path class="st0-bull-white" d="M364.36,192.23c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
				C363.96,192.03,364.16,192.13,364.36,192.23z"/>
			<path class="st0-bull-white" d="M314.26,227.43c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
				S313.66,227.13,314.26,227.43z"/>
			<path class="st0-bull-white" d="M405.26,188.93c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.3,0.2,0.3,0.2
				C405.46,188.63,405.36,188.73,405.26,188.93z"/>
			<path class="st0-bull-white" d="M416.96,229.83c-0.1-0.3-0.3-0.5-0.3-0.6s0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C417.36,229.43,417.16,229.63,416.96,229.83z"/>
			<path class="st0-bull-white" d="M300.76,229.73c-0.3,0.1-0.5,0.2-0.6,0.1c-0.1,0-0.1-0.3-0.1-0.4c0,0,0.2-0.2,0.3-0.1
				C300.46,229.33,300.56,229.53,300.76,229.73z"/>
			<path class="st0-bull-white" d="M336.26,188.93c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C336.56,188.63,336.46,188.73,336.26,188.93z"/>
			<path class="st0-bull-white" d="M297.96,238.23c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.2-0.4c0-0.1,0.2-0.3,0.3-0.4
				C297.66,237.93,297.86,238.03,297.96,238.23z"/>
			<path class="st0-bull-white" d="M322.66,331.93c-0.7,0.5-0.9,0.7-1.1,0.8c-0.1-0.2-0.4-0.4-0.4-0.6s0.3-0.4,0.5-0.5
				C321.86,331.63,322.16,331.83,322.66,331.93z"/>
			<path class="st0-bull-white" d="M265.16,332.73c-0.1-0.3-0.3-0.5-0.3-0.6s0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C265.46,332.33,265.36,332.43,265.16,332.73z"/>
			<path class="st0-bull-white" d="M404.66,186.03c-0.3,0.2-0.4,0.3-0.6,0.3s-0.3-0.2-0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C404.16,185.73,404.36,185.83,404.66,186.03z"/>
			<path class="st0-bull-white" d="M290.96,242.83c0.3,0.2,1,0.3,0.4,1.2C291.16,243.43,291.06,243.13,290.96,242.83L290.96,242.83z"/>
			<path class="st0-bull-white" d="M349.06,243.23c-0.2,0.2-0.4,0.4-0.5,0.4c-0.2,0-0.3-0.1-0.5-0.2c0.1-0.1,0.2-0.3,0.4-0.4
				C348.56,242.93,348.76,243.13,349.06,243.23z"/>
			<path class="st0-bull-white" d="M251.96,328.53c0.2-0.2,0.3-0.3,0.4-0.5c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1-0.1,0.3-0.2,0.3
				C252.26,328.83,252.16,328.73,251.96,328.53z"/>
			<path class="st0-bull-white" d="M306.66,246.43c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C306.96,246.03,306.86,246.13,306.66,246.43z"/>
			<path class="st0-bull-white" d="M286.86,247.93c0-0.4,0.1-0.8,0.1-1.3C287.46,247.33,287.46,247.33,286.86,247.93
				C286.76,247.93,286.86,247.93,286.86,247.93z"/>
			<path class="st0-bull-white" d="M253.56,325.63c0.2,0.2,0.3,0.4,0.3,0.5s-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C253.26,326.03,253.46,325.83,253.56,325.63z"/>
			<path class="st0-bull-white" d="M296.26,249.93c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C296.66,249.63,296.46,249.73,296.26,249.93z"/>
			<path class="st0-bull-white" d="M256.66,323.23c0.4,0.2,0.9,0.3,1.3,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4-0.2-0.9-0.3-1.3-0.5
				C256.56,323.53,256.66,323.43,256.66,323.23z"/>
			<path class="st0-bull-white" d="M268.66,322.83c0.3-0.1,0.5-0.2,0.6-0.2s0.2,0.2,0.2,0.4c0,0-0.2,0.2-0.3,0.2
				C269.06,323.13,268.96,323.03,268.66,322.83z"/>
			<path class="st0-bull-white" d="M270.56,253.63c0.2,0.3,0.3,0.4,0.3,0.5c0,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4
				S270.46,253.93,270.56,253.63z"/>
			<path class="st0-bull-white" d="M260.56,319.93c0,0.4-0.1,0.8-0.1,1.3C259.96,320.53,259.96,320.53,260.56,319.93
				C260.66,320.03,260.56,319.93,260.56,319.93z"/>
			<path class="st0-bull-white" d="M297.86,266.93c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C297.36,266.63,297.56,266.73,297.86,266.93z"/>
			<path class="st0-bull-white" d="M355.16,312.93c0.1,0.2,0.2,0.3,0.4,0.5c-0.2,0.1-0.3,0.3-0.5,0.3c-0.1,0-0.3-0.2-0.4-0.3
				C354.76,313.23,354.96,313.13,355.16,312.93z"/>
			<path class="st0-bull-white" d="M270.56,273.23c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C270.96,272.73,270.76,272.93,270.56,273.23z"/>
			<path class="st0-bull-white" d="M292.26,273.63c-0.3,0.1-0.5,0.2-0.6,0.2s-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C291.86,273.33,291.96,273.43,292.26,273.63z"/>
			<path class="st0-bull-white" d="M288.66,274.63c0.7-0.4,1.1-0.6,1.4-0.8c0.1,0.1,0.2,0.2,0.2,0.3c-0.2,0.2-0.3,0.5-0.5,0.6
				C289.56,274.83,289.26,274.73,288.66,274.63z"/>
			<path class="st0-bull-white" d="M353.46,307.63c0.1,0.2,0.2,0.3,0.2,0.5l-0.3,0.2c-0.1-0.1-0.2-0.3-0.3-0.4
				C353.16,307.83,353.26,307.73,353.46,307.63z"/>
			<path class="st0-bull-white" d="M271.46,275.23c-0.3,0.1-0.5,0.3-0.6,0.3s-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C271.06,274.93,271.26,275.13,271.46,275.23z"/>
			<path class="st0-bull-white" d="M314.86,277.73c-0.3,0.1-0.5,0.3-0.6,0.3s-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C314.46,277.43,314.56,277.63,314.86,277.73z"/>
			<path class="st0-bull-white" d="M384.76,305.93c0.1,0.3,0.3,0.5,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.2-0.3
				C384.46,306.33,384.56,306.23,384.76,305.93z"/>
			<path class="st0-bull-white" d="M301.36,307.23c0.1-0.3,0.1-0.7,0.2-1.2C302.46,306.93,301.56,306.93,301.36,307.23
				C301.26,307.23,301.36,307.23,301.36,307.23z"/>
			<path class="st0-bull-white" d="M325.16,279.03c-0.3,0.1-0.5,0.3-0.6,0.3s-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C324.76,278.63,324.96,278.83,325.16,279.03z"/>
			<path class="st0-bull-white" d="M332.56,283.53c-0.2-0.2-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C332.86,283.23,332.66,283.33,332.56,283.53z"/>
			<path class="st0-bull-white" d="M271.76,303.63c0.2,0.2,0.3,0.4,0.3,0.5s-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C271.56,304.03,271.66,303.93,271.76,303.63z"/>
			<path class="st0-bull-white" d="M310.96,287.03c0-0.5,0-0.9,0-1.4c0.1,0,0.1,0,0.2,0c0,0.5,0,0.9,0,1.4
				C311.06,287.03,310.96,287.03,310.96,287.03z"/>
			<path class="st0-bull-white" d="M298.06,300.83c-0.2,0.1-0.4,0.2-0.5,0.2s-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4c0.2,0,0.4,0.1,0.5,0.2
				C298.06,300.53,298.06,300.73,298.06,300.83z"/>
			<path class="st0-bull-white" d="M350.06,289.53c-0.2,0.3-0.4,0.5-0.6,0.8C349.16,289.53,349.16,289.53,350.06,289.53L350.06,289.53z"/>
			<path class="st0-bull-white" d="M254.46,294.53c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
				C254.66,294.83,254.56,294.73,254.46,294.53z"/>
			<path class="st0-bull-white" d="M776.96,336.03c-0.3-0.8-0.5-1.5-1-2.7c1.6,1.1,2.8,2,4.4,3c-0.6-1.7-1.1-3.1,0-4.7
				c-0.6,0.1-1.1,0.1-1.6,0.2c-0.3-1.7,0.2-2.4,2.6-3.2c0.2,0.7,0.6,1.1,0.2,2.1c-0.4,1.1,0.3,2.6,0.5,4.1c-0.2,0-0.5,0-1,0
				c-0.1,1-0.5,2,0.9,2.4l0,0c0.1,0.5,0.1,0.9,0.2,1.4l0,0c-0.4,0.1-0.7,0.3-1.3,0.5c1.6,1.5-0.4,2.8,0.1,4.3
				c-2.8-1.1-3.2-2.1-1.7-3.9c0-0.4,0-0.8,0-1.2C778.66,337.33,778.26,336.13,776.96,336.03L776.96,336.03z"/>
			<path class="st0-bull-white" d="M788.46,270.73c-0.4-0.2-0.8-0.5-1.3-0.7c0.2-0.3,0.3-0.7,0.5-0.8c0.9-0.7,1.1-1.4,0.7-2.6
				c-0.4-1.2-0.5-2.9,0-4.1c0.6-1.3-0.1-1.9-0.8-2.7c2.2-1.9,0.7-4,0.4-5.9c-0.3-1.6-0.5-3.1,1.3-4.3c-0.1,0.5-0.2,0.8-0.2,1
				c-0.1,0.8-0.6,1.8-0.3,2.5c0.5,1.4,0.9,2.7,0.7,4.2c-0.1,1.1,0.4,2.2,0.4,3.4c0.1,1.4-0.2,2.9,0,4.4
				C790.26,267.13,789.86,269.13,788.46,270.73z"/>
			<path class="st0-bull-white" d="M788.36,344.23c-0.4-2.1-2.2-4.2-0.2-6.5c-0.7-0.7-1.1-1.4-0.7-2.5c0.2-0.6-0.3-1.6-0.4-2.4
				c-0.1-0.3-0.1-0.7-0.1-1.4c1,1.1,1.6,1.9,2.2,2.5c-0.1,1.1-0.3,2.1-0.4,3.3c0.7-0.2,1.1-0.3,1.7-0.4c0.1,1.9,0.3,3.8,0.4,5.4
				C789.86,343.03,789.16,343.63,788.36,344.23z"/>
			<path class="st0-bull-white" d="M754.46,309.83c1.5-0.4,2.8-1,3.2-2.7c0.1-0.2,0.5-0.3,0.9-0.6c-0.1,0.7-0.2,1.2-0.3,1.9c1.3,0,2.6,0,3.8,0
				c0.1,0.1,0.1,0.2,0.2,0.4c-0.3,0.3-0.6,0.6-1,0.7c-0.8,0.3-1.6,0.5-2.3,0.5c-1.4,0-1.9,0.8-2,2.1c0,0.1-0.3,0.3-0.5,0.5
				C755.86,311.73,755.86,310.23,754.46,309.83L754.46,309.83z"/>
			<path class="st0-bull-white" d="M789.56,352.03c-0.4-0.1-0.8-0.2-1.2-0.3c0.2-1.8,0.2-1.8-1-2.6c1-1.7,1.1-2.2,0.5-3.7
				c1.3,0.5,2.4,1.7,2.1,2.7c-0.4,1.1-0.1,1.9,0.5,2.7C790.16,351.23,789.76,351.63,789.56,352.03
				C789.46,352.03,789.56,352.03,789.56,352.03z"/>
			<path class="st0-bull-white" d="M816.56,349.43c0.2-1.1,0.4-2.2,0.7-3.4c0.4,0.2,0.8,0.4,1.3,0.6c-0.3,0.2-0.5,0.4-0.8,0.7
				c0.6,0.2,1.1,0.4,1.9,0.7c-0.8,0.3-1.3,0.6-1.9,0.8c0.3,1.3,0.9,2.6,0.2,4.1c-0.2-0.1-0.7-0.1-0.7-0.2
				C817.16,351.73,816.06,350.73,816.56,349.43L816.56,349.43z"/>
			<path class="st0-bull-white" d="M784.46,270.03c0.4,0.5,0.8,0.8,0.8,1.1c0.1,2.4,0,4.8,0,7.4C784.26,276.83,783.76,272.73,784.46,270.03z"/>
			<path class="st0-bull-white" d="M788.56,242.73c-0.1,1.2,1.7,2.1,0.5,3.4c0.3,0.4,0.8,0.7,0.9,1.1c0.1,0.4-0.8,1.4-1.3,1.7
				C787.06,246.93,788.26,244.83,788.56,242.73z"/>
			<path class="st0-bull-white" d="M824.86,337.83c0,0-0.2-0.3-0.5-0.5c0.5-0.4,0.9-0.7,1.3-1c0.2-0.2,0.4-0.5,0.6-0.5c0.9,0,1.7,0.4,2.5-0.6
				c0.5-0.6,1.6-0.8,2.8-1.3c-0.3,0.7-0.5,1.3-0.6,1.3c-1.1,0.1-1.8,0.9-2.6,1.4c-0.2,0.1-0.4,0.1-0.6,0.2
				C826.96,337.23,825.96,337.53,824.86,337.83z"/>
			<path class="st0-bull-white" d="M793.56,353.93c-0.5-0.9-0.9-1.7-1.3-2.4c1.2-0.5,2.5-1,3.8-1.5C795.86,351.83,794.36,352.53,793.56,353.93z
				"/>
			<path class="st0-bull-white" d="M764.46,309.73c0.3-0.4,0.5-0.9,0.9-1.1c1.3-0.5,2.7-1,4.1-1.4c0.6-0.2,1.1,0.1,1,0.8
				c-1.9,0.6-3.7,1.3-5.6,1.9C764.76,309.93,764.56,309.83,764.46,309.73z"/>
			<path class="st0-bull-white" d="M787.26,305.03c-0.9-1.2-1-2.3-0.1-3.5c0.8,0.6,1.6,1,2.7,0.2C789.66,303.63,786.86,302.93,787.26,305.03z"
				/>
			<path class="st0-bull-white" d="M779.46,304.53c2-0.5,4-0.9,6-1.4c0.1,0,0.2,0.3,0.5,0.6c-0.8,0.3-1.5,0.7-2.3,0.7c-1.6,0.2-1.8,0.2-2.8,0.9
				C780.46,305.13,779.96,304.83,779.46,304.53L779.46,304.53z"/>
			<path class="st0-bull-white" d="M813.16,312.43c0.8,0.6,1.1,1.1,1,2c-0.4,1.7-0.8,2.5-1.8,3C812.66,315.83,812.86,314.23,813.16,312.43z"/>
			<path class="st0-bull-white" d="M809.76,342.23c-0.4,0-0.8,0-1.5,0c0.2-0.6,0.3-1.1,0.4-1.6c-0.3-0.1-0.7-0.3-1.1-0.5
				c0.1-0.2,0.3-0.4,0.4-0.6c0.7,0,1.4,0,2.2,0C810.06,340.53,809.96,341.33,809.76,342.23z"/>
			<path class="st0-bull-white" d="M861.16,321.83c2,0.1,1.5-1.7,2.4-2.9c0.2,1.3,0.6,2.2-0.5,3c-0.1,0.1,0,0.5,0,0.7c0,0.4,0,0.9-0.2,1
				c-0.6,0.3-0.8-0.1-0.9-0.7C861.86,322.63,861.56,322.33,861.16,321.83z"/>
			<path class="st0-bull-white" d="M783.76,248.63c-0.4-0.4-0.9-0.8-1.4-1.3c0.7-0.7,1.1-1.9,2.7-1.4
				C784.56,246.93,784.16,247.83,783.76,248.63z"/>
			<path class="st0-bull-white" d="M805.26,326.13c0.5-1.2,0.9-2.2,1.4-3.1c0.1-0.2,0.5-0.2,0.8-0.3c0.1,0.2,0.3,0.6,0.2,0.7
				c-0.4,0.9-0.8,1.8-1.4,2.7C806.36,326.23,805.76,326.13,805.26,326.13z"/>
			<path class="st0-bull-white" d="M779.46,304.53c0,0.3,0,0.5-0.1,0.8c-1.3-0.4-1.4,1.6-2.6,1.4c-0.2,0-0.6-0.8-0.5-0.8c0.4-0.4,0.9-0.8,1.4-1
				C778.26,304.53,778.86,304.63,779.46,304.53L779.46,304.53z"/>
			<path class="st0-bull-white" d="M796.36,329.13c0.7,0.7,1.5,1.4,2.1,2.1c0.1,0.1,0.1,0.6-0.1,0.7c-0.1,0.1-0.7,0.1-0.7,0
				c-0.1-0.9-1-0.9-1.6-1.4c-0.3-0.2-0.3-0.7-0.4-1.1C795.96,329.33,796.16,329.23,796.36,329.13z"/>
			<path class="st0-bull-white" d="M812.26,345.53c0.4,0.8,0.6,1.4,0.9,2c-0.4,0.3-0.8,0.5-1.3,0.8c-0.4-0.7-0.6-1.2-0.9-1.7
				C811.26,346.43,811.66,346.03,812.26,345.53z"/>
			<path class="st0-bull-white" d="M787.56,328.43c0-0.4-0.2-0.9-0.1-1.2c0.4-0.7,1-1.1,1.9-0.8C789.36,327.63,788.36,327.83,787.56,328.43z"/>
			<path class="st0-bull-white" d="M775.46,342.23c0,0.8,0,1.2,0,1.7c-0.5,0.2-1,0.3-1.5,0.5c-0.2-0.4-0.5-0.8-0.7-1.3
				C773.86,342.83,774.56,342.53,775.46,342.23z"/>
			<path class="st0-bull-white" d="M814.26,349.73c-1.1,0.1-1.8,2.1-3.3,0.7C811.96,349.53,812.96,349.13,814.26,349.73z"/>
			<path class="st0-bull-white" d="M781.56,324.43c-0.8-0.4-1.3-0.7-1.9-0.9c0.1-1.2,1-0.8,1.8-0.9C781.46,323.23,781.56,323.73,781.56,324.43z
				"/>
			<path class="st0-bull-white" d="M817.96,338.43c0.2,0.4,0.4,0.8,0.6,1.3c-0.4,0.4-0.7,0.8-1.3,1.6c-0.1-1-0.1-1.5-0.2-2.1
				C816.96,338.63,817.16,338.23,817.96,338.43L817.96,338.43z"/>
			<path class="st0-bull-white" d="M873.06,317.03c0.8-0.5,1.6-1,2.4-1.4c0.1,0,0.5,0.4,0.8,0.8c-1.2,0.4-2.1,0.7-3.1,1
				C873.26,317.23,873.16,317.13,873.06,317.03z"/>
			<path class="st0-bull-white" d="M751.86,316.33c0.3-0.2,0.5-0.4,0.9-0.7c0.3,0.7,0.5,1.3,0.8,2C751.96,317.63,751.96,317.63,751.86,316.33
				L751.86,316.33z"/>
			<path class="st0-bull-white" d="M776.96,335.93c0.6,1.2-0.1,2.4-0.3,3.6c-0.3,0.1-0.6,0.2-1,0.3C776.06,338.53,776.46,337.23,776.96,335.93
				C776.96,336.03,776.96,335.93,776.96,335.93z"/>
			<path class="st0-bull-white" d="M774.76,308.23c-1.2-0.8-1.2-0.8,0-2.9C774.76,306.53,774.76,307.33,774.76,308.23z"/>
			<path class="st0-bull-white" d="M785.26,334.93c-0.3,0.3-0.5,0.4-0.9,0.8c-0.1-1.2-1.6-1.3-1.4-3C783.86,333.53,784.46,334.23,785.26,334.93
				z"/>
			<path class="st0-bull-white" d="M862.46,332.83c0.5,1.5-0.2,2.2-1.4,2.8C860.96,334.43,861.36,333.63,862.46,332.83z"/>
			<path class="st0-bull-white" d="M823.06,347.33c0.3-0.7,0.5-1.3,0.8-2c0.5,0,1,0.1,1.4,0.1c0.1,0.1,0.2,0.3,0.3,0.4
				c-0.7,0.6-1.5,1.2-2.2,1.8C823.26,347.43,823.16,347.43,823.06,347.33z"/>
			<path class="st0-bull-white" d="M822.26,342.33c0.9,0.4,1.4,0.6,2,0.8c-0.3,0.5-0.6,1-1.1,1.6C822.76,343.93,822.56,343.33,822.26,342.33z"
				/>
			<path class="st0-bull-white" d="M787.26,312.93c0.8,0.1,0.9,0.4,0.4,1c-0.1,0.1,0.2,0.6,0.3,1c-0.5,0-0.8,0-1.3,0
				C786.96,314.23,787.16,313.53,787.26,312.93z"/>
			<path class="st0-bull-white" d="M869.56,350.93c0.5-0.8,1-1.5,1.5-2.3c0.1,0.1,0.3,0.2,0.4,0.3c-0.3,0.9-0.2,2-1.5,2.3
				C869.86,351.13,869.76,351.03,869.56,350.93z"/>
			<path class="st0-bull-white" d="M785.56,283.13c-0.3-1.2-0.5-2.4-0.8-3.6c0.3,0,0.6-0.1,1-0.2c0,1.3,0,2.6,0,3.8
				C785.66,283.13,785.66,283.13,785.56,283.13z"/>
			<path class="st0-bull-white" d="M784.36,344.03c-0.3-0.3-0.5-0.4-0.8-0.7c0.2-0.5,0.5-1,0.8-1.6c0.2,0.2,0.4,0.3,0.5,0.5
				C785.36,343.03,785.36,343.03,784.36,344.03z"/>
			<path class="st0-bull-white" d="M810.86,337.53c-0.5,0.4-0.9,0.9-1.7,1.6c-0.1-1-0.2-1.5-0.2-2.1c0.6,0,1.1,0,1.6,0
				C810.66,337.23,810.76,337.43,810.86,337.53z"/>
			<path class="st0-bull-white" d="M844.36,342.73c0.8,0,1.5,0,2.3,0c0.2,0.6-0.1,0.9-0.8,0.9C845.36,343.53,844.46,343.83,844.36,342.73z"/>
			<path class="st0-bull-white" d="M780.56,346.93c-0.8,0-1.6,0-2.6,0c0.1-0.4,0-0.8,0.2-1C779.16,345.33,779.76,346.43,780.56,346.93z"/>
			<path class="st0-bull-white" d="M782.06,338.63c0.6,0,0.9,1.1,1.6,0.2c0.3,0.3,0.7,0.7,1,1c-0.1,0.1-0.2,0.2-0.3,0.3
				C783.76,339.53,782.06,340.53,782.06,338.63L782.06,338.63z"/>
			<path class="st0-bull-white" d="M783.46,224.23c-0.3,0.9-0.5,1.7-0.8,2.7C782.16,225.73,782.16,225.73,783.46,224.23z"/>
			<path class="st0-bull-white" d="M795.56,220.73c0,1.9-1.4,1-2.8,1.3c0.6-0.5,0.8-0.9,1-1C794.46,220.83,795.06,220.83,795.56,220.73
				L795.56,220.73z"/>
			<path class="st0-bull-white" d="M868.46,311.33c1.4,0.7,1.4,0.9,0,2.2C868.46,312.73,868.46,312.13,868.46,311.33z"/>
			<path class="st0-bull-white" d="M787.26,308.63c0.2,1,0.4,1.8,0.7,2.7c-0.1,0.1-0.4,0.2-1,0.5C787.66,310.63,785.76,309.83,787.26,308.63z"
				/>
			<path class="st0-bull-white" d="M793.56,243.53c1,0.3,1.5,0.7,1.4,1.7C793.36,245.13,793.36,245.13,793.56,243.53z"/>
			<path class="st0-bull-white" d="M765.56,313.83c0.8,0.1,1.6,0.2,2.4,0.3c0.2,1-0.6,0.8-1.1,0.8C765.96,315.03,765.56,314.63,765.56,313.83z"
				/>
			<path class="st0-bull-white" d="M804.06,314.73c0,0.4,0,0.9-0.1,1.6c-0.6-0.2-1.5-0.3-1.6-0.6C802.16,314.53,803.66,315.23,804.06,314.73z"
				/>
			<path class="st0-bull-white" d="M862.06,350.93c0,0.6-0.1,1.3-0.1,2C860.96,352.13,860.96,352.13,862.06,350.93z"/>
			<path class="st0-bull-white" d="M817.96,338.43c-0.1-0.8-0.2-1.7-0.3-2.5c0.1,0,0.2-0.1,0.4-0.1c0.4,0.4,0.8,0.9,1.2,1.3
				C818.76,337.63,818.36,338.03,817.96,338.43L817.96,338.43z"/>
			<path class="st0-bull-white" d="M868.56,353.13c-0.9,0.4-1.4,0.6-2.2,0.9C867.26,352.43,867.26,352.43,868.56,353.13z"/>
			<path class="st0-bull-white" d="M833.66,321.13c0.6,0,1.3,0,1.9,0c0,0.1,0,0.2,0,0.3c-0.6,0-1.3,0-1.9,0
				C833.66,321.33,833.66,321.23,833.66,321.13z"/>
			<path class="st0-bull-white" d="M760.16,303.43c0,0.6-0.1,1.3-0.1,2C758.76,304.93,758.76,304.93,760.16,303.43z"/>
			<path class="st0-bull-white" d="M774.66,337.13c-0.2,0.5-0.5,1.1-0.8,1.8c-0.4-0.3-0.9-0.5-0.8-0.7
				C773.06,337.43,773.46,337.33,774.66,337.13z"/>
			<path class="st0-bull-white" d="M789.56,311.03c1.7,0.3,1.8,0.3,0.7,1.6C790.06,312.03,789.86,311.53,789.56,311.03z"/>
			<path class="st0-bull-white" d="M855.66,324.83c-0.3-1.6,1.2-0.9,1.9-1.9C857.56,324.63,856.26,324.33,855.66,324.83L855.66,324.83z"/>
			<path class="st0-bull-white" d="M793.06,301.73c-0.5,0.2-1,0.5-1.4,0.7c-0.1-0.2-0.2-0.5-0.3-0.7c0.5-0.2,1-0.3,1.5-0.5
				C792.86,301.43,792.96,301.53,793.06,301.73z"/>
			<path class="st0-bull-white" d="M869.16,338.43c-1.2,0.2-1.2,0.2-2-0.7C868.16,337.13,868.46,338.53,869.16,338.43z"/>
			<path class="st0-bull-white" d="M779.16,338.33c0,0.4,0,0.8,0,1.2c-0.1,0-0.1,0-0.2,0c-0.5,0-1.2,0-1.1-0.8
				C778.06,337.93,778.66,338.33,779.16,338.33z"/>
			<path class="st0-bull-white" d="M794.86,341.13c-0.5,0.1-1,0.3-1.5,0.4c0-0.2-0.2-0.5-0.1-0.5c0.5-0.2,0.9-0.4,1.4-0.6
				C794.76,340.63,794.76,340.83,794.86,341.13z"/>
			<path class="st0-bull-white" d="M785.76,332.13c-0.5,0.2-0.9,0.4-1.4,0.6c-0.1-0.2-0.2-0.4-0.2-0.5c0.2-1,1-0.3,1.4-0.6
				C785.56,331.73,785.66,331.93,785.76,332.13z"/>
			<path class="st0-bull-white" d="M781.86,337.33c0.1-0.4,0.2-0.9,0.4-1.5c0.3,0.3,0.9,0.5,0.8,0.7C783.06,337.23,782.66,337.63,781.86,337.33
				C781.86,337.23,781.86,337.33,781.86,337.33z"/>
			<path class="st0-bull-white" d="M786.46,290.83c0,0.5,0,0.9,0,1.4c-0.3-0.2-0.6-0.3-0.7-0.4C785.16,291.03,785.76,290.93,786.46,290.83z"/>
			<path class="st0-bull-white" d="M776.16,252.73c0,0.5-0.1,0.9-0.1,1.4c-0.1,0-0.2,0-0.4,0c0-0.5,0-0.9,0-1.4
				C775.86,252.73,775.96,252.73,776.16,252.73z"/>
			<path class="st0-bull-white" d="M805.46,328.33c0-0.4,0-0.9,0-1.5c0.8,0.6,0.6,1.1,0.4,1.5C805.76,328.33,805.66,328.33,805.46,328.33z"/>
			<path class="st0-bull-white" d="M800.66,327.23c-0.4,0.3-0.6,0.6-0.8,0.6c-0.2,0-0.6-0.3-0.5-0.4c0.1-0.3,0.2-0.6,0.5-0.7
				C799.86,326.73,800.16,327.03,800.66,327.23z"/>
			<path class="st0-bull-white" d="M764.36,342.23c0.5-0.2,0.8-0.5,0.9-0.5c0.2,0.1,0.4,0.4,0.5,0.7c0,0.1-0.4,0.4-0.5,0.4
				C764.96,342.83,764.76,342.53,764.36,342.23z"/>
			<path class="st0-bull-white" d="M795.06,327.13c-0.4,0.1-0.7,0.2-0.9,0.2c-0.2-0.1-0.3-0.4-0.4-0.6c0.3-0.1,0.5-0.3,0.8-0.4
				C794.76,326.43,794.86,326.83,795.06,327.13z"/>
			<path class="st0-bull-white" d="M775.66,339.83c0.3-0.1,0.6-0.2,1-0.3c0.5,0.2,0.6,0.6,0.1,0.8c-0.7,0.2-1.4,0-2.2,0
				C774.76,339.73,775.06,339.33,775.66,339.83z"/>
			<path class="st0-bull-white" d="M792.16,209.83c-0.4-0.3-0.9-0.5-1.5-0.9C791.66,208.73,791.66,208.73,792.16,209.83z"/>
			<path class="st0-bull-white" d="M855.66,324.83c-0.7,0.2-1.4,0.5-2.1,0.7C853.96,324.43,853.96,324.43,855.66,324.83L855.66,324.83z"/>
			<path class="st0-bull-white" d="M822.56,334.73c-0.5-0.3-0.9-0.6-1.5-1c1.2-0.7,1.3,0.2,1.7,0.6C822.76,334.43,822.66,334.53,822.56,334.73z
				"/>
			<path class="st0-bull-white" d="M764.96,304.03c-0.2-0.5-0.4-0.8-0.4-1s0.4-0.4,0.5-0.4c0.2,0.1,0.6,0.4,0.6,0.6
				C765.76,303.43,765.36,303.63,764.96,304.03z"/>
			<path class="st0-bull-white" d="M811.66,328.13c0.3,0.5,1.2,0.6,0.7,1.9c-0.5-0.6-0.8-1.1-1.1-1.5
				C811.46,328.33,811.56,328.23,811.66,328.13z"/>
			<path class="st0-bull-white" d="M757.46,337.83c1.5-0.6,1.5,0.3,2,0.6c-0.1,0.1-0.1,0.2-0.2,0.4C758.76,338.53,758.26,338.33,757.46,337.83z
				"/>
			<path class="st0-bull-white" d="M788.96,322.53c-0.4,0.3-0.7,0.7-0.8,0.6c-0.2,0-0.4-0.4-0.6-0.6c0-0.1,0.3-0.4,0.5-0.4
				C788.36,322.13,788.56,322.33,788.96,322.53z"/>
			<path class="st0-bull-white" d="M857.46,351.93c-0.4,0.2-0.7,0.4-0.9,0.4s-0.5-0.4-0.5-0.5c0.1-0.3,0.3-0.6,0.6-0.6
				C856.76,351.23,857.06,351.63,857.46,351.93z"/>
			<path class="st0-bull-white" d="M760.06,316.63c-0.4,0.3-0.8,0.6-1.2,1c-0.1-0.4-0.3-0.6-0.2-0.7c0.4-0.3,0.8-0.5,1.2-0.8
				C759.86,316.33,759.96,316.43,760.06,316.63z"/>
			<path class="st0-bull-white" d="M809.76,343.73c-0.5,0.2-0.7,0.4-1,0.3c-0.2,0-0.5-0.4-0.4-0.5c0.1-0.2,0.4-0.6,0.6-0.6
				S809.36,343.33,809.76,343.73z"/>
			<path class="st0-bull-white" d="M849.16,343.13c1.2-0.3,1.9-0.4,1.7,0.9C850.36,343.83,849.96,343.53,849.16,343.13z"/>
			<path class="st0-bull-white" d="M819.36,344.23c0.6,0.2,1.1,0.4,1.6,0.6c-0.1,0.2-0.2,0.4-0.3,0.4
				C820.16,345.23,819.46,345.53,819.36,344.23z"/>
			<path class="st0-bull-white" d="M813.76,335.53c0,0.4,0,0.8,0,1.2c-0.1,0-0.2,0-0.3,0c0-0.4-0.1-0.8-0.1-1.2
				C813.46,335.53,813.66,335.53,813.76,335.53z"/>
			<path class="st0-bull-white" d="M812.76,319.63c-0.3,0.3-0.5,0.5-0.8,0.8c-0.1-0.1-0.2-0.2-0.4-0.3c0.2-0.3,0.5-0.6,0.7-0.9
				C812.46,319.33,812.56,319.43,812.76,319.63z"/>
			<path class="st0-bull-white" d="M866.26,320.13c-0.3,0.2-0.5,0.5-0.9,0.8C864.96,319.93,865.26,319.63,866.26,320.13z"/>
			<path class="st0-bull-white" d="M779.06,321.53c0.4-0.5,0.7-0.8,1.1-1.2C780.36,321.33,780.16,321.63,779.06,321.53z"/>
			<path class="st0-bull-white" d="M808.76,224.73c0,0.5,0,0.9,0,1.4c-0.1,0-0.2,0-0.3,0c0-0.5,0-0.9,0-1.4
				C808.56,224.73,808.66,224.73,808.76,224.73z"/>
			<path class="st0-bull-white" d="M815.96,341.53c-0.2-0.3-0.5-0.5-0.8-0.9C816.16,340.33,816.46,340.63,815.96,341.53z"/>
			<path class="st0-bull-white" d="M795.66,300.83c1-0.4,1.3-0.1,1.1,1C796.36,301.43,796.06,301.23,795.66,300.83z"/>
			<path class="st0-bull-white" d="M790.86,246.73c0.3-0.2,0.5-0.5,1.1-1c-0.1,0.7-0.1,1.1-0.3,1.3c-0.1,0.1-0.5,0.1-0.7,0.1
				C790.96,246.93,790.86,246.83,790.86,246.73z"/>
			<path class="st0-bull-white" d="M792.96,323.23c-0.3-0.3-0.6-0.6-0.9-0.9C792.96,322.23,792.96,322.23,792.96,323.23z"/>
			<path class="st0-bull-white" d="M774.06,259.33c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1-0.2-0.3-0.4-0.3-0.4c0.2-0.2,0.5-0.3,0.7-0.5
				C773.86,259.13,773.96,259.23,774.06,259.33z"/>
			<path class="st0-bull-white" d="M864.96,316.73c-0.4-0.3-0.7-0.5-1-0.7C864.86,315.63,864.86,315.63,864.96,316.73z"/>
			<path class="st0-bull-white" d="M879.06,313.73c0.2,0.9,0.1,1.3-1.1,1.1C878.36,314.43,878.66,314.13,879.06,313.73z"/>
			<path class="st0-bull-white" d="M859.86,314.63c0.3-0.2,0.6-0.4,0.9-0.6c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.2-0.5,0.3-0.7,0.5
				C859.96,314.83,859.96,314.73,859.86,314.63z"/>
			<path class="st0-bull-white" d="M782.36,312.23c0.4,0.2,0.8,0.3,1.2,0.5c0,0.1,0,0.2-0.1,0.2c-0.4-0.1-0.9-0.1-1.3-0.2
				C782.16,312.63,782.26,312.43,782.36,312.23z"/>
			<path class="st0-bull-white" d="M852.16,348.23c0.2,0.2,0.5,0.3,0.7,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2-0.2-0.4-0.4-0.7-0.6
				C851.96,348.43,852.06,348.33,852.16,348.23z"/>
			<path class="st0-bull-white" d="M859.56,311.33c0.5,0.1,1.3-0.2,1.2,1.2C860.26,312.03,859.96,311.63,859.56,311.33L859.56,311.33z"/>
			<path class="st0-bull-white" d="M819.36,346.63c0-0.7,0.2-1,1.3-0.5c-0.4,0.4-0.7,0.7-0.9,0.9C819.66,346.93,819.56,346.83,819.36,346.63z"
				/>
			<path class="st0-bull-white" d="M800.66,299.83c-0.4,0.2-0.8,0.4-1.3,0.7C799.56,299.73,799.56,299.73,800.66,299.83z"/>
			<path class="st0-bull-white" d="M841.66,344.43c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1-0.1-0.3-0.4-0.3-0.4c0.2-0.2,0.5-0.3,0.7-0.5
				C841.46,344.23,841.56,344.33,841.66,344.43z"/>
			<path class="st0-bull-white" d="M817.86,343.53c-0.3,0.9-0.7,1.2-1.8,0.4C816.96,343.73,817.36,343.63,817.86,343.53z"/>
			<path class="st0-bull-white" d="M827.46,342.83c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
				C827.16,343.03,827.26,343.03,827.46,342.83z"/>
			<path class="st0-bull-white" d="M814.56,341.73c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
				C813.76,341.33,813.96,341.43,814.56,341.73z"/>
			<path class="st0-bull-white" d="M765.26,346.13c0.3-0.1,0.4-0.3,0.5-0.3c0.1,0,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
				C765.66,346.43,765.46,346.33,765.26,346.13z"/>
			<path class="st0-bull-white" d="M823.46,338.93c0.5,0.7,0.7,0.9,0.8,1.1c-0.2,0.1-0.4,0.4-0.6,0.4s-0.4-0.3-0.5-0.5
				C823.16,339.63,823.26,339.43,823.46,338.93z"/>
			<path class="st0-bull-white" d="M842.66,346.93c-0.6,0-1.2,0-1.8,0c0-0.1,0-0.2,0-0.3c0.6,0,1.3,0,1.9,0
				C842.76,346.73,842.76,346.83,842.66,346.93z"/>
			<path class="st0-bull-white" d="M765.16,339.23c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C764.76,338.83,764.86,339.03,765.16,339.23z"/>
			<path class="st0-bull-white" d="M795.66,220.73c0.7-0.4,1.4-0.7,2,0.7C796.76,221.13,796.16,220.93,795.66,220.73
				C795.56,220.73,795.66,220.73,795.66,220.73z"/>
			<path class="st0-bull-white" d="M822.96,221.33c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C822.56,221.03,822.76,221.13,822.96,221.33z"/>
			<path class="st0-bull-white" d="M816.56,349.43c-0.4,0-0.8-0.1-1.4-0.2C816.06,348.33,816.26,349.03,816.56,349.43L816.56,349.43z"/>
			<path class="st0-bull-white" d="M786.06,350.53c-0.1-0.3-0.2-0.5-0.2-0.6s0.2-0.2,0.4-0.3c0.1,0.2,0.2,0.3,0.2,0.5
				C786.46,350.23,786.26,350.33,786.06,350.53z"/>
			<path class="st0-bull-white" d="M769.86,350.23c-0.2,0.2-0.4,0.3-0.5,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.2-0.3,0.4-0.3
				C769.46,350.03,769.56,350.13,769.86,350.23z"/>
			<path class="st0-bull-white" d="M817.76,335.43c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.2-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C817.96,335.03,817.86,335.23,817.76,335.43z"/>
			<path class="st0-bull-white" d="M844.96,352.83c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.2-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C844.56,352.53,844.66,352.63,844.96,352.83z"/>
			<path class="st0-bull-white" d="M796.56,353.53c-0.4,0.2-0.9,0.4-1.3,0.6c-0.1-0.1-0.1-0.3-0.2-0.4c0.4-0.2,0.9-0.4,1.3-0.6
				C796.46,353.23,796.56,353.43,796.56,353.53z"/>
			<path class="st0-bull-white" d="M784.56,354.03c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
				C784.76,354.43,784.66,354.23,784.56,354.03z"/>
			<path class="st0-bull-white" d="M834.26,332.93c0.6,0,1.3,0,1.9,0c0,0.1,0,0.2,0,0.2c-0.6,0-1.3,0-1.9,0
				C834.26,333.13,834.26,333.03,834.26,332.93z"/>
			<path class="st0-bull-white" d="M824.36,188.43c-0.2,0.1-0.3,0.2-0.5,0.2l-0.2-0.3c0.1-0.1,0.3-0.2,0.4-0.3
				C824.16,188.13,824.16,188.23,824.36,188.43z"/>
			<path class="st0-bull-white" d="M790.76,333.23c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
				C790.46,333.03,790.56,333.13,790.76,333.23z"/>
			<path class="st0-bull-white" d="M788.66,237.43c0.3,0.3,0.5,0.8,0.3,1.5C788.86,238.13,788.76,237.73,788.66,237.43
				C788.76,237.33,788.66,237.43,788.66,237.43z"/>
			<path class="st0-bull-white" d="M749.16,328.23c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1-0.1-0.3-0.1-0.3-0.2c0-0.3,0.1-0.5,0.2-0.8
				C748.96,328.13,749.06,328.13,749.16,328.23z"/>
			<path class="st0-bull-white" d="M792.86,327.43c0.2,0.2,0.3,0.4,0.3,0.5s-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C792.56,327.83,792.66,327.73,792.86,327.43z"/>
			<path class="st0-bull-white" d="M798.06,327.23c-0.2,0.3-0.4,0.5-0.5,0.6c-0.2-0.1-0.5-0.3-0.5-0.4c0-0.2,0.2-0.4,0.3-0.6
				C797.56,326.93,797.76,327.03,798.06,327.23z"/>
			<path class="st0-bull-white" d="M790.36,327.93c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C790.66,327.53,790.46,327.73,790.36,327.93z"/>
			<path class="st0-bull-white" d="M820.26,326.33c0.1,0.3,0.3,0.5,0.2,0.6c0,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.1-0.3
				C819.96,326.73,820.06,326.63,820.26,326.33z"/>
			<path class="st0-bull-white" d="M810.26,323.23c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1-0.2-0.3-0.3-0.2-0.5c0-0.1,0.2-0.3,0.3-0.4
				C810.06,322.93,810.16,323.03,810.26,323.23z"/>
			<path class="st0-bull-white" d="M802.36,319.63c0.1,0.2,0.2,0.3,0.3,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.2-0.2-0.4-0.3
				C802.06,319.83,802.16,319.73,802.36,319.63z"/>
			<path class="st0-bull-white" d="M784.16,319.13c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C783.86,318.83,783.96,318.93,784.16,319.13z"/>
			<path class="st0-bull-white" d="M773.76,262.63c-0.2,0.2-0.3,0.3-0.4,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C773.46,262.33,773.56,262.53,773.76,262.63z"/>
			<path class="st0-bull-white" d="M751.86,316.23c-0.4,0-0.8-0.1-1.3-0.1C751.26,315.63,751.26,315.63,751.86,316.23
				C751.86,316.33,751.86,316.23,751.86,316.23z"/>
			<path class="st0-bull-white" d="M870.16,313.73c0.2,0.6,0.4,0.7,0.4,0.9c0,0.1-0.3,0.3-0.4,0.3s-0.4-0.2-0.4-0.3
				C869.66,314.43,869.86,314.33,870.16,313.73z"/>
			<path class="st0-bull-white" d="M778.36,314.73c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C778.06,314.33,778.16,314.53,778.36,314.73z"/>
			<path class="st0-bull-white" d="M769.86,314.03c-0.1,0.5,0,1.2-1.1,0.7C769.26,314.43,769.56,314.23,769.86,314.03L769.86,314.03z"/>
			<path class="st0-bull-white" d="M769.96,314.13c0.7-0.6,1.5-0.4,2.2,0.2C771.36,314.23,770.66,314.13,769.96,314.13
				C769.86,314.03,769.96,314.13,769.96,314.13z"/>
			<path class="st0-bull-white" d="M826.86,313.13c-0.2,0.2-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
				C826.46,312.83,826.66,312.93,826.86,313.13z"/>
			<path class="st0-bull-white" d="M788.46,273.23c-0.1-0.4-0.2-0.9-0.3-1.3c0.1,0,0.2-0.1,0.3-0.1c0.2,0.4,0.3,0.8,0.5,1.2
				C788.86,273.13,788.66,273.13,788.46,273.23z"/>
			<path class="st0-bull-white" d="M771.86,306.43c1,0.8,1,0.8,0.9,2.2C772.46,307.83,772.26,307.33,771.86,306.43z"/>
			<path class="st0-bull-white" d="M792.86,307.23c-0.1-0.3-0.3-0.5-0.3-0.6s0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.5
				C793.16,306.83,792.96,307.03,792.86,307.23z"/>
			<path class="st0-bull-white" d="M783.36,305.83c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
				C783.56,306.23,783.46,306.13,783.36,305.83z"/>
			<path class="st0-bull-white" d="M643.26,324.33c-1.9,0.9-1.5,2.6-1.4,4.1c2.3,1.4,2.4,2.1,0.5,3.6c-0.4-0.3-0.8-0.7-1.2-1s-0.8-0.7-1.4-1.2
				c-0.3,0.8-0.4,1.3-0.6,1.8c-1.1-0.2-2.3-0.2-3.3-0.6c-0.8-0.3-0.8-1.2-0.5-2c0.5-0.2,0.9-0.4,1.5-0.6c0.3,0.6,0.6,1.1,0.9,1.7
				c0.7-0.3,1.3-0.5,1.9-0.7c0.3-1.5,0.3-1.5-0.6-3.4c-1.8,0.2-3.7-0.6-5.5,0.5c-0.4,0.3-1.2,0.3-1.5-0.7c0.8-0.5,1.7-1,2.6-1.3
				c0.5-0.2,1.2-0.2,1.8-0.1c1.5,0.4,2.6,0,3.3-1.3c0.5-1,1.2-2,1-3.2c-0.1-0.3,0-0.7,0.2-1c0.9-1.1,0.8-2.5,1.1-3.8
				c0.6-1.9,0.2-3.9,0.6-5.8c0.2-1,0.4-2,0.5-3c0.1-1.1-0.1-2.3,0.5-3.4c0.2-0.3-0.1-0.8-0.2-1.2c0-0.2-0.3-0.4-0.3-0.5
				c1-2.8-0.5-5.6-0.2-8.5c0.2-1.6-0.3-3.2-0.4-4.8c-0.1-2.8-0.3-5.6-0.2-8.4c0-1,0.3-1.8,0.1-2.9c-0.2-1.2,0.7-2.6,1-3.9
				c0.2,0,0.3,0,0.5,0c0.1,0.4,0.3,1,0.1,1.2c-0.8,0.7-0.6,1.5-0.6,2.3c0,2.3-0.1,4.7,0,7c0,1.4-0.3,2.8,0.6,4.2
				c0.3,0.5,0.1,1.5,0.1,2.2c0,2.6,0,2.6,1.4,4.2c0,1.4,0,2.6,0,4.2c-0.8,0.7-1.2,1.6-0.6,3c0.3,0.6-0.2,1.6-0.3,2.5
				c-0.2,3.6-0.4,7.1-0.6,10.7c0,0.2,0,0.5-0.1,0.6c-0.9,1.2-0.8,2.7-1.3,4c-0.6,1.5-0.4,2.8-0.5,4.2
				C642.26,323.73,642.66,324.03,643.26,324.33L643.26,324.33z"/>
			<path class="st0-bull-white" d="M614.66,282.83c0,0.3,0,0.6,0.1,0.8c0.4,0.5,0.9,1,1.5,1.8c-0.7,0.1-1.2,0.1-1.7,0.2
				c-0.2,1.4-0.2,1.4,0.8,2.5c-0.5,0.6-1.1,1.2-1.6,1.8c0.3,0.4,0.7,0.8,1,1.2c-0.2,0.4-0.4,0.7-0.7,1.2c-0.4-0.1-0.9-0.3-1.3-0.4
				c-0.2,0.2-0.3,0.3-0.3,0.4c0,0.9-0.1,1.8-0.1,2.7c0.1,0.9-0.3,1.4-1.3,1.5c0.5,1.2,1.2,2.2,0.2,3.5c-0.2,0.3-0.2,0.9,0,1.3
				c0.5,1.3,1.1,2.6,1.7,3.8c0.1,0.2,0,0.5,0,0.7c-0.8,1.4-2.3-1-3,1c0.7,0.3,1.5,0.7,2.3,1.1c-0.6,1,0.4,2.5-1.1,3.3
				c0.9,1,0.7,1.9-0.2,2.8c0.7,0.9,1.4,1.7,2.3,2.8c-1.1-0.3-1.8-0.5-2.6-0.7c-0.4-2-0.8-4.1-1.2-6.4c-0.8-0.5-2.7,0.8-3.3-1.2
				c0.5-0.1,1-0.1,1.4-0.3c1-0.4-0.1-0.8-0.1-1.3c0-0.4,0-0.8,0.1-1.2c0.3-1.2,0.7-2.3,0.9-3.5c0.2-1.4,0.3-2.6,0-3.9
				c2.1-2.3,1.2-5.2,1.6-7.9c1.4,0.9,1.4,0.9,2.9,0.9c-0.2-0.3-0.3-0.6-0.6-0.8c-2-1.5-2.1-1.6-1.6-4.4c0.8,0.2,0.8,0.2,1.3,0.9
				c0.9-0.6,1.1-2.2,0.3-3.6C612.96,282.63,613.76,282.43,614.66,282.83L614.66,282.83z"/>
			<path class="st0-bull-white" d="M616.36,267.93c-1.2,0.7-1.8-0.4-2.3-1.1c-0.4-0.6-0.8-1.5,0.1-2.2c0.1-0.1,0.2-0.2,0.2-0.3
				c-0.3-1.1-0.5-2.4-1.1-3.3c-0.5-0.7-0.9-1.9-2.2-1.8c-0.1,0-0.2-0.6-0.4-1.2c0.9,0.3,1.4,0.4,2.1,0.7c0.3-0.4,0.5-0.8,0.8-1.3
				c0.3,0.1,0.5,0.1,0.6,0.2c0.7,0.7,1.3,1.4,2,2.1s1.3,1.6,2.6,1.1c0.4-0.2,1,0.2,1.5,0.4c0.6,1.8-1.7,2-1.6,3.4
				c0,0.8,1.6,1.3,0.5,2.4c-0.1,0.1,1.7,1.9,2.2,1.9c0.4,0.1,0.8,0.2,1.2,0.3v-0.1c-0.2,0.2-0.4,0.6-0.7,0.7c-0.7,0.1-1.5,0.2-2.2,0
				c-0.4-0.1-0.8-0.6-1.1-1C617.96,267.93,617.46,267.23,616.36,267.93L616.36,267.93z M618.16,262.03c-1-0.4-1.7-0.7-2.8-1.1
				c0.4,1.2,0.7,1.9,1.1,3.1C617.16,263.23,617.56,262.73,618.16,262.03z M615.16,264.83c-0.1,0.1-0.1,0.3-0.2,0.4
				c0.7,0.4,0.4,2,1.8,1.6c0.3-0.1,0.5-0.9,0.7-1.5C616.56,265.13,615.86,265.03,615.16,264.83z"/>
			<path class="st0-bull-white" d="M612.36,330.43c-0.8-0.2-1.5-0.4-2.5-0.7c0.5-0.7,0.9-1.2,1.3-1.9c-0.4-0.7-2.1-1.1-0.5-2.3
				c-0.4-0.2-0.7-0.3-1-0.4c1-1.8-0.2-3.3-0.6-5c0.1-0.1,0.4-0.2,0.6-0.3c-0.1-0.8-0.2-1.7-0.3-2.7c0.7,0,1.2,0,1.9,0
				c-0.2,1.3-0.4,2.5-0.5,3.7c0,0.9,0.4,1.8,0.4,2.8c0.1,1.1,0.1,2.1,0.9,3C613.06,327.63,612.86,329.13,612.36,330.43z"/>
			<path class="st0-bull-white" d="M686.86,332.63c-0.9-0.5-1.1,0.4-1.4,1c-1.1,1.8-1.8,2-4,1.5c0.3-2.2,1.9-0.5,2.9-1.1
				c0.2-0.3,0.4-0.8,0.6-1.3c-0.5-0.3-1-0.6-1.9-1.1c0.8-0.4,1.2-0.7,1.8-0.8c1.4-0.4,1.5-0.6,1.1-2.1c-0.1-0.3-0.1-0.7,0-0.8
				c0.9-0.3,0.3-1.1,0.5-1.6c0.1-0.2,0.5-0.3,1.1-0.6C687.26,328.23,687.06,330.43,686.86,332.63z"/>
			<path class="st0-bull-white" d="M619.76,282.93c0.3-1.7,0.3-1.7,2-1.9c0.3,0,0.5-0.4,0.9-0.8c0.5,0.2,1.1,0.5,1.8,0.8
				c0.7-0.7,1.3-1.3,2.3-2.3c-0.3,2-0.6,3.6-0.9,5.3c0,0.1-0.5,0.1-0.9,0.2c0-0.5,0-0.8,0-1.1c0-1.2-0.3-1.4-1.4-0.9
				c-0.2,0.1-0.6,0.4-0.7,0.3C621.66,281.63,620.76,283.03,619.76,282.93z"/>
			<path class="st0-bull-white" d="M643.26,324.33c-0.3-0.7,0-1.2,0.6-1.2c1.6,0.2,2.5-0.7,3.5-1.8c0.4,0.8,0.7,1.4,1.8,0.6
				c0.6-0.5,1.7-0.2,2.6-0.2c0,0.1,0.1,0.3,0.1,0.4c-0.9,0.3-1.8,0.8-2.6,0.8c-1.3,0-1.8,0.6-2.2,1.7c-0.2,0.5-0.1,1.6-1.4,1.1
				c0.1-0.5,0.1-1,0.2-1.7C644.96,324.13,644.06,324.23,643.26,324.33L643.26,324.33z"/>
			<path class="st0-bull-white" d="M627.76,278.23c-0.8-0.2-1.4-0.4-2.1-0.6c0.5-2.5-2.3-0.7-2.7-2.1c-0.1-1,0.9-1.2,1.3-2
				c0.4,0.5,0.6,0.8,1.1,1.4c0-1.2,0-2,0-2.9c1.2,0.2,1.4,1,1.2,1.7c-0.5,1.4,0.3,2.4,0.8,3.5
				C627.46,277.53,627.56,277.83,627.76,278.23z"/>
			<path class="st0-bull-white" d="M621.36,310.73c-0.4,0.1-0.9,0.1-1.5,0.2c0.1-0.8,0.2-1.4,0.3-2c-1.5-0.6-2.9-1.4-3.4-3.3
				c1.6-1.2,2.4,0.7,3.7,1c-0.1,0.4-0.2,0.8-0.4,1.3c0.4,0.1,0.8,0.2,1.3,0.3C621.36,309.03,621.36,309.83,621.36,310.73
				L621.36,310.73z"/>
			<path class="st0-bull-white" d="M613.86,311.23c1.5-1.1,1.6-1.1,2.3,0.1c0.5-0.1,0.9-0.2,1.4-0.4c0.1,1,0.2,1.9,0.2,2.8
				c0,0.8-0.6,1.1-1.1,1.3C615.76,313.73,614.86,312.53,613.86,311.23z"/>
			<path class="st0-bull-white" d="M606.06,275.93c1.4,0.9,2.6,0.4,3.7-0.4c0.4,0.4,0.5,0.6,0.8,2.2c-1.1,0.2-2.2,0.8-3.2,0.6
				c-0.6-0.1-1-1.3-1.5-2C605.86,276.13,605.96,276.03,606.06,275.93z"/>
			<path class="st0-bull-white" d="M615.76,306.53c-0.2,0.5-0.4,0.9-0.5,1.2c0.5,0.3,1,0.5,1.7,0.9c-1.2,0.8-2,1.4-3,0.5c-0.7-0.5-1-1.2-0.7-2
				C613.66,306.23,614.56,306.13,615.76,306.53z"/>
			<path class="st0-bull-white" d="M608.76,282.73c0.5-0.3,0.8-0.5,1.4-0.9c0.4,0.4,0.9,0.8,1,1.2c0.1,0.4-0.1,1.1-0.4,1.4
				c-0.2,0.2-0.9,0.3-1.2,0.1c-0.7-0.3-1.3-0.7-1.8-1.2c-0.4-0.4-0.5-1.1-0.5-1.8C607.66,281.83,608.16,282.23,608.76,282.73z"/>
			<path class="st0-bull-white" d="M620.16,295.23c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-0.4-1-0.8-0.3-1.3c0.6-0.5,0.9,0.2,1.3,0.5
				c0.8,0.5,1,1.2,0.8,1.9c-0.8,0.7-1.5,1.2-2.4,2c-0.1-0.2-0.4-0.5-0.8-1C618.96,296.23,619.46,295.83,620.16,295.23z"/>
			<path class="st0-bull-white" d="M616.36,301.13c-2.1-0.7-3.1-1.9-2.9-3.8C615.26,297.93,616.56,299.63,616.36,301.13L616.36,301.13z"/>
			<path class="st0-bull-white" d="M614.66,282.83c-0.3-0.5-0.7-1-1.1-1.6c0.9-0.5,1.6-1.1,1.4-2.3c0.8,0.1,1.5,0.2,2.3,0.3
				C616.46,280.53,615.56,281.73,614.66,282.83L614.66,282.83z"/>
			<path class="st0-bull-white" d="M616.36,268.03c1.2,0.5,1.8,1.5,2.4,2.9c-0.6,0.1-1.4,0.3-1.6,0.2c-0.5-0.8-1.3-0.7-2.2-0.6
				C615.46,269.53,615.96,268.73,616.36,268.03C616.36,267.93,616.36,268.03,616.36,268.03z"/>
			<path class="st0-bull-white" d="M621.36,310.73c0.3,0.5,0.7,1,1,1.5c0.9,1.8,0.6,2.3-1.6,2.2C619.76,313.03,621.06,311.93,621.36,310.73
				L621.36,310.73z"/>
			<path class="st0-bull-white" d="M621.66,271.33c-0.6,0.4-1,0.6-1.4,0.9c0.3,0.5,0.5,1,0.8,1.5c-1.4,0.8-1.9-0.9-3.1-0.7
				C618.76,271.53,619.76,270.73,621.66,271.33z"/>
			<path class="st0-bull-white" d="M626.36,325.43c0.8,1.3,0.8,1.3,2.6,0.7c-0.2,0.4-0.2,0.8-0.4,0.9c-1.3,0.4-2.5,0.8-3.8,1.2
				c-0.1,0-0.4-0.1-0.5-0.2C624.96,326.13,624.96,326.13,626.36,325.43z"/>
			<path class="st0-bull-white" d="M639.56,338.13c0.1,1,0.3,2,0.2,3c-0.1,1.3-1.3,1.7-2.2,2.4c0.3-1.1,0.3-2.3,1.2-3.2
				c0.7-0.6,0.5-1.1-0.2-1.7C639.06,338.43,639.36,338.33,639.56,338.13C639.66,338.13,639.56,338.13,639.56,338.13z"/>
			<path class="st0-bull-white" d="M664.16,332.33c1.7-1,2.6,0.4,4,1c-0.3,0.5-0.5,0.9-0.9,1.6C666.36,333.73,665.56,332.73,664.16,332.33z"/>
			<path class="st0-bull-white" d="M690.06,332.33c1-0.3,2-0.4,3.1-0.1c1,0.3,2.1,0.2,3.3,0.3c-1.5,0.7-2.4,0.9-3.5,0.8c-0.2,0-0.5-0.1-0.7-0.2
				C691.26,332.53,690.96,332.43,690.06,332.33z"/>
			<path class="st0-bull-white" d="M615.96,294.13c-0.6-0.3-1.3-0.6-0.8-1.4c0.3-0.6,0.8-1.1,1.2-1.6
				C617.76,292.33,617.76,292.33,615.96,294.13z"/>
			<path class="st0-bull-white" d="M617.46,298.13c1-0.3,1.4,0.3,1.7,1.2c0.2,0.6,0.5,1.1,0.7,1.6C618.66,301.33,617.86,300.53,617.46,298.13z"
				/>
			<path class="st0-bull-white" d="M607.26,340.13c0.4-1,0.6-1.6,0.9-2.4c0.6,0.6,0.9,0.9,1.2,1.2c0.2,0.2,0.3,0.5,0.5,0.7
				c-0.3,0.2-0.5,0.4-0.8,0.5C608.76,340.33,608.26,340.23,607.26,340.13z"/>
			<path class="st0-bull-white" d="M630.96,354.03c-1.2-1.2-0.9-2.9,1.1-4.3C631.66,351.33,631.26,352.53,630.96,354.03z"/>
			<path class="st0-bull-white" d="M627.96,332.53c0,1.2,0,2.2,0,3.5c-0.4-0.6-0.7-1-0.9-1.5c-0.3-0.6-0.4-1.2-0.7-2
				C626.86,332.53,627.46,332.53,627.96,332.53z"/>
			<path class="st0-bull-white" d="M623.66,310.93c-1.4-0.2-0.6-1.2-0.9-1.9c0.8-0.4,1.5-0.7,2.5-1.1
				C624.66,309.13,624.26,309.93,623.66,310.93z"/>
			<path class="st0-bull-white" d="M622.56,269.23c-0.3-1.3,0.6-1,1.2-0.8c0.7,0.2,1.3,0.6,2.1,0.9c0,0.4,0,1,0,1.6c-0.1,0-0.2,0.1-0.2,0.1
				c-0.2-0.4-0.4-0.9-0.6-1.4C624.26,269.43,623.36,269.23,622.56,269.23C622.46,269.13,622.56,269.23,622.56,269.23z"/>
			<path class="st0-bull-white" d="M616.36,301.13c0.5,0.8,1,1.6,1.4,2.4c0.1,0.2-0.1,0.6-0.1,0.9c-0.3-0.2-0.7-0.2-0.9-0.5
				c-0.4-0.5-0.6-1-0.9-1.6C615.96,302.03,616.16,301.63,616.36,301.13L616.36,301.13z"/>
			<path class="st0-bull-white" d="M609.16,288.23c-0.9,0.2-1.8,0.3-2.7,0.5c-0.1-0.4-0.2-0.8-0.4-1.3c1.1,0.2,2.1,0.3,3.1,0.5
				C609.16,288.03,609.16,288.13,609.16,288.23z"/>
			<path class="st0-bull-white" d="M641.96,335.43c-0.3,0.6-0.6,1.1-0.9,1.7c-0.3,0.7-0.6,1.2-1.5,0.9l0,0c0.1-0.2,0.2-0.5,0.2-0.7
				C640.06,335.93,640.36,335.63,641.96,335.43L641.96,335.43z"/>
			<path class="st0-bull-white" d="M617.76,295.23c-0.3,1.2-0.3,1.2-1.7,2c-0.1-0.7-0.3-1.3-0.4-2C616.46,295.23,617.06,295.23,617.76,295.23z"
				/>
			<path class="st0-bull-white" d="M609.86,348.43c-0.8,0.5-1.7,0.9-2.9,1.6C608.06,348.03,608.26,347.93,609.86,348.43z"/>
			<path class="st0-bull-white" d="M616.46,274.83c-0.6-0.4-1.2-0.8-1.8-1.3c-0.2-0.2-0.1-0.7-0.1-1c0.3,0.1,0.8,0.1,1,0.3
				C616.16,273.43,616.96,273.93,616.46,274.83L616.46,274.83z"/>
			<path class="st0-bull-white" d="M649.66,328.93c0,1.2-0.9,1.6-1.6,2.4c-0.2-0.7-0.4-1.1-0.6-1.6C648.06,329.53,648.76,329.33,649.66,328.93z
				"/>
			<path class="st0-bull-white" d="M631.96,276.73c-0.1,1-0.2,1.8-0.3,2.7c-0.4,0-0.8,0-1.2,0c0-0.6,0-1.2,0-2
				C630.76,277.23,631.26,277.03,631.96,276.73z"/>
			<path class="st0-bull-white" d="M608.16,269.83c1.2,0.2,2.1-0.4,2.6,0.9C609.66,271.33,608.96,271.33,608.16,269.83z"/>
			<path class="st0-bull-white" d="M620.06,290.03c0.1,0.4,0.2,0.8,0.3,1.2c-1,0.4-1.7,0.3-2.4-0.7C618.76,290.33,619.46,290.13,620.06,290.03
				L620.06,290.03z"/>
			<path class="st0-bull-white" d="M605.56,307.73c-0.2,0.5-0.3,1-0.5,1.5c-0.5-0.2-1-0.4-1.5-0.7C603.86,307.53,604.56,307.33,605.56,307.73z"
				/>
			<path class="st0-bull-white" d="M630.36,273.23c1.1,0.4,1.1,0.4,0.8,2.5C629.66,275.23,631.26,273.93,630.36,273.23z"/>
			<path class="st0-bull-white" d="M636.16,345.13c-0.8-0.3-1.5-0.5-2.2-0.7c0.6-0.7,1.1-1.1,1.6-1.7
				C635.66,343.43,635.86,344.03,636.16,345.13z"/>
			<path class="st0-bull-white" d="M633.56,278.83c-0.5-0.9-1.1-1.5,0.2-2.3C633.56,277.33,634.96,277.93,633.56,278.83z"/>
			<path class="st0-bull-white" d="M631.26,295.83c1.4,0.1,1.4,0.1,1.2,2.2C631.96,297.13,631.66,296.63,631.26,295.83z"/>
			<path class="st0-bull-white" d="M616.06,316.73c1.1,0.6,1.8,0.9,2.5,1.3c-0.1,0.1-0.1,0.3-0.2,0.4
				C617.46,318.43,616.66,318.33,616.06,316.73z"/>
			<path class="st0-bull-white" d="M687.26,337.23c0.3,0.6,0.5,1.1,0.8,1.6c-0.3,0.2-0.5,0.5-0.8,0.7c-0.3-0.3-0.5-0.5-0.8-0.7
				C686.76,338.23,686.96,337.73,687.26,337.23z"/>
			<path class="st0-bull-white" d="M619.86,276.13c0.7,0.1,1.2,0.5,1.2,1.5c-0.7-0.2-1.2-0.4-1.8-0.6
				C619.46,276.63,619.66,276.33,619.86,276.13L619.86,276.13z"/>
			<path class="st0-bull-white" d="M687.56,345.43c-0.3,0.7-0.5,1.2-0.8,1.8c-0.2-0.2-0.5-0.4-0.6-0.6
				C685.86,345.73,686.46,345.43,687.56,345.43z"/>
			<path class="st0-bull-white" d="M628.66,320.63c0.4,0.5,0.9,1,1.3,1.4C628.86,322.33,628.76,322.23,628.66,320.63z"/>
			<path class="st0-bull-white" d="M618.96,220.53c1.7-0.9,1.4,0.7,2,1.1C619.86,222.03,619.56,221.23,618.96,220.53z"/>
			<path class="st0-bull-white" d="M621.56,304.03c-0.1,0.5-0.3,1-0.4,1.5c-0.3-0.1-0.7-0.2-0.8-0.4C620.26,304.43,620.36,303.83,621.56,304.03
				z"/>
			<path class="st0-bull-white" d="M635.16,351.93c-1.8-0.6-0.7-1.3-0.2-2C635.06,350.53,635.16,351.13,635.16,351.93z"/>
			<path class="st0-bull-white" d="M596.56,335.23c-0.5,0-0.9,0.1-1.6,0.1c0.1-0.5,0.2-1.1,0.3-1.1C595.86,334.13,596.46,334.03,596.56,335.23z
				"/>
			<path class="st0-bull-white" d="M631.56,326.23c-0.9,0.4-1.3,0.6-1.9,0.8C629.76,325.93,629.76,325.93,631.56,326.23z"/>
			<path class="st0-bull-white" d="M672.16,325.93c0,0.8,0,1.4,0,2C670.66,326.53,670.66,326.53,672.16,325.93z"/>
			<path class="st0-bull-white" d="M617.76,326.83c-0.6,0.5-1.1,0.9-1.7,1.5c-0.1-0.5-0.4-0.9-0.2-1.1
				C616.16,326.53,616.86,326.43,617.76,326.83z"/>
			<path class="st0-bull-white" d="M616.46,274.93c0.4,0.1,0.9,0.3,1.3,0.4c0,0.1,0,0.3,0,0.4c-0.5,0.2-1,0.4-1.7,0.8
				C616.26,275.83,616.36,275.33,616.46,274.93C616.46,274.83,616.46,274.93,616.46,274.93z"/>
			<path class="st0-bull-white" d="M631.36,310.23c-0.6,0.9-0.8,1.6-1.7,1.3c-0.1-0.1-0.2-0.7-0.2-0.7
				C630.06,310.63,630.56,310.53,631.36,310.23z"/>
			<path class="st0-bull-white" d="M649.76,317.43c-0.2,0.4-0.4,0.8-0.6,1.2c0,0-0.4-0.2-0.6-0.3c0.2-0.4,0.4-0.8,0.6-1.2
				C649.36,317.23,649.56,317.33,649.76,317.43z"/>
			<path class="st0-bull-white" d="M684.46,353.83c1.2-1.1,1.2-1.1,1.8,0.4C685.66,354.03,685.16,353.93,684.46,353.83z"/>
			<path class="st0-bull-white" d="M613.96,336.93c-0.2-0.4-0.4-0.8-0.7-1.3c1.5-0.3,1,0.7,1.1,1.2C614.26,336.83,614.06,336.93,613.96,336.93z
				"/>
			<path class="st0-bull-white" d="M710.06,316.43c0.6-0.3,1.3-0.5,1.9-0.8c0.1,0.1,0.1,0.3,0.2,0.4C711.56,316.33,711.16,317.23,710.06,316.43
				z"/>
			<path class="st0-bull-white" d="M606.46,316.83c0.2-1.4,1.2-0.8,2-0.7c-0.3,0.1-0.6,0.2-0.9,0.3C607.16,316.53,606.86,316.63,606.46,316.83z
				"/>
			<path class="st0-bull-white" d="M608.46,311.83c-0.5,0.4,0,1.7-1.4,1.5C607.56,312.73,607.16,311.73,608.46,311.83z"/>
			<path class="st0-bull-white" d="M601.86,340.93c0.3,0.3,0.7,0.7,1.1,1.1c-0.4,0.2-0.7,0.3-0.7,0.3c-0.3-0.3-0.5-0.7-0.8-1
				C601.66,341.13,601.76,341.03,601.86,340.93z"/>
			<path class="st0-bull-white" d="M612.06,271.93c0.5,0.2,1,0.5,1.6,0.7c-0.3,0.2-0.4,0.3-0.6,0.3C612.36,273.13,611.76,273.03,612.06,271.93z
				"/>
			<path class="st0-bull-white" d="M675.06,324.53c-1,0-2,0-3.1,0c0-0.1,0-0.2,0-0.3c1,0,2,0,3.1,0C675.06,324.33,675.06,324.43,675.06,324.53z
				"/>
			<path class="st0-bull-white" d="M623.26,284.23c0.5,0.3,0.9,0.6,1.6,1c-1.3,0.7-1.4-0.2-1.8-0.6C623.16,284.43,623.16,284.33,623.26,284.23z
				"/>
			<path class="st0-bull-white" d="M641.06,285.53c-0.3,0.7-0.6,1.2-1,2C639.86,286.33,639.66,285.63,641.06,285.53z"/>
			<path class="st0-bull-white" d="M651.96,265.53c-0.4,0.3-0.6,1.3-1.7,0.5c0.6-0.4,1-0.7,1.5-1C651.76,265.23,651.86,265.43,651.96,265.53z"
				/>
			<path class="st0-bull-white" d="M622.16,317.43c0.6-0.5,1-0.8,1.8-1.4C623.46,317.33,623.46,317.33,622.16,317.43z"/>
			<path class="st0-bull-white" d="M606.76,319.73c-0.4-0.6-0.8-1.3,0.5-1.4C607.06,318.93,606.86,319.33,606.76,319.73z"/>
			<path class="st0-bull-white" d="M599.06,332.53c1.2-0.8,1.6-0.3,1.9,1C600.16,333.13,599.56,332.83,599.06,332.53z"/>
			<path class="st0-bull-white" d="M614.96,316.23c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3-0.3-0.6-0.5-0.9-0.8
				C614.66,316.53,614.86,316.43,614.96,316.23z"/>
			<path class="st0-bull-white" d="M672.96,351.23c0.9-0.5,1.2-0.2,0.9,0.8C673.56,351.73,673.26,351.43,672.96,351.23z"/>
			<path class="st0-bull-white" d="M624.76,314.93c-0.5,0.1-1.1,0.1-1.9,0.2C623.56,314.23,624.06,313.93,624.76,314.93z"/>
			<path class="st0-bull-white" d="M687.56,349.63c-1,0-1,0-0.9-1.1C686.96,348.93,687.16,349.23,687.56,349.63z"/>
			<path class="st0-bull-white" d="M612.96,311.73c0.2,0.3,0.5,0.6,0.8,0.9C612.76,313.03,612.46,312.73,612.96,311.73z"/>
			<path class="st0-bull-white" d="M610.16,279.03c0.4-0.2,0.7-0.4,1.4-0.7c-0.2,0.7-0.3,1.2-0.5,1.6
				C610.76,279.63,610.46,279.33,610.16,279.03z"/>
			<path class="st0-bull-white" d="M631.16,286.13c-0.4-0.3-0.6-0.6-1-0.9C631.16,284.93,631.16,284.93,631.16,286.13z"/>
			<path class="st0-bull-white" d="M593.06,334.43c1.1-0.4,1.2,0,1.1,1C593.66,335.03,593.46,334.83,593.06,334.43z"/>
			<path class="st0-bull-white" d="M625.66,263.43c-0.3-0.3-0.6-0.6-0.9-0.9C625.86,262.43,625.86,262.43,625.66,263.43z"/>
			<path class="st0-bull-white" d="M619.46,224.73c0.3,0.4,0.6,0.6,0.9,1C619.26,225.73,619.26,225.73,619.46,224.73z"/>
			<path class="st0-bull-white" d="M652.06,272.63c-0.3,0.3-0.6,0.6-0.9,0.9C651.06,272.53,651.06,272.53,652.06,272.63z"/>
			<path class="st0-bull-white" d="M629.16,282.13c-0.6,0.4-0.9,0.6-1.2,0.8C627.86,282.13,627.86,282.13,629.16,282.13z"/>
			<path class="st0-bull-white" d="M626.56,284.33c0.2-0.2,0.3-0.5,0.5-0.7c0,0,0.3,0.2,0.4,0.3c-0.2,0.2-0.4,0.5-0.6,0.7
				C626.76,284.53,626.66,284.43,626.56,284.33z"/>
			<path class="st0-bull-white" d="M679.86,345.93c-0.3-0.3-0.7-0.7-1.2-1.2C679.76,344.53,679.86,345.03,679.86,345.93z"/>
			<path class="st0-bull-white" d="M627.36,343.73c0.2,0.3,0.3,0.4,0.3,0.6s-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.3-0.3-0.4
				C627.06,344.23,627.16,344.03,627.36,343.73z"/>
			<path class="st0-bull-white" d="M631.46,343.43c-0.2,0-0.5,0.1-0.5,0.1c-0.1-0.1-0.1-0.4-0.1-0.5s0.3-0.1,0.4-0.2
				C631.26,342.93,631.36,343.13,631.46,343.43z"/>
			<path class="st0-bull-white" d="M669.86,341.73c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.3,0.1,0.5
				C670.26,341.63,670.06,341.63,669.86,341.73z"/>
			<path class="st0-bull-white" d="M676.26,348.43c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.2,0.3-0.4c0.1,0.1,0.2,0.3,0.3,0.4
				C676.56,348.03,676.46,348.23,676.26,348.43z"/>
			<path class="st0-bull-white" d="M630.66,199.73c0.2-0.1,0.3-0.2,0.5-0.3c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1-0.2,0.2-0.4,0.3
				C630.86,200.13,630.76,199.93,630.66,199.73z"/>
			<path class="st0-bull-white" d="M654.46,352.53c-0.2,0-0.5,0.1-0.7,0c-0.1,0-0.1-0.2-0.1-0.3c0.3-0.1,0.6-0.1,0.8-0.2
				C654.46,352.23,654.46,352.43,654.46,352.53z"/>
			<path class="st0-bull-white" d="M641.96,335.53c-0.2-1.4-0.2-1.4,0.9-1.9C642.46,334.33,642.26,334.93,641.96,335.53
				C641.96,335.43,641.96,335.53,641.96,335.53z"/>
			<path class="st0-bull-white" d="M644.66,327.83c0,0.6,0,1.3,0,1.9c-0.1,0-0.2,0-0.2,0c0-0.6,0-1.3,0-1.9
				C644.56,327.83,644.66,327.83,644.66,327.83z"/>
			<path class="st0-bull-white" d="M624.76,325.43c-0.2-0.2-0.3-0.3-0.5-0.4c0.1-0.1,0.3-0.3,0.4-0.3c0.1,0,0.3,0.2,0.4,0.3
				C625.06,325.13,624.96,325.23,624.76,325.43z"/>
			<path class="st0-bull-white" d="M604.86,324.03c-0.1-0.3-0.2-0.5-0.2-0.6s0.3-0.2,0.4-0.2c0,0,0.2,0.2,0.2,0.3
				C605.16,323.73,605.06,323.83,604.86,324.03z"/>
			<path class="st0-bull-white" d="M656.56,321.73c0.1,0.2,0.2,0.3,0.3,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2-0.2-0.3-0.4
				C656.26,321.93,656.36,321.83,656.56,321.73z"/>
			<path class="st0-bull-white" d="M606.46,321.83c0.2,0.2,0.4,0.4,0.4,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1-0.1-0.3-0.2-0.4-0.3
				C606.26,322.33,606.36,322.13,606.46,321.83z"/>
			<path class="st0-bull-white" d="M634.76,321.23c0.2,0.3,0.3,0.4,0.3,0.5c0,0.2-0.2,0.3-0.3,0.4c-0.1-0.1-0.3-0.2-0.3-0.4
				C634.46,321.63,634.66,321.53,634.76,321.23z"/>
			<path class="st0-bull-white" d="M624.46,257.43c-0.1-0.3-0.3-0.4-0.3-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.3,0.3,0.3,0.4
				C624.76,257.03,624.56,257.23,624.46,257.43z"/>
			<path class="st0-bull-white" d="M623.16,259.33c-0.1-0.3-0.3-0.5-0.2-0.6c0-0.1,0.2-0.3,0.3-0.4c0.1,0.1,0.2,0.3,0.2,0.4
				C623.46,258.93,623.26,259.03,623.16,259.33z"/>
			<path class="st0-bull-white" d="M614.96,319.93c-0.6,0.3-0.8,0.4-0.9,0.4c-0.1,0-0.3-0.2-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
				C614.26,319.53,614.46,319.63,614.96,319.93z"/>
			<path class="st0-bull-white" d="M646.86,260.33c-0.2,0.2-0.2,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
				C646.46,260.13,646.56,260.23,646.86,260.33z"/>
			<path class="st0-bull-white" d="M605.16,317.93c-0.3,0.1-0.4,0.3-0.6,0.3c-0.1,0-0.3-0.2-0.4-0.3c0.1-0.1,0.3-0.3,0.4-0.3
				C604.76,317.63,604.96,317.83,605.16,317.93z"/>
			<path class="st0-bull-white" d="M627.66,315.43c0.3-0.2,0.4-0.3,0.6-0.3s0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
				C628.16,315.73,627.96,315.63,627.66,315.43z"/>
			<path class="st0-bull-white" d="M609.46,268.53c0.3-0.1,0.4-0.3,0.5-0.3c0.1,0,0.3,0.2,0.4,0.3c-0.1,0.1-0.3,0.3-0.4,0.3
				C609.86,268.93,609.76,268.73,609.46,268.53z"/>
			<path class="st0-bull-white" d="M652.06,268.63c-0.3,0.1-0.5,0.2-0.6,0.2c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2
				C651.66,268.33,651.86,268.53,652.06,268.63z"/>
			<path class="st0-bull-white" d="M619.86,276.13c-0.5-0.2-1-0.5-0.3-1.5C619.66,275.33,619.76,275.73,619.86,276.13L619.86,276.13z"/>
			<path class="st0-bull-white" d="M632.06,280.73c-0.6,0.3-0.8,0.4-1,0.4c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.2-0.4,0.3-0.4
				C631.26,280.33,631.36,280.43,632.06,280.73z"/>
			<path class="st0-bull-white" d="M629.06,286.53c-0.5,0.1-0.7,0.2-0.9,0.2c-0.1,0-0.2-0.3-0.3-0.4c0.2-0.1,0.4-0.2,0.5-0.2
				C628.56,286.13,628.66,286.33,629.06,286.53z"/>
			<path class="st0-bull-white" d="M640.56,288.43c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.1,0.2-0.3,0.3-0.4
				C640.36,288.13,640.46,288.23,640.56,288.43z"/>
			<path class="st0-bull-white" d="M620.16,290.03c0.2-0.3,0.3-0.5,0.5-0.8C620.96,289.93,620.96,289.93,620.16,290.03
				C620.06,290.03,620.16,290.03,620.16,290.03z"/>
			<path class="st0-bull-white" d="M630.26,291.83c-0.2,0.2-0.3,0.3-0.3,0.5c-0.1-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3
				C629.86,291.73,629.96,291.83,630.26,291.83z"/>
			<path class="st0-bull-white" d="M605.36,294.33c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1-0.1-0.3-0.2-0.4-0.3c0-0.1,0.1-0.3,0.2-0.5
				C604.96,294.13,605.16,294.13,605.36,294.33z"/>
			<path class="st0-bull-white" d="M824.96,382.13c0.6-2.1,1.5-2.7,2.9-2.2c0.1-0.3,0.2-0.5,0.3-0.9c0.7,0.3,1.3,0.5,2,0.8c0.3-0.3,0.7-0.6,1-1
				c0.3-0.3,0.6-0.7,1.1-1.2c-0.1,1.2-0.1,2.2-0.4,3c-0.4,0.8,0.5,2.2-0.9,2.6c0-0.1-1-2.6-1-2.7c-0.7,0.2-1.3,1.6-2.1,0.3
				c-0.2,0.1-0.4,0.3-0.6,0.4C826.56,381.53,825.86,381.83,824.96,382.13z"/>
			<path class="st0-bull-white" d="M819.06,411.83c1.4-1.3,1.7-1.3,2.6,0.2c0.5-0.1,1.1-0.2,1.9-0.4c-0.3,1-0.8,1.7-0.2,2.6
				c0.6,0.9-0.5,1.5-1.6,2c0.2-0.6,0.2-0.8,0.4-1.3C820.66,414.53,820.06,413.13,819.06,411.83z"/>
			<path class="st0-bull-white" d="M826.36,411.73c-1.4-0.6-1.4-0.6-0.5-2.3c-0.8-0.4-1.7-0.7-2.4-1.3c-0.6-0.6-0.9-1.5-1.3-2.2
				c1.9-1.1,2.4,1.1,3.8,1.2c-0.2,0.5-0.4,0.8-0.6,1.3c0.6,0.1,1.1,0.2,1.7,0.3c-0.7,1.1-0.7,1.1,0,2
				C826.86,411.03,826.56,411.33,826.36,411.73L826.36,411.73z"/>
			<path class="st0-bull-white" d="M820.66,406.93c-0.1,0.5-0.2,0.9-0.3,1.3c0.5,0.6,1.6-0.2,1.9,1c-0.7,0.3-1.4,0.7-2.2,1.1
				c-0.8-0.8-2.2-1.3-1.6-2.7C818.76,406.63,819.56,406.43,820.66,406.93z"/>
			<path class="st0-bull-white" d="M826.96,395.43c-0.7,0.6-1.4,1.3-2.2,2c-0.4-0.2-1.2-0.6-0.5-1.5c0.4-0.5,1-0.8,1.5-1.2
				c-0.4-0.6-0.8-1.1-1.2-1.8c1.3-0.7,1.5,0.4,2,0.7C827.46,394.13,827.46,394.73,826.96,395.43z"/>
			<path class="st0-bull-white" d="M818.36,397.53c1.9,0,2.4,1.9,4.1,2.7c-0.5,0.5-0.9,0.8-1.3,1.2C819.66,400.53,818.86,399.23,818.36,397.53z
				"/>
			<path class="st0-bull-white" d="M826.36,411.73c0.9-0.1,1.4,0.3,1.7,1.1c0.2,0.5,0.4,1,0.5,1.5c-0.4,1.1-1.3,1.3-2.4,1
				c-0.2-0.9-0.3-1.8,0.2-2.7C826.56,412.43,826.36,412.03,826.36,411.73L826.36,411.73z"/>
			<path class="st0-bull-white" d="M819.36,381.43c-0.1-0.5-0.2-1-0.3-1.3c0.6-0.8,1.1-1.5,1.6-2.3c0.5,0.1,1.3,0.2,2,0.2c0,0,0.1,0.1,0,0.2
				c-0.1,0.2-0.1,0.5-0.2,0.5C820.86,378.93,820.86,381.03,819.36,381.43C819.26,381.33,819.36,381.43,819.36,381.43z"/>
			<path class="st0-bull-white" d="M821.06,393.83c-2.1-1.7,0.9-2.2,0.3-3.6C823.36,391.63,823.26,392.13,821.06,393.83z"/>
			<path class="st0-bull-white" d="M821.86,397.33c-0.7-0.9-1.5-1.4-0.9-2.6c0.7,0,1.5,0,2.7,0C822.96,395.73,822.46,396.43,821.86,397.33z"/>
			<path class="st0-bull-white" d="M824.86,401.63c-0.8-1.2-1.5-2.2-2.2-3.2c0.9-0.9,1.5-0.2,1.8,0.3c0.4,0.7,0.2,1.7,1.1,2.1
				C825.46,401.03,825.36,401.13,824.86,401.63z"/>
			<path class="st0-bull-white" d="M829.46,411.53c-1.3-0.2-0.6-1.2-0.9-2c0.8-0.4,1.5-0.7,2.4-1.2C830.36,409.63,829.86,410.53,829.46,411.53z
				"/>
			<path class="st0-bull-white" d="M836.76,378.03c0-0.8,0-1.5,0-2.2c0.4-0.2,0.9-0.4,1.7-0.7c-0.1,0.7-0.2,1.2-0.3,1.7
				c-0.1,0.4-0.2,0.7-0.4,1.3C837.56,378.03,837.06,378.03,836.76,378.03z"/>
			<path class="st0-bull-white" d="M823.16,404.63c-1.3,0-1.2-1.7-2.6-2c0.5-0.4,0.8-0.6,1.3-1c0.6,0.9,1.1,1.8,1.7,2.6
				C823.46,404.33,823.26,404.43,823.16,404.63z"/>
			<path class="st0-bull-white" d="M823.56,389.63c1,0.1,1.6,0.1,2.4,0.2C825.16,391.13,824.86,391.13,823.56,389.63z"/>
			<path class="st0-bull-white" d="M838.86,397.63c-0.5-0.6-0.9-1-1.1-1.5c-0.1-0.2,0.2-0.6,0.4-0.9c0.2,0.2,0.7,0.3,0.7,0.5
				C838.96,396.33,838.86,396.93,838.86,397.63z"/>
			<path class="st0-bull-white" d="M827.66,405.13c-0.5,0.4-0.9,0.8-1.1,0.7c-0.3-0.1-0.6-0.5-0.6-0.8s0.4-0.6,0.6-0.8
				C826.86,404.53,827.16,404.73,827.66,405.13z"/>
			<path class="st0-bull-white" d="M823.76,419.63c-0.7-0.4-1.5-0.8-2.2-1.2c0.1-0.2,0.2-0.4,0.3-0.5c0.8,0.4,1.5,0.8,2.3,1.2
				C823.96,419.33,823.86,419.43,823.76,419.63z"/>
			<path class="st0-bull-white" d="M840.16,375.23c-0.2,0.8,1.1,1.4-0.3,2.2C839.96,376.63,838.46,376.13,840.16,375.23z"/>
			<path class="st0-bull-white" d="M816.76,377.13c-0.3,0.5-0.6,1-0.9,1.5c-0.2-0.2-0.4-0.4-0.7-0.6C815.56,377.43,815.76,376.73,816.76,377.13
				z"/>
			<path class="st0-bull-white" d="M828.86,415.53c0.4-0.1,0.9-0.3,1.3-0.4c0,0,0.3,0.4,0.2,0.5C830.06,416.33,829.56,416.23,828.86,415.53z"/>
			<path class="st0-bull-white" d="M822.46,372.93c0.2,0.4,0.4,0.8,0.6,1.3c-0.4,0.2-0.9,0.5-1.4,0.7c-0.1-0.1-0.2-0.1-0.3-0.2
				C821.76,374.13,822.16,373.53,822.46,372.93L822.46,372.93z"/>
			<path class="st0-bull-white" d="M825.56,374.43c0.4,0.6,0.7,1,1.3,1.8c-0.9-0.3-1.4-0.5-2-0.7C825.06,375.23,825.26,374.93,825.56,374.43z"
				/>
			<path class="st0-bull-white" d="M839.56,393.03c0,0.4,0,0.7,0,1.1c-0.1,0-0.2,0-0.3,0c0-0.4-0.1-0.8-0.1-1.1
				C839.16,393.03,839.36,393.03,839.56,393.03z"/>
			<path class="st0-bull-white" d="M829.16,383.33c0.4,0.3,0.8,0.6,1.2,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4-0.3-0.7-0.6-1.1-0.9
				C828.96,383.63,829.06,383.53,829.16,383.33z"/>
			<path class="st0-bull-white" d="M829.76,417.73c-0.4,0.3-0.8,0.6-1.2,1c-0.1-0.3-0.2-0.4-0.2-0.5C828.56,417.63,828.96,417.33,829.76,417.73
				z"/>
			<path class="st0-bull-white" d="M835.06,381.13c-0.3,0.3-0.6,0.5-1,0.8C834.06,380.73,834.06,380.73,835.06,381.13z"/>
			<path class="st0-bull-white" d="M817.86,412.33c0.3,0.3,0.6,0.6,0.9,0.9C817.86,413.63,817.86,413.63,817.86,412.33z"/>
			<path class="st0-bull-white" d="M817.76,396.23c0.3-0.3,0.6-0.6,0.9-0.9C818.66,396.23,818.66,396.23,817.76,396.23z"/>
			<path class="st0-bull-white" d="M831.06,412.03c-0.2,0.2-0.3,0.5-0.5,0.7c0,0-0.3-0.2-0.4-0.3c0.2-0.2,0.4-0.5,0.6-0.7
				C830.86,411.83,830.96,411.93,831.06,412.03z"/>
			<path class="st0-bull-white" d="M550.36,413.13c0.8-0.1,1.4-0.5,2.3-0.7c1-0.1,1.9-1.2,2.9-1.8c0.4,0.8,0.9,1.5,2,0.5
				c0.4-0.4,1.5-0.2,2.2-0.2c0,0.1,0,0.3,0.1,0.4c-0.9,0.3-1.9,0.6-2.8,0.7c-1,0.1-1.6,0.4-2,1.4c-0.2,0.6-0.7,1.2-1,1.8
				c-0.2-0.1-0.4-0.1-0.5-0.2c0-0.5,0.1-1,0.1-1.6C552.66,413.43,551.56,413.33,550.36,413.13L550.36,413.13z"/>
			<path class="st0-bull-white" d="M552.56,417.43c0,0.6,0,1.3,0,1.9c-0.1,0-0.2,0-0.2,0c0-0.6,0-1.3,0-1.9
				C552.46,417.43,552.46,417.43,552.56,417.43z"/>
			<path class="st0-bull-white" d="M535.96,416.23c-1.6,0.5-3.3,1-5.2,1.6c0.3-1.6,1-2.5,2.2-2.9c0.8,1.1,0.9,1.1,2.8,0.8
				C535.86,415.93,535.86,416.03,535.96,416.23z"/>
			<path class="st0-bull-white" d="M527.26,399.73c0.4,0.2,1,0.4,1.3,0.7c0.4,0.4,0.6,1,0.9,1.5c-0.9,1.1-0.9,1.1-2.3,1.1
				c-0.4-0.8-0.4-1.5,0-2.3C527.36,400.53,527.26,400.03,527.26,399.73L527.26,399.73z"/>
			<path class="st0-bull-white" d="M530.36,399.53c-1.2-0.4-0.7-1.3-0.8-2.1c0.7-0.4,1.4-0.7,2.4-1.3
				C531.26,397.53,530.86,398.53,530.36,399.53z"/>
			<path class="st0-bull-white" d="M539.26,385.43c-0.2-0.5-0.5-1-0.7-1.6c0-0.1,0.2-0.5,0.4-0.6c0.2,0,0.6,0.2,0.6,0.3
				c0.1,0.6,0.1,1.2,0.1,1.8C539.56,385.33,539.46,385.33,539.26,385.43z"/>
			<path class="st0-bull-white" d="M530.76,405.73c-0.5,0.3-1,0.7-1.5,1c-0.1-0.1-0.2-0.2-0.3-0.4c0.4-0.5,0.5-1.4,1.4-1.2
				C530.56,405.33,530.66,405.53,530.76,405.73z"/>
			<path class="st0-bull-white" d="M538.16,399.53c-0.4,0.2-0.8,0.4-1.2,0.5c0,0-0.2-0.4-0.3-0.6c0.4-0.2,0.8-0.4,1.2-0.6
				C537.96,399.03,538.06,399.23,538.16,399.53z"/>
			<path class="st0-bull-white" d="M529.76,403.43c0.5-0.2,0.9-0.4,1.4-0.7c0.1,0.2,0.2,0.5,0.3,0.7c-0.5,0.2-1,0.3-1.5,0.5
				C529.86,403.73,529.76,403.63,529.76,403.43z"/>
			<path class="st0-bull-white" d="M536.66,411.33c-0.7-0.2-1.4-0.4-1.3-1.6C536.16,410.23,536.66,410.63,536.66,411.33z"/>
			<path class="st0-bull-white" d="M539.86,382.33c0-0.5,0-1,0-1.6C540.76,381.23,540.56,381.73,539.86,382.33z"/>
			<path class="st0-bull-white" d="M548.06,374.13c-0.3-0.7-0.7-1.4,0.5-2C548.26,373.03,548.16,373.63,548.06,374.13z"/>
			<path class="st0-bull-white" d="M536.56,415.63c0.5-0.1,0.9-0.2,1.4-0.3c0,0.1,0.1,0.2,0.1,0.4c-0.4,0.2-0.9,0.4-1.3,0.5
				C536.76,416.03,536.66,415.83,536.56,415.63z"/>
			<path class="st0-bull-white" d="M535.76,373.73c-0.4,0-0.7-0.1-1.1-0.1c0-0.1,0-0.2,0-0.3c0.4,0,0.7,0,1.1,0
				C535.76,373.43,535.76,373.53,535.76,373.73z"/>
			<path class="st0-bull-white" d="M534.76,376.83c-0.1-0.3-0.1-0.7-0.2-1c0.1,0,0.3,0,0.4-0.1c0.1,0.3,0.1,0.7,0.2,1
				C535.06,376.83,534.96,376.83,534.76,376.83z"/>
			<path class="st0-bull-white" d="M535.76,404.33c-0.3,0.1-0.5,0.1-0.8,0.2c0,0-0.1-0.2-0.1-0.4c0.3-0.1,0.6-0.1,0.8-0.2
				C535.66,404.03,535.66,404.13,535.76,404.33z"/>
			<path class="st0-bull-white" d="M671.56,402.33c1.6-1,3.5-0.8,5.1-1.4c0.4-0.1,1,0.1,1.6,0.1c-0.5,1.5-1.9,0.6-2.4,1.4
				c-1.3-1.1-1.9,0.5-2.8,0.8C672.56,402.93,672.06,402.63,671.56,402.33L671.56,402.33z"/>
			<path class="st0-bull-white" d="M679.66,398.83c1.1,1.2,1.1,1.2,2.7,0.9c-0.4,0.8-0.4,0.8-2.7,1.9c0.1,0.4,0.2,0.8,0.4,1.3
				c-0.2,0-0.4,0.1-0.4,0c-0.9-0.9-1-2.2-0.4-3.3C679.36,399.33,679.56,399.03,679.66,398.83L679.66,398.83z"/>
			<path class="st0-bull-white" d="M706.36,416.23c0.3-2,0.5-3.6,0.7-5.2C708.56,412.03,708.46,413.83,706.36,416.23z"/>
			<path class="st0-bull-white" d="M718.06,392.33c-0.7,1.1-1.3,2.1-1.9,3.1c-0.8-0.2-0.8-0.6-0.4-1.1c0.5-0.6,1.1-1.1,0.1-1.9
				c0.4-0.5,0.9-0.9,1.4-1.5C717.66,391.53,717.86,392.03,718.06,392.33z"/>
			<path class="st0-bull-white" d="M677.86,375.73c0.8,1.2,0.4,2.5-0.1,3.8c-0.2-1.2-0.4-2.5-0.6-3.7
				C677.46,375.73,677.66,375.73,677.86,375.73z"/>
			<path class="st0-bull-white" d="M680.16,406.63c-0.5,1.3,0.5,2.3-0.3,3.3C680.16,408.93,677.86,408.03,680.16,406.63z"/>
			<path class="st0-bull-white" d="M710.86,401.23c0.6-0.7,0.8-1.1,1.3-1.6c0.4,0.5,0.8,0.9,1.3,1.6C712.46,401.23,711.86,401.23,710.86,401.23
				z"/>
			<path class="st0-bull-white" d="M720.06,385.13c0.8,1.4-0.5,1.8-1,2.9C718.96,386.83,718.56,385.73,720.06,385.13z"/>
			<path class="st0-bull-white" d="M712.06,405.43c-0.8,0.7-1.3,1.2-1.9,1.8C710.26,405.73,710.56,405.33,712.06,405.43z"/>
			<path class="st0-bull-white" d="M697.46,413.03c0,0.6,0,1,0,1.4c-0.6,0-1.1,0-1.5,0C695.76,413.73,695.76,413.73,697.46,413.03z"/>
			<path class="st0-bull-white" d="M679.26,413.23c0.2-0.7,0.3-1.4,0.5-2.1c1.3,0.4,0.2,1.5,0.9,2.2C679.96,413.33,679.46,413.33,679.26,413.23
				z"/>
			<path class="st0-bull-white" d="M685.66,399.13c-0.1,0.7-0.5,1.1-1.2,0.9c-0.1,0-0.2-0.4-0.2-0.5C684.46,398.53,685.06,398.93,685.66,399.13
				z"/>
			<path class="st0-bull-white" d="M708.76,410.33c-0.4-0.1-0.9-0.3-1.3-0.4c0,0,0-0.4,0.1-0.5c0.5,0.1,0.9,0.2,1.4,0.3
				C708.96,409.83,708.86,410.13,708.76,410.33z"/>
			<path class="st0-bull-white" d="M674.56,410.53c0.5,0.2,0.9,0.4,1.5,0.7C674.66,411.53,674.66,411.53,674.56,410.53z"/>
			<path class="st0-bull-white" d="M714.46,397.53c-0.2-0.9,0.4-1.1,1.2-1.4C715.76,397.03,715.36,397.53,714.46,397.53L714.46,397.53z"/>
			<path class="st0-bull-white" d="M714.36,397.43c0,0.5-0.1,1.1-0.1,1.7C713.76,398.33,713.76,398.33,714.36,397.43
				C714.46,397.53,714.36,397.43,714.36,397.43z"/>
			<path class="st0-bull-white" d="M682.46,409.33c0.9,0.2,1.7,0.4,0.7,1.5C682.96,410.33,682.76,409.83,682.46,409.33z"/>
			<path class="st0-bull-white" d="M678.76,389.23c-0.9-0.5-1.1-0.9-0.1-1.6C678.76,388.23,678.76,388.73,678.76,389.23z"/>
			<path class="st0-bull-white" d="M679.76,398.73c-0.5-0.1-1.2,0.1-0.6-1C679.36,398.23,679.56,398.53,679.76,398.73
				C679.66,398.83,679.76,398.73,679.76,398.73z"/>
			<path class="st0-bull-white" d="M703.66,414.83c0.3,0,0.6,0,0.9,0c0,0.1,0,0.2,0,0.3c-0.3,0.1-0.6,0.1-0.9,0.2
				C703.66,415.13,703.66,414.93,703.66,414.83z"/>
			<path class="st0-bull-white" d="M408.36,383.83c2.1,0,4.3,0,6.4,0c0.9,0,1.7,0,1.9,1.1c0.1,1-0.3,1.5-1.2,1.9c-4.5,2.1-8.9,4.3-13.4,6.4
				c-1.6,0.8-2.6,0.1-2.5-1.6c0-2,0.1-4,0.2-6c0.1-1.5,0.4-1.8,1.8-1.9C403.86,383.83,406.06,383.83,408.36,383.83L408.36,383.83z
				 M402.36,391.43c1.8,0.1,5.3-1.5,6.3-3c-1.4,0-2.7,0-4,0C402.46,388.43,402.06,388.93,402.36,391.43z"/>
			<path class="st0-bull-white" d="M391.56,400.23c0.6,0.7,1.6,1.4,1.5,1.8c-0.5,1.7,1.5,0.4,1.7,1.5c0.1,0.5,1.2,0.9,1.9,1.4
				c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.5-0.4,1.2-1.1,0.9c-1-0.4,0-1,0-1.5c-0.1-0.1-0.2-0.2-0.3-0.2c-1.1-0.2-2.3,0.2-3.2-1.2
				c-0.7-1-2.2-1.4-3.4-2c-0.8,0.5-1.4,1-2,1.4c-0.1-0.1-0.2-0.1-0.3-0.2c0.2-0.7,0.4-1.3,0.4-2s-0.1-1.3-0.2-2.2
				c0.3,0,1.1-0.1,1.1,0.1c0.8,1.7,2.4,1.5,3.9,1.6C391.46,400.13,391.66,400.23,391.56,400.23z"/>
			<path class="st0-bull-white" d="M393.26,419.13c-1.3-0.9-2-1.7-1.1-3.1c0.3-0.6,0-1.5,0.1-2.2c0-0.3,0.3-0.6,0.5-0.9c0.2,0,0.3,0,0.5,0
				c0.1,0.6,0.1,1.2,0.3,1.8c0.1,0.7,0.5,1.4,0.4,2.1C393.86,417.53,393.56,418.23,393.26,419.13z"/>
			<path class="st0-bull-white" d="M390.96,390.93c0.4,1.7,0.7,3.2,1,4.7c-0.6,0.6-0.9,1.5-2.1,1.4c-0.1-0.7,1.2-1.2,0.1-1.9
				c0,0,0.1-0.4,0.2-0.6c0.4-0.6,0.5-1.3-0.1-1.8C389.06,391.73,390.06,391.53,390.96,390.93z"/>
			<path class="st0-bull-white" d="M388.86,417.23c0.5,0.2,0.8,0.3,1.1,0.4c0,0.1,0,0.2,0,0.3c-0.4,0-0.8,0-1.2,0c-0.3-0.1-0.6-0.2-0.8-0.3
				c-0.7,0.9-1.3,1.8-2,2.8c-0.2-1.9,0.3-3.4,1.4-4.7c0.2,0,0.5,0.1,0.8,0.1c0.2-0.6,0.3-1.1,0.5-1.6c0.1,0,0.2,0.1,0.4,0.1
				C388.96,415.23,388.96,416.23,388.86,417.23z"/>
			<path class="st0-bull-white" d="M384.46,395.43c1.1-0.8,1.7-1.4,1.1-2.8c-0.2-0.5,0.5-1.5,0.7-2.2c0.1,0,0.3,0,0.4,0.1
				c0.1,0.2,0.3,0.5,0.2,0.6c-0.8,1.2-0.6,2.6-0.7,3.9C386.26,396.33,385.76,396.43,384.46,395.43z"/>
			<path class="st0-bull-white" d="M391.06,383.03c-0.2-0.4-0.6-1-0.5-1.3c0.3-1.3,1.3-1.9,2.5-2.2c0.6,0.6,0.4,1.1-0.2,1.4
				c-0.8,0.5-1.7,0.8-1.2,2.4C391.46,383.13,391.26,383.03,391.06,383.03z"/>
			<path class="st0-bull-white" d="M419.06,398.43c1,1.4,0.1,4-1.3,4.3C418.16,401.33,418.56,400.03,419.06,398.43z"/>
			<path class="st0-bull-white" d="M393.06,409.23c0.7,0.5,1.3,1,1.8,1.4c-0.5,0.5-1,1-1.8,1.8c0.1-0.9,0.2-1.4,0.2-1.9
				c-0.4,0.1-0.7,0.1-1.5,0.2C392.46,410.03,392.76,409.63,393.06,409.23z"/>
			<path class="st0-bull-white" d="M395.06,408.23c-1.9,0.1-2.7-0.4-3.4-1.9c0.6,0.2,1.3,0.3,1.8,0.6
				C394.06,407.33,394.56,407.83,395.06,408.23z"/>
			<path class="st0-bull-white" d="M414.06,414.63c0.4-0.8,0.7-1.6,1.2-2.7c0.3,0.5,0.7,1.1,0.7,1.1C415.36,413.63,415.16,414.63,414.06,414.63
				C413.96,414.53,414.06,414.63,414.06,414.63z"/>
			<path class="st0-bull-white" d="M389.16,410.23c0.7,0.3,1.2,0.6,1.7,0.8c-0.3,0.6-0.6,1.1-1,1.9C389.66,411.93,389.46,411.23,389.16,410.23z
				"/>
			<path class="st0-bull-white" d="M406.66,377.63c-0.4-0.8-0.6-1.4,0.2-1.8c0.2-0.1,0.6,0.1,0.7,0.2
				C408.06,376.93,407.16,377.03,406.66,377.63z"/>
			<path class="st0-bull-white" d="M396.36,382.83c-0.5,0.1-0.9,0.3-1.1,0.2c-0.2-0.1-0.3-0.5-0.5-0.7c0.3-0.1,0.6-0.4,0.8-0.3
				C395.86,382.03,396.06,382.43,396.36,382.83z"/>
			<path class="st0-bull-white" d="M418.26,404.63c-0.8,0-1.3,0-1.9,0C417.36,403.13,417.46,403.13,418.26,404.63z"/>
			<path class="st0-bull-white" d="M400.66,383.03c0-0.5-0.1-0.9-0.1-1.5C401.66,382.13,401.66,382.13,400.66,383.03z"/>
			<path class="st0-bull-white" d="M393.36,384.53c0,0.9,0,1.5,0,2.2C392.66,386.23,392.66,386.23,393.36,384.53z"/>
			<path class="st0-bull-white" d="M395.86,403.43c-0.4-0.9-0.7-1.7,0.8-1.7C396.36,402.43,396.06,402.93,395.86,403.43z"/>
			<path class="st0-bull-white" d="M413.76,394.63c-0.4,0.2-0.7,0.4-0.9,0.5c-0.1,0-0.4-0.4-0.4-0.5c0.1-0.2,0.4-0.5,0.6-0.5
				C413.26,394.03,413.46,394.33,413.76,394.63z"/>
			<path class="st0-bull-white" d="M391.06,416.63c-0.2-0.3-0.5-0.4-0.5-0.6s0.2-0.4,0.3-0.6c0.2,0.2,0.5,0.3,0.7,0.5
				C391.46,416.13,391.26,416.33,391.06,416.63z"/>
			<path class="st0-bull-white" d="M382.26,375.13c0.4,0.2,0.9,0.5,1.5,0.8c-1.2,0.7-1.3-0.2-1.7-0.4
				C382.06,375.33,382.16,375.23,382.26,375.13z"/>
			<path class="st0-bull-white" d="M418.26,396.63c0.1-0.2,0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.3,1-0.2c-0.3,0.4-0.6,0.7-0.8,1.1
				C418.46,396.83,418.36,396.73,418.26,396.63z"/>
			<path class="st0-bull-white" d="M395.06,374.43c0.3,0.1,0.6,0.1,0.9,0.2c-0.2,0.1-0.4,0.3-0.6,0.4c-0.1,0-0.2-0.1-0.3-0.2
				C395.06,374.73,395.06,374.63,395.06,374.43z"/>
			<path class="st0-bull-white" d="M406.96,382.03c0,0.3,0,0.6,0,1c-0.1,0-0.3,0-0.4,0c0-0.3,0-0.6,0-0.9
				C406.66,382.03,406.86,382.03,406.96,382.03z"/>
			<path class="st0-bull-white" d="M422.26,411.63c-0.3,0.1-0.5,0.3-0.7,0.3c-0.1,0-0.2-0.3-0.3-0.4c0-0.1,0.2-0.3,0.2-0.3
				C421.66,411.23,421.76,411.43,422.26,411.63z"/>
			<path class="st0-bull-white" d="M399.06,418.03c-0.4-0.3-0.7-0.5-1.3-0.9C399.16,416.83,399.16,416.83,399.06,418.03L399.06,418.03z"/>
			<path class="st0-bull-white" d="M265.46,383.83c2.3,0,4.7,0,7,0c1.2,0,1.8,0.8,1.5,1.9c-0.1,0.4-0.5,0.8-0.9,1c-4.6,2.2-9.1,4.4-13.7,6.6
				c-1.5,0.7-2.2,0.3-2.2-1.4c-0.1-2,0-4,0-6c0-1.8,0.3-2,2-2.1C261.16,383.83,263.26,383.83,265.46,383.83z M266.06,389.03
				c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.1-0.4-0.1-0.6-0.2c-1.1,0-2.2,0-3.3,0c-2.5,0-3,0.9-2.2,3.6
				C261.96,391.03,264.06,390.03,266.06,389.03z"/>
			<path class="st0-bull-white" d="M261.06,400.03c1.9,0.3,1.9,0.3,2.3,2c-2,0.1-3.9,0.3-5.6,2.1c0.1-0.6,0-1,0.2-1.1c0.8-0.9,1.5-1.8,0.6-3
				c-0.1-0.1,0.4-0.6,0.6-1C259.86,399.33,260.46,399.73,261.06,400.03c-0.8,0.7-0.8,0.7-0.1,1.5
				C260.96,401.03,260.96,400.53,261.06,400.03z"/>
			<path class="st0-bull-white" d="M245.46,383.53c-0.6-1.3-1.3-1.9-2.4-1.7c-0.3-0.9-0.6-1.7-1-2.7c1.3,0,2.5,0,3.9,0c-0.7,1-0.6,2,0,3.1
				C246.06,382.33,245.76,382.83,245.46,383.53z"/>
			<path class="st0-bull-white" d="M247.46,378.73c1-0.2,1.9-0.4,2.9-0.7c0.5-1.1,1-2.3,1.5-3.5c0.2,0.1,0.4,0.1,0.5,0.2
				c-0.5,1.2-0.8,2.5-1.4,3.6c-0.6,1.1-1.4,2.1-2.3,3.1c0.1-0.6,0.2-1.3,0.4-2c-0.5-0.2-1.2-0.3-1.8-0.5
				C247.36,378.93,247.36,378.83,247.46,378.73z"/>
			<path class="st0-bull-white" d="M253.66,397.23c-0.5,0.1-1,0.3-1.7,0.5c0.2-1,0.3-1.9,0.4-2.9c-0.1,0,0.1-0.1,0.1-0.1
				c0.7,0.6,1.8-0.5,2.3,0.5c0.4,0.7,0.6,1.5,0.9,2.2v-0.1c-0.6,0.2-1.2,0.5-1.8,0.8C253.86,397.83,253.66,397.53,253.66,397.23
				c1,0,1-0.5,0.3-1.3C253.86,396.33,253.76,396.73,253.66,397.23z"/>
			<path class="st0-bull-white" d="M256.26,399.23c0,0.1,0.1,0.4,0,0.4c-0.9,0.5-1.3,1.4-1.3,2.5c-0.6,0-1,0-1.8,0c0.6-0.3,0.9-0.5,1.4-0.9
				c-0.6-0.3-0.9-0.5-1.5-0.8c0.8-0.7,1.3-1.6,2.5-1.5C255.96,399.03,256.06,399.13,256.26,399.23z"/>
			<path class="st0-bull-white" d="M239.26,398.63c0.4,0,0.9,0.1,1.4,0.1c0.2,0.6,0.4,1.4,0.6,2.3c0.2,0.8-0.6,1.2-1.9,0.9
				C239.26,400.83,239.26,399.73,239.26,398.63L239.26,398.63z"/>
			<path class="st0-bull-white" d="M248.56,410.43c0,0.9,0,1.5,0,2c-1.8,0.5-1.9,0.5-1.8-1.3C247.26,410.93,247.86,410.73,248.56,410.43z"/>
			<path class="st0-bull-white" d="M257.16,404.73c0,0.6,0.1,1.1,0.1,1.7c-0.6,0-1.3,0-2,0C255.46,405.33,255.76,404.53,257.16,404.73z"/>
			<path class="st0-bull-white" d="M243.96,414.33c-0.7,1.2-1.5,1.9-3,1.5C241.16,414.73,241.66,414.53,243.96,414.33z"/>
			<path class="st0-bull-white" d="M275.86,388.23c0-0.7,0-1.4,0-2.4c0.7,0.6,1.2,1.1,1.9,1.6c-0.6,0.4-1,0.7-1.5,1
				C276.16,388.43,275.96,388.33,275.86,388.23z"/>
			<path class="st0-bull-white" d="M243.96,408.63c0.2,0.3,0.4,0.5,0.6,0.7c-0.5,0.8-1.1,1.5-1.7,2.3
				C242.76,410.43,242.46,409.23,243.96,408.63z"/>
			<path class="st0-bull-white" d="M254.26,378.43c-0.2,2,1.5,0.9,1.8,2c-0.9,0.9-1.9,0.5-3.1-0.1C253.36,379.73,253.66,379.33,254.26,378.43z"
				/>
			<path class="st0-bull-white" d="M263.46,395.13c1,0.8,1.6,1.3,2.2,1.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,0.1-1.1,0.2-1.8,0.3
				C263.56,396.93,263.56,396.23,263.46,395.13z"/>
			<path class="st0-bull-white" d="M278.86,379.13c0.2-0.4,0.4-0.6,0.5-0.9c0.9,0.8,2.5,0.3,3.5,1.7C281.46,379.63,280.36,379.43,278.86,379.13
				z"/>
			<path class="st0-bull-white" d="M266.96,402.33c-0.9,0.6-1.3,0.9-1.9,1.3c-0.3-0.8-0.5-1.3-0.9-2.2
				C265.16,401.83,265.86,402.03,266.96,402.33z"/>
			<path class="st0-bull-white" d="M266.06,396.43c0.2-0.2,0.3-0.4,0.5-0.6c0.4-0.2,0.8-0.4,1.1-0.5c0,0.3,0.1,0.8-0.1,1
				C267.26,396.83,266.76,397.53,266.06,396.43z"/>
			<path class="st0-bull-white" d="M277.76,398.83c-1.3,0-2.4,0.5-3.1-0.9C275.86,397.23,276.56,398.13,277.76,398.83z"/>
			<path class="st0-bull-white" d="M254.26,411.03c0.2-1.1,0.8-1.6,2-1C255.46,410.43,254.86,410.73,254.26,411.03z"/>
			<path class="st0-bull-white" d="M245.26,404.03c-0.4,0.2-0.8,0.4-1.3,0.6c-0.1-0.2-0.3-0.6-0.3-0.6c0.4-0.2,0.8-0.4,1.2-0.6
				C245.06,403.63,245.16,403.83,245.26,404.03z"/>
			<path class="st0-bull-white" d="M257.76,406.73c0.7,0.1,1.3,0.1,1.9,0.2C258.86,408.03,258.86,408.03,257.76,406.73z"/>
			<path class="st0-bull-white" d="M251.06,412.53c-0.6-0.2-1.1-0.4-1.8-0.7c0.2-0.4,0.3-0.8,0.5-0.8
				C250.46,410.93,250.56,411.03,251.06,412.53z"/>
			<path class="st0-bull-white" d="M250.16,399.93c-0.2-0.5-0.4-0.9-0.6-1.6C250.76,398.53,250.56,399.23,250.16,399.93z"/>
			<path class="st0-bull-white" d="M256.26,399.23c-0.2-0.1-0.4-0.2-0.6-0.3c0-0.5,0-1,0.1-1.5c0,0,0,0.1,0,0
				C256.36,397.93,256.56,398.53,256.26,399.23z"/>
			<path class="st0-bull-white" d="M246.96,398.13c-0.4,0-0.8,0-1.2,0c0-0.1,0-0.2,0-0.3c0.4,0,0.8,0,1.2,0
				C246.96,397.93,246.96,398.03,246.96,398.13z"/>
			<path class="st0-bull-white" d="M272.06,396.23c-0.2-0.2-0.4-0.5-0.9-1c0.7,0.1,1,0.1,1.2,0.3c0.1,0.1,0,0.4,0.1,0.7
				C272.36,396.13,272.26,396.23,272.06,396.23z"/>
			<path class="st0-bull-white" d="M271.66,373.33c0.4,0,0.8-0.1,1.2-0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.1-0.4,0.3-0.7,0.3
				c-0.2,0-0.4-0.1-0.7-0.2C271.66,373.53,271.66,373.43,271.66,373.33z"/>
			<path class="st0-bull-white" d="M255.86,375.43c0-0.4,0-0.8,0-1.3c0.1,0,0.2,0,0.3,0c0.1,0.4,0.1,0.9,0.2,1.3
				C256.26,375.43,256.06,375.43,255.86,375.43z"/>
			<path class="st0-bull-white" d="M242.56,407.93c-0.6,1.1-1,1-1.5,0.1C241.56,408.03,241.96,408.03,242.56,407.93z"/>
			<path class="st0-bull-white" d="M251.06,409.73c0.2,0.2,0.5,0.4,0.7,0.6c0,0-0.2,0.3-0.3,0.4c-0.2-0.2-0.4-0.4-0.7-0.6
				C250.96,409.93,251.06,409.83,251.06,409.73z"/>
			<path class="st0-bull-white" d="M290.96,378.83c1.3,0.3,0.9,1,0.5,1.6C291.26,379.93,291.16,379.43,290.96,378.83z"/>
		</g>
		<path class="st0-bull-white" d="M568.26,318.93c0.3,0.7,0.1,1.2-0.6,1.1c-0.7-0.1-1.4-0.5-2.2-0.8c-0.4,0.5-0.8,1.2-1.3,1.8
			c0.1,0.4,0.2,0.8,0.3,1.2c-1.6,0.2-0.9,1.6-1.2,2.4c-0.6,0.4-1.1,0.7-1.8,1.1c1.1,1,1.8,0.2,2.4-0.2c0.7-0.4,1.2-1.1,1.9-1.7
			c-0.1-0.3-0.3-0.7-0.5-1.1c0.7-0.7,1.4-0.9,2.1,0c-0.5,2.1-0.8,4.3-1.5,6.3c-0.9,2.8-0.7,2.8-3.7,3.2c-0.6,1.2,0.9,0.9,1.1,1.5
			c-0.7,0.6-0.7,0.6-0.2,1.2c0-0.5,0.1-0.9,0.1-1.3c0.4,0.1,0.8,0.2,1.4,0.4c-0.3,1.1-0.5,2.1-0.8,3.1c-0.2,0.5-0.5,1-1.2,0.7
			c-1-0.7-1.7-0.4-2.4,0.6c0.3,0.3,0.6,0.5,0.6,0.5c0.7-0.5,1.3-0.8,1.8-1.2c0.9,1.2,0.7,2.4,0.2,3.6c-0.9,1.9-0.8,4.1-2,6
			c-0.5,0.8-1.3,1.4-1.5,2.4c-0.1,0.4-0.3,0.8-0.4,1.2c0.4,0,0.8,0.1,1.4,0.2c-0.3,1-0.6,2-0.9,3c-0.1,0-0.3,0-0.4,0
			c-0.2-0.5-0.3-0.9-0.6-1.6c-0.5,0-1.1-0.1-1.7-0.1c-0.4,1.1-0.9,2-1.3,3.1c-0.8,0-1.5,0-2.3,0c-18.3,0-36.6,0-54.9,0
			c-3.6,0-7.2,0-10.8,0c-0.9,0-1.6-0.1-1.6-1.2c-1.3,2.3-3.3,0.7-5,1.3c-0.2-0.8-0.3-1.4-0.6-2.3c-0.5,0.5-0.9,0.8-1.1,1.1
			c-0.5,0.8-1.1,1.1-2,1.1c-4.5-0.1-9.1-0.2-13.6-0.2c-4.1,0-8.3,0.1-12.4,0.2c-0.6,0-1.1,0-1.7,0c-0.9,0-1.5-0.2-1.4-1.4
			c-0.3,1-0.6,1.5-1.6,1.4c-0.9-0.1-1.8,0-2.7,0c-0.7,0-1.3,0-1.7-0.8c-0.6-1-1.6-0.8-2.7-0.6c-0.2,0.4-0.3,0.9-0.5,1.4
			c-0.7,0-1.3,0.1-1.9,0c-0.4-0.1-0.8-0.2-1.1-0.5c-1.2-1.6-2.3-3.2-3.5-4.9c0.3-0.1,0.6-0.3,1-0.5c-2-0.1-2.9-1.3-3.5-2.9
			c-0.2-0.4-0.6-0.7-0.9-1.1c-1.6-2-3.2-4-4.7-6c-0.1-0.2-0.2-0.4-0.3-0.6c-0.6-1.8-1.1-2.3-2.2-3.6c-1.7-1.9-3.3-4-4.9-5.9
			c0.3,0,0.7,0.1,1,0.1c0,0.7,0.3,0.9,0.9,0.6c0.5-0.3,0.9-0.8,1.5-1.3c-1.3-1.3,0-3.3-1.7-4.4c-0.6,0.4-1.1,0.9-0.5,1.7
			c0.4,0.6,0.4,1.3-0.3,1.8c-0.1-0.4-0.2-0.8-0.3-1.4c-0.8,0.9-0.2,1.1,0.3,1.4c-0.1,0.1-0.1,0.2,0,0.3c-0.3,0.4-0.6,0.8-0.9,1.2
			c-1.2-0.7-1.4-1.7-1-3c0.3-1.1,0.6-2.1,0.8-3.2c0.8-0.3,1.5-0.6,2.3-0.9c-0.6-1.9-1.8-3.5-0.6-5.5c0.2,0,0.5,0.1,0.9,0.2
			c0.1-0.8,0.2-1.7,1.3-1.9c0.2,0,0.5-0.3,0.6-0.6c0.1-0.2,0.2-0.7,0.1-0.7c-0.2-0.2-0.6-0.3-0.9-0.2c-0.3,0.1-0.6,0.4-1.1,0.6
			c-0.1-0.4-0.1-0.5-0.2-0.7c-0.4-1.7,1.1-2.9,1.2-4.5c0.1-1.4,1-2.9,1.8-4.2c0.4-0.7,1.2-1.1,2.1-1.9c-1,0.2-1.7,0.3-2.4,0.4
			c0.3-1.1,0.6-2.2,0.8-3.3s0.4-2.2,0.6-3.2c0.5-0.1,0.8-0.2,1.2-0.3c-1.3-1.9-0.2-3.4,0.4-5c0.1-0.3,0.6-0.2,0.2-0.7
			c-0.2-0.3,0.1-1,0.2-1.6c0.5-2.7,1.7-5.2,2.1-8c0.4-2.7,1.2-5.4,1.9-8c0.2-1,0.5-2,0.8-3c0.1-0.4,0.4-0.8,0.9-1.1
			c-0.1,0.4-0.3,0.9-0.2,1.3c0.2,0.5,0.6,0.9,0.9,1.3c-0.3,0.5-0.7,1-1.2,1.7c0.8,0.2,1.4,0.3,2.1,0.5c1-1.4,0.4-2.8-0.3-4.2
			c-0.2-0.3-0.4-0.6-0.4-0.9c-0.1-2.6-0.3-5.2,0.5-7.7c1.1-0.5,0.6-1.7,1.1-2.3c0.6-0.8,1.6-1.3,2.7-2.1c0.4,0.9,0.6,1.5,0.8,2.1
			c1.3-0.4,1.7-0.8,1.4-1.8c-0.6-1.7,0.3-2.6,1.4-3.4c0.5,0.5,1,0.9,1.5,1.4c-0.1,1-0.3,2-0.4,3.1c1.7,0.1,2.2-0.1,2.2-1
			c0-1.2-0.6-1.9-1.9-2c0.1-0.6,1.2-1.1,0.3-1.9c-0.1-0.1,0.1-0.6,0.3-0.9c0.4-0.5,0.7-1,1.2-1.4c6.5-5.3,13.1-10.6,19.7-15.9
			c0.1-0.1,0.2-0.1,0.6-0.1c0,0.7,0.1,1.3,0.1,2c0.1,1.8,0.4,3.7,0.4,5.5c0,1.7-0.2,3.3-0.3,5c-0.1,1.8-0.2,3.6-0.5,5.4
			c-0.2,1.8,0.2,3.7-0.7,5.5c-0.5,1,0.2,2.3-0.6,3.4c-0.6,0.8-0.6,1.9-0.8,2.9c-0.3,1.3-0.7,2.7-2,3.5c-0.9,0.5-1.5,0.9-2.4,0.1
			c-0.3-0.2-1-0.3-1.4-0.1c-0.9,0.3-1.7,0.8-2.5,1.3c-0.2,0.8,0.4,1.2,0.9,0.9c1.9-1.1,3.9-0.4,6-0.7c0.4,1.1,0.7,2.1,1.1,3.2
			c-0.9,0.4-1.5,0.7-2.2,1c-0.5-0.5-1-1.1-1.4-1.5c-0.5,0.3-1,0.5-1.5,0.8c0.1,1.7,0.2,1.8,1.6,2c0.8,0.1,1.5,0.2,2.4,0.3
			c0.1-0.3,0.2-0.8,0.4-1.3c0,0,0.1-0.1,0.4-0.2c0.8,0.6,1.6,1.3,2.5,2c0.9-0.4,1.3-1.2,1-1.9c-0.2-0.6-0.9-1-1.5-1.6
			c-0.1-1.3-0.4-2.8,1-3.9c0.1-0.1-0.1-0.4-0.1-0.6c1,0.1,2,0.3,3,0.4c-0.1,0.5-0.2,0.9-0.2,1.4c1.3,0.3,1.4-0.7,1.6-1.3
			c0.4-1,1-1.4,2.1-1.5c0.9-0.1,1.8-0.5,2.8-0.8c-1.3-0.6-2.5-0.6-3.7,0.4c-0.3-0.5-0.5-0.9-0.7-1.2c-1.1,0.7-2,1.8-3.1,2
			c-0.7,0.1-1.2,0.3-1.8,0.6c-0.4-1.5-0.5-3.1,0.1-4.6c0.2-0.4,0.2-0.9,0.2-1.4c0-0.3-0.1-0.8,0.1-1c1.4-2.4,0.6-5.2,1.2-7.6
			c0.6-2.7-0.4-5.4,0.8-7.9c0.1-0.1-0.1-0.4-0.2-0.5c-0.4-1.7-0.4-1.8,0.7-2.9c0-1.4,0-2.7,0-4.1c-0.3-0.4-1-1-1.3-1.7
			c-0.5-1.2,0.1-2.4,1.4-3.4c0.7-0.5,1.4-1.1,2-1.8c0.7-0.9,0.4-1.6-0.7-1.7c-1-0.1-2,0-3.3,0c0.1-4.4-0.7-8.6,0.3-12.8
			c-0.2,0.2-0.5,0.3-0.5,0.5c0,1.5-1.4,2.9-0.4,4.5c0.1,0.1,0.1,0.3,0.1,0.4c-1.1,2.3-0.1,4.8-0.7,7.3c-1.4,0.1-2.8,0.2-4.2,0.2
			c-2.4-0.1-4.8,0.4-7.2-0.4c-0.8-0.3-1.8,0.2-2.8,0.3c-1.7,0.3-3.3,0.1-4.7-0.9c-0.9,1.8-2.3,0.6-3.5,1.2c0.1-1.6,0.5-2.8,1.8-3.1
			c0.8-0.2,1.6,0.1,2.4-0.4c0,0,0.6,0.7,0.8,1.1c0.1,0.3-0.2,0.6-0.4,1.4c0.7-0.4,1-0.5,1.3-0.7c0.1,0,0-0.3,0-0.4
			c0.2-1.4,0.4-2.7,0.6-4.3c-0.8,0.7-1.5,1.3-2.1,1.9c-0.7-0.2-1.3-1.1-2.1-0.1c-0.1,0.1-0.8-0.2-1.4-0.4c0.6-2,1.3-4,1.9-5.9
			c0,1.4,0.9,1.6,2,1.5c1.1,0,1.1,0.6,0.8,1.4c0.7,0.2,1.4,0.3,2.4,0.6c-0.7-1.2-1.2-2.1-1.8-3.2c0.6-1.9,0.5-2.3-0.7-2.9
			c-1,0.7,1.3,2-0.6,2.6c-0.2-0.3-0.3-0.6-0.4-0.8c-0.7,0.3-1.2,0.6-1.8,0.9c0.3-1.5,0.6-3,0.9-4.4c0.2-0.8,0.7-0.9,1.5-0.7
			c0.1,0.5,0.3,1,0.4,1.5c0.1,0,0.2,0,0.4,0c0-0.6,0.1-1.3,0.1-2c-0.6-0.1-1-0.3-1.6-0.4c-0.2-1.8,0.4-3.4,1.1-5.1
			c0.1-0.2,0.5-0.4,0.5-0.6c-0.1-3.8,1.5-7.2,2.3-10.8c0.8-3.8,1.9-7.5,2.8-11.4c2.5,0,5-0.1,7.4-0.1c2.5,0,5,0,7.5,0
			c2.6,0,5.1,0,7.7,0c2.6,0,5.1,0,7.7,0c2.6,0,5.1,0,7.7,0c2.5,0,5,0,7.4,0c2.5,0,5.1,0,7.8,0c-0.4,1.5-0.4,2.9-1,4
			c-0.5,1.1-0.5,2.2-0.7,3.2c-0.3,1.3-0.6,2.5-1,3.8c-0.3,1.2-0.6,2.4-0.9,3.6c-0.3,1.3-0.7,2.5-1,3.8c-0.5-0.5-0.8-0.8-1.3-1.3
			c-0.4,0.7-0.6,1.2-0.9,1.8c0.5,0.5,1.1,1.1,0.6,2c-0.2,0.3,0.3,0.9,0.5,1.5c-0.7,1.1-1.4,2.2-1.2,3.9c0.1,1.5-0.3,3-0.7,4.5
			c-0.6,2.4-1.3,4.7-1.9,7c-0.6,2.3-1.3,4.6-1.9,6.9c-1.2,5-2.3,10.1-3.5,15.1c-4,16.2-8.1,32.4-12.2,48.6c-0.4,1.6-0.8,3.3-1.2,5
			c-0.5,1.8-0.9,3.6-1.4,5.4c-0.4,1.7-0.9,3.5-1.3,5.2c-0.2,0.9-0.7,1.9,0.6,2.5c-0.3,0.6-0.5,1.1-0.9,1.8c0.6,0.1,1,0.2,1.6,0.3
			c0.1-0.8,0.3-1.4,0.4-2.2c0.8,0,1.5,0,2.2,0c12,0,24.1,0,36.1,0c0.5,0,1,0,1.5,0c1.6-0.1,1.7-0.2,2.2-1.6c0.1-0.5,0.3-0.9,0.4-1.4
			c0.4-0.1,0.8-0.2,1.5-0.4c-1.2-0.3-1.2-0.8-1-1.7c1.9-7.6,3.8-15.1,5.7-22.7c6.3-25.1,12.6-50.2,18.9-75.3
			c1.8-7.1,3.6-14.2,5.3-21.3c0.2-0.7,0.4-1.3,0.6-2c6,0,11.8,0,17.7,0s11.8-0.1,17.6-0.1c5.9,0,11.7,0,17.8,0c-0.4,1.6-0.7,3-1,4.4
			c-2.6,10.4-5.2,20.9-7.9,31.3c-0.8,3.2-1.5,6.5-2.4,9.7c-0.9,3.3-1.7,6.6-2.5,9.8c-0.8,3.3-1.6,6.6-2.5,9.8
			c-0.2,0.6-0.6,1.2-0.8,1.8c-0.2,0.4-0.3,0.9-0.4,1.3c-0.2,1-0.3,2-0.4,3.1c-1.6,0.2-2.9,1.1-4.4,1c-0.6,0-1.7,0.2-1.7,1.4
			c-2.9-0.5-3.2-0.4-3.7,1.4c0.6,0,1.5,0.1,1.5,0c0.3-1.2,1.5-0.9,2.2-1.5c0.4,0.1,0.8,0.2,1.3,0.3c0.4-0.3,1.2-1.2,1.4-1.1
			c1,0.5,1.9-0.4,3,0c-0.3,2.7-1.2,5.2-1.8,8c-0.5-0.1-1-0.1-1.4-0.2c-0.4,1.1,0.7,0.8,1,1.2c-0.5,3.4-1.1,5.3-2,5.7
			c0,0.8,0.2,1.6,0,2.3s-0.9,1.2-1.4,2c1,0.8,0.7,1.9,0.3,3c-0.2,0.6-0.2,1.2-0.5,1.8c-0.7,1.5-1.4,3-1.2,4.7c0,0.5-0.3,1-0.5,1.7
			c-0.7-0.4-1.2-0.7-1.7-1c-0.8,1.3,0.3,1.5,1.1,1.9c-1,1.7-2,3.2-3,4.8c0.5,0.1,1.2,0.2,2.1,0.3c-0.4,1.6-0.8,3.3-1.2,5
			c-1,0.4-2.3,0.3-2.6,1.7c-0.1,0.3-0.5,0.6,0,1c0.2,0.1,0.2,0.7,0.1,0.7c-0.9,0.7,0,1.6-0.3,2.1c-0.5,1-0.1,1.4,0.2,2.2
			c0.4,0.8,0.2,1.7-1.1,1.8c-0.3,0-0.5,0.3-1.2,0.7c0.2-0.7,0.3-1,0.3-1.3c0.1-0.9-0.6-1.6-1.5-1.4c-0.2,0-0.3,0.6-0.5,0.9
			c0.7,0.3,0.9,0.8,0.9,1.6c0,0.1,0.6,0.4,0.9,0.5c0.5,0.1,1.1,0,1.8,0C570.26,312.33,569.66,315.73,568.26,318.93
			c0-0.3,0-0.7,0-1.4C567.36,318.43,567.86,318.63,568.26,318.93z M462.46,278.83c-1.5,0.5-1.8,1.7-1.5,3.1
			c-0.4,0.1-0.8,0.1-1.1,0.2c0,0.6,0.3,1.4,0,1.6c-1.1,0.9-1,2.3-1.6,3.5c1.9-0.8,2.8-2,2.5-3.9c-0.1-0.5,0-1,0-1.4
			C462.86,281.53,461.96,279.83,462.46,278.83c0.9-0.2,0.9-0.2,0.8-1.9C462.96,277.83,462.76,278.33,462.46,278.83z M564.96,311.53
			c0-0.9,0-0.9-0.8-0.6C564.46,311.13,564.66,311.33,564.96,311.53c0,0.4,0,0.8,0,1.3c-0.7-0.3-1.5-0.4-1.9,0.4
			c-0.2,0.4-0.2,1.1,0,1.4c0.4,0.7,1.1,1.1,2.2,0.4c-0.1-0.7-0.2-1.5-0.3-2.3c0.8,0.2,1.5,0.5,2.3,0.6c0.9,0.1,1.6-0.2,1.4-1.5
			C567.36,311.83,566.16,311.63,564.96,311.53z M465.36,333.43c0.3,0.3,0.5,0.8,0.9,0.9c0.4,0.2,1.8-0.9,1.9-1.4
			c0.1-0.5,0.3-1.1,0.5-2c-0.9,0.6-1.4,1-2,1.4c-0.6-0.4-1.1-0.8-1.7-1.2c-0.1,0.1-0.2,0.2-0.3,0.3
			C464.86,332.13,465.16,332.83,465.36,333.43c-0.9-0.4-1.2,0.3-1.8,0.8C464.66,334.83,464.96,334.13,465.36,333.43z M553.16,263.33
			c-0.8-0.4-1.7-0.8-2.7-1.3c0.3-2.5,0.9-5.1,2.2-7.6c-0.4-1-0.4-1,0-2.1c0.1-0.3,0.3-0.6,0.3-1c0-1.7-0.1-3.3,0-5s0.4-3.4,0.5-5.1
			c0.1-2-0.1-4.1,0.6-6.1c0.2-0.6,0.5-1.7-0.8-2c-0.2,0-0.4-0.5-0.4-0.7c0.5-1.5-1.7-3-0.1-4.6c0,0-0.1-0.2-0.1-0.4
			c-0.9-2.7-0.6-5.5-0.7-8.2c-0.1-1.2,0.1-2.5,0-3.7c0-0.2-0.6-0.3-1.2-0.6c0,2.3,0,4.2,0,6.2c0,2.2,0.1,4.5,0.2,6.7
			c0,0.3,0,0.6,0.1,0.8c0.8,0.8,0.6,1.8,0.6,2.7c0,2.4,0,4.8,0,7.3c0,0.5,0.1,1.2-0.2,1.6c-0.5,0.6-0.3,0.9,0,1.6
			c0.3,0.8,0,1.8-0.1,2.7c0,0.3-0.3,0.6-0.3,0.9c-0.1,2.3-0.3,4.6-0.4,6.9c-0.2,3.6-0.9,6.9-2.1,10.2c-0.3,0.9-1,1.5-2,1.4
			c-1.3-0.2-2.3,0.4-3.4,1.2c0.2,0.2,0.4,0.6,0.5,0.6c0.7-0.3,1.3-0.6,1.6-0.8c1.1,0.1,1.9,0.1,2.8,0.2c0.1,0.3,0.2,0.7,0.3,1.3
			c0.5,0,1-0.1,1.4-0.1c0.4-1.9,0.4-2,2.2-2.6C552.26,263.63,552.76,263.43,553.16,263.33c1.2,0,0.8,1.4,1.4,2
			c0.3-0.1,0.6-0.1,0.6-0.2c0.2-1.4,1-2.1,2.5-2.2c0.6,0,1.3-0.3,1.9-0.6c0.4-0.2,0.7-0.5,1.5-1.1c-1.8-0.1-3-0.1-4.2-0.2
			c-0.1-0.6-0.1-1.1-0.1-1.5c-1,1.4-1.9,2.7-3.5,3.2C553.16,262.73,553.16,263.13,553.16,263.33z M586.16,212.83
			c0.5,0.7,1.3,1.2,0.7,2.5c-0.5,1.2-0.4,2.9,0,4.1c0.6,1.5,0,2.2-0.9,3c-0.2,0.2-0.3,0.4-0.6,0.7c0.6,0.3,1.1,0.5,1.5,0.6
			c1.8-1.9,1.6-4.3,1.5-6.6c0-0.6-0.3-1.2-0.2-1.7c0.6-2.8-0.3-5.6-0.4-8.4c-1.1-1.4-0.7-2.7,0-4.1c0,0-0.2-0.2-0.4-0.5
			c-0.5,0.9-1.4,1.9-1.3,2.6c0.3,1.2,0.2,2.6,0.7,3.6C587.56,210.23,586.86,211.33,586.16,212.83z M560.56,318.33
			c0.5-0.6,0.2-1.8,1.3-1.9c-0.1-0.7-0.2-1.4-0.3-2.4c-1.7,0.5-3.6,1-5.4,1.5c-0.1,0.5-0.2,1-0.3,1.4c-0.6-0.1-1.1-0.1-1.7-0.2
			c0.4,1.7,2.1,1.6,3.1,2.5c-0.2-0.8-0.3-1.5-0.5-2.3C558.56,316.03,559.06,318.03,560.56,318.33z M471.16,329.03
			c0.1,0.1,0.2,0.3,0.3,0.4c1.5-0.4,2.6-2,4.3-1.8c0.1,0,0.2-0.2,0.3-0.3c0.7-1.1,1.9-1.6,3.1-2.2c-0.1-0.5-0.1-1-0.2-1.7
			c-1.1,0.5-1.9,0.9-2.6,1.8c-0.6,0.8-1.8,1.2-2.7,1.9C472.76,327.83,471.96,328.43,471.16,329.03z M587.16,195.53
			c-0.2,0-0.3,0-0.5,0c0,0.5,0.1,1.1,0,1.6s-0.4,1-0.6,1.4c-0.1,0.3-0.1,0.7,0,1c0.3,0.7,0.7,1.4,1,2.1c1.1-0.9,1.5-1.3,1.3-1.9
			c-0.1-0.3-0.6-0.6-0.9-0.8C588.66,197.73,586.66,196.83,587.16,195.53z M569.06,260.63c-0.5-0.3-0.9-0.7-1.2-0.7
			c-1.3,0.3-2.5,0.7-3.8,1.2c-0.4,0.2-0.8,0.5-1.1,0.8c-0.2,0.2-0.1,0.6,0,0.9c0,0.1,0.5,0.2,0.7,0.1
			C565.36,262.33,567.06,261.53,569.06,260.63z M583.26,231.33c0-2.6,0-5.2,0-7.8c-1-0.2-1,0.3-1.1,0.9
			C581.76,226.83,583.16,228.93,583.26,231.33z M447.16,269.13c-1.5,0.3-1.9,1.3-2.3,2.7c1.9,0.1,3.3-0.9,4.9-1.4c0.1,0,0-0.4,0-0.6
			C448.06,270.43,447.96,270.33,447.16,269.13z M470.66,319.63c-0.6,0-1.1-0.1-1.6,0c-0.9,0.2-0.9,1.7-2.2,1.5
			c0.1,0.4,0.2,0.8,0.3,1.2c0.5-0.1,1-0.2,1.4-0.4c0.6-0.3,1-0.8,1.5-1.2C470.46,320.53,470.46,320.13,470.66,319.63z
			 M565.36,305.93c-0.8,0-1.4-0.1-1.6,0.1c-0.8,0.9-1.5,2-2.3,3c1,0.3,2.2,0.5,2.2-0.5c0-1.2,0.6-1.3,1.4-1.6
			C565.06,306.93,565.06,306.53,565.36,305.93z M430.26,306.03c-0.3-1.1-0.3-1.7,0.6-1.8c0.8-0.1,0.7-0.6,0.5-1.3
			c-0.8,0.3-1.6,0.4-2,0.9c-0.4,0.4-0.5,1.2-0.7,1.8C429.06,305.63,429.56,305.83,430.26,306.03z M453.16,293.43
			c-1.5,1.1-2.1,2.1-2,3.3c0,0.3,0.4,0.6,0.7,1C452.26,296.23,452.66,295.03,453.16,293.43z M446.86,276.23c0.5,0.7,0.9,1.2,1.2,1.8
			c0.5,1.1,0.4,1.1,1.6,1.2c-1.4-1-0.5-1.9-0.3-3C448.46,276.23,447.76,276.23,446.86,276.23z M451.56,223.23c0.4,0,0.8,0,1.2,0
			c0.1-1,0.2-1.9,0.4-3c-0.8,0.4-1.3,0.6-1.5,0.7C451.56,221.73,451.56,222.33,451.56,223.23z M429.46,281.63
			c-1.2,0.7-0.9,1.4-0.7,2.1c0.3,0.1,0.4,0.1,0.5,0.1c0.6-0.1,1.4-0.1,1.6-0.4c0.5-0.9-0.8-0.6-1-1.1
			C429.76,282.13,429.66,281.93,429.46,281.63z M495.36,203.13c-1.6-0.8-1.5,1.3-2.6,1.3c0.6,0.7,1,1.3,1.5,1.8
			C494.66,205.13,494.96,204.13,495.36,203.13z M443.56,252.63c0.3,0.8-0.5,2,1,2.1c0.5-1,0.9-2,1.5-3.3
			C445.06,251.93,444.46,252.23,443.56,252.63z M437.06,348.73c-0.9,0.3-2.3,1.7-2.3,2.3c0.4,0.2,0.9,0.4,1.4,0.7
			C436.46,350.63,436.76,349.83,437.06,348.73z M471.26,319.13c1.4,0.6-0.6,2.1,1,2.7c0.5-1.1,1.2-2.1,0.6-3.3
			C472.46,318.73,471.96,318.83,471.26,319.13z M573.46,295.23c-0.7,0.2-1.4,0.4-2.1,0.6c0.3,0.6,0.5,1.1,0.7,1.6
			C572.76,296.73,574.36,297.03,573.46,295.23z M440.56,300.33c-0.8,0.9-1.7,1.4-2.2,2.6c1.2-0.4,2.1-0.7,3.2-1.1
			C441.26,301.43,440.96,301.03,440.56,300.33z M583.56,236.03c0.8-2.9,0.7-3.5-0.5-3.7C583.26,233.63,583.46,234.83,583.56,236.03z
			 M462.26,350.63c0,0.5,0,1,0,1.4c2.2-0.3,2.2-0.3,1.8-2.2C463.46,350.13,462.86,350.33,462.26,350.63z M582.06,201.43
			c0.1-0.4,0.3-0.9,0.5-1.3c0.2-0.4,0.5-0.7,0.8-1.1c-1.5-0.5-2.1,0.4-2.8,1.3C580.96,200.63,581.46,200.93,582.06,201.43z
			 M444.66,335.23c0.8-0.4,2-2.7,1.9-3.1c-0.1-0.3-0.4-0.4-0.8-0.8C445.36,332.73,445.06,334.03,444.66,335.23z M428.16,293.23
			c1-0.2,2.1-0.3,2.7-1.5C429.26,291.03,428.86,292.33,428.16,293.23z M542.36,342.53c0.1-1.5-1.4-2-2-3.3
			C539.86,341.23,541.56,341.53,542.36,342.53z M440.36,349.23c0.6,0.8,0.9,1.2,1.3,1.8c0.6-0.6,1-1.1,1.6-1.8
			C442.26,349.23,441.56,349.23,440.36,349.23z M551.86,270.33c-0.3-0.6-0.7-1.2-1.3-2.1c-0.3,0.7-0.8,1.3-0.6,1.6
			C550.26,270.33,550.86,271.13,551.86,270.33z M560.76,318.73c-0.2,0.1-0.3,0.2-0.5,0.3c0.1,0.6-0.5,1.3,0.6,1.8
			c0.4-0.3,0.8-0.6,1.3-1C561.66,319.43,561.16,319.13,560.76,318.73z M436.66,271.83c1.2,0.2,1.5-0.8,2.2-1.5
			C436.86,270.23,436.76,270.23,436.66,271.83z M467.66,317.93c-0.7-1.1-1.2-0.6-1.7-0.3c-0.2,0.2-0.2,0.6-0.3,1.1
			C466.46,318.43,467.06,318.23,467.66,317.93z M454.26,222.53c1.6-0.8,0.2-1.5,0.4-2.4C453.36,221.03,454.06,221.73,454.26,222.53z
			 M454.56,287.93c0.9,0.3,1.6,0.5,2.4,0.7c-0.3-0.9-0.5-1.5-0.7-2.1C455.86,286.93,455.36,287.33,454.56,287.93z M468.36,273.23
			c0.3,0.5,0.5,1,0.7,1.5c0.7-0.5,1.4-1,1.5-2C469.76,272.93,469.06,273.13,468.36,273.23z M593.26,197.83c-1-1.3-1.3-1.5-1.7-1.2
			c-0.1,0.1-0.3,0.4-0.2,0.5C591.76,197.83,592.26,198.43,593.26,197.83z M443.36,345.23c-1.3-0.4-1.5-0.3-1.8,1.7
			C442.26,346.23,442.76,345.73,443.36,345.23z M563.46,266.63c0.6,1.5,1.7,1.2,2.6,1.1c0.1,0,0.2-0.5,0.3-0.8
			C565.36,268.03,564.56,266.53,563.46,266.63z M472.06,342.23c1,0.6,1.7,0.6,2.1-0.8C473.36,341.73,472.76,342.03,472.06,342.23z
			 M451.56,216.73c0,0.7,0,1.2-0.1,1.7c-0.3,0.9,0.3,0.7,0.8,0.9C452.36,217.23,452.36,217.23,451.56,216.73z M486.06,317.13
			c-0.7,0.1-1.3,0.2-2.2,0.3C485.26,318.53,485.26,318.53,486.06,317.13z M421.96,318.53c-1.1,1.2-1.1,1.2-0.1,2
			C421.86,319.83,421.86,319.23,421.96,318.53z M436.36,305.03c1.6-0.2,1.6-0.2,1.6-1.5C437.06,303.53,436.76,304.13,436.36,305.03z
			 M572.96,258.13c-0.6,1-0.9,1.5-1.2,2.1c0.5,0.3,0.8,0.5,1.2,0.7C572.96,260.13,572.96,259.43,572.96,258.13z M443.56,258.73
			c0.9,0.2,1.5,0.6,1.9-0.3c0.1-0.1-0.1-0.5-0.2-0.5C444.46,257.53,444.26,258.13,443.56,258.73z M469.76,313.13
			c0.4-0.7,0.7-1.1,1.1-1.8c-0.8,0-1.3,0-1.9,0C469.16,311.93,469.46,312.43,469.76,313.13z M558.26,258.23c0.1-0.3,0.1-0.7,0.2-1
			c0-0.3,0-0.5-0.1-1C557.16,257.73,557.16,257.73,558.26,258.23z M571.16,290.93c0.1,0.2,0.3,0.3,0.4,0.5c1,0.1,1.1-0.7,0.9-1.7
			C572.06,290.13,571.66,290.53,571.16,290.93z M437.46,260.63c-0.1,0.2-0.2,0.3-0.4,0.5c0.7,0.4,1.4,0.8,2.4,1.4
			C439.26,260.83,438.16,261.03,437.46,260.63z M434.96,281.13c0.2-1.8,0.2-1.8-0.9-1.9C434.36,279.73,434.56,280.23,434.96,281.13z
			 M427.76,306.33c-0.6,0.2-1.1,0.4-1.6,0.6c0,0,0.1,0.6,0.2,0.7C427.16,307.73,427.56,307.33,427.76,306.33z M557.06,307.63
			c0.1,0.8,0.4,1.3,1.1,1c0.1-0.1,0.2-0.5,0.2-0.5C557.86,307.93,557.46,307.73,557.06,307.63z M558.86,313.03c1.4,0,2-0.4,2-2.1
			C560.06,311.73,559.56,312.23,558.86,313.03z M449.36,264.03c0.1,1.3,0.4,2,2,1.9C450.56,265.13,450.06,264.73,449.36,264.03z
			 M562.06,317.03c-0.1,0.2-0.2,0.4-0.3,0.6c0.4,0.2,0.8,0.4,1.3,0.5c0,0,0.2-0.3,0.3-0.5
			C562.96,317.43,562.46,317.23,562.06,317.03z M531.16,260.03c0.1,0.2,0.2,0.4,0.2,0.6c0.3-0.1,0.6-0.1,0.8-0.3
			c0.3-0.2,0.5-0.5,0.9-1C532.26,259.63,531.66,259.83,531.16,260.03z M452.36,254.33c-0.5-0.1-0.7-0.2-0.9-0.2
			c-0.3,0.1-0.6,0.2-0.8,0.4c-0.1,0,0.1,0.5,0.1,0.5c0.3,0,0.6,0,0.9-0.1C451.96,254.93,452.06,254.63,452.36,254.33z
			 M556.86,325.23c0.6-0.4,1.1-0.7,1.1-0.8c-0.1-0.4-0.4-0.7-0.7-1.1c0,0-0.2,0-0.4,0.1C556.86,323.83,556.86,324.23,556.86,325.23z
			 M477.06,335.53c0.6,0.6,0.9,0.8,1.2,1.1c0.3-0.4,0.5-0.7,0.9-1.1C478.46,335.53,477.96,335.53,477.06,335.53z M443.36,336.73
			c0.1,0.2,0.2,0.5,0.3,0.7c0.4-0.2,0.8-0.4,1.1-0.6c0,0-0.2-0.4-0.3-0.6C444.16,336.33,443.76,336.53,443.36,336.73z
			 M465.76,299.53c0.1,0,0.3,0.1,0.4,0.1c0.2-0.6,0.3-1.3,0.5-2C465.06,297.83,465.76,298.83,465.76,299.53z M454.76,295.13
			c0.4,0.1,0.7,0.2,1.1,0.4c0-0.7,0-1.1,0-1.6c0-0.1-0.2-0.2-0.4-0.4C455.26,294.03,455.06,294.53,454.76,295.13z M434.06,311.53
			c0.2,0.1,0.3,0.1,0.5,0.2c0.2-0.5,0.4-0.9,0.5-1.4c-0.2-0.1-0.4-0.2-0.5-0.2C433.56,310.33,434.06,310.93,434.06,311.53z
			 M470.66,261.03c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.5-0.4,0.9-0.6,1.4c0.2,0.1,0.7,0.2,0.7,0.2
			C470.36,261.93,470.56,261.43,470.66,261.03z M570.36,267.13c0-0.2,0-0.4,0-0.5c-0.6,0-1.2,0.1-1.8,0.1c0,0.1,0,0.3,0,0.4
			C569.16,267.13,569.76,267.13,570.36,267.13z M573.66,266.63c-0.6,1.1-0.7,1.6,0,1.6C573.66,267.73,573.66,267.33,573.66,266.63z
			 M496.16,313.23c0.2,0.1,0.3,0.2,0.5,0.2c0.2-0.4,0.5-0.8,0.7-1.1c-0.3-0.1-0.5-0.3-0.8-0.4
			C496.46,312.33,496.26,312.73,496.16,313.23z M560.86,207.53c0.6,0,1.1,0,1.6,0c0-0.1,0.1-0.2,0.1-0.4c-0.5-0.2-0.9-0.4-1.4-0.7
			C561.06,206.83,560.96,207.13,560.86,207.53z M559.46,212.43c0.4,0.3,0.6,0.6,0.8,0.6s0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7
			C560.16,211.83,559.86,212.13,559.46,212.43z M471.46,210.03c0.9,0.4,1.2-0.2,1.6-0.8C471.96,208.63,471.76,209.43,471.46,210.03z
			 M440.96,328.13c-0.3-0.4-0.4-0.8-0.6-0.8s-0.5,0.2-0.7,0.5c-0.1,0.1,0.2,0.5,0.3,0.6C440.16,328.33,440.46,328.23,440.96,328.13z
			 M452.56,269.83c-1.7-0.3-1.7-0.3-1.8,0.7C451.26,270.33,451.66,270.13,452.56,269.83z M558.66,269.03c-1.2,0.2-1.2,0.2-1.3,1.2
			C557.66,269.93,557.96,269.63,558.66,269.03z M560.66,205.13c-0.1-0.2-0.2-0.3-0.4-0.5c-0.4,0.3-0.8,0.7-1.4,1.1
			C560.06,206.23,560.36,205.63,560.66,205.13z M493.56,345.63c-0.5-0.3-0.7-0.6-0.9-0.5c-0.2,0-0.4,0.4-0.4,0.7
			c0,0.1,0.3,0.4,0.5,0.4C492.86,346.13,493.06,345.83,493.56,345.63z M558.46,325.43c1.4,0.1,1.1-0.6,1.1-1.3
			c0-0.1-0.2-0.1-0.3-0.2C558.96,324.33,558.76,324.83,558.46,325.43z M446.26,328.83c-1.1,0.5-1.1,0.5-0.9,1.6
			C445.66,329.73,445.96,329.23,446.26,328.83z M505.76,322.93c-0.6,1.1,0,1.3,1,1.7C506.36,323.93,506.16,323.53,505.76,322.93z
			 M586.86,224.33c-0.1,1.6-0.1,1.6,0.9,1.7C587.46,225.43,587.16,225.03,586.86,224.33z M555.76,329.73c-0.4-0.3-0.6-0.5-0.9-0.6
			c-0.2,0-0.5,0.3-0.5,0.4c0.1,0.3,0.2,0.6,0.4,0.7C554.96,330.23,555.26,330.03,555.76,329.73z M563.06,256.83
			c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.3,0.3-0.6,0.2-0.8c0-0.1-0.4-0.4-0.5-0.4c-0.2,0.1-0.5,0.4-0.6,0.6
			C562.76,256.23,562.96,256.53,563.06,256.83z M423.66,317.43c0.2-0.4,0.4-0.7,0.4-0.9s-0.4-0.5-0.4-0.5c-0.3,0.1-0.6,0.3-0.6,0.6
			C422.96,316.73,423.26,317.03,423.66,317.43z M473.36,316.53c-0.5-0.2-0.8-0.5-0.9-0.4c-0.2,0.1-0.4,0.4-0.5,0.7
			c0,0.1,0.4,0.4,0.5,0.4C472.76,317.03,472.96,316.83,473.36,316.53z M432.86,320.83c0.1,0.1,0.2,0.1,0.3,0.2
			c0.3-0.3,0.6-0.6,0.8-0.9c0-0.1-0.1-0.3-0.3-0.7C433.26,320.03,433.06,320.43,432.86,320.83z M570.76,261.03
			c0.1,0,0.3-0.1,0.4-0.1c0-0.5,0.4-1.2-0.9-1.4C570.46,260.13,570.66,260.53,570.76,261.03z M476.96,318.33
			c-0.4-0.3-0.7-0.6-0.9-0.6s-0.4,0.4-0.5,0.6c0,0.1,0.3,0.4,0.5,0.4S476.46,318.53,476.96,318.33z M443.36,261.03
			c0.8,0.3,1.2-0.1,1.4-0.9C443.86,259.83,443.56,260.33,443.36,261.03z M435.76,259.83c-0.2,0.1-0.3,0.3-0.5,0.4
			c0.3,0.3,0.6,0.5,0.9,0.8c0.1-0.1,0.2-0.2,0.3-0.3C436.26,260.43,436.06,260.13,435.76,259.83z M444.56,262.63c0,0.1,0,0.3,0,0.4
			c0.5,0,0.9-0.1,1.4-0.1c0-0.1,0-0.2,0-0.4C445.46,262.63,445.06,262.63,444.56,262.63z M448.56,226.23c0.2,0.1,0.3,0.3,0.5,0.4
			c0.2-0.3,0.4-0.6,0.7-0.9c-0.1-0.1-0.2-0.2-0.4-0.3C449.06,225.73,448.86,226.03,448.56,226.23z M556.86,193.23
			c0.3,0.2,0.5,0.5,0.9,0.8C558.06,193.03,557.76,192.73,556.86,193.23z M565.96,317.13c0.6,0,1.1,0,1.5,0
			C567.06,316.03,566.66,315.83,565.96,317.13z M482.56,335.43c-0.4,0.4-0.6,0.7-0.9,1.1c0.7,0,1.2,0,1.9,0
			C483.16,336.13,482.86,335.83,482.56,335.43z M538.36,336.53c0.1,0,0.2,0,0.3,0c0-0.4,0-0.8,0-1.2c-0.1,0-0.2,0-0.3,0
			C538.36,335.73,538.36,336.13,538.36,336.53z M428.66,315.53c0,0.6,0,1,0,1.6C429.86,316.53,429.76,316.03,428.66,315.53z
			 M446.56,336.43c0.4-0.4,0.7-0.6,0.7-0.6c-0.1-0.2-0.3-0.5-0.5-0.6c-0.1,0-0.5,0.2-0.5,0.4
			C446.16,335.93,446.36,336.13,446.56,336.43z M578.76,273.63c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
			c-0.1,0.2-0.2,0.4-0.3,0.6c0.1,0.1,0.2,0.2,0.3,0.3C578.26,274.13,578.56,273.83,578.76,273.63z M521.06,307.63
			c-0.1,0.2-0.2,0.3-0.3,0.5c0.3,0.1,0.6,0.3,0.9,0.4l0.2-0.3C521.56,307.93,521.36,307.83,521.06,307.63z M551.96,211.93
			c0,0.9,0,1.5,0,2.5c0.4-0.8,0.7-1.3,0.9-1.7C552.56,212.43,552.36,212.23,551.96,211.93z M473.16,216.03c-1.1-0.1-1.4,0.2-1.1,1.2
			C472.46,216.83,472.76,216.53,473.16,216.03z M457.76,300.53c-0.2-1.3-0.6-1.8-1.9-1.5C456.56,299.53,457.06,299.93,457.76,300.53
			z M471.36,331.53c-0.2-0.2-0.4-0.5-0.5-0.5c-0.2,0.2-0.5,0.4-0.7,0.6c0.1,0.1,0.3,0.3,0.4,0.3
			C470.86,331.93,471.06,331.73,471.36,331.53z M583.56,240.23c0.2,0,0.3,0,0.5,0c0-0.5-0.1-0.9-0.1-1.4c-0.1,0-0.3,0-0.4,0
			C583.56,239.33,583.56,239.73,583.56,240.23z M486.96,332.03c0.2-1.1-0.2-1.2-0.9-1.1C486.26,331.33,486.46,331.53,486.96,332.03z
			 M484.16,330.13c-0.2,0.3-0.4,0.5-0.4,0.5c0.4,0.3,0.8,0.6,1.1,1c0.1-0.1,0.2-0.2,0.3-0.3
			C484.86,330.93,484.46,330.53,484.16,330.13z M584.56,243.33c-0.5,0.4-0.8,0.7-1.1,1c0.4,0.3,0.7,0.5,1.1,0.8
			C584.56,244.53,584.56,244.03,584.56,243.33z M485.46,337.53c0.5-1.2,0.2-1.4-0.7-1.2C484.96,336.63,485.16,336.93,485.46,337.53z
			 M564.16,315.93c-0.1,1.1-0.1,1.1,0.9,1C564.86,316.63,564.66,316.33,564.16,315.93z M478.76,316.03c-0.1,1-0.1,1,0.9,0.8
			C479.36,316.53,479.06,316.33,478.76,316.03z M575.56,266.23c-1.1,0-1.1,0-0.8,1C574.96,266.83,575.26,266.53,575.56,266.23z
			 M553.46,313.83c1,0.4,1.3,0.2,1.2-0.8C554.26,313.33,553.96,313.53,553.46,313.83z M427.06,310.63c-0.3,0.4-0.6,0.7-0.9,1.1
			C427.06,311.83,427.46,311.73,427.06,310.63z M572.66,212.03c-1.3-0.2-1.5,0.1-1.2,1.1C571.76,212.73,572.06,212.53,572.66,212.03
			z M481.76,343.83c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3
			C481.46,344.23,481.56,344.13,481.76,343.83z M437.76,344.93c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.2,0.3,0.3,0.4
			c0.1,0,0.3-0.1,0.5-0.2C437.96,345.23,437.96,345.13,437.76,344.93z M479.26,342.03c-0.2,0.2-0.3,0.3-0.5,0.4
			c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3C479.46,342.33,479.36,342.23,479.26,342.03z M531.86,345.13
			c-0.1,0.3-0.2,0.5-0.2,0.6s0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5C532.16,345.43,532.06,345.33,531.86,345.13z M493.36,215.93
			c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3C493.56,216.23,493.46,216.13,493.36,215.93z
			 M472.56,212.63c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3
			C472.26,212.33,472.36,212.43,472.56,212.63z M496.26,340.23c-0.4-0.1-0.6-0.2-0.8-0.2c-0.1,0-0.2,0.3-0.3,0.4
			c0.2,0.1,0.4,0.3,0.6,0.3C495.86,340.73,496.06,340.43,496.26,340.23z M459.36,339.73c0.1-0.3,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4
			c-0.1,0.1-0.3,0.3-0.3,0.4S459.16,339.53,459.36,339.73z M447.46,348.23c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3
			c-0.1,0-0.2,0.2-0.4,0.3C447.16,347.93,447.26,348.03,447.46,348.23z M467.66,204.13c-0.2-0.1-0.3-0.2-0.5-0.3
			c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3C467.36,204.43,467.46,204.33,467.66,204.13z M573.36,199.53
			c-0.1,0.3-0.2,0.5-0.2,0.6s0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5C573.66,199.83,573.56,199.73,573.36,199.53z M490.26,349.83
			c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.3,0.4C489.86,349.63,490.06,349.73,490.26,349.83z
			 M494.06,349.13c0,0.1-0.1,0.2-0.1,0.3c0.3,0.1,0.5,0.2,0.8,0.2c0,0,0.1-0.2,0.2-0.3C494.66,349.33,494.36,349.23,494.06,349.13z
			 M447.36,338.23c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3
			C446.96,338.53,447.16,338.43,447.36,338.23z M584.16,195.33c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.2-0.4,0.3
			c0,0.1,0.1,0.3,0.2,0.5C583.86,195.53,583.96,195.53,584.16,195.33z M499.46,195.83c0.2-0.2,0.4-0.3,0.4-0.5s-0.1-0.3-0.2-0.5
			c-0.1,0.1-0.3,0.2-0.4,0.3C499.26,195.33,499.36,195.53,499.46,195.83z M423.96,335.83c0.2-0.4,0.4-0.8,0.7-1.3
			C423.36,334.43,423.66,335.13,423.96,335.83z M514.76,352.33c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
			c-0.1,0-0.2,0.2-0.3,0.4C514.36,352.13,514.46,352.23,514.76,352.33z M473.86,353.03c-0.1-0.2-0.2-0.4-0.3-0.5
			c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.1,0.1,0.3,0.2,0.5C473.46,353.23,473.66,353.13,473.86,353.03z M587.96,191.03
			c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.3-0.3,0.4s0.2,0.3,0.3,0.4C587.66,191.43,587.76,191.23,587.96,191.03z M452.76,352.93
			c-0.2,0.2-0.3,0.4-0.3,0.5s0.1,0.2,0.2,0.3c0.1-0.1,0.4-0.1,0.4-0.3C452.96,353.33,452.86,353.13,452.76,352.93z M500.56,333.83
			c-0.6-0.3-0.8-0.4-1-0.4c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4C499.76,334.23,499.96,334.13,500.56,333.83z
			 M482.36,236.53c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0.2,0.1,0.3,0.1c0.1-0.2,0.1-0.3,0.2-0.5C482.76,236.63,482.56,236.53,482.36,236.53
			z M494.46,332.73c-0.1,0-0.2,0-0.4,0c-0.1,0.1-0.2,0.3-0.2,0.3c0.1,0.2,0.3,0.3,0.4,0.5
			C494.36,333.33,494.36,333.03,494.46,332.73z M479.46,240.63c-0.3-0.3-0.5-0.4-0.6-0.5c-0.1,0.1-0.3,0.3-0.3,0.4
			c0,0.1,0.2,0.4,0.3,0.4C478.96,240.93,479.16,240.73,479.46,240.63z M458.46,242.13c-0.2-0.1-0.4-0.2-0.5-0.2s-0.2,0.2-0.2,0.3
			c0.1,0.1,0.2,0.2,0.3,0.4C458.26,242.43,458.36,242.33,458.46,242.13z M553.06,187.13c-0.1-0.2-0.2-0.4-0.3-0.5
			c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.2,0.1,0.3,0.2,0.5C552.76,187.33,552.86,187.23,553.06,187.13z M556.26,185.63
			c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4c0.2,0,0.3-0.1,0.5-0.2C556.46,186.03,556.36,185.83,556.26,185.63z
			 M562.16,327.93c0.1-0.2,0.2-0.3,0.3-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0.2-0.4,0.3
			C561.86,327.63,561.96,327.73,562.16,327.93z M481.76,326.03c-0.3,0.3-0.5,0.4-0.6,0.6c0.2,0.2,0.3,0.4,0.5,0.4s0.5-0.2,0.5-0.4
			C482.16,326.53,481.86,326.33,481.76,326.03z M557.46,250.53c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1-0.2,0.1-0.3c-0.2,0-0.4-0.1-0.5-0.1
			C557.56,250.13,557.56,250.33,557.46,250.53z M451.86,252.83c-0.2,0.1-0.4,0.2-0.6,0.3c0.1,0.1,0.2,0.3,0.3,0.4
			c0.1,0,0.3-0.1,0.5-0.2C452.06,253.13,452.06,253.03,451.86,252.83z M458.06,258.23c0.2-0.2,0.3-0.4,0.3-0.5
			c0-0.2-0.2-0.3-0.3-0.5c-0.1,0.1-0.3,0.2-0.3,0.4S457.96,257.93,458.06,258.23z M497.96,318.03c-0.2-0.2-0.3-0.4-0.5-0.4
			s-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4C497.46,318.23,497.66,318.13,497.96,318.03z M460.56,262.53c-0.1,0.3-0.3,0.4-0.3,0.6
			c0,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4C460.86,262.93,460.76,262.73,460.56,262.53z M456.16,265.53
			c-0.3-0.3-0.5-0.4-0.6-0.5c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4C455.76,265.73,455.96,265.63,456.16,265.53z
			 M477.86,265.63c-0.3-0.1-0.5-0.3-0.6-0.3s-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3C477.46,265.93,477.56,265.83,477.86,265.63z
			 M485.26,314.23c0.1-0.2,0.3-0.3,0.3-0.5c0,0-0.2-0.2-0.3-0.2c-0.2,0-0.3,0.2-0.4,0.2C485.06,313.93,485.16,314.03,485.26,314.23z
			 M466.26,312.23c-0.2-0.2-0.3-0.4-0.5-0.4s-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4C465.76,312.43,465.96,312.33,466.26,312.23z
			 M460.96,293.83c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3
			C460.76,294.23,460.86,294.13,460.96,293.83z M425.36,305.53c-0.3-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.5,0.3
			c0.1,0.1,0.2,0.3,0.4,0.3S425.06,305.63,425.36,305.53z M556.66,290.53c-0.1,0.2-0.2,0.3-0.2,0.5c0.4,0.2,0.9,0.3,1.3,0.5
			c0-0.1,0.1-0.2,0.1-0.4C557.46,290.93,557.06,290.73,556.66,290.53z M539.56,291.93c0.1,0,0.2,0,0.4,0c0.1-0.1,0.2-0.3,0.2-0.3
			c-0.1-0.2-0.3-0.3-0.4-0.5C539.66,291.43,539.56,291.63,539.56,291.93z M460.96,292.23c-0.2-0.2-0.3-0.3-0.4-0.5
			c-0.1,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.3,0.2,0.4C460.66,292.43,460.86,292.33,460.96,292.23z M474.86,295.53
			c-0.1,0.2-0.2,0.4-0.3,0.5c0,0,0.2,0.2,0.3,0.2c0.2-0.1,0.3-0.2,0.4-0.3C475.16,295.83,475.06,295.73,474.86,295.53z
			 M563.16,292.03c0-0.2,0-0.3,0-0.5c-0.3,0-0.6,0.1-0.8,0.1v0.3C562.66,292.03,562.96,292.03,563.16,292.03z M563.26,294.53
			c-0.2,0.3-0.3,0.5-0.4,0.7c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0,0.3-0.3,0.4-0.5C563.66,294.93,563.56,294.73,563.26,294.53z"/>
		<path class="st0-bull-white" d="M282.56,225.83c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.5,0.6-0.7,0.9c0.1,0.1,0.2,0.2,0.3,0.3
			c0.5-0.1,1-0.1,1.5-0.2c0.4,1.1-0.3,1.2-1.1,1.2c-1.4,0-2.9,0-4.4,0c-0.2-0.4-0.4-0.9-0.6-1.4c-0.6,0-1.3,0-2,0
			c0.1,0.4,0.1,0.8,0.2,1.2c-1.8,0-3.5,0-5.5,0c0.8-4.5,2.2-8.6,3.2-12.9c0.4,0.1,0.8,0.3,1.1,0.3c0.2,0,0.5-0.2,0.6-0.4
			s-0.1-0.6-0.2-0.7c-1.4-0.3-0.9-1.3-0.7-2.1c0.7-2.9,1.4-5.8,2.2-8.6c0.8-2.7,1.9-5.4,2.3-8.1c0.4-2.9,1.3-5.7,2-8.6
			c0.2-1.1,0.7-2.1,1-3.2c0.9,0,1.8,0,2.8,0c-0.4,1.3,0,2.4,1.5,3.1c-0.2-1-0.3-2-0.5-3.2c1.9,0,3.6,0,5.2,0c1.4,0,2.8,0,4.3,0
			c30,0,60,0,90,0c0.8,0,1.8-0.5,2.4,0.6c0,0.1,0.4,0.1,0.5,0c0.5-1,1.5-0.6,2.2-0.6c9.2,0,18.4,0,27.6,0c2.3,0,4.6,0.3,6.9,0.3
			c1.6,0,3.3-0.2,4.9-0.3c0.7,0,1.4,0,2.2,0c-0.1,0.7-0.1,1.2-0.3,1.6c-1.3,5.2-2.7,10.4-4,15.6c-3.6,14.3-7.1,28.7-10.6,43
			c-0.2,1-0.7,1.7-1.6,2.3c-4.8,2.9-9.7,5.9-14.5,8.9c-0.2,0.1-0.5,0.2-0.9,0.4c-0.3-2.4-0.2-4.6,0.2-6.9c0.2-0.9,0.7-1.9-0.7-2.4
			c-0.1,0-0.3-0.2-0.2-0.3c0.4-1.5-1.3-3-0.2-4.5c0-0.1,0.1-0.1,0-0.2c-0.2-2-0.4-4-0.7-6c-0.1-0.8-0.2-2.3,0-3.2
			c0.4-1.4,0.1-2.7-0.6-3.8c-0.2,0-0.3,0-0.5,0c0,1,0.1,2,0,3c-0.1,0.8-0.3,1.6-0.4,2.3c-0.1,0.4-0.2,1,0,1.2c0.8,0.9,0.5,2,0.5,3
			c0.1,4.5,1,8.9,0.6,13.4c-0.1,0.9-0.3,1.7-0.4,2.6c0,0.3-0.1,0.7,0.1,1c0.8,1.2,0.2,2.1-0.8,2.8c-0.6,0.4-1.1,0.8-1.7,1.2
			c-3.4,2.1-6.8,4.2-10.2,6.3c-0.7,0.4-1.5,0.9-1.4,1.8c2.8,3.4,5.6,6.8,8.5,10.3c-1,0.5-1.8,0.8-2.6,1.2c0.7,0.8,0.7,0.8,2.3-0.3
			c1,0.8,2,1.5,3,2.4c0.4,0.4,0.5,1.3,0.9,1.7c3.1,2.9,5.7,6.3,8.4,9.5c0.6,0.8,0.6,1.4,0.4,2.3c-0.9,3.3-1.9,6.6-2.7,10
			c-1,3.9-1.7,7.8-2.7,11.7c-3.1,12.7-6.3,25.4-9.5,38.2c-0.1,0.3-0.1,0.5-0.2,0.9c-1.5,0.1-2.1-1-2.6-2.1c-0.9,0.9-0.1,1.5,0.2,2.4
			c-0.9,0-1.6,0-2.4,0c-10.7,0-21.4,0-32.1,0c-7.6,0-15.3,0-22.9,0c-3.5,0-7-0.2-10.5-0.2c-5.7,0-11.3,0.2-17,0.2
			c-2.8,0-5.6,0-8.5,0c-0.5,0-0.9-0.4-1.5-0.7c-0.2,0.6-0.8,0.8-1.7,0.8c-3-0.1-5.9-0.1-8.9-0.1c-3.2,0-6.3,0.1-9.5,0.1
			c-2.8,0-5.6,0-8.3,0c-0.2-0.6-0.2-1.2-0.5-1.3c-0.9-0.3,0.1-0.9-0.2-1.1c-0.4-0.3-0.9-0.5-1.7-0.9c0.7-0.8,1.2-1.4,1.7-1.9
			c-1.3-0.8-2.1,0.2-2.9,1.2c-0.1-0.1-0.2-0.1-0.4-0.2c0.1-1.3,0.1-2.5,0.4-3.8c0.2-1.1,0-1.6-1.1-1.7l0.1,0.1
			c0.1-0.9,0.2-1.8-1.1-2c-0.2,0-0.4-0.4-0.6-0.5c0.1-0.1,0.1-0.3,0.2-0.3c0.7-0.2,1.2-0.5,0.9-1.3c-0.1-0.2-0.5-0.5-0.5-0.4
			c-0.4,0.3-0.8,0.6-1.1,1.1c-0.4,0.6-0.3,1.1,0.6,0.9c-0.7,1.3-1,2.5-0.1,3.8h0.1c-0.4,0-0.8,0-1.2,0.1c-1.3,0.3-2.7-0.3-3.4-1.4
			c-2.4-4.1-4.9-8.2-7.3-12.3c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.8-0.1-1.6,0.9-1.7c1.4-0.2,1.7-1,1.4-2.3c-0.5-0.1-1-0.3-1.5-0.4
			c0-0.3,0-0.5,0-0.6c1.5-6.1,3-12.2,4.6-18.3c0.5-2,1.1-4.1,1.5-6.1c0.2-1.1,0.6-2,1.1-3s0.3-2.3,0.5-3.5c0.2-1.1,0.7-2.2,1-3.3
			s0.3-2.4,0.7-3.4c0.8-1.9,0.5-4.1,1.9-5.7c-0.4-1.5,0.3-2.8,0.7-4.2c1.6-6.4,3.1-12.9,4.7-19.3c0.2-0.9,0.6-1.6,1.4-2.2
			c2.8-2,5.5-4.1,8.2-6.2c1.2-1,2.3-2.1,3.5-3.2c0.4-0.4,0.9-0.7,1.5-1.1c0.4,1.7,0.4,3.2,0.1,4.7c-0.5,2.2-0.8,2.5-1,2.7
			c-0.1,1.5-0.2,2.9-0.3,4.2c0,0.4,0,0.8,0,1.1s0,0.6-0.2,0.9c-0.6,0.9-0.7,2.7-0.2,4c-0.6,0.1-1.1,0.3-1.7,0.4
			c0.5,2,2.4,0.9,3.4,1.5c0.1,1.1,0.1,2.2,0.3,3.2s0.6,2,1,3.1c0.6,0.1,1.3,0.3,2.4,0.6c-0.9-1.1-1.5-2-2.2-2.9
			c0.5-0.9,1.4-1.7,0.2-2.7c1.7-0.7,0.1-2.5,1.3-3.4c-1-0.4-1.7-0.7-2.5-1.1c0.7-1.7,2.1,0,3.1-1.1c-0.1-0.3-0.2-0.9-0.4-1.4
			c-0.5-1.1-1-2.2-1.4-3.3c-0.1-0.4-0.2-1,0.1-1.2c1-1.1,0.6-2.1-0.2-3.3c2.3-0.9,1-2.9,1.5-4.5c0.4,0,0.8,0.1,1.3,0.1
			c0.2-0.4,0.4-0.8,0.8-1.4c-0.3-0.2-0.7-0.5-1.2-0.8c0.7-0.8,1.2-1.3,1.7-1.8c-0.2-0.5-0.4-1-0.5-1.3c0.8-0.8,1.5-1.4,2.1-2.1
			c1.3-1.5,3.3-1.9,4.9-2.8c0.1-0.1,0.4,0,0.5,0c1,0.2,1,0.2,1.8-0.3c0.7,0.4,1.2,0.8,0.6,1.8c-0.3,0.6,0.4,0.7,0.9,0.6
			c0.3-1.6,0.6-3.3,1-5.4c-1,1-1.7,1.7-2.4,2.4c-0.3-0.2-0.8-0.4-1.2-0.6c0-0.2,0-0.4,0.1-0.5c0.7-0.5,1.6-0.8,2-1.5
			c0.7-1.3,1.5-1.1,2.5-0.5c-0.6-2.6,1.7-2.8,2.9-4.2c0.2,0.9-0.4,1.8,0.9,1.9c0.1-1.1,0.3-2.3,0.2-3.4c0-0.4-0.7-0.9-1.1-1
			c-0.6-0.1-1.4,0-2,0c-0.8,0-1.6,0-2.5,0c-0.1-0.9-0.1-1.6-0.2-2.5c-1.1,0.1-2.1-1.2-3.3-0.1v-0.1c-1.5,0.4-2.2-0.9-3.3-1.6
			c0.2-0.4,0.4-0.8,0.5-1.1c-0.4-0.5-0.7-1-0.9-1.3c-0.1-1.7,2.5-1.7,1.8-3.1c-1.1-1.6-2.8,0-3.8-1.5c-0.7-1.1-2-1.8-3-2.8
			c-0.2,0.4-0.3,0.5-0.4,0.7c-0.1,0.2-0.2,0.4-0.3,0.7c-1.7-0.7-1.7-0.7-2.1,0.3c1.8,0.4,2.8,1.5,2.8,3.3c0,0.9,0.5,1.6-0.1,2.7
			C279.26,223.93,280.76,225.43,282.56,225.83L282.56,225.83z M301.36,307.23c-0.9,1-0.9,1.1,0,2.3
			C301.26,308.63,301.26,307.93,301.36,307.23c0.2-0.3,1.1-0.4,0.2-1.2C301.46,306.53,301.36,306.93,301.36,307.23z M290.96,242.83
			c0.1,0.3,0.2,0.7,0.3,1.3C291.96,243.13,291.26,243.03,290.96,242.83c-0.2-0.2-0.3-0.6-0.6-0.8c-0.2-0.2-0.5-0.1-0.8-0.2
			c0.2,0.5,0.4,0.8,0.8,1C290.56,242.93,290.76,242.83,290.96,242.83z M286.86,247.93c-0.7,0-1.3-0.1-2.4-0.1
			C286.16,249.03,286.26,249.03,286.86,247.93c0.6-0.6,0.6-0.6,0.1-1.3C286.86,247.13,286.86,247.53,286.86,247.93z M303.56,314.03
			c0.2,0.9,0.2,0.9,1.5,0.5C304.46,314.33,303.96,314.23,303.56,314.03c-0.5-0.7-0.7-1.8-2.1-1.3
			C302.16,313.23,302.26,314.43,303.56,314.03z M260.86,312.83c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4,0-1-0.3-1.2-0.1c-0.7,0.5,0,0.7,0.4,1
			c0,0,0,0.3-0.1,0.3c-1.1,0.4-0.2,1.3-0.9,2.1c1.1-0.4,2-0.3,2-1.5c0-0.3,0.6-0.6,0.9-0.8
			C261.36,313.63,261.06,313.23,260.86,312.83c0.8,0.4,1.5,0.2,2-0.7c-0.6-0.2-1.1-0.5-1.6-0.7
			C261.16,311.93,261.06,312.33,260.86,312.83z M257.96,305.73c0.4-0.9-0.2-1.1-0.8-1.4C256.66,305.43,257.66,305.33,257.96,305.73
			c0.3,0.4,0.8,0.7,0.9,1.2c0.3,1,0.4,2.1,0.6,3.4c0.5-0.3,1-0.5,1-0.7c0.1-0.9,0-1.8-0.3-2.7
			C259.66,305.53,259.06,305.33,257.96,305.73z M310.86,347.03c0.2,0.4,0.5,0.8,0.7,1.2c1.4-0.5,2.5-1.2,2.3-3
			c-0.7,0.4-1.3,0.7-1.8,1c-0.6-0.3-1.1-0.6-1.9-1C310.56,346.03,310.76,346.53,310.86,347.03c-0.6,0.2-1.2,0.4-1.8,0.6
			C310.06,348.23,310.86,348.33,310.86,347.03z M282.76,258.93c-0.3,0.3-0.5,0.6-0.9,1.1c1.1,0.2,0.8,2,2.7,1.7
			C283.86,260.63,283.76,259.53,282.76,258.93c0-0.5,0.4-1.3,0.1-1.6c-0.8-1-1.9-1.7-2.9-2.6
			C279.86,257.03,281.06,258.23,282.76,258.93z M263.56,342.03c0,0.4,0,0.8,0,1.2c0.8,0.9,0.8,0.9,2.4-0.7c-0.4-1.4-0.7-2.9-1.2-4.8
			c-0.6,0.6-1,0.9-1.4,1.2C264.26,340.13,264.86,341.13,263.56,342.03z M287.76,268.53c-0.1,1.1-1.5,2-0.6,3.4
			c0.9,0.2,1.7,0.1,2.2-1.2C288.86,270.03,288.26,269.33,287.76,268.53c-0.1-0.9-0.1-1.8-0.1-2.7c-0.4-0.1-0.8-0.2-1.2-0.3
			c0.1-0.5,0.3-0.9,0.5-1.6c-1.5,0.1-1.9-2.3-3.8-1.1c0.4,2,1.6,3,3.4,3.3c-0.1,0.8-0.2,1.5-0.3,2.4
			C286.96,268.53,287.36,268.53,287.76,268.53z M260.56,319.93c0.2,0.5,0.3,1,0.6,1.4s0.7,0.7,1.2,1.2c-0.7,0.2-1.3,0.4-1.7,0.7
			c-0.6,0.4-1.1,1-1.6,1.5c0.5,0.4,0.9,0.8,1.4,1.2c0.6-0.7,1-1.7,1.8-2.1c1-0.5,1.5-1.5,1.8-2.2c0.5-1.1,1.5-0.6,2.2-1.2
			c1.2-1.2,2.5-2.5,3.7-3.9c-1.8,0.5-3.6,1-5.4,1.5c0.6,1.2-0.3,1.3-1.2,1.4C262.46,319.73,261.46,319.03,260.56,319.93
			c-0.7,0.6-0.7,0.6-0.1,1.3C260.56,320.73,260.56,320.33,260.56,319.93z M309.56,281.43c-0.2-0.2-0.7-0.5-0.7-0.7
			c0.1-1.5-0.4-2.9,0.5-4.4c0.6-0.9,0.3-2.3,0.8-3.3c0.4-0.8,0.5-1.5,0.5-2.3c0.1-1.8,0.3-3.6,0.4-5.4c0.1-2.2,0.6-4.5,0.2-6.7
			c-0.2-1.5-0.1-2.5,0.9-3.4c-0.9-1.3-0.5-2.5-0.1-3.7c-1.4-1.5-1.4-1.5-1.4-3.5c0-1.2,0.3-2.5-0.2-3.5c-0.5-1.1-0.5-2.1-0.5-3.2
			c0-2.4,0-4.8,0-7.3c0-1-0.2-2,0.6-2.9c0.1-0.2-0.1-0.7-0.2-1.1c-0.3,0.1-0.5,0.2-0.5,0.2c-0.1,1.6-1.9,2.9-0.6,4.6
			c0.1,0.1,0.1,0.4,0,0.6c-1.2,2.8-0.7,5.7-0.4,8.5c0.2,2.3,0.5,4.6,0.6,6.9c0,1.8-0.3,3.6,0.5,5.3c0.2,0.4-0.1,1-0.2,1.6
			c0,0.3-0.3,0.6-0.2,0.8c1.1,2-0.5,4-0.2,6c0.1,0.6-0.3,1.4-0.2,2.3c0.1,0.9-0.3,1.8-0.4,2.7c-0.1,0.5,0.1,1.1-0.1,1.6
			c-0.7,1.8-0.3,3.9-1.6,5.6c-0.2,0.3-0.1,0.8-0.1,1.2c0.1,1.4-0.9,2.5-1.6,3.4c-0.5,0.6-1.8,1.7-3,0.4c-0.2-0.2-0.7-0.3-1-0.1
			c-1,0.5-2,1.1-3,1.7c0,0.1,0.1,0.3,0.1,0.4c2.3,0,4.7,0,7.3,0c0.2,0.9,0.4,2,0.6,2.9c-0.9,0.4-1.5,0.7-2.2,1.1
			c-0.4-0.7-0.8-1.2-1.1-1.8c-0.6,0.3-1.2,0.5-1.7,0.8c0,1.6,0.2,1.8,1.4,2c0.7,0.1,1.5,0.2,2.2,0.3c0.2,0,0.3,0.1,0.5,0.1
			c0.2-0.5,0.3-0.9,0.5-1.6c1,0.7,1.9,1.3,2.7,1.9c1.8-1.5,1.7-2.1-0.4-3.2C307.86,284.33,308.46,282.83,309.56,281.43
			c0.8,0,1.7,0.1,2.5,0.2c0.1,0.6-1.1,1.6,0.6,1.8c0.3-0.7,0.6-1.5,1.1-2.1c0.3-0.4,0.9-0.8,1.4-1c0.9-0.3,1.9-0.5,2.8-0.7
			c-1.3-1.2-3,1.1-4.1-0.8c-1.1,0.7-2,2-3.7,1.7C310.06,280.53,309.76,281.13,309.56,281.43z M350.06,289.53c0.5,0.2,1,0.4,1.4,0.6
			c-0.3,0.6-0.5,1.1-0.7,1.8c-0.7-0.2-1.4-0.3-2.1-0.5c-1.1,0.9-1.1,1-2.5,0.9c-1.8,0-3.6-0.2-5.5-0.2c-2.2,0-4.4,0.2-6.6,0.3
			c0.2-0.7,0.3-1.1,0.4-1.7c-1.5,0-2.1-2.4-4-1.1c0.4,1.2,2.6,0.8,2.3,2.6c-7.9,0-15.8,0-23.6,0c0-0.4,0-0.7,0-1
			c-0.2,0-0.5,0-0.7,0.1c-0.2,0.2-0.4,0.7-0.6,0.8c-1.3,0.3-1.4,1.3-1.7,2.3c-0.4,1.3-0.9,2.6-1.4,3.9c-0.3,0.9-1,2.1-0.7,2.6
			c0.7,1.1,0.2,2,0,2.9c-0.4,1.8-1,3.6-1.4,5.4c-0.3,1.2,0.1,1.6,1.3,1.7c0.5,0,1,0,1.4,0c8.9,0,17.8,0,26.7,0c1,0,2,0.1,3,0.1
			c3,0,6.1-0.1,9.1-0.1c1.8,0,2.1-0.1,2.6-1.8c0.6-2.3,1.2-4.6,1.8-7c0.7-3,1.4-6,2.1-9c0.1-0.2,0.2-0.5,0.3-0.7
			c1.7-2.7,1.8-3,2.1-5.6c0-0.4,0.2-0.8,0.4-1.1c0.4-0.7,0.3-1.4,0-2.3c-0.4,0.2-0.7,0.4-0.7,0.5c0,0.6,0.4,1.2-0.5,1.5
			c-0.1,0-0.1,0.5,0,0.8c0.3,1.6,0.4,1.6-1.3,2.1C350.36,288.33,349.66,288.53,350.06,289.53c-0.8,0-0.8,0-0.6,0.8
			C349.66,290.03,349.86,289.73,350.06,289.53z M341.06,247.03c0.3,0.5,0.5,1.1,1.4,0.2C341.86,247.13,341.46,247.13,341.06,247.03
			c0.6-1.9,2.3-2.5,4.1-2.7c0.9-1.8,3-2.9,4-2.4c1-0.8,1.7-1.7,2.7-2.2c4.8-2.9,9.6-5.7,14.4-8.5c0.5-0.3,1-0.5,1.4-0.9
			c0.6-0.6,0.4-1.3-0.5-1.5c-0.4-0.1-0.8-0.1-1.2-0.1c-1.6,0-3.2,0-4.8,0s-3.2,0-4.8,0s-3.2-0.1-4.8,0c-1.5,0.1-2.9-0.6-4.5-0.1
			c-0.5,0.2-1.2-0.2-1.5-0.3c-0.4-2.6-0.8-4.8-1.2-7.3c-1,1-1.2,2-0.9,3.2c0.2,0.8,0.5,1.6,0.7,2.4c0.3,1.1-0.1,1.8-1.1,2
			c-0.5,0.1-1,0-1.4,0c-5,0-9.9,0-14.9,0c-0.8,0-1.5,0-2.3,0c-2,0-2,0-2.5,2c0,0.1-0.1,0.3-0.1,0.4c-1.6,6.6-3.3,13.2-4.9,19.9
			c-0.6,2.3-1.1,4.7-1.7,7c-0.2,0.7-0.2,1.5,0.6,1.8c0.5,0.2,1.2,0.1,1.7-0.1c1-0.5,1.9-1.1,2.9-1.6c6.1-3.6,12.1-7.1,18.2-10.7
			C339.56,246.93,340.26,246.53,341.06,247.03z M266.96,349.23c0-1.1-1.2-2.3-2.1-2.3c-1.3,0-2.3-0.4-3.2-1.3
			c-0.3-0.3-1-0.3-1.8-0.5c0.8,1.3,0.9,2.2,0.2,3.2c-0.1,0.2,0,0.6,0.1,0.9c0.3,0,0.7,0,0.9-0.1c0.5-0.3,0.8-0.7,1.4-1.1
			c0.8,0.5,1.6,1.1,2.4,1.6c1.2,0.8,2.3,1.9,3.9,1.5c0.1,0,0.2,0.1,0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.4,0,0.7,0.1,1.1
			c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.2,0.3-0.6,0.4-0.9c-0.5-0.4-1.3-0.7-1.4-1.1C268.46,349.03,267.56,349.63,266.96,349.23z
			 M276.26,287.33c1.1,0.3,1.8,0.4,2.6,0.6c0.1-0.4,0-0.9,0.2-1.2c0.7-1.1,0.1-2.3-0.5-2.9c-0.8-0.8-0.9-1.5-0.8-2.4
			c0.2-1.5-0.8-2.8-0.1-4.3c0.3-0.7,0-1.7-0.1-2.7c-0.6,0-1,0.1-1.5,0.1c-1,0.9,1,2.4-0.6,3.1c0.5,1.6,1.6,3.2,0.6,4.9
			c0.3,0.1,0.6,0.2,0.9,0.4c-0.2,0.4-0.3,0.7-0.5,1c0.4,0.5,0.8,1,1.1,1.4C277.16,286.03,276.76,286.53,276.26,287.33z
			 M284.46,268.83c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.1-0.9,0.2-1.3,0.3c-0.9-1.4-1.4-1.4-2.5-0.1c0.4,1.7,2.9,1.8,2.6,4
			C285.46,271.93,283.36,269.93,284.46,268.83z M316.66,343.23c1.8-0.5,3-1.9,4.7-1.7c0.1,0,0.4-0.2,0.4-0.4
			c0.3-1.1,1.3-1.5,2.2-1.9c1.1-0.5,0.8-1.1,0.2-1.9c-1,0.4-1.8,0.8-2.4,1.8c-0.6,1-1.5,1.7-2.7,2.1c-0.7,0.2-1.3,0.6-2,1
			C316.86,342.33,316.86,342.73,316.66,343.23z M285.86,255.13c0.7-0.7,1.4-1.3,2.1-2c-0.1-1.6-0.8-2.2-2.6-2.1
			c0.4,0.6,0.8,1.2,1.1,1.7C284.76,253.83,284.76,254.03,285.86,255.13z M281.86,263.93c-0.9-0.4-1.6-0.3-2,0.5
			c-0.5,0.9-0.1,1.6,1.5,2.9c0.6-0.4,1.2-0.8,2.1-1.4c-0.9-0.2-1.4-0.4-1.9-0.5C281.66,264.93,281.76,264.43,281.86,263.93z
			 M292.66,282.93c-1.1,0.8-2.4,1.1-2,2.8c1.7-0.6,3.2-1.1,4.7-1.6C294.46,283.53,293.16,284.63,292.66,282.93z M362.96,290.03
			c-1.6-0.3-3.2-0.5-4.7-0.8c-0.4-0.1-0.9-0.1-1.2,0s-0.4,0.5-0.7,0.8c0.7-0.1,1.3-0.3,1.7-0.1
			C360.36,291.23,360.36,291.23,362.96,290.03z M259.16,343.03c0.2,0.1,0.5,0.1,0.7,0.2c1.3-1.7,0.5-3.7,1.1-5.6
			c-1.1,0.5-1.6,1.2-1.2,2.4c0.2,0.7,0.2,1.3-0.4,1.9C259.16,342.13,259.26,342.63,259.16,343.03z M312.46,336.03
			c2,0.8,1.9-1.9,3.4-1.8c0.1,0,0.2-0.5,0.2-0.7c-1.1-0.5-2.2-0.4-2.4,0.7c-0.2,1.1-1.1,0.1-1.3,0.7
			C312.46,335.23,312.46,335.63,312.46,336.03z M282.06,251.73c2.2-1.5,2.3-2,0.8-3.3c-0.4,0.5-0.8,1-1.3,1.5
			C280.96,250.73,281.56,251.23,282.06,251.73z M345.46,215.53c-1.8,0.7-1.9,1.2-0.9,2.7c-0.7,0.6-0.7,1.2-0.2,2
			C346.26,218.93,344.66,217.23,345.46,215.53z M267.26,327.73c-1.5-0.1-2.4,0.5-2.8,1.6c-0.3,0.9,0.2,1.2,1.1,1.5
			C264.86,328.93,267.36,329.13,267.26,327.73z M275.46,319.63c0.2-0.9,0.2-0.9,1.4-1.8c0.2-0.1,0.3-0.6,0.3-0.7
			c-0.2-0.2-0.6-0.5-0.8-0.4c-1.2,0.4-2,1.2-2.3,2.6C274.46,319.43,274.86,319.53,275.46,319.63z M285.96,258.93
			c0.3-0.4,0.5-0.6,0.6-0.7c-0.4-0.6-0.8-1-1-1.5c-0.3-0.7-0.6-1.4-1.6-1.2C283.76,257.13,284.96,257.83,285.96,258.93z
			 M253.56,345.63c0.4-0.2,0.9-0.4,1.7-0.7c-1.4-0.4-1.5-2.2-3.3-1.8C252.46,344.03,252.96,344.83,253.56,345.63z M292.46,289.93
			c0.4,0.7,0.7,1.3,1.1,1.9c0.3,0.5,0.6,0.9,0.9,1.4c0.1-1.2,0.1-2.2,0.2-3.3C293.96,289.93,293.26,289.93,292.46,289.93z
			 M297.56,311.53c0.3-1.6,0.6-2.9,1-4.6C296.56,308.83,296.26,309.93,297.56,311.53z M263.36,336.83c1.8-0.7,1.8-0.7,0.3-3.9
			C263.86,334.63,262.56,335.63,263.36,336.83z M269.06,354.63c-0.9-1.3-2.3-1.5-3.6-2.2C266.16,354.13,267.26,354.73,269.06,354.63
			z M282.66,255.03c0.6-1.1,1.9-1.2,1.5-2.4c-0.8,0-1.5,0-2.5,0C282.06,253.43,282.26,254.13,282.66,255.03z M259.46,301.73
			c-0.2,0.9-0.4,1.7-0.6,2.8c0.9-0.2,1.6-0.3,2.4-0.5C260.66,303.03,260.96,301.93,259.46,301.73z M259.26,319.73
			c-0.2-0.2-0.3-0.4-0.5-0.6c-0.9,0.6-1.8,1.1-2.6,1.7c-0.2,0.2-0.2,0.7-0.3,1c0.4,0,0.8,0.1,1-0.1
			C257.76,321.03,258.56,320.33,259.26,319.73z M340.76,216.93c-1.4-0.6-1.4,1.3-2.6,1.2c0.6,0.8,1.1,1.4,1.5,1.9
			C340.06,218.93,340.36,218.03,340.76,216.93z M276.36,297.43c0-0.1,0.1-0.3,0.1-0.4c-0.5-0.5-1-1-1.6-1.5
			c-0.6,0.4-1.2,0.9-0.6,1.9C274.96,297.43,275.66,297.43,276.36,297.43z M317.26,332.93c0,0.4,0.2,1,0.1,1.2c-0.8,0.9,0,1,0.5,1.4
			c0.3-1.1,1.5-2,0.6-3.3C317.96,332.53,317.66,332.73,317.26,332.93z M291.26,345.53c-0.3,1.3-1.3,2.4-0.8,3.8
			C291.26,348.13,292.36,347.03,291.26,345.53z M349.06,192.03c0.9,0.5,1.5,1,2.2,1.4c0.5-0.8,1-1.5,1.6-2.3
			C351.46,191.43,350.46,191.73,349.06,192.03z M291.26,265.53c-0.8,0.3-1.4,0.5-2,0.8c0.2,0.8-0.4,1.7,0.7,2.3
			C290.46,267.63,290.76,266.73,291.26,265.53z M318.86,326.53c1,0,1.6,0,2.3,0c0-0.8-0.1-1.4-0.1-2.1c-0.1,0-0.3,0-0.4-0.1
			C320.16,325.03,319.56,325.63,318.86,326.53z M297.16,234.73c-0.1,0.8-0.1,1.4-0.2,2.2c0.4,0,0.8,0,1.2,0c0.1-0.9,0.2-1.7,0.3-2.7
			C297.86,234.43,297.46,234.63,297.16,234.73z M290.66,202.03c2.3,0.8,2.4,0.8,3.3-0.9C292.66,201.43,291.66,201.73,290.66,202.03z
			 M283.86,316.73c1.2-0.4,2.1-0.7,3.1-1.1c-0.4-0.5-0.6-0.8-0.9-1.2c-0.7,0.6-1.3,1-1.8,1.5
			C284.06,315.93,284.06,316.23,283.86,316.73z M349.06,215.23c0.6,0.3,1.1,0.6,1.8,0.9c0.1-0.2,0.2-0.3,0.3-0.5
			c0.1-0.1,0.1-0.2,0.2-0.3C350.26,214.03,350.06,213.93,349.06,215.23z M284.76,192.93c-1.7-1.3-1.7-1.3-2.6,0
			C283.06,192.93,283.86,192.93,284.76,192.93z M313.76,287.13c0.2,0.5,0.5,0.9,0.7,1.5c0.8-0.6,1.6-0.9,1.6-2
			C315.26,286.73,314.56,286.93,313.76,287.13z M338.36,281.63c1,0.6,2.1,0.7,3.1,0C340.46,281.63,339.46,281.63,338.36,281.63z
			 M279.76,205.93c1.2-0.5,2.2-0.9,3.2-1.3C280.26,204.13,280.26,204.13,279.76,205.93z M284.26,284.23c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.6,0.2-1.2,0.4-1.7,0.7c-0.2,0.1-0.2,0.7-0.2,1c0.3,0,0.7,0.2,0.9,0C283.46,285.13,283.86,284.63,284.26,284.23z
			 M329.46,331.93c1.1,0.2,2,0.5,2.3-0.9C330.86,330.93,329.96,330.43,329.46,331.93z M327.96,322.83c0.9,1,1.8,0.9,2.7,0.1
			C329.76,321.33,328.86,323.13,327.96,322.83z M284.16,317.73c-0.6-0.3-0.9-0.6-1-0.5c-0.5,0.4-0.9,0.9-1.3,1.4
			c0.2,0.2,0.5,0.5,0.6,0.5C282.96,318.73,283.46,318.33,284.16,317.73z M283.06,197.43c-0.6-0.2-1.1-0.4-1.6-0.6c0,0.9,0,1.6,0,2.4
			c0.1,0,0.2,0.1,0.4,0.1C282.26,198.73,282.66,198.13,283.06,197.43z M282.86,274.43c0.3,1.3,1.3,1.2,2.1,1.3
			C284.66,274.63,283.76,274.73,282.86,274.43z M273.66,306.93c1-0.2,1.9-0.4,2.7-1.3C274.96,305.23,274.26,305.93,273.66,306.93z
			 M298.66,255.23c0.1,0,0.3,0,0.4,0c0-0.6,0.1-1.2,0-1.8c0-0.2-0.5-0.3-0.8-0.4c-0.1,0.2-0.4,0.5-0.3,0.7
			C298.16,254.13,298.46,254.63,298.66,255.23z M394.26,187.63c0.5-0.8,0.7-1.2,1-1.6c-0.3-0.4-0.5-0.7-0.8-1
			c-0.3,0.2-0.6,0.3-0.7,0.3C393.96,186.03,394.06,186.63,394.26,187.63z M325.06,321.63c-0.8,0.6-1.2,0.9-1.6,1.2
			c0.8,1.3,1.8,1,2.8,0.6c-0.5-0.1-1-0.2-1.5-0.3C324.86,322.73,324.96,322.43,325.06,321.63z M259.36,339.63
			c-0.7-0.8-1-1.1-1.4-1.6c-0.3,0.7-0.5,1.2-0.8,1.9C257.86,339.83,258.36,339.73,259.36,339.63z M272.06,265.03
			c-1-0.1-1.8-0.3-2.1,0.9c0.4,0.2,0.8,0.4,1.3,0.6C271.56,266.03,271.76,265.63,272.06,265.03z M353.46,294.43
			c-0.2,1.1-1,1.9,0.1,2.6C354.76,296.33,353.96,295.53,353.46,294.43z M275.06,273.73c-1.9-0.6-1.9-0.6-2.1,0.5
			C273.66,274.03,274.36,273.93,275.06,273.73z M300.56,302.03c0.7,0.1,1.3,0.2,2,0.3c-0.3-0.8-0.5-1.4-0.7-2.2
			C301.36,300.73,301.06,301.23,300.56,302.03z M297.46,267.73c-0.5,0.2-0.9,0.4-1.4,0.6c0.1,0.2,0.1,0.6,0.3,0.6
			C297.06,269.23,297.36,268.73,297.46,267.73z M299.86,236.33c0.9-1,0.9-1,0-2C298.96,234.93,299.86,235.53,299.86,236.33z
			 M273.26,320.73c-0.1-0.2-0.1-0.5-0.2-0.7c-0.5,0.2-1,0.3-1.5,0.5c0,0,0.1,0.4,0.2,0.7C272.26,321.03,272.76,320.93,273.26,320.73
			z M261.56,295.43c-0.4,1.4-0.4,1.4,0.6,1.4C262.06,296.43,261.76,295.93,261.56,295.43z M267.16,290.83c0.1-1.1-0.4-1.6-1.5-1.4
			C265.46,290.83,266.66,290.43,267.16,290.83z M355.06,251.53c-0.2,0-0.3,0-0.5,0c-0.1,0.6-0.4,1.2,0.5,2
			C355.06,252.73,355.06,252.13,355.06,251.53z M312.66,330.83c-1,0.2-1.5,0.6-1.3,1.7C311.66,331.83,313.56,332.53,312.66,330.83z
			 M314.36,324.93c0.3,0.7,0.5,1.2,0.9,1.9c0.4-0.6,0.7-1.1,1.1-1.7C315.56,325.03,315.16,324.93,314.36,324.93z M288.26,261.63
			c-1.1-0.2-1.5,0.1-1.3,0.8c0.1,0.3,0.5,0.4,0.8,0.6C287.86,262.63,287.96,262.23,288.26,261.63z M426.26,193.73
			c0.4-1.2,0.6-2.1,0.9-2.9c-0.1,0-0.3-0.1-0.4-0.1C426.46,191.63,425.36,192.13,426.26,193.73z M346.26,323.53
			c-0.5-0.8-0.9-1.4-1.2-1.9c-0.1,0-0.2,0.1-0.3,0.1c0,0.6,0.1,1.3,0.1,2C345.36,323.73,345.66,323.63,346.26,323.53z
			 M295.16,278.13c0,1.2,0,1.2,1.3,1.3C295.96,279.03,295.56,278.53,295.16,278.13z M315.86,274.73c-0.7,0-1,0.5-0.8,1.1
			c0,0.2,0.6,0.3,0.8,0.2C316.36,275.63,316.26,275.13,315.86,274.73z M378.96,273.53c-1.7-0.4-1.7-0.4-2.1,1
			C377.56,274.23,378.16,273.93,378.96,273.53z M350.86,310.93c0.8,0.3,1.3,0.5,1.9,0.7C352.26,310.23,352.26,310.23,350.86,310.93z
			 M275.26,269.23c-1.1,0.2-1.1,0.2-1,1.6C274.56,270.33,274.86,269.83,275.26,269.23z M397.16,225.73c0.1,0.9,0.1,1.5,0.1,2.1
			C397.56,227.13,399.06,226.63,397.16,225.73z M328.06,349.63c-0.3,0.2-0.6,0.3-1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0,1,0,1.4,0
			c0.1-0.1,0.2-0.3,0.3-0.4C328.66,349.93,328.36,349.73,328.06,349.63z M328.56,318.63c0,0.2,0,0.3,0,0.5c0.5,0,0.9-0.1,1.4-0.1
			c0-0.2,0-0.3,0-0.5C329.56,318.53,329.06,318.53,328.56,318.63z M328.76,344.13c0.8,0.7,1.3,1.2,2,1.8
			C330.76,344.53,330.26,344.03,328.76,344.13z M261.26,291.93c0,0.2,0,0.3,0,0.5c0.6,0.2,1.3,0.5,1.9-0.5
			C262.36,291.93,261.76,291.93,261.26,291.93z M344.66,192.63c-0.2,0-0.3,0-0.5,0c0,0.5,0.1,0.9,0.1,1.4c0.1,0,0.2,0,0.4,0
			C344.66,193.53,344.66,193.13,344.66,192.63z M338.46,197.53c0-0.8,1.3-1.1,0.7-2.2C338.16,195.83,337.96,196.53,338.46,197.53z
			 M341.56,321.93c0-0.1,0-0.3,0.1-0.4c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.2-0.7,0.3c0,0.1,0.1,0.2,0.1,0.3
			C340.66,321.93,341.16,321.93,341.56,321.93z M288.96,350.63c0.1,0.2,0.2,0.3,0.3,0.5c0.4-0.2,0.7-0.4,1.1-0.6
			c0,0-0.2-0.5-0.3-0.5C289.76,350.23,289.36,350.43,288.96,350.63z M274.56,214.03c0.2,0.1,0.3,0.2,0.5,0.2
			c0.3-0.5,0.5-0.9,0.8-1.4c-0.2-0.1-0.4-0.2-0.7-0.3C274.96,213.03,274.76,213.53,274.56,214.03z M274.16,329.13
			c-0.1,0.6-0.1,1.1-0.2,1.9C275.06,330.33,275.46,329.93,274.16,329.13z M307.56,243.03c-1.2-0.2-1.2,0.4-1.1,1.1
			c0,0.1,0.2,0.1,0.4,0.2C307.06,244.03,307.26,243.63,307.56,243.03z M288.36,189.63c0,0.1,0,0.2,0,0.4c0.4,0,0.8,0.1,1.2,0.1
			c0.1,0,0.1-0.3,0.3-0.6C289.26,189.53,288.76,189.53,288.36,189.63z M290.86,271.13c-0.6,0.6-1,0.9-1.5,1.4
			C290.36,272.73,291.06,272.83,290.86,271.13z M389.56,282.93c0,0.2,0,0.3,0,0.5c0.5,0,1-0.1,1.4-0.1c0-0.1,0-0.3,0-0.4
			C390.56,282.93,390.06,282.93,389.56,282.93z M406.26,218.83c-0.1-0.1-0.1-0.2-0.2-0.4c-0.3,0.1-0.6,0.1-0.8,0.3s-0.3,0.5-0.4,0.7
			c0.1,0.1,0.2,0.2,0.3,0.3C405.46,219.43,405.86,219.13,406.26,218.83z M341.16,355.03c0.3-0.4,0.7-0.7,0.6-0.9
			c0-0.2-0.4-0.4-0.6-0.5c-0.1,0-0.5,0.3-0.5,0.4C340.76,354.33,340.96,354.53,341.16,355.03z M273.96,275.53c-1.4,0.2-1.2,0.9-1,2
			C273.36,276.73,273.56,276.23,273.96,275.53z M261.26,309.83c0,0.2,0,0.4,0,0.5c0.6,0,1.2-0.1,2.2-0.1
			C262.46,309.43,261.86,309.53,261.26,309.83z M327.06,341.33c0.3-0.4,0.7-0.7,0.7-0.9s-0.4-0.4-0.6-0.5c-0.1,0-0.5,0.3-0.5,0.4
			C326.66,340.63,326.86,340.83,327.06,341.33z M389.46,188.63c1-0.1,1-0.1,0.9-1.7C390.06,187.63,389.76,188.13,389.46,188.63z
			 M252.46,304.13c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.4,0.6,1.3,1.8,0.6C253.36,304.73,252.96,304.43,252.46,304.13z M279.76,333.63
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5,0.4-1,0.7-0.5,1.7C279.16,334.53,279.46,334.13,279.76,333.63z M348.76,247.63
			c0.1-0.1,0.3-0.2,0.4-0.3c-0.3-0.4-0.6-0.9-1-1.5C347.56,247.03,348.46,247.13,348.76,247.63z M337.36,283.73
			c0.4,0.7,0.7,1.2,1.2,2c0-0.9,0-1.5,0-2.3C338.16,283.53,337.86,283.63,337.36,283.73z M291.56,342.03c-0.8,0.6-1.4,0.9-0.9,1.8
			c0.3-0.3,0.6-0.5,0.9-0.8C291.66,342.93,291.56,342.63,291.56,342.03z M265.56,313.83c0.1-0.2,0.2-0.3,0.4-0.5
			c-0.4-0.4-0.7-1-1.7-0.5C264.76,313.23,265.16,313.53,265.56,313.83z M318.56,223.13c-0.1-0.2-0.3-0.4-0.4-0.6
			c-0.4,0.3-0.8,0.7-1.2,1c0.1,0.1,0.2,0.3,0.3,0.4C317.56,223.63,318.06,223.43,318.56,223.13z M277.56,215.23
			c0.4-0.4,0.7-0.6,0.7-0.8s-0.3-0.5-0.6-0.6c-0.1,0-0.5,0.2-0.5,0.4C277.26,214.53,277.46,214.73,277.56,215.23z M269.86,330.13
			c-0.3-0.2-0.5-0.3-0.7-0.5c-0.2,0.3-0.3,0.5-0.5,0.8c0.2,0.1,0.4,0.3,0.6,0.3S269.56,330.43,269.86,330.13z M318.86,330.13
			c-0.5-0.2-0.8-0.5-1-0.4s-0.4,0.4-0.4,0.7c0,0.1,0.4,0.4,0.5,0.4C318.26,330.73,318.46,330.53,318.86,330.13z M268.16,298.03
			c0.1,1.2,0.1,1.2,1,1.7C269.46,298.83,268.66,298.73,268.16,298.03z M271.16,298.33c0,0.1,0.1,0.3,0.1,0.4
			c0.5-0.2,1.3,0.5,1.4-0.7c0-0.1-0.1-0.2-0.2-0.4C272.06,297.93,271.56,298.13,271.16,298.33z M353.66,317.73
			c0.4,0.3,0.6,0.6,0.8,0.6s0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7C354.46,317.23,354.16,317.53,353.66,317.73z M395.46,192.83
			c-0.7-0.4-1.1-0.6-1.7-0.9C393.76,193.23,394.46,193.03,395.46,192.83z M351.26,337.13c-0.1,0.1-0.2,0.2-0.3,0.3
			c0.3,0.4,0.6,0.7,1,1.1C352.56,337.43,351.76,337.33,351.26,337.13z M330.56,349.93c0,0.2-0.1,0.4-0.1,0.5c0.3,0.1,0.6,0.2,1,0.2
			c0-0.1,0.1-0.3,0.1-0.4C331.16,350.23,330.86,350.13,330.56,349.93z M267.36,315.43c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.3,0.3-1.2,0.2-0.6,1.5C266.76,316.13,267.06,315.73,267.36,315.43z M351.26,313.23c0,0.2,0,0.3,0,0.5c0.5,0,0.9-0.1,1.4-0.1
			c0-0.1,0-0.2,0-0.4C352.16,313.23,351.66,313.23,351.26,313.23z M324.66,349.43c-0.8,0-1.2,0.1-2,0.1c0.5,0.5,0.8,0.7,1.1,1
			C324.06,350.13,324.26,349.93,324.66,349.43z M374.56,222.33c-0.2,0-0.3,0-0.5,0c0,0.4,0.1,0.9,0.1,1.3c0.1,0,0.3,0,0.4,0
			C374.56,223.23,374.56,222.73,374.56,222.33z M291.46,268.43c-0.4,0.4-0.6,0.7-1.1,1.2C291.46,269.73,291.76,269.53,291.46,268.43
			z M406.46,220.43c0,0.2-0.1,0.5-0.1,0.7c0.5,0,0.9,0.1,1.4,0.1c0-0.2,0.1-0.3,0.1-0.5C407.36,220.73,406.96,220.53,406.46,220.43z
			 M311.46,313.73c0.2-0.9,0.4-1.6,0.6-2.4C311.06,311.73,311.06,311.73,311.46,313.73z M341.86,327.13c0.9-0.8,0.9-0.8,0.2-1.8
			C341.96,326.03,341.96,326.53,341.86,327.13z M419.36,220.73c0.1,0,0.2,0,0.3,0c0-0.5,0-0.9,0-1.4c-0.1,0-0.2,0-0.3,0
			C419.36,219.83,419.36,220.33,419.36,220.73z M291.36,276.73c0-0.2,0-0.3,0-0.5c-0.4,0-0.8,0.1-1.2,0.1c0,0.1,0,0.2,0,0.4
			C290.66,276.73,291.06,276.73,291.36,276.73z M258.16,284.63c-0.3-0.2-0.5-0.5-0.9-0.8C256.86,284.83,257.16,285.13,258.16,284.63
			z M353.76,303.03c-0.8,0-1.2,0.3-1.3,1c0,0.2,0.3,0.3,0.5,0.6C353.26,304.03,353.46,303.53,353.76,303.03z M260.36,286.93
			c0-1.1,0-1.1-1-0.8C259.66,286.33,259.96,286.53,260.36,286.93z M355.86,243.73c0.2,0,0.3,0,0.5-0.1c-0.1-0.4-0.1-0.7-0.2-1.1
			c-0.1,0-0.2,0-0.3,0C355.86,242.93,355.86,243.33,355.86,243.73z M266.26,333.53c0.3,0.2,0.6,0.5,1.1,1c-0.1-0.9-0.1-1.5-0.1-2.4
			C266.76,332.83,266.56,333.13,266.26,333.53z M294.36,240.43c0.4-0.4,0.6-0.7,1.1-1.1C294.26,239.13,294.16,239.53,294.36,240.43z
			 M297.66,243.83c0.1-1.3,0.1-1.3-0.8-1.1C297.06,242.93,297.26,243.23,297.66,243.83z M386.66,188.83c-0.4,0.4-0.6,0.7-1,1.1
			C386.76,190.23,386.86,189.73,386.66,188.83z M279.66,324.93c0.2,0.1,0.4,0.1,0.6,0.2c0.1-0.4,0.2-0.8,0.2-1.2l-0.3-0.1
			C279.96,324.23,279.76,324.63,279.66,324.93z M343.96,252.53c-1,0-1.4,0.2-0.7,1C343.46,253.23,343.66,252.93,343.96,252.53z
			 M367.16,322.73c0.2-1.1,0-1.5-1-1.1C366.46,321.93,366.66,322.23,367.16,322.73z M400.26,223.73c1.1,0.1,1.5,0,1.1-1
			C401.06,223.03,400.76,223.23,400.26,223.73z M279.46,269.23c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.3,0.1,0.6,0.2,0.9c0,0,0.4,0,0.5-0.1
			C279.76,269.83,279.56,269.53,279.46,269.23z M291.06,219.93c0.4,0.3,0.7,0.6,1.1,1C292.26,220.03,292.16,219.53,291.06,219.93z
			 M259.56,310.33c-0.2,0.2-0.3,0.3-0.5,0.5c0.1,0.7,0.4,1.1,1.5,0.7C260.16,311.03,259.86,310.73,259.56,310.33z M353.96,306.53
			c-0.4-0.1-0.6-0.2-0.9-0.3l-0.2,0.3c0.2,0.2,0.3,0.3,0.5,0.4C353.46,307.03,353.66,306.83,353.96,306.53z M295.86,284.53
			c0.8-0.4,1.1-0.6,1.6-0.9C296.86,283.53,296.26,283.13,295.86,284.53z M262.86,302.13c0.1-0.1,0.2-0.2,0.3-0.3
			c-0.3-0.3-0.6-0.7-1-1c-0.1,0.5-0.1,0.8,0.1,1C262.36,301.93,262.66,302.03,262.86,302.13z M259.46,300.03c0.1,1.1,0.1,1.1,1,0.8
			C260.16,300.63,259.86,300.33,259.46,300.03z M280.46,292.83c-0.2,0-0.4,0-0.6,0.1c0.1,0.5,0.3,1,0.4,1.5c0.1,0,0.2,0,0.4-0.1
			C280.56,293.83,280.46,293.33,280.46,292.83z M416.96,213.13c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.2,0.3,0.4
			c0.1-0.1,0.3-0.3,0.3-0.4C417.36,213.53,417.16,213.43,416.96,213.13z M344.46,213.83c-0.2,0.3-0.3,0.4-0.3,0.6
			c0,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4C344.76,214.23,344.56,214.03,344.46,213.83z M289.76,216.93
			c0.1-0.4,0.2-0.7,0.2-0.9c0-0.1-0.3-0.1-0.4-0.2c-0.1,0.2-0.2,0.3-0.2,0.5C289.36,216.43,289.56,216.53,289.76,216.93z
			 M286.46,341.63c-0.4-0.2-0.7-0.4-0.9-0.4s-0.4,0.3-0.5,0.4c0.2,0.1,0.3,0.4,0.5,0.4C285.86,342.03,286.06,341.83,286.46,341.63z
			 M420.36,219.03c0.1,0.2,0.2,0.4,0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.3c0-0.2-0.1-0.3-0.2-0.5
			C420.66,218.83,420.56,218.83,420.36,219.03z M418.66,211.43c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1,0.1-0.3,0.3-0.3,0.4
			c0,0.1,0.2,0.3,0.3,0.4C418.46,211.73,418.56,211.63,418.66,211.43z M389.56,219.83c-0.1,0.3-0.2,0.5-0.2,0.6s0.2,0.2,0.4,0.3
			c0.1-0.2,0.2-0.3,0.2-0.5C389.96,220.13,389.76,220.03,389.56,219.83z M305.36,348.03c0.2-0.2,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4
			c-0.1,0.1-0.3,0.2-0.3,0.4C305.16,347.63,305.26,347.83,305.36,348.03z M289.76,202.93c0-0.1,0-0.2,0-0.2c-0.6,0-1.3,0-1.9,0
			c0,0.1,0,0.2,0,0.2C288.46,202.93,289.16,202.93,289.76,202.93z M271.56,349.23c-0.3-0.3-0.4-0.4-0.6-0.5c-0.2,0-0.4,0.1-0.5,0.1
			c0.1,0.1,0.1,0.4,0.2,0.4C270.86,349.33,271.16,349.33,271.56,349.23z M283.06,349.03c-0.3-0.2-0.4-0.3-0.6-0.3
			c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3C282.66,349.33,282.86,349.23,283.06,349.03z M287.46,349.13
			c-0.2-0.2-0.4-0.3-0.5-0.3s-0.3,0.2-0.4,0.3c0.1,0.1,0.2,0.3,0.4,0.3C286.96,349.33,287.16,349.23,287.46,349.13z M402.16,199.73
			c-0.3-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.5,0.3c0.1,0.1,0.2,0.3,0.4,0.3C401.66,200.03,401.86,199.93,402.16,199.73z
			 M412.66,197.63c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.1,0,0.3-0.2,0.4-0.3
			C412.86,197.93,412.76,197.83,412.66,197.63z M416.46,196.43c0-0.1,0-0.3,0.1-0.4c-0.5-0.1-0.9-0.2-1.4-0.3c0,0.2-0.1,0.3-0.1,0.5
			C415.46,196.33,415.96,196.43,416.46,196.43z M305.86,225.83c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
			c-0.1,0-0.2,0.2-0.3,0.4C305.56,225.63,305.66,225.73,305.86,225.83z M312.46,225.63c-0.2-0.1-0.3-0.3-0.5-0.3l-0.2,0.2
			c0,0.2,0.2,0.3,0.2,0.4C312.16,225.93,312.26,225.83,312.46,225.63z M397.76,195.63c-0.2-0.2-0.4-0.3-0.5-0.3s-0.3,0.2-0.4,0.3
			c0.1,0.1,0.2,0.3,0.4,0.3C397.36,195.93,397.46,195.73,397.76,195.63z M344.86,196.03c0.1-0.2,0.2-0.3,0.3-0.4
			c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3C344.46,195.73,344.66,195.83,344.86,196.03z M319.86,225.83
			c0.1-0.2,0.3-0.3,0.4-0.4c-0.2-0.1-0.3-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3C319.56,225.53,319.66,225.73,319.86,225.83z
			 M374.76,225.63c-0.2-0.1-0.4-0.2-0.5-0.3c0,0-0.2,0.2-0.2,0.3s0.2,0.3,0.3,0.4C374.36,225.83,374.56,225.73,374.76,225.63z
			 M419.16,225.43c-0.3-0.1-0.5-0.3-0.6-0.3c-0.2,0-0.3,0.2-0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3
			C418.76,225.73,418.86,225.63,419.16,225.43z M405.56,226.93c0.3-0.3,0.5-0.4,0.7-0.6c-0.1-0.2-0.3-0.5-0.5-0.5s-0.4,0.2-0.6,0.3
			C405.26,226.43,405.36,226.63,405.56,226.93z M292.36,352.33c0.1-0.2,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.2-0.5-0.2
			c-0.1,0-0.2,0.2-0.3,0.4C292.06,352.13,292.16,352.23,292.36,352.33z M364.36,192.23c-0.2-0.1-0.3-0.2-0.5-0.3
			c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3C364.06,192.63,364.16,192.43,364.36,192.23z M314.26,227.43
			c-0.6-0.2-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4S313.66,227.63,314.26,227.43z M405.26,188.93
			c0.1-0.2,0.2-0.4,0.3-0.5c0,0-0.2-0.2-0.3-0.2c-0.2,0-0.3,0.2-0.4,0.3C404.96,188.63,405.06,188.73,405.26,188.93z M416.96,229.83
			c0.2-0.2,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.2-0.3,0.4C416.76,229.43,416.86,229.53,416.96,229.83z M300.76,229.73
			c-0.2-0.2-0.3-0.3-0.5-0.4c-0.1,0-0.3,0.1-0.3,0.1c0,0.2,0.1,0.4,0.1,0.4C300.26,229.93,300.46,229.83,300.76,229.73z
			 M336.26,188.93c0.1-0.2,0.3-0.3,0.4-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3
			C335.96,188.63,336.06,188.83,336.26,188.93z M297.96,238.23c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4
			c0,0.1,0.2,0.3,0.2,0.4C297.66,238.53,297.76,238.43,297.96,238.23z M322.66,331.93c-0.5-0.1-0.8-0.3-1-0.3s-0.5,0.3-0.5,0.5
			s0.2,0.4,0.4,0.6C321.76,332.63,321.96,332.43,322.66,331.93z M265.16,332.73c0.2-0.2,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4
			c-0.1,0.1-0.3,0.2-0.3,0.4C264.86,332.23,265.06,332.43,265.16,332.73z M404.66,186.03c-0.3-0.1-0.5-0.3-0.6-0.3s-0.3,0.2-0.4,0.3
			c0.2,0.1,0.3,0.3,0.5,0.3C404.26,186.33,404.36,186.23,404.66,186.03z M349.06,243.23c-0.3-0.1-0.5-0.2-0.6-0.2s-0.2,0.2-0.4,0.4
			c0.2,0.1,0.3,0.2,0.5,0.2C348.66,243.53,348.86,243.43,349.06,243.23z M251.96,328.53c0.2,0.1,0.4,0.2,0.5,0.3
			c0,0,0.2-0.2,0.2-0.3c0-0.2-0.2-0.3-0.3-0.4C252.26,328.23,252.16,328.33,251.96,328.53z M306.66,246.43c0.1-0.3,0.3-0.4,0.3-0.6
			c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4C306.36,246.03,306.56,246.13,306.66,246.43z M253.56,325.63
			c-0.1,0.3-0.3,0.5-0.3,0.6s0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4C253.86,326.03,253.76,325.83,253.56,325.63z M296.26,249.93
			c0.2-0.2,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.2-0.3,0.4C295.96,249.53,296.16,249.73,296.26,249.93z M256.66,323.23
			c-0.1,0.1-0.1,0.3-0.2,0.4c0.4,0.2,0.9,0.3,1.3,0.5c0.1-0.1,0.1-0.3,0.2-0.4C257.56,323.63,257.16,323.43,256.66,323.23z
			 M268.66,322.83c0.2,0.2,0.4,0.3,0.5,0.3s0.3-0.1,0.3-0.2c0-0.1-0.1-0.4-0.2-0.4C269.16,322.63,268.96,322.73,268.66,322.83z
			 M270.56,253.63c-0.1,0.3-0.3,0.5-0.3,0.6s0.2,0.3,0.3,0.4c0.1-0.2,0.3-0.3,0.3-0.5C270.86,254.03,270.76,253.83,270.56,253.63z
			 M297.86,266.93c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3
			C297.36,267.23,297.56,267.03,297.86,266.93z M355.16,312.93c-0.2,0.2-0.4,0.3-0.5,0.4c0.1,0.1,0.3,0.3,0.4,0.3
			c0.2,0,0.3-0.2,0.5-0.3C355.46,313.33,355.26,313.13,355.16,312.93z M270.56,273.23c0.2-0.3,0.3-0.4,0.3-0.6
			c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4C270.26,272.73,270.46,272.93,270.56,273.23z M292.26,273.63
			c-0.2-0.2-0.3-0.4-0.5-0.4s-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4C291.76,273.93,291.86,273.73,292.26,273.63z M288.66,274.63
			c0.6,0.1,0.9,0.2,1.1,0.1c0.2-0.1,0.4-0.4,0.5-0.6c-0.1-0.1-0.2-0.2-0.2-0.3C289.76,274.13,289.36,274.33,288.66,274.63z
			 M353.46,307.63c-0.2,0.1-0.3,0.2-0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.4l0.3-0.2C353.66,307.93,353.46,307.83,353.46,307.63z
			 M271.46,275.23c-0.2-0.2-0.4-0.3-0.5-0.3c-0.2,0-0.3,0.2-0.4,0.3c0.1,0.1,0.2,0.3,0.4,0.3
			C271.06,275.53,271.16,275.43,271.46,275.23z M314.86,277.73c-0.2-0.2-0.4-0.3-0.5-0.3s-0.3,0.2-0.4,0.3c0.1,0.1,0.2,0.3,0.4,0.3
			C314.36,278.03,314.56,277.83,314.86,277.73z M384.76,305.93c-0.2,0.3-0.3,0.4-0.3,0.6c0,0.1,0.1,0.2,0.2,0.3
			c0.1-0.1,0.3-0.2,0.4-0.3C384.96,306.43,384.86,306.23,384.76,305.93z M325.16,279.03c-0.2-0.2-0.4-0.3-0.5-0.3s-0.3,0.2-0.4,0.3
			c0.1,0.1,0.2,0.3,0.4,0.3C324.76,279.23,324.86,279.13,325.16,279.03z M332.56,283.53c0.1-0.2,0.3-0.3,0.4-0.4
			c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3C332.26,283.23,332.36,283.33,332.56,283.53z M271.76,303.63
			c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4c0.1-0.1,0.3-0.3,0.3-0.4C272.16,304.03,271.96,303.83,271.76,303.63z
			 M310.96,287.03c0.1,0,0.1,0,0.2,0c0-0.5,0-0.9,0-1.4c-0.1,0-0.1,0-0.2,0C310.96,286.13,310.96,286.63,310.96,287.03z
			 M298.06,300.83c0-0.1,0-0.3,0-0.4c-0.2-0.1-0.4-0.2-0.5-0.2s-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4
			C297.76,301.03,297.96,300.93,298.06,300.83z M254.46,294.53c0.1,0.2,0.2,0.4,0.3,0.5c0.1-0.1,0.3-0.2,0.4-0.3
			c0-0.1-0.1-0.3-0.2-0.5C254.76,294.33,254.66,294.43,254.46,294.53z"/>
		<path class="st0-bull-white" d="M804.86,342.83c-0.5-0.5-1-1-0.7-1.8c0.1-0.4,0.6-0.6,1-1c-0.3-0.1-0.7-0.2-1.3-0.4c0.8-0.1,1.3-0.2,2.1-0.4
			c-0.4-0.4-0.7-0.6-1.2-1c0.8-0.1,1.5-0.1,2.4-0.2c-1-0.7-1.7-1.3-2.6-1.9c-0.4,0.3-0.7,0.7-1.1,1c-0.3,0.2-0.7,0.4-0.9,0.3
			c-1.3-0.8-2.7-0.5-4-0.4c-0.6,0-1.3-0.9-1.7-0.1c-0.4,1-1.1,0-1.7,0.5c-0.8,0.7-2.1,0.8-3.2,1.2c0,0.1,0,0.6,0.1,0.6
			c0.6,0.1,1.2,0.3,1.8,0.2c1.2-0.3,2.3-0.8,3.4-1.3c0.3-0.1,0.7-0.5,0.8-0.5c0.9,0.9,1.7-0.5,2.6,0c0.5,0.3,1.1,0.5,1.6,0.9
			c0.7,0.5,1,2.4,0.3,3.2c-1,0.9-0.6,2.6-2,3.2c-0.1,0-0.1,0.5,0,0.8c0,0.2,0.2,0.3,0.4,0.5c-1.2,0.9-0.8,2.5-1.6,3.6
			c-0.4,0.4-0.6,1.1-0.7,1.6c-0.2,1.7-0.4,2.4-1.9,3.9c-2.2-0.7-4.4,1.1-6.8-0.4c0-0.6,0-1.3,0-2.1c0-0.2-0.3-0.5-0.5-0.7
			c0.3-0.4,0.6-0.8,1-1.2c-0.6-0.8-0.9-1.6-0.5-2.7c0.3-1-0.7-2.2-2.1-2.7c0.5,1.6,0.4,2-0.5,3.7c1.2,0.8,1.2,0.8,1,2.6
			c0.4,0.1,0.8,0.2,1.2,0.3c-0.2,0.4-0.6,0.8-0.7,1.2c-0.6,1.6-1.6,2.3-3.2,2.2c-1-0.1-2,0-3.2,0c-0.1-0.5-0.2-1-0.2-1.5
			c0.7-0.3,1.2-0.6,1.9-0.9c-0.7-0.6-1.3-0.9-2.2-0.8c-0.3-0.6-0.6-1.3-0.9-2s-0.5-1.4-0.9-2.2c-0.6,0.6-1.9,0-2,1.4
			c0,0-0.5,0.1-0.6,0c-0.5-0.6-1-1.2-1.2-1.9c-0.3-1-0.7-1.6-1.8-1.6c-0.8,0-1.8,1.4-1.7,2.4c0,1,0.1,2,0,3
			c-0.2,0.8-0.2,1.4,0.2,2.1c0.2,0.4,0.1,1.1,0.2,1.6c-0.8,0.1-1.4,0.2-2.1,0.3c0-0.6,0.2-1.4,0-1.6c-0.5-0.4-1.3-0.5-2-0.7
			c-0.1,0.2-0.2,0.3-0.2,0.5c0.3,0.3,0.7,0.6,1,0.9c0,1-1.5,1.3-4.4,0.7c0.5-0.6,1-1.1,1.6-1.7c-0.7-0.3-1.2-0.6-1.7,0.2
			c-0.4,0.6-0.9,1.1-1.4,1.4c-1.1,0.7-2,0.4-2.7-0.5c-1.9-2.5-3.7-5-5.6-7.5c-3.7-4.9-7.3-9.7-11-14.6c-0.9-1.1-1.1-2.1-0.7-3.4
			c0.9-3.5,1.8-7.1,2.7-10.6c0.2-0.8,0.4-1.4,1.3-1.4c-1.2-1.4-0.2-2.7,0-4.2c0.6-0.2,1.2-0.4,2-0.7c0,1.1,0.3,1.8,1.7,1.4
			c0.1-1.5,1.1-2.3,2.5-2.7c0.6-0.2,0.7-0.5,0.6-1.1c1.4,0.4,1.3,1.9,2,2.8c0.3-0.2,0.5-0.4,0.5-0.5c0.1-1.3,0.5-2.1,2-2.1
			c0.8,0,1.6-0.3,2.3-0.5c0.4-0.1,0.7-0.5,1-0.7c-0.1-0.1-0.1-0.2-0.2-0.4c-1.2,0-2.4,0-3.8,0c0.1-0.7,0.2-1.2,0.3-1.9
			c-0.4,0.3-0.9,0.4-0.9,0.6c-0.4,1.7-1.7,2.3-3.2,2.7c-0.7-0.1-1.3-0.3-2-0.4c-0.3-1.6,1.1-6.7,2-7.7c-0.1-0.5-0.4-1-0.3-1.3
			c0.9-2.2,0.3-4.5,0.5-6.7c0.1-1-0.2-2,0.6-3c0.3-0.4,0.1-1.1,0.1-1.6c-0.2-6.6,1.2-12.9,3-19.1c0.1-0.4,0.7-0.8,0.8-1.2
			c0.1-0.5-0.1-1-0.1-1.5c0.1-0.9,0.3-1.7,1.7-1.7c-1.7-1.1-0.6-2.2-0.5-3.2c0.1-1.3,1-2,2.4-2.3c-0.4-0.5-0.6-0.9-0.9-1.3
			c3-2.4,6-4.8,9-7.2c5.4-4.4,10.9-8.7,16.3-13c0.5-0.4,1-0.7,1.5-1.1c0.1,0.4,0.1,0.8,0.2,1.5c0.3-0.6,0.1-1.2-0.3-1.5
			c2.5-2,5-4,7.4-6.1c0.4-0.3,0.8-0.9,0.7-1.3c-0.1-0.8-0.9-0.7-1.5-0.7c-7.1,0-14.1,0-21.2,0c-1.8,0-3.6-0.3-5.7,0.2
			c3.7-15.2,7.4-29.9,11-44.7c17.7,0,35.3,0,53.2,0c-1.6,6.5-3.3,12.8-4.8,19.1c-1.4,6.3-3.2,12.5-4.7,18.8
			c-1.5,6.4-3.1,12.7-4.7,19.1c-1.6,6.4-3.2,12.7-4.8,19.1c-1.6,6.3-3.1,12.6-4.7,18.9c-1.6,6.4-3.2,12.7-4.8,19.1
			c-0.4,0.1-0.8,0.3-1.1,0.4c0.2,0.5,0.7,0.9,0.7,1.3c-0.3,1.5-0.9,3-1.2,4.6c-0.4,1.5-0.8,3.1-1,4.6c-0.2,1,1.3,1.6,2.5,1.5
			c2.8-0.2,5.6-0.2,8.4-0.3c0.2,0,0.4,0.4,0.7,0.6c0.5,0.3,1,0.5,1.7,0.8c-0.1-0.7-0.1-1-0.2-1.3c0.1-0.1,0.2-0.2,0.4-0.2
			c13.9,0,27.8,0,41.7,0c0.9,0,1.9,0.3,2.8,0.5c0.3,0.3,0.6,0.7,1.1,1.2c0.1-1.3-0.7-1.1-1.2-1.2c0.4-0.2,0.8-0.4,1.3-0.4
			c2.8-0.1,5.7-0.1,8.5-0.1c0.7,0,1.4-0.1,1.8,0.7c0.2-0.9,0.9-0.7,1.5-0.7c3.2,0,6.5,0,10,0c-1.2,2.3-0.9,4.9-2.1,7
			c-0.7,1.2-1.9,2.3-3.1,3c-0.6,0.3-1,0.7-1.5,1.2c-1,1-2.3,1.8-3.1,3.1c-0.2,0.3-0.2,0.6-0.4,0.9c1.5,0.2,1.6,0.2,2-1.8
			c0.9-0.3,1.9-0.5,2.6-1c0.6-0.3,0.5-1.4,1.4-1.6c0.5-0.1,0.9-0.8,1.7-1.6c-0.1,1-0.2,1.6-0.3,2.1c-2.1,8.7-4.3,17.4-6.5,26.1
			c-0.2,1-0.6,1.9-1,2.9c-0.2,0.7-0.5,1.3-0.4,2.1c0.1,0.7-0.4,1.4-0.6,2.2c-0.8-0.5-1.9,0.2-2.2-1c-1.3,0.5-2.5,1.1-4.1,0.6
			c-1.2-0.4-2.7,0.3-4.1,0.3c-8.7,0.1-17.5,0.2-26.2,0.3c-0.6,0-1.2-0.1-1.9-0.1c-0.1-0.7-0.2-1.4-0.3-2c-1.2-0.4-1.7,0.6-2.3,1.1
			s-1.1,1.1-1.9,1c-1.6-0.1-3.1-0.2-4.7-0.3c-1.5,0-3.1,0-4.6,0.1c-0.3,0-0.5,0-0.8-0.1c0.1-0.5,0.2-0.8,0.3-1.4
			c-0.8,0.3-1.4,0.6-1.9,0.8c-0.3-0.6-0.7-1.3-1.2-2.2c-0.3,0.5-0.5,0.8-0.7,1.1c0.3,0.1,0.5,0.2,0.8,0.3c-0.2,0.5-0.4,0.9-0.7,1.5
			c-0.5-0.3-0.9-0.6-1.3-0.9c-0.2,0.3-0.4,0.7-0.5,1c-1.6-0.1-3.4,0.5-4.7-1.1c0.7-0.8,1.4-1.6,2.3-2.8c-1.4-0.2-2.4-0.3-3.4-0.5
			l0.1,0.1c-0.4-0.1-0.8-0.2-1.2-0.2s-0.9,0.1-1.3,0.2l0.1,0.1c-0.1-0.5-0.2-1.1-0.2-1.6c-0.8,0.1-1.5,0.3-2.2,0.3
			c-0.4,0-0.7-0.3-1.3-0.6c1.1-1,2.3-1.2,1.6-2.5c1.4,0.5,2.5,0.7,3.5,1.2c0.6,0.3,1,1,1.6,1.6c0.3-0.5,0.6-0.9,0.9-1.5
			c-0.5-0.1-0.9-0.2-1.4-0.4c-0.2-1.2-0.5-2.4-0.8-3.7c0.3-0.2,0.6-0.3,1-0.5C806.96,342.13,805.76,343.03,804.86,342.83
			L804.86,342.83z M769.96,314.13c-0.3,0.2-0.7,0.4-1.2,0.6C769.86,315.23,769.76,314.53,769.96,314.13c0.7,0,1.5,0.1,2.2,0.2
			C771.36,313.73,770.66,313.53,769.96,314.13z M855.66,324.83c0.6-0.4,1.8-0.1,1.9-1.9C856.86,323.83,855.36,323.23,855.66,324.83
			c-1.8-0.4-1.8-0.4-2.1,0.7C854.36,325.23,854.96,325.03,855.66,324.83z M795.66,220.73c-0.6,0.1-1.2,0.1-1.8,0.2
			c-0.3,0.1-0.5,0.4-1,1C794.16,221.73,795.56,222.63,795.66,220.73c0.5,0.2,1.1,0.4,2,0.7
			C797.06,220.03,796.36,220.33,795.66,220.73z M775.66,339.83c-0.6-0.5-0.9-0.1-1.2,0.5c0.8,0,1.6,0.1,2.2,0
			c0.6-0.1,0.4-0.5-0.1-0.8c0.1-1.2,0.8-2.3,0.3-3.6c1.4,0.2,1.8,1.3,2.3,2.3c-0.5,0-1.1-0.4-1.3,0.4c-0.1,0.8,0.5,0.8,1.1,0.8
			c0.1,0,0.1,0,0.2,0c-1.5,1.7-1.1,2.7,1.7,3.9c-0.6-1.5,1.5-2.8-0.1-4.3c0.6-0.2,1-0.3,1.3-0.5c-0.1,1.9,1.7,0.9,2.3,1.6
			c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.3-0.7-0.7-1-1c-0.7,0.9-1.1-0.2-1.6-0.2c-0.1-0.5-0.1-0.9-0.2-1.4c0.8,0.3,1.2-0.1,1.3-0.8
			c0-0.2-0.5-0.4-0.8-0.7c-0.2,0.6-0.3,1.1-0.4,1.5c-1.4-0.5-1-1.5-0.9-2.4c0.5,0,0.8,0,1,0c-0.2-1.5-0.9-3-0.5-4.1
			c0.4-1,0-1.4-0.2-2.1c-2.4,0.8-2.9,1.5-2.6,3.2c0.5-0.1,1-0.1,1.6-0.2c-1.1,1.6-0.5,3,0,4.7c-1.5-1.1-2.7-1.9-4.4-3
			c0.4,1.2,0.7,1.9,1,2.7C776.46,337.23,776.06,338.53,775.66,339.83z M751.86,316.33c-0.6-0.7-0.6-0.7-1.2-0.2
			C751.06,316.13,751.46,316.23,751.86,316.33c0.1,1.4,0.1,1.4,1.7,1.4c-0.3-0.7-0.5-1.3-0.8-2
			C752.36,315.93,752.06,316.13,751.86,316.33z M817.96,338.43c-0.8-0.3-1,0.1-0.9,0.8c0,0.6,0.1,1.1,0.2,2.1c0.6-0.8,1-1.2,1.3-1.6
			C818.26,339.33,818.06,338.83,817.96,338.43c0.4-0.4,0.9-0.8,1.3-1.2c-0.4-0.5-0.8-0.9-1.2-1.3c-0.1,0-0.2,0.1-0.4,0.1
			C817.76,336.83,817.86,337.63,817.96,338.43z M779.46,304.53c0.5,0.3,1,0.6,1.5,0.9c1-0.7,1.2-0.7,2.8-0.9
			c0.7-0.1,1.4-0.5,2.3-0.7c-0.3-0.3-0.4-0.6-0.5-0.6C783.46,303.63,781.46,304.03,779.46,304.53c-0.6,0.1-1.2,0.1-1.7,0.3
			s-1,0.6-1.4,1c-0.1,0.1,0.3,0.8,0.5,0.8c1.2,0.2,1.2-1.8,2.6-1.4C779.36,305.03,779.36,304.73,779.46,304.53z M816.56,349.43
			c-0.3-0.4-0.5-1.2-1.4-0.2C815.76,349.33,816.16,349.33,816.56,349.43c-0.5,1.2,0.6,2.2,0.7,3.4c0,0.1,0.5,0.1,0.7,0.2
			c0.7-1.5,0.1-2.8-0.2-4.1c0.6-0.3,1.1-0.5,1.9-0.8c-0.8-0.3-1.3-0.5-1.9-0.7c0.4-0.3,0.6-0.5,0.8-0.7c-0.5-0.2-1-0.5-1.3-0.6
			C816.96,347.33,816.76,348.33,816.56,349.43z M788.46,270.73c1.4-1.7,1.8-3.6,1.5-5.7c-0.2-1.5,0.1-2.9,0-4.4
			c-0.1-1.1-0.6-2.3-0.4-3.4c0.2-1.5-0.2-2.8-0.7-4.2c-0.3-0.7,0.2-1.7,0.3-2.5c0-0.3,0.1-0.5,0.2-1c-1.9,1.2-1.6,2.7-1.3,4.3
			c0.3,2,1.7,4-0.4,5.9c0.7,0.8,1.4,1.3,0.8,2.7c-0.5,1.1-0.4,2.8,0,4.1c0.4,1.2,0.3,1.9-0.7,2.6c-0.2,0.2-0.4,0.5-0.5,0.8
			C787.66,270.33,788.06,270.53,788.46,270.73z M788.36,344.23c0.8-0.6,1.6-1.2,2.5-1.9c-0.1-1.7-0.2-3.5-0.4-5.4
			c-0.6,0.1-1.1,0.2-1.7,0.4c0.1-1.2,0.3-2.2,0.4-3.3c-0.5-0.6-1.2-1.4-2.2-2.5c0,0.7,0,1,0.1,1.4c0.1,0.8,0.7,1.7,0.4,2.4
			c-0.5,1.1,0,1.8,0.7,2.5C786.16,340.13,787.96,342.13,788.36,344.23z M784.46,270.03c-0.6,2.7-0.2,6.8,0.9,8.5c0-2.7,0-5.1,0-7.4
			C785.26,270.83,784.86,270.53,784.46,270.03z M788.56,242.73c-0.3,2.1-1.5,4.2,0.1,6.2c0.5-0.3,1.5-1.3,1.3-1.7
			c-0.1-0.4-0.6-0.8-0.9-1.1C790.26,244.73,788.46,243.83,788.56,242.73z M824.86,337.83c1.1-0.3,2-0.6,3-0.9
			c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.6,1.5-1.4,2.6-1.4c0.2,0,0.3-0.6,0.6-1.3c-1.2,0.5-2.3,0.7-2.8,1.3c-0.8,0.9-1.6,0.6-2.5,0.6
			c-0.2,0-0.4,0.3-0.6,0.5c-0.4,0.3-0.8,0.6-1.3,1C824.66,337.53,824.86,337.83,824.86,337.83z M793.56,353.93
			c0.8-1.3,2.4-2.1,2.5-4c-1.4,0.6-2.6,1-3.8,1.5C792.66,352.23,793.06,353.03,793.56,353.93z M764.46,309.73
			c0.1,0.1,0.3,0.2,0.4,0.3c1.9-0.6,3.7-1.3,5.6-1.9c0.1-0.8-0.4-1-1-0.8c-1.4,0.4-2.8,0.8-4.1,1.4
			C764.96,308.83,764.76,309.33,764.46,309.73z M787.26,305.03c-0.4-2.1,2.4-1.4,2.7-3.4c-1.1,0.8-1.9,0.4-2.7-0.2
			C786.26,302.73,786.36,303.83,787.26,305.03z M813.16,312.43c-0.3,1.8-0.6,3.4-0.8,5c1-0.5,1.4-1.3,1.8-3
			C814.26,313.53,813.96,313.03,813.16,312.43z M809.76,342.23c0.1-0.9,0.2-1.7,0.4-2.6c-0.8,0-1.5,0-2.2,0
			c-0.1,0.2-0.3,0.4-0.4,0.6c0.4,0.2,0.8,0.3,1.1,0.5c-0.1,0.6-0.3,1-0.4,1.6C808.96,342.23,809.46,342.23,809.76,342.23z
			 M861.16,321.83c0.4,0.5,0.7,0.8,0.8,1.2c0.1,0.6,0.4,1,0.9,0.7c0.2-0.1,0.1-0.7,0.2-1c0-0.2-0.1-0.6,0-0.7c1.1-0.8,0.7-1.7,0.5-3
			C862.66,320.13,863.26,321.93,861.16,321.83z M783.76,248.63c0.4-0.9,0.8-1.8,1.2-2.7c-1.6-0.5-2,0.7-2.7,1.4
			C782.96,247.93,783.36,248.33,783.76,248.63z M805.26,326.13c0.5,0,1.1,0.1,1.2,0c0.5-0.8,1-1.8,1.4-2.7c0.1-0.2-0.1-0.5-0.2-0.7
			c-0.3,0.1-0.7,0.1-0.8,0.3C806.26,323.93,805.76,324.93,805.26,326.13z M796.36,329.13c-0.2,0.1-0.4,0.2-0.6,0.3
			c0.1,0.4,0.1,0.9,0.4,1.1c0.5,0.4,1.4,0.4,1.6,1.4c0,0,0.6,0.1,0.7,0c0.1-0.1,0.2-0.6,0.1-0.7
			C797.76,330.43,797.06,329.83,796.36,329.13z M812.26,345.53c-0.6,0.5-1,0.8-1.3,1.1c0.3,0.6,0.6,1.1,0.9,1.7
			c0.5-0.3,0.9-0.6,1.3-0.8C812.86,346.93,812.66,346.43,812.26,345.53z M787.56,328.43c0.8-0.6,1.8-0.8,1.9-2
			c-0.9-0.3-1.6,0.1-1.9,0.8C787.36,327.63,787.56,328.03,787.56,328.43z M775.46,342.23c-0.9,0.3-1.5,0.6-2.2,0.9
			c0.3,0.5,0.5,0.9,0.7,1.3c0.5-0.2,1-0.3,1.5-0.5C775.46,343.43,775.46,343.03,775.46,342.23z M814.26,349.73
			c-1.2-0.6-2.3-0.1-3.3,0.7C812.36,351.83,813.06,349.83,814.26,349.73z M781.56,324.43c0-0.7-0.1-1.2-0.1-1.9
			c-0.8,0.1-1.7-0.3-1.8,0.9C780.26,323.73,780.76,324.03,781.56,324.43z M873.06,317.03c0.1,0.1,0.1,0.3,0.2,0.4
			c0.9-0.3,1.9-0.6,3.1-1c-0.4-0.4-0.7-0.8-0.8-0.8C874.66,316.03,873.86,316.53,873.06,317.03z M774.76,308.23c0-0.9,0-1.7,0-2.9
			C773.56,307.43,773.56,307.43,774.76,308.23z M785.26,334.93c-0.7-0.7-1.4-1.4-2.3-2.2c-0.3,1.6,1.3,1.8,1.4,3
			C784.76,335.33,784.96,335.23,785.26,334.93z M862.46,332.83c-1.1,0.7-1.5,1.6-1.4,2.8C862.16,335.13,862.86,334.43,862.46,332.83
			z M823.06,347.33c0.1,0.1,0.2,0.1,0.3,0.2c0.7-0.6,1.5-1.2,2.2-1.8c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4,0-0.9-0.1-1.4-0.1
			C823.56,346.03,823.26,346.63,823.06,347.33z M822.26,342.33c0.4,1,0.6,1.6,0.9,2.4c0.4-0.6,0.7-1.1,1.1-1.6
			C823.56,342.93,823.06,342.73,822.26,342.33z M787.26,312.93c-0.2,0.6-0.4,1.3-0.6,2c0.5,0,0.8,0,1.3,0c-0.1-0.4-0.4-0.9-0.3-1
			C788.26,313.33,788.06,313.03,787.26,312.93z M869.56,350.93c0.1,0.1,0.3,0.2,0.4,0.3c1.2-0.3,1.1-1.5,1.5-2.3
			c-0.1-0.1-0.3-0.2-0.4-0.3C870.56,349.43,870.06,350.13,869.56,350.93z M785.56,283.13c0.1,0,0.1,0,0.2,0c0-1.2,0-2.5,0-3.8
			c-0.4,0.1-0.7,0.1-1,0.2C785.06,280.83,785.26,281.93,785.56,283.13z M784.36,344.03c1-1.1,1-1.1,0.5-1.8
			c-0.1-0.2-0.3-0.3-0.5-0.5c-0.3,0.6-0.5,1.1-0.8,1.6C783.86,343.63,784.06,343.83,784.36,344.03z M810.86,337.53
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.5,0-1,0-1.6,0c0.1,0.6,0.1,1.1,0.2,2.1C809.96,338.43,810.46,338.03,810.86,337.53z M844.36,342.73
			c0.1,1.1,0.9,0.8,1.6,0.9c0.7,0,1-0.3,0.8-0.9C845.96,342.73,845.16,342.73,844.36,342.73z M780.56,346.93c-0.8-0.6-1.4-1.6-2.4-1
			c-0.2,0.1-0.1,0.6-0.2,1C778.86,346.93,779.76,346.93,780.56,346.93z M783.46,224.23c-1.4,1.5-1.4,1.5-0.8,2.7
			C782.96,225.93,783.26,225.03,783.46,224.23z M868.46,311.33c0,0.7,0,1.4,0,2.2C869.86,312.23,869.86,312.03,868.46,311.33z
			 M787.26,308.63c-1.5,1.2,0.4,2-0.3,3.2c0.6-0.3,0.8-0.4,1-0.5C787.66,310.43,787.46,309.63,787.26,308.63z M793.56,243.53
			c-0.2,1.5-0.2,1.5,1.4,1.7C795.06,244.23,794.46,243.93,793.56,243.53z M765.56,313.83c0.1,0.8,0.5,1.1,1.3,1.1
			c0.6,0,1.3,0.1,1.1-0.8C767.16,314.03,766.36,313.93,765.56,313.83z M804.06,314.73c-0.5,0.6-2-0.1-1.6,1.1
			c0.1,0.3,0.9,0.3,1.6,0.6C804.06,315.53,804.06,315.13,804.06,314.73z M862.06,350.93c-1.1,1.2-1.1,1.2-0.1,2
			C861.96,352.23,862.06,351.53,862.06,350.93z M868.56,353.13c-1.3-0.7-1.3-0.7-2.2,0.9C867.06,353.73,867.66,353.53,868.56,353.13
			z M833.66,321.13c0,0.1,0,0.2,0,0.3c0.6,0,1.3,0,1.9,0c0-0.1,0-0.2,0-0.3C834.96,321.13,834.36,321.13,833.66,321.13z
			 M760.16,303.43c-1.4,1.5-1.4,1.5-0.1,2C760.06,304.73,760.16,304.03,760.16,303.43z M774.66,337.13c-1.3,0.2-1.6,0.3-1.6,1.1
			c0,0.2,0.5,0.4,0.8,0.7C774.26,338.13,774.46,337.63,774.66,337.13z M789.56,311.03c0.2,0.5,0.5,1,0.7,1.6
			C791.36,311.33,791.36,311.23,789.56,311.03z M793.06,301.73c-0.1-0.2-0.1-0.3-0.2-0.5c-0.5,0.2-1,0.3-1.5,0.5
			c0.1,0.2,0.2,0.5,0.3,0.7C792.06,302.23,792.56,301.93,793.06,301.73z M869.16,338.43c-0.8,0.1-1-1.4-2-0.7
			C867.96,338.63,867.96,338.63,869.16,338.43z M794.86,341.13c-0.1-0.3-0.1-0.5-0.2-0.8c-0.5,0.2-1,0.4-1.4,0.6
			c0,0,0.1,0.3,0.1,0.5C793.86,341.33,794.36,341.23,794.86,341.13z M785.76,332.13c-0.1-0.2-0.1-0.4-0.2-0.6
			c-0.4,0.3-1.2-0.4-1.4,0.6c0,0.2,0.1,0.4,0.2,0.5C784.76,332.53,785.26,332.33,785.76,332.13z M786.46,290.83
			c-0.6,0.2-1.3,0.2-0.7,1c0.1,0.2,0.4,0.2,0.7,0.4C786.46,291.63,786.46,291.33,786.46,290.83z M776.16,252.73c-0.2,0-0.3,0-0.5,0
			c0,0.5,0,0.9,0,1.4c0.1,0,0.2,0,0.4,0C776.06,253.73,776.06,253.23,776.16,252.73z M805.46,328.33c0.1,0,0.3,0,0.4,0
			c0.2-0.5,0.4-1-0.4-1.5C805.46,327.43,805.46,327.93,805.46,328.33z M800.66,327.23c-0.5-0.2-0.8-0.5-0.9-0.4
			c-0.2,0.1-0.4,0.4-0.5,0.7c0,0.1,0.4,0.4,0.5,0.4C800.06,327.83,800.26,327.63,800.66,327.23z M764.36,342.23
			c0.4,0.3,0.6,0.6,0.8,0.6c0.2,0,0.6-0.3,0.5-0.4c-0.1-0.3-0.2-0.6-0.5-0.7C765.06,341.73,764.76,342.03,764.36,342.23z
			 M795.06,327.13c-0.2-0.4-0.4-0.8-0.5-0.7c-0.3,0-0.5,0.2-0.8,0.4c0.1,0.2,0.2,0.5,0.4,0.6
			C794.46,327.43,794.66,327.23,795.06,327.13z M792.16,209.83c-0.4-1.1-0.4-1.1-1.5-0.9C791.26,209.33,791.66,209.53,792.16,209.83
			z M822.56,334.73c0.1-0.1,0.2-0.3,0.3-0.4c-0.4-0.4-0.6-1.3-1.7-0.6C821.66,334.13,822.16,334.43,822.56,334.73z M764.96,304.03
			c0.4-0.4,0.7-0.6,0.7-0.8s-0.3-0.5-0.6-0.6c-0.1,0-0.5,0.2-0.5,0.4C764.66,303.23,764.86,303.53,764.96,304.03z M811.66,328.13
			c-0.1,0.1-0.2,0.2-0.4,0.3c0.3,0.4,0.6,0.9,1.1,1.5C812.96,328.73,811.96,328.63,811.66,328.13z M757.46,337.83
			c0.8,0.5,1.3,0.7,1.8,1c0.1-0.1,0.1-0.2,0.2-0.4C758.96,338.23,758.86,337.23,757.46,337.83z M788.96,322.53
			c-0.4-0.2-0.7-0.4-0.9-0.4c-0.2,0-0.5,0.4-0.5,0.4c0.1,0.3,0.3,0.6,0.6,0.6C788.36,323.23,788.56,322.83,788.96,322.53z
			 M857.46,351.93c-0.4-0.3-0.6-0.7-0.8-0.7s-0.5,0.4-0.6,0.6c0,0.1,0.3,0.5,0.5,0.5C856.76,352.33,856.96,352.13,857.46,351.93z
			 M760.06,316.63c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4,0.3-0.8,0.5-1.2,0.8c0,0,0.1,0.3,0.2,0.7
			C759.26,317.23,759.66,316.93,760.06,316.63z M809.76,343.73c-0.4-0.4-0.6-0.7-0.8-0.7c-0.2,0-0.5,0.3-0.6,0.6
			c0,0.1,0.3,0.5,0.4,0.5C808.96,344.03,809.26,343.93,809.76,343.73z M849.16,343.13c0.8,0.4,1.2,0.6,1.7,0.9
			C851.06,342.73,850.36,342.83,849.16,343.13z M819.36,344.23c0,1.3,0.8,1,1.3,0.9c0.1,0,0.1-0.2,0.3-0.4
			C820.46,344.63,820.06,344.43,819.36,344.23z M813.76,335.53c-0.1,0-0.3,0-0.4,0c0,0.4,0.1,0.8,0.1,1.2c0.1,0,0.2,0,0.3,0
			C813.76,336.33,813.76,335.93,813.76,335.53z M812.76,319.63c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2,0.3-0.5,0.6-0.7,0.9
			c0.1,0.1,0.2,0.2,0.4,0.3C812.26,320.13,812.46,319.83,812.76,319.63z M866.26,320.13c-0.9-0.5-1.2-0.2-0.9,0.8
			C865.66,320.63,865.96,320.33,866.26,320.13z M779.06,321.53c1.1,0.1,1.4-0.2,1.1-1.2C779.76,320.73,779.46,321.03,779.06,321.53z
			 M808.76,224.73c-0.1,0-0.2,0-0.3,0c0,0.5,0,0.9,0,1.4c0.1,0,0.2,0,0.3,0C808.76,225.63,808.76,225.23,808.76,224.73z
			 M815.96,341.53c0.5-0.9,0.2-1.2-0.8-0.9C815.46,341.03,815.76,341.23,815.96,341.53z M795.66,300.83c0.4,0.3,0.7,0.6,1.1,1
			C796.96,300.73,796.76,300.43,795.66,300.83z M790.86,246.73c0,0.1,0.1,0.2,0.1,0.3c0.2,0,0.6,0,0.7-0.1c0.2-0.2,0.1-0.5,0.3-1.3
			C791.36,246.23,791.06,246.43,790.86,246.73z M792.96,323.23c0.1-1,0.1-1-0.9-0.9C792.36,322.63,792.56,322.83,792.96,323.23z
			 M774.06,259.33c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0.2,0.3,0.3,0.4
			C773.56,259.83,773.76,259.53,774.06,259.33z M864.96,316.73c-0.2-1.1-0.2-1.1-1-0.7C864.26,316.23,864.56,316.43,864.96,316.73z
			 M879.06,313.73c-0.4,0.4-0.6,0.7-1.1,1.1C879.06,315.03,879.26,314.63,879.06,313.73z M859.86,314.63c0.1,0.1,0.2,0.2,0.2,0.3
			c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0,0-0.2-0.1-0.4C860.46,314.23,860.16,314.43,859.86,314.63z M782.36,312.23
			c-0.1,0.2-0.1,0.4-0.2,0.6c0.4,0.1,0.9,0.1,1.3,0.2c0-0.1,0-0.2,0.1-0.2C783.06,312.53,782.76,312.33,782.36,312.23z
			 M852.16,348.23c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.2,0.4,0.4,0.7,0.6c0.1-0.1,0.2-0.3,0.3-0.4
			C852.66,348.53,852.36,348.43,852.16,348.23z M819.36,346.63c0.1,0.1,0.3,0.3,0.4,0.4c0.3-0.3,0.5-0.5,0.9-0.9
			C819.56,345.63,819.36,346.03,819.36,346.63z M800.66,299.83c-1.1-0.1-1.1-0.1-1.3,0.7C799.76,300.23,800.16,300.03,800.66,299.83
			z M841.66,344.43c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0.2,0.3,0.3,0.4
			C841.16,344.83,841.46,344.63,841.66,344.43z M817.86,343.53c-0.5,0.1-1,0.2-1.8,0.4C817.16,344.83,817.56,344.53,817.86,343.53z
			 M827.46,342.83c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4s0.3-0.1,0.5-0.2C827.66,343.13,827.56,343.03,827.46,342.83z
			 M814.56,341.73c-0.6-0.3-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4S813.96,341.93,814.56,341.73z
			 M765.26,346.13c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0,0.3-0.2,0.4-0.3c-0.1-0.1-0.3-0.3-0.4-0.3
			C765.66,345.83,765.46,346.03,765.26,346.13z M823.46,338.93c-0.1,0.5-0.3,0.8-0.3,1s0.3,0.4,0.5,0.5c0.2,0,0.4-0.2,0.6-0.4
			C824.06,339.83,823.96,339.63,823.46,338.93z M842.66,346.93c0-0.1,0.1-0.2,0.1-0.3c-0.6,0-1.3,0-1.9,0c0,0.1,0,0.2,0,0.3
			C841.56,346.93,842.16,346.93,842.66,346.93z M765.16,339.23c-0.2-0.2-0.4-0.4-0.5-0.4c-0.2,0-0.3,0.1-0.5,0.2
			c0.1,0.1,0.2,0.3,0.3,0.4C764.66,339.43,764.76,339.33,765.16,339.23z M822.96,221.33c-0.2-0.2-0.4-0.3-0.5-0.3
			c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.2,0.3,0.4,0.3C822.46,221.63,822.66,221.43,822.96,221.33z M786.06,350.53
			c0.2-0.2,0.4-0.3,0.4-0.5s-0.1-0.3-0.2-0.5c-0.1,0.1-0.3,0.2-0.4,0.3C785.86,350.03,786.06,350.23,786.06,350.53z M769.86,350.23
			c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.3C769.46,350.63,769.66,350.43,769.86,350.23z
			 M817.76,335.43c0.1-0.2,0.2-0.3,0.3-0.5c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.2,0.2-0.4,0.3
			C817.36,335.13,817.56,335.23,817.76,335.43z M844.96,352.83c-0.2-0.2-0.4-0.3-0.5-0.3c-0.1,0-0.3,0.2-0.4,0.3
			c0.1,0.1,0.2,0.3,0.4,0.3C844.46,353.13,844.66,352.93,844.96,352.83z M796.56,353.53c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.4,0.2-0.9,0.4-1.3,0.6c0.1,0.1,0.1,0.3,0.2,0.4C795.76,353.93,796.16,353.73,796.56,353.53z M784.56,354.03
			c0.1,0.2,0.2,0.4,0.3,0.6c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.2-0.5C784.86,353.83,784.76,353.93,784.56,354.03z
			 M834.26,332.93c0,0.1,0,0.2,0,0.2c0.6,0,1.3,0,1.9,0c0-0.1,0-0.2,0-0.2C835.46,332.93,834.86,332.93,834.26,332.93z
			 M824.36,188.43c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.4,0.3l0.2,0.3C824.06,188.63,824.16,188.53,824.36,188.43z
			 M790.76,333.23c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3
			C790.56,333.63,790.66,333.53,790.76,333.23z M749.16,328.23c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.3-0.2,0.5-0.2,0.8
			c0,0,0.2,0.1,0.3,0.2C749.06,328.73,749.06,328.43,749.16,328.23z M792.86,327.43c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
			c0.1-0.1,0.3-0.3,0.3-0.4C793.16,327.83,792.96,327.73,792.86,327.43z M798.06,327.23c-0.3-0.2-0.5-0.3-0.7-0.4
			c-0.1,0.2-0.3,0.4-0.3,0.6s0.3,0.3,0.5,0.4C797.66,327.73,797.76,327.53,798.06,327.23z M790.36,327.93c0.2-0.3,0.3-0.4,0.3-0.6
			c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4C790.06,327.53,790.16,327.73,790.36,327.93z M820.26,326.33
			c-0.2,0.3-0.3,0.4-0.3,0.6c0,0.1,0.1,0.2,0.1,0.3c0.1-0.1,0.4-0.2,0.4-0.3C820.56,326.83,820.36,326.63,820.26,326.33z
			 M810.26,323.23c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0.2,0.3,0.2,0.5
			C809.96,323.53,810.06,323.43,810.26,323.23z M802.36,319.63c-0.2,0.2-0.3,0.3-0.5,0.4c0.1,0.1,0.2,0.3,0.4,0.3
			c0.1,0,0.3-0.2,0.4-0.3C802.56,319.93,802.46,319.83,802.36,319.63z M784.16,319.13c-0.2-0.2-0.3-0.3-0.4-0.5
			c-0.1,0.1-0.3,0.2-0.3,0.4c0,0.1,0.2,0.3,0.3,0.4C783.86,319.43,783.96,319.33,784.16,319.13z M773.76,262.63
			c-0.2-0.2-0.3-0.3-0.4-0.4c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.1,0.2,0.3,0.3,0.4C773.46,262.93,773.56,262.83,773.76,262.63z
			 M870.16,313.73c-0.3,0.6-0.4,0.7-0.4,0.9c0,0.1,0.2,0.3,0.4,0.3c0.1,0,0.4-0.2,0.4-0.3
			C870.56,314.43,870.36,314.33,870.16,313.73z M778.36,314.73c-0.2-0.2-0.3-0.4-0.5-0.4s-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4
			C777.86,314.93,778.06,314.83,778.36,314.73z M826.86,313.13c-0.2-0.1-0.4-0.2-0.5-0.3c0,0-0.2,0.2-0.2,0.3s0.2,0.3,0.3,0.4
			C826.56,313.43,826.66,313.33,826.86,313.13z M788.46,273.23c0.2-0.1,0.4-0.1,0.5-0.2c-0.2-0.4-0.3-0.8-0.5-1.2
			c-0.1,0-0.2,0.1-0.3,0.1C788.26,272.33,788.36,272.73,788.46,273.23z M771.86,306.43c0.4,0.9,0.6,1.4,0.9,2.2
			C772.96,307.23,772.96,307.23,771.86,306.43z M792.86,307.23c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.2-0.3-0.3-0.5
			c-0.1,0.1-0.3,0.2-0.3,0.4C792.56,306.73,792.66,306.93,792.86,307.23z M783.36,305.83c0.1,0.2,0.2,0.4,0.3,0.6
			c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.2-0.5C783.66,305.73,783.56,305.73,783.36,305.83z"/>
		<path class="st0-bull-white" d="M728.76,310.83c-0.9,3.4-1.3,6.6-2.5,9.2c-1.5,0.6-2.7,1-3.8,1.5c-0.1,0,0.1,0.6,0.2,1.1
			c0.7-0.5,1.2-0.9,1.8-1.3c0.5,0.2,1,0.4,1.6,0.7c-0.7,2.9-1.4,5.7-2,8.6c-0.7,2.9-1.4,5.8-2.1,8.6s-1.3,5.6-2.4,8.3
			c-0.1,0-0.4,0-0.6,0c0,0.2-0.1,0.4-0.1,0.5c0.4,0.6,0.7,1.1,0.4,2c-0.6,1.7-0.9,3.5-1.4,5.5c-0.7,0-1.4,0-2.1,0
			c-8.5,0-17,0.1-25.5,0c-3.6,0-7.1,0.1-10.7,0.1c-3.5-0.1-7,0-10.6,0c-4,0-8,0-12,0c-0.8,0-1.7,0.2-1.9-0.6
			c-1.2,0.2-2.2,0.6-3.2,0.6c-2,0.1-4,0-6.1,0c-0.1-0.6-0.2-1-0.3-1.8c-0.4,0.8-0.6,1.2-1,1.8c-2.6-0.1-5.4,0.4-8.1-0.4
			c-0.3-0.1-0.6-0.2-0.8-0.1c-1.7,0.9-3.5,0.4-5.3,0.4c-5.3,0.1-10.5,0-15.8,0c-0.9,0-1.9-0.3-2.8-0.4c-0.4,0-0.8,0.1-1.2,0.2
			c-2,0.5-2.7,0.3-3.9-1.3c-3.1-4.1-6.3-8.2-9.3-12.4c-2.3-3.3-4.9-6.4-7.3-9.6c-0.8-1.1-0.4-2-0.2-3.1c0.2-0.9,0.6-1.4,1.8-1.3
			c-1.1-1-1-2.1-0.7-3.2c3.8-15.1,7.5-30.1,11.3-45.2c1.3-5.4,2.8-10.8,4-16.2c0.5-2.5,1.9-4.3,3.8-5.9c0.5,0.1,1.1,0.2,1.8,0.4
			c-0.1-0.6-0.2-1.1-0.3-1.8c4.3-3.2,8.7-6.6,13.2-10c0.4-0.3,1-0.4,1.4-0.7c0.5-0.3,1.2-0.7,1.3-1.1c0.2-1.1,1-1.6,1.7-2.2
			c4.1-3.3,8.2-6.6,12.3-9.8c0.3-0.3,0.7-0.5,0.9-0.8s0.5-0.8,0.4-1.1c-0.2-0.3-0.7-0.7-1.1-0.7c-2.3-0.1-4.7-0.1-7-0.1
			c-4.9,0-9.8,0-14.7,0c-0.4,0-0.8-0.1-1.3-0.2c-0.1-0.8-0.2-1.5-0.3-2.2c-1.5,0.1-0.9,1.4-1.2,2.3c-0.8,0-1.5,0-2.5,0
			c3.7-14.9,7.3-29.6,10.9-44.5c2,0.2,3.9-0.2,5.9,0.3c1.7,0.4,3.7-0.3,5.6-0.3c2-0.1,4,0,6,0c1.9,0,3.9,0,5.8,0c2,0,4,0,6,0
			c1.9,0,3.9,0,5.8,0c2,0,4,0,6,0c1.9,0,3.9,0,5.8,0c2,0,4,0,6.2,0c-0.2,1-0.3,1.8-0.5,2.6c-7.3,29.3-14.6,58.6-21.9,87.9
			c-2.7,10.7-5.3,21.4-8,32.1c-0.1,0.5-0.3,1.1-0.4,1.6c-0.3,1.5,0,2,1.6,2c0.8,0,1.5-0.1,2.3-0.1c20.3,0,40.5-0.1,60.8,0
			c4.6,0,9.1-0.2,13.7,0C726.96,310.83,727.66,310.83,728.76,310.83z M619.86,276.13c-0.1-0.4-0.2-0.8-0.3-1.5
			C618.86,275.63,619.36,275.83,619.86,276.13c-0.1,0.2-0.3,0.5-0.6,0.9c0.6,0.2,1.2,0.4,1.8,0.6
			C620.96,276.63,620.56,276.23,619.86,276.13z M620.16,290.03c-0.7,0.1-1.3,0.3-2.2,0.5c0.7,1,1.4,1.2,2.4,0.7
			C620.26,290.73,620.16,290.33,620.16,290.03c0.8-0.1,0.8-0.1,0.5-0.8C620.46,289.43,620.26,289.73,620.16,290.03z M616.56,274.83
			c-0.1,0.5-0.3,0.9-0.5,1.6c0.7-0.3,1.2-0.5,1.7-0.8c0-0.1,0-0.3,0-0.4C617.36,275.23,616.86,275.03,616.56,274.83
			c0.4-0.9-0.4-1.4-0.9-2c-0.2-0.2-0.7-0.2-1-0.3c0,0.4-0.1,0.9,0.1,1C615.26,274.13,615.86,274.43,616.56,274.83z M641.96,335.53
			c-1.6,0.1-1.9,0.4-2.2,1.9c0,0.2-0.2,0.5-0.2,0.7c-0.3,0.2-0.5,0.4-0.9,0.6c0.7,0.5,0.9,1,0.2,1.7c-0.9,0.8-1,2-1.2,3.2
			c0.9-0.7,2.1-1.1,2.2-2.4c0.1-1-0.1-2-0.2-3c0.9,0.3,1.2-0.2,1.5-0.9C641.36,336.63,641.66,336.03,641.96,335.53
			c0.2-0.6,0.5-1.1,0.9-1.9C641.76,334.13,641.76,334.13,641.96,335.53z M616.36,301.13c-0.2,0.4-0.4,0.8-0.6,1.2
			c0.3,0.6,0.6,1.2,0.9,1.6c0.2,0.2,0.6,0.3,0.9,0.5c0.1-0.3,0.3-0.7,0.1-0.9C617.36,302.73,616.86,301.93,616.36,301.13
			c0.2-1.5-1.1-3.2-2.9-3.8C613.26,299.33,614.26,300.53,616.36,301.13z M621.36,310.73c-0.2,1.2-1.6,2.3-0.7,3.7
			c2.3,0,2.6-0.4,1.6-2.2C621.96,311.63,621.66,311.23,621.36,310.73c0-0.9,0-1.7,0-2.5c-0.5-0.1-1-0.2-1.3-0.3
			c0.2-0.5,0.3-0.9,0.4-1.3c-1.3-0.3-2.1-2.2-3.7-1c0.5,1.9,1.9,2.8,3.4,3.3c-0.1,0.7-0.2,1.3-0.3,2
			C620.46,310.83,620.86,310.73,621.36,310.73z M622.56,269.23c-0.4-0.1-0.8-0.2-1.2-0.3s-2.3-1.8-2.2-1.9c1.1-1.1-0.5-1.6-0.5-2.4
			c-0.2-1.4,2.2-1.7,1.6-3.4c-0.5-0.1-1.1-0.5-1.5-0.4c-1.3,0.6-1.9-0.3-2.6-1.1c-0.7-0.7-1.3-1.4-2-2.1c-0.1-0.1-0.4-0.1-0.6-0.2
			c-0.3,0.4-0.5,0.9-0.8,1.3c-0.7-0.2-1.2-0.4-2.1-0.7c0.2,0.6,0.3,1.2,0.4,1.2c1.3-0.2,1.7,1.1,2.2,1.8c0.6,0.9,0.8,2.2,1.1,3.3
			c0,0.1-0.1,0.3-0.2,0.3c-0.9,0.7-0.5,1.6-0.1,2.2c0.5,0.7,1.1,1.8,2.3,1.1c-0.4,0.8-0.8,1.5-1.4,2.5c0.9-0.1,1.7-0.2,2.2,0.6
			c0.1,0.2,0.9-0.1,1.6-0.2c-0.6-1.4-1.2-2.4-2.4-2.9c1.1-0.8,1.7-0.1,2.2,0.8c0.3,0.4,0.7,0.9,1.1,1c0.7,0.2,1.4,0.1,2.2,0
			C622.06,269.73,622.26,269.33,622.56,269.23c0.9,0.1,1.8,0.2,2.6,0.4c0.2,0.5,0.4,1,0.6,1.4c0.1,0,0.2-0.1,0.2-0.1
			c0-0.6,0-1.2,0-1.6c-0.8-0.4-1.5-0.7-2.1-0.9C623.16,268.23,622.26,267.93,622.56,269.23z M614.66,282.83
			c-0.9-0.4-1.7-0.3-2.2,0.7c0.8,1.4,0.6,3-0.3,3.6c-0.4-0.7-0.4-0.7-1.3-0.9c-0.5,2.8-0.5,2.8,1.6,4.4c0.2,0.2,0.3,0.5,0.6,0.8
			c-1.5,0-1.5,0-2.9-0.9c-0.4,2.7,0.5,5.6-1.6,7.9c0.3,1.3,0.2,2.5,0,3.9c-0.2,1.2-0.6,2.4-0.9,3.5c-0.1,0.4-0.1,0.8-0.1,1.2
			s1,0.8,0.1,1.3c-0.4,0.2-0.8,0.2-1.4,0.3c0.6,2,2.5,0.7,3.3,1.2c0.5,2.3,0.9,4.4,1.2,6.4c0.8,0.2,1.5,0.4,2.6,0.7
			c-0.9-1.1-1.6-1.9-2.3-2.8c0.9-0.9,1.1-1.8,0.2-2.8c1.5-0.8,0.5-2.4,1.1-3.3c-0.9-0.4-1.6-0.8-2.3-1.1c0.7-2,2.2,0.4,3-1
			c0-0.1,0.1-0.4,0-0.7c-0.5-1.3-1.2-2.5-1.7-3.8c-0.2-0.4-0.2-1.1,0-1.3c1-1.2,0.3-2.3-0.2-3.5c1-0.1,1.4-0.6,1.3-1.5
			c-0.1-0.9,0-1.8,0.1-2.7c0-0.1,0.1-0.2,0.3-0.4c0.4,0.1,0.9,0.3,1.3,0.4c0.2-0.4,0.4-0.8,0.7-1.2c-0.3-0.4-0.7-0.8-1-1.2
			c0.5-0.6,1.1-1.2,1.6-1.8c-1-1.1-1-1.1-0.8-2.5c0.5,0,0.9-0.1,1.7-0.2c-0.6-0.8-1.1-1.2-1.5-1.8
			C614.66,283.53,614.76,283.13,614.66,282.83c0.9-1.2,1.7-2.4,2.7-3.6c-0.8-0.1-1.5-0.2-2.3-0.3c0.2,1.1-0.6,1.7-1.4,2.3
			C614.06,281.83,614.36,282.33,614.66,282.83z M643.26,324.33c-0.6-0.3-1-0.7-0.9-1.4c0.2-1.4,0-2.7,0.5-4.2c0.5-1.3,0.4-2.8,1.3-4
			c0.1-0.1,0.1-0.4,0.1-0.6c0.2-3.6,0.4-7.1,0.6-10.7c0-0.8,0.6-1.8,0.3-2.5c-0.7-1.4-0.2-2.3,0.6-3c0-1.5,0-2.8,0-4.2
			c-1.4-1.6-1.4-1.6-1.4-4.2c0-0.8,0.3-1.7-0.1-2.2c-0.9-1.4-0.5-2.8-0.6-4.2c-0.1-2.3,0-4.7,0-7c0-0.8-0.2-1.6,0.6-2.3
			c0.2-0.1-0.1-0.8-0.1-1.2c-0.2,0-0.3,0-0.5,0c-0.4,1.3-1.2,2.7-1,3.9c0.2,1.1,0,1.9-0.1,2.9c-0.1,2.8,0.1,5.6,0.2,8.4
			c0.1,1.6,0.6,3.2,0.4,4.8c-0.4,2.9,1.1,5.6,0.2,8.5c0,0.1,0.2,0.3,0.3,0.5c0.1,0.4,0.3,0.9,0.2,1.2c-0.6,1.1-0.4,2.3-0.5,3.4
			c-0.1,1-0.3,2-0.5,3c-0.4,1.9-0.1,3.9-0.6,5.8c-0.4,1.3-0.2,2.7-1.1,3.8c-0.2,0.2-0.3,0.7-0.2,1c0.2,1.3-0.5,2.2-1,3.2
			c-0.7,1.4-1.8,1.7-3.3,1.3c-0.5-0.1-1.2-0.1-1.8,0.1c-0.9,0.3-1.7,0.9-2.6,1.3c0.3,1.1,1.1,1,1.5,0.7c1.8-1.2,3.7-0.3,5.5-0.5
			c0.9,2,0.9,2,0.6,3.4c-0.6,0.2-1.2,0.5-1.9,0.7c-0.3-0.6-0.6-1.1-0.9-1.7c-0.6,0.2-1,0.4-1.5,0.6c-0.3,0.8-0.3,1.7,0.5,2
			c1,0.4,2.2,0.4,3.3,0.6c0.2-0.5,0.3-1.1,0.6-1.8c0.6,0.5,1,0.9,1.4,1.2c0.4,0.3,0.8,0.7,1.2,1c1.9-1.5,1.8-2.2-0.5-3.6
			C641.76,326.93,641.36,325.23,643.26,324.33c0.8-0.1,1.7-0.2,2.6-0.3c-0.1,0.7-0.2,1.2-0.2,1.7c1.3,0.4,1.2-0.7,1.4-1.1
			c0.4-1.1,0.9-1.7,2.2-1.7c0.9,0,1.8-0.5,2.6-0.8c0-0.1-0.1-0.3-0.1-0.4c-0.9,0.1-2-0.2-2.6,0.2c-1.1,0.9-1.4,0.2-1.8-0.6
			c-1,1.1-1.9,2-3.5,1.8C643.26,323.13,642.96,323.73,643.26,324.33z M612.36,330.43c0.5-1.3,0.8-2.7-0.2-3.8
			c-0.9-0.9-0.9-1.9-0.9-3c-0.1-0.9-0.4-1.8-0.4-2.8c0-1.2,0.3-2.4,0.5-3.7c-0.7,0-1.2,0-1.9,0c0.1,1,0.2,1.8,0.3,2.7
			c-0.2,0.1-0.5,0.2-0.6,0.3c0.3,1.7,1.6,3.2,0.6,5c0.3,0.2,0.6,0.3,1,0.4c-1.6,1.2,0.1,1.6,0.5,2.3c-0.5,0.7-0.9,1.2-1.3,1.9
			C610.86,330.03,611.56,330.23,612.36,330.43z M686.86,332.63c0.2-2.3,0.5-4.4,0.7-6.9c-0.6,0.3-1,0.4-1.1,0.6
			c-0.3,0.5,0.4,1.3-0.5,1.6c-0.1,0,0,0.5,0,0.8c0.4,1.5,0.3,1.7-1.1,2.1c-0.5,0.2-1,0.5-1.8,0.8c0.9,0.5,1.4,0.8,1.9,1.1
			c-0.2,0.5-0.5,1-0.6,1.3c-1,0.6-2.7-1.1-2.9,1.1c2.2,0.5,3,0.3,4-1.5C685.76,333.03,685.86,332.23,686.86,332.63z M619.76,282.93
			c1.1,0.1,1.9-1.3,3.1-0.4c0.1,0.1,0.5-0.2,0.7-0.3c1.1-0.5,1.4-0.2,1.4,0.9c0,0.3,0,0.6,0,1.1c0.4-0.1,0.8-0.1,0.9-0.2
			c0.3-1.6,0.6-3.3,0.9-5.3c-1,1-1.6,1.6-2.3,2.3c-0.6-0.3-1.3-0.6-1.8-0.8c-0.4,0.3-0.6,0.7-0.9,0.8
			C619.96,281.23,619.96,281.23,619.76,282.93z M627.76,278.23c-0.2-0.5-0.3-0.7-0.4-1c-0.5-1.1-1.4-2.1-0.8-3.5
			c0.2-0.7,0.1-1.5-1.2-1.7c0,0.9,0,1.7,0,2.9c-0.5-0.6-0.7-0.9-1.1-1.4c-0.4,0.8-1.4,1-1.3,2c0.4,1.4,3.2-0.4,2.7,2.1
			C626.26,277.83,626.86,278.03,627.76,278.23z M613.86,311.23c1,1.3,1.9,2.5,2.9,3.9c0.5-0.2,1.1-0.5,1.1-1.3
			c0-0.9-0.1-1.8-0.2-2.8c-0.5,0.1-1,0.3-1.4,0.4C615.46,310.13,615.36,310.13,613.86,311.23z M606.06,275.93
			c-0.1,0.1-0.2,0.3-0.3,0.4c0.5,0.7,0.9,1.9,1.5,2c1,0.2,2.1-0.4,3.2-0.6c-0.3-1.6-0.3-1.7-0.8-2.2
			C608.56,276.23,607.46,276.83,606.06,275.93z M615.76,306.53c-1.2-0.4-2-0.3-2.5,0.7c-0.3,0.8,0,1.5,0.7,2c1,0.9,1.8,0.3,3-0.5
			c-0.8-0.4-1.3-0.7-1.7-0.9C615.36,307.43,615.56,307.03,615.76,306.53z M608.76,282.73c-0.6-0.5-1.1-0.8-1.6-1.2
			c-0.1,0.8,0.1,1.4,0.5,1.8c0.5,0.5,1.2,0.9,1.8,1.2c0.3,0.1,1,0.1,1.2-0.1c0.3-0.4,0.5-1,0.4-1.4c-0.1-0.4-0.6-0.8-1-1.2
			C609.56,282.13,609.26,282.43,608.76,282.73z M620.16,295.23c-0.7,0.6-1.2,1-1.8,1.5c0.4,0.5,0.6,0.8,0.8,1c0.9-0.7,1.6-1.3,2.4-2
			c0.2-0.7-0.1-1.4-0.8-1.9c-0.4-0.3-0.7-1-1.3-0.5c-0.7,0.5,0,0.9,0.3,1.3C619.96,294.83,620.06,295.03,620.16,295.23z
			 M621.66,271.33c-2-0.6-2.9,0.2-3.7,1.6c1.2-0.2,1.7,1.6,3.1,0.7c-0.3-0.5-0.5-1-0.8-1.5
			C620.76,271.93,621.06,271.73,621.66,271.33z M626.36,325.43c-1.4,0.7-1.4,0.7-2.1,2.5c0.2,0.1,0.4,0.3,0.5,0.2
			c1.3-0.4,2.6-0.7,3.8-1.2c0.2-0.1,0.2-0.5,0.4-0.9C627.06,326.73,627.06,326.73,626.36,325.43z M664.16,332.33
			c1.4,0.3,2.3,1.3,3.2,2.5c0.4-0.6,0.6-1.1,0.9-1.6C666.76,332.83,665.76,331.43,664.16,332.33z M690.06,332.33
			c0.9,0.1,1.2,0.2,2.3,0.8c0.2,0.1,0.4,0.2,0.7,0.2c1.2,0.1,2-0.1,3.5-0.8c-1.2-0.1-2.3,0-3.3-0.3
			C692.06,331.93,691.06,332.03,690.06,332.33z M615.96,294.13c1.8-1.8,1.8-1.8,0.4-3c-0.4,0.5-0.8,1-1.2,1.6
			C614.66,293.53,615.36,293.83,615.96,294.13z M617.46,298.13c0.4,2.4,1.2,3.2,2.4,2.8c-0.3-0.5-0.6-1-0.7-1.6
			C618.86,298.43,618.46,297.83,617.46,298.13z M607.26,340.13c1,0.1,1.5,0.1,1.9,0.1c0.3,0,0.5-0.3,0.8-0.5
			c-0.2-0.2-0.3-0.5-0.5-0.7c-0.3-0.4-0.7-0.7-1.2-1.2C607.86,338.53,607.66,339.23,607.26,340.13z M630.96,354.03
			c0.4-1.4,0.7-2.7,1.1-4.3C630.06,351.13,629.66,352.83,630.96,354.03z M627.96,332.53c-0.5,0-1.1,0-1.6,0c0.3,0.7,0.4,1.4,0.7,2
			c0.2,0.5,0.6,0.9,0.9,1.5C627.96,334.73,627.96,333.73,627.96,332.53z M623.66,310.93c0.5-1,1-1.9,1.6-3.1c-1,0.5-1.7,0.8-2.5,1.1
			C623.06,309.73,622.36,310.73,623.66,310.93z M609.16,288.23c0-0.1,0-0.2,0-0.3c-1-0.2-2-0.3-3.1-0.5c0.2,0.6,0.3,0.9,0.4,1.3
			C607.36,288.53,608.26,288.43,609.16,288.23z M617.76,295.23c-0.7,0-1.3,0-2.2,0c0.2,0.7,0.3,1.3,0.4,2
			C617.46,296.33,617.46,296.33,617.76,295.23z M609.86,348.43c-1.7-0.6-1.8-0.5-2.9,1.6C608.16,349.43,609.06,348.93,609.86,348.43
			z M649.66,328.93c-0.9,0.3-1.5,0.6-2.2,0.8c0.2,0.5,0.4,1,0.6,1.6C648.76,330.53,649.56,330.23,649.66,328.93z M631.96,276.73
			c-0.7,0.3-1.2,0.6-1.5,0.7c0,0.8,0,1.4,0,2c0.4,0,0.8,0,1.2,0C631.76,278.53,631.86,277.63,631.96,276.73z M608.16,269.83
			c0.8,1.4,1.6,1.5,2.6,0.9C610.26,269.43,609.26,270.03,608.16,269.83z M605.56,307.73c-1-0.3-1.7-0.1-2,0.8c0.6,0.2,1,0.4,1.5,0.7
			C605.26,308.73,605.36,308.23,605.56,307.73z M630.36,273.23c0.8,0.7-0.8,2,0.8,2.5C631.56,273.63,631.56,273.63,630.36,273.23z
			 M636.16,345.13c-0.3-1.1-0.5-1.7-0.6-2.4c-0.5,0.5-1,1-1.6,1.7C634.66,344.63,635.36,344.83,636.16,345.13z M633.56,278.83
			c1.3-0.9,0-1.5,0.2-2.3C632.46,277.33,633.06,278.03,633.56,278.83z M631.26,295.83c0.4,0.8,0.7,1.4,1.2,2.2
			C632.66,295.93,632.66,295.93,631.26,295.83z M616.06,316.73c0.5,1.6,1.4,1.7,2.3,1.7c0.1-0.1,0.1-0.3,0.2-0.4
			C617.86,317.73,617.26,317.33,616.06,316.73z M687.26,337.23c-0.3,0.5-0.5,1.1-0.8,1.6c0.3,0.2,0.5,0.5,0.8,0.7
			c0.3-0.2,0.5-0.5,0.8-0.7C687.76,338.33,687.56,337.73,687.26,337.23z M687.56,345.43c-1.1,0-1.6,0.3-1.4,1.2
			c0,0.2,0.3,0.3,0.6,0.6C686.96,346.63,687.16,346.13,687.56,345.43z M628.66,320.63c0.1,1.6,0.2,1.7,1.3,1.4
			C629.46,321.63,629.06,321.13,628.66,320.63z M618.96,220.53c0.7,0.7,0.9,1.5,2,1.1C620.26,221.23,620.56,219.63,618.96,220.53z
			 M621.56,304.03c-1.2-0.2-1.3,0.4-1.1,1.1c0,0.2,0.5,0.3,0.8,0.4C621.36,305.03,621.46,304.53,621.56,304.03z M635.16,351.93
			c-0.1-0.8-0.1-1.4-0.2-2C634.46,350.63,633.36,351.33,635.16,351.93z M596.56,335.23c0-1.2-0.7-1.1-1.3-1
			c-0.2,0.1-0.2,0.6-0.3,1.1C595.56,335.33,596.06,335.33,596.56,335.23z M631.56,326.23c-1.8-0.2-1.8-0.2-1.9,0.8
			C630.16,326.83,630.66,326.63,631.56,326.23z M672.16,325.93c-1.4,0.6-1.4,0.6,0,2C672.16,327.23,672.16,326.73,672.16,325.93z
			 M617.76,326.83c-0.9-0.4-1.6-0.4-2,0.4c-0.1,0.2,0.1,0.6,0.2,1.1C616.76,327.83,617.26,327.33,617.76,326.83z M631.36,310.23
			c-0.8,0.3-1.4,0.4-1.9,0.6c0,0,0.1,0.6,0.2,0.7C630.66,311.93,630.86,311.13,631.36,310.23z M649.76,317.43
			c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2,0.4-0.4,0.8-0.6,1.2c0.2,0.1,0.6,0.3,0.6,0.3C649.36,318.23,649.56,317.83,649.76,317.43z
			 M684.46,353.83c0.7,0.2,1.2,0.3,1.8,0.4C685.66,352.73,685.66,352.73,684.46,353.83z M613.96,336.93c0.1,0,0.3-0.1,0.4-0.1
			c-0.1-0.5,0.4-1.5-1.1-1.2C613.56,336.13,613.76,336.53,613.96,336.93z M710.06,316.43c1.1,0.8,1.4-0.2,2.1-0.4
			c-0.1-0.1-0.1-0.3-0.2-0.4C711.36,315.93,710.76,316.23,710.06,316.43z M606.46,316.83c0.3-0.1,0.7-0.3,1-0.4
			c0.3-0.1,0.6-0.2,0.9-0.3C607.66,315.93,606.76,315.33,606.46,316.83z M608.46,311.83c-1.3-0.2-1,0.9-1.4,1.5
			C608.56,313.63,608.06,312.23,608.46,311.83z M601.86,340.93c-0.1,0.1-0.3,0.3-0.4,0.4c0.3,0.3,0.5,0.7,0.8,1c0,0,0.3-0.1,0.7-0.3
			C602.56,341.53,602.26,341.23,601.86,340.93z M612.06,271.93c-0.3,1,0.2,1.2,1,1.1c0.2,0,0.3-0.2,0.6-0.3
			C613.06,272.43,612.66,272.23,612.06,271.93z M675.06,324.53c0-0.1,0-0.2,0-0.3c-1,0-2,0-3.1,0c0,0.1,0,0.2,0,0.3
			C673.06,324.53,674.06,324.53,675.06,324.53z M623.26,284.23c-0.1,0.1-0.1,0.3-0.2,0.4c0.4,0.3,0.6,1.3,1.8,0.6
			C624.26,284.73,623.76,284.43,623.26,284.23z M641.06,285.53c-1.4,0.2-1.2,0.9-1,2C640.46,286.73,640.66,286.23,641.06,285.53z
			 M651.96,265.53c-0.1-0.1-0.2-0.3-0.2-0.4c-0.5,0.3-0.9,0.6-1.5,1C651.36,266.83,651.56,265.83,651.96,265.53z M622.16,317.43
			c1.3-0.1,1.3-0.1,1.8-1.4C623.16,316.63,622.76,316.93,622.16,317.43z M606.76,319.73c0.1-0.4,0.3-0.8,0.5-1.4
			C605.96,318.43,606.36,319.03,606.76,319.73z M599.06,332.53c0.5,0.3,1.1,0.6,1.9,1C600.66,332.23,600.26,331.83,599.06,332.53z
			 M614.96,316.23c-0.2,0.1-0.3,0.3-0.5,0.4c0.3,0.3,0.6,0.5,0.9,0.8c0.1-0.1,0.2-0.2,0.3-0.3
			C615.46,316.93,615.26,316.53,614.96,316.23z M672.96,351.23c0.3,0.2,0.5,0.5,0.9,0.8C674.26,351.03,673.96,350.73,672.96,351.23z
			 M624.76,314.93c-0.7-1-1.2-0.7-1.9,0.2C623.66,315.03,624.16,315.03,624.76,314.93z M687.56,349.63c-0.3-0.4-0.6-0.7-0.9-1.1
			C686.46,349.63,686.46,349.63,687.56,349.63z M612.96,311.73c-0.5,0.9-0.2,1.2,0.8,0.9C613.36,312.33,613.16,312.03,612.96,311.73
			z M610.16,279.03c0.3,0.3,0.6,0.6,1,0.9c0.1-0.5,0.3-0.9,0.5-1.6C610.86,278.63,610.46,278.83,610.16,279.03z M631.16,286.13
			c0-1.1,0-1.1-1-0.9C630.56,285.53,630.86,285.73,631.16,286.13z M593.06,334.43c0.4,0.4,0.7,0.6,1.1,1
			C594.26,334.43,594.16,334.03,593.06,334.43z M625.66,263.43c0.2-1,0.2-1-0.9-0.9C625.16,262.83,625.36,263.13,625.66,263.43z
			 M619.46,224.73c-0.1,1-0.1,1,0.9,1C619.96,225.33,619.76,225.03,619.46,224.73z M652.06,272.63c-1-0.1-1-0.1-0.9,0.9
			C651.56,273.23,651.76,273.03,652.06,272.63z M629.16,282.13c-1.3-0.1-1.3-0.1-1.2,0.8C628.26,282.73,628.46,282.53,629.16,282.13
			z M626.56,284.33c0.1,0.1,0.2,0.2,0.3,0.3c0.2-0.2,0.4-0.5,0.6-0.7c-0.1-0.1-0.4-0.3-0.4-0.3
			C626.86,283.83,626.66,284.13,626.56,284.33z M679.86,345.93c0.1-0.9-0.1-1.4-1.2-1.2C679.16,345.23,679.46,345.63,679.86,345.93z
			 M627.36,343.73c-0.1,0.3-0.3,0.5-0.3,0.6s0.2,0.3,0.3,0.4c0.1-0.2,0.3-0.3,0.3-0.5C627.66,344.23,627.46,344.03,627.36,343.73z
			 M631.46,343.43c-0.1-0.3-0.1-0.4-0.2-0.6c-0.2,0.1-0.4,0.1-0.4,0.2c0,0.2,0,0.4,0.1,0.5
			C630.96,343.53,631.26,343.43,631.46,343.43z M669.86,341.73c0.2-0.1,0.4-0.1,0.4-0.2c0-0.2-0.1-0.3-0.1-0.5
			c-0.1,0-0.3,0.1-0.3,0.1C669.76,341.33,669.86,341.53,669.86,341.73z M676.26,348.43c0.2-0.2,0.3-0.4,0.3-0.5s-0.2-0.3-0.3-0.4
			c-0.1,0.1-0.3,0.2-0.3,0.4C675.96,348.03,676.16,348.13,676.26,348.43z M630.66,199.73c0.1,0.2,0.2,0.4,0.3,0.6
			c0.1-0.1,0.3-0.2,0.4-0.3c0-0.1-0.1-0.3-0.2-0.5C631.06,199.53,630.86,199.63,630.66,199.73z M654.46,352.53c0-0.2,0-0.3,0-0.5
			c-0.3,0.1-0.6,0.1-0.8,0.2c0,0.1,0.1,0.3,0.1,0.3C653.96,352.63,654.26,352.63,654.46,352.53z M644.66,327.83c-0.1,0-0.2,0-0.2,0
			c0,0.6,0,1.3,0,1.9c0.1,0,0.2,0,0.2,0C644.66,329.13,644.66,328.43,644.66,327.83z M624.76,325.43c0.1-0.2,0.2-0.3,0.3-0.4
			c-0.1-0.1-0.3-0.3-0.4-0.3c-0.1,0-0.3,0.2-0.4,0.3C624.46,325.13,624.56,325.23,624.76,325.43z M604.86,324.03
			c0.2-0.2,0.3-0.4,0.4-0.5c0-0.1-0.1-0.3-0.2-0.3c-0.2,0-0.4,0.1-0.4,0.2C604.66,323.53,604.76,323.73,604.86,324.03z
			 M656.56,321.73c-0.2,0.1-0.4,0.2-0.5,0.3c0.1,0.1,0.2,0.4,0.3,0.4c0.2,0,0.3-0.1,0.5-0.2
			C656.76,322.03,656.76,321.93,656.56,321.73z M606.46,321.83c-0.1,0.3-0.2,0.5-0.2,0.6s0.2,0.2,0.4,0.3c0.1-0.2,0.2-0.3,0.2-0.5
			C606.86,322.23,606.66,322.03,606.46,321.83z M634.76,321.23c-0.1,0.3-0.3,0.4-0.3,0.6c0,0.1,0.2,0.3,0.3,0.4
			c0.1-0.1,0.3-0.3,0.3-0.4C635.06,321.63,634.96,321.43,634.76,321.23z M624.46,257.43c0.2-0.3,0.3-0.4,0.3-0.6
			c0-0.1-0.2-0.3-0.3-0.4c-0.1,0.1-0.3,0.3-0.3,0.4C624.16,257.03,624.26,257.23,624.46,257.43z M623.16,259.33
			c0.2-0.3,0.3-0.4,0.3-0.5s-0.2-0.3-0.2-0.4c-0.1,0.1-0.3,0.2-0.3,0.4C622.86,258.83,623.06,259.03,623.16,259.33z M614.96,319.93
			c-0.6-0.2-0.8-0.4-0.9-0.4c-0.1,0-0.3,0.3-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4C614.26,320.33,614.36,320.13,614.96,319.93z
			 M646.86,260.33c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4
			C646.56,260.73,646.66,260.53,646.86,260.33z M605.16,317.93c-0.3-0.1-0.4-0.3-0.6-0.3c-0.1,0-0.3,0.2-0.4,0.3
			c0.1,0.1,0.3,0.3,0.4,0.3C604.76,318.23,604.96,318.13,605.16,317.93z M627.66,315.43c0.3,0.2,0.4,0.3,0.6,0.3s0.3-0.2,0.4-0.3
			c-0.1-0.1-0.3-0.3-0.4-0.3C628.16,315.13,627.96,315.33,627.66,315.43z M609.46,268.53c0.3,0.2,0.4,0.3,0.5,0.3
			c0.1,0,0.3-0.2,0.4-0.3c-0.1-0.1-0.3-0.3-0.4-0.3C609.86,268.23,609.76,268.43,609.46,268.53z M652.06,268.63
			c-0.2-0.2-0.3-0.4-0.5-0.4s-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.3,0.3,0.4C651.56,268.93,651.76,268.73,652.06,268.63z M632.06,280.73
			c-0.6-0.3-0.8-0.4-1-0.4c-0.1,0-0.3,0.2-0.3,0.4c0,0.1,0.2,0.4,0.3,0.4C631.26,281.13,631.46,281.03,632.06,280.73z
			 M629.06,286.53c-0.4-0.2-0.5-0.4-0.6-0.4c-0.2,0-0.3,0.1-0.5,0.2c0.1,0.1,0.2,0.4,0.3,0.4
			C628.36,286.73,628.56,286.63,629.06,286.53z M640.56,288.43c-0.2-0.2-0.3-0.3-0.4-0.5c-0.1,0.1-0.3,0.2-0.3,0.4
			c0,0.1,0.2,0.3,0.3,0.4C640.26,288.73,640.46,288.63,640.56,288.43z M630.26,291.83c-0.2-0.1-0.4-0.2-0.5-0.2
			c-0.1,0-0.2,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.4C629.96,292.23,630.06,292.13,630.26,291.83z M605.36,294.33
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0.2,0.2,0.4,0.3C605.06,294.63,605.16,294.53,605.36,294.33z"/>
		<path class="st0-bull-white" d="M86.56,108.33c-0.4,0-0.9,0-1.3-0.1c0-0.1,0-0.3,0-0.4c0.4,0,0.9-0.1,1.3-0.1
			C86.56,108.03,86.56,108.23,86.56,108.33z"/>
		<path class="st0-bull-white" d="M50.26,106.23c0.4-1.7,1.4-1.8,2.8-2.2C52.06,104.83,51.36,105.43,50.26,106.23z"/>
		<path class="st0-bull-white" d="M222.36,352.63c0,0.4,0.1,0.8,0.1,1.2C221.86,353.43,221.86,353.23,222.36,352.63
			C222.46,352.63,222.36,352.63,222.36,352.63z"/>
		<path class="st0-bull-white" d="M69.86,93.93c-0.3-0.9-0.3-0.9,0.7-1.3C70.26,93.13,70.06,93.53,69.86,93.93z"/>
		<path class="st0-bull-white" d="M222.46,352.63c0.1-0.5,0.1-0.9,0.2-1.5c-0.7-0.2-1.3-0.4-2.1-0.6c0.2-1-0.7-2.3,0.8-2.9
			c0.9,0.4-0.7,1.6,0.6,1.7c0.6-0.7,1.1-1.4,1.7-2.2c-0.5-0.1-1.1-0.3-1.7-0.5c-0.3-0.7-0.5-1.5-0.9-2.3c-0.4-0.7-0.8-1.4-1.3-2.1
			c0.3-0.2,0.6-0.3,1.2-0.6c-1.7-0.8-0.9-2.2-1.2-3.6c0.5,0,0.9,0.1,1.4,0.1c0.2-1.2,0.3-2.4,0.5-3.6c-0.1,0-0.2-0.1-0.4-0.1
			c0.4-0.3,0.8-0.6,1.3-1c-1-0.9-1.7-1.8-1.2-3.1c0.3,0,0.6-0.1,0.7-0.1c-0.2-1.9-0.4-3.7-0.6-5.9c-0.7,0.4-1.5,0.7-1.9,1.2
			c-3.1,4-6,8.1-9.2,12.2c0.9,0.8,1.6,1.6,2.4,2.3c0.7,0.6,1.4,1.2,0.7,2.3c-0.2,0.3,0.3,1.1,0.1,1.4c-0.3,0.5-0.4,0.8-0.2,1.4
			c0.2,0.5-0.2,1.3-0.2,2c0.1,0.7,0.4,1.3,0.7,2.1c-0.9,0.1-1.5,0.1-2.1,0.1c0-0.5,0.1-1.2-0.1-1.4c-0.5-0.4-1.2-0.4-1.8-0.6
			c-0.1,0.1-0.2,0.3-0.2,0.4c0.3,0.4,0.6,0.7,0.9,1.1c-0.9,1.2-1.7-0.1-2.6,0.1c-0.1-0.5-0.2-1-0.3-1.5c-0.3,0-0.7-0.2-0.8-0.1
			c-1.2,0.9-2.3,0.2-3.4-0.1c-1.7-0.6-3.4-1.2-5.3-1.8c0.3-1.2,0.7-2.3,0.8-3.4c0.1-1.2,1.1-2.1,1.1-3.4s0.7-2.6,1-4
			c0.4-1.3,0.7-2.5,1-3.8c0.4-1.3,0.8-2.7,1.1-4c0.4-1.3,0.6-2.5,1.1-3.8c0.4-1.3,0.4-2.4-0.4-3.5c-1-1.4-1.8-3-2.7-4.4
			c-0.5-0.7-0.6-1.4,0.6-2.3c-0.9,0.1-1.2,0.2-1.7,0.3c-0.4-0.6-0.7-1.2-1.2-1.8c-0.6-0.8-0.6-1.6-0.3-2.6c0.3-1.2,1-1.5,2-1.6
			c0.7-0.1,1.4-0.3,2.1-0.6c0.3-0.1,0.6-0.4,1.3-0.9c-1.1-0.1-1.7-0.1-2.2-0.1s-1.1,0-1.8,0c0.1-0.6,0.1-1.1,0.1-1.6
			c-0.1,0-0.2-0.1-0.4-0.1c-0.3,0.5-0.7,1-1,1.6c-0.9,1.5-2.3,1.7-3.2,0.2c-0.7-1.2-1-2.4-0.4-4c1-2.9,1.3-6.1,1.1-9.2
			c0-1.2-0.3-2.5,0.6-3.6c0.2-0.3,0.1-0.8,0.1-1.2c0-2.3-0.1-4.6,0.5-6.9c0.1-0.3,0-1-0.2-1.1c-1.9-1-1.2-3-1.3-4.4
			c-0.1-2-0.3-4.1-0.3-6.1c0-0.9-0.1-2.6,0-3.6c0-1-0.1-2-0.2-3c0-0.1-0.3-0.2-0.7-0.4c-0.1,0.5-0.2,1-0.2,1.4c0,3.1,0,6.3,0,9.4
			c0,0.9-0.3,1.8,0.6,2.5c0,0,0.1,0.1,0.1,0.2c-0.1,3.4,0.5,6.8-0.3,10.2c-0.1,0.3,0.1,0.7,0.1,1.1c0,0.9,0,1.9,0,2.8
			c0,0.6-0.4,1.1-0.4,1.7c-0.2,3.1-0.4,6.3-0.6,9.4c0,0.3-0.2,0.5-0.4,0.9c-0.4-0.4-0.7-0.6-0.8-0.9c-10-16.8-20-33.6-30-50.3
			c-0.1-0.2-0.3-0.5-0.4-0.7c-1.6-2.3-2.6-4.8-3.2-7.5c-1.2-6-2.6-12-3.8-18c-0.1-0.6-0.2-1.2-0.3-2.2c1.5,0.8,2.8,1.4,4,2.1
			c6,3.2,12,6.5,18.1,9.6c1.1,0.6,2.1,1.5,3.4,1.9c1.4,0.4,2.6,1.3,3.8,2c1.2,0.6,2.5,1.3,3.7,1.9c1.3,0.7,2.5,1.4,3.8,2.1
			c1.2,0.7,2.4,1.3,3.6,2c0.4,0.2,0.9,0.4,1.3,0.6c1.2,0.4,1.9-0.1,1.6-1.4c-0.3-1.4-0.7-2.8-1-4.2c-0.6-2.6-1.5-5.1-1.8-7.8
			c-0.2-2.1-1.5-4.1-1.8-6.3c-0.3-2-1.1-3.8-1.5-5.7c-0.3-1.2-1-1.7-2.1-1.9c-2.4-0.4-4.9-0.9-7.3-1.3c-5.4-0.9-10.9-1.8-16.3-2.7
			c-4.5-0.7-9-1.5-13.4-2.3c-0.2,0-0.4-0.1-0.7-0.2c-0.7-3.2-1.5-6.5-2.2-9.8c-1.8-8.6-3.7-17.3-5.5-25.9c-0.3-1.4-0.9-2.3-2.4-2.6
			c-2.3-0.5-4.5-1.2-6.8-1.9c-0.7-0.2-1.3-0.9-1.7-1.2c-1.3-0.2-2.3-0.1-3.3-0.4c-5.1-1.6-10.1-3.2-15.2-4.9
			c-4.7-1.6-9.5-3.3-14.2-5c-1.9-0.7-3.8-1.4-5.7-2.2c1.6-2,3.1-4,4.7-6.1c-1.8-0.5-2.9,0.4-3,2.3c-1.1-0.3-1.6,0.8-2.3,1.2
			c-0.6,0.3-1,0.9-1.6,1.1c-1.1,0.5-1.9,0.1-2.2-1.3c-0.4,0.2-0.9,0.6-1.2,0.5c-2-0.8-4.2-1.2-6-2.7c0.2-0.6,0.4-1.1,0.6-1.5
			c-0.1-0.2-0.2-0.3-0.2-0.3c-0.7,0.3-1.5,0.5-1.6,1.7c-0.6-0.3-1.1-0.5-1.8-0.8c0.5-0.4,0.8-0.6,1.1-0.8c-0.5-0.8-1.1-1-1.9-0.6
			c-0.5,0.2-1.1,0.7-1.4,0.6c-0.7-0.3-1.3-0.9-1.8-1.6c-0.1,0-0.3-0.1-0.4-0.1c-0.9,0.2-1.9,0.4-2.4-0.6c-0.4-1,0.5-1.6,1.2-2.2
			c0.8-0.7,1.2-2,2.6-1.9c0.7-1.3,1.3-2.6,2-4c0.1-0.3,0.1-0.7,0.1-1c1-0.5,1,0.4,1.2,0.8c0.7-0.1,1-0.8,0.6-2
			c1.7,0.1,2.6-1.2,3.8-2c1.6-1.1,2.3-2.9,3.8-4c-0.9-0.1-1.7,0-2,1c-0.4,1-1.2,0.9-2,0.9c0.5-2.8,1.6-5,6.4-5
			c-0.6-0.3-1-0.4-1.4-0.7c0-0.6-0.1-1.3-0.1-2.2c0.4,0.1,0.7,0.2,1,0.2c1.2,0.1,1.7-0.2,1.8-1.4c0.1-1.1,0.6-1.9,1.2-2.8
			c0.8-1.3,1.7-2.6,2.6-3.7c0.4-0.5,0.4-1.3,0.6-1.9c-1.5-0.3-1.5-0.3-1.9,1.1c-0.1,0.3-0.5,0.5-0.6,0.7c-0.1,0.3-0.1,0.7-0.2,1.1
			c-0.6,0.1-1.2,0.2-1.9,0.2c0.1,0.9,0.4,1.9-1,2.3c-0.4,0.1-0.5,0.8-0.7,1.1c-1-0.1-2.1-0.2-3.1-0.3c-0.7-1-0.7-1-1.5,0
			c0.5,0,1,0,1.5,0c0.3,0.8,0.7,1.7,1,2.6c-0.8,0.5-1.5,0.9-2.3,1.4c0.3,0.5,0.6,1,0.9,1.5c-0.3,0.2-0.7,0.5-1,0.5
			c-1.8-0.2-2.8,0.6-3,2.4c0,0.2-0.3,0.4-0.5,0.6c0.8,1,1.4,2.1,0.4,3.4c-0.3-0.1-0.7-0.3-1.1-0.4c-0.5-0.1-1.2-0.2-1.4,0
			c-0.8,1.2-2.1,0.7-3.5,1c0.4,1.3,0.7,2.6,1.2,4.4c-0.9-0.6-1.3-0.9-1.8-1.3c-0.7,1.4-0.8,1.5,0.5,2.2c0.6,0.3,0.9,0.6,0.5,1.2
			c-1.5,0.9-2.5-0.9-3.8-0.6c-0.7-1.8,0.8-1.4,1.6-1.8c0.2-0.8,0.4-1.6,0.5-2.4c-0.8-0.3-1.5-0.5-2.2-0.8c0.1-0.2,0.1-0.4,0.1-0.5
			c0.6-0.9,2-0.6,2.5-1.9c0.4-0.9,1.3-1.5,1.9-2.1c1.4,0.9,2.6,0.7,4,0.3c-0.3-1.5-1.3-1.3-2.2-1.3c-0.9,0-1.8,0-3,0
			c0.5-2.7,2.8-3.6,4.1-5.4c-0.7-0.5-1.6-1.2-2.6-1.9c-0.9,1-0.4,2.7-1.2,3.9c-1.2,0.3-0.9-0.8-1.2-1.3c-0.8,0.1-1.6,0.2-2.4,0.2
			c-0.9,1.3-2,2.5-0.8,4.1c-1.1,0.8-1.7,0.7-1.7-0.3c0-0.9,0.1-1.7-0.4-2.5c-0.2-0.4,0.2-1.2,0.2-1.8c0-0.5-0.2-1-0.6-1.5
			c0.4-0.7,0.7-1.4,1.2-2.3c1.5,0,3.1,0,4.7,0c0.2,0.2,0.4,0.5,0.7,0.6c0.1,0,0.3-0.2,0.5-0.4c-0.2-0.1-0.4-0.2-0.6-0.3
			c0.2-0.6-0.9-1.3,0-2c0.1-0.1-0.2-0.8-0.5-1.1c-0.9-0.9-0.9-1.1,0.2-1.8c0.4-0.2,0.6-0.6,1-1c1-1,1.4-2.8,3.5-2.4
			c0.6,0.1,0.9-0.7,1.2-2.4c-0.6,0-1.2,0.1-1.9,0.1c0.1-0.5,0.2-0.9,0.3-1.3c-0.5,0-1,0-1.8,0c0.4,0.3,0.7,0.5,0.9,0.7
			c-1.1,2-1.1,2-2.1,2.6c-0.1-0.3-0.2-0.6-0.3-0.9c-0.5,0.3-1.1,0.5-1.5,0.9s-0.5,1-0.8,1.7c-0.5-0.5-1-0.9-1.5-1.4
			c0.9-0.9,1.8-1.7,2.8-2.7c-0.6-0.8-1.1-0.5-1.8,0.1c-0.7,0.6-1.4,1.5-2.6,0.9c-0.1-0.4-0.2-0.9-0.4-1.4c-0.8,0-1.5,0-2.6,0
			c0.9,0.7,2.3,0.6,2.1,2c-1,0.3-2,0.6-3,0.9c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1,0.1-0.2,0.1-0.4,0.2c0,0.6,0,1.1,0,1.7
			c1.1-0.1,2.1-0.2,3.2-0.3c0,0.1,0.1,0.2,0.1,0.4c-0.8,0.5-1.7,1.1-2.9,1.8c-2.2-0.3-3.4,0.2-3.8,1.8c-0.6-0.6-1.2-1.1-1.9-1.7
			c0.3-1.2-0.1-2.6,0.7-3.8c0.1-0.2,0.1-0.6,0-0.9c-0.4-1.9-0.2-3.6,1-5.2c0.2-0.2,0.5-0.5,0.4-0.7c-0.5-2.5,1.9-4.3,1.9-6.7
			c0-1.1,0.9-2.2,1.4-3.3c0.2-0.5,0.3-1,0.6-1.4c0.2-0.4,0.9-0.9,1-0.8c0.9,0.8,1.4-0.5,2.2-0.4c2.5,1.7,5,3.4,7.5,5.1
			s5,3.4,7.6,4.9s5.3,2.7,8,4.1c-0.2,0.5-0.3,0.8-0.4,1.2c1.5-0.5,2.7,0,3.9,0.6c1,0.5,1.2,2.1,0.5,3c-0.3,0.4-0.8,0.8-1.2,1.2
			c0.5,0.3,1,0.6,1.7,1c0-0.5,0-0.9,0-1.5c0.9-0.2,2-0.3,1.7-1.6c1.9-0.1,1.9-0.1,2.6,1.7c-0.4-0.2-0.9-0.4-1.7-0.8
			c0.5,0.7,0.8,1.1,1,1.4c0.3-0.3,0.5-0.5,0.7-0.7c0.2-0.1,0.5-0.4,0.6-0.3c1.2,0.8,2.5,1.3,4,1.4c0.4,0,0.8,0.3,1.2,0.4
			c6.5,2.7,13,5.3,19.5,8c1.2,0.5,2.3,0.3,3.4-0.1c3.9-1.5,7.7-2.9,11.8-4.4c1,0.9,2.1,1.8,3.3,2.7c1.1,0.9,2.3,1.8,3.3,2.8
			c1.4,1.5,2.9,1.5,4.7,0.9c3.5-1.2,7.1-2.3,10.6-3.4c14.2-4.5,28.4-9,42.6-13.6c7.6-2.4,15.1-4.9,22.6-7.3c0.4-0.1,0.8-0.2,1.2-0.3
			c2.1,1.5,4.1,3.1,6.2,4.6c1.9,1.3,1.9,1.3,3.8,0c1.7-1.2,3.5-2.4,5.2-3.8c0.8-0.6,1.5-0.7,2.4-0.4c16.5,5.6,32.9,11.1,49.4,16.7
			c7.5,2.5,15,5,22.5,7.7c1.7,0.6,2.9,0.4,4.3-0.9c2-1.9,4.3-3.7,6.6-5.6c3.7,1.4,7.6,2.7,11.5,4.2c1.6,0.6,2.9,0.7,4.5,0
			c11.9-4.6,23.5-10,34.7-16.2c8.8-5,17.2-10.5,24.9-17c3.2-2.7,5.8-5.8,8.6-8.8c2.1-2.2,3.6-4.8,5.2-7.4c0.9-1.4,1.1-3.2,2.6-4.1
			c0.4-0.3,0.8-0.6,1.3-0.8c-0.3-0.3-0.8-0.5-0.9-0.9c-0.1-0.4,0-1,0.2-1.5c0.2-0.8,0.5-1.6,0.6-2.4c0.1-0.5,0-0.9,0.7-1.2
			c0.8-0.3,0.5-1.3,0.2-2c-0.9-1.9-0.9-2.4,0.7-3.7c0.2-0.2,0.2-0.6,0.3-0.8c-0.5,0-0.9,0-1.6-0.1c-0.2-1.2-0.4-2.5-0.7-3.9
			c0.7-0.1,1.4-0.1,2.1-0.2c-1.2-1.6-2.3-3-3.3-4.5c0.1-0.2,0.3-0.4,0.4-0.5c-0.7-1.2-1.4-2.4-2.1-3.6l0,0c0.5-0.3,1.1-0.6,1.6-0.9
			c-0.1-2.3-0.6-4.4-2-6.2l0,0c0.6-0.2,1.1-0.5,1.7-0.7c0.2,0.5,0.3,0.9,0.5,1.3c2.7-0.9,5.3-1.8,7.3-4c2.4,1.7,4.6,3.4,7.1,5.2
			c-0.3,0.2-0.7,0.4-1.4,0.8c0.8,0.2,1.1,0.4,1.4,0.4s0.6-0.2,0.9-0.3c0,0.2,0,0.6,0.2,0.7c0.6,0.3,1.2,0.4,1.8,0.7
			c-0.6,0.5-1.1,0.8-1.7,1.3c0.7,0.5,1.1,0.8,1.8,1.3c-0.9,0.2-1.5,0.4-2.1,0.5c-0.1,0.4-0.1,0.8-0.3,1.6c0.6-0.5,0.8-0.7,1.4-1.2
			c-0.2,1.4-0.3,2.6-0.5,3.9c1.4-0.2,1-2,2.3-2c-0.2,0.8-0.4,1.5-0.6,2.3c-0.3,0.1-0.6,0.2-0.9,0.3c0.2,0.4,0.4,0.8,0.6,1.2
			c-0.4,0.1-0.9,0.2-1.5,0.3c0.4,0.5,0.7,0.8,1,1.1c0.2-0.6,0.4-1,0.6-1.4c0.1-0.5,0.2-1,0.2-1.6c0.7-0.4,1.4-0.8,2.3-1.3
			c-0.8-0.4-1.2-0.7-1.7-0.9c-1.5-0.7-1.1-1.8-0.6-3c0.6-0.1,1.2-0.3,2.1-0.5c0.3,0.5,0.6,1.1,0.8,1.7c-0.4,0.2-0.8,0.4-1.1,0.7
			c-0.1,0.1,0.1,0.4,0.2,0.7c0.2-0.1,0.6-0.1,0.6-0.3c0.2-0.3,0.2-0.7,0.3-1.1c1,0.6,1.9,1.2,2.9,1.8c-0.2,0.4-0.2,0.6-0.4,0.7
			c-0.6,0.5-1.4,0.9-1.8,1.5c-0.4,0.6-0.5,1.5-0.8,2.3c-0.1,0.3-0.5,0.6-0.7,0.9c-0.6,0.7-1.2,1.4-1.6,2.2c-0.1,0.2,0.3,0.6,0.4,1
			c0.3-0.1,0.8-0.2,0.8-0.4c0.2-2.2,2.4-3,3-4.9c0.3-0.9,1.3-1.7,1.7-3c0.7,0.7,1.3,1.1,1.8,1.6c1.7,1.6,3.6,3.1,5,4.9
			c2.3,3.2,5.3,6,7.1,9.6c1.3,2.6,3.1,5,4.4,7.7c1,2.2,1.8,4.5,2.4,6.8c0.3,1.1,0,2.4,0,3.6c-0.2,0.1-0.4,0.2-0.5,0.3
			c-0.2,0.3-0.3,0.6-0.4,1c0.2,0.1,0.5,0.2,0.5,0.2c0.2-0.3,0.4-0.6,0.5-0.9c0-0.1-0.1-0.4-0.2-0.5c1,1.1,0.7,2.5,0.5,3.7
			c-0.1,0.3-0.7,0.5-0.9,0.7c0.2,0.4,0.7,0.9,0.7,1.3c0,1.1-0.1,2.2-0.2,3.3c-0.2,1.2-0.4,2.4-0.7,3.7c-0.3,1.6-0.6,3.1-2.7,3.7
			c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.1,0.7,0.2,1.1,0.4c-0.9,3.4-2.4,6.5-4.1,9.6c-5,8.7-12.1,15.3-20.3,20.9
			c-5.7,3.9-11.6,7.2-17.8,10c-6.3,2.9-12.5,6.1-18.9,9c-12,5.4-24.1,10.2-36.6,14.4c-11,3.7-22.1,7.3-33.2,10.9
			c-1.4,0.5-3,0.7-4.4,0.7c-4.5,0.1-9,0.2-13.5,0c-4.9-0.3-9.8,0.5-14.8,0.4c-5-0.2-10.1,0-15.1,0s-10,0-14.9,0
			c-2.3,0-2.5,0.2-3,2.5c-1.8,7.5-3.5,15-5.3,22.6c-1.7,7.5-3.4,15-5.2,22.5c-2.2,9.3-4.4,18.6-6.6,28c-3.6,15.2-7.1,30.5-10.7,45.7
			c-0.9,3.7-1.7,7.4-2.6,11.1c-1.1,4.7-2.2,9.5-3.3,14.2c-0.5,1.9-0.9,3.9-1.9,5.7c-0.1,0.2-0.1,0.4-0.1,0.6c0.6,2.1-0.6,4-0.9,6
			c-0.2,0.9-0.6,1.5-1.7,1.4c-0.3,0-0.5,0.4-1,0.8c0.8,0.3,1.4,0.4,1.9,0.6c-1,4.8-1.7,9.5-3.2,14.4c-0.6-1.1,0.2-2.4-1-2.9
			c0.4-0.2,0.8-0.5,0.9-0.6c-0.2-0.8-0.5-1.5-0.3-2.1c0.4-1.5-0.2-2.4-1.6-3.1c0,1.3-0.4,2.8,0.1,3.6c0.7,0.9-0.1,1.4,0.1,2.1
			c0.4,0,0.7,0.1,0.9,0.1c0,1.7-0.8,2.9-2.4,3.4c-0.1,0.5,0.7,1.1-0.3,1.3c0.3,0.4,0.8,0.7,0.9,1.2c0.3,1-0.4,1.7-1,2.3
			c-0.6,0.7-1.2,0.4-1.8-0.1c0.3-0.4,0.6-0.8,1.2-1.5C224.36,352.73,223.36,352.73,222.46,352.63L222.46,352.63z M422.16,111.83
			c0.6-0.3,1.2-0.7,1.4-1.7C422.56,110.53,422.06,111.03,422.16,111.83c-0.5,0-1.1,0-1.7,0
			C421.16,112.93,421.66,112.73,422.16,111.83z M234.96,223.93c-0.8-0.2-1.7-0.4-2.6,0.4C233.46,224.53,234.26,224.63,234.96,223.93
			c0.4-0.2,0.9-0.3,1.5-0.4C235.46,223.03,235.46,223.03,234.96,223.93z M197.46,171.23c0.2-1-0.3-1.4-1.2-1.7
			C196.16,170.63,196.96,170.83,197.46,171.23c0.1,0.4,0.2,0.9,0.3,1.4c0.8,0.3,1.7,0.5,2.5,0.8
			C199.26,172.63,198.96,171.13,197.46,171.23z M84.06,126.53c-2.2-0.4-2.2-0.4-3,2.5C82.46,128.33,83.06,127.13,84.06,126.53
			c0.6-0.4,1.3-0.8,0.8-1.8c-0.3,0-0.6,0-1,0C83.96,125.33,84.06,125.93,84.06,126.53z M408.96,119.23c0.4,0.2,0.7,0.4,1.1,0.6
			c0.1-0.4,0.3-0.8,0.4-1.3c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0.1-1.9,0.2-2.8,0.4c-0.3,0.1-0.5,0.5-1,1.1
			C406.86,120.53,408.26,121.03,408.96,119.23z M219.86,302.03c0.4,0.3,0.9,0.6,1.2,0.9c0.8-0.4,1.6-1.3,2-1.1
			c1.1,0.5,1.7-0.4,2.6-0.6c-0.2-0.3-0.5-0.6-0.7-0.6C223.26,301.13,221.36,300.73,219.86,302.03c-0.5,0.1-1,0.2-1.5,0.2
			c-1,0-1.5,0.5-1.7,1.7c1.4-0.2,2.4-0.8,3.2-1.7C219.96,302.13,219.86,302.13,219.86,302.03z M83.96,120.53
			c-0.4-0.2-0.8-0.4-1.1-0.7c-0.4-0.5-0.8-1-1.4-1.8c-0.5,1.8-1.7,1.2-3.2,1.2c1.7,1.7,4,0.8,5.2,2.7
			C83.66,121.13,83.76,120.83,83.96,120.53c0.2-0.1,0.5,0,0.7-0.1s0.3-0.4,0.3-0.6c0-0.1-0.2-0.4-0.4-0.4s-0.5,0.1-0.6,0.3
			C83.86,119.93,83.96,120.23,83.96,120.53z M83.16,117.13c-0.2-0.3-0.4-0.7-0.6-1.1c-0.3,0.5-0.5,0.7-0.8,1.2
			C82.36,117.23,82.76,117.13,83.16,117.13c0.7,0.5,1,1.4,1.7,1.7c0.5-1.1,0.7-2.2,1.4-2.9c0.7-0.7,1.8-1.1,2.7-1.6s1.5-1.4,1.4-2.3
			c-3.3,0.9-3.9,1.4-3.9,3c-0.6,0.1-1.2,0.1-1.9,0.2c-0.1,0.7-0.3,1.4-0.4,2C83.76,117.23,83.46,117.13,83.16,117.13z
			 M226.96,327.73c-0.1,0.1-0.2,0.1-0.3,0.2c0.2,1,0.8,2,0.6,2.9c-0.3,1.4,0.7,2.9-0.3,4.2c0,0,0,0.1,0,0.2c0.4,1.4,0.7,2.8,1.1,4.3
			c0.8-0.8,1.5-1.4,2.2-2.1c-0.1-1.5-0.2-3.2-0.4-4.9c-0.6,0.2-0.9,0.3-1.3,0.4c0-1.1,0-2.1,0-3.2
			C228.26,329.03,227.56,328.33,226.96,327.73z M228.46,261.23c-0.2,1.3-0.3,2.3-0.4,3.3c0,0.6-0.2,1.5,0.1,1.8
			c1.1,1.2,0.3,1.8-0.4,2.6c-0.2,0.2-0.4,0.5-0.6,0.7c0.5,0.2,0.8,0.4,1.2,0.5c1.1-1.4,1.4-2.8,1.4-4.5c-0.1-1.8,0-3.7,0-5.5
			c-0.1-1.7,0-3.6-0.5-5.2c-0.5-1.5-0.6-2.8,0.2-4.5c-1.1,0.5-1.9,1.3-1.6,2.1c0.3,1.3-0.1,2.8,0.9,4c0.3,0.4,0.2,1.4-0.2,1.9
			C227.46,259.83,227.76,260.63,228.46,261.23z M88.36,108.23c-1.1-1.4-2.5-1.2-3.8-1.3c-0.3,1.4-2.5,1.4-2.3,3.2
			c0.6,0,1.2,0.1,1.8,0.1c0.5,0,1,0,1.3-0.2c0.8-0.4,1.3-1.7,2.5-0.9C88.06,108.83,88.26,108.53,88.36,108.23z M93.96,111.53
			c0.1-0.2,0.3-0.4,0.3-0.7c0-0.4-0.2-0.8-0.3-1.3c-0.3,0.1-0.7,0.1-1,0.3c-0.7,0.4-1.3,0.4-1.8-0.3c-0.4-0.4-0.8-0.8-1.3-1.1
			C91.16,111.83,91.16,111.83,93.96,111.53z M228.36,243.73c-0.4,1.5-0.7,2.5-0.9,3.5c-0.1,0.7,0.8,2.2,1.5,2.5
			c0.2-0.5,0.6-1,0.6-1.5C229.26,246.83,228.86,245.53,228.36,243.73z M401.46,113.93c-1.9-0.2-3.4-0.3-4.4,1.3
			c1.2,0.3,2.3,0.6,3.6,1C400.96,115.53,401.16,114.83,401.46,113.93z M100.86,149.83c2-0.1,2.6-0.9,2.3-3c-1.3-0.6-1.7,0.6-2.3,1
			C100.86,148.63,100.86,149.23,100.86,149.83z M443.86,93.13c1.1-0.2,2.1-0.1,2.8-0.5s1.1-1.2,1.6-1.9c-0.4-0.2-0.7-0.5-1.1-0.8
			c-0.3,0.5-0.6,1-0.9,1.5C444.46,91.13,444.46,91.13,443.86,93.13z M69.06,101.33c0.1,0.5,0,1,0.2,1.2c0.4,0.3,1,0.6,1.5,0.5
			c0.4,0,0.7-0.5,1.1-0.8c0.5-0.5,0.7-1,0.2-1.6C71.16,100.83,70.26,101.03,69.06,101.33z M211.26,305.33c0-0.2-0.1-0.3-0.1-0.5
			c-1.7,0-3.4,0.2-4.8,1.2c-0.3,0.2-0.3,0.7-0.4,1.3C207.86,306.53,209.56,305.93,211.26,305.33z M102.76,116.13
			c-0.6,0.9-1,1.8-1.7,2.8c1.1-0.5,1.8-0.9,2.5-1.2c0.5,0.5,1,1,1.9,1.8C105.06,117.83,105.06,117.83,102.76,116.13z M402.36,113.83
			c1.1,1.2,1.8-0.2,2.7-0.1c1,0,1.7-0.5,2.4-1.4C405.36,111.73,404.06,113.03,402.36,113.83z M434.96,45.63c1.3,0.6,2.6,1.1,4.1,0.3
			c-0.9-0.6-1.7-1-2.6-1.6C436.26,44.93,434.96,44.23,434.96,45.63z M434.76,40.53c1.4,0.1,2.5,0.2,3.6,0.1c0.2,0,0.4-0.4,0.7-0.8
			c-0.9-0.2-1.7-0.1-2.2-0.4C435.76,38.93,435.36,39.53,434.76,40.53z M224.76,277.03c0-2.3,0.6-4.7-0.2-7
			C223.36,272.43,224.96,274.73,224.76,277.03z M226.96,302.23c-0.1-1.2,0.7-1.4,1.4-1.6c0.6-0.2,1.2-0.3,1-1.2
			c-0.6,0-1.2,0.9-1.8,0c-0.1-0.1-1.1,0.2-1.1,0.4C226.26,300.53,225.96,301.43,226.96,302.23z M450.36,81.73c-0.7,0-1.4,0-2.1,0
			c0,0.6,0,1.2,0,1.7c0.8,0.1,1.5,0.1,2.4,0.2C450.56,82.93,450.46,82.33,450.36,81.73z M64.36,113.93c0.3,1-0.9,2.2,0.8,2.7
			c0.5-1,1-1.8,1.6-2.9C65.76,113.83,65.16,113.83,64.36,113.93z M71.26,110.53c0.3-0.8,0.5-1.4,0.6-1.8c-0.6-0.3-1-0.5-1.5-0.7
			c-0.4,0.6-0.8,1.2-1.4,2.1C69.86,110.23,70.46,110.33,71.26,110.53z M454.46,82.43c-0.4,0-0.6,0.1-0.9,0c-0.4-0.1-0.7-0.3-1.1-0.4
			c-0.3,0.7-0.6,1.4-0.9,2C453.46,84.33,453.46,84.33,454.46,82.43z M444.46,94.43c-0.3,0.5-0.5,1.1-0.9,1.4
			c-0.5,0.3-1.1,0.3-1.7,0.5c0.6,0.9,1.5,1.2,2.4,0.6C445.26,96.23,444.86,95.33,444.46,94.43z M429.46,39.33
			c1.4-0.6,2.5-1.7,4.5-1.3c-1.3-1.3-2.4-0.8-3.3-0.8C430.16,37.93,429.86,38.63,429.46,39.33z M303.26,157.93
			c-0.4,2.5-0.1,3,1.8,3.4C305.06,159.93,304.06,159.23,303.26,157.93z M427.46,53.13c1.4,0.1,2.4,0.3,2.8-0.8
			c0.1-0.4-0.1-0.9-0.3-1.7C428.96,51.53,428.36,52.13,427.46,53.13z M97.96,139.63c-1.2,0.5-2.1,0.6-1.9,1.8
			c0.6,0.3,1.2,0.6,2.1,1.1C98.06,141.53,97.96,140.73,97.96,139.63z M75.76,102.53c-0.7,0.3-1.5,0.3-1.7,0.7
			c-0.2,0.3,0.1,1,0.2,1.6C75.76,104.23,75.76,104.23,75.76,102.53z M91.36,140.53c1-0.3,1.9-0.5,2.8-0.7c0-0.9-0.5-1.4-1.1-1.3
			C92.16,138.63,91.56,139.33,91.36,140.53z M99.66,121.23c0.7-0.5,1.8-0.6,1.1-1.9c-0.3,0.1-0.6,0.2-0.9,0.3
			c-0.5,0.2-1.4-1-1.5,0.2C98.16,120.23,99.06,120.73,99.66,121.23z M419.96,106.53c1.5,0,2-0.5,2.4-2
			C420.76,104.33,420.56,105.63,419.96,106.53z M291.76,159.83c-1,1.4-1,1.4,0.1,2.7C291.66,161.73,293.46,160.83,291.76,159.83z
			 M225.16,277.93c-0.6,1.5,0,2.6,0.2,3.7C225.76,280.53,225.96,279.43,225.16,277.93z M224.76,247.23c-1.2-0.3-1.8,0.2-2.2,1.2
			c0.4,0.3,0.8,0.6,1.2,1C224.16,248.63,224.46,248.03,224.76,247.23z M455.46,92.33c-0.5,1.1-0.9,2.1-1.3,3
			C456.26,93.83,456.26,93.83,455.46,92.33z M454.66,92.23c0.3-0.9,0.5-1.5,0.8-2.3c-0.8,0.1-1.4,0.1-2.3,0.2
			C453.76,90.83,454.16,91.43,454.66,92.23z M455.36,101.93c0.8-1.1,0.2-2,0.1-3C454.06,99.53,454.06,100.03,455.36,101.93z
			 M404.96,118.13c-0.2-0.8-0.3-1.4-0.5-2.1c-1.3,0.5-1.2,1.3-0.9,2.3C403.96,118.23,404.36,118.23,404.96,118.13z M88.26,152.03
			c-2.2-1.5-2.2-1.5-2.6,0.3C86.46,152.23,87.16,152.13,88.26,152.03z M413.36,122.73c1.2-0.2,1.9-0.4,3-0.6c-0.5-0.5-0.7-1-1-1.1
			C414.46,120.83,414.16,121.73,413.36,122.73z M444.76,81.53c0.1,0.7,0.1,1.1,0.3,1.5c0,0.1,0.7,0,0.7,0c0-0.9,1-0.7,1.5-1.4
			C446.46,81.63,445.76,81.63,444.76,81.53z M402.06,120.63c1.2,1.2,1.3,1.1,2.8-0.7C403.76,120.23,402.96,120.43,402.06,120.63z
			 M433.96,46.73c-0.4-0.8-0.8-1.4-1.2-2c-0.2,0-0.3,0.1-0.5,0.1c0.1,0.7,0.1,1.4,0.2,2.3C433.16,46.93,433.46,46.83,433.96,46.73z
			 M433.26,86.63c-0.3,1.3,0.4,1.8,1.5,2.2C434.56,87.83,434.66,86.83,433.26,86.63z M112.96,148.63c0.9,0.6,1.5,0.9,2.1,1.3
			C115.26,148.33,115.26,148.33,112.96,148.63z M399.86,121.23c-1.3-0.3-1.3-0.3-2.2,0.1C399.16,121.73,399.16,121.73,399.86,121.23
			z M435.56,86.03c0.5,1.2,1.1,1.8,2.1,2.1C437.16,86.53,437.16,86.53,435.56,86.03z M425.96,39.83c-0.2,0.5-0.4,0.9-0.7,1.4
			c0.8,0,1.4,0,2,0c0.1,0,0.2-0.3,0.4-0.5C427.16,40.43,426.66,40.23,425.96,39.83z M442.06,75.33c0,1.4,0.6,2,1.6,2.1
			C443.06,76.63,442.56,75.93,442.06,75.33z M100.36,132.63c-1-0.7-1.7-1.2-2.7-1C98.16,132.93,99.06,132.93,100.36,132.63z
			 M227.16,324.33c1.5-0.6,1.5-0.6,1.8-1.8C227.86,322.53,227.26,322.93,227.16,324.33z M108.96,116.63c-1.1,0.3-1.8,0.6-1.6,1.7
			c0.1,0.2,0.5,0.4,0.9,0.6C108.46,118.13,108.66,117.53,108.96,116.63z M208.96,311.13c-0.8-0.2-1.6-0.3-2.3-0.4
			C207.16,312.03,208.06,312.23,208.96,311.13z M94.56,127.03c-0.6-0.1-1.2-0.1-1.8-0.1c0,0-0.1,0.4-0.1,0.5
			C93.26,128.03,93.86,127.93,94.56,127.03z M426.46,75.63c1.3,1,1.5,0.9,2.2-0.6C427.86,75.23,427.26,75.43,426.46,75.63z
			 M221.66,318.93c-0.7,0.1-1.4-0.4-1.6,0.9c0.5,0.2,1,0.4,1.6,0.7C221.66,319.83,221.66,319.43,221.66,318.93z M409.76,124.43
			c1,0,1.6,0,2.4,0c-0.4-0.5-0.7-0.9-1-1.3C410.66,123.53,410.46,123.73,409.76,124.43z M78.46,104.43c0.3-1.6-1.2-0.2-1.3-1.2
			c0-0.2-0.7,0.3-0.5,1C77.16,104.33,77.66,104.33,78.46,104.43z M224.56,331.23c0.8-1.8-1-1.7-1.4-2.5c-0.1,0.1-0.3,0.2-0.4,0.3
			C223.26,329.63,223.86,330.33,224.56,331.23z M429.86,47.13c-0.5-1.9-0.8-2-1.9-1.1C428.56,46.43,429.06,46.73,429.86,47.13z
			 M91.86,148.23c-0.4-0.6-0.8-1.1-1.1-1.7C89.86,148.13,89.86,148.13,91.86,148.23z M425.46,114.03c1.6,0.2,0.7-1.2,1.3-1.7
			C425.36,112.23,425.96,113.53,425.46,114.03z M450.56,93.23c0.5-0.8,1-1.5,1.5-2.3C450.16,90.63,450.76,92.23,450.56,93.23z
			 M461.76,84.83c-0.1-0.2-0.1-0.3-0.2-0.5c-0.8,0.3-1.7,0.5-2.5,0.8c0.1,0.2,0.1,0.3,0.2,0.5
			C460.06,85.33,460.86,85.13,461.76,84.83z M227.26,306.13c-0.9,0.6-0.9,0.6,0.2,2.2C227.46,307.63,227.36,307.03,227.26,306.13z
			 M232.36,245.33c0.6,0.5,1,0.8,1.6,1.4C233.76,245.03,233.76,245.03,232.36,245.33z M214.96,305.23c0.1-0.1,0.3-0.1,0.4-0.2
			c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2,0.1-0.6,0.4-0.6,0.4C214.56,304.63,214.76,304.93,214.96,305.23z M223.56,338.63
			c0.6,0,1,0.1,1.1-0.1c0.6-0.7,0.2-1.2-0.4-1.7C224.16,337.33,223.96,337.83,223.56,338.63z M86.36,136.23c0.1,0.2,0.2,0.4,0.3,0.6
			c0.4-0.2,0.9-0.4,1.3-0.6c-0.1-0.2-0.2-0.5-0.3-0.7C87.16,135.83,86.76,136.03,86.36,136.23z M416.56,122.53
			c1-0.2,1.5-0.4,1.2-1.2c-0.1-0.1-0.6-0.2-0.6-0.2C416.96,121.53,416.76,121.93,416.56,122.53z M433.56,43.53
			c-0.8-1.1-0.8-1.1-1.6,0C432.56,43.53,433.06,43.53,433.56,43.53z M78.16,108.83c-0.6,0.1-1.2,0.2-2.1,0.3
			C77.16,110.43,77.66,109.43,78.16,108.83z M434.96,50.23c0.5,0,1-0.1,1.7-0.1C435.96,49.13,435.46,49.03,434.96,50.23z
			 M415.06,109.23c-0.7,0.3-1.1,0.4-1.5,0.6c0,0,0,0.3,0.1,0.3C414.16,110.53,414.56,110.23,415.06,109.23z M410.16,111.13
			c-0.4,0.4-0.8,0.7-1.1,1.1c0.1,0.1,0.2,0.2,0.2,0.3c0.4-0.2,0.9-0.4,1.4-0.6C410.46,111.53,410.36,111.43,410.16,111.13z
			 M91.06,141.13c-0.5,0.9-0.6,1.4,0.2,1.6c0.1,0,0.3-0.1,0.5-0.2C91.56,142.13,91.36,141.73,91.06,141.13z M86.46,144.93
			c1.6,0.2,1.6-0.4,1.2-1.5C87.36,143.93,87.06,144.23,86.46,144.93z M451.56,105.33c0.7-0.3,0.7-0.7,0.4-1.3
			c-0.1-0.1-0.5-0.1-0.5-0.1C450.96,104.53,450.96,104.93,451.56,105.33z M406.16,126.53c-0.5-0.8-0.8-1.3-1.2-2
			C404.46,125.73,404.86,126.13,406.16,126.53z M219.66,148.53c0,1.5,0,1.5,1.6,1.5C220.66,149.53,220.26,149.03,219.66,148.53z
			 M224.56,335.43c-0.3-1.6-1.2-0.9-2.3-0.7C223.16,334.93,223.76,335.13,224.56,335.43z M453.86,77.73c0.1-0.1,0.3-0.3,0.4-0.4
			c-0.5-0.3-0.2-1.5-1.9-0.8C452.96,77.03,453.46,77.33,453.86,77.73z M139.26,149.33c-0.1,0-0.2,0-0.3,0c0,0.5,0,1.1,0,1.6
			c0.1,0,0.2,0,0.3,0C139.26,150.43,139.26,149.93,139.26,149.33z M212.76,311.03c0-0.1,0-0.3,0-0.4c-0.5,0-1.1,0.1-1.6,0.1
			c0,0.1,0,0.2,0,0.4C211.66,311.03,212.16,311.03,212.76,311.03z M428.26,67.43c-0.1,1.6,0.9,1.6,1.4,2.1c0.1-0.1,0.2-0.2,0.3-0.3
			C429.46,68.73,428.96,68.13,428.26,67.43z M451.96,107.13c-0.5,0.9-0.6,1.3,0.1,1.6c0.1,0,0.3-0.1,0.5-0.2
			C452.46,108.13,452.26,107.73,451.96,107.13z M462.16,91.33c0.5-0.3,0.9-0.6,0.5-1.3c-0.1-0.1-0.4-0.2-0.5-0.1
			C461.76,90.43,461.56,90.93,462.16,91.33z M407.56,116.03c-0.8,0.6-1.4,0.9-1.9,1.3C406.66,117.63,407.36,117.53,407.56,116.03z
			 M409.46,116.13c0.1,0.2,0.2,0.4,0.3,0.5c0.5-0.3,1-0.6,1.5-0.9c0-0.1-0.1-0.2-0.1-0.3C410.66,115.63,410.06,115.83,409.46,116.13
			z M442.56,80.83c0-0.1,0-0.3,0-0.4c-0.5,0-1,0-1.5,0.1c0,0.1,0,0.2,0,0.4C441.56,80.83,442.06,80.83,442.56,80.83z M431.46,68.93
			c1.4,0.3,1.4,0.3,1.6-0.7C432.46,68.43,431.96,68.73,431.46,68.93z M231.36,213.03c0.1-0.2,0.3-0.3,0.4-0.5
			c-0.3-0.3-0.7-0.6-1-0.8c-0.1,0.1-0.2,0.2-0.3,0.4C230.76,212.43,231.06,212.73,231.36,213.03z M70.66,96.93
			c1.4,0.1,1.4,0.1,1.5-0.7C71.76,96.33,71.36,96.53,70.66,96.93z M419.36,119.33c-0.1-0.1-0.1-0.3-0.2-0.4
			c-0.4,0.1-0.8,0.3-1.1,0.5c0,0,0.1,0.3,0.1,0.5C418.56,119.63,418.96,119.43,419.36,119.33z M227.56,311.73c0-0.7,0-1.2,0-1.8
			c-0.1,0-0.2,0-0.3,0c-0.2,0.6-0.4,1.2-0.7,1.8C226.96,311.73,227.26,311.73,227.56,311.73z M137.06,153.03
			c0.5,0.5,0.9,0.9,1.6,1.6C138.76,153.23,138.06,153.03,137.06,153.03z M228.26,149.83c0.1-0.1,0.2-0.2,0.2-0.3
			c-0.4-0.3-0.5-1.2-1.9-0.8C227.36,149.23,227.86,149.53,228.26,149.83z M422.86,73.23c-0.1,0.2-0.1,0.4-0.2,0.5
			c0.4,0.2,0.8,0.3,1.2,0.5c0.1-0.2,0.1-0.5,0.2-0.7C423.66,73.43,423.26,73.33,422.86,73.23z M88.86,121.03
			c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.3,0.8,0.6,1.2,0.9c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.9
			C89.26,121.03,89.06,121.03,88.86,121.03z M432.46,77.73c0.8-0.4,1.4-0.7,2-1c-0.6-0.2-1-0.3-1.5-0.5
			C432.76,76.63,432.66,77.03,432.46,77.73z M81.56,123.63c0.5,0.3,0.8,0.6,1.5,1.1c-0.2-0.9-0.4-1.4-0.5-2
			C82.26,123.13,81.96,123.33,81.56,123.63z M201.76,301.73c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2,0.4-0.4,0.8-0.5,1.2c0,0,0.4,0.2,0.5,0.2
			C201.36,302.43,201.56,302.03,201.76,301.73z M428.86,59.13c-0.2-0.8-0.4-1.3-0.6-2C427.46,58.13,427.66,58.63,428.86,59.13z
			 M215.06,165.63c0.5,0.4,0.9,0.6,1.3,0.9c0.1-0.2,0.2-0.4,0.2-0.5C216.46,165.13,215.96,165.23,215.06,165.63z M98.16,127.73
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0.7-1.2,0.8-1.2,1.6c0.1,0.1,0.3,0.2,0.4,0.3C97.36,128.73,97.76,128.23,98.16,127.73z
			 M438.86,49.93c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.3-0.9,0.7-1.5,1.1C438.06,50.93,438.66,50.73,438.86,49.93z M426.16,100.93
			c-0.4,0.8-0.6,1.2-0.8,1.7C426.56,102.63,426.56,102.13,426.16,100.93z M94.96,136.93c0.7-0.3,1-0.4,1.4-0.6
			c0.1,0-0.1-0.6-0.2-0.7C95.46,135.43,95.16,135.83,94.96,136.93z M80.56,105.83c-0.6-0.3-1.3-0.6-2.1-0.9
			C78.76,106.43,79.76,105.93,80.56,105.83z M228.56,135.13c0-0.1,0-0.3-0.1-0.4c-0.4,0.2-0.9,0.3-1.3,0.5c-0.1,0.1,0,0.5,0,0.7
			C227.66,135.63,228.06,135.43,228.56,135.13z M222.46,228.23c0.1,0.1,0.3,0.2,0.4,0.3c0.2-0.6,0.3-1.2,0.5-1.8
			c-0.1,0-0.2-0.1-0.4-0.1C222.86,227.13,222.66,227.63,222.46,228.23z M145.56,138.73c-0.2-0.1-0.3-0.2-0.5-0.3
			c-0.7,0.2-0.5,0.9-0.5,1.8C145.06,139.53,145.36,139.13,145.56,138.73z M221.96,316.63c0.2-0.1,0.3-0.3,0.5-0.4
			c-0.3-0.2-0.5-0.5-0.8-0.7c-0.1,0.1-0.2,0.2-0.3,0.3C221.56,316.13,221.76,316.33,221.96,316.63z M203.56,255.13
			c0.8,0.1,1.4,0.1,2.2,0.2C205.06,254.53,204.56,254.23,203.56,255.13z M446.36,77.63c0.1,0.2,0.2,0.3,0.3,0.5
			c0.3-0.2,0.6-0.4,0.9-0.7l-0.2-0.3C446.96,77.33,446.66,77.53,446.36,77.63z M432.56,50.93c-0.1,0.3-0.2,0.6-0.3,0.8
			c-0.1,0.2-0.3,0.4-0.4,0.6c0.2,0.1,0.3,0.1,0.5,0.2C432.86,52.23,432.56,51.53,432.56,50.93z M430.96,58.53
			c-0.3,0.3-0.4,0.5-0.6,0.7c0.2,0.2,0.5,0.4,0.7,0.6c0.1-0.2,0.4-0.4,0.4-0.6C431.56,58.93,431.26,58.83,430.96,58.53z
			 M445.46,60.63c0.1,0,0.3,0,0.4,0c0-0.4-0.1-0.8-0.1-1.1c-0.1,0-0.2,0-0.3,0C445.46,59.83,445.46,60.23,445.46,60.63z
			 M436.66,99.83c-0.7,0-1,0.1-1.6,0.1C435.66,100.83,436.06,101.23,436.66,99.83z M228.16,272.53c0.2,0,0.3,0,0.5-0.1
			c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1,0-0.2,0.1-0.3,0.1C228.06,271.73,228.06,272.13,228.16,272.53z M429.66,97.93
			c0.1,0.1,0.2,0.2,0.3,0.2c0.3-0.2,0.6-0.3,0.8-0.5c0.1-0.2,0.1-0.5,0.1-0.8c-0.1,0-0.2-0.1-0.3-0.1
			C430.26,97.13,429.96,97.53,429.66,97.93z M456.66,96.23c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0.2-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.2,0.8
			c0.1,0,0.2,0,0.3,0C456.66,97.13,456.66,96.73,456.66,96.23z M144.36,152.33c0,0.2-0.1,0.3-0.1,0.5c0.5,0.2,1,0.3,1.6,0.5
			c0.1-0.2,0.1-0.4,0.2-0.5C145.36,152.63,144.86,152.53,144.36,152.33z M435.56,63.83c0.4,1.2,0.9,1.1,1.7,0.6
			C436.56,64.13,436.26,64.03,435.56,63.83z M213.46,304.03c-0.2,0-0.4,0-0.6,0.1c0.1,0.4,0.2,0.9,0.3,1.3c0.1,0,0.3,0,0.4-0.1
			C213.56,304.83,213.46,304.43,213.46,304.03z M451.06,80.53c0.7,0.4,1.1,0.6,1.6,0.9C452.86,80.23,452.26,80.33,451.06,80.53z
			 M416.76,113.23c-0.4-1.3-1-1.3-1.7-0.9C415.56,112.53,415.96,112.83,416.76,113.23z M406.26,104.83c-0.2-0.1-0.4-0.1-0.6-0.2
			c-0.1,0.6-0.2,1.1-0.3,1.7c0.1,0,0.2,0,0.4,0.1C405.86,105.93,406.06,105.33,406.26,104.83z M232.26,299.33
			c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3,0.2-0.7,0.4-1,0.7l0.2,0.3C231.56,299.63,231.86,299.53,232.26,299.33z M429.86,36.23
			c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4,0.3-0.8,0.5-1.2,0.8c0.1,0.1,0.2,0.3,0.3,0.4C429.06,36.73,429.46,36.53,429.86,36.23z
			 M450.16,73.23c-0.1,0.3-0.2,0.6-0.2,0.6c0.2,0.2,0.4,0.3,0.6,0.4c0.1-0.1,0.3-0.4,0.2-0.4
			C450.66,73.63,450.46,73.53,450.16,73.23z M443.86,70.93c0-0.2,0-0.3,0-0.5c-0.4,0.1-0.7,0.1-1.1,0.2c0,0.1,0,0.2,0,0.3
			C443.16,70.93,443.46,70.93,443.86,70.93z M192.46,157.03c-0.1,0-0.3,0-0.4-0.1c0,0.4-0.1,0.7-0.1,1.1c0.1,0,0.2,0,0.4,0
			C192.36,157.73,192.36,157.43,192.46,157.03z M399.76,99.93c-0.2,0-0.4,0.1-0.5,0.1c0.1,0.5,0.3,0.9,0.4,1.4
			c0.4-0.4,0.5-0.7,0.4-1C399.96,100.23,399.86,100.03,399.76,99.93z M456.46,76.73c-0.2-0.1-0.3-0.1-0.5-0.2
			c-0.1,0.4-0.3,0.9-0.4,1.3c0.1,0,0.2,0.1,0.2,0.1C456.06,77.43,456.26,77.03,456.46,76.73z M457.36,81.93
			c-0.2,0.2-0.4,0.4-0.6,0.6c0.2,0.3,0.5,0.5,0.7,0.8c0.2-0.2,0.4-0.3,0.5-0.5C457.76,82.53,457.56,82.23,457.36,81.93z
			 M144.16,149.03c0.1-0.1,0.2-0.2,0.3-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1,0.1-0.2,0.3-0.3,0.4
			C143.56,148.63,143.86,148.83,144.16,149.03z M138.26,141.73c-0.3-0.2-0.5-0.5-0.7-0.5s-0.5,0.3-0.4,0.5c0,0.2,0.3,0.5,0.4,0.5
			C137.76,142.23,137.96,142.03,138.26,141.73z M227.66,319.73c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.2,0.2-0.4,0.2-0.6
			c0-0.1-0.3-0.3-0.4-0.3c-0.2,0.1-0.4,0.3-0.5,0.5C227.46,319.23,227.66,319.53,227.66,319.73z M438.76,98.33c0,0.2,0,0.3,0.1,0.5
			c0.3-0.1,0.7-0.1,1-0.2c0-0.1,0-0.2,0-0.3C439.46,98.33,439.16,98.33,438.76,98.33z M415.56,87.43c-0.5,0.5-0.8,1,0.3,1.4
			C415.76,88.23,415.66,87.83,415.56,87.43z M90.16,106.33c-0.3-0.1-0.5-0.3-0.6-0.3c-0.2,0.1-0.3,0.3-0.5,0.5
			c0.1,0.1,0.3,0.3,0.4,0.3C89.66,106.73,89.86,106.63,90.16,106.33z M76.26,107.73c1,0.4,1.2-0.2,1.5-0.7c0,0-0.1-0.1-0.3-0.3
			C77.06,107.03,76.76,107.33,76.26,107.73z M428.46,49.13c0,0.2,0.1,0.3,0.1,0.5c0.5-0.2,1-0.4,1.5-0.5c0-0.1-0.1-0.3-0.1-0.4
			C429.56,48.83,428.96,48.93,428.46,49.13z M100.16,125.93c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0.4-0.6,0.9-0.9,1.3
			c0.1,0.1,0.2,0.1,0.3,0.2C99.46,126.63,99.76,126.23,100.16,125.93z M105.16,124.33c-0.4-0.2-0.6-0.3-0.8-0.3
			c-0.1,0-0.4,0.3-0.3,0.4c0.1,0.2,0.3,0.4,0.4,0.6C104.66,124.73,104.76,124.63,105.16,124.33z M85.26,122.83
			c-0.3-0.2-0.5-0.4-0.6-0.4c-0.2,0-0.4,0.2-0.5,0.3c0,0.1,0.2,0.4,0.2,0.4C84.66,123.13,84.86,123.03,85.26,122.83z M410.26,121.63
			c0.2-0.5,0.3-0.7,0.5-0.9c-0.4,0-0.7,0-1.3-0.1C409.76,121.03,409.96,121.23,410.26,121.63z M229.56,307.93
			c0.1,0.6,0.1,0.9,0.2,1.5c0.4-0.4,0.7-0.6,1.1-0.9C230.36,308.33,230.06,308.13,229.56,307.93z M411.26,117.93
			c0.1,0.1,0.3,0.3,0.4,0.4c0.3-0.3,0.5-0.6,0.8-0.9c-0.1-0.1-0.2-0.2-0.2-0.3C411.86,117.43,411.56,117.63,411.26,117.93z
			 M415.96,115.93c-0.1-0.1-0.2-0.3-0.4-0.4c-0.3,0.3-0.6,0.5-1,0.8c0.1,0.1,0.2,0.2,0.3,0.3
			C415.26,116.43,415.56,116.13,415.96,115.93z M420.96,114.23c-0.2,0.1-0.4,0.1-0.7,0.2c0.2,0.4,0.3,0.7,0.5,1.1l0.3-0.1
			C421.06,115.03,420.96,114.63,420.96,114.23z M225.16,285.83c0.1,0,0.3,0,0.4,0c0-0.4,0-0.7-0.1-1.1c-0.1,0-0.3,0-0.4,0
			C225.16,285.13,225.16,285.53,225.16,285.83z M226.16,290.23c0-0.4,0-0.8,0-1.3C225.26,289.73,225.26,289.73,226.16,290.23z
			 M239.56,297.33c-0.6-0.1-1.2-0.2-1.2,1C238.96,297.93,239.26,297.63,239.56,297.33z M206.36,336.43c-0.3,0.5-0.4,0.7-0.5,0.9
			c0,0.2,0.2,0.3,0.3,0.5c0.2-0.1,0.5-0.2,0.5-0.4C206.76,337.23,206.56,336.93,206.36,336.43z M304.36,168.93c0.4,0,0.8,0,1.3,0
			C305.06,168.23,304.66,167.93,304.36,168.93z M209.76,162.03c-0.1,0.2-0.1,0.3-0.2,0.5c0.3,0.1,0.6,0.2,1,0.3
			c0-0.1,0.1-0.2,0.1-0.3C210.36,162.33,210.06,162.13,209.76,162.03z M207.66,161.23c0-0.2,0.1-0.3,0.1-0.5c-0.5-0.1-1-0.2-1.5-0.3
			c0,0.2-0.1,0.3-0.1,0.5C206.66,161.03,207.16,161.13,207.66,161.23z M114.06,158.23c0-0.1,0-0.2,0.1-0.3c-0.3-0.1-0.6-0.1-0.9-0.2
			c0,0.1,0,0.3,0,0.3C113.46,158.13,113.76,158.23,114.06,158.23z M255.26,156.63c-0.2-0.1-0.4-0.3-0.5-0.3s-0.2,0.2-0.4,0.4
			c0.2,0.1,0.4,0.3,0.5,0.4C255.06,156.93,255.16,156.83,255.26,156.63z M98.46,156.33c-0.1,0.2-0.3,0.5-0.2,0.5
			c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2-0.3,0.3-0.4C98.96,156.73,98.76,156.53,98.46,156.33z M232.66,154.23c0,0.2,0,0.4,0,0.5
			c0.3,0,0.7,0,1,0c0,0,0-0.2,0.1-0.4C233.36,154.33,232.96,154.23,232.66,154.23z M192.56,155.13c0.2-0.3,0.4-0.5,0.5-0.7
			c0-0.1-0.3-0.4-0.4-0.3c-0.2,0-0.4,0.2-0.6,0.3C192.06,154.63,192.26,154.83,192.56,155.13z M194.66,154.63
			c0.9-0.1,1.2-0.1,1.7-0.2c-0.3-0.4-0.5-0.6-0.7-0.9C195.46,153.83,195.26,154.03,194.66,154.63z M231.06,248.23
			c0.1-0.3,0.3-0.5,0.3-0.7c0,0-0.3-0.3-0.4-0.2c-0.2,0.1-0.3,0.3-0.5,0.5C230.66,247.93,230.86,248.03,231.06,248.23z
			 M216.36,253.43c-0.2,0-0.3,0.1-0.5,0.1c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0,0.2,0,0.3-0.1C216.46,254.03,216.46,253.73,216.36,253.43z
			 M173.76,257.23c0.1,0,0.3,0,0.4,0c0-0.3-0.1-0.6-0.1-0.9c-0.1,0-0.2,0-0.3,0C173.76,256.63,173.76,256.93,173.76,257.23z
			 M229.46,137.33c-0.1,0.4-1.3,0.8,0,1.2C229.46,138.13,229.46,137.73,229.46,137.33z M100.46,137.13c-0.2-0.3-0.3-0.5-0.4-0.7
			c-0.1,0.1-0.4,0.2-0.4,0.3c0,0.2,0.1,0.4,0.2,0.7C100.06,137.33,100.26,137.23,100.46,137.13z M195.56,259.33c0,0.9,0,1.5,0,2
			C195.96,260.83,196.46,260.33,195.56,259.33z M450.66,88.03c0.2-0.1,0.3-0.1,0.5-0.2c-0.2-0.3-0.3-0.6-0.5-0.8
			c0,0-0.2,0.1-0.3,0.1C450.36,87.43,450.56,87.73,450.66,88.03z M103.96,132.93c-0.1-0.8-0.1-1.1-0.2-1.6c-0.4,0.2-0.6,0.4-1,0.7
			C103.06,132.23,103.26,132.43,103.96,132.93z M117.06,129.43c-0.3-0.2-0.5-0.5-0.6-0.4c-0.2,0.1-0.4,0.3-0.5,0.5
			c0.1,0.1,0.3,0.4,0.4,0.4C116.66,129.73,116.76,129.63,117.06,129.43z M448.16,87.93c-0.1-0.1-0.2-0.1-0.2-0.2
			c-0.2,0.3-0.4,0.5-0.6,0.8c0.2,0.1,0.3,0.2,0.5,0.2C447.86,88.53,448.06,88.23,448.16,87.93z M420.56,122.73c0,0.1,0,0.2,0,0.3
			c0.3,0,0.6,0,0.9,0c0-0.1,0-0.2,0-0.3C421.16,122.73,420.86,122.73,420.56,122.73z M412.96,123.73c0.2-0.1,0.3-0.1,0.5-0.2
			c-0.2-0.3-0.3-0.6-0.5-0.9c0,0-0.2,0.1-0.4,0.1C412.66,123.13,412.76,123.43,412.96,123.73z M425.46,44.73
			c-0.2,0.4-0.3,0.7-0.6,1.1c0.6-0.1,0.9-0.1,1.5-0.1C425.96,45.33,425.76,45.13,425.46,44.73z M419.16,113.73
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2,0-0.4,0-0.5,0.1s-0.2,0.3-0.3,0.5c0.1,0.1,0.2,0.1,0.3,0.2
			C418.66,114.13,418.86,113.93,419.16,113.73z M226.96,298.53c-0.1-0.2-0.1-0.4-0.2-0.5c0,0-0.2,0-0.3,0.1c0.1,0.2,0.1,0.4,0.2,0.7
			C226.66,298.63,226.76,298.63,226.96,298.53z"/>
		<path class="st0-bull-white" d="M29.86,106.03c0.3-1.4-0.4-2.7-0.8-3.9c-0.1-0.2-0.1-0.5-0.2-0.8c0.8,0,1.5,0,2.4,0c0.1-0.5,0.2-1,0.4-1.6
			c1.2-0.2,1.3-1.4,1.9-2.1c-1.1,0.3-1.9,0.9-1.8,2.2c-0.2-0.2-0.3-0.4-0.5-0.6c-0.8,0.3-0.9,1.8-2.1,1.3c-0.5-0.7,0.1-1.3,0-2.1
			c-0.1-0.7,0.6-1.8,1.5-2.4c1.5-1.2,3.2-2.2,4.1-4.2c0.4-0.9,1.1-1.6,1.7-2.4c-0.6-0.8-2.3,0.1-1.7-1.8c-0.9,0.4-1.4,0.6-2,0.9
			c0.4,0.4,0.8,0.7,1,1.1s0.2,0.9,0.3,1.5c-2.5,2.6-4.9,5.3-8.2,6.9c0.4-1.4,1.2-2.3,2.6-2.6c0.3-1.5-0.1-2.6-1.5-3.3
			c0.3-1,1.4-2-0.2-3.2c-0.4,1.2-0.9,2.3-1.3,3.6c-0.3,0-0.7-0.1-1.2-0.1c-0.6,1.4,0.6,3.3-1.4,3.9c0.3,0.9,0.5,1.6,0.8,2.5
			c-1.7,0-3,0.5-4.6,0.3s-3.5,0.4-5,1.1c-1.1,0.5-1.8,1.7-2.8,2.7c-1.2-1.5-3.3-1.8-3.9-3.9c0,0.5,0,1,0,1.9c-0.7-0.6-1.1-1-1.6-1.4
			c1.1-1.5,2.3-2.6,4.1-2.9c0.5-0.1,1-0.4,1.4-0.7c0.3-0.2,0.4-0.8,0.6-0.8c1.1,0,1.4-0.7,1.5-1.6c0-0.2,0.5-0.3,0.8-0.5
			c-1.1-0.7-3-0.5-3.6,0.5s-1.4,1.4-2.5,1.5c-0.3,0-0.7,0.1-1,0.3c-1,0.7-2,0.7-2.9,0.1c-1-0.8-1.3-2.1-1.2-3.3
			c0.2-1.6,0.9-2.9,2.8-3c0.6-0.1,0.9-0.3,1.3-0.8c0.5-0.6,1.3-0.9,2-1.2c1.8-0.7,3.6-1.5,5.5-1.9c1.6-0.4,3.2-0.7,4.8-0.5
			c1.2,0.2,2.3,1.1,3.3,1.7c0.3,1.9-0.2,3.4-1.4,4.8c-0.1,0.1-0.1,0.4-0.1,0.6s0.2,0.4,0.7,0.9c0.2-0.5,0.3-1,0.5-1.5
			c0.3,1.2,0.8,2.2-0.1,3.1c0.2,0.2,0.5,0.4,1,0.7c0.3-3,0.6-5.7,1-8.7c0.9-0.5,1.8,0,2.8,0.4c0-0.4,0-0.7-0.1-1
			c0.9-0.5,1.2,0.4,1.7,0.7c1.9,0.1,1.9-1.5,2.2-2.7c0.1-0.2-0.4-0.5-0.8-1.1c-0.3,0.8-0.4,1.3-0.5,1.6c-0.9,0.2-1.7,0.5-2.6,0.7
			c-0.4-0.9-1.9-1.3-1.4-2.6c-0.5-0.2-0.9-0.3-1.2-0.6c-0.3-0.4-0.5-0.9-0.8-1.3s-0.6-0.8-0.9-1.2c0.6-0.2,1-0.3,1.4-0.5
			c-1.5-0.3-2.9-0.6-4.4-0.9c0.4,1.4-1,3.2,1,4.4c0.2-0.6,0.4-1.1,0.6-1.8c1,1.6,1.5,0,2.3-0.1c-0.2,0.7-0.4,1.5-0.8,2
			c-1.1,1.6-2.3,1.6-3.7,0.1c-1.8,1.4-4.1,0.9-6.2,1.5c-1.5,0.4-3.1,0.6-4.6,1.5c-2,1.3-4.1,2.5-6.5,3.9c0.1-0.7,0.1-1.3,0.3-1.3
			c1-0.4,0.3-1,0.2-1.5c0.4-0.5,0.8-0.7,1.2-0.8c-0.2-0.4-0.3-0.7-0.5-1c-0.6,0.4-1.1,0.7-1.8,1.2c-0.6-3.2-0.2-6.1,1.3-9.1
			c-0.5-0.5-0.9-1.2,0-1.9c0.2-0.2,0.5-0.5,0.6-0.8c0.7-4.1,3.2-7.3,5.5-10.6c4.7-6.7,10.4-12.5,16.7-17.7
			c8.6-7.2,17.9-13.4,27.6-19c9.1-5.2,18.6-9.8,28.2-13.8c4.3-1.8,8.7-3.5,13-5.2c0.4-0.1,0.8-0.2,1.2,0c-0.6,0.3-1.3,0.7-1.9,1
			c-7.4,3.8-14.6,8.1-21.3,13.1c-7.4,5.5-14.2,11.6-19.1,19.6c-3.6,5.9-5.6,12.3-5,19.3c0.2,2,0.7,4.1,1,6.2c-0.5,0.3-1.5,0.5-1,1.7
			c0.4,0,0.9,0,1.3,0.2c0.5,0.3,0.9,0.8,1.1,1.3c0.1,0.4-0.2,1-0.5,1.4c-0.8,0.9-1.7,1.7-2.8,2.9c-0.8,0.1-1.8,0.6-2.4,1.9
			c-0.2,0.5-1,0.7-1.5,1.1c0.1,0.2,0.3,0.4,0.4,0.6c-2,0.9-2.2,3-3.6,4.4c-1.2,1.2-2.1,2.8-3.3,4.4c-0.3-0.5-0.5-0.7-0.8-1.2
			c0.2,1,0.3,1.7,0.4,2.7c1.9-1.4,3.6-2.6,4.5-4.8c0.9-2.2,2.6-3.7,4.9-4.5c-0.2-0.3-0.3-0.5-0.6-1c1.4,0.4,2.6,1,3.9,0.2
			c-0.7-1-1.7-0.3-2.7-0.8c-0.1-0.2-0.2-0.7-0.3-1.2c2.5-0.4,3.8-2.2,5-4.3c1,1.6,2,3,2.9,4.5c0.2,0.4,0.3,1.2,0.1,1.4
			c-1.7,1.4-2.3,3.7-4.1,4.9c-0.4,0.3-1,0.4-1.5,0.5s-1.1,0.1-1.7,0.2c0.4,1,1,1.9,0.9,2.7c-0.1,0.7-1.1,1.3-1.8,2.2
			c-0.3-0.5-0.5-0.9-0.8-1.3c-0.5,0-1.1,0.1-1.6,0.1c-0.6,1.1,0.2,1.9,0.6,2.7c1.1,0.3,1.9,0.1,2.7-0.7c1.6-1.7,3.9-2.9,4.1-5.7
			c0.5-0.1,1-0.2,1.6-0.3c-0.2-2.3,2.1-2.8,3.2-4.5c0.6,0.8,1.2,1.4,1.6,2c-0.5,1.3-2.7,0.6-2.2,2.5c1.5,0.6,1.9-1,3-1.6
			c0.6,0.9,1.3,1.8,1.9,2.8c-0.4,0.6-0.8,1.2-1.4,2c0.8-0.1,1.3-0.2,1.7-0.2c0.2,1.9-1.5,2-3.1,2.8c1.2,0.1,1.8,0.1,2.8,0.2
			c0,2.3,0,4.5,0,6.8c1.3-0.8,2.3-1.4,3.3-1.9c0.1-0.9,0.2-1.8,0.3-2.8c-1,0.3-2,1-3.2,0.2c0.9-1.3,1.1-2.8,0.7-4.4
			c-0.1-0.3-0.5-0.5-0.8-0.7c0.9-1.1,0.9-1.1,2.3,0.1c-0.2,0.3-0.3,0.5-0.5,0.9c0.2,0.1,0.4,0.2,0.5,0.2c0.6-0.3,1-0.3,1.5,0.2
			c1.1,1,2.3,2,3.4,3c1,0.9,0.8,1.1-0.3,1.9c-0.6,0.5-1.7,0.6-1.5,1.9c0.1,0.4-0.7,0.9-1.1,1.4l0,0c-0.2,0.6-0.3,1.3-0.5,2
			c-1,0.1-1.1-0.8-1.4-1.3c-0.9,0.5-1.6,0.9-2.7,1.4c0.1,0.6,0.2,1.3,0.3,2.1c0.1,0.9,0.1,0.9-1,1c-0.2,0-0.6,0.2-0.7,0.3
			c-0.4,1.1-1,2.3-1,3.4c0,2.2-0.3,4.2-1.4,6c-0.4,0.7-0.5,1.7-0.7,2.5c0.2,0.1,0.3,0.1,0.5,0.2c0.7-0.9,1.3-1.8,2.1-2.9
			c0.3,0.3,0.5,0.7,0.7,0.9c0.7,0.9-1.5,1.8,0,2.6c-0.1,1-0.3,2-0.4,2.7c-0.9,0.2-1.5,0.3-2.4,0.5c0.1-0.4,0.1-1,0.2-1.6
			c0.1-0.5,0.3-1.1,0.5-1.7c-1.6-0.1-1.8,1-2.3,1.7c-0.5,0.6-0.8,1.3-1.1,2c-0.2-0.1-0.4-0.1-0.4-0.1c-0.6-2.1-1.4-2.3-3.1-0.9
			c-0.3,0.2-0.5,0.4-1,0.6c0.1-0.4,0.2-0.7,0.4-1.2c-0.7-0.3-1.4-0.7-2.1-0.7c-0.8,0-1.4,0.4-1.6,1.3c-0.1,0.2-0.7,0.3-1.1,0.3
			c-1,0.1-1.4-0.8-2.2-1.4c1.1-0.3,1.5,1.3,2.5,0.3c-0.3-0.3-0.7-0.6-1-0.9c1.5-1,2.9-2,4.4-3.1c-0.3-0.4-0.5-0.9-0.6-0.8
			c-0.5,0-1.1,0.2-1.5,0.4c-0.4,0.3-0.7,0.7-1.1,1.1c-1.4,1.7-1.8,1.7-2.9-0.2c-1.3,0-1.1,1.2-1.4,2c-0.5,0.3-1.3,0-1.8,1
			c-0.4,1-0.9,0.1-1.1-0.5c-0.7,0.3-1.3,0.6-2.2,1c0.7,0.2,1.2,0.4,1.6,0.5c-0.6,1.3-0.6,1.3,0.6,3.2c2-0.5,0.2-2.2,0.8-3.1
			c0.6-0.2,1.2-0.5,1.8-0.7c0.6,0.6,0.6,2,1.9,1.4c0.2-0.1,0.7,0.5,1,0.8c0.5,0.5,1,0.9,1.5,1.4c0.7-0.8,0.6-2.2,2.2-1.6
			c-0.1,0.5,0,1.3-0.3,1.4c-1.2,0.5-1.6,1.6-2.1,2.6c-0.1,0.2-0.4,0.2-0.9,0.4c0.1-0.7,0.2-1.1,0.3-1.7c-0.3,0.1-0.6,0-0.7,0.1
			c-0.4,0.7-0.8,1.1-1.6,0.3c-0.4-0.4-1.2-0.4-1.9-0.6c-0.2,0.7-0.4,1.3-0.8,2.3c-0.5-0.9-0.8-1.5-1.1-2.2c-0.9,0.5-2,1.1-3.1,1.8
			c-1.2-1.4-2.7-2.3-3.5-4c-0.4-0.8-1.4-1.4-2.2-2c-0.8-0.6-1.7-1.2-2.7-1.8c0.3-0.6,1.5-0.3,1.3-1.2c0.5,0.1,1,0.3,1.6,0.5
			c-0.1-0.8-0.1-1.7-0.2-2.6c0.9,0.7,1.5,1.5,2.6,0.9c1.1-0.7,2.8-0.8,2.2-2.9c0.9-0.4,1.7-0.7,2.6-1c-0.2-0.4-0.4-0.9-0.6-1.4
			c-0.2,0.3-0.4,0.5-0.7,0.9c-0.2-0.3-0.4-0.5-0.7-0.9c-0.6,1.2-1.1,2.4-1.7,3.5c-0.2-0.3-1.5-0.1-1-1.4c-1.4-0.3-1.4,1-1.8,1.8
			c-1.5-0.2-1.2-1.4-1.6-2.2c-0.6-0.3-2.1,0.1-1.4-1.6c1.1,0.9,1.7,0,2.3-0.7C29.46,105.73,29.66,105.93,29.86,106.03
			c0.3,0.4,0.2,1.2,1,1c0.4-0.1,0.8-0.4,1.3-0.6c-0.4-0.3-0.6-0.4-0.9-0.6c0.4-0.3,0.6-0.5,0.8-0.7c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.4,0.3-0.7,0.5-1.1,0.8C30.46,105.63,30.16,105.83,29.86,106.03z M33.76,114.33c-0.4-0.1-0.8-0.2-1.5-0.3
			C32.86,115.13,33.26,115.13,33.76,114.33c0.1-0.1,0.4-0.1,0.6-0.1c0.1,0.2,0.3,0.5,0.4,0.7c0.2-0.2,0.4-0.5,0.9-1
			c-0.8,0.2-1.1,0.3-1.4,0.4c0-0.5,0-1,0-1.5c-0.1,0-0.2,0-0.3,0C33.86,113.33,33.76,113.83,33.76,114.33z M8.56,68.23
			c1.7-0.9,1.8-1.1,0.9-2.5C8.96,66.53,8.26,67.23,8.56,68.23c-1.2,0.1-1.6,1.1-2.5,2.2C7.66,70.13,7.96,69.13,8.56,68.23z
			 M52.26,110.03c-1.2-0.8-1.8-0.7-1.9,0.6c-0.1,0.6,0.2,1.3,0.3,1.9c0.1,0,0.2,0,0.3,0c0.1-0.4,0.3-0.8,0.4-1.3
			c0.4,0.1,0.9,0.2,1.8,0.4C52.66,110.83,52.46,110.43,52.26,110.03c0.8,0.1,1.3-0.2,1.7-1C52.86,108.53,52.76,109.63,52.26,110.03z
			 M54.26,108.23c0.1-0.7,0-1.3,0.2-1.6c0.5-0.7-0.2-1-0.3-1.4c-0.1-0.5,0.2-1,0.2-1.5c-0.1-0.5-0.4-0.9-0.5-1
			c0.4-0.7,0.7-1.3,0.8-1.8c0.1-0.3-0.3-0.7-0.5-1.1c-1.9,1.8-2.7,4.3-5.1,5.4c-0.4,0.2-0.5,0.8-0.8,1.1c-0.2,0.2-0.4,0.5-0.7,0.6
			c-1.1,0.5-2.2,1-3.5,0.9c-0.5-0.1-1.1,0.1-1.6,0.1c0,0.2,0,0.3,0,0.5c1.3,0,2.6-0.1,3.9,0c2.3,0.1,4.6,0.1,6.4-1.6
			c0.1-0.1,0.4,0.1,0.7,0.1c-0.1,0.5-0.2,0.9-0.2,1.4C53.76,108.23,54.06,108.23,54.26,108.23z M51.46,96.73c1-0.4,1.9-1.2,2.8-1.2
			s2.2,0.5,2.6,1.2c0.7,1.3,1.4,0.9,2.3,0.7c0.5-1.2,0-1.7-1-1.9c-2-0.5-4.1-0.8-6.1-1.3c-0.5-0.1-0.9-0.6-1.3-0.9
			c-0.5,0.2-1.3,0.6-1.4,0.4c-1-1.3-3,0-3.8-1.5c-1.6,0.3-2.9-1-4.4-0.9c-0.3-0.6-0.5-1.1-0.7-1.7c-0.5,1.3-0.9,2.3-1.3,3.2
			c1.4,0,2.9,0,4.7,0c0.8,0.9,1.9,1.5,3.4,1.3c0.3,0,0.7,0.1,1,0.2c0.9,0.5,1.9,1,3,1.5C51.26,96.13,51.36,96.53,51.46,96.73z
			 M19.26,93.43c0.4-1.3,0.8-2.3,1-3.4c0.1-1,0.5-2.1-0.5-3.1c-0.6,0.9-0.7,2.1-2,2.2c-0.2,0-0.5,1.1-0.3,1.4c0.5,0.8,1,0.3,1.4-0.3
			c0,0,0.2,0,0.4,0.1C19.26,91.23,19.26,92.33,19.26,93.43z M36.06,110.93c1.1-0.6,2.3-1.2,3.6-1.8c-1.9-0.9-3.7,0.2-5.4-0.1
			c-0.1,0.1-0.1,0.2-0.2,0.4C34.76,109.83,35.36,110.33,36.06,110.93z M55.56,84.73c-1.1-0.5-1.9-0.3-2.6,0.4
			c-1.1,1.1-2.2,2.1-3.4,3.2c0.6,0.5,1.1,0.9,1.7-0.1c0.3-0.6,0.8-1.1,1.4-1.5C53.66,86.13,54.26,84.93,55.56,84.73z M31.16,92.33
			c0.1-0.6,0.2-1.3,0.3-1.6c-0.5-0.5-0.8-0.9-1.4-1.5c-0.5,1.4-0.9,2.7-1.4,4.4C29.76,93.13,30.46,92.73,31.16,92.33z M55.66,101.53
			c0.5-0.2,1-0.3,1-0.5c0.2-0.9,0.2-1.7,0.2-2.6c0-0.1-0.4-0.3-0.5-0.2c-0.2,0.1-0.5,0.3-0.5,0.5
			C55.76,99.63,55.76,100.43,55.66,101.53z M28.96,77.13c-0.5,0.3-1.1,0.7-1.7,1c0.7,0.7,1.4,1.3,2,2c0.1-0.1,0.4-0.2,0.6-0.3
			C29.56,78.93,29.26,78.13,28.96,77.13z M37.16,104.13c-0.2,0.8-0.3,1.6-0.5,2.4c0.3,0.3,0.6,0.5,0.9,0.8c0.1,0.1,0.2,0.1,0.4,0.1
			C38.56,105.83,38.56,105.73,37.16,104.13z M12.66,79.33c-0.8,0.4-1.6,0.7-2.7,1.2C12.66,81.13,12.76,81.13,12.66,79.33z
			 M14.76,97.33c-2.6,0-3.2,0.5-2.3,2.1C13.06,98.83,13.76,98.23,14.76,97.33z M46.76,101.23c0.4,0.5,0.6,0.8,0.9,1.2
			c0.5-0.7,0.8-1,1-1.4c0.3-0.4,0.6-0.8,0.8-1.2C48.16,99.53,47.96,100.93,46.76,101.23z M31.66,79.73c1.1-0.2,1.9-0.4,2.8-0.6
			C33.06,77.83,32.66,77.83,31.66,79.73z M6.46,81.73c-1.3,1.5-1.3,1.5-0.9,2.5C7.36,82.83,7.36,82.83,6.46,81.73z M31.06,86.03
			c-0.7,0.8-1.2,1.4-1.8,2.1C31.66,88.23,31.76,88.13,31.06,86.03z M36.06,119.33c-0.1-0.1-0.1-0.2-0.2-0.3c-0.7,0-1.4,0-2.3,0
			c0.2,0.6,0.4,1,0.6,1.5C34.96,120.13,35.46,119.73,36.06,119.33z M35.76,96.93c0,0.2,0.1,0.3,0.1,0.5c0.5,0.2,0.9,0.3,1.6,0.6
			c0-0.7-0.1-1.3-0.1-2C36.76,96.43,36.26,96.73,35.76,96.93z M45.76,63.93c0.3-0.8,0.4-1.4,0.6-1.9c0-0.1-0.1-0.2-0.1-0.3
			C45.06,62.33,44.96,62.73,45.76,63.93z M21.56,95.33c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0.9-0.8,1.9-1.3,2.9c0.4,0,0.6,0,0.6,0
			C20.76,97.03,21.16,96.13,21.56,95.33z M32.46,81.53c-0.5-0.5-0.9-0.9-1-0.8c-0.5,0.2-1,0.6-1.4,0.9c0.1,0.1,0.1,0.3,0.2,0.4
			C30.86,81.83,31.56,81.73,32.46,81.53z M31.56,83.43c0.2,1.2,0.5,1.6,1.1,1.4c0.1,0,0.3-0.6,0.2-0.6
			C32.56,83.93,32.16,83.73,31.56,83.43z M44.86,104.73c-0.9-0.9-0.9-0.9-2.1-0.2C43.66,104.63,44.26,104.73,44.86,104.73z
			 M45.76,80.33c-1.2-0.2-1.4,0.3-1.2,1.1c0,0.1,0.1,0.1,0.2,0.2C45.06,81.23,45.36,80.83,45.76,80.33z M55.36,86.63
			c0.1,0.1,0.2,0.3,0.3,0.4c0.4-0.2,0.8-0.4,1.2-0.6c-0.1-0.1-0.1-0.3-0.2-0.4C56.16,86.23,55.76,86.43,55.36,86.63z M28.96,61.93
			c0.2,0,0.3-0.1,0.5-0.1c-0.1-0.3-0.1-0.6-0.2-0.8c0,0-0.3,0.1-0.5,0.1C28.76,61.33,28.86,61.63,28.96,61.93z M33.26,104.73
			c0.1-0.1,0.3-0.3,0.4-0.4c-0.3-0.3-0.6-0.6-1-0.9c-0.1,0.1-0.2,0.2-0.3,0.3C32.66,104.03,32.96,104.43,33.26,104.73z M11.06,98.83
			c-0.5,0.5-0.8,0.8-1.1,1.2c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.2,0.6-0.5,0.8-0.7C11.16,99.53,11.16,99.33,11.06,98.83z M34.06,81.33
			c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3,0.2-0.5,0.3-0.8,0.5c0,0,0.1,0.2,0.1,0.3C33.46,81.63,33.76,81.43,34.06,81.33z M34.96,75.63
			c-0.2-0.1-0.3-0.1-0.5-0.2c-0.5,0.3-0.5,0.8-0.1,1.5C34.66,76.43,34.76,76.03,34.96,75.63z M24.96,74.43c0.1-0.2,0.2-0.4,0.3-0.5
			c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2,0-0.3,0.2-0.4,0.3C24.66,74.13,24.76,74.23,24.96,74.43z M26.36,87.83c-0.3-0.3-0.5-0.5-0.5-0.5
			c-0.2,0.1-0.4,0.3-0.6,0.5c0.1,0.1,0.2,0.4,0.3,0.3C25.76,88.13,25.96,87.93,26.36,87.83z"/>
		<path class="st0-bull-white" d="M419.66,27.83c-0.2-0.8-0.4-1.6-0.6-2.6c1.8,0.2,2.7,1.2,3.8,2.1c0.3,0.3,0.7,0.6,1.1,0.8
			c2.3,0.8,4.3,2.2,6.4,3.5c0.2,0.1,0.5,0.3,0.7,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c-1.8,0.5-3.7,0.9-5.8,1.5c-0.4-0.2-1.2-0.6-2-1
			c-0.6,1.1-1.2,2-1.8,3.1c0.3,0,0.6,0.1,1,0.1l0,0c0.2,1,0.2,1-0.7,1.7c1.5,1,1.4,1.4-0.5,2.4c-0.8-1.1-2.1-2.1-2.2-3.3
			c-0.2-1.3-0.9-1.4-1.8-1.8c0.3-0.5,0.4-1.2,1.2-0.8c0.3,0.3,0.3,1.2,1.1,0.9c0.2-0.1,0.2-0.4,0.3-0.6c-0.5-0.1-0.9-0.1-1.4-0.2
			c-0.1-0.6-0.2-1.2-0.3-2c-0.7,0.5-1.2,1.1-1.5,1.1c-0.5-0.1-0.9-0.7-1.4-1c-0.8-0.7-1.5-1.4-2.6-2.3c1.3-0.7,2.3-1.3,3.5-1.9
			c-0.5-0.6-0.9-1-1.5-1.7c-0.4,0.8-0.6,1.2-0.8,1.6c-0.9,0-1.7,0-2.8,0c-0.6-0.5-1.4-1.2-2.1-1.9c0.9-0.9,1.7-1.8,2.8-3
			c-1-0.3-1.6-0.5-2.3-0.7c-0.5-0.8-0.5-0.8-1.7-0.1c0.8,0,1.3,0.1,1.8,0.1c0.2,0.5,0.4,1,0.6,1.4c-2.5,1.3-4.1-0.5-5.8-1.7
			c-0.8-0.5-1.4-1.2-2.2-1.9c0.4-0.1,0.8-0.3,1.4-0.5c-0.8-0.8-1.5-1.6-2.4-2.4c0.4-0.5,0.8-1,1.2-1.7c0.9,0.9,1.7,1.7,2.7,2.6
			c1-0.4,2.4-0.8,3.2,0.9c0.1,0.2,0.6,0.1,0.9,0.2c1.3,0.5,3,0.5,3.5,2.4c0.2,0.6,0.9,1.1,1.5,1.6c0.1-0.3,0.2-0.7,0.4-1.3
			c0.4,0.2,0.8,0.5,1.2,0.7c0.6,0.3,1,1,1.8,1c0.9,0,1.3,0.9,1.3,1.8s0,1.7,0,2.9C418.96,28.13,419.36,27.93,419.66,27.83
			c-0.1,0.6-0.2,1.1-0.3,1.9c0.3-0.2,0.8-0.4,0.8-0.5C420.06,28.73,419.86,28.33,419.66,27.83z M426.56,30.73
			c-0.2-0.1-0.4-0.2-0.5-0.2c0,0-0.2,0.2-0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.4C426.26,31.03,426.36,30.93,426.56,30.73z"/>
		<path class="st0-bull-white" d="M43.56,127.43c0.6,0.2,1.1,0.4,1.6,0.6c0.1,0.5,0.1,0.9,0.2,1.6c1.2-0.9,0.4-2,0.9-2.8
			c1.4-0.2,2.9-0.5,4.3-0.7c-0.1,0.6-0.3,1.3-0.4,1.5c-1,1-1.7,1.8-2.6,2.6c0.7,0.6,1.2,0.9,1.6,1.3c-0.4,0.8-2,0.7-1.5,2.4
			c0.9-0.4,1.7-0.7,3-1.2c-0.6,0.9-0.9,1.3-1.2,1.8c-0.1,0.2-0.1,0.5-0.1,0.8c-0.9,0-1.7,0-2.5,0c-0.9-1.6-0.9-3,0.5-4.4
			c-1.3-0.4-1.9,0-2.7,1.8c-0.5-0.2-0.9-0.9-1.7,0c-0.6,0.7-3.3-0.3-3.8-1.2c-0.4-0.6,0-1.8,0.9-1.9
			C41.76,129.33,42.36,128.13,43.56,127.43z"/>
		<path class="st0-bull-white" d="M396.36,13.63c-0.2,0.7-0.3,1-0.6,1.8c0.7-0.6,1-0.9,1.5-1.2c1,0.3,2.1,0.6,3.2,0.9c0,0.1,0,0.2-0.1,0.3
			c-0.7-0.1-1.5-0.2-2.2-0.3c-0.2,1.5,1,2.2,1.6,3.5c-1.4-0.1-2.2-1.1-3.1-1.6c-1-0.6-2-1.3-3-2c0.1-0.5,0.2-1.1,0.4-2
			c-0.6,0.5-1,0.9-1.5,1.3c-1.5-1-2.9-1.9-4.4-2.8c-1.5-0.8-3-1.5-4.5-2.3c-1.6-0.8-3.1-1.7-4.6-2.8c2.1,0.3,3.9,1.2,5.8,2
			c1.9,0.7,3.8,1.5,5.7,2.4C392.26,11.83,394.26,12.73,396.36,13.63z"/>
		<path class="st0-bull-white" d="M22.36,119.23c-0.4-0.2-0.8-0.4-1.5-0.8c0.9-0.5,1.6-0.8,2.5-1.2c-0.2-0.5-0.5-1-0.7-1.5
			c-0.1-0.5-0.1-1.1-0.2-1.9c-0.4,0.8-0.6,1.4-0.9,1.9c-0.8,0.4-1.5,0.8-2.4,1.3c-1-0.9-2.2-2-3.6-3.2c0.8-0.3,1.3-0.6,1.9-0.8
			c0.6-0.1,1.3-0.3,1.7,0c0.6,0.4,0.9,0.1,1.4-0.1c1.9-0.9,3.6,0.3,3.7,2.5C24.36,117.43,24.36,117.43,22.36,119.23z"/>
		<path class="st0-bull-white" d="M73.66,144.73c-1.2,0.3-1.7,1-1.3,2.4c-1.3-0.5-2-1.4-2.7-2.5c-0.2,0.5-0.3,0.9-0.5,1.5
			c-0.7-0.2-1.3-0.4-2.1-0.7c0-1,0.1-1.8,0.1-2.7c1.4-1.2,2.9-2.3,4.9-1.5C72.56,143.63,72.56,143.63,73.66,144.73z"/>
		<path class="st0-bull-white" d="M16.26,104.73c0.9-0.1,1.8-0.1,3-0.2c0.3-2.5,2.1-3.9,4.3-5.5c0.1,1.2-0.8,1.9-0.2,2.8c0.1,0.1,0,0.5-0.1,0.6
			c-1.7,0.8-1.3,3-2.7,3.9c-0.1,0.1-0.2,0.3-0.3,0.5c-1.2-0.1-2.3-0.2-3.6-0.3C16.46,106.03,16.36,105.43,16.26,104.73L16.26,104.73
			z"/>
		<path class="st0-bull-white" d="M18.86,109.93c-0.6,0.6-0.9,1.1-1.4,1.5c-1.4,1.2-3.3,0.8-4.3-0.8c-0.3-0.5-0.9-1-1.5-1.6
			c0.4-0.3,0.8-0.6,1.2-1c0.4-0.3,0.7-0.6,1.1-1C15.56,108.13,17.56,108.23,18.86,109.93z"/>
		<path class="st0-bull-white" d="M30.66,122.23c-1.7-0.2-3.3-0.3-5.1-0.5c0.3-1.2,0.6-2.7,0.9-4.3c0.5,1,2.8,0.5,1.9,2.6
			C30.36,120.03,30.56,120.23,30.66,122.23z"/>
		<path class="st0-bull-white" d="M16.26,104.73c-1.5,0.4-2.6-0.3-3.8-1.3c1.1-0.9,1.7-2.5,3.5-2.4c0.4,0.6,0.9,1.2,1.4,1.8
			C16.96,103.53,16.66,104.13,16.26,104.73L16.26,104.73z"/>
		<path class="st0-bull-white" d="M69.86,136.73c0.4-0.6,0.7-1,1.1-1.5c0.8-0.1,1.6-0.3,2.5-0.5c0,0.7,0,1.3,0,2c-0.4,0.2-0.9,0.4-1.2,0.8
			c-0.9,0.9-2,1-2.8,0.1c-0.5-0.5-1.1-1-1.7-1.5C69.06,135.33,69.06,135.33,69.86,136.73z"/>
		<path class="st0-bull-white" d="M53.96,129.43c0.8,0.2,1.7-1.4,2.7,0.2c-1.5,0.8-3.2,1.3-3.3,3.6c-1.2-1.1-0.8-2-0.5-3
			c0.3-0.8,0.3-1.7,0.5-2.8c-0.3-0.1-0.8-0.3-1.6-0.5c1.3-0.4,2.2-0.4,2.9,0.5C54.76,128.23,52.96,128.33,53.96,129.43z"/>
		<path class="st0-bull-white" d="M217.36,347.23c0.7,0.6,1.4,1.2,2.4,2c-0.9,0-1.4,0-2.2,0c0.5,0.9,0.8,1.7,1.2,2.5c-0.8,0.1-0.8,0.1-2.9-2
			c0.4-0.3,0.8-0.7,1.3-1.1c-0.1-0.4-0.3-0.9-0.6-1.8C217.06,347.13,217.26,347.23,217.36,347.23L217.36,347.23z"/>
		<path class="st0-bull-white" d="M32.66,123.33c0.9,0.6,1.7,1.1,2.5,1.7c-0.7,0.7-1.3,1.4-2,2.2c-0.3-0.1-0.8-0.3-1.3-0.5
			c0.7-0.6,1.3-1,1.8-1.4C33.66,124.33,31.96,124.93,32.66,123.33z"/>
		<path class="st0-bull-white" d="M38.66,122.83c0.5-0.6,1.1-1.1,1.7-1.7c0.6,0.5,0.6,1.7,2.3,1.3c-1.5,1.4-2.7,0.6-3.7,0.9
			C38.76,123.23,38.66,123.03,38.66,122.83z"/>
		<path class="st0-bull-white" d="M64.66,124.73c0.2,0.7,0.2,1.1,0.4,1.4c0.6,1.6,0.6,1.6-1,2.6C64.06,127.43,63.06,126.13,64.66,124.73z"/>
		<path class="st0-bull-white" d="M9.56,104.93c1.1,0.5,2,1,3,1.5c-0.2,1-1.1,1.1-1.5,1C9.96,107.13,9.56,106.23,9.56,104.93z"/>
		<path class="st0-bull-white" d="M66.36,141.63c-0.3,1.1-0.5,1.8-0.8,2.5c-0.9-0.2-1.7-0.4-2.4-0.6c0-0.1-0.1-0.2-0.1-0.4
			C63.96,142.73,64.96,142.33,66.36,141.63z"/>
		<path class="st0-bull-white" d="M75.86,131.63c0.3,0.6,0.5,1,0.6,1.4c-0.7,0.5-1.4,0.9-2.1,1.4C74.06,133.03,74.06,133.03,75.86,131.63z"/>
		<path class="st0-bull-white" d="M75.46,131.13c0-1,0-1.8,0-2.8c1,0.5,1,0.5,1.5,1.9C76.46,130.53,75.96,130.73,75.46,131.13z"/>
		<path class="st0-bull-white" d="M66.06,96.03c0.5,1.4-1.1,1.6-1.6,2.5c0-1,0-2,0-2.9l0,0C65.26,96.13,65.46,96.23,66.06,96.03L66.06,96.03z"
			/>
		<path class="st0-bull-white" d="M51.46,126.03c0.6-0.7,1.2-1.3,1.8-2.1c0.4,0.7,0.3,1.2-0.2,1.8S52.16,126.63,51.46,126.03z"/>
		<path class="st0-bull-white" d="M66.06,122.13c-1.3,0.1-1.7-0.6-2.1-1.6c0.4-0.2,0.7-0.4,1.3-0.7C65.56,120.63,65.76,121.33,66.06,122.13z"/>
		<path class="st0-bull-white" d="M27.76,98.43c0,0.6,0,1.2,0,2c-0.5,0-0.9,0-1.6,0c0.5-0.7,0.9-1.4,1.2-2C27.56,98.43,27.66,98.43,27.76,98.43
			z"/>
		<path class="st0-bull-white" d="M56.46,122.03c-0.5,0.7-1,1.3-1.5,1.9c-0.2-0.3-0.4-0.9-0.7-1.6C54.96,122.53,55.46,121.13,56.46,122.03z"/>
		<path class="st0-bull-white" d="M23.46,107.23c-0.3,0-0.7,0-1.1-0.1c0.1-0.7,0.1-1.3,0.3-1.8c0.1-0.2,0.5-0.3,0.8-0.5
			c0.1,0.3,0.4,0.6,0.4,0.8C23.76,106.23,23.56,106.73,23.46,107.23L23.46,107.23z"/>
		<path class="st0-bull-white" d="M28.36,103.43c0,0.7,0,1.2,0,1.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5-0.7-1-1.4-1.8-2.4
			C27.16,103.23,27.76,103.33,28.36,103.43z"/>
		<path class="st0-bull-white" d="M66.16,96.03c0.1-1.1,0.4-2.1,1.6-2.9C67.66,94.53,67.36,95.53,66.16,96.03
			C66.06,96.03,66.16,96.03,66.16,96.03z"/>
		<path class="st0-bull-white" d="M63.96,138.23c1,0.3,1.6,0.5,2.2,0.7C65.16,140.23,65.16,140.23,63.96,138.23z"/>
		<path class="st0-bull-white" d="M217.46,347.33c0-0.8,0-1.7,0-2.5c0.3,0.2,0.7,0.6,1.3,1.1C217.96,346.13,218.96,347.63,217.46,347.33
			C217.36,347.23,217.46,347.33,217.46,347.33z"/>
		<path class="st0-bull-white" d="M51.96,118.03c-0.5,0.2-1,0.5-1.8,0.9c0.2-0.7,0.1-1.3,0.4-1.4c0.3-0.2,0.9,0,1.3,0
			C51.96,117.63,51.96,117.83,51.96,118.03z"/>
		<path class="st0-bull-white" d="M60.76,108.83c-0.2,1.1-0.4,2-0.6,3C59.46,110.13,59.46,110.13,60.76,108.83z"/>
		<path class="st0-bull-white" d="M219.96,327.63c0.1,1.3,0.2,2.5,0.3,3.8C219.36,330.23,219.66,328.93,219.96,327.63z"/>
		<path class="st0-bull-white" d="M58.66,121.03c-1.6-1.1-1.6-1.1-0.1-2C58.66,119.63,58.66,120.23,58.66,121.03z"/>
		<path class="st0-bull-white" d="M53.66,134.83c-0.2,0.4-0.4,0.9-0.6,1.3l-0.5-0.2c0.1-0.5,0.2-0.9,0.3-1.3
			C53.26,134.63,53.46,134.73,53.66,134.83z"/>
		<path class="st0-bull-white" d="M63.96,134.13c0.7-0.3,1.3-0.5,1.6,0.3c0.1,0.1,0,0.4-0.2,0.5C64.76,135.33,64.36,134.93,63.96,134.13z"/>
		<path class="st0-bull-white" d="M23.16,110.93c-0.7-0.2-1.2-0.5-0.9-1.3c0-0.1,0.4-0.3,0.5-0.2C23.66,109.63,23.36,110.23,23.16,110.93z"/>
		<path class="st0-bull-white" d="M64.66,129.43c0.7-0.5,1.1-0.8,1.5-1.2C66.56,129.53,66.56,129.53,64.66,129.43z"/>
		<path class="st0-bull-white" d="M23.36,107.23c0.5,0.9,0.5,0.9-0.6,1.4C22.46,107.83,23.06,107.63,23.36,107.23
			C23.46,107.23,23.36,107.23,23.36,107.23z"/>
		<path class="st0-bull-white" d="M63.86,100.63c-0.2-0.2-0.4-0.5-0.6-0.7c0,0,0.1-0.2,0.2-0.3c0.3,0.2,0.5,0.4,0.8,0.6
			C64.16,100.33,64.06,100.43,63.86,100.63z"/>
		<path class="st0-bull-white" d="M72.36,116.33c-0.1,0.6,0.4,1.5-0.9,2.2c0.2-1,0.3-1.6,0.5-2.3C72.06,116.33,72.26,116.33,72.36,116.33z"/>
		<path class="st0-bull-white" d="M19.26,111.83c0.5-0.2,1-0.4,1.7-0.7C20.86,112.83,19.66,111.73,19.26,111.83z"/>
		<path class="st0-bull-white" d="M66.56,134.13c0.3-0.3,0.5-0.5,0.8-0.8c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.3-0.4,0.6-0.6,0.9
			C66.86,134.33,66.66,134.23,66.56,134.13z"/>
		<path class="st0-bull-white" d="M52.66,119.23c0.1,0.6,0.1,1,0.2,1.5c-0.3,0-0.4,0-0.5-0.1C51.66,120.13,51.76,119.63,52.66,119.23z"/>
		<path class="st0-bull-white" d="M819.36,381.43c-1.3,0.2-1.7,1-1.6,2.3c-0.6,0.1-1.1,0.1-1.9,0.2c0.5-1.3,0.4-2.4-0.8-3.2
			c-0.4,0.2-0.8,0.5-1.5,0.9c-0.1-1.3-0.3-2.4-0.3-3.4c0-1.4,0.1-1.5,1.8-2c0-0.6,0.1-1.2,0.1-2c-0.7,0-1.2,0.1-1.9,0.1
			c0-0.9,0-1.6,0-2.5c1,0,1.9,0,2.7,0c1,0,1.9,0.1,2.9,0.2c0.5,0.1,1.1,0.2,1.5,0.6c0.7,0.7,1.3,0.6,2.1,0.3
			c-0.4,0.6-0.7,1.2-1.1,1.8c0.1,0.1,0.2,0.1,0.3,0.2c0.5-0.3,1-0.5,1.4-0.7c-0.2-0.5-0.4-0.9-0.6-1.3c-0.4-0.6-0.3-1.1,0.5-1
			c0.7,0.1,1.5,0.3,2.2,0.4c0.4,0,0.9-0.1,1.3-0.2c0.1,0,0.1-0.1,0.2-0.1c1,0.5,2,0.3,3.1-0.3c0,0.2,0.1,0.3,0.1,0.5
			c-0.4,0.6-0.8,1.2-1.5,2c0.9,0.2,1.6,0.3,2.2,0.4c0.9,0.1,1.2,0.6,0.9,1.5c0.8,0.2,1.5,0.3,2.7,0.6c-0.8-1.2-1.5-2.1-2.2-3.2
			c0.1-0.4,0.3-0.9,0.6-1.6c1.4,0,2.8,0,4,0c0.8,0.7-0.7,1.8,0.8,2.3c0.1-0.7,0.2-1.4,0.3-2.3c4,0,8.1,0,12,0c1.2,1.3-1.2,2.4,0,3.8
			c0.8,1-0.7,2.3-0.4,3.6c0.2,1.3,0.1,2.7,0.1,4.4c-5.3,0.2-10.5-0.1-15.7,0.1c-0.1-0.4-0.2-0.7-0.3-1.1c-1.3,0.3-1.3,1.5-1.8,2.4
			c-0.1,0.1,0,0.3,0,0.4c0.2,5.6-0.6,4.7,4.3,4.8c3.9,0.1,7.9,0,11.8,0c0.7,0,1.5,0,2.4,0c0,1.2,0,2,0,2.9c0.1,1.8-0.3,3.6,0.5,5.3
			c0.2,0.5,0,1.2-0.1,1.8c-0.1,0.6-0.1,1.1-0.2,1.9c-0.9,0.1-2,0.3-3.1,0.3c-3,0-5.9-0.2-8.9-0.2c-1.9,0-3.7,0.2-5.6,0.3
			c-1,0.1-1.2,0.8-1.2,1.6c0,1.9,0.1,3.7,0,5.6c-0.1,1.4,0.6,2.9-0.5,4.1c0,0,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.4,0.9
			c0.5,0.9-0.8,1.5-0.2,2.5c0.4,0.7,0.1,1.9,0.1,3c-1,0-1.9,0.1-2.9,0.1c-3.4,0-6.7,0-10.1-0.1c-0.5,0-1-0.6-1.3-1
			c-0.4-0.6-0.6-1.4-0.9-2.3c0.7,0.1,1.3,0.1,2.1,0.2c-0.9-1-1.6-1.8-2.3-2.7c1.1-1.7,1.1-1.7,0.1-3.2c1.8-0.7,0.3-2.5,1.4-3.5
			c-1-0.4-1.8-0.7-2.7-1.1c0.7-2.1,2.1,0.2,3.2-1.1c0-0.2,0-0.8-0.2-1.4c-0.5-1.2-1.1-2.2-1.6-3.4c-0.2-0.3-0.2-0.8,0-1.2
			c0.7-2.2,0.7-2.2-0.1-3.9c0.5-0.3,1-0.6,1.6-0.9c0-1.2,0-2.4,0-3.8c0.6,0.1,1,0.1,1.5,0.2c0.7-1.2,0.7-1.2,0.1-2.1
			c1.1-1.2,2.1-2.4,3.2-3.6c0.9-1,0.7-1.7-0.6-2.1C820.46,383.43,819.36,382.93,819.36,381.43c1.5-0.3,1.5-2.5,3.2-2.7
			c0.1,0,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0-0.2c-0.7-0.1-1.5-0.2-2-0.2c-0.5,0.8-1,1.5-1.6,2.3
			C819.06,380.43,819.26,380.93,819.36,381.43z M826.36,411.73c0,0.3,0.1,0.7,0,1c-0.6,0.9-0.5,1.8-0.2,2.7c1.1,0.3,2,0.1,2.4-1
			c-0.2-0.5-0.4-1-0.5-1.5C827.76,412.03,827.16,411.63,826.36,411.73c0.2-0.3,0.4-0.7,0.7-1.1c-0.6-0.9-0.6-0.9,0-2
			c-0.6-0.1-1.1-0.2-1.7-0.3c0.2-0.5,0.4-0.8,0.6-1.3c-1.4-0.1-1.9-2.2-3.8-1.2c0.4,0.8,0.7,1.7,1.3,2.2c0.6,0.6,1.6,0.9,2.4,1.3
			C825.06,411.03,825.06,411.03,826.36,411.73z M824.96,382.13c0.9-0.3,1.6-0.6,2.2-0.8c0.2-0.1,0.4-0.3,0.6-0.4
			c0.8,1.3,1.4-0.1,2.1-0.3c0,0.1,1,2.6,1,2.7c1.4-0.5,0.6-1.8,0.9-2.6c0.4-0.8,0.3-1.8,0.4-3c-0.5,0.6-0.8,0.9-1.1,1.2
			s-0.7,0.7-1,1c-0.7-0.3-1.3-0.5-2-0.8c-0.1,0.4-0.2,0.6-0.3,0.9C826.46,379.43,825.56,380.03,824.96,382.13z M819.06,411.83
			c0.9,1.3,1.5,2.7,3,3.2c-0.1,0.4-0.2,0.7-0.4,1.3c1.1-0.5,2.2-1.1,1.6-2c-0.6-1-0.1-1.6,0.2-2.6c-0.8,0.2-1.3,0.3-1.9,0.4
			C820.76,410.63,820.46,410.53,819.06,411.83z M820.66,406.93c-1.1-0.5-2-0.2-2.3,0.7c-0.6,1.5,0.8,1.9,1.6,2.7
			c0.8-0.4,1.5-0.7,2.2-1.1c-0.3-1.3-1.4-0.5-1.9-1C820.46,407.83,820.56,407.33,820.66,406.93z M826.96,395.43
			c0.5-0.7,0.5-1.3-0.3-1.7c-0.6-0.3-0.8-1.4-2-0.7c0.4,0.6,0.8,1.2,1.2,1.8c-0.5,0.4-1.1,0.7-1.5,1.2c-0.7,0.9,0.1,1.3,0.5,1.5
			C825.56,396.73,826.26,396.13,826.96,395.43z M818.36,397.53c0.5,1.6,1.2,3,2.8,3.8c0.4-0.3,0.7-0.7,1.3-1.2
			C820.76,399.53,820.26,397.53,818.36,397.53z M821.06,393.83c2.3-1.7,2.4-2.2,0.3-3.6C821.96,391.63,818.96,392.13,821.06,393.83z
			 M821.86,397.33c0.6-0.9,1.2-1.6,1.9-2.6c-1.2,0-2,0-2.7,0C820.26,395.93,821.16,396.43,821.86,397.33z M824.86,401.63
			c0.5-0.5,0.6-0.7,0.7-0.8c-0.9-0.4-0.7-1.4-1.1-2.1c-0.3-0.5-0.8-1.2-1.8-0.3C823.36,399.43,824.06,400.53,824.86,401.63z
			 M829.46,411.53c0.5-1,0.9-1.9,1.6-3.2c-1,0.5-1.7,0.9-2.4,1.2C828.76,410.23,828.16,411.33,829.46,411.53z M836.76,378.03
			c0.3,0,0.8,0.1,1,0.1c0.2-0.6,0.3-0.9,0.4-1.3c0.1-0.5,0.2-0.9,0.3-1.7c-0.8,0.4-1.3,0.6-1.7,0.7
			C836.76,376.53,836.76,377.23,836.76,378.03z M823.16,404.63c0.1-0.1,0.3-0.3,0.4-0.4c-0.5-0.8-1.1-1.7-1.7-2.6
			c-0.5,0.4-0.8,0.6-1.3,1C821.96,402.93,821.86,404.63,823.16,404.63z M823.56,389.63c1.4,1.5,1.6,1.5,2.4,0.2
			C825.16,389.73,824.56,389.73,823.56,389.63z M838.86,397.63c0-0.8,0.1-1.4,0-2c0-0.2-0.5-0.3-0.7-0.5c-0.1,0.3-0.5,0.7-0.4,0.9
			C837.96,396.63,838.46,397.03,838.86,397.63z M827.66,405.13c-0.5-0.4-0.8-0.7-1.1-0.9c-0.2,0.3-0.6,0.6-0.6,0.8
			c0,0.3,0.3,0.7,0.6,0.8C826.86,405.93,827.16,405.53,827.66,405.13z M823.76,419.63c0.1-0.2,0.2-0.3,0.3-0.5
			c-0.8-0.4-1.5-0.8-2.3-1.2c-0.1,0.2-0.2,0.4-0.3,0.5C822.26,418.83,823.06,419.23,823.76,419.63z M840.06,375.23
			c-1.7,0.9-0.1,1.4-0.3,2.2C841.26,376.63,839.96,376.03,840.06,375.23z M816.76,377.13c-1.1-0.4-1.2,0.4-1.7,0.9
			c0.3,0.2,0.5,0.5,0.7,0.6C816.16,378.03,816.46,377.63,816.76,377.13z M828.86,415.53c0.7,0.7,1.2,0.8,1.6,0.1
			c0-0.1-0.2-0.5-0.2-0.5C829.76,415.23,829.26,415.33,828.86,415.53z M825.56,374.43c-0.3,0.6-0.5,0.8-0.7,1.1c0.6,0.2,1,0.4,2,0.7
			C826.26,375.43,825.96,375.03,825.56,374.43z M839.56,393.03c-0.2,0-0.3,0-0.5,0c0,0.4,0.1,0.8,0.1,1.1c0.1,0,0.2,0,0.3,0
			C839.56,393.73,839.56,393.43,839.56,393.03z M829.16,383.33c-0.1,0.1-0.2,0.3-0.3,0.4c0.4,0.3,0.7,0.6,1.1,0.9
			c0.1-0.1,0.2-0.3,0.3-0.4C829.86,383.93,829.46,383.63,829.16,383.33z M829.76,417.73c-0.8-0.4-1.2-0.1-1.4,0.5
			c0,0.1,0.1,0.2,0.2,0.5C828.96,418.33,829.26,418.03,829.76,417.73z M835.06,381.13c-1-0.5-1-0.5-1,0.8
			C834.46,381.63,834.66,381.43,835.06,381.13z M817.86,412.33c-0.1,1.3-0.1,1.3,0.9,0.9C818.46,412.93,818.26,412.63,817.86,412.33
			z M817.76,396.23c1,0,1,0,0.9-0.9C818.26,395.63,818.06,395.83,817.76,396.23z M831.06,412.03c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.2,0.2-0.4,0.5-0.6,0.7c0.1,0.1,0.4,0.3,0.4,0.3C830.76,412.53,830.96,412.33,831.06,412.03z"/>
		<path class="st0-bull-white" d="M866.66,371.83c0,4.1,0,7.9,0,11.9c-5.3,0-10.5,0-15.8,0c-0.1-0.4-0.3-0.7-0.3-1c0-3,0-5.9,0-8.9
			c0-1.4,0.6-2,2-2C857.26,371.73,861.86,371.83,866.66,371.83z"/>
		<path class="st0-bull-white" d="M852.16,402.33c-0.1-0.8-0.2-1.3-0.3-1.9c0-0.3-0.2-0.8,0-1c1.3-1.6,0.3-3.5,0.7-5.2c0-0.2,0.2-0.4,0.2-0.5
			c-0.4-1.1-2.2-1.5-1.3-3.2c2.5,0,5,0,7.6,0c0,4.1,0,7.9,0,11.9C856.66,402.33,854.36,402.33,852.16,402.33z"/>
		<path class="st0-bull-white" d="M550.36,413.13c-0.6-1.3-0.3-2.6-0.1-4.2c0.7-0.1,1.4-0.1,2.1-0.1c2.3,0,4.7,0,7,0c1.8,0,2-0.3,2.1-2.1
			c0-7,0-14,0-20.9c0-1.8-0.3-2-2.1-2c-1.9,0-3.8,0-6,0c0.1-0.9,0.2-1.7,0.3-2.6c-1.6-0.8-1.5-2.4-1.5-4c0-1.3,0.2-2.7-0.6-4
			c-0.2-0.3,0-0.8,0.1-1.2c1.8-0.5,26.4-0.5,28.2,0c0,16.1,0,32.3,0,48.6c-7.7,0-15.3,0-23.1,0c0.5-0.7,0.9-1.2,1.5-2
			c-1.1,0.2-1.9,0.3-2.7,0.5c0.1,0.5,0.3,0.9,0.4,1.5c-1.5,0-2.9,0-4.4,0c0.1-1.4-0.8-2.1-2.1-2.6c-0.1-1.6-0.4-3.2,0.9-4.4
			C550.56,413.73,550.46,413.33,550.36,413.13c1.1,0.1,2.3,0.3,3.3,0.4c0,0.6-0.1,1.1-0.1,1.6c0.2,0.1,0.4,0.1,0.5,0.2
			c0.3-0.6,0.8-1.2,1-1.8c0.3-1,1-1.3,2-1.4c0.9-0.1,1.9-0.5,2.8-0.7c0-0.1,0-0.3-0.1-0.4c-0.8,0-1.8-0.2-2.2,0.2
			c-1.1,1-1.6,0.3-2-0.5c-1,0.7-1.9,1.7-2.9,1.8C551.86,412.63,551.16,413.03,550.36,413.13z M552.56,417.43c-0.1,0-0.2,0-0.2,0
			c0,0.6,0,1.3,0,1.9c0.1,0,0.2,0,0.2,0C552.56,418.73,552.56,418.13,552.56,417.43z"/>
		<path class="st0-bull-white" d="M527.26,399.73c0.1-0.6,0.9-1,0.3-1.8c-0.2-0.2,0.1-0.9,0.2-1.4c-0.3-0.1-0.6-0.2-0.9-0.3
			c-0.1-1-0.3-1.9-0.4-3c0.5,0.2,1,0.4,1.6,0.7c0.1-0.8,0.1-1.4,0.2-2.2c0.9-0.8,2.1-1.7,3.2-2.6c1.3-1.1,3-1.9,4.1-3.3
			c0.3-0.4,0.6-1.1,0.4-1.4c-0.2-0.4-0.9-0.7-1.3-0.7c-2-0.1-4,0-5.7,0c-0.7-1.1-1.3-1.9-1.9-2.8c-0.2-0.3-0.7-0.8-0.6-1
			c0.5-1.3,0.2-2.5,0-3.8s0-2.7,0-4.3c0.9,0,1.8-0.2,2.5-0.1c2.3,0.4,4.5,0.1,6.8,0c0.9,0,1.8,0.4,2.8,0.6c0.3-0.2,0.7-0.6,1.2-0.6
			c3.3,0,6.6,0,9.9,0c0.2,0,0.4,0.1,0.5,0.2c0.9,3.8,0.8,7.7,0.7,11.8c-1.4,0-2.6,0-3.8,0c-1.9,0-2.4,0.5-2.4,2.5
			c0,1.9,0.1,3.9,0.1,5.8c0.1,5.4,0.1,10.7,0.2,16.1c0,0.1-0.1,0.3-0.2,0.6c1.2,0.1,2.4,0.2,3.6,0.3c0.3,2.1-1,4.4-2.8,4.9
			c-0.5,0.1-1-0.2-1.5-0.2c-0.6-0.1-1.2-0.3-1.7-0.1c-0.8,0.2-1.6,0.7-2.4,1.1c-0.2,0.1-0.4,0.5-0.7,1c2.8-0.2,5.2-0.4,7.6-0.6
			c1.3,1.9,1.3,1.9,1.2,3.7c-0.7,0.3-1.5,0.6-2.3,0.9c-0.4-0.7-0.8-1.2-1.1-1.7c-0.6,0.2-1.1,0.4-1.8,0.6c0.1,0.7,0.3,1.4,0.4,2.2
			c-3.6,0-7.2,0-10.8,0c-0.4,0-0.7-0.3-1.1-0.5c-0.2-0.1-0.4-0.4-0.6-0.6c-1.4-1.6-3.2-3.1-4-5c-0.7-1.7-0.2-3.9-0.2-5.9
			c0-2.7,0-5.4,0-8.1C526.16,400.03,526.16,399.33,527.26,399.73c0,0.3,0.1,0.7-0.1,1.1c-0.4,0.8-0.4,1.5,0,2.3c1.4,0,1.4,0,2.3-1.1
			c-0.3-0.5-0.5-1.1-0.9-1.5C528.26,400.13,527.66,400.03,527.26,399.73z M535.96,416.23c-0.1-0.1-0.1-0.3-0.2-0.4
			c-2,0.3-2,0.3-2.8-0.8c-1.2,0.4-1.9,1.2-2.2,2.9C532.66,417.23,534.36,416.73,535.96,416.23z M530.36,399.53c0.5-1.1,1-2,1.6-3.3
			c-1,0.5-1.7,0.9-2.4,1.3C529.66,398.23,529.16,399.13,530.36,399.53z M539.26,385.43c0.1,0,0.3,0,0.4-0.1c0-0.6,0-1.2-0.1-1.8
			c0-0.1-0.4-0.3-0.6-0.3c-0.2,0-0.4,0.4-0.4,0.6C538.76,384.33,539.06,384.83,539.26,385.43z M530.76,405.73
			c-0.1-0.2-0.2-0.3-0.3-0.5c-1-0.2-1,0.7-1.4,1.2c0.1,0.1,0.2,0.2,0.3,0.4C529.76,406.33,530.26,406.03,530.76,405.73z
			 M538.16,399.53c-0.1-0.2-0.2-0.4-0.3-0.7c-0.4,0.2-0.8,0.4-1.2,0.6c0.1,0.2,0.3,0.6,0.3,0.6
			C537.36,399.83,537.76,399.63,538.16,399.53z M529.76,403.43c0.1,0.2,0.1,0.3,0.2,0.5c0.5-0.2,1-0.3,1.5-0.5
			c-0.1-0.2-0.2-0.5-0.3-0.7C530.66,403.03,530.16,403.23,529.76,403.43z M536.66,411.33c0-0.7-0.5-1.1-1.3-1.6
			C535.26,410.93,535.96,411.13,536.66,411.33z M539.86,382.33c0.7-0.6,0.9-1.1,0-1.6C539.86,381.33,539.86,381.83,539.86,382.33z
			 M548.06,374.13c0.1-0.6,0.3-1.1,0.5-2C547.36,372.73,547.76,373.43,548.06,374.13z M536.56,415.63c0.1,0.2,0.2,0.4,0.2,0.6
			c0.4-0.2,0.9-0.4,1.3-0.5c0-0.1-0.1-0.2-0.1-0.4C537.56,415.43,537.06,415.53,536.56,415.63z M535.76,373.73c0-0.1,0-0.3,0-0.4
			c-0.4,0-0.7,0-1.1,0c0,0.1,0,0.2,0,0.3C535.06,373.63,535.36,373.63,535.76,373.73z M534.76,376.83c0.1,0,0.3,0,0.4-0.1
			c-0.1-0.3-0.1-0.7-0.2-1c-0.1,0-0.3,0-0.4,0.1C534.66,376.13,534.76,376.53,534.76,376.83z M535.76,404.33c0-0.1,0-0.2-0.1-0.4
			c-0.3,0.1-0.6,0.1-0.8,0.2c0,0.1,0.1,0.4,0.1,0.4C535.16,404.43,535.46,404.33,535.76,404.33z"/>
		<path class="st0-bull-white" d="M671.56,402.33c-0.5,0.3-0.8,0.2-0.9-0.3c-0.1-0.5-0.1-1.1-0.1-1.7c0-0.9,0.1-1.8,0-2.7
			c-0.6-3.3,0.8-5.4,3.5-7.2c1.9-1.2,3.6-2.8,5.3-4.2c0.5-0.4,1.2-0.8,1-1.6s-0.9-0.7-1.5-0.9c-0.4-0.1-0.7-0.5-1-0.8
			c-0.3,0.3-0.6,0.8-1,0.8c-2.1,0.1-4.1,0-6.3,0c0-4,0-7.9,0-11.9c2.1,0,4.1,0,6.3,0c0.1,0.9,0.2,1.9,0.2,2.8c0.1,0,0.2,0,0.3,0
			c0.1-0.9,0.1-1.7,0.2-2.6c1.5-0.4,33-0.5,35.4,0c0,1.9,0.5,3.8-0.3,5.7c-0.5,1.1-0.6,2.4-0.8,3.6c-0.4,2.7-0.8,5.3-1.3,8
			c-0.3,1.4-0.8,2.8-1.3,4.2c-0.1,0.3-0.7,0.4-1.5,0.8c0.5,0.4,1,0.7,1.3,0.9c-0.5,1.8-1,3.5-1.5,5.4c-1.1-0.9-1.8-1.9-1.8-3.4
			c0-3.7,0-7.4,0-11.2c0-2.1-0.1-2.2-2.2-2.2c-4.1,0-8.3,0-12.4,0c-2,0-2.3,0.2-2.3,2.3c0,6.8,0,13.7,0,20.5c0,1.8,0.4,2.2,2.2,2.2
			c1.1,0,2.2,0,3.3,0.1c0.9,0.1,1.7,0.3,2.8,0.4c0.4-0.1,1.1-0.5,1.7-0.5c1.8-0.1,3.6,0,5.4,0c1.3,0,1.6-0.3,1.6-1.6
			c0-2.1,0-4.1,0-6.4c0.8,0.3,1.3,0.5,2,0.7c0.1,0.8,0.2,1.7,0.2,2.5c0.2,0,0.3,0,0.5,0c0.3-1.7,0.6-3.4,0.9-5.1
			c0-0.2-0.1-0.4,0-0.6c0.9-1.8,1.2-3.7,1.5-5.6c0.4-2.4,1-4.9,1.5-7.3c0.3-1.2,0.4-2.5,0.7-3.7c0.9-2.8,1.4-5.7,1.2-8.6
			c0-0.3,0.1-0.7,0.1-1.3c3.2,0,6.4,0,9.9,0c0,2.2,0,4.3,0,6.3s0,4,0,6c0,2.1,0,4.1,0,6.2c0,2.1-0.1,4.1,0,6.2
			c0.1,1.9-0.5,3.8-0.1,5.8c0.4,2,0.1,4.1,0.1,6.1s0,4,0,6c0,2.1,0,4.1,0,6.3c-0.7,0.1-1.3,0.1-1.8,0.1c-5.3,0-10.6,0-16,0
			c-0.8,0-1.7,0.3-1.9-0.6c-0.7,0.2-1.3,0.6-1.8,0.6c-5.3,0-10.5,0.1-15.8,0c-2.1,0-4.2-0.3-6.3-0.3c-1.2,0-2.3,0.3-3.5,0.3
			c-1.8,0-2.4-0.4-3-2.1c-0.2-0.5-0.4-0.7-0.8-0.9c-1-0.6-2.2-1.1-1.9-2.7c-1.3-0.8-0.9-2.2-1-3.3c-0.1-1.3-0.1-2.6,0-3.9
			c0.1-1.3,0.2-2.6,0.3-3.9C670.96,403.43,671.26,402.93,671.56,402.33c0.5,0.3,1.1,0.6,1.6,0.8c0.9-0.3,1.5-1.9,2.8-0.8
			c0.5-0.8,2,0.1,2.4-1.4c-0.6-0.1-1.2-0.2-1.6-0.1C674.96,401.53,673.06,401.43,671.56,402.33z M714.36,397.43
			c1,0.1,1.3-0.4,1.2-1.4C714.86,396.33,714.26,396.63,714.36,397.43c-0.7,0.9-0.7,0.9-0.1,1.7
			C714.36,398.53,714.36,397.93,714.36,397.43z M679.66,398.83c-0.2-0.3-0.3-0.6-0.5-1.1C678.56,398.83,679.26,398.73,679.66,398.83
			c-0.1,0.2-0.3,0.5-0.5,0.8c-0.6,1.1-0.5,2.4,0.4,3.3c0.1,0.1,0.2,0,0.4,0c-0.1-0.4-0.2-0.9-0.4-1.3c2.3-1,2.3-1,2.7-1.9
			C680.76,399.93,680.76,399.93,679.66,398.83z M706.36,416.23c2.1-2.4,2.2-4.2,0.7-5.2C706.86,412.63,706.66,414.13,706.36,416.23z
			 M718.06,392.33c-0.2-0.3-0.4-0.8-0.7-1.4c-0.5,0.6-1,1-1.4,1.5c0.9,0.8,0.4,1.3-0.1,1.9c-0.5,0.5-0.4,0.9,0.4,1.1
			C716.76,394.43,717.36,393.43,718.06,392.33z M677.86,375.73c-0.2,0-0.4,0-0.6,0.1c0.2,1.2,0.4,2.5,0.6,3.7
			C678.26,378.23,678.66,376.93,677.86,375.73z M680.16,406.63c-2.3,1.4,0,2.3-0.3,3.3C680.66,408.93,679.76,407.93,680.16,406.63z
			 M710.86,401.23c1,0,1.6,0,2.6,0c-0.6-0.7-0.9-1.1-1.3-1.6C711.66,400.13,711.46,400.53,710.86,401.23z M720.06,385.13
			c-1.4,0.6-1,1.6-1,2.9C719.56,387.03,720.76,386.53,720.06,385.13z M712.06,405.43c-1.5-0.1-1.8,0.3-1.9,1.8
			C710.76,406.63,711.36,406.13,712.06,405.43z M697.46,413.03c-1.7,0.7-1.7,0.7-1.5,1.4c0.5,0,1,0,1.5,0
			C697.46,414.03,697.46,413.63,697.46,413.03z M679.26,413.23c0.3,0,0.7,0.1,1.4,0.2c-0.7-0.7,0.4-1.8-0.9-2.2
			C679.56,411.93,679.36,412.53,679.26,413.23z M685.66,399.13c-0.6-0.1-1.2-0.5-1.4,0.4c0,0.2,0.1,0.5,0.2,0.5
			C685.16,400.23,685.46,399.83,685.66,399.13z M708.76,410.33c0.1-0.2,0.1-0.5,0.2-0.7c-0.5-0.1-0.9-0.2-1.4-0.3
			c0,0.2-0.1,0.5-0.1,0.5C707.86,410.03,708.36,410.23,708.76,410.33z M674.56,410.53c0.1,1,0.1,1,1.5,0.7
			C675.46,410.93,674.96,410.73,674.56,410.53z M682.46,409.33c0.2,0.5,0.4,0.9,0.7,1.5C684.26,409.73,683.46,409.53,682.46,409.33z
			 M678.76,389.23c0-0.6,0-1-0.1-1.6C677.66,388.33,677.86,388.73,678.76,389.23z M703.66,414.73c0,0.2,0,0.3,0.1,0.5
			c0.3-0.1,0.6-0.1,0.9-0.2c0-0.1,0-0.2,0-0.3C704.26,414.73,703.96,414.73,703.66,414.73z"/>
		<path class="st0-bull-white" d="M399.06,418.03c0.6,0.8,0.8,1.6,0.1,2.7c-4.7,0-9.5,0-14.4,0c-0.6-0.7-0.8-1.5-0.8-2.4c0-0.7-1.5-1.1-0.6-2.4
			c0.4,0.1,0.9,0.3,1.3,0.5c2.2-0.9,2.3-3,2.9-4.9c0.3-1.1,0.6-2.2,0.9-3.4c-0.2-0.2-1.2-0.3-0.5-1.2c-0.5-0.3-1-0.7-1.6-1.1
			c0.5-0.8,1-1.4,1.5-2.2c-0.5,0-1.1-0.1-1.6-0.1c-0.5,0.7-1,1.4-1.5,2.1c-0.7-2-0.6-3.3,0.2-5c0.4-0.9,0.1-1.4-1.1-1.7l0,0
			c0.2-1.4-1.1-1.8-2-2.7c0.8-0.5,1.7-1.1,0.7-2.4c-0.4,0.4-0.8,0.8-1.2,1.1c-0.6-0.8,0.1-2.1,1.4-2.3c0.4,0,0.8,0,1.4,0
			c-0.2-1.3,0.4-2,1.3-2.6c1.1-0.7,2.1-1.5,3.2-2.2c0.1,0.5,0.2,1.1,0.3,1.6c1.8-0.3,1.9-0.4,1.5-1.8c-0.1-0.6,0-1.3,0.2-1.9
			c0.1-0.5,0.5-0.9,1.1-1.7c-2-0.1-3.7-0.1-5.4-0.2c-1.7-0.1-3.3-0.1-5.1-0.1c0-3.1,0-6.2,0-9.4c0.7-0.7,1.5-1.4,2.3-2
			c1.2-1,1.8-0.8,2.5,0.7c0.2-0.3,0.3-0.6,0.5-1c0.2,0.1,0.4,0.3,0.4,0.4c0,0.8,0.1,1.5,1.1,1.6c-0.1,1.3-1.3,0.5-1.8,1.2
			c-0.4,0.7-1.2,1.1-2,1.8c0.1,1.2,0.9-0.2,1.3,0.1c0.1,0.2,0.3,0.5,0.5,1c1-1.2,1.9-2.3,2.8-3.3c0.6-0.7,1.1-1.4,1.7-2
			c1.1,0.7,1.6-0.7,2.6-0.6c1.5,0.1,3.1-0.2,4.6-0.3c0.7,1.8,0.9,1.7,2.3,0c11.5,0,23,0,34.8,0c0,7.2,0,14.3,0,21.6
			c-3.3,1.6-6.5,3.1-9.7,4.6c-0.2,0.1-0.5,0.2-0.8,0.3c-0.7,0.3-1.5,0.7-1.5,1.5c0,0.9,0.5,1.7,1.3,2.2c3.2,1.9,6.4,3.7,9.6,5.6
			c0.4,0.2,0.8,0.5,1.2,0.8c0,4,0,8,0,12.1c-0.4,0.1-0.8,0.2-1.3,0.2c-3.1,0-6.2,0-9.3,0c-0.5,0-1,0-1.4-0.3c-3-2-6.1-4-9.1-6.1
			c1.1,0.1,1.4-0.9,2-1.5c0.1-0.1-0.4-0.6-0.7-1.1c-0.5,1.1-0.9,1.9-1.2,2.7c-2.6-1.2-4.9-2.9-5.1-3.6c-1.3-0.6-2.4-1.1-3.4-1.7
			c-1.2-0.7-2.3-1.5-3.4-2.2c-1.5-0.9-2.5-0.4-2.4,1.3c0.1,2.8-0.6,5.7-0.1,8.5C399.66,417.23,399.26,417.63,399.06,418.03
			c0.1-1.2,0.1-1.2-1.3-0.9C398.36,417.43,398.76,417.73,399.06,418.03z M408.36,383.83L408.36,383.83c-2.3,0-4.6,0-6.8,0
			c-1.5,0-1.8,0.3-1.8,1.9c-0.1,2-0.2,4-0.2,6c0,1.8,1,2.4,2.5,1.6c4.5-2.1,8.9-4.3,13.4-6.4c0.9-0.4,1.3-0.9,1.2-1.9
			c-0.2-1.1-1-1.1-1.9-1.1C412.66,383.83,410.56,383.83,408.36,383.83z M391.56,400.23c0,0-0.2,0-0.4,0c-1.5,0-3,0.1-3.9-1.6
			c-0.1-0.2-0.8-0.1-1.1-0.1c0.1,0.8,0.2,1.5,0.2,2.2c-0.1,0.7-0.3,1.4-0.4,2c0.1,0.1,0.2,0.1,0.3,0.2c0.6-0.4,1.3-0.9,2-1.4
			c1.1,0.7,2.7,1,3.4,2c0.9,1.4,2.1,1,3.2,1.2c0.1,0,0.2,0.2,0.3,0.2c0.1,0.5-1,1.1,0,1.5c0.7,0.3,0.9-0.4,1.1-0.9
			c0.1-0.2,0.2-0.5,0.3-0.7c-0.7-0.5-1.8-0.9-1.9-1.4c-0.2-1.1-2.1,0.2-1.7-1.5C393.26,401.63,392.26,400.93,391.56,400.23z
			 M393.26,419.13c0.2-0.8,0.6-1.6,0.6-2.3s-0.3-1.4-0.4-2.1c-0.1-0.6-0.2-1.2-0.3-1.8c-0.2,0-0.3,0-0.5,0c-0.2,0.3-0.5,0.6-0.5,0.9
			c-0.1,0.7,0.2,1.6-0.1,2.2C391.36,417.43,392.06,418.23,393.26,419.13z M390.96,390.93c-0.9,0.6-1.9,0.8-0.9,1.8
			c0.6,0.6,0.5,1.2,0.1,1.8c-0.1,0.2-0.2,0.5-0.2,0.6c1,0.6-0.2,1.2-0.1,1.9c1.2,0.1,1.5-0.7,2.1-1.4
			C391.66,394.13,391.36,392.63,390.96,390.93z M388.86,417.23c0-1,0.1-2,0.1-3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0.5-0.3,1-0.5,1.6
			c-0.3,0-0.6-0.1-0.8-0.1c-1.1,1.4-1.6,2.9-1.4,4.7c0.7-1,1.3-1.8,2-2.8c0.2,0.1,0.5,0.3,0.8,0.3c0.4,0.1,0.8,0,1.2,0
			c0-0.1,0-0.2,0-0.3C389.66,417.53,389.36,417.43,388.86,417.23z M384.46,395.43c1.3,1,1.7,0.8,1.9-0.4c0.1-1.3-0.1-2.7,0.7-3.9
			c0.1-0.1-0.1-0.4-0.2-0.6c-0.1,0-0.3,0-0.4-0.1c-0.3,0.7-1,1.7-0.7,2.2C386.16,394.03,385.66,394.63,384.46,395.43z
			 M391.06,383.03c0.2,0.1,0.4,0.2,0.7,0.2c-0.5-1.5,0.4-1.9,1.2-2.4c0.5-0.3,0.8-0.8,0.2-1.4c-1.2,0.3-2.2,1-2.5,2.2
			C390.46,382.03,390.86,382.53,391.06,383.03z M419.06,398.43c-0.5,1.6-0.9,2.9-1.3,4.3C419.16,402.43,420.06,399.83,419.06,398.43
			z M393.06,409.23c-0.3,0.4-0.6,0.8-1.2,1.5c0.8-0.1,1.1-0.2,1.5-0.2c-0.1,0.5-0.1,1-0.2,1.9c0.8-0.8,1.3-1.3,1.8-1.8
			C394.36,410.23,393.76,409.73,393.06,409.23z M395.06,408.23c-0.5-0.5-1-1-1.5-1.3s-1.2-0.4-1.8-0.6
			C392.36,407.83,393.16,408.33,395.06,408.23z M389.16,410.23c0.2,1,0.4,1.7,0.7,2.7c0.4-0.8,0.7-1.3,1-1.9
			C390.36,410.73,389.86,410.53,389.16,410.23z M406.66,377.63c0.5-0.6,1.4-0.7,0.9-1.6c-0.1-0.2-0.5-0.3-0.7-0.2
			C406.06,376.13,406.26,376.73,406.66,377.63z M396.36,382.83c-0.4-0.4-0.5-0.8-0.8-0.8c-0.2-0.1-0.6,0.2-0.8,0.3
			c0.2,0.3,0.3,0.6,0.5,0.7C395.46,383.13,395.86,382.93,396.36,382.83z M418.26,404.63c-0.8-1.4-0.9-1.4-1.9,0
			C416.96,404.63,417.46,404.63,418.26,404.63z M400.66,382.93c1-0.9,1-0.9-0.1-1.5C400.56,382.03,400.66,382.53,400.66,382.93z
			 M393.36,384.53c-0.7,1.7-0.7,1.7,0,2.2C393.36,386.03,393.36,385.43,393.36,384.53z M395.86,403.43c0.2-0.5,0.5-1,0.8-1.7
			C395.16,401.73,395.46,402.53,395.86,403.43z M413.76,394.63c-0.3-0.3-0.6-0.6-0.7-0.6c-0.2,0.1-0.5,0.3-0.6,0.5
			c0,0.1,0.3,0.5,0.4,0.5C413.16,395.03,413.36,394.83,413.76,394.63z M391.06,416.63c0.2-0.3,0.4-0.5,0.5-0.7
			c-0.2-0.2-0.5-0.3-0.7-0.5c-0.1,0.2-0.3,0.4-0.3,0.6S390.76,416.33,391.06,416.63z M382.26,375.03c-0.1,0.1-0.1,0.2-0.2,0.4
			c0.4,0.2,0.6,1.1,1.7,0.4C383.06,375.53,382.66,375.33,382.26,375.03z M418.26,396.63c0.1,0.1,0.2,0.1,0.3,0.2
			c0.3-0.4,0.6-0.7,0.8-1.1c-0.5-0.1-0.8,0-1,0.2C418.36,396.13,418.36,396.43,418.26,396.63z M395.06,374.43c0,0.1,0,0.2,0,0.4
			c0.1,0.1,0.3,0.2,0.3,0.2c0.2-0.1,0.4-0.3,0.6-0.4C395.66,374.53,395.36,374.53,395.06,374.43z M406.96,382.03c-0.1,0-0.3,0-0.4,0
			c0,0.3,0,0.6,0,0.9c0,0,0.2,0,0.4,0C406.96,382.63,406.96,382.33,406.96,382.03z M422.26,411.63c-0.4-0.3-0.6-0.4-0.7-0.5
			c0,0-0.2,0.2-0.2,0.3c0,0.2,0.1,0.4,0.3,0.4C421.76,411.93,421.96,411.83,422.26,411.63z"/>
		<path class="st0-bull-white" d="M239.26,398.63c-0.3-0.5-0.2-1,0.5-1s1.5,0.1,2.2,0.1c0.4,0,0.8-0.1,1.7-0.1c-1.4-1-1.7-2.3-3-2.9
			c-0.7-0.3-0.8-2-0.2-2.6c1-1,2.1-1.9,3.2-2.9c0.2,0.3,0.4,0.5,0.6,0.6c0.1,0,0.3,0,0.4-0.1c0.6-1.4,1.4-2.6,2.6-3.5
			c0.4-0.3,0.4-0.9,0.6-1.5c-1.1-0.8-2.2-1.1-3.4-1.1c-1.8-0.1-3.7-0.2-5.5-0.3c-0.8-1.5-0.4-3-0.4-4.4c1.9-0.6,3.5-2,5.6-1.5
			c0.5-0.7,1-1.3,1.4-1.8c1.2,0.3,2.2,0.5,3.6,0.7c-0.3-1-0.6-1.4-1.2-1.3c-1.5,0.2-2.5-0.4-3-2.2c-1.9,1-3.8,1.2-5.4-0.9
			c0.7-0.1,1.3-0.3,1.8-0.3c5.2,0,10.4,0,15.5,0.1c1,0,2.1-0.1,3.1-0.1c0.5,0,1.1,0.1,1.5,0.3c0.7,0.5,1.1,0.4,1.8,0
			c0.5-0.3,1.3-0.2,2.2-0.4c-0.3,0.5-0.4,0.8-0.5,1.1c0.5,0.6,1,1.4,1.7,1.8c0.6,0.3,1.5,0,2.4-0.1c-0.6-1-1.6-0.3-2.2-0.7
			c-0.3-0.5-0.6-1.1-0.9-1.6c1.5-0.5,21-0.7,25.9-0.3c0,7.1,0,14.3,0,21.7c-3.7,1.4-7,3.7-11,4.8c-0.3,0.1-0.6,0.1-0.8,0.3
			c-3,2.2-6.5,3.3-9.8,4.9c-4.1,1.9-8.3,3.6-12.3,5.8c-1.6,0.9-1.8,1.2-1.4,2.6c-0.5,0.3-1,0.6-1.6,1c-0.2,0-0.4-0.1-0.6-0.1
			c-0.4-0.1-0.8-0.3-1.2-0.4c0,0-0.3,0.4-0.3,0.5c0.3,0.4,0.6,0.9,1,1.2c0.2,0.1,0.6,0,0.8,0.1c0.7,0.5,1.7,0.9,1.8,1.5
			c0.2,1.5,0.1,3,0,4.5c0,0.3-0.8,0.7-0.8,0.7c-1-0.8-1.9,0-2.9,0s-2.1,0-3.1,0c-1.6,0-3.1-0.1-4.7-0.2c-0.1,0-0.3-0.1-0.4-0.1
			c-2.4,1.1-3.5-0.4-4.6-2.2c-0.3-0.6-0.9-1.1-1.2-1.7c-0.3-0.5-0.6-1-0.5-1.5c0.4-3.1-0.4-6.2,0.1-9.2c0.5-0.1,1-0.1,1.9-0.3
			c-0.8-0.8-1.3-1.2-2-2C238.96,402.23,237.96,400.33,239.26,398.63c0,1.1,0,2.2,0,3.3c1.3,0.3,2.1-0.1,1.9-0.9
			c-0.1-0.8-0.4-1.6-0.6-2.3C240.06,398.73,239.66,398.73,239.26,398.63z M255.76,398.93c-1.2-0.1-1.7,0.8-2.5,1.5
			c0.5,0.3,0.9,0.5,1.5,0.8c-0.5,0.3-0.9,0.5-1.4,0.9c0.8,0,1.2,0,1.8,0c0-1.1,0.3-1.9,1.3-2.5c0,0,0-0.3,0-0.4
			c0.3-0.7,0.1-1.3-0.5-1.8c-0.3-0.7-0.5-1.5-0.9-2.2c-0.5-1-1.6,0.1-2.3-0.5c0,0-0.2,0.1-0.1,0.1c-0.1,1-0.3,1.9-0.4,2.9
			c0.7-0.2,1.2-0.3,1.7-0.5l0,0c0.1,0.3,0.2,0.7,0.4,1c0.7-0.3,1.3-0.5,1.8-0.8C255.76,397.93,255.76,398.43,255.76,398.93z
			 M261.06,400.03L261.06,400.03c-0.6-0.3-1.2-0.7-1.9-1.1c-0.2,0.4-0.7,0.9-0.6,1c1,1.3,0.2,2.1-0.6,3c-0.2,0.2-0.1,0.5-0.2,1.1
			c1.7-1.8,3.6-2,5.6-2.1C262.96,400.33,262.96,400.33,261.06,400.03z M265.46,383.83c-2.1,0-4.3,0-6.4,0c-1.8,0-2,0.3-2,2.1
			c0,2,0,4,0,6c0,1.7,0.7,2.1,2.2,1.4c4.6-2.2,9.2-4.4,13.7-6.6c0.4-0.2,0.8-0.6,0.9-1c0.4-1.1-0.2-1.8-1.5-1.9
			C270.16,383.83,267.76,383.83,265.46,383.83z M245.46,383.53c0.3-0.7,0.7-1.1,0.6-1.3c-0.6-1-0.7-2,0-3.1c-1.4,0-2.6,0-3.9,0
			c0.4,1,0.7,1.8,1,2.7C244.16,381.63,244.86,382.23,245.46,383.53z M247.46,378.73c-0.1,0.1-0.1,0.2-0.2,0.3
			c0.6,0.2,1.3,0.4,1.8,0.5c-0.1,0.7-0.3,1.3-0.4,2c1-1,1.8-2,2.3-3.1c0.6-1.2,0.9-2.4,1.4-3.6c-0.2-0.1-0.4-0.1-0.5-0.2
			c-0.5,1.2-1,2.3-1.5,3.5C249.36,378.33,248.36,378.53,247.46,378.73z M248.56,410.43c-0.8,0.3-1.3,0.5-1.8,0.7
			c0,1.8,0,1.8,1.8,1.3C248.56,411.93,248.56,411.33,248.56,410.43z M257.16,404.73c-1.4-0.2-1.8,0.5-1.9,1.7c0.8,0,1.4,0,2,0
			C257.26,405.83,257.26,405.33,257.16,404.73z M243.96,414.33c-2.2,0.2-2.7,0.5-3,1.5C242.46,416.23,243.26,415.43,243.96,414.33z
			 M275.86,388.23c0.2,0.1,0.3,0.2,0.5,0.3c0.4-0.3,0.9-0.6,1.5-1c-0.7-0.6-1.2-1-1.9-1.6
			C275.86,386.83,275.86,387.53,275.86,388.23z M243.96,408.63c-1.5,0.6-1.2,1.8-1,3c0.6-0.8,1.1-1.5,1.7-2.3
			C244.36,409.13,244.16,408.93,243.96,408.63z M254.26,378.43c-0.6,0.9-1,1.4-1.3,1.9c1.2,0.6,2.2,1,3.1,0.1
			C255.86,379.23,254.06,380.43,254.26,378.43z M263.46,395.13c0.1,1.1,0.1,1.7,0.2,2.5c0.7-0.1,1.3-0.2,1.8-0.3
			c0.1-0.1,0.1-0.3,0.2-0.4C265.06,396.53,264.46,396.03,263.46,395.13z M278.86,379.13c1.5,0.3,2.6,0.5,4.1,0.8
			c-1.1-1.4-2.6-0.9-3.5-1.7C279.26,378.53,279.06,378.73,278.86,379.13z M266.96,402.33c-1.2-0.4-1.8-0.6-2.8-0.8
			c0.4,0.9,0.6,1.4,0.9,2.2C265.66,403.33,266.06,403.03,266.96,402.33z M266.06,396.43c0.7,1.2,1.2,0.4,1.6-0.1
			c0.2-0.2,0.1-0.7,0.1-1c-0.4,0.2-0.8,0.3-1.1,0.5C266.36,395.93,266.26,396.23,266.06,396.43z M277.76,398.83
			c-1.2-0.7-1.8-1.6-3.1-0.9C275.26,399.33,276.36,398.83,277.76,398.83z M254.26,411.03c0.6-0.3,1.2-0.6,2-1
			C255.06,409.43,254.46,409.93,254.26,411.03z M245.26,404.03c-0.1-0.2-0.2-0.4-0.3-0.7c-0.4,0.2-0.8,0.4-1.2,0.6
			c0,0,0.2,0.4,0.3,0.6C244.36,404.43,244.76,404.23,245.26,404.03z M257.76,406.73c1.1,1.3,1.1,1.3,1.9,0.2
			C259.06,406.83,258.56,406.73,257.76,406.73z M251.06,412.53c-0.5-1.5-0.6-1.6-1.3-1.5c-0.2,0-0.3,0.5-0.5,0.8
			C249.96,412.13,250.46,412.33,251.06,412.53z M250.16,399.93c0.5-0.7,0.6-1.4-0.6-1.6C249.76,399.03,249.96,399.43,250.16,399.93z
			 M246.96,398.13c0-0.1,0-0.2,0-0.3c-0.4,0-0.8,0-1.2,0c0,0.1,0,0.2,0,0.3C246.16,398.13,246.56,398.13,246.96,398.13z
			 M272.06,396.23c0.1,0,0.3-0.1,0.4-0.1c0-0.2,0.1-0.6-0.1-0.7c-0.2-0.2-0.5-0.1-1.2-0.3
			C271.66,395.73,271.86,396.03,272.06,396.23z M271.66,373.33c0,0.1,0,0.2,0,0.3c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0,0.4-0.2,0.7-0.3
			c0-0.1-0.1-0.2-0.1-0.3C272.46,373.23,272.06,373.33,271.66,373.33z M255.86,375.43c0.2,0,0.3,0,0.5,0c-0.1-0.4-0.1-0.9-0.2-1.3
			c-0.1,0-0.2,0-0.3,0C255.86,374.63,255.86,375.03,255.86,375.43z M242.56,407.93c-0.6,0.1-1.1,0.1-1.5,0.1
			C241.56,408.93,241.96,409.03,242.56,407.93z M251.06,409.73c-0.1,0.1-0.2,0.3-0.3,0.4c0.2,0.2,0.4,0.4,0.7,0.6
			c0.1-0.1,0.3-0.4,0.3-0.4C251.56,410.03,251.36,409.93,251.06,409.73z M290.96,378.83c0.2,0.6,0.3,1.1,0.5,1.6
			C291.76,379.73,292.26,379.13,290.96,378.83z"/>
		<path class="st0-bull-white" d="M548.86,420.73c-0.8,0.2-1.5,0.5-1.9-0.4c-0.1-0.1,0-0.5,0.2-0.5C548.06,419.33,548.26,420.13,548.86,420.73z
			"/>
		<path class="st0-bull-white" d="M386.36,411.13c-0.2,0.3-0.3,0.7-0.5,0.7c-0.4,0.1-0.9,0.1-1.3,0.1c-0.1,0-0.2-0.5-0.2-0.5
			c0.4-0.2,0.7-0.4,1.1-0.6C385.76,410.93,385.96,411.03,386.36,411.13z"/>
		<path class="st0-bull-white" d="M26.06,93.93c0.3-0.3,0.5-0.5,0.6-0.7c0.2,0.2,0.4,0.3,0.5,0.5c0,0.1-0.2,0.5-0.3,0.5
			C26.66,94.23,26.46,94.03,26.06,93.93z"/>
		<path class="st0-bull-white" d="M57.56,109.53c0.3,0,0.5-0.1,0.8-0.1c0,0.2,0,0.3,0,0.5c-0.3,0-0.6,0-0.8,0.1
			C57.66,109.93,57.56,109.73,57.56,109.53z"/>
	</g>
</g>
</svg>

    </div>
  )
}

export default BullProofWhite
