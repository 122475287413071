import React from 'react'
import "./BullProof.css"
const Rivaj = ({color}) => {
  return (
    <div>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 884.7 316.4" style={{enableBackground:"new 0 0 884.7 316.4"}} space="preserve">

<g>
	<path class={color} d="M127.79,270.24c-14-2.75-18.31-6.33-17.53-18.95c0.89-14.28,2.19-29.15,7-42.44
		c11.9-32.9,25.98-65.01,39.02-97.49c5.68-14.15,11.22-28.36,16.36-42.71c3.94-11.01,2.12-13.35-9.59-15.7
		c-11.16-2.23-22.75-3.67-30.95-12.95c1.31-9.05,7.98-12.26,14.59-13.99c23.47-6.11,46.88-13.12,70.77-16.79
		c39.35-6.04,78.95-5.49,118.08,3.18c13.7,3.04,27.24,6.74,38.41,16.05c10.55,8.79,13.6,18.15,8.04,30.71
		c-2.92,6.6-7.38,12.94-12.45,18.11c-22.97,23.42-51.35,38.47-81.5,50.25c-14.56,5.69-29.8,9.59-44.54,14.86
		c-3.83,1.37-9.26,4.46-9.69,7.39c-0.46,3.07,3.53,7.81,6.76,10.25c25.02,18.85,50.73,36.79,75.35,56.13
		c10.92,8.57,20.19,19.39,29.46,29.85c4.06,4.57,6.25,10.8,9.28,16.28c-0.97,0.87-1.93,1.73-2.9,2.6
		c-4.28-2.64-8.62-5.17-12.82-7.92c-37.23-24.38-74.31-48.99-111.72-73.08c-9.66-6.22-20.15-11.29-30.63-16.1
		c-13.61-6.24-21.75-2.93-27.77,10.62c-11.89,26.74-23.62,53.55-35.46,80.31C131.72,262.42,129.83,266.04,127.79,270.24z
		 M275.14,38.39c-10.73,0.57-22.67,1.28-34.62,1.83c-6.49,0.3-10.22,3.16-12.73,9.52c-4.21,10.68-9.49,20.95-14.65,31.23
		c-4.43,8.84-9.79,17.24-13.79,26.26c-1.79,4.05-3.19,10.2-1.35,13.5c3.16,5.7,8.9,1.45,13.23-0.24
		c24.18-9.48,48.47-18.7,72.3-29.01c12.78-5.53,24.89-12.75,36.91-19.86c4.49-2.65,8.38-6.76,11.65-10.91
		c5.96-7.56,4.41-14.23-4.46-17.64c-6.43-2.47-13.48-3.99-20.35-4.48C297.03,37.85,286.68,38.39,275.14,38.39z"/>
	<path class={color} d="M508.87,202.48c3.62-8.13,6.13-17.01,11.06-24.25c16.02-23.55,36.56-42.64,62.31-55.05
		c8.98-4.33,19.53-6.25,29.56-7.27c9.26-0.94,16.62,4.3,20.45,13.16c3.16,7.29,2.84,14.65-1.99,21.12
		c-4.96,6.64-12.24,6.87-19.1,4.32c-18.16-6.77-33.03-0.26-47.07,10.52c-4.12,3.17-7.78,6.87-4.18,12.46
		c3.74,5.82,8.83,5.16,13.93,1.84c2.41-1.57,4.66-3.4,6.91-5.21c12.81-10.28,21.71-11.01,35.96-2.52
		c5.66,3.37,10.79,7.69,16.6,10.74c9.43,4.94,19.1,9.47,28.89,13.64c6.05,2.57,9.84,6.11,8.66,13.01c-1.2,7.07-6.66,10.1-12.69,8.84
		c-9.54-1.99-19.01-5.03-27.98-8.87c-6.33-2.71-12.06-7.14-17.57-11.42c-10.6-8.23-21.83-9.5-33.5-3.33
		c-7.98,4.22-15.1,10.14-23.21,14.05c-9.14,4.4-18.69,8.82-28.55,10.66C514.1,221.38,510.25,216.84,508.87,202.48z"/>
	<path class={color} d="M437.06,110.6c-1.96,26.12-3.85,51.27-5.81,77.37c11.39-0.8,18.56-6.01,24.55-11.75
		c14.84-14.2,28.63-29.49,43.24-43.94c7.85-7.76,16.3-14.95,24.93-21.84c2.66-2.13,6.75-3.22,10.25-3.39
		c7.84-0.37,11.8,4.33,9.53,11.76c-1.64,5.38-4.31,10.81-7.79,15.22c-27.15,34.34-59.71,62.36-98.56,82.84
		c-24.1,12.7-33.82,8.04-38.2-18.42c-4.08-24.64,1.08-48.44,7.76-71.83C410.81,113.12,422.47,107.79,437.06,110.6z"/>
	<path class={color} d="M650.03,315.61c-8.95-0.99-17.52-2.46-22.22-11.29c-4.55-8.57-1.31-16.02,8.3-17.01
		c16.9-1.75,26.37-12.22,33.27-26.18c8.5-17.21,13-35.69,15.08-54.58c2.83-25.8,4.74-51.7,6.73-77.59
		c0.72-9.33,2.6-17.99,9.55-24.74c7.93-7.71,17.38-7.46,22.9,2.01c3.52,6.03,6.5,14.19,5.51,20.76
		c-7.7,51.01-18.09,101.47-38.16,149.32c-3.79,9.04-8.93,17.67-14.47,25.78C670.32,311.13,660.71,314.87,650.03,315.61z"/>
	<path class={color} d="M340.64,188.03c-0.2-25.08,3.5-49.33,17.19-71.02c2.82-4.47,6.19-8.86,10.17-12.27
		c5.65-4.84,13.99-4.65,19.52-0.53c6.18,4.6,7.17,10.84,4.81,17.66c-1.33,3.85-2.97,7.81-5.39,11.02
		c-16.68,22.09-21.86,47.57-22.52,74.44c-0.3,12.19-3.29,17.58-10.31,18.11c-7.26,0.54-11.16-4.49-12.71-16.44
		c-0.32-2.45-0.67-4.91-0.74-7.38C340.56,197.1,340.64,192.56,340.64,188.03z"/>
	<path class={color} d="M721.48,82.48c-10.69,0.14-19.53-8.58-19.65-19.38c-0.12-10.97,8.21-19.28,19.41-19.38
		c11.12-0.09,19.67,8.22,19.6,19.06C740.77,73.74,732.32,82.34,721.48,82.48z"/>
	<path class={color} d="M421.04,44.66c10.08,0.2,17.86,8.04,17.93,18.05c0.07,10.55-8.12,18.51-18.85,18.33
		c-10.58-0.19-18.54-8.68-18.01-19.21C402.6,52.13,411.05,44.46,421.04,44.66z"/>
</g>
     </svg>
  
    </div>
  )
}

export default Rivaj
