import React from 'react'

const Behance = ({getFooterColor}) => {
  const color=getFooterColor === "#FFFFFF" ? "#ffffff" : "#141613"

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
    <g clip-path="url(#clip0_420_266)">
      <path d="M6.98493 11.3413C7.50022 11.2377 7.94189 11.0464 8.31812 10.7675C8.65347 10.5204 8.93155 10.2255 9.15239 9.8669C9.50409 9.32495 9.67585 8.71127 9.67585 8.03383C9.67585 7.35639 9.52045 6.81443 9.21782 6.35218C8.9152 5.88993 8.45717 5.55519 7.85192 5.34C8.24451 5.14076 8.54714 4.92557 8.75161 4.68647C9.11149 4.26407 9.29961 3.6982 9.29961 2.99685C9.29961 2.2955 9.11967 1.72964 8.75979 1.24348C8.16272 0.446488 7.14034 0.040023 5.70082 0.0161133H0V11.5087H5.3164C5.91347 11.5087 6.46965 11.4529 6.98493 11.3493V11.3413ZM2.35557 1.99265H4.92381C5.48816 1.99265 5.95437 2.04844 6.31425 2.17595C6.73956 2.34332 6.95222 2.70197 6.95222 3.23595C6.95222 3.72211 6.78864 4.05685 6.46965 4.24813C6.15067 4.4394 5.73353 4.53504 5.21825 4.53504H2.3474V2.00062L2.35557 1.99265ZM2.35557 9.49232V6.43188H5.25915C5.76625 6.43188 6.15885 6.50361 6.43693 6.62316C6.93586 6.84631 7.18941 7.26075 7.18941 7.85849C7.18941 8.56781 6.92768 9.046 6.4124 9.29307C6.12613 9.42856 5.72536 9.49232 5.21825 9.49232H2.35557Z" fill={color}/>
      <path d="M11.6711 10.7357C12.5544 11.437 13.585 11.7797 14.7464 11.7797C16.1532 11.7797 17.2492 11.3653 18.0262 10.5364C18.5333 10.0184 18.8114 9.50034 18.8687 8.99824H16.5376C16.3986 9.24531 16.2432 9.44455 16.0714 9.58004C15.7443 9.84305 15.3271 9.97057 14.8037 9.97057C14.3129 9.97057 13.8958 9.86696 13.5441 9.65177C12.9716 9.30907 12.6689 8.71132 12.628 7.85854H18.9832C18.9914 7.12531 18.9668 6.55945 18.9096 6.16893C18.8032 5.49946 18.5742 4.91765 18.2143 4.41555C17.8217 3.84172 17.3146 3.41931 16.7094 3.15631C16.1041 2.8933 15.4171 2.75781 14.6564 2.75781C13.3805 2.75781 12.3418 3.14834 11.5402 3.93736C10.7387 4.71841 10.3379 5.85013 10.3379 7.32456C10.3379 8.89463 10.7796 10.0343 11.6711 10.7277V10.7357ZM13.2987 5.08502C13.6177 4.75826 14.0757 4.59886 14.6564 4.59886C15.1963 4.59886 15.6461 4.75029 16.006 5.05314C16.3659 5.356 16.5703 5.81028 16.6112 6.40005H12.6853C12.7671 5.85013 12.9716 5.41179 13.2905 5.08502H13.2987Z" fill={color}/>
      <path d="M17.0775 0.661621H11.9492V1.82523H17.0775V0.661621Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_420_266">
        <rect width="19" height="11.7875" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

export default Behance
