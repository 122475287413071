import React from 'react'
import "./BullProof.css"
const Crossfit = ({color}) => {
  return (
    <div>
     
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 845.8 228.84" style={{ enableBackground: 'new 0 0 845.8 228.84' }}
    space="preserve">

<g>
	<g>
		<path class={color} d="M70.6,140.52c15.4,0,30.9,0,47.4,0c0,6,0,11.7,0,17.5c0,6.7-0.3,13.4,0.1,20.1c0.9,17.7-7.4,30.5-21.3,40
			c-22.9,15.7-58.4,13.9-79.8-3.8c-11.1-9.2-17.1-20.6-17-35.5c0.3-43.5,0.3-86.9,0.1-130.4c-0.1-11.9,4.4-21.7,12.4-30
			c22.7-23.4,66.6-24.7,90.8-2.8c9.8,8.9,15.2,19.6,14.9,33.1c-0.2,13-0.1,26-0.1,39.4c-16.3,0-31.8,0-47.4,0
			c-1.8-2.8-0.9-5.9-1-8.9c-0.2-9.2,0.1-18.4-0.3-27.6c-0.2-5-3.7-7.8-8.9-8.1c-5.5-0.4-9.7,2.3-10.7,7.1c-0.5,2.1-0.4,4.4-0.4,6.6
			c0,38.2,0,76.3,0,114.5c0,8.5,1.3,10.9,6.8,12.7c7.2,2.4,13.1-1.3,13.4-8.9c0.4-8.9,0.1-17.8,0.2-26.7
			C69.7,146.32,69,143.42,70.6,140.52z"/>
		<path class={color} d="M338.5,143.22c0,13.6-0.3,27.3,0.1,40.9c0.5,15.7-6.7,27.1-19.9,34.1c-23.7,12.6-48.2,12.5-72.2,0.7
			c-14.5-7.1-21.6-19.2-21.4-35.8c0.4-26.4,0.5-52.9,0-79.4c-0.3-18.1,8-30.4,23.7-37.7c21.9-10.2,44.3-9.7,66.1,0.1
			c15.5,7,24.1,19,23.4,36.9C338.1,116.42,338.5,129.82,338.5,143.22L338.5,143.22z M272.5,143.72c0,12,0,23.9,0,35.9
			c0,10,2.3,13,9.9,12.7c9.2-0.3,10.1-4.8,9.6-13.1c-0.3-5.3,0-10.6,0-15.8c0-19.5,0-38.9,0-58.4c0-4.7-0.5-8.9-5.9-10.6
			c-7.5-2.4-13.4,1.4-13.5,9.3C272.4,117.02,272.5,130.32,272.5,143.72z"/>
		<path class={color} d="M639.5,224.02c-16.7,0-32.4,0-48.6,0c0-73,0-145.7,0-218.3c6.1-1.9,78.8-2.5,94.9-0.7c0,12.4,0,24.8,0,38.1
			c-15,0-30.2,0-45.9,0c0,17.1,0,33.3,0,50.2c11.2,0,22.2,0,34.4,0c0.2,13.7,0.3,26.8-0.2,40.7c-12.1,0-22.9,0-34.6,0
			C639.5,164.42,639.5,194.02,639.5,224.02z"/>
		<path class={color} d="M393.4,170.42c0,4.8-0.2,9,0.1,13.1c0.5,6.5,4.2,9.6,11.2,9.7c6.3,0.1,9.5-2.9,10.3-9.5
			c1-8.3-2.4-14.5-8.9-19.4c-7.4-5.6-15.9-9.1-23.8-13.7c-4.1-2.4-8.4-4.4-12.2-7.2c-24-17.2-25.4-57.9-2.5-74.8
			c8.8-6.5,19.2-8.6,29.8-9.5c14.7-1.2,28.9,0.5,42,8c11.4,6.6,17.9,16.5,19,29.6c0.5,5.8,0.1,11.6,0.1,18c-14.1,0-27.8,0-41.7,0
			c-1.6-4-0.4-8.2-0.9-12.3c-0.7-5.6-3-8.1-8.3-8.6c-5.8-0.6-10.3,2.1-11.4,7.3c-1.4,6.4-0.3,12.3,5.5,16.3c3.2,2.2,6.7,4,10.2,5.7
			c10.5,5.2,20.9,10.5,29.7,18.4c15.4,13.9,20.4,31.5,16.6,51.5c-3,15.6-13.1,25.6-27.9,30.5c-17.7,5.9-35.7,5.6-53.2-0.9
			c-17.8-6.6-26.4-19.4-26.4-38.3c0-4.4,0-8.9,0-13.8C364.9,170.42,378.6,170.42,393.4,170.42z"/>
		<path class={color} d="M469.2,170.22c14.6,0,28.2,0,42.2,0c1.1,4.2,0.5,8.3,0.7,12.3c0.3,7,3.5,10.4,10.1,10.7
			c7.2,0.3,10.9-2.9,11.5-10.1c0.7-7.6-2.4-13.5-8.2-18.2c-6.6-5.3-14.2-8.7-21.5-12.7c-5.6-3.1-11.4-5.7-16.5-9.7
			c-18.3-14.3-23.3-44.8-10.6-64.2c4.9-7.5,11.8-12.5,20.3-15.3c16.6-5.5,33.4-5.8,50.1-0.7c20,6.1,29.8,19.9,30,41.1
			c0,3.6,0,7.2,0,11.3c-14.2,0-28,0-41.9,0c-1.5-3.7-0.5-7.6-0.8-11.4c-0.6-6.6-3.2-9.3-9.2-9.6c-6.5-0.3-10.7,3.3-11.2,9.7
			c-0.5,6.7,1.9,12,8,15.3c5.9,3.1,11.8,6.2,17.8,9.2c9.2,4.7,17.8,10.3,24.7,18.2c13.1,14.8,16.1,32.1,11,50.7
			c-3.3,12.3-11.9,20.8-23.6,25.3c-20.4,7.8-40.9,7.5-60.9-1.5c-13.5-6.1-20.9-17.1-21.7-32.1
			C468.9,182.72,469.2,176.82,469.2,170.22z"/>
		<path class={color} d="M843.7,188.12c0,11.6,0,23.5,0,36.4c-13-0.7-25.7,1.4-38.3-2.3c-18.5-5.4-30.1-21.2-30-40.4
			c0.1-26.2,0.1-52.4,0.1-78.6c0-2.5,0.5-5-0.9-7.9c-6,0-12,0-18.8,0c0-11.2,0-21.8,0-33.3c6.2,0,12.5,0,19.5,0c0-9.6,0-18.4,0-27.7
			c15.7,0,30.5,0,45.9,0c1.5,8.8,0,17.7,1,27.3c7.8,0,15.4,0,23.6,0c0,11.3,0,21.8,0,32.8c-7.3,1.9-15.1-0.1-22.7,1.2
			c-1.8,1.8-1.2,4.2-1.2,6.3c0,23.7,0,47.4,0,71c0,12.2,1.9,14,14,14.3C838.3,187.32,840.8,186.72,843.7,188.12z"/>
		<path class={color} d="M178.3,224.02c-16.1,0-31.1,0-46.3,0c0-55.1,0-109.8,0-164.9c14.6,0,28.7,0,43.2,0c1.6,4.9,2.2,9.8,3.1,16.4
			c5.3-5.8,10-10.8,16.5-13.1c6.6-2.4,13.3-3.5,20.9-3.4c0,12.9,0,25.6,0,38.6c-4.3,0.7-8.7,1.3-12.9,2.3
			c-16.4,3.9-24.5,14.3-24.5,31c0,28.4,0,56.8,0,85.2C178.3,218.62,178.3,221.02,178.3,224.02z"/>
		<path class={color} d="M743.7,224.02c-15.2,0-30.1,0-45.7,0c0-53.8,0-107.6,0-161.8c15.2,0,30.1,0,45.7,0
			C743.7,115.92,743.7,169.42,743.7,224.02z"/>
		<path class={color} d="M697.7,42.82c0-12.8,0-25.2,0-38.1c15.5,0,30.6,0,46.1,0c0,12.6,0,25,0,38.1
			C728.9,42.82,713.8,42.82,697.7,42.82z"/>
	</g>
</g>
</svg>

   </div>
  )
}

export default Crossfit
