import React from 'react'

const LogoImage = ({color}) => {
  return (
    <svg width="190" height="42" viewBox="0 0 190 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_317_204)">
    <path d="M68.8752 7.77673H60.6523V28.3742H68.8752C74.82 28.3742 79.5525 23.8951 79.5525 18.0755C79.5525 12.2559 74.82 7.77673 68.8752 7.77673ZM68.8752 25.0878H64.1426V11.0595H68.8752C72.9697 11.0595 76.0359 14.0727 76.0359 18.0755C76.0359 22.0783 72.9735 25.0915 68.8752 25.0915V25.0878Z" fill={color}/>
    <path d="M88.7138 25.7746C86.3456 25.7746 84.6493 24.401 84.2552 21.9823H96.0247C96.0847 21.6241 96.0847 21.2364 96.0847 20.8782C96.0847 16.4914 92.9585 13.2677 88.5599 13.2677C84.1614 13.2677 80.915 16.6391 80.915 20.9705C80.915 25.302 84.2815 28.6734 88.71 28.6734C92.0465 28.6734 94.9888 26.672 96.141 23.4483H92.6807C92.0127 24.8515 90.5865 25.7746 88.7063 25.7746H88.7138ZM88.5336 16.1332C90.808 16.1332 92.388 17.5659 92.6582 19.5673H84.3453C84.8294 17.4182 86.4395 16.1332 88.5336 16.1332Z" fill={color}/>
    <path d="M105.614 19.8664L104.218 19.6264C102.213 19.2978 101.305 18.91 101.305 17.7469C101.305 16.5837 102.397 15.9559 103.854 15.9559C105.554 15.9559 106.552 17.001 106.706 18.1346H110.102C110.012 15.45 107.464 13.2677 103.884 13.2677C100.547 13.2677 97.9988 14.9995 97.9988 17.7469C97.9988 20.2246 99.3349 21.506 102.641 22.0746L104.037 22.3146C106.072 22.6433 106.92 23.031 106.92 24.1942C106.92 25.3574 105.828 25.9851 104.158 25.9851C102.487 25.9851 101.245 25.1506 101.031 23.7179H97.6348C97.8149 26.5243 100.333 28.6734 104.158 28.6734C107.738 28.6734 110.286 26.9711 110.286 24.1979C110.286 21.7792 108.92 20.4351 105.614 19.8701V19.8664Z" fill={color}/>
    <path d="M116.689 16.8791V13.5668H113.322H111.765V16.8791H113.322V25.0619H111.765V28.3742H113.322H116.689H118.25V25.0619H116.689V16.8791Z" fill={color}/>
    <path d="M131.694 15.3873C130.541 13.9841 128.751 13.2677 126.781 13.2677C122.593 13.2677 119.166 16.5505 119.166 20.7601C119.166 24.9697 122.352 28.2819 126.751 28.2819C128.721 28.2819 130.481 27.536 131.634 26.2215V28.4001C131.634 30.4015 130.601 31.236 128.781 31.236H123.662C122.382 31.236 121.347 32.2552 121.347 33.5144V34.2234H128.687C132.238 34.2234 134.906 32.4029 134.906 28.2524V13.5668H131.69V15.3873H131.694ZM126.987 25.2097C124.394 25.1949 122.532 23.319 122.532 20.7637C122.532 18.3192 124.589 16.292 127.074 16.3178C129.656 16.3437 131.54 18.2158 131.54 20.7637C131.54 23.212 129.476 25.2281 126.987 25.2134V25.2097Z" fill={color}/>
    <path d="M145.801 13.2677C143.891 13.2677 142.345 13.9546 141.373 15.0882V13.5668H138.156V28.3706H141.523V20.2505C141.523 17.8909 143.009 16.3991 145.253 16.3991C147.497 16.3991 148.774 17.8318 148.774 20.2505V28.3706H152.14V19.9514C152.14 15.8637 149.502 13.264 145.797 13.264L145.801 13.2677Z" fill={color}/>
    <path d="M158.865 16.8791V13.5668H155.495H153.938V16.8791H155.495V25.0619H153.938V28.3742H155.495H158.861H160.423V25.0619H158.865V16.8791Z" fill={color}/>
    <path d="M166.739 12.8209C166.739 11.4472 167.467 10.7013 169.043 10.7013H170.619V7.77673H168.889C165.493 7.77673 163.369 9.83722 163.369 12.7027V13.5668H161.034V16.6095H163.369V28.3705H166.735V16.6095H170.86V13.5668H166.735V12.8209H166.739Z" fill={color}/>
    <path d="M183.879 13.5668L179.631 24.5819L175.322 13.5668H171.682L177.78 28.4038L175.386 34.2234H178.932L187.459 13.5668H183.879Z" fill={color}/>
    <path d="M188.014 24.5819C186.911 24.6003 186.111 25.4016 186.111 26.4909C186.111 27.5803 186.929 28.3705 188.052 28.3705C189.174 28.3705 190.011 27.547 189.992 26.4577C189.973 25.3573 189.125 24.5634 188.01 24.5819H188.014Z" fill={color}/>
    <path d="M25.0439 42H4.69877L0 36.3872H21.546C31.3414 36.3872 39.309 28.5477 39.309 18.91C39.309 15.6752 38.4121 12.6399 36.8471 10.0366C33.7058 7.28556 29.57 5.6128 25.0439 5.6128H4.69877L0 0H21.546C28.5041 0 34.6103 3.65571 37.9842 9.11711C41.8423 12.5771 44.263 17.5585 44.263 23.09C44.263 33.518 35.6424 42 25.0439 42ZM5.38557 40.5673H25.0439C34.8392 40.5673 42.8069 32.7278 42.8069 23.09C42.8069 19.6411 41.786 16.4211 40.0259 13.7107C40.5063 15.365 40.7652 17.108 40.7652 18.9137C40.7652 29.3417 32.1446 37.8236 21.546 37.8236H3.08498L5.38557 40.571V40.5673ZM5.38557 4.18006H25.0439C28.1851 4.18006 31.1538 4.92597 33.7771 6.24793C30.5908 3.26798 26.2823 1.43274 21.546 1.43274H3.08498L5.38557 4.18006Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_317_204">
    <rect width="190" height="42" fill={color}/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default LogoImage
