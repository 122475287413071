import React from 'react'
import "./lego.css"
const Lego = () => {
  return (
    <div>
     
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 873.11 389.76"  style={{ enableBackground: 'new 0 0 873.11 389.76' }} space="preserve">

<g id="_x32_NCYXu.tif">
	<g>
		<path class="st0-simple" d="M547.81,332.21c-10,12-21,22.7-34,31.4c-22.3,14.9-46.7,23.5-73.5,25.7c-18.2,1.4-36-0.4-53.2-6.2
			c-14.6-5-27.2-13.1-37.7-24.3c-1.1-1.1-1.9-2.7-3.6-3.4c-1.9,0.2-2.6,1.9-3.7,3c-14.2,13.3-31.3,21.4-50.1,26
			c-24.3,5.9-48.7,7.2-73.1,1c-17.7-4.5-33-13.1-45.3-25.9c-1.5-0.5-2,0.1-2.6,0.7c-16.2,15.1-35.7,22.8-57.3,26.2
			c-16.8,2.6-33.8,3.2-50.7,0.2c-30.4-5.4-54.2-23.9-61.2-57.2c-2.7-13.2-2.1-26.4,0-39.5c6-38.9,19.1-75.6,33.6-112
			c14.8-36.9,31.1-73,51.4-107.2c8.1-13.6,18.1-25.3,31.8-33.5c11.3-6.8,23.8-10.6,37-11.3c13.1-0.7,26.2-0.5,38.6,4
			c16.7,6,26.8,17.4,28.5,35.5c0.2,3.1,0.7,6.1,1.1,9.2c0,0.1,0.2,0.2,0.6,0.5c1.7-2.4,3.4-4.6,5-7c16.2-21.5,38.1-34,64-39.7
			c23.7-5.2,47.6-6.7,71.6-3.7c15.9,2,31,7.2,44,17.2c5,3.7,8.8,8.6,12.8,13.5c17.2-14.1,36.6-23,58-27.6c29.9-6.4,59.7-6,89,3
			c22.8,7,37.7,19.2,49.6,39.6c2.1,0.2,2.6-1.6,3.7-2.7c22.8-25,51.3-38.5,84.7-42.1c19.6-2.1,38.9-1.4,57.9,3.9
			c35.5,9.8,56.2,33.3,63.4,69c3.6,17.7,3,35.4,0.4,53.2c-8,54.3-25.9,105.4-51.4,153.8c-11.2,21.2-25.5,40.2-44.5,55.3
			c-19.3,15.3-41.3,24-65.8,26.9c-19.1,2.2-38,2.2-56.8-2.6c-28.4-7.3-49.4-23.3-60.8-50.9c-0.2-0.5-0.6-1-1-1.5
			C548.41,332.61,548.11,332.41,547.81,332.21z M488.21,243.91c-3.7,14.9-9.7,28.1-21.3,38.1c-6.8,5.9-14.4,9.5-23.8,8.7
			c-8.2-0.6-12.7-4.9-14.4-12.8c-1.2-6.2-0.2-12.3,0.9-18.4c3.7-19.1,10.1-37.5,16.6-55.8c7.3-20.4,15.4-40.5,25.2-59.9
			c3.5-7.1,7.6-13.8,13.2-19.4c7.1-7.2,15.4-11,25.7-10c8.1,0.7,11.1,4.6,9.3,12.6c-0.7,3.4-2.1,6.7-3,10.1
			c-3.6,13.5,1.2,22.7,14.2,26.4c17.7,5.1,46.2,0.9,58.5-25.7c4.6-9.8,7-20.3,7.3-31.1c0.7-19.7-7.8-34.2-25.4-43.3
			c-5.1-2.7-10.6-4.7-16.2-6.1c-19.3-4.9-39-5.2-58.5-2.6c-21.2,2.7-40.2,10.6-56.8,24.4c-14.8,12.5-25.9,27.8-35.2,44.6
			c-22.2,39.9-37.7,82.2-47.3,126.7c-3.9,17.7-6,35.5-3,53.7c4.2,25.5,18.1,43.5,42.7,52.4c11.1,4,22.5,5.2,34.2,5.1
			c33-0.6,61.2-12.8,84.7-36c20.2-19.9,33.3-44.1,41.2-71.1c4-13.7,7.3-27.6,6.5-42.1c-1.1-18.1-11.1-28.9-28.9-31.9
			c-12.8-2.2-25.7-1.1-38.2,1.6c-11.2,2.5-19.9,8.7-25.8,18.9c-2.9,5.1-5,10.5-6.5,16.1c-3,12,1.9,20.8,13.7,24.7
			C481.01,242.31,484.31,242.91,488.21,243.91z M802.81,124.01c-0.1-3-0.2-7.8-0.7-12.6c-3-27.8-17.3-47.7-46-54.8
			c-15.7-4-31.5-4.1-47.5-2.2c-23.9,2.9-44.5,12.2-61.3,29.5c-11.7,12.1-20.4,26-27.5,41.1c-18.3,39-33,79.3-42.3,121.4
			c-4.1,18.7-6.7,37.5-5.5,56.7c1.6,25.5,15.6,44.1,39.7,52.4c17.2,6,35,6,52.8,4.2c13.9-1.4,27-5.7,39.1-12.8
			c18.3-10.8,31.8-26.4,42.5-44.3c20.7-34.9,35.5-72.2,46-111.3C797.91,169.71,802.11,148.01,802.81,124.01z M252.41,278.41
			c4.2-15.8,8.5-30.6,14.1-45.2c2.4,0.2,4.5,0.4,6.5,0.7c10.7,1.9,21.4,2.5,32.3,1.9c25.3-1.4,42.3-16.2,47-40.7
			c2.7-14.6-2.6-23.5-16.9-27.6c-11-3.1-22.2-2.9-33.3-1.9c-3.6,0.4-7.3,1-11.6,0.6c4.7-13,10.6-24.9,17.6-36.1c2.5,0,4.6,0,6.6,0
			c14.7,0.1,29.5,0.1,44-2.4c13.2-2.2,25-7.2,33.5-18.2c15.1-19.7,9-43.1-13.8-52.4c-7.3-3-14.9-4.9-22.9-5.2
			c-13.9-0.6-27.8-0.2-41.6,1.5c-24.4,3.1-45,13.5-60.7,32.8c-7,8.6-13,17.7-18.1,27.4c-21,40-37.2,81.9-48.2,125.8
			c-5.1,20.3-8.8,40.9-7.8,62c0.9,18.4,8.7,33.4,23.5,44.6c10.7,8.1,22.9,12.6,36,13.9c23.4,2.5,46.3-0.2,67.8-11
			c17.8-8.8,29.4-22.4,31.1-43.1c1.4-16.8-5.7-27.2-22.2-31.6c-10.2-2.7-20.5-2.5-30.9-1.7
			C273.81,273.41,263.61,275.81,252.41,278.41z M110.91,277.01c1.1-2.7,2-4.6,2.9-6.5c14.9-31.4,30-62.5,44.6-94
			c11.2-24.2,22.2-48.4,29.6-74.1c2.4-8.1,4.6-16.3,4.4-24.9c-0.5-12.7-6.2-19.8-18.6-21.9c-19.6-3.4-37,1-51.2,15.7
			c-4.4,4.5-7.8,9.6-11.1,14.9c-10,16.4-18.7,33.6-26.8,51.1c-20.1,43.3-38,87.6-49.7,134c-4,15.9-7.2,32-5.6,48.4
			c1.2,13.1,7.2,23.5,18.4,30.9c5.6,3.6,11.8,6,18.3,7.5c13.3,2.9,26.8,2.2,40.2,0.7c10.3-1.2,20.3-3.7,29.8-8.3
			c21.2-10.3,31.8-27.6,32.8-50.8c0.4-8.1-3.5-14.3-10.3-18.6c-4.6-2.9-9.8-4.2-15.2-5.1C132.91,274.21,122.51,275.51,110.91,277.01
			z"/>
		<g>
			<path class="st0-simple" d="M849.81,45.91c-12.7-0.1-22.8-10.2-22.7-23c0-12.7,10.7-23.2,23.7-22.9c12.5,0.2,22.3,10.7,22.3,23.5
				C873.11,35.41,862.11,46.61,849.81,45.91z M850.51,41.11c10.3-0.1,17.8-7.8,17.7-18.3c-0.1-10.1-8.1-18.1-18.2-18.1
				c-10.3,0-18.3,8.2-18.1,18.8C832.11,33.51,840.21,41.11,850.51,41.11z"/>
			<path class="st0-simple" d="M841.21,11.11c3.7,0,6.7,0,9.8,0c5.5,0.1,7.8,1.4,9,4.9c1.4,4.2-1.4,6.5-4.6,8.7c2.1,2.9,4,5.7,5.4,9.1
				c-1.5,1.4-3,1.1-4.6,0.6c-3.4-2.6-3-9.6-9.7-8.5c-1.2,2.6-0.1,5.4-0.9,8c-1.2,1.4-2.6,0.9-4.4,0.4
				C841.21,26.81,841.21,19.31,841.21,11.11z M846.01,15.11c-0.1,2.5-0.9,4.4,0.9,6.1c3-0.2,6.5,1.4,8.5-2.6
				C853.41,13.91,849.81,14.91,846.01,15.11z"/>
		</g>
		<path class="st0-simple" d="M645.21,274.01c0.5-7.5,2.7-15.9,5.1-24.3c10.7-37.1,23.9-73.5,40-108.6c2.6-5.6,5.6-11,9.2-16.1
			c5.4-7.6,12.7-10.2,21.7-9.5c6.1,0.5,9.3,3.7,10.2,9.8c1,7-0.5,13.6-2.1,20.3c-6,25.2-14.7,49.4-23.5,73.7
			c-5.9,16.1-12.2,32-19.6,47.6c-2.6,5.6-5.6,11.1-9.6,15.8c-3,3.6-6.5,6.5-11,7.8C653.61,294.31,645.01,287.91,645.21,274.01z"/>
	</g>
</g>
</svg>

    </div>
  )
}

export default Lego
