import React from 'react'
import "./index.css"
const AleemBeauty = ({color}) => {
  return (
    <div>
    
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 878.7 361.3" style={{ enableBackground: 'new 0 0 878.7 361.3' }} space="preserve">
    
    <g>
	<g>
		<path class={color} d="M221.7,223.3h6.5h6.5v39.1c5.7-8.4,13.1-12.5,22.1-12.4c10.1,0,18.2,3.5,24.1,10.5s8.9,16.4,8.9,28.3
			c0,13.5-3,24-8.9,31.7c-6,7.7-14.2,11.5-24.8,11.5c-8.7,0-15.7-3.4-20.9-10.1l-1.2-1.6v9.6h-12.2V223.3H221.7z M255.4,320.6
			c6.8,0,12-2.6,15.5-7.7c3.5-5.1,5.3-12.6,5.3-22.5c0-9.7-1.7-16.9-5.1-21.6c-3.4-4.7-8.6-7-15.7-7c-7.2,0-12.5,2.6-15.8,7.9
			c-3.3,5.3-5,13.6-5,25c0,8.3,1.8,14.6,5.4,19.1C243.7,318.4,248.8,320.6,255.4,320.6"/>
		<path class={color} d="M336.9,331.9c-11,0-19.6-3.5-25.9-10.4s-9.4-16.6-9.4-28.8c0-13,3.3-23.4,10-31.1
			c6.6-7.7,15.6-11.6,26.8-11.6c10.1,0,18.3,3.8,24.6,11.3c6.2,7.5,9.4,17.4,9.4,29.7l-0.2,4.6h-56.6c0.5,7.7,2.3,13.6,5.3,17.7
			c3.7,4.9,9.5,7.4,17.4,7.4c5,0,9.3-1.4,13-4.2s6-6.4,6.8-10.7H371c-1.9,8.5-5.8,15-11.6,19.5C353.4,329.7,346,331.9,336.9,331.9
			 M358.6,285.1c-0.4-7.2-2.2-12.8-5.4-16.8c-3.5-4.4-8.7-6.7-15.5-6.7c-6.9,0-12.3,2.6-16.5,7.7c-3.2,4-5.2,9.3-5.9,15.8H358.6z"/>
		<path class={color} d="M408.2,331.9c-7.6,0-13.6-2-18-6.1s-6.7-9.5-6.7-16.5s2.1-12.5,6.3-16.6c4.2-4.1,10.3-6.7,18.3-7.7l17.8-2.3
			c1.4-0.1,2.8-0.4,4.1-0.7c1.7-0.4,3-1.3,3.9-2.6s1.4-2.9,1.4-4.9v-0.6c0-4.2-1.6-7.4-4.7-9.7c-3.1-2.4-7.5-3.5-13.1-3.5
			c-5.4,0-9.5,1.2-12.6,3.7c-3,2.5-4.8,6.2-5.5,11.1h-12c0.6-8.5,3.6-14.9,9-19.2s13-6.4,23-6.4c9.4,0,16.6,2,21.5,6.1
			s7.4,10,7.4,17.9v42.6c0,1.3,0.4,2.4,1.2,3.2c0.8,0.8,1.9,1.2,3.2,1.2c0.4,0,0.9-0.1,1.7-0.2c0.7-0.1,1.6-0.2,2.4-0.4v9.4
			c-1.3,0.5-2.6,0.9-4,1.2s-2.6,0.4-3.6,0.4c-4.9,0-8.5-1.4-10.7-4.1c-1.4-1.8-2.5-4.3-3.1-7.6c-1,1.2-2.1,2.4-3.3,3.5
			C425.6,329,417.6,331.9,408.2,331.9 M435.3,299.1v-8.4c-2.4,1.2-5.1,1.9-8,2.4l-11.9,1.7c-6.3,0.9-10.9,2.3-13.8,4.4
			c-2.9,2.1-4.3,4.8-4.3,8.4c0,4.3,1.2,7.7,3.5,10c2.4,2.3,5.7,3.5,10.1,3.5c6.9,0,12.7-1.9,17.4-5.6c3.2-2.6,5.4-5.6,6.4-8.8
			c0.2-0.6,0.3-1.6,0.4-3C435.2,302.3,435.3,300.8,435.3,299.1"/>
		<path class={color} d="M483.8,302.3c0,6.5,1.3,11.2,3.8,14.1c2.5,2.9,6.6,4.3,12.2,4.3c6.3,0,11.3-2.4,14.9-7.3
			c3.7-4.9,5.5-11.4,5.5-19.7v-41.4h6.5h6.5v77.5H527h-6.2V318c-0.9,1.4-1.8,2.7-2.9,4c-5.6,6.6-12.5,9.9-20.9,9.9
			c-8.8,0-15.4-2.2-19.8-6.6c-4.4-4.4-6.6-11-6.6-19.8v-53.3h6.5h6.5v50.1H483.8z"/>
		<path class={color} d="M581.3,329.8c-4.4,0.7-7.7,1-9.7,1c-5.7,0-9.7-1.3-12.1-3.8c-2.4-2.5-3.5-6.8-3.5-13v-51.1h-10.7v-10.7H556
			v-21.7h13v21.7h12.3v10.7H569V314c0,1.7,0.7,3.1,2.1,4.1c1.4,1,3.2,1.5,5.5,1.5h4.6v10.2H581.3z"/>
		<path class={color} d="M627.5,332.4c-4.5,11.9-8.6,19.7-12.1,23.4c-3.6,3.7-8.9,5.5-16,5.5c-2.3,0-4.4-0.2-6.3-0.8v-12.1
			c2,0.7,4.5,1.1,7.5,1.2c2.9,0,5.4-1.2,7.4-3.6c2-2.4,4.2-6.9,6.7-13.5l0.2-1.2l0,0l-28.8-78.8h7.4h7.5l20.7,64.1l21-64.1h7.1h7.2
			L627.5,332.4z"/>
	</g>
	<g>
		<path class={color} d="M0,190.3h15.1l20.3-52.2h75.9l20,52.2h15.3L73.2,1.1L0,190.3z M73.2,40.3l32,82.8h-64L73.2,40.3z"/>
		<polygon class={color} points="194.5,175.3 194.5,11.2 179.7,11.2 179.7,190.3 243.5,190.3 243.5,175.3 		"/>
		<polygon class={color} points="290.3,175.3 290.3,100.3 364,100.3 364,84.6 290.3,84.6 290.3,26.1 364,26.1 364,11.2 275.4,11.2 
			275.4,190.3 364,190.3 364,175.3 		"/>
		<polygon class={color} points="419.9,175.3 519.9,11.2 401,11.2 401,26.4 492.9,26.4 393.2,190.3 517.4,190.3 517.4,175.3 		"/>
		<polygon class={color} points="565,175.3 565,100.3 638.7,100.3 638.7,84.6 565,84.6 565,26.1 638.7,26.1 638.7,11.2 550,11.2 
			550,190.3 638.7,190.3 638.7,175.3 		"/>
		<polygon class={color} points="770.8,199.2 827.7,49.7 863.3,190.3 878.7,190.3 830.6,0 770.8,156.9 711.1,0.3 663,190.3 
			678.1,190.3 714,49.5 		"/>
	</g>
</g>
    </svg>
    
        </div>
  )
}

export default AleemBeauty
