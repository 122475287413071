import React from 'react'

const DilaraBlack = ({className,color}) => {
  return (
    <div className={className}>
     <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 884.7 334.8" style={{enableBackground:"new 0 0 884.7 334.8"}} space="preserve">
<g>
	<path d="M123.8,45.5L155.4,77l0,272.1l-31.6,31.1h-8.1L86.5,351h-3.3l-29.6,29.2h-8.1l-31.1-31.1l0-249.6L0,85.1L0,77l31.1-31.6
		l8.1,0L70.8,77l0,13.9l44.9-45.4H123.8z M84.6,85.1L70.8,99l0,228.1l12.4,12h3.3L99,326.6l0-227.1L84.6,85.1z" fill={color}/>
	<path d="M262,76.5v8.6l-14.3,14.3l0,249.6l-31.1,31.1h-8.1l-31.1-31.1V341l13.9-13.9l0-250.5l31.1-31.1h8.1L262,76.5z" fill={color}/>
	<polygon points="340.4,76.5 309.4,45.5 301.2,45.5 269.7,76.5 269.7,85.1 284,99.5 284,315.9 283.5,327.1 269.7,341 269.7,349.1 
		300.8,380.2 308.9,380.2 340,349.1 339.6,265" fill={color}/>
	<path d="M375.8,349.1l0-272.1l31.1-31.6h8.1l29.6,29.6h3.3l29.6-29.6h8.1L516.9,77l0,250.1l13.9,13.9v8.1l-31.1,31.1h-8.1
		l-31.1-31.1l0-154.4L448,182.2l-3.3,0l-12.4,12.4l0,132.4l13.9,13.9v8.1L415,380.2h-8.1L375.8,349.1z M448,86.6h-3.3L432.2,99v59.3
		l12.4,12h3.3l12.4-12V99L448,86.6z" fill={color}/>
	<path d="M662.2,45.5l31.6,31.1V144l-28.2,28.2v8.1l28.2,28.2l0,140.6l-31.6,31.1h-8.1l-29.6-29.6h-3.3l-29.2,29.6h-8.1l-31.6-31.1
		l0-272.5l31.6-31.1L662.2,45.5z M624.5,86.6h-3.3l-12.4,12.9l0,58.3l12.4,12.4h3.3l12.9-12.9V99.5L624.5,86.6z M624.5,181.7l-3.3,0
		l-12.4,12.9l0,132l12.4,12.4h3.3l12.9-12.9l0-131.5L624.5,181.7z" fill={color}/>
	<path d="M729.8,349.1l0-272.1l31.1-31.6l8.1,0l29.6,29.6l3.3,0l29.6-29.6l8.1,0L870.8,77l0,250.1l13.9,13.9v8.1l-31.1,31.1l-8.1,0
		l-31.1-31.1l0-154.4L802,182.2h-3.3l-12.4,12.4l0,132.4l13.9,13.9v8.1L769,380.2h-8.1L729.8,349.1z M802,86.6h-3.3L786.2,99v59.3
		l12.4,12h3.3l12.4-12l0-59.3L802,86.6z" fill={color}/>
</g>
</svg>

    </div>
  )
}

export default DilaraBlack
