import React from 'react'

const Dibble = ({getFooterColor}) => {
  const color=getFooterColor === "#FFFFFF" ? "#ffffff" : "#141613"

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <g clip-path="url(#clip0_420_264)">
      <path d="M8.99675 0C4.02966 0 0 3.95829 0 8.83741C0 13.7165 4.02966 17.6748 8.99675 17.6748C13.9638 17.6748 17.9935 13.7165 17.9935 8.83741C17.9935 3.95829 13.9703 0 8.99675 0ZM8.99675 1.53472C10.7024 1.53472 12.2712 2.11023 13.5277 3.06304C12.6228 4.20768 11.3794 5.10933 9.79096 5.77437C9.02929 4.40591 8.15696 3.06943 7.17396 1.77132C7.75986 1.62424 8.36528 1.54111 8.99675 1.54111V1.53472ZM5.64412 2.32765C6.64014 3.61298 7.5255 4.93667 8.29367 6.29234C6.47089 6.8167 4.29656 7.09167 1.78373 7.10446C2.30452 5.01341 3.73671 3.28046 5.64412 2.32765ZM1.56239 8.83741C1.56239 8.77346 1.5689 8.70952 1.57541 8.65196C4.45931 8.65196 6.95262 8.31944 9.0358 7.67998C9.21157 8.03168 9.38083 8.38339 9.53707 8.73509C7.0047 9.51524 4.91501 11.1075 3.28752 13.5055C2.21338 12.2394 1.5689 10.6151 1.5689 8.83741H1.56239ZM8.99675 16.1401C7.27812 16.1401 5.6962 15.5582 4.43327 14.5926C5.91103 12.3161 7.79241 10.8389 10.1295 10.1547C10.826 11.9388 11.3338 13.7741 11.6593 15.6477C10.826 15.9611 9.93418 16.1401 8.99024 16.1401H8.99675ZM13.1241 14.9059C12.7986 13.173 12.3168 11.4784 11.6854 9.82858C13.0915 9.63035 14.6409 9.6815 16.3335 9.97566C16.008 12.0283 14.8101 13.7997 13.1241 14.9123V14.9059ZM11.0864 8.3706C10.9042 7.95495 10.7154 7.53929 10.5136 7.13004C12.2452 6.39465 13.6383 5.39069 14.6734 4.12455C15.6825 5.30117 16.3204 6.79112 16.4116 8.42176C14.4781 8.10842 12.7074 8.09563 11.0864 8.3706Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_420_264">
        <rect width="18" height="17.6812" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

export default Dibble
