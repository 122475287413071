import React from 'react'
import "./lego.css"
const LegoColor = () => {
  return (
    <div>
     
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" link="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 884.7 425.6"  style={{ enableBackground: 'new 0 0 884.7 425.6' }} space="preserve">

<g id="_x32_NCYXu.tif">
	<g>
		<path class="st0-color" d="M530.1,339.6c-3.1,1.2-4.9,3.4-7.1,5.1c-18.6,14.2-39.4,23.4-62.4,26.8c-22.8,3.4-45.4,2.3-67.1-6.7
			c-8-3.3-15.4-7.7-22.7-13.2c-20.9,14.1-44.3,20.3-69.3,21.3c-25.2,1-48.8-4.2-69.9-18.8c-6.3,3.6-12.4,7-18.9,9.5
			c-13,4.9-26.4,7.7-40.3,8.3c-13.1,0.5-26.3,0.6-39.1-3c-27.1-7.6-46.5-23.6-55.4-50.9c-3.9-12-4.4-24.4-3.1-36.9
			c3.9-37.5,16.5-72.6,30.3-107.3c10.7-26.8,23-53,37.3-78.1c6.1-10.7,12.9-20.9,22.1-29.3c12.9-12,28-19.5,45.4-21.9
			c13.4-1.9,26.8-1.7,40.1,1c12.2,2.5,22.1,6.7,30.5,20.2c0.9,0.2,1.6-0.2,2.3-0.7c13.8-11.1,29.8-17.1,47-20.3
			c16.8-3.1,33.7-3.7,50.7-2.8c18.6,1,35.9,6.1,50.9,17.7c0.9,0.7,1.9,1.3,3,2c12-7,24.6-12.3,38.2-15.3c8.9-2,17.9-3.5,27.1-3.7
			c13.1-0.2,26.2-0.2,39.3,1.8c20.2,3.2,39.2,10.7,54.3,25.1c1.8,0,2.8-1.4,4.1-2.4C613.7,54.7,632,47.5,652,44.4
			c10.4-1.6,20.8-2.2,31.3-1.7c20.5,1,40.2,5.4,57.4,17.2c19.9,13.6,31.7,32.7,36.5,56.2c2.7,13.3,2.5,26.7,1.2,40.2
			c-1,10.1-1.9,20.1-3.8,30.1c-3.3,17.1-8.7,33.7-14.4,50.1c-7.3,20.9-15.7,41.2-26.8,60.4c-8.6,14.7-18.2,28.6-30.2,40.7
			c-19.1,19.1-42.1,30.6-68.7,34.2c-22.1,3-44.1,1.7-65.4-5.7c-14.4-5-27-12.7-37.1-24.3C531.5,341,530.9,340.4,530.1,339.6z
			 M533.1,308.4c0.3,0.2,0.5,0.3,0.6,0.4c0.3,0.4,0.6,0.8,0.8,1.2c9.1,22.2,26,35.1,48.8,40.9c15,3.9,30.3,3.9,45.6,2.1
			c19.6-2.4,37.3-9.3,52.8-21.6c15.2-12.1,26.7-27.3,35.7-44.4c20.5-38.9,34.8-79.9,41.3-123.5c2.1-14.2,2.6-28.5-0.3-42.7
			c-5.8-28.7-22.4-47.5-50.9-55.4c-15.3-4.2-30.8-4.8-46.5-3.1c-26.9,2.9-49.7,13.7-68,33.8c-0.8,0.9-1.3,2.4-3,2.2
			c-9.5-16.4-21.5-26.2-39.8-31.8c-23.6-7.2-47.5-7.4-71.5-2.4c-17.2,3.6-32.7,10.8-46.6,22.2c-3.2-3.9-6.4-7.8-10.3-10.8
			c-10.4-8-22.5-12.2-35.3-13.8c-19.3-2.4-38.5-1.2-57.5,3c-20.8,4.6-38.4,14.6-51.4,31.9c-1.4,1.8-2.7,3.7-4,5.6
			c-0.3-0.2-0.4-0.3-0.5-0.4c-0.3-2.5-0.7-4.9-0.9-7.4c-1.4-14.5-9.5-23.7-22.9-28.5c-10-3.6-20.5-3.8-31-3.2
			c-10.6,0.6-20.6,3.6-29.7,9.1c-10.9,6.6-19,16-25.5,26.9c-16.3,27.5-29.5,56.5-41.3,86.1c-11.7,29.1-22.2,58.7-27,89.9
			c-1.6,10.6-2.2,21.2,0,31.7c5.6,26.7,24.7,41.5,49.1,45.9c13.6,2.4,27.2,2,40.7-0.2c17.2-2.7,33-8.9,46-21c0.5-0.4,0.9-1,2.1-0.6
			c9.9,10.3,22.2,17.2,36.4,20.8c19.6,5,39.2,4,58.7-0.8c15.1-3.7,28.8-10.2,40.2-20.9c0.9-0.9,1.6-2.2,3-2.4c1.4,0.5,2,1.8,2.9,2.7
			c8.5,9,18.6,15.5,30.3,19.5c13.9,4.8,28.1,6.2,42.7,5c21.5-1.6,41.2-8.6,59-20.6C516.2,326.6,525.1,318,533.1,308.4z"/>
		<path class="st1-color" d="M775.6,78.5c-10.2-0.1-18.3-8.2-18.2-18.5c0-10.2,8.6-18.6,19-18.4c10,0.2,17.9,8.6,17.9,18.9
			C794.3,70.1,785.5,79.1,775.6,78.5z M776.2,74.7c8.3-0.1,14.3-6.3,14.2-14.7c-0.1-8.1-6.5-14.5-14.6-14.5
			c-8.3,0-14.7,6.6-14.5,15.1C761.4,68.6,767.9,74.7,776.2,74.7z"/>
		<path class="st2-color" d="M533.1,308.4c-8,9.6-16.9,18.2-27.3,25.2c-17.9,12-37.5,18.9-59,20.6c-14.6,1.1-28.9-0.3-42.7-5
			c-11.7-4-21.8-10.5-30.3-19.5c-0.9-0.9-1.5-2.2-2.9-2.7c-1.5,0.2-2.1,1.5-3,2.4c-11.4,10.7-25.1,17.2-40.2,20.9
			c-19.5,4.7-39.1,5.8-58.7,0.8c-14.2-3.6-26.5-10.5-36.4-20.8c-1.2-0.4-1.6,0.1-2.1,0.6c-13,12.1-28.7,18.3-46,21
			c-13.5,2.1-27.1,2.6-40.7,0.2c-24.4-4.3-43.5-19.2-49.1-45.9c-2.2-10.6-1.7-21.2,0-31.7c4.8-31.2,15.3-60.7,27-89.9
			c11.9-29.6,25-58.6,41.3-86.1c6.5-10.9,14.5-20.3,25.5-26.9c9.1-5.5,19.1-8.5,29.7-9.1c10.5-0.6,21-0.4,31,3.2
			c13.4,4.8,21.5,14,22.9,28.5c0.2,2.5,0.6,4.9,0.9,7.4c0,0.1,0.2,0.2,0.5,0.4c1.4-1.9,2.7-3.7,4-5.6c13-17.3,30.6-27.3,51.4-31.9
			c19-4.2,38.2-5.4,57.5-3c12.8,1.6,24.9,5.8,35.3,13.8c4,3,7.1,6.9,10.3,10.8c13.8-11.3,29.4-18.5,46.6-22.2
			c24-5.1,47.9-4.8,71.5,2.4c18.3,5.6,30.3,15.4,39.8,31.8c1.7,0.2,2.1-1.3,3-2.2c18.3-20.1,41.2-30.9,68-33.8
			c15.7-1.7,31.2-1.1,46.5,3.1c28.5,7.9,45.1,26.7,50.9,55.4c2.9,14.2,2.4,28.4,0.3,42.7c-6.4,43.6-20.8,84.6-41.3,123.5
			c-9,17-20.5,32.3-35.7,44.4c-15.5,12.3-33.2,19.3-52.8,21.6c-15.3,1.8-30.5,1.8-45.6-2.1c-22.8-5.9-39.7-18.7-48.8-40.9
			c-0.2-0.4-0.5-0.8-0.8-1.2C533.6,308.7,533.4,308.6,533.1,308.4z M485.3,237.5c-3,12-7.8,22.6-17.1,30.6c-5.5,4.7-11.6,7.6-19.1,7
			c-6.6-0.5-10.2-3.9-11.6-10.3c-1-5-0.2-9.9,0.7-14.8c3-15.3,8.1-30.1,13.3-44.8c5.9-16.4,12.4-32.5,20.2-48.1
			c2.8-5.7,6.1-11.1,10.6-15.6c5.7-5.8,12.4-8.8,20.6-8c6.5,0.6,8.9,3.7,7.5,10.1c-0.6,2.7-1.7,5.4-2.4,8.1
			c-2.9,10.8,1,18.2,11.4,21.2c14.2,4.1,37.1,0.7,47-20.6c3.7-7.9,5.6-16.3,5.9-25c0.6-15.8-6.3-27.5-20.4-34.8
			c-4.1-2.2-8.5-3.8-13-4.9c-15.5-3.9-31.3-4.2-47-2.1c-17,2.2-32.3,8.5-45.6,19.6c-11.9,10-20.8,22.3-28.3,35.8
			c-17.8,32-30.3,66-38,101.7c-3.1,14.2-4.8,28.5-2.4,43.1c3.4,20.5,14.5,34.9,34.3,42.1c8.9,3.2,18.1,4.2,27.5,4.1
			c26.5-0.5,49.1-10.3,68-28.9c16.2-16,26.7-35.4,33.1-57.1c3.2-11,5.9-22.2,5.2-33.8c-0.9-14.5-8.9-23.2-23.2-25.6
			c-10.3-1.8-20.6-0.9-30.7,1.3c-9,2-16,7-20.7,15.2c-2.3,4.1-4,8.4-5.2,12.9c-2.4,9.6,1.5,16.7,11,19.8
			C479.5,236.2,482.1,236.7,485.3,237.5z M737.9,141.2c-0.1-2.4-0.2-6.3-0.6-10.1c-2.4-22.3-13.9-38.3-36.9-44
			c-12.6-3.2-25.3-3.3-38.1-1.8c-19.2,2.3-35.7,9.8-49.2,23.7c-9.4,9.7-16.4,20.9-22.1,33c-14.7,31.3-26.5,63.7-34,97.5
			c-3.3,15-5.4,30.1-4.4,45.5c1.3,20.5,12.5,35.4,31.9,42.1c13.8,4.8,28.1,4.8,42.4,3.4c11.2-1.1,21.7-4.6,31.4-10.3
			c14.7-8.7,25.5-21.2,34.1-35.6c16.6-28,28.5-58,36.9-89.4C733.9,177.9,737.3,160.5,737.9,141.2z M295.9,265.2
			c3.4-12.7,6.8-24.6,11.3-36.3c1.9,0.2,3.6,0.3,5.2,0.6c8.6,1.5,17.2,2,25.9,1.5c20.3-1.1,34-13,37.7-32.7
			c2.2-11.7-2.1-18.9-13.6-22.2c-8.8-2.5-17.8-2.3-26.7-1.5c-2.9,0.3-5.9,0.8-9.3,0.5c3.8-10.4,8.5-20,14.1-29c2,0,3.7,0,5.3,0
			c11.8,0.1,23.7,0.1,35.3-1.9c10.6-1.8,20.1-5.8,26.9-14.6c12.1-15.8,7.2-34.6-11.1-42.1c-5.9-2.4-12-3.9-18.4-4.2
			c-11.2-0.5-22.3-0.2-33.4,1.2c-19.6,2.5-36.1,10.8-48.7,26.3c-5.6,6.9-10.4,14.2-14.5,22c-16.9,32.1-29.9,65.8-38.7,101
			c-4.1,16.3-7.1,32.8-6.3,49.8c0.7,14.8,7,26.8,18.9,35.8c8.6,6.5,18.4,10.1,28.9,11.2c18.8,2,37.2-0.2,54.4-8.8
			c14.3-7.1,23.6-18,25-34.6c1.1-13.5-4.6-21.8-17.8-25.4c-8.2-2.2-16.5-2-24.8-1.4C313.1,261.2,304.9,263.1,295.9,265.2z
			 M182.3,264.1c0.9-2.2,1.6-3.7,2.3-5.2c12-25.2,24.1-50.2,35.8-75.5c9-19.4,17.8-38.9,23.8-59.5c1.9-6.5,3.7-13.1,3.5-20
			c-0.4-10.2-5-15.9-14.9-17.6c-15.7-2.7-29.7,0.8-41.1,12.6c-3.5,3.6-6.3,7.7-8.9,12c-8,13.2-15,27-21.5,41
			c-16.1,34.8-30.5,70.3-39.9,107.6c-3.2,12.8-5.8,25.7-4.5,38.9c1,10.5,5.8,18.9,14.8,24.8c4.5,2.9,9.5,4.8,14.7,6
			c10.7,2.3,21.5,1.8,32.3,0.6c8.3-1,16.3-3,23.9-6.7c17-8.3,25.5-22.2,26.3-40.8c0.3-6.5-2.8-11.5-8.3-14.9
			c-3.7-2.3-7.9-3.4-12.2-4.1C200,261.8,191.6,262.9,182.3,264.1z"/>
		<path class="st3-color" d="M485.3,237.5c-3.1-0.8-5.8-1.3-8.3-2.1c-9.5-3.1-13.4-10.2-11-19.8c1.1-4.5,2.9-8.8,5.2-12.9
			c4.6-8.1,11.6-13.2,20.7-15.2c10.1-2.2,20.4-3,30.7-1.3c14.3,2.4,22.3,11.2,23.2,25.6c0.7,11.6-1.9,22.8-5.2,33.8
			c-6.4,21.7-16.9,41.1-33.1,57.1c-18.8,18.6-41.5,28.3-68,28.9c-9.3,0.2-18.6-0.9-27.5-4.1c-19.9-7.1-30.9-21.5-34.3-42.1
			c-2.4-14.5-0.7-28.9,2.4-43.1c7.7-35.7,20.2-69.7,38-101.7c7.5-13.4,16.4-25.8,28.3-35.8c13.3-11.1,28.5-17.4,45.6-19.6
			c15.8-2.1,31.5-1.8,47,2.1c4.5,1.1,8.9,2.8,13,4.9c14.1,7.3,21,18.9,20.4,34.8c-0.3,8.7-2.2,17.1-5.9,25
			c-9.9,21.3-32.9,24.7-47,20.6c-10.5-3-14.4-10.4-11.4-21.2c0.7-2.7,1.9-5.4,2.4-8.1c1.3-6.4-1-9.5-7.5-10.1
			c-8.2-0.8-14.9,2.2-20.6,8c-4.5,4.6-7.7,10-10.6,15.6c-7.8,15.6-14.3,31.7-20.2,48.1c-5.3,14.7-10.3,29.4-13.3,44.8
			c-1,4.9-1.7,9.8-0.7,14.8c1.3,6.4,5,9.8,11.6,10.3c7.5,0.6,13.6-2.3,19.1-7C477.5,260.1,482.3,249.5,485.3,237.5z"/>
		<path class="st3-color" d="M737.9,141.2c-0.5,19.3-3.9,36.7-8.6,53.9c-8.5,31.4-20.3,61.4-36.9,89.4c-8.6,14.5-19.5,26.9-34.1,35.6
			c-9.7,5.8-20.2,9.2-31.4,10.3c-14.3,1.4-28.6,1.4-42.4-3.4c-19.4-6.7-30.6-21.6-31.9-42.1c-1-15.4,1.1-30.6,4.4-45.5
			c7.5-33.8,19.3-66.2,34-97.5c5.7-12.1,12.7-23.3,22.1-33c13.5-14,30.1-21.5,49.2-23.7c12.8-1.5,25.5-1.3,38.1,1.8
			c23,5.7,34.5,21.7,36.9,44C737.7,135,737.7,138.8,737.9,141.2z M611.3,261.7c-0.2,11.1,6.7,16.3,16.5,13.4
			c3.6-1.1,6.4-3.4,8.8-6.3c3.2-3.8,5.6-8.2,7.7-12.7c5.8-12.5,10.9-25.3,15.7-38.2c7.1-19.5,14.1-39,18.9-59.2
			c1.3-5.3,2.5-10.7,1.7-16.3c-0.7-4.8-3.3-7.4-8.2-7.9c-7.1-0.6-13.1,1.5-17.4,7.6c-2.9,4.1-5.3,8.4-7.4,12.9
			c-12.9,28.2-23.5,57.3-32.1,87.2C613.5,248.9,611.7,255.6,611.3,261.7z"/>
		<path class="st3-color" d="M295.9,265.2c8.9-2.2,17.2-4,25.6-4.6c8.3-0.6,16.6-0.8,24.8,1.4c13.2,3.5,18.9,11.9,17.8,25.4
			c-1.4,16.6-10.7,27.5-25,34.6c-17.2,8.5-35.6,10.7-54.4,8.8c-10.5-1.1-20.3-4.8-28.9-11.2c-11.9-9-18.1-21-18.9-35.8
			c-0.9-16.9,2.2-33.5,6.3-49.8c8.8-35.2,21.8-68.9,38.7-101c4.1-7.8,9-15.1,14.5-22c12.6-15.6,29.1-23.8,48.7-26.3
			c11.1-1.4,22.2-1.6,33.4-1.2c6.4,0.3,12.5,1.8,18.4,4.2c18.2,7.6,23.2,26.3,11.1,42.1c-6.8,8.8-16.3,12.9-26.9,14.6
			c-11.7,2-23.5,2-35.3,1.9c-1.7,0-3.3,0-5.3,0c-5.6,9-10.2,18.7-14.1,29c3.4,0.3,6.3-0.2,9.3-0.5c9-0.8,17.9-1,26.7,1.5
			c11.5,3.3,15.8,10.4,13.6,22.2c-3.7,19.7-17.5,31.6-37.7,32.7c-8.7,0.5-17.3,0-25.9-1.5c-1.6-0.3-3.3-0.4-5.2-0.6
			C302.7,240.7,299.3,252.6,295.9,265.2z"/>
		<path class="st3-color" d="M182.3,264.1c9.2-1.2,17.6-2.3,26.1-1c4.3,0.7,8.5,1.7,12.2,4.1c5.5,3.4,8.6,8.4,8.3,14.9
			c-0.8,18.7-9.3,32.5-26.3,40.8c-7.5,3.7-15.6,5.7-23.9,6.7c-10.8,1.3-21.6,1.7-32.3-0.6c-5.2-1.1-10.2-3-14.7-6
			c-8.9-5.8-13.8-14.2-14.8-24.8c-1.2-13.3,1.3-26.2,4.5-38.9c9.3-37.3,23.7-72.8,39.9-107.6c6.5-14,13.4-27.8,21.5-41
			c2.6-4.3,5.4-8.4,8.9-12c11.4-11.8,25.4-15.3,41.1-12.6c9.9,1.7,14.5,7.5,14.9,17.6c0.3,6.9-1.5,13.5-3.5,20
			c-6.1,20.6-14.8,40.1-23.8,59.5c-11.7,25.3-23.9,50.3-35.8,75.5C183.9,260.4,183.3,262,182.3,264.1z"/>
		<path class="st1-color" d="M768.7,50.6c3,0,5.4,0,7.9,0c4.4,0.1,6.3,1.1,7.2,3.9c1.1,3.4-1.1,5.2-3.7,7c1.7,2.3,3.2,4.6,4.3,7.3
			c-1.2,1.1-2.4,0.9-3.7,0.5c-2.7-2.1-2.4-7.7-7.8-6.8c-1,2.1-0.1,4.3-0.7,6.4c-1,1.1-2.1,0.7-3.5,0.3
			C768.7,63.2,768.7,57.2,768.7,50.6z M772.6,53.8c-0.1,2-0.7,3.5,0.7,4.9c2.4-0.2,5.2,1.1,6.8-2.1
			C778.5,52.8,775.6,53.6,772.6,53.8z"/>
		<path class="st2-color" d="M611.3,261.7c0.4-6,2.2-12.8,4.1-19.5c8.6-29.8,19.2-59,32.1-87.2c2.1-4.5,4.5-8.8,7.4-12.9
			c4.3-6.1,10.2-8.2,17.4-7.6c4.9,0.4,7.5,3,8.2,7.9c0.8,5.6-0.4,10.9-1.7,16.3c-4.8,20.2-11.8,39.7-18.9,59.2
			c-4.7,12.9-9.8,25.7-15.7,38.2c-2.1,4.5-4.5,8.9-7.7,12.7c-2.4,2.9-5.2,5.2-8.8,6.3C618.1,278,611.2,272.8,611.3,261.7z"/>
	</g>
</g>
</svg>

    </div>
  )
}

export default LegoColor
