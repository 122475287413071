import React from 'react'
import "./fiverr.css"
const Fverr = () => {
  return (
    <>
    
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1422.7 425.6" style={{enableBackground:"new 0 0 1422.7 425.6"}} space="preserve">
<g id="B9MxsG.tif">
	<g>
		<path class="st0-fiver-green" d="M11,200.2c0-20.7,0-39.8,0-59.7c10.8,0,21.3,0,32.3,0c0.6-9.2,1-17.7,1.9-25.9c4.1-33.9,23.2-56,55.2-67
			c26.3-9.1,53.3-10,81-6c0,21.2,0,42,0,63.2c-6.5,0-12.6,0-18.8,0c-1.8,0-3.5,0-5.3,0c-24.7,0.3-33,9.9-30.1,35c60,0,120,0,181,0
			c0,61.9,0,123.2,0,185.8c10.8,0,20.5,0,30.9,0c0,20.4,0,39.3,0,58.9c-47.9,0-95.5,0-143.6,0c0-19.3,0-38.4,0-58.4
			c9.9,0,19.4,0,28.7,0c2.4-7,2.9-115.1,0.8-125.8c-32.3,0-64.8,0-97.9,0c0,41.4,0,82.6,0,124.8c11.5,0,22.8,0,34.9,0
			c0,20.1,0,39.2,0,58.3c-6.2,1.9-125,2.9-148.4,1c0-19.1,0-38.4,0-58.6c9.7,0,19.4,0,29.6,0c0-42.2,0-83.4,0-125.6
			C33.1,200.2,22.6,200.2,11,200.2z"/>
		<path class="st0-fiver-green" d="M859.3,278c-51.9,0-103.3,0-154.9,0c3.2,21.8,18.8,38,40.3,43c19.6,4.5,38.2,0.8,56.4-6.5
			c9.9-4,19.1-8.9,29.5-13.8c9.6,18.1,19.3,36.6,29.6,55.9c-3.3,2.5-6.4,5.1-9.7,7.2c-31.2,19.7-65.4,28-102.2,26.9
			c-23.6-0.6-45.7-5.9-66.2-17.7c-34.9-20.1-54.8-50.6-61.1-89.8c-4.8-29.9-0.6-58.9,14.2-85.8c21.5-39.5,56.2-58.4,100.1-62.2
			c21.5-1.9,42.7,0.5,62.6,9.2c34.2,15,53,42.2,60.8,77.7C862.5,240,862.7,258.3,859.3,278z M776.4,232c-0.6-4-0.8-6.8-1.4-9.7
			c-4.3-18.3-19.4-28.3-37.7-25.2c-15.8,2.7-27.4,15.8-30.9,34.9C729.4,232,752.4,232,776.4,232z"/>
		<path class="st0-fiver-green" d="M358,199.8c-9.2,0-18,0-27.2,0c0-19.9,0-39,0-58.9c43,0,86.1,0,129.7,0c0,19.1,0,38.4,0,58.9
			c-6.8,0-13.4,0-20.9,0c11,41.1,27.4,79.1,35.3,121.6c7.6-42.7,24.2-80.4,35-121.3c-7,0-13.7,0-20.9,0c0-20.4,0-39.6,0-59.5
			c43.3,0,86.3,0,129.9,0c0,19.4,0,38.5,0,58.7c-8.9,0-17.7,0-27.2,0c-22.8,61.9-45.4,123.4-68.1,185.1c-32.3,0-64.5,0-97.9,0
			C403.6,323.7,381,262.4,358,199.8z"/>
		<path class="st0-fiver-green" d="M1211.8,195.4c9.4-23.2,23.6-41.2,46.3-51.9c12.9-6,26.3-8.4,40.9-6.4c0,26.7,0,53.2,0,79.6
			c-7.5,0-14.8-0.3-22,0c-32,1.6-53,19.4-59.5,50.6c-4,18.9-2.2,38-2.2,58.3c11.1,0,21.7,0,32.6,0c0,20.1,0,39.2,0,58.9
			c-49.3,0-98.5,0-148.4,0c0-19.3,0-38.2,0-58.3c10.7,0,21.2,0,32.2,0c0.3-2.9,0.8-4.8,0.8-6.7c0-35.5,0-70.8,0-106.3
			c0-10.3-2.7-13.1-13.2-13.2c-6.4-0.2-12.9,0-19.9,0c0-20.1,0-39.2,0-58.9c2.1-0.2,4.3-0.6,6.5-0.6c23.4,0,46.8,0,70.2,0
			c1,0,1.8,0,2.7,0c22.9,0.8,32.5,10.3,33,33.6C1212,180.6,1211.8,187.3,1211.8,195.4z"/>
		<path class="st0-fiver-green" d="M996.2,325.8c11.6,0,22,0,32.8,0c0,19.7,0,38.8,0,58.6c-49.2,0-98.4,0-148.4,0c0-19.1,0-38.2,0-58.4
			c10.8,0,21.5,0,33,0c0-3.8,0-6.5,0-9.1c0-33.9,0-68,0-101.9c0-12.6-2.4-14.8-15.1-15c-5.9,0-11.8,0-18,0c0-20.1,0-39.2,0-59.1
			c2.1-0.2,4.3-0.6,6.5-0.6c23.4,0,46.8,0,70.2,0c1.1,0,2.4,0,3.5,0.2c21.7,1,31.2,10.5,31.8,31.8c0.2,7.3,0,14.8,0,23.7
			c7.5-18.9,18-34.2,33.9-45.2c15.9-10.8,33.3-16.6,53.5-13.5c0,26.4,0,52.9,0,79.8c-2.2,0-4.5-0.2-6.7,0
			c-9.4,0.5-19.1-0.2-28.3,1.8c-25,4.9-39.8,21-45.8,45.2C994.3,283.7,996.4,304.1,996.2,325.8z"/>
		<path class="st0-fiver-green" d="M267.6,35.2c22.1-0.2,39.6,17,39.8,39s-17.2,39.5-39.3,39.5c-22.1,0.2-39.6-17-39.8-39
			C228.1,52.9,245.5,35.4,267.6,35.2z"/>
		<path class="st0-fiver-green" d="M1419.4,73.1c0.2,29.9-22.8,53.3-52.2,53.3c-28.8,0-51.6-23.4-51.6-52.9c0-29.3,23.1-53.2,51.7-53.2
			C1396,20.4,1419.2,44,1419.4,73.1z M1403.3,73.6c0-16.4-8.8-30.2-22.9-35.8c-18.9-7.5-39.8,2.4-46.5,22.1
			c-7.2,21.2,3.8,44.6,23.7,50.3C1381,117.1,1403.1,99.1,1403.3,73.6z"/>
		<path class="st0-fiver-green" d="M1381.7,77.6c3.5,6.8,6.7,13.1,10.2,19.9c-6.2,0-11.1,0-16.6,0c-1.3-2.9-2.5-5.7-4-8.6
			c-1.3-2.7-2.7-5.4-4.5-9.1c-4.9,6-1.1,12.3-4,17.5c-4.3,0-8.9,0-14,0c0-16.4,0-32.3,0-49.2c9.1,0,17.8-0.5,26.6,0.2
			c6.2,0.5,11.3,3.8,13.2,10.2C1390.7,66.1,1389.1,72.6,1381.7,77.6z M1364.3,70.6c5.7,0.5,9.2-0.8,9.2-6.4c0-4.1-2.2-5.3-9.2-4.6
			C1364.3,62.9,1364.3,66.4,1364.3,70.6z"/>
	</g>
</g>
</svg>

    </>
  )
}

export default Fverr
