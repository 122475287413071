import React from 'react'

const FiveStar = ({color}) => {
  return (
 
      <svg xmlns="http://www.w3.org/2000/svg" width="93" height="19" viewBox="0 0 93 19" fill="none">
  <path d="M8.34705 2.99596C8.6426 2.06678 9.9574 2.06678 10.253 2.99596L11.1663 5.86745C11.2983 6.28248 11.6838 6.56434 12.1193 6.56434H15.1195C16.083 6.56434 16.4892 7.7931 15.7154 8.36735L13.2519 10.1957C12.9088 10.4503 12.7654 10.8947 12.8949 11.3019L13.8272 14.2329C14.1214 15.158 13.0578 15.9176 12.2783 15.3391L9.89597 13.571C9.54206 13.3083 9.05794 13.3083 8.70403 13.571L6.32172 15.3391C5.54221 15.9176 4.47855 15.158 4.7728 14.2329L5.70512 11.3019C5.83461 10.8947 5.69118 10.4503 5.34813 10.1957L2.88456 8.36735C2.11081 7.79309 2.51696 6.56434 3.48053 6.56434H6.48073C6.91624 6.56434 7.30167 6.28248 7.43368 5.86745L8.34705 2.99596Z" fill={color}/>
  <path d="M26.9467 2.99596C27.2422 2.06678 28.557 2.06678 28.8526 2.99596L29.7659 5.86745C29.8979 6.28248 30.2834 6.56434 30.7189 6.56434H33.7191C34.6826 6.56434 35.0888 7.7931 34.3151 8.36735L31.8515 10.1957C31.5084 10.4503 31.365 10.8947 31.4945 11.3019L32.4268 14.2329C32.7211 15.158 31.6574 15.9176 30.8779 15.3391L28.4956 13.571C28.1417 13.3083 27.6575 13.3083 27.3036 13.571L24.9213 15.3391C24.1418 15.9176 23.0782 15.158 23.3724 14.2329L24.3047 11.3019C24.4342 10.8947 24.2908 10.4503 23.9477 10.1957L21.4842 8.36735C20.7104 7.79309 21.1166 6.56434 22.0801 6.56434H25.0803C25.5159 6.56434 25.9013 6.28248 26.0333 5.86745L26.9467 2.99596Z" fill={color}/>
  <path d="M45.5472 2.99596C45.8428 2.06678 47.1576 2.06678 47.4531 2.99596L48.3665 5.86745C48.4985 6.28248 48.884 6.56434 49.3195 6.56434H52.3197C53.2832 6.56434 53.6894 7.7931 52.9156 8.36735L50.4521 10.1957C50.109 10.4503 49.9656 10.8947 50.0951 11.3019L51.0274 14.2329C51.3216 15.158 50.258 15.9176 49.4785 15.3391L47.0962 13.571C46.7423 13.3083 46.2581 13.3083 45.9042 13.571L43.5219 15.3391C42.7424 15.9176 41.6787 15.158 41.973 14.2329L42.9053 11.3019C43.0348 10.8947 42.8914 10.4503 42.5483 10.1957L40.0848 8.36735C39.311 7.79309 39.7172 6.56434 40.6807 6.56434H43.6809C44.1164 6.56434 44.5019 6.28248 44.6339 5.86745L45.5472 2.99596Z" fill={color}/>
  <path d="M64.1469 2.99596C64.4424 2.06678 65.7572 2.06678 66.0528 2.99596L66.9661 5.86745C67.0981 6.28248 67.4836 6.56434 67.9191 6.56434H70.9193C71.8828 6.56434 72.289 7.7931 71.5152 8.36735L69.0517 10.1957C68.7086 10.4503 68.5652 10.8947 68.6947 11.3019L69.627 14.2329C69.9213 15.158 68.8576 15.9176 68.0781 15.3391L65.6958 13.571C65.3419 13.3083 64.8577 13.3083 64.5038 13.571L62.1215 15.3391C61.342 15.9176 60.2784 15.158 60.5726 14.2329L61.5049 11.3019C61.6344 10.8947 61.491 10.4503 61.1479 10.1957L58.6844 8.36735C57.9106 7.79309 58.3168 6.56434 59.2803 6.56434H62.2805C62.716 6.56434 63.1015 6.28248 63.2335 5.86745L64.1469 2.99596Z" fill={color}/>
  <path d="M82.7474 2.99596C83.043 2.06678 84.3578 2.06678 84.6533 2.99596L85.5667 5.86745C85.6987 6.28248 86.0841 6.56434 86.5197 6.56434H89.5199C90.4834 6.56434 90.8896 7.7931 90.1158 8.36735L87.6523 10.1957C87.3092 10.4503 87.1658 10.8947 87.2953 11.3019L88.2276 14.2329C88.5218 15.158 87.4582 15.9176 86.6787 15.3391L84.2964 13.571C83.9425 13.3083 83.4583 13.3083 83.1044 13.571L80.7221 15.3391C79.9426 15.9176 78.8789 15.158 79.1732 14.2329L80.1055 11.3019C80.235 10.8947 80.0916 10.4503 79.7485 10.1957L77.2849 8.36735C76.5112 7.79309 76.9174 6.56434 77.8809 6.56434H80.8811C81.3166 6.56434 81.7021 6.28248 81.8341 5.86745L82.7474 2.99596Z" fill={color}/>
</svg>
    
  )
}

export default FiveStar
