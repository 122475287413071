import React from 'react'

const PlusIcon = () => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="7" y1="4.37114e-08" x2="7" y2="14" stroke="black" stroke-width="2"/>
<line x1="14" y1="7" x2="-8.74228e-08" y2="7" stroke="black" stroke-width="2"/>
</svg>

  )
}

export default PlusIcon
